import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import DocMetaView from "../../components/DocMetaView/DocMetaView";
import StaticDocViewHeader from "../../components/StaticDocViewHeader/StaticDocViewHeader";
import StaticDocView from "../../components/StaticDocView/StaticDocView";
import { usePageAccess } from "../handlers/permissionHandler";
import { useAuthentication } from "../handlers/authentication/authentication-context";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Fade, Grid, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { PAGE_ACCESS } from "../defaultRolePageAccess";

function PageAccessMissing() {
  const { t } = useTranslation("organisation");

  return (
    <StaticDocView icon={"ErrorOutline"} type={"error"}>
      <StaticDocViewHeader text={t("permissions_missing")} />
    </StaticDocView>
  );
}

export function HasPageAccess({
  children,
  requiredPageAccess,
  returnNothing
}: {
  readonly children: React.ReactNode;
  readonly requiredPageAccess: PAGE_ACCESS[];
  readonly returnNothing: boolean;
}) {
  const { hasPageAccess } = usePageAccess();

  const validPageAccess = hasPageAccess(requiredPageAccess);

  if (!validPageAccess && returnNothing) {
    return <></>;
  }
  if (!validPageAccess && !returnNothing) {
    return <DocMetaView docViewContent={<PageAccessMissing />} />;
  }

  return <>{children}</>;
}

HasPageAccess.propTypes = {
  requiredPageAccess: PropTypes.arrayOf(PropTypes.string),
  returnNothing: PropTypes.bool
};

HasPageAccess.defaultProps = {
  requiredPageAccess: [],
  returnNothing: false
};

export function HasFeatureToggleOn({
  children,
  feature,
  navigateAway
}: {
  readonly children: React.ReactNode;
  readonly feature: string;
  readonly navigateAway: boolean;
}) {
  const { auth } = useAuthentication();
  const isFeatureToggleOn = useIsFeaturePresent(feature);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth?.uid) {
      return;
    }
    if (isFeatureToggleOn === null) {
      // not yet initialized
      return;
    }

    if (!isFeatureToggleOn && navigateAway) {
      navigate("/processes");
    }
  }, [auth?.uid, isFeatureToggleOn, navigateAway, navigate]);

  if (!isFeatureToggleOn) {
    return <></>;
  }
  return <>{children}</>;
}

const ToggleProtectedResourcePages: Record<string, string> = {
  "databreach-cause-type": "databreach-cause-type",
  "databreach-measure-type": "databreach-measure-type",
  "databreach-impact-type": "databreach-impact-type",
  "data-classification": "dataClassification",
  "asset-type": "assets",
  "dsr-request-type": "resourceCustomRequestType"
};

export function HasResourcesToggledOn({ children }: { readonly children: React.ReactNode }) {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const rawResourceType = params?.resourceType || "";
  const { tenantData } = useUserAndTenantData();

  const navigate = useNavigate();

  useEffect(() => {
    if (!tenantData?.features) {
      return;
    }

    const requiredFeatureToggle = ToggleProtectedResourcePages[rawResourceType];
    if (!requiredFeatureToggle) {
      setLoading(false);
      return;
    }

    const isFeatureToggleOn = tenantData.features.includes(requiredFeatureToggle);
    if (isFeatureToggleOn) {
      setLoading(false);
      return;
    }

    navigate("/resources");
  }, [rawResourceType, navigate, tenantData?.features]);

  return <>{loading ? <></> : children}</>;
}

HasFeatureToggleOn.propTypes = {
  feature: PropTypes.string.isRequired,
  navigateAway: PropTypes.bool
};

HasFeatureToggleOn.defaultProps = {
  navigateAway: true
};

export function ShowPageIf({
  children,
  pageAccessCondition
}: {
  readonly children: React.ReactNode;
  readonly pageAccessCondition?: (pages: PAGE_ACCESS[]) => boolean;
}) {
  const { auth } = useAuthentication();
  const { pageAccess } = usePageAccess();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (auth?.permissions.includes("super_admin")) {
      setShow(true);
      return;
    }

    setShow(pageAccessCondition?.(pageAccess) || false);
  }, [pageAccess, auth?.permissions, setShow, pageAccessCondition]);

  if (!show) {
    return <></>;
  }

  return <>{children}</>;
}

ShowPageIf.propTypes = {
  pageAccessCondition: PropTypes.func.isRequired
};

ShowPageIf.defaultProps = {};

const useLinkStyles = makeStyles((theme: any) => ({
  onHoverLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  linkIcon: {
    padding: "5px 0 0 4px",
    color: theme.palette.primary.main
  }
}));

export function LinkText({
  pathname,
  children,
  onClick
}: {
  readonly pathname: string;
  readonly children: React.ReactNode;
  readonly onClick: () => void;
}) {
  const classes = useLinkStyles();
  const { t } = useTranslation();
  const [displayIcon, setDisplayIcon] = useState(false);
  const onMouseEnter = useCallback(() => {
    setDisplayIcon(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setDisplayIcon(false);
  }, []);

  return (
    <Grid container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} alignItems="center">
      <Grid item>
        <Link to={{ pathname: pathname }} target="_blank" className={classes.onHoverLink} onClick={onClick}>
          <Tooltip title={t("common:openTab")} placement="right">
            <Grid container alignItems="center">
              <Grid item>{children}</Grid>
              <Fade in={displayIcon}>
                <Grid item>
                  <OpenInNewIcon className={classes.linkIcon} />
                </Grid>
              </Fade>
            </Grid>
          </Tooltip>
        </Link>
      </Grid>
    </Grid>
  );
}

LinkText.propTypes = {
  pathname: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

LinkText.defaultProps = {
  onClick: () => {
    /* empty */
  }
};

export function WindowFocus({ onFocus, onBlur }: { readonly onFocus: () => void; readonly onBlur: () => void }) {
  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, [onFocus, onBlur]);
  return <></>;
}

WindowFocus.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

WindowFocus.defaultProps = {
  onFocus: () => {
    /* empty */
  },
  onBlur: () => {
    /* empty */
  }
};
