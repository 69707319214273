import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress } from "@material-ui/core";
import CardItemList from "components/CardItemList/CardItemList";
import { useExternalRecipients } from "../../contexts/external-recipient-context";
import { useUserDepartments } from "../../contexts/department-context";
import { DataLocationDTO, linkDataLocationApi } from "../../api/externalRecipientApi";
import { useDataLocations } from "../../../hook/useDataLocations";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "../../contexts/overview-context";
import { COLLECTIONS } from "../../collections";
import { getProcessStatusIconProps } from "../questionnaires/utils/get-process-status-icon-props";
import { useUserProcesses } from "../../../hook/useUserProcesses";

export default function DataLocationDetailsInMetaview({ dataLocationId }: { dataLocationId: string }) {
  const { processes: relatedProcesses, processesLoaded } = useUserProcesses({
    dataLocationIDs: [dataLocationId]
  });

  const { t } = useTranslation("lists_data_locations");
  const [dataLocationDetailsLoaded, setDataLocationDetailsLoaded] = useState(false);
  const { getDepartmentName } = useUserDepartments();
  const [unlinkActivated, setUnlinkActivated] = useState(false);
  const { externalRecipients, loadExternalRecipientsHook } = useExternalRecipients();
  const { dataLocations, dataLocationsReload, dataLocationsLoaded } = useDataLocations();
  const dispatch = useOverviewDispatch();

  const [dataLocation, setDataLocation] = useState<DataLocationDTO | null>();

  useEffect(() => {
    const found = dataLocations.find(item => item.id === dataLocationId);
    setDataLocation(found || null);
  }, [dataLocationId, dataLocations]);

  const externalRecipient = useMemo(
    () => externalRecipients.find(externalRecipient => externalRecipient.id === dataLocation?.externalRecipientId),
    [dataLocation, externalRecipients]
  );

  useEffect(() => {
    if (!processesLoaded || !dataLocationsLoaded || !dataLocation) {
      return;
    }
    setDataLocationDetailsLoaded(false);
    setDataLocationDetailsLoaded(true);
  }, [dataLocation, processesLoaded, dataLocationsLoaded]);

  const externalDataRecipientCardNormal = useMemo(
    () => ({
      primaryIcon: externalRecipient?.approvedAt ? "ApprovedIcon" : "ProcessEditIcon",
      actionIcons: [
        {
          icon: "UnlinkIcon",
          tooltip: t("common:unlink"),
          onClick: () => setUnlinkActivated(true)
        }
      ],
      onClick: () => window.open(`/external-recipients/general/${dataLocation?.externalRecipientId || ""}`),
      primaryText: externalRecipient ? externalRecipient.name : "",
      ...(externalRecipient && externalRecipient.serviceType
        ? { secondaryText: t("lists_service_provider_types:" + externalRecipient.serviceType) }
        : {})
    }),
    [dataLocation, externalRecipient, t]
  );

  const handleConfirmUnlink = useCallback(async () => {
    if (!dataLocation?.externalRecipientId) {
      return;
    }
    await linkDataLocationApi({
      toLinkIDs: [dataLocation.id],
      externalRecipientId: null
    });
    await dataLocationsReload();
    await loadExternalRecipientsHook();
    setUnlinkActivated(false);
    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.DATA_LOCATIONS,
      reloadOverview: Date.now()
    });
  }, [dataLocation?.externalRecipientId, dataLocation?.id, dataLocationsReload, loadExternalRecipientsHook, dispatch]);

  const externalDataRecipientCardUnlinkActivated = useMemo(
    () => ({
      primaryIcon: "ErrorOutline",
      colorTheme: "yellow",
      actionIcons: [
        {
          icon: "ConfirmIcon",
          tooltip: t("common:confirm"),
          onClick: () => handleConfirmUnlink()
        },
        {
          icon: "CloseIcon",
          tooltip: t("common:cancel"),
          onClick: () => setUnlinkActivated(false)
        }
      ],
      primaryText: `${t("inline_icons_tooltips:confirm_unlink_product")}?`,
      secondaryText: externalRecipient?.name ? externalRecipient.name : ""
    }),
    [externalRecipient?.name, handleConfirmUnlink, t]
  );

  const externalDataRecipientCard = useMemo(
    () => [unlinkActivated ? externalDataRecipientCardUnlinkActivated : externalDataRecipientCardNormal],
    [unlinkActivated, externalDataRecipientCardUnlinkActivated, externalDataRecipientCardNormal]
  );

  const relatedProcessingActivitiesCardItemList = useMemo(
    () =>
      relatedProcesses.map(process => ({
        primaryIcon: getProcessStatusIconProps(process.status).icon,
        primaryIconTooltip: t(`processes_overview:${process.status}`),
        onClick: () => window.open(`/processes/${process.id}/general`),
        primaryText: process.title,
        secondaryText: process.orgUnitIds?.length ? getDepartmentName(process.orgUnitIds[0]) : ""
      })),
    [getDepartmentName, relatedProcesses, t]
  );

  const externalRecipientExists = externalRecipients.some(sp => sp.id === dataLocation?.externalRecipientId);
  const metaViewContent = (
    <>
      {dataLocationDetailsLoaded && (
        <Box p={3}>
          <CardItemList
            header={t("service_providers_overview:externalRecipient")}
            headerIcon={"HomeWorkIcon"}
            itemList={externalRecipientExists ? externalDataRecipientCard : emptyItem}
            text={externalRecipientExists ? undefined : t("no_external_recipients_linked")}
            disablePagination
            headerIconTooltip={undefined}
          />
          <Box mt={3} />
          <CardItemList
            header={t("processes_overview:list_processes")}
            headerIcon={"InsertDriveFileIcon"}
            itemList={relatedProcesses.length > 0 ? relatedProcessingActivitiesCardItemList : emptyItem}
            text={relatedProcesses.length === 0 ? t("no_processes") : undefined}
            headerIconTooltip={undefined}
          />
        </Box>
      )}
      {!dataLocationDetailsLoaded && (
        <Box pt={2} display="flex" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      )}
    </>
  );

  return <>{metaViewContent}</>;
}

const emptyItem: any[] = [];
