import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ServiceProviderSectionHeader from "components/ServiceProviderSectionHeader/ServiceProviderSectionHeader";
import TextField from "@material-ui/core/TextField";
import { Box, Grid } from "@material-ui/core";
import MultiAutocomplete from "../../../../components/MultiAutocomplete/MultiAutocomplete";
import { getCountries } from "../../../handlers/countryHandler";
import { translateAndSortCountriesOrAreas } from "../../../utils/translateAndSortCountriesOrAreas";
import SERVICE_TYPES_KEYS from "../utils/serviceTypes";
import { LabelField } from "../../../../components/LabelField";
import Question from "components/Question/Question";
import TextEditor from "app/pages/questionnaires/utils/TextEditor";
import { useExternalRecipients } from "../../../contexts/external-recipient-context";
import { useMetaView } from "../../../contexts/meta-view-context";
import { useUserDepartments } from "app/contexts/department-context";
import OrgunitsPathsAutocomplete from "components/OrgunitsPathsAutocomplete/OrgunitsPathsAutocomplete";
import HouseIcon from "@mui/icons-material/House";

export default function GeneralTab({ answersLoaded, documentId, answers, updateAnswers, disableComment }) {
  const { departments, getDepartmentWithParentName } = useUserDepartments();
  const { setInfo, setMeta, setInsight } = useMetaView();
  const { t, i18n } = useTranslation("serviceProviderGeneralTab");
  const [sortedCountries, setSortedCountries] = useState([]);
  useEffect(() => {
    setSortedCountries(translateAndSortCountriesOrAreas(getCountries(), t));
  }, [i18n, i18n.language, t]);

  const { updateExternalRecipientHook } = useExternalRecipients();

  useEffect(() => {
    setInsight({});
    setInfo({ title: t("generalInformation"), text: t("infoGeneralInformation") });
  }, [i18n.language, setInfo, setInsight, setMeta, t]);

  return (
    <>
      <ServiceProviderSectionHeader text={t("generalInformation")} />
      {answersLoaded === true && (
        <>
          <Question questionId={disableComment ? undefined : "name"} questionName={t("companyName")}>
            <TextField
              id={"name"}
              fullWidth={true}
              required
              label={t("companyName")}
              variant="outlined"
              onFocus={() => {
                setInfo({ title: t("generalInformation"), text: t("infoName") });
              }}
              value={answers.name || ""}
              onChange={event => {
                updateAnswers({ name: event.target.value });
              }}
              onBlur={() => {
                updateExternalRecipientHook(documentId, { name: answers.name });
              }}
            />
          </Question>

          <Question questionId={disableComment ? undefined : "street"} questionName={t("street")}>
            <TextField
              id={"street"}
              fullWidth={true}
              label={t("street")}
              variant="outlined"
              onFocus={() => {
                setInfo({ title: t("generalInformation"), text: t("infoStreet") });
              }}
              value={answers.street || ""}
              onChange={event => {
                updateAnswers({ street: event.target.value });
              }}
              onBlur={() => {
                updateExternalRecipientHook(documentId, { street: answers.street });
              }}
            />
          </Question>

          <Grid container>
            <Grid item xs={6}>
              <Box mr={3}>
                <Question questionId={disableComment ? undefined : "ZIP"} questionName={t("ZIP")}>
                  <TextField
                    id={"ZIP"}
                    fullWidth={true}
                    label={t("ZIP")}
                    variant="outlined"
                    onFocus={() => {
                      setInfo({ title: t("generalInformation"), text: t("infoZIP") });
                    }}
                    value={answers.ZIP || ""}
                    onChange={event => {
                      updateAnswers({ ZIP: event.target.value });
                    }}
                    onBlur={() => {
                      updateExternalRecipientHook(documentId, { ZIP: answers.ZIP });
                    }}
                  />
                </Question>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box ml={3}>
                <Question questionId={disableComment ? undefined : "city"} questionName={t("city")}>
                  <TextField
                    id={"city"}
                    fullWidth={true}
                    label={t("city")}
                    variant="outlined"
                    onFocus={() => {
                      setInfo({ title: t("generalInformation"), text: t("infoCity") });
                    }}
                    value={answers.city || ""}
                    onChange={event => {
                      updateAnswers({ city: event.target.value });
                    }}
                    onBlur={() => {
                      updateExternalRecipientHook(documentId, { city: answers.city });
                    }}
                  />
                </Question>
              </Box>
            </Grid>
          </Grid>
          {sortedCountries.length && (
            <Question questionId={disableComment ? undefined : "country"} questionName={t("country")}>
              <MultiAutocomplete
                id="country"
                label={t("country")}
                hasMultiSelect={false}
                options={sortedCountries.map(element => element.id)}
                selected={answers.country}
                getOptionLabel={countryId =>
                  sortedCountries.find(country => country.id === countryId)?.name || countryId
                }
                onFocus={() => {
                  setInfo({ title: t("generalInformation"), text: t("infoCountry") });
                }}
                updateSelected={value => {
                  updateAnswers({ country: value });
                  updateExternalRecipientHook(documentId, { country: value });
                }}
              />
            </Question>
          )}

          <Grid container>
            <Grid item xs={6}>
              <Box mr={3}>
                <Question questionId={disableComment ? undefined : "phone"} questionName={t("phone")}>
                  <TextField
                    id={"phone"}
                    fullWidth={true}
                    label={t("phone")}
                    variant="outlined"
                    onFocus={() => {
                      setInfo({ title: t("generalInformation"), text: t("infoPhone") });
                    }}
                    value={answers.phone || ""}
                    onChange={event => {
                      updateAnswers({ phone: event.target.value });
                    }}
                    onBlur={() => {
                      updateExternalRecipientHook(documentId, { phone: answers.phone });
                    }}
                  />
                </Question>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box ml={3}>
                <Question questionId={disableComment ? undefined : "url"} questionName={t("url")}>
                  <TextField
                    id={"url"}
                    fullWidth={true}
                    label={t("url")}
                    variant="outlined"
                    onFocus={() => {
                      setInfo({ title: t("generalInformation"), text: t("infoUrl") });
                    }}
                    value={answers.url || ""}
                    onChange={event => {
                      updateAnswers({ url: event.target.value });
                    }}
                    onBlur={() => {
                      updateExternalRecipientHook(documentId, { url: answers.url });
                    }}
                  />
                </Question>
              </Box>
            </Grid>
          </Grid>

          <ServiceProviderSectionHeader text={t("serviceType")} />
          <Question questionId={disableComment ? undefined : "serviceType"} questionName={t("serviceType")}>
            <MultiAutocomplete
              id={"serviceType"}
              label={t("serviceType")}
              disableClearable={true}
              hasMultiSelect={false}
              selected={answers.serviceType || "SERVICEPROVIDER"}
              onChange={value => {
                updateExternalRecipientHook(documentId, { serviceType: value });
                updateAnswers({ serviceType: value });
              }}
              onFocus={() => {
                setInfo({ title: t("generalInformation"), text: t("infoServiceType") });
              }}
              options={SERVICE_TYPES_KEYS}
              getOptionLabel={option => t("filter_criteria:service_type_" + option)}
            />
          </Question>

          <ServiceProviderSectionHeader text={t("organization")} />

          <Question
            questionId={disableComment ? undefined : "responsibleOrgUnitId"}
            questionName={t("responsibleOrgUnitId")}
          >
            <OrgunitsPathsAutocomplete
              value={answers.responsibleOrgUnitId}
              onChange={value => {
                updateExternalRecipientHook(documentId, { responsibleOrgUnitId: value });
                updateAnswers({ responsibleOrgUnitId: value });
              }}
              label={t("responsibleOrgUnitId")}
              onFocus={() => {
                setInfo({ title: t("generalInformation"), text: t("infoResponsibleOrganizationalUnit") });
              }}
              noDefaultValue={true}
            />
          </Question>

          <Question
            questionId={disableComment ? undefined : "furtherOrganizationalUnits"}
            questionName={t("furtherOrganizationalUnits")}
          >
            <MultiAutocomplete
              id={"furtherOrganizationalUnits"}
              icon={<HouseIcon color="primary" />}
              label={t("furtherOrganizationalUnits")}
              options={departments.map(element => element["id"])}
              selected={answers.furtherOrgUnitIds || []}
              getOptionLabel={getDepartmentWithParentName}
              hasMultiSelect
              onFocus={() => {
                setInfo({ title: t("generalInformation"), text: t("infoFurtherOrganizationalUnits") });
              }}
              updateSelected={value => {
                updateAnswers({ furtherOrgUnitIds: value });
                updateExternalRecipientHook(documentId, { furtherOrgUnitIds: value });
              }}
            />
          </Question>

          <ServiceProviderSectionHeader text={t("contactPerson")} />

          <Question questionId={disableComment ? undefined : "contactPerson"} questionName={t("contactPerson")}>
            <TextField
              id={"contactPerson"}
              fullWidth={true}
              label={t("name")}
              variant="outlined"
              onFocus={() => {
                setInfo({ title: t("generalInformation"), text: t("infoContactPerson") });
              }}
              value={answers.externalContactName || ""}
              onChange={event => {
                updateAnswers({ externalContactName: event.target.value });
              }}
              onBlur={() => {
                updateExternalRecipientHook(documentId, { externalContactName: answers.externalContactName });
              }}
            />
          </Question>

          <Grid container>
            <Grid item xs={6}>
              <Box mr={3}>
                <Question
                  questionId={disableComment ? undefined : "externalEmail"}
                  questionName={t("email")}
                  disabled={disableComment}
                >
                  <TextField
                    id={"email"}
                    fullWidth={true}
                    label={t("email")}
                    variant="outlined"
                    onFocus={() => {
                      setInfo({ title: t("generalInformation"), text: t("infoContactEmail") });
                    }}
                    value={answers.externalContactEmail || ""}
                    onChange={event => {
                      updateAnswers({ externalContactEmail: event.target.value });
                    }}
                    onBlur={() => {
                      updateExternalRecipientHook(documentId, { externalContactEmail: answers.externalContactEmail });
                    }}
                  />
                </Question>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box ml={3}>
                <Question
                  questionId={disableComment ? undefined : "externalPhone"}
                  questionName={t("phone")}
                  disabled={disableComment}
                >
                  <TextField
                    id={"externalPhone"}
                    fullWidth={true}
                    label={t("phone")}
                    variant="outlined"
                    onFocus={() => {
                      setInfo({ title: t("generalInformation"), text: t("infoContactPhone") });
                    }}
                    value={answers.externalContactPhone || ""}
                    onChange={event => {
                      updateAnswers({ externalContactPhone: event.target.value });
                    }}
                    onBlur={() => {
                      updateExternalRecipientHook(documentId, {
                        externalContactPhone: answers.externalContactPhone
                      });
                    }}
                  />
                </Question>
              </Box>
            </Grid>
          </Grid>

          <ServiceProviderSectionHeader text={t("internalContactPerson")} />
          <Question
            questionId={disableComment ? undefined : "internalContactPerson"}
            questionName={t("internalContactPerson")}
            disabled={disableComment}
          >
            <TextField
              id={"internalContactPerson"}
              fullWidth={true}
              label={t("name")}
              variant="outlined"
              onFocus={() => {
                setInfo({ title: t("generalInformation"), text: t("infoInternalContactPerson") });
              }}
              value={answers.internalContactName || ""}
              onChange={event => {
                updateAnswers({ internalContactName: event.target.value });
              }}
              onBlur={() => {
                updateExternalRecipientHook(documentId, {
                  internalContactName: answers.internalContactName
                });
              }}
            />
          </Question>
          <Grid container>
            <Grid item xs={6}>
              <Box mr={3}>
                <Question
                  questionId={disableComment ? undefined : "internalEmail"}
                  questionName={t("email")}
                  disabled={disableComment}
                >
                  <TextField
                    id={"internalEmail"}
                    fullWidth={true}
                    label={t("email")}
                    variant="outlined"
                    onFocus={() => {
                      setInfo({ title: t("generalInformation"), text: t("infoContactEmail") });
                    }}
                    value={answers.internalContactEmail || ""}
                    onChange={event => {
                      updateAnswers({ internalContactEmail: event.target.value });
                    }}
                    onBlur={() => {
                      updateExternalRecipientHook(documentId, {
                        internalContactEmail: answers.internalContactEmail
                      });
                    }}
                  />
                </Question>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box ml={3}>
                <Question
                  questionId={disableComment ? undefined : "internalPhone"}
                  questionName={t("phone")}
                  disabled={disableComment}
                >
                  <TextField
                    id={"internalPhone"}
                    fullWidth={true}
                    label={t("phone")}
                    variant="outlined"
                    onFocus={() => {
                      setInfo({ title: t("generalInformation"), text: t("infoContactPhone") });
                    }}
                    value={answers.internalContactPhone || ""}
                    onChange={event => {
                      updateAnswers({ internalContactPhone: event.target.value });
                    }}
                    onBlur={() => {
                      updateExternalRecipientHook(documentId, {
                        internalContactPhone: answers.internalContactPhone
                      });
                    }}
                  />
                </Question>
              </Box>
            </Grid>
          </Grid>

          <ServiceProviderSectionHeader text={t("compliance_page:notes")} />
          <Question
            questionId={disableComment ? undefined : "generalNotes"}
            questionName={t("compliance_page:notes")}
            disabled={disableComment}
          >
            <TextEditor
              id="generalNotes"
              variant="outlined"
              inputValue={answers.generalNotes || ""}
              onFocus={() => {
                setInfo({
                  title: t("generalInformation"),
                  text: t("infoNotes")
                });
              }}
              onChange={value => {
                updateAnswers({ generalNotes: value });
              }}
              onBlur={() => {
                updateExternalRecipientHook(documentId, {
                  generalNotes: answers.generalNotes
                });
                setInfo(null);
              }}
            />
          </Question>

          <Question
            questionId={disableComment ? undefined : "label"}
            questionName={t("labels:label")}
            disabled={disableComment}
          >
            <LabelField
              onFocus={() => {
                setInfo({
                  title: t("labels:info_header"),
                  text: t("labels:info_text_SP")
                });
              }}
              onBlur={() => {
                setInfo(null);
              }}
              selectedIDs={answers.labels ? answers.labels : []}
              onSelectionChanged={value => {
                updateAnswers({ labels: value });
                updateExternalRecipientHook(documentId, { labels: value });
              }}
            />
          </Question>
        </>
      )}
    </>
  );
}
