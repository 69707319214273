export class SmartLookTracker {
  private static smartlook: any = null;
  private static smartlookDomainProjectIDsMap: Record<string, string> = {
    "app.caralegal.eu": "2009d97dc1c8c22ed97c5a38e9ec7b87e4c41e32"
    // ,localhost: "2009d97dc1c8c22ed97c5a38e9ec7b87e4c41e32"
  };

  private static async loadSmartLook() {
    if (SmartLookTracker.smartlook) {
      return SmartLookTracker.smartlook;
    }
    const hostname = window.location.hostname;
    const smartlookProjectID =
      window.localStorage.getItem("smartLookProjectId") || SmartLookTracker.smartlookDomainProjectIDsMap[hostname];
    if (!smartlookProjectID) {
      return;
    }
    SmartLookTracker.smartlook = (await import("smartlook-client")).default;
    SmartLookTracker.smartlook.init(smartlookProjectID, {
      region: "eu",
      relayProxyUrl: `https://smartlook.${import.meta.env.VITE_API_DOMAIN || "caralegal.de"}`
    });
    return SmartLookTracker.smartlook;
  }

  public static async disableSmartLook() {
    if (!SmartLookTracker.smartlook) {
      return; // do nothing if smart look is not loaded
    }
    SmartLookTracker.smartlook?.pause();
  }

  public static async enableSmartLook() {
    if (!SmartLookTracker.smartlook) {
      // load smart look if not loaded yet, it starts automatically
      await SmartLookTracker.loadSmartLook();
    } else {
      // if loaded then restart
      SmartLookTracker.smartlook?.resume();
    }
  }
}
