import Box from "@material-ui/core/Box";
import { useCallback, useState } from "react";
import { Tooltip, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import i18n from "app/i18n";
import { getLocale } from "components/DateField";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { STATUSES } from "app/handlers/tasksHandler";
import { createDueDateLabel, createRegularDateLabel } from "app/utils/create-due-date-label";

const useStyles = makeStyles(theme => ({
  root: {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    borderRadius: "20px",
    padding: "6px 10px",
    fontWeight: 600,
    letterSpacing: "1px",
    fontSize: "10px",
    whiteSpace: "nowrap",
    height: "24px",
    "&.overdue": {
      color: "#FFFFFF",
      backgroundColor: "#F17A79"
    },
    "&.today": {
      color: "#C81228",
      backgroundColor: "#FDE9E9"
    },
    "&.tomorrow": {
      color: "#C81228",
      backgroundColor: "#FDE9E9"
    },
    "&.five_days": {
      color: "#BF871F",
      backgroundColor: "#FFF0D4"
    },
    "&.regular": {
      color: "#6C6C6C",
      backgroundColor: "#eeeeee"
    }
  },
  addDueDateDashed: {
    height: "24px",
    border: "1px dashed",
    borderRadius: "22px",
    fontSize: "10px",
    letterSpacing: "0.5px",
    borderColor: "#9E9E9E",
    color: "#9E9E9E",
    "&:hover": {
      background: theme.palette.grey[200]
    }
  }
}));

interface TaskOverviewRowDueDateProps {
  readonly dueDate: Date | null;
  readonly onChange: (dueAt: Date | null) => void;
  readonly readOnly?: boolean;
  readonly showAddDueDate?: boolean;
  readonly status: string;
}
const TaskOverviewRowDueDate = ({
  dueDate,
  status,
  onChange,
  showAddDueDate,
  readOnly
}: TaskOverviewRowDueDateProps) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");

  const [open, setOpen] = useState<boolean>(false);

  const { dateLabel, dateVariant } =
    status === STATUSES.done ? createRegularDateLabel(dueDate, t) : createDueDateLabel(dueDate, t);

  const openMenuCallback = useCallback(() => {
    if (readOnly) {
      return;
    }
    setOpen(true);
  }, [readOnly]);

  const closeMenuCallback = useCallback(() => {
    setOpen(false);
  }, []);

  const onChangeCallback = useCallback(
    (date: MaterialUiPickersDate) => {
      onChange(date || null);
    },
    [onChange]
  );

  const textFieldComponentCallback = useCallback(
    () =>
      dueDate ? (
        <Tooltip title={t("changeDueDate")} disableHoverListener={readOnly}>
          <Box
            className={`${cls.root} ${dateVariant}`}
            onClick={openMenuCallback}
            mr={2}
            style={!readOnly ? { cursor: "pointer" } : {}}
          >
            {dateLabel}
          </Box>
        </Tooltip>
      ) : (
        <Tooltip title={t("changeDueDate")} disableHoverListener={readOnly}>
          <Box
            className={`${cls.root} ${cls.addDueDateDashed}`}
            onClick={openMenuCallback}
            mr={2}
            style={!readOnly ? { cursor: "pointer" } : {}}
          >
            {`+ ${t("add_due_date")}`}
          </Box>
        </Tooltip>
      ),
    [cls.addDueDateDashed, cls.root, dateLabel, dateVariant, dueDate, openMenuCallback, readOnly, t]
  );

  if (!dueDate && !showAddDueDate) {
    return <></>;
  }

  return (
    <MuiPickersUtilsProvider locale={getLocale(i18n?.language || "")} utils={DateFnsUtils}>
      <KeyboardDatePicker
        disablePast={true}
        fullWidth
        disableToolbar
        variant="dialog"
        inputVariant="outlined"
        format="dd.MM.yyyy"
        margin="normal"
        id="deadline"
        value={dueDate}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        onChange={onChangeCallback}
        onClick={openMenuCallback}
        onClose={closeMenuCallback}
        open={open}
        label={t("due_date", { optional: "(optional)" })}
        minDateMessage={t("general_page:pastDate")}
        clearable={true}
        InputProps={{
          readOnly: true
        }}
        cancelLabel={t("common:cancel")}
        clearLabel={t("common:clear")}
        okLabel={t("common:ok")}
        TextFieldComponent={textFieldComponentCallback}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TaskOverviewRowDueDate;
