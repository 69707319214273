import React, { useCallback } from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Done from "@material-ui/icons/Done";

export interface ConfirmerProps {
  readonly title: string;
  readonly onConfirm: (event: any) => void;
  readonly onCancel: (event: any) => void;
}

const OverviewConfirmer = ({ title, onConfirm, onCancel }: ConfirmerProps) => {
  const stopEvent = useCallback(event => event.stopPropagation(), []);
  return (
    <Box minWidth={0} display="flex" flex={1} onClick={stopEvent} alignItems={"center"}>
      <Box minWidth={0} flex={1}>
        <Typography noWrap className={"deleteTitle"}>
          {title}
        </Typography>
      </Box>
      <Box ml={2}>
        <IconButton color="primary" onClick={onConfirm}>
          <Done />
        </IconButton>
        <IconButton color="secondary" onClick={onCancel}>
          <Close />
        </IconButton>
      </Box>
    </Box>
  );
};

export default OverviewConfirmer;
