import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

export default function TextBodySecondary({
  text,
  translationKey,
  parseHTML = false
}: {
  text: string;
  translationKey?: string;
  parseHTML?: boolean;
}) {
  const { t } = useTranslation(translationKey);

  return (
    <>
      {parseHTML ? (
        <Typography variant="body2" component="span">
          {parse(DOMPurify.sanitize(text))}
        </Typography>
      ) : (
        <Typography variant="body2" component="span">
          {translationKey ? t(text) : text}
        </Typography>
      )}
    </>
  );
}
