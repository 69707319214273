import AssignUsersMultiAutocomplete from "app/pages/questionnaires/utils/AssignUsersMultiAutocomplete/AssignUsersMultiAutocomplete";
import React, { useCallback } from "react";
import { QuestionProps } from "../../Question";
import { QUESTION_TYPE } from "../../QuestionTypes";

const AssignToUserQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onChange,
  onBlur,
  orgUnitIds,
  onFocus,
  assignType
}: QuestionProps) => {
  const onChangeCallback = useCallback(
    (ids: string[]) => {
      onChange?.(ids);
    },
    [onChange]
  );
  const _value: string[] = Array.isArray(value) ? value : [value as string];
  return (
    <AssignUsersMultiAutocomplete
      id={QUESTION_TYPE.ASSIGNED_TO_USER}
      docOrgUnitIds={orgUnitIds}
      docAssignedUserIds={_value}
      onDocAssignedUserIdsChanged={onChangeCallback}
      onBlur={onBlur}
      freeSolo={false}
      disableClearable={true}
      label={questionName || ""}
      hasMultiSelect={false}
      hasDefaultValue={true}
      disabled={disabled}
      error={error}
      helperText={helperText}
      onFocus={onFocus}
      assignType={assignType}
    />
  );
};

export default React.memo(AssignToUserQuestion);
