import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import i18n from "app/i18n";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.riskUrl}/api/risks`
});

export async function getRisksApi(type) {
  const response = await axiosInstance.get("/", { params: { type } });
  return response.data.risks;
}

export async function getRiskApi(id) {
  const response = await axiosInstance.get(`/${id}`);
  return response.data;
}

export async function createRiskApi(title, additionalPayload = {}) {
  const response = await axiosInstance.post(`/`, {
    title,
    ...additionalPayload
  });
  return response.headers["x-resource-id"] || "";
}

export async function updateRiskApi(
  id,
  version,
  {
    title,
    type,
    orgUnitId,
    furtherAffectedOrgUnitIds,
    description,
    ownerUID,
    privacyRelevant,
    riskSourceIds,
    riskAssetIds,
    dataLocationIds,
    protectionObjectiveIds,
    implementedMeasureIds,
    labelIds
  } = {}
) {
  const response = await axiosInstance.patch(`/${id}`, {
    title,
    type,
    orgUnitId,
    furtherAffectedOrgUnitIds,
    description,
    ownerUID,
    privacyRelevant,
    riskSourceIds,
    riskAssetIds,
    dataLocationIds,
    protectionObjectiveIds,
    implementedMeasureIds,
    version,
    labelIds
  });
  return response.data;
}

export async function updateRiskTreatmentApi(id, version, { type, measureIds, description }) {
  const response = await axiosInstance.patch(`/${id}/treatments`, {
    type,
    measureIds,
    description,
    version
  });

  return response.data;
}

export async function updateRiskAssessmentSettingApi(id, phaseId, version, { individualAssessmentEnabled }) {
  const response = await axiosInstance.patch(`/${id}/phases/${phaseId}`, {
    individualAssessmentEnabled,
    version
  });

  return response.data;
}

export async function updateRiskAssessmentApi(
  id,
  phaseId,
  protectionObjectiveId,
  version,
  { occurrenceId, damageExtendId, impactDescription, reasonDescription }
) {
  const response = await axiosInstance.patch(`/${id}/phases/${phaseId}/assessments/${protectionObjectiveId}`, {
    occurrenceId,
    damageExtendId,
    impactDescription,
    reasonDescription,
    version
  });

  return response.data;
}

export const getRisksExports = async params => {
  const response = await axiosInstance.post(
    `/exports`,
    {
      riskIDs: params.riskIDs
    },
    {
      headers: {
        "Accept-Language": i18n.language || "en-US"
      },
      responseType: "blob",
      timeout: 300000
    }
  );
  return new Blob([response.data]);
};

export async function deleteRiskApi(id, version) {
  await axiosInstance.delete(`/${id}`, { params: { version } });
}

export async function copyRiskApi(id) {
  const response = await axiosInstance.post(`/${id}`);
  return response.headers["x-resource-id"] || "";
}
