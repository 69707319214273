import { ResourceDTO } from "app/api/resourceApi";
import { COLLECTIONS } from "app/collections";
import { useOverviewDispatch, OVERVIEW_ACTIONS } from "app/contexts/overview-context";
import { RESOURCE_TYPE, updateResourceOrgUnits } from "app/handlers/resourceHandler";
import OrgUnitPickerModal from "app/pages/shared/OrgUnitPickerModal/OrgUnitPickerModal";
import { TFunction } from "i18next";
import { isEqual } from "lodash-es";
import { useCallback } from "react";

interface ResourceTypeOverviewOrgUnitPickerProps {
  readonly resourceType: RESOURCE_TYPE;
  readonly selectedResourceForLinking: ResourceDTO | null;
  readonly onClose: () => void;
  readonly t: TFunction;
}

const ResourceTypeOverviewOrgUnitPicker = ({
  resourceType,
  selectedResourceForLinking,
  onClose,
  t
}: ResourceTypeOverviewOrgUnitPickerProps) => {
  const dispatch = useOverviewDispatch();
  const onSelectedResourceOrgUnitsChanged = useCallback(
    async updatedOrgUnitIds => {
      if (!selectedResourceForLinking || !Array.isArray(updatedOrgUnitIds)) {
        return;
      }
      if (isEqual(updatedOrgUnitIds, selectedResourceForLinking.orgUnitIds)) {
        onClose();
        return;
      }

      await updateResourceOrgUnits({
        resourceType: resourceType,
        resourceId: selectedResourceForLinking.id,
        orgUnitIds: updatedOrgUnitIds
      });
      dispatch({
        type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
        collection: COLLECTIONS.RESOURCES,
        reloadOverview: Date.now()
      });
    },
    [onClose, selectedResourceForLinking, resourceType, dispatch]
  );

  return (
    <OrgUnitPickerModal
      onConfirm={onSelectedResourceOrgUnitsChanged}
      selectedDepartmentIds={selectedResourceForLinking?.orgUnitIds || []}
      onClose={onClose}
      open={!!selectedResourceForLinking}
      linkingOrgUnitsModalHeading={t("resources_overview:linkingModalHeading")}
      linkingOrgUnitsModalSubHeading={t("resources_overview:linkingModalSubHeading")}
    />
  );
};

export default ResourceTypeOverviewOrgUnitPicker;
