import { getDataSubjectRequest } from "./dataSubjectRequestHandler";
import { isSameYYYYMMDDInUTC } from "./utility/date-helper";
import { COLLECTIONS } from "../collections";
import { getDataBreachById } from "./dataBreachHandler";
import { getTextFromTextEditorJsonString } from "../pages/questionnaires/utils/textEditorConverter";
import { addItemToSection } from "./sectionHandler";
import {
  NewTask,
  Task,
  createTaskApi,
  deleteSingleTaskApi,
  detailType,
  getActiveTasksOfUserApi,
  getDocumentTasksApi,
  getTaskByIdApi,
  getTasksApi,
  updateTaskApi
} from "../api/taskApi";

import {
  NewRecurringTask,
  RecurringTask,
  UpdateRecurringTask,
  activateRecurringTaskApi,
  createRecurringTaskApi,
  deactivateRecurringTaskApi,
  deleteRecurringTaskApi,
  getRecurringTaskApi,
  getRecurringTasksApi,
  updateRecurringTaskApi
} from "../api/recurringTaskApi";
import { UserNotificationDTO, sendNotificationApi } from "../api/userNotificationApi";
import {
  getGroupTaskByIdApi,
  updateGroupTaskApi,
  UpdateGroupTask,
  getAllGroupStatusByTaskIdApi
} from "app/api/groupTaskApi";
import { getSingleGroupApi } from "app/api/groupApi";

export const STATUSES = {
  deleted: "DELETED",
  todo: "TODO",
  open: "OPEN",
  done: "DONE"
};

export const PRIORITIES = {
  low: "low",
  medium: "medium",
  high: "high",
  highest: "highest"
};

export const DONE_INTERVAL = {
  today: "today",
  yesterday: "yesterday",
  lastSevenDays: "lastSevenDays",
  all: "all"
};

export const TYPES = {
  fill: "fill",
  check_databreach: "check_databreach",
  dsr_auto: "dsr_automatic_tasks",
  check_dsr: "check_dsr",
  individual: "individual"
};

/* INNER */
const sendTaskNotification = async (input: {
  readonly tenantId: string;
  readonly senderUID: string;
  readonly task: Task | RecurringTask;
  readonly notificationTitleType: string;
  readonly receiverUIDs?: string[];
  readonly includeNotes?: boolean;
}) => {
  const { task, senderUID, notificationTitleType, includeNotes } = input;
  const receivers: string[] = [
    ...new Set(
      (input.receiverUIDs || [task.creatorUID, task.assigneeUID])
        .filter(notNull => notNull)
        .filter(userUID => userUID !== senderUID)
    )
  ] as string[];

  if (receivers.length <= 0) {
    return;
  }

  const notificationObj: UserNotificationDTO = {
    title: notificationTitleType,
    receivers,
    assignee: task.assigneeUID || undefined,
    pageId: task.pageId || "1",
    collection: task.collection || "tasks",
    docId: task.documentId || task.id,
    docName: task.title || "",
    message: includeNotes
      ? task.notes || ""
      : task.description
        ? getTextFromTextEditorJsonString(task.description)
        : "",
    origin: { taskId: task.id, questionId: task.questionId || "" }
  };
  return sendNotificationApi(notificationObj);
};

// task assignment have some special behaviour
const sendTaskAssignmentNotification = async (input: {
  readonly tenantId: string;
  readonly changerUID: string;
  readonly task: Task;
  readonly previousAssigneeUID?: string;
}) => {
  const { tenantId, changerUID, previousAssigneeUID, task } = input;
  if (!task.assigneeUID) {
    return;
  }
  const notificationPromises = [];

  notificationPromises.push(
    sendTaskNotification({
      tenantId,
      senderUID: changerUID,
      task,
      notificationTitleType: "task_assignment",
      receiverUIDs: [task.assigneeUID]
    })
  );

  // do not include the assignee of the task to the reassignment receivers (this may happen due to overlap in getAdditionalReceiverUIDByCollectionType)
  const reassignmentReceivers: string[] = [
    task.creatorUID,
    previousAssigneeUID,
    ...(await getAdditionalReceiverUIDByCollectionType(tenantId, task))
  ].reduce<string[]>((acc, next) => {
    if (next && next !== task.assigneeUID) {
      return [...acc, next];
    } else {
      return acc;
    }
  }, []);

  if (reassignmentReceivers.length > 0) {
    notificationPromises.push(
      sendTaskNotification({
        tenantId,
        senderUID: changerUID,
        task,
        notificationTitleType: "task_reassignment",
        receiverUIDs: reassignmentReceivers
      })
    );
  }

  return await Promise.all(notificationPromises);
};

const sendTaskParticipationNotification = async (input: {
  readonly tenantId: string;
  readonly changerUID: string;
  readonly task: Task | RecurringTask;
  readonly newParticipantIds: string[];
  readonly oldParticipantIds: string[];
}) => {
  const { tenantId, task, newParticipantIds, oldParticipantIds, changerUID } = input;
  if (!task.assigneeUID) {
    return;
  }
  const newParticipationNotificationReceivers = newParticipantIds.filter(
    participantId => !oldParticipantIds.includes(participantId)
  );
  const participationRemovalReceivers = oldParticipantIds.filter(
    previousParticipantId => !newParticipantIds.includes(previousParticipantId)
  );

  return await Promise.all([
    sendTaskNotification({
      tenantId,
      senderUID: changerUID,
      notificationTitleType: "task_participation",
      task,
      receiverUIDs: newParticipationNotificationReceivers
    }),
    sendTaskNotification({
      tenantId,
      senderUID: changerUID,
      notificationTitleType: "task_participation_removal",
      task,
      receiverUIDs: participationRemovalReceivers
    })
  ]);
};

const getAdditionalReceiverUIDByCollectionType = async (tenantId: string, task: Task | RecurringTask) => {
  if (!task.documentId || !task.collection) {
    return [];
  }

  if (task.collection === COLLECTIONS.DATA_SUBJECT_REQUESTS) {
    const dsr = await getDataSubjectRequest(task.documentId);
    return dsr ? [dsr?.createdBy, dsr?.inputData?.assignedTo].filter(uid => uid) : [];
  } else if (task.collection === COLLECTIONS.DATA_BREACHES) {
    const databreach = await getDataBreachById(task.documentId);
    if (!databreach) {
      return [];
    }
    return databreach ? [(databreach?.creatorUID, databreach.assigneeUID)].filter(uid => uid) : [];
  }

  return [];
};

/* EXPORT */
export const createTask = async (input: {
  readonly tenantId: string;
  readonly task: NewTask;
  readonly sectionKey?: string;
}): Promise<string> => {
  const { task, tenantId, sectionKey } = input;
  const createdTaskId = await createTaskApi(task);
  const createdTask = await getTask(createdTaskId);

  if (!createdTask) {
    return "";
  }

  if (sectionKey) {
    await addItemToSection(tenantId, createdTask.creatorUID, COLLECTIONS.TASKS, sectionKey, createdTaskId);
  }

  await sendTaskAssignmentNotification({ tenantId, changerUID: createdTask.creatorUID, task: createdTask });

  return createdTaskId;
};

export const getAllTasksByDocumentId = async (documentId: string) => {
  const documentTasks = await getDocumentTasksApi(documentId);
  return documentTasks.sort((a: Task, b: Task) => new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf());
};

export const genericTasksFilter = (tasks: Task[], filter: any) => {
  // remove all null
  Object.keys(filter).forEach(key => {
    if (filter[key] === null || filter[key] === "") {
      delete filter[key];
    }
  });
  const filterKeys = Object.keys(filter);
  return tasks
    .filter(task => {
      return !filterKeys.some(key => {
        return filter[key] !== task[key as keyof Task];
      });
    })
    .sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
};

export const getAllTasksFromForCollection = async (input: { readonly collectionId: string }) => {
  return await getTasksApi(input);
};

export const getAllActiveUserRelatedTasks = async (
  input: { readonly includeDone: boolean } = { includeDone: false }
) => {
  return await getActiveTasksOfUserApi(input);
};

export const getTask = async (taskId: string): Promise<Task | null> => {
  let task;
  try {
    task = await getTaskByIdApi(taskId);
  } catch (error: any) {
    if (error?.response?.status === 404) {
      return null;
    }
    throw error;
  }

  if (!task) {
    return null;
  }
  if (task.status === STATUSES.deleted) {
    return null;
  }

  return {
    ...task,
    id: taskId
  };
};

export const deleteTask = async (taskId: string) => {
  await deleteSingleTaskApi(taskId);
};

export const markTaskAsDone = async (tenantId: string, changerUID: string, taskId: string) => {
  const task = await getTask(taskId);
  if (!task || (task.status !== STATUSES.open && task.status !== STATUSES.todo)) {
    return;
  }

  await updateTaskApi(taskId, { status: STATUSES.done });
  await sendTaskNotification({
    tenantId,
    senderUID: changerUID,
    notificationTitleType: "task_done",
    task,
    includeNotes: true
  });
};

export const markGroupTaskAsDone = async (tenantId: string, changerUID: string, groupTaskId: string) => {
  const groupTask = await getGroupTask(groupTaskId);
  if (!groupTask || (groupTask.status !== STATUSES.open && groupTask.status !== STATUSES.todo)) {
    return;
  }

  await updateGroupTaskApi(groupTaskId, { status: STATUSES.done });
  await sendTaskNotification({
    tenantId,
    senderUID: changerUID,
    notificationTitleType: "task_done",
    task: groupTask,
    includeNotes: true
  });
};

export const changeTaskTitle = async (tenantId: string, changerUID: string, taskId: string, title: string) => {
  const task = await getTask(taskId);

  if (!task) {
    return;
  }

  if (task.status === STATUSES.open || task.status === STATUSES.todo || task.status === STATUSES.done) {
    await updateTaskApi(taskId, { ...task, title, updatedAt: new Date().toString(), dueAt: task.dueAt?.toString() });
  }
};

export const changeTaskAssignee = async (tenantId: string, changerUID: string, taskId: string, assigneeUID: string) => {
  const task = await getTask(taskId);

  if (!task || (task.status !== STATUSES.open && task.status !== STATUSES.todo && task.status !== STATUSES.done)) {
    return;
  }
  const participants = (task.participants ?? []).filter(participantId => participantId !== assigneeUID);

  await updateTaskApi(taskId, {
    assigneeUID: assigneeUID,
    seen: changerUID === assigneeUID,
    participants
  });

  await sendTaskAssignmentNotification({
    tenantId,
    changerUID,
    task: { ...task, assigneeUID },
    previousAssigneeUID: task.assigneeUID || undefined
  });
};

export const changeRecurringTaskAssignee = async (
  tenantId: string,
  changerUID: string,
  taskId: string,
  assigneeUID: string
) => {
  const task = await getRecurringTask(taskId);

  const taskParticipants = task.participants ?? [];
  const newParticipants = taskParticipants.filter(participantId => participantId !== assigneeUID);

  await updateRecurringTaskApi(taskId, {
    assigneeUID: assigneeUID,
    participants: newParticipants
  });
};

export const changeTaskParticipants = async (
  tenantId: string,
  changerUID: string,
  taskId: string,
  participantsIds: string[]
) => {
  const task = await getTask(taskId);
  if (!task) {
    return;
  }
  const previousParticipantsIds = task.participants ?? [];
  await updateTaskApi(taskId, {
    participants: participantsIds
  });

  await sendTaskParticipationNotification({
    tenantId,
    changerUID,
    task,
    newParticipantIds: participantsIds,
    oldParticipantIds: previousParticipantsIds
  });
};

export const changeTaskGroups = async (taskId: string, groupIds: string[], tenantId: string) => {
  const task = await getTask(taskId);
  if (!task) return;
  await updateTaskApi(taskId, { groupIds });
  try {
    const receivers = await getAllReceivers(groupIds);
    const groupTasks = await getAllGroupStatusByTaskId(taskId);
    if (receivers.length > 0 && groupTasks.length > 0) {
      await notifyReceivers(task, receivers, groupTasks, tenantId);
    }
  } catch (error: any) {
    console.error("Error when sending task notification:", error.message);
  }
};

const getAllReceivers = async (groupIds: string[]): Promise<string[]> => {
  const receivers: string[] = [];
  for (const groupId of groupIds) {
    const groupDetails = await getSingleGroupApi(groupId);
    if (groupDetails?.userIds) receivers.push(...groupDetails.userIds);
  }
  return receivers;
};

const notifyReceivers = async (task: Task, receivers: string[], groupTasks: Task[], tenantId: string) => {
  for (const receiver of receivers) {
    const receiverTask = groupTasks.find(task => task.assigneeUID === receiver);
    if (receiverTask) {
      await sendTaskNotification({
        tenantId,
        senderUID: task.creatorUID,
        task: receiverTask,
        notificationTitleType: "task_assignment",
        receiverUIDs: [receiver]
      });
    }
  }
};

export const changeRecurringTaskParticipants = async (
  tenantId: string,
  changerUID: string,
  taskId: string,
  participantsIds: string[]
) => {
  const task = await getRecurringTask(taskId);
  if (!task) {
    return;
  }
  const previousParticipantsIds = task.participants ?? [];
  await updateRecurringTaskApi(taskId, {
    participants: participantsIds
  });

  await sendTaskParticipationNotification({
    tenantId,
    changerUID,
    task,
    oldParticipantIds: previousParticipantsIds,
    newParticipantIds: participantsIds
  });
};

export const updateTaskDeadline = async (
  tenantId: string,
  changerUID: string,
  taskId: string,
  deadline: Date | null
) => {
  const task = await getTask(taskId);
  if (!task || (task.status !== STATUSES.open && task.status !== STATUSES.todo)) {
    return;
  }
  if (isSameYYYYMMDDInUTC(task.dueAt, deadline)) {
    return;
  }

  await updateTaskApi(taskId, {
    dueAt: deadline?.toISOString() || null
  });
  await sendTaskNotification({
    tenantId,
    senderUID: changerUID,
    task: { ...task, dueAt: deadline || null },
    notificationTitleType: "task_due_changed"
  });
};

export const updateTaskInformation = async (
  tenantId: string,
  updaterUID: string,
  taskId: string,
  payload: Partial<{
    readonly title: string;
    readonly description: string;
    readonly labels: string[];
    readonly notes: string;
    readonly status: string;
    readonly priority: string;
    readonly seen: boolean;
    readonly assignedToType: string;
  }>
) => {
  const { title, description, labels, notes, status, priority, seen, assignedToType } = payload;
  const task = await getTask(taskId);

  if (!task) {
    return;
  }

  if (task.status === STATUSES.open || task.status === STATUSES.todo || task.status === STATUSES.done) {
    if (status === STATUSES.done) {
      await sendTaskNotification({
        tenantId,
        senderUID: updaterUID,
        task,
        notificationTitleType: "task_done",
        includeNotes: true
      });
    }

    await updateTaskApi(taskId, {
      ...task,
      title,
      description,
      labels,
      notes,
      status,
      priority,
      seen,
      updatedAt: new Date().toString(),
      dueAt: task.dueAt ? task.dueAt.toString() : null,
      assignedToType
    });
  }
};

export const getDocFollowUpTasks = async (docId: string) => {
  return await getRecurringTasksApi({ documentId: docId, type: "follow-up" });
};

export const createDocFollowUpTask = async (docId: string, createPayload: NewRecurringTask) => {
  const existingFollowUpTasks = await getDocFollowUpTasks(docId);
  if (!existingFollowUpTasks.length) {
    await createRecurringTaskApi({
      ...createPayload,
      recurrenceType: createPayload.recurrenceType || "YEARLY",
      dayOfWeek: createPayload.dayOfWeek || "MONDAY",
      endTime: createPayload.endTime || null,
      startTime: createPayload.startTime || null,
      priority: createPayload.priority || "medium",
      type: createPayload.type || "follow-up"
    });
  }
};

export const deleteDocFollowUpTasks = async (docId: string) => {
  const existingFollowUpTasks = await getRecurringTasksApi({ documentId: docId, type: "follow-up" });
  await Promise.all(existingFollowUpTasks.map(async existingTask => await deleteRecurringTaskApi(existingTask.id)));
};

export const createRecurringTask = async (data: NewRecurringTask) => {
  return await createRecurringTaskApi({ ...data, collection: COLLECTIONS.RECURRING_TASKS });
};

export const getRecurringTask = async (id: string) => {
  return await getRecurringTaskApi(id);
};

export const getGroupTask = async (id: string) => {
  return await getGroupTaskByIdApi(id);
};

export const getAllGroupStatusByTaskId = async (id: string) => {
  return await getAllGroupStatusByTaskIdApi(id);
};

export const updateRecurringTask = async (
  taskId: string,
  {
    title,
    recurrenceType,
    dayOfWeek,
    startTime,
    endTime,
    description,
    labels,
    notes,
    priority,
    assigneeUID,
    ownerUID
  }: UpdateRecurringTask
) => {
  return await updateRecurringTaskApi(taskId, {
    title,
    recurrenceType,
    dayOfWeek,
    startTime,
    endTime,
    description,
    labels,
    notes,
    priority,
    assigneeUID,
    ownerUID
  });
};

export const updateGroupTask = async (groupTaskId: string, { status, seen }: UpdateGroupTask) => {
  return await updateGroupTaskApi(groupTaskId, {
    status,
    seen
  });
};

export const updateGroupTaskInformation = async (
  tenantId: string,
  updaterUID: string,
  groupTaskId: string,
  payload: Partial<{
    readonly status: string;
    readonly seen: boolean;
  }>
) => {
  const { status, seen } = payload;
  const groupTask = await getGroupTask(groupTaskId);

  if (!groupTask) {
    return;
  }

  if (groupTask.status === STATUSES.open || groupTask.status === STATUSES.todo || groupTask.status === STATUSES.done) {
    if (status === STATUSES.done) {
      await sendTaskNotification({
        tenantId,
        senderUID: updaterUID,
        task: groupTask,
        notificationTitleType: "task_done",
        includeNotes: true
      });
    }

    await updateGroupTaskApi(groupTaskId, {
      status,
      seen
    });
  }
};

export const deleteRecurringTask = async (taskId: string) => {
  return await deleteRecurringTaskApi(taskId);
};

export const activateRecurringTask = async (taskId: string) => {
  return await activateRecurringTaskApi(taskId);
};

export const deactivateRecurringTask = async (taskId: string) => {
  return await deactivateRecurringTaskApi(taskId);
};

export const sortTaskByPrioprity = (tasks: Task[]) => {
  const priorities = [PRIORITIES.highest, PRIORITIES.high, PRIORITIES.medium, PRIORITIES.low];
  const taskWithNoPriopirty = tasks.filter(task => !task.priority);
  const taskWithPriopirty = tasks.filter(task => task.priority);
  const sortedTaskWithPriopirty = [...taskWithPriopirty].sort(
    (a, b) => priorities.indexOf(a.priority || "") - priorities.indexOf(b.priority || "")
  );
  return [...sortedTaskWithPriopirty, ...taskWithNoPriopirty];
};

export const isMyTask = (userId: string, task: any): boolean => {
  return userId == task.assigneeUID || task.participants?.includes(userId) || task.ownerUID === userId;
};

/**
 * @param taskId string
 * If we not sure what kind of task we have
 * and we know only ID of the task
 */
export const getTaskDetailTypeByTaskId = async (taskId: string) => {
  // BASIC TASK DETAILE
  try {
    const task = await getTaskByIdApi(taskId);
    if (task) {
      return {
        detailType: "DEFAULT" as detailType,
        task
      };
    }
  } catch (e) {
    // do nothing
  }

  // RECURRING TASK DETAILE
  try {
    const task = await getRecurringTaskApi(taskId);
    if (task) {
      return {
        detailType: "RECURRING" as detailType,
        task
      };
    }
  } catch (e) {
    // do nothing
  }

  // GROUP TASK DETAILE
  try {
    const task = await getGroupTaskByIdApi(taskId);
    if (task) {
      return {
        detailType: "GROUP" as detailType,
        task
      };
    }
  } catch (e) {
    // do nothing
  }
};
