import { useCallback } from "react";
import overviewBaseController, {
  OverviewController,
  OverviewNewItem,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import {
  getDataSubjectRequestCaseFilter,
  getDataSubjectRequestStatusFilter,
  getLabelsFilter,
  getOrganizationFilter,
  getProcessTaskFilter
} from "app/pages/shared/Filters/filters";
import { resourcesDecorator } from "components/Overview/controllers/decorator/resourcesDecorator";
import { tDeletedEntry } from "app/handlers/dataTypeTranslatorHandler";
import { departmentsDecorator } from "../../../../components/Overview/controllers/decorator/departmentsDecorator";
import { AxiosInstance } from "axios";

export interface DataSubjectRequestOverviewCollectionParams {
  readonly exportToXLSX: (ids: string[]) => Promise<void>;
}

const DataSubjectRequestOverviewController = (
  axiosInstance: AxiosInstance,
  collectionParams: DataSubjectRequestOverviewCollectionParams
): OverviewController => {
  const { t } = useTranslation();
  const translateItem = useCallback(
    obj => ({ ...obj, subTitle: obj.subTitle === "deleted" ? tDeletedEntry({ t }) : obj.subTitle }),
    [t]
  );
  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.DATA_SUBJECT_REQUESTS, translateItem, [
    resourcesDecorator,
    departmentsDecorator
  ]);

  const getFilters = useCallback(
    data => [
      getOrganizationFilter("allOrgUnitIds", data._departments, t, "department"),
      getOrganizationFilter("orgUnitId", data._departments, t, "affectedDepartment"),
      getOrganizationFilter("furtherOrgUnitIds", data._departments, t, "furtherDepartments"),
      getDataSubjectRequestCaseFilter("types", t, data._resources),
      getDataSubjectRequestStatusFilter("status", t),
      getLabelsFilter("labelIds", data._resources, t),
      getProcessTaskFilter("openTasks", t)
    ],
    [t]
  );

  const getSortings = useCallback(
    () => [
      {
        field: "title",
        type: "asc",
        label: t("filter_criteria:aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: t("filter_criteria:zToA")
      },
      {
        field: "createdAt",
        type: "desc",
        label: t("filter_criteria:newFirst")
      },
      {
        field: "createdAt",
        type: "asc",
        label: t("filter_criteria:oldFirst")
      }
    ],
    [t]
  );

  const getPaginatedOverview = async (setup: OverviewSetup) => {
    const data = await baseController.getPaginatedOverview(setup);
    if (!data) {
      return null;
    }

    return {
      ...data,
      filters: getFilters(data),
      sortings: getSortings()
    };
  };

  const goToItem = (id: string) => {
    baseController.goToItem(`/data-subject-requests/${id}/general`);
  };

  const addItem = async (data: OverviewNewItem) => {
    return await baseController.addItem({
      title: data?.title || createOverviewItemDefaultName("data_subject_request")
    });
  };

  const patchItem = async (id: string, data: object) => {
    return await baseController.patchItem(id, data);
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    const response = await baseController.addItem({
      title: data?.title || createOverviewItemDefaultName("data_subject_request")
    });
    goToItem(response.headers["x-resource-id"] || "");
  };

  const exportItems = async (format: string, ids: string[]) => {
    await collectionParams.exportToXLSX(ids);
  };

  const exportAllItems = async (format: string, setup: OverviewSetup) => {
    const data = await baseController.getOverview(setup);
    if (data) {
      const ids = data.allItems.map(({ id }) => id);
      await collectionParams.exportToXLSX(ids);
    }
  };

  return {
    ...baseController,
    getPaginatedOverview,
    goToItem,
    addItem,
    patchItem,
    addItemAndGo,
    exportItems,
    exportAllItems
  };
};

export default DataSubjectRequestOverviewController;
