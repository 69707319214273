import React, { useCallback, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import "date-fns";
import { useTranslation } from "react-i18next";
import { useTom } from "../../../contexts/tom-context";
import { Checkbox, CircularProgress, DialogContent, Grid, Typography } from "@material-ui/core";
import MiniTomDetail from "./MiniTomDetail";
import { v4 } from "uuid";
import { useErrorSnackbar } from "../../../../hook/errorSnackbar";
import CustomAlert from "../../../../components/CustomAlert/CustomAlert";
import { getRisk } from "../../../handlers/risksHandler";
import { TOM_STATUSES } from "../../../handlers/tomHandler";
import { TomModel } from "../../../api/tomApi";

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(1)
  },
  tomModal: {
    margin: "30px 0 0 0"
  },
  container: {
    margin: "16px 0 10px 0"
  },
  saveAsProcessText: {
    wordBreak: "break-word"
  },
  title: {
    marginBottom: 15
  }
}));

export interface CreateEditTomModalProps {
  measureName: string;
  measureId: string;
  setMeasureId: (id: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  updateQuestionnaireMeasure: (data: {
    tomId: string;
    measureName: string;
    measureDescription: string;
    protectionObjective: string[];
  }) => void;
  measureData: {
    measureName?: string;
    measureDescription?: string;
    protectionObjective?: string[];
  };
  mode: "create" | "edit" | "";
  setMode: (mode: "create" | "edit" | "") => void;
  riskId?: string;
}

export default function CreateEditTomModal({
  measureName,
  measureId,
  setMeasureId,
  open,
  setOpen,
  updateQuestionnaireMeasure,
  measureData,
  mode,
  setMode,
  riskId
}: CreateEditTomModalProps) {
  const classes = useStyles();
  const { t } = useTranslation("dpia_four_four_page");
  const { loadTomsHook, updateTomHook, addTomHook, tomInitiated, getTomHook } = useTom();
  const [saveAsProcessSpecific, setSaveAsProcessSpecific] = useState(false);
  const [processSpecific, setProcessSpecific] = useState(false);
  const [tomName, setTomName] = useState("");
  const [description, setDescription] = useState("");
  const [protectionObjective, setProtectionObjective] = useState<string[]>([]);
  const [statusData, setStatusData] = useState<Pick<TomModel, "statusDate" | "status"> | null>(null);
  const [labels, setLabels] = useState<string[]>([]);
  const [initialTomId, setInitialTomId] = useState("");
  const { catchAsSnackbar } = useErrorSnackbar();

  const [risk, setRisk] = useState<{ readonly protectionObjectiveIds?: string[] } | null>(null);
  useEffect(() => {
    if (riskId) {
      getRisk(riskId).then(setRisk);
    }
  }, [riskId]);

  const [pageLoaded, setPageLoaded] = useState(false);
  useEffect(() => {
    if (pageLoaded || !tomInitiated || !open || (riskId && !risk?.protectionObjectiveIds)) {
      return;
    }

    const init = async () => {
      if (mode === "edit" && measureData) {
        setInitialTomId(measureId);
        const tom = await getTomHook(measureId);
        setProcessSpecific(tom?.processSpecific || false);
        setTomName(measureData.measureName || "");
        setDescription(measureData.measureDescription || "");
        setProtectionObjective(measureData.protectionObjective || []);
        setLabels(tom?.labelIds || []);
        setStatusData({
          status: tom?.status || null,
          statusDate: tom?.statusDate || null
        });
      }

      if (mode === "create") {
        const newId = v4(); // we need always an id for the attachments overview
        setMeasureId(newId);
        setInitialTomId(newId);
        setTomName(measureName);
        setDescription("");
        setProtectionObjective(risk?.protectionObjectiveIds || []);
        setLabels([]);
        setStatusData({
          status: TOM_STATUSES.PLANNED,
          statusDate: null
        });
      }

      setPageLoaded(true);
    };

    init();
  }, [
    open,
    tomInitiated,
    riskId,
    pageLoaded,
    measureData,
    measureId,
    measureName,
    mode,
    setMeasureId,
    getTomHook,
    risk?.protectionObjectiveIds
  ]);

  const handleClose = useCallback(() => {
    setSaveAsProcessSpecific(false);
    setOpen(false);
    setMode("");
  }, [setMode, setOpen]);

  const [saving, setSaving] = useState(false);
  const addOrUpdateTom = useCallback(async () => {
    const tomDataToSave = {
      name: tomName,
      description: description,
      labelIds: labels,
      protectionObjectiveIds: protectionObjective,
      status: statusData?.status || null,
      statusDate: statusData?.statusDate || null
    };

    const updateQuestionnaire = (id?: string) => {
      if (updateQuestionnaireMeasure && id) {
        updateQuestionnaireMeasure({
          tomId: id,
          measureName: tomName,
          measureDescription: description,
          protectionObjective: protectionObjective
        });
      }
    };

    setSaving(true);
    try {
      let tomId: string = measureId;
      if (mode === "create" || saveAsProcessSpecific) {
        const createdTOM = await addTomHook(tomName, tomDataToSave, saveAsProcessSpecific);
        tomId = createdTOM.id;
      } else if (mode === "edit") {
        await updateTomHook(tomDataToSave, measureId).catch(catchAsSnackbar("Error updating tom"));
      }
      await loadTomsHook();
      updateQuestionnaire(tomId);
    } catch (error) {
      setSaving(false);
      throw error;
    }
    setSaving(false);
    handleClose();
  }, [
    addTomHook,
    catchAsSnackbar,
    measureId,
    handleClose,
    loadTomsHook,
    mode,
    saveAsProcessSpecific,
    tomName,
    updateTomHook,
    description,
    labels,
    protectionObjective,
    updateQuestionnaireMeasure,
    statusData?.status,
    statusData?.statusDate
  ]);

  const onSaveAsProcessSpecificChanged = useCallback(
    (event, checked) => {
      if (checked === true && mode === "edit") {
        setMeasureId(v4()); // we have to set a new id for the attachment overview if we create a new document
      } else {
        setMeasureId(initialTomId);
      }
      setSaveAsProcessSpecific(checked);
    },
    [initialTomId, mode, setMeasureId]
  );

  if (!pageLoaded) {
    return (
      <Grid container justifyContent="center" spacing={3}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <Dialog open={!!open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
        <DialogContent>
          <div className={classes.title}>
            <Typography variant="h5" component="div">
              {mode === "edit" ? t("edit_measure") : t("create_measure")}
            </Typography>
          </div>
          <CustomAlert severity="info">
            {mode === "create"
              ? t("alert_creating_measure")
              : processSpecific
                ? t("alert_editing_process_specific_measure")
                : t("alert_editing_general_measure")}
          </CustomAlert>
          <MiniTomDetail
            className={classes.tomModal}
            tomId={measureId}
            name={tomName}
            onNameChanged={setTomName}
            description={description}
            onDescriptionChanged={setDescription}
            labels={labels}
            onLabelsChanged={setLabels}
            protectionObjectiveIDs={protectionObjective}
            onProtectionObjectiveIDsChanged={setProtectionObjective}
            status={statusData}
            onStatusChanged={setStatusData}
            explicitRiskId={riskId}
          />
          <Grid
            container
            className={classes.container}
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item xs={6}>
              {(!processSpecific || mode === "create") && (
                <Grid container alignItems="center" justifyContent="flex-start" wrap="nowrap">
                  <Grid item xs={9}>
                    <span className={classes.saveAsProcessText}>{t("checkbox_process_specific")}</span>
                  </Grid>
                  <Grid item xs={3}>
                    <Checkbox
                      checked={saveAsProcessSpecific}
                      onChange={onSaveAsProcessSpecificChanged}
                      inputProps={{ "aria-label": "controlled" }}
                      color="primary"
                      id={"processSpecificCheckbox"}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item zeroMinWidth={true}>
              <Grid container spacing={1}>
                {mode === "edit" && (
                  <Grid item>
                    <Button disabled={saving} variant="contained" color="primary" onClick={addOrUpdateTom}>
                      {saving && <CircularProgress color="inherit" size={14} className={classes.icon} />}
                      {t("save_measure_button")}
                    </Button>
                  </Grid>
                )}
                {mode === "create" && (
                  <Grid item>
                    <Button disabled={saving} variant="contained" color="primary" onClick={addOrUpdateTom}>
                      {saving && <CircularProgress color="inherit" size={14} className={classes.icon} />}
                      {t("questionnaires:add_pg")}
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button disabled={saving} variant="outlined" color="primary" onClick={handleClose}>
                    {t("cancel")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
