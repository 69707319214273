import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@material-ui/core/Box";
import { Editor, EditorProps, EditorState } from "react-draft-wysiwyg";
import { makeStyles } from "@material-ui/core/styles";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import QuestionTitle from "components/QuestionTitle/QuestionTitle";
import PropTypes from "prop-types";
import { convertContentStateFromRawObject, parseContentState } from "./textEditorConverter";
import { convertToRaw, EditorState as DraftJSEditorState } from "draft-js";
import { handleDraftEditorPastedText, onDraftEditorCopy, onDraftEditorCut } from "draftjs-conductor";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  toolbar: {
    borderStyle: "none",
    "& .rdw-link-wrapper": {
      position: "unset"
    },
    "& .rdw-link-modal": {
      top: "45px",
      marginLeft: "60%"
    }
  },
  editor: {
    padding: "0px 14px 18.5px",
    cursor: "auto"
  },
  wrapper: {
    borderStyle: "solid",
    borderRadius: "5px",
    borderWidth: "1.5px"
  },
  maxHeight: {
    maxHeight: "300px",
    overflow: "auto"
  },
  borderColorNoCommentNoFocus: {
    borderColor: theme.palette.grey[400],
    color: (props: any) => (props.disabled ? theme.palette.text.disabled : "inherit")
  },
  borderColorFocus: {
    borderColor: theme.palette.primary.main,
    color: (props: any) => (props.disabled ? theme.palette.text.disabled : "inherit")
  },
  icon: {
    borderStyle: "none"
  },
  blockType: {
    width: 150
  },
  helperText: {
    margin: "3px 14px 0"
  },
  error: {
    borderColor: "#E7211F"
  }
}));

export interface TextEditorProps {
  readonly title: string | null;
  readonly inputValue: string | null;
  readonly className: string;
  readonly toolbarHidden: EditorProps["toolbarHidden"];
  readonly disabled: boolean;
  readonly testId: string;
  readonly focused: boolean;
  readonly titleComponent?: React.ReactNode;
  readonly additionalClasses: Record<string, string>;
  readonly error?: boolean;
  readonly helperText?: string;
  readonly toolbarOptions?: string[];
  readonly placeholder?: string;
  readonly onChange: (text: string) => void;
  readonly onBlur: EditorProps["onBlur"];
  readonly onFocus: EditorProps["onFocus"];
}

const TextEditor = ({
  focused,
  className,
  inputValue,
  title,
  titleComponent,
  toolbarHidden,
  disabled,
  testId,
  error,
  helperText,
  additionalClasses,
  toolbarOptions,
  placeholder,
  onFocus,
  onBlur,
  onChange
}: TextEditorProps) => {
  const styles = useStyles({ disabled });
  const cls = {
    ...styles,
    ...additionalClasses
  };
  const borderStyle = () => {
    if (className) {
      return className;
    }
    if (focused) {
      return cls.borderColorFocus;
    } else {
      return cls.borderColorNoCommentNoFocus;
    }
  };
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<{ readonly locale?: string }>({});
  const [editorState, setEditorState] = useState<EditorState>();
  const [lastChangedInputValue, setLastChangedInputValue] = useState<string | null>(null);

  const updateEditorState = useCallback(value => {
    setEditorState(value);
    const valueCurrentContent = value.getCurrentContent();
    const editorRawContent = convertToRaw(valueCurrentContent);
    const lastChangedInputValue = JSON.stringify(editorRawContent);
    setLastChangedInputValue(lastChangedInputValue);
    return lastChangedInputValue;
  }, []);

  const onEditorStateChange = useCallback(
    value => {
      const updatedValue = updateEditorState(value);
      onChange(updatedValue);
    },
    [onChange, updateEditorState]
  );

  useEffect(() => {
    const updateEditorWithInputValue = () => {
      if (!inputValue && !lastChangedInputValue) {
        return;
      }
      if (inputValue === lastChangedInputValue) {
        return;
      }

      const newRawContent = parseContentState(inputValue || "");
      const convertedState = convertContentStateFromRawObject(newRawContent);
      const newEditorState = DraftJSEditorState.createWithContent(convertedState);
      const newEditorWithCursorAtTheEnd = DraftJSEditorState.moveSelectionToEnd(newEditorState); // set cursor/selector at the end of the text
      updateEditorState(newEditorWithCursorAtTheEnd);
    };

    updateEditorWithInputValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, updateEditorState]); // we only want to trigger this based on input value change

  useEffect(() => {
    const supportedLanguages = [
      "en",
      "fr",
      "zh",
      "ru",
      "pt",
      "ko",
      "it",
      "nl",
      "de",
      "da",
      "zh_tw",
      "pl",
      "es",
      "fi",
      "hu",
      "no",
      "ro",
      "sk",
      "sl",
      "sv"
    ];
    const language = i18n.language.split("-")[0];
    setLanguage({ locale: supportedLanguages.includes(language) ? language : "en" });
  }, [i18n.language]);

  const toolbar = useMemo(
    () => ({
      options: toolbarOptions || ["inline", "blockType", "fontSize", "colorPicker", "emoji", "list", "link"],
      inline: {
        options: ["bold", "underline", "italic", "strikethrough"],
        bold: { className: cls.icon },
        italic: { className: cls.icon },
        underline: { className: cls.icon },
        strikethrough: { className: cls.icon }
      },
      blockType: {
        inDropdown: true,
        options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote", "Code"],
        className: cls.blockType
      },
      colorPicker: { className: cls.icon },
      emoji: { className: cls.icon },
      list: {
        unordered: { className: cls.icon },
        ordered: { className: cls.icon },
        indent: { className: cls.icon },
        outdent: { className: cls.icon }
      },
      link: {
        link: { className: cls.icon },
        unlink: { className: cls.icon }
      }
    }),
    [toolbarOptions, cls.icon, cls.blockType]
  );

  const handlePastedText = useCallback(
    (text, html, editorState) => {
      const newEditorState = handleDraftEditorPastedText(html, editorState);
      if (newEditorState) {
        const updatedValue = updateEditorState(newEditorState);
        onChange(updatedValue);
        return true;
      }
      return false;
    },
    [onChange, updateEditorState]
  );

  return (
    <>
      {titleComponent}
      {!titleComponent && title && <QuestionTitle disabled={disabled}>{title}</QuestionTitle>}
      <Box mt={1} data-testid={testId} style={inlineStylePositionRelative}>
        <Editor
          readOnly={disabled}
          toolbarHidden={toolbarHidden || disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onCopy={onDraftEditorCopy}
          onCut={onDraftEditorCut}
          handlePastedText={handlePastedText}
          toolbarClassName={cls.toolbar}
          editorClassName={cls.editor}
          wrapperClassName={`${cls.wrapper} ${borderStyle()} ${!disabled ? cls.maxHeight : ""} ${
            error ? cls.error : ""
          }`}
          localization={language}
          toolbar={toolbar}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          placeholder={placeholder}
        />
      </Box>
      {helperText && (
        <Box className={cls.helperText}>
          <Typography variant="caption" color={error ? "secondary" : "primary"}>
            {helperText}
          </Typography>
        </Box>
      )}
    </>
  );
};

// declare inline style outside so that it's not a new object with re-render every time
const inlineStylePositionRelative = {
  position: "relative"
} as const;

TextEditor.propTypes = {
  title: PropTypes.string,
  inputValue: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  toolbarHidden: PropTypes.bool,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  focused: PropTypes.bool,
  titleComponent: PropTypes.element,
  additionalClasses: PropTypes.object
};

TextEditor.defaultProps = {
  title: null,
  inputValue: null,
  className: "",
  onChange: () => {
    /* empty */
  },
  onBlur: () => {
    /* empty */
  },
  onFocus: () => {
    /* empty */
  },
  toolbarHidden: false,
  disabled: false,
  testId: "textEditor",
  additionalClasses: {},
  focused: false
};

export default TextEditor;
