import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, makeStyles, TextField } from "@material-ui/core";
import AccordionMultiField from "components/AccordionMultiField/AccordionMultiField";
import { DataBreachesPageButtons, DataBreachesPageStepper } from "./DataBreachesPagination";
import {
  downloadDataBreachNotificationWordDocument,
  getControllerByDataBreachId,
  getDataBreachById,
  getDPAByDataBreachId,
  getDPOByDataBreachId,
  updateController,
  updateDPA,
  updateDPO
} from "app/handlers/dataBreachHandler";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import DocMetaView from "components/DocMetaView/DocMetaView";
import DocView from "components/DocView/DocView";
import DocumentNotFound from "app/pages/shared/DocumentNotFound/DocumentNotFound";
import Question from "components/Question/Question";
import { getResourceTreeRelatedToDataTypeIds } from "./dataBreachesUtils";
import { shouldShowEmailInputError, shouldShowPhoneInputError } from "../../utils/emailFormatter";
import { tSimpleTranslate } from "../../handlers/dataTypeTranslatorHandler";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useMetaView } from "app/contexts/meta-view-context";
import { COLLECTIONS } from "app/collections";
import { DataBreachesMetaView } from "./DataBreachesMetaView";
import { getAllResources, RESOURCE_TYPES, translateResourceById } from "../../handlers/resourceHandler";
import { useDataTypeTree } from "app/api/dataAssetApi";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { exportDataBreachesExcel } from "app/export/createdExcelExportData";
import { useUserDepartments } from "../../contexts/department-context";

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: "30px",
    marginTop: "20px"
  },
  subHeader: {
    marginBottom: "30px",
    marginTop: "40px"
  },
  accordion: {
    margin: "0px 0px 15px 0px",
    border: "1px solid " + theme.palette.grey[400],
    borderRadius: "5px"
  },
  loadingIcon: {
    marginLeft: "5px"
  }
}));

export default function DataBreachesAuthorityNotificationPage({ documentId }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation("data_breaches_authority_notification_page");
  const { setInfo } = useMetaView();
  const [loadingNotificationDocument, setLoadingNotificationDocument] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  // can be deleted when default values are created with creation of Service Provider
  const [accordionLoaded, setAccordionsLoaded] = useState(false);
  const [documentNotFound, setDocumentNotFound] = useState(false);
  const [dpaAccordion, setDpaAccordion] = useState({});
  const [controllerAccordion, setControllerAccordion] = useState({});
  const [dpoAccordion, setDpoAccordion] = useState({});
  const [dpaData, setDpaData] = useState({});
  const [dpoData, setDpoData] = useState({});
  const [controllerData, setControllerData] = useState({});
  const [dataBreach, setDataBreach] = useState();
  const { isPartOfUserDepartments } = useUserDepartments();
  const { auth } = useAuthentication();

  const dataTypeTree = useDataTypeTree();
  const dataTypeCategoryPersonGroupList = dataTypeTree.data;

  const loadDataBreacheData = async function () {
    const dataBreach = await getDataBreachById(documentId);
    if (!dataBreach) {
      setDocumentNotFound(true);
      return;
    }
    const controller = await getControllerByDataBreachId(documentId);
    const dpa = await getDPAByDataBreachId(documentId);
    const dpo = await getDPOByDataBreachId(documentId);
    if (!dpa || !dpo || !controller) {
      setDocumentNotFound(true);
      return;
    }
    setControllerAccordion(controller);
    setDpaAccordion(dpa);
    setDpoAccordion(dpo);

    setDataBreach(dataBreach);
    setControllerData(controller);
    setDpaData(dpa);
    setDpoData(dpo);

    setAccordionsLoaded(true);
  };

  const noWritePermission = useMemo(() => {
    if (!dataBreach?.orgUnitId) return false;
    if (
      dataBreach?.assigneeUID === auth.uid ||
      dataBreach?.dpoUID === auth.uid ||
      auth?.permissions.includes("db_write_all")
    )
      return false;
    if (isPartOfUserDepartments(...[dataBreach.orgUnitId])) return false;
    return true;
  }, [dataBreach, isPartOfUserDepartments, auth.uid]);

  const filterUpdatedData = function (payload, baseData) {
    const payloadUpdated = {};
    Object.entries(payload).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (baseData[key] !== value) {
        payloadUpdated[key] = value;
      }
    });
    return payloadUpdated;
  };

  const exportToXLSX = async ids => {
    setLoadingExport(true);
    if (auth?.tenantId) {
      const report = await exportDataBreachesExcel(auth?.tenantId, auth?.uid, t, [documentId]);
      setLoadingExport(false);
      return report;
    }
  };

  const updateControllerAndReloadAnswers = async function (dataBreachId, payload) {
    //check for difference
    const controllerId = controllerData.id;
    const payloadUpdated = filterUpdatedData(payload, controllerData);
    const updatedAnswers = await updateController(dataBreachId, controllerId, payloadUpdated);
    setControllerAccordion(updatedAnswers);
  };

  const updateDPOAndReloadAnswers = async function (dataBreachId, payload) {
    //check for difference
    const dpoId = dpoData.id;
    const payloadUpdated = filterUpdatedData(payload, dpoData);
    const updatedAnswers = await updateDPO(dataBreachId, dpoId, payloadUpdated);
    setDpoAccordion(updatedAnswers);
  };

  const updateDPAAndReloadAnswers = async function (dataBreachId, payload) {
    //check for difference
    const dpaId = dpaData.id;
    const payloadUpdated = filterUpdatedData(payload, dpaData);
    const updatedAnswers = await updateDPA(dataBreachId, dpaId, payloadUpdated);
    setDpaAccordion(updatedAnswers);
  };

  useEffect(() => {
    loadDataBreacheData();
  }, []);

  useEffect(() => {
    setInfo({
      title: t("enteringInfoCardTitle"),
      text: t("enteringInfoCardText")
    });
  }, [i18n.language, setInfo, t]);

  const getResetAccordion = (accordion, baseData) => {
    let resetAccordion = {};
    Object.keys(accordion).forEach(key => {
      resetAccordion[key] = baseData[key];
    });
    return resetAccordion;
  };

  const downloadDataBreachDoc = async () => {
    const resourceTree = getResourceTreeRelatedToDataTypeIds(
      dataBreach.dataTypeIds,
      dataTypeCategoryPersonGroupList,
      key => tSimpleTranslate({ t, typeCategoryOrPersonGroup: key })
    );

    try {
      setLoadingNotificationDocument(true);
      await downloadDataBreachNotificationWordDocument(
        dataBreach.id,
        resourceTree,
        translateResourceById({
          t,
          resourceType: RESOURCE_TYPES.DATABREACH_DETECTION,
          resourceId: dataBreach.detectionType,
          resources: await getAllResources({ showMerged: true })
        }),
        i18n.language
      );
    } finally {
      setLoadingNotificationDocument(false);
    }
  };

  // content for doc view
  const docViewContent = accordionLoaded === true && (
    <DocView header={dataBreach?.title || ""} pagination={<DataBreachesPageStepper />}>
      <div className={classes.header}>
        <QuestionnaireSubHeader text={t("contact_information")} />
      </div>
      {accordionLoaded === true && (
        <>
          <Question questionId={"dpa"} questionName={t("data_protection_authority")} disabled={noWritePermission}>
            <AccordionMultiField
              id={"dpa-accordion-multifield"}
              onFocus={() => {}}
              index={0}
              titleType={"text"}
              cancelButtonText={t("questionnaires:cancel")}
              saveButtonText={t("questionnaires:save")}
              onClickSave={() => updateDPAAndReloadAnswers(documentId, dpaAccordion)}
              onClickCancel={() => {
                const resetAccordion = getResetAccordion(dpaAccordion, dpaData);
                setDpaAccordion(resetAccordion);
              }}
              disableButton={noWritePermission}
              title={t("data_protection_authority")}
              hasDelete={false}
              getStyle={classes.accordion}
            >
              <Question>
                <TextField
                  id={"dpa_name"}
                  inputProps={{ "data-testid": "dpa_name" }}
                  label={t("dpa_name")}
                  value={dpaAccordion.name || ""}
                  onChange={event => {
                    setDpaAccordion({ ...dpaAccordion, name: event.target.value });
                  }}
                  variant="outlined"
                  disabled={noWritePermission}
                  fullWidth
                />
              </Question>
              <Question>
                <TextField
                  id={"dpa_street"}
                  label={t("street")}
                  inputProps={{ "data-testid": "dpa_street" }}
                  value={dpaAccordion.street || ""}
                  onChange={event => {
                    setDpaAccordion({ ...dpaAccordion, street: event.target.value });
                  }}
                  variant="outlined"
                  disabled={noWritePermission}
                  fullWidth
                />
              </Question>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Question>
                    <TextField
                      id={"dpa_ZIP"}
                      label={t("ZIP")}
                      value={dpaAccordion.zip || ""}
                      onChange={event => {
                        setDpaAccordion({ ...dpaAccordion, zip: event.target.value });
                      }}
                      variant="outlined"
                      disabled={noWritePermission}
                      fullWidth
                    />
                  </Question>
                </Grid>
                <Grid item xs={6}>
                  <Question>
                    <TextField
                      id={"dpa_city"}
                      label={t("city")}
                      value={dpaAccordion.city || ""}
                      onChange={event => {
                        setDpaAccordion({ ...dpaAccordion, city: event.target.value });
                      }}
                      variant="outlined"
                      disabled={noWritePermission}
                      fullWidth
                    />
                  </Question>
                </Grid>
              </Grid>
            </AccordionMultiField>
          </Question>
          <Question questionId={"controller"} questionName={t("controller")} disabled={noWritePermission}>
            <AccordionMultiField
              id={"controller-accordion-multifield"}
              onFocus={() => {}}
              index={1}
              titleType={"text"}
              cancelButtonText={t("questionnaires:cancel")}
              saveButtonText={t("questionnaires:save")}
              onClickSave={() => updateControllerAndReloadAnswers(documentId, controllerAccordion)}
              onClickCancel={() => {
                const resetAccordion = getResetAccordion(controllerAccordion, controllerData);
                setDpaAccordion(resetAccordion);
              }}
              title={t("controller")}
              hasDelete={false}
              getStyle={classes.accordion}
              disableButton={
                noWritePermission ||
                shouldShowEmailInputError(controllerAccordion?.email) ||
                shouldShowPhoneInputError(controllerAccordion?.phone)
              }
            >
              <Question>
                <TextField
                  id={"controller_name"}
                  inputProps={{ "data-testid": "controller_name" }}
                  label={t("name")}
                  value={controllerAccordion.name || ""}
                  onChange={event => {
                    setControllerAccordion({ ...controllerAccordion, name: event.target.value });
                  }}
                  variant="outlined"
                  disabled={noWritePermission}
                  fullWidth
                />
              </Question>
              <Question>
                <TextField
                  id={"controller_company_name"}
                  inputProps={{ "data-testid": "controller_company_name" }}
                  label={t("company_name")}
                  value={controllerAccordion.companyName || ""}
                  onChange={event => {
                    setControllerAccordion({ ...controllerAccordion, companyName: event.target.value });
                  }}
                  variant="outlined"
                  disabled={noWritePermission}
                  fullWidth
                />
              </Question>
              <Question>
                <TextField
                  id={"controller_street"}
                  inputProps={{ "data-testid": "controller_street" }}
                  label={t("street")}
                  value={controllerAccordion.street || ""}
                  onChange={event => {
                    setControllerAccordion({ ...controllerAccordion, street: event.target.value });
                  }}
                  variant="outlined"
                  disabled={noWritePermission}
                  fullWidth
                />
              </Question>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Question>
                    <TextField
                      id={"controller_ZIP"}
                      label={t("ZIP")}
                      value={controllerAccordion.zip || ""}
                      onChange={event => {
                        setControllerAccordion({ ...controllerAccordion, zip: event.target.value });
                      }}
                      variant="outlined"
                      disabled={noWritePermission}
                      fullWidth
                    />
                  </Question>
                </Grid>
                <Grid item xs={6}>
                  <Question>
                    <TextField
                      id={"controller_city"}
                      label={t("city")}
                      value={controllerAccordion.city || ""}
                      onChange={event => {
                        setControllerAccordion({ ...controllerAccordion, city: event.target.value });
                      }}
                      variant="outlined"
                      disabled={noWritePermission}
                      fullWidth
                    />
                  </Question>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Question>
                    <TextField
                      id={"controller_phone"}
                      label={t("phone")}
                      value={controllerAccordion.phone || ""}
                      onChange={event => {
                        setControllerAccordion({ ...controllerAccordion, phone: event.target.value });
                      }}
                      error={shouldShowPhoneInputError(controllerAccordion?.phone)}
                      helperText={shouldShowPhoneInputError(controllerAccordion?.phone) && t("phoneBadFormat")}
                      variant="outlined"
                      disabled={noWritePermission}
                      fullWidth
                    />
                  </Question>
                </Grid>
                <Grid item xs={6}>
                  <Question>
                    <TextField
                      id={"controller_fax"}
                      label={t("fax")}
                      value={controllerAccordion.fax || ""}
                      onChange={event => {
                        setControllerAccordion({ ...controllerAccordion, fax: event.target.value });
                      }}
                      variant="outlined"
                      disabled={noWritePermission}
                      fullWidth
                    />
                  </Question>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Question>
                    <TextField
                      id={"controller_email"}
                      label={t("email")}
                      value={controllerAccordion.email || ""}
                      onChange={event => {
                        setControllerAccordion({ ...controllerAccordion, email: event.target.value });
                      }}
                      error={shouldShowEmailInputError(controllerAccordion?.email)}
                      helperText={shouldShowEmailInputError(controllerAccordion?.email) && t("emailBadFormat")}
                      variant="outlined"
                      disabled={noWritePermission}
                      fullWidth
                    />
                  </Question>
                </Grid>
                <Grid item xs={6}>
                  <Question>
                    <TextField
                      id={"controller_website"}
                      label={t("website")}
                      value={controllerAccordion.website || ""}
                      onChange={event => {
                        setControllerAccordion({ ...controllerAccordion, website: event.target.value });
                      }}
                      variant="outlined"
                      disabled={noWritePermission}
                      fullWidth
                    />
                  </Question>
                </Grid>
              </Grid>
            </AccordionMultiField>
          </Question>
          <Question questionId={"dpo"} questionName={t("data_protection_officer")} disabled={noWritePermission}>
            <AccordionMultiField
              id={"dpo-accordion-multifield"}
              onFocus={() => {}}
              index={2}
              titleType={"text"}
              cancelButtonText={t("questionnaires:cancel")}
              saveButtonText={t("questionnaires:save")}
              onClickSave={() => updateDPOAndReloadAnswers(documentId, dpoAccordion)}
              onClickCancel={() => {
                const resetAccordion = getResetAccordion(dpoAccordion, dpaData);
                setDpaAccordion(resetAccordion);
              }}
              title={t("data_protection_officer")}
              hasDelete={false}
              getStyle={classes.accordion}
              disableButton={
                noWritePermission ||
                shouldShowEmailInputError(dpoAccordion?.email) ||
                shouldShowPhoneInputError(dpoAccordion?.phone)
              }
            >
              <Question>
                <div className={classes.textArea}>
                  <TextField
                    id={"dpo_name"}
                    inputProps={{ "data-testid": "dpo_name" }}
                    label={t("name")}
                    value={dpoAccordion.name || ""}
                    onChange={event => {
                      setDpoAccordion({ ...dpoAccordion, name: event.target.value });
                    }}
                    variant="outlined"
                    disabled={noWritePermission}
                    fullWidth
                  />
                </div>
              </Question>
              <Question>
                <div className={classes.textArea}>
                  <TextField
                    id={"dpo_company_name"}
                    inputProps={{ "data-testid": "dpo_company_name" }}
                    label={t("company_name")}
                    value={dpoAccordion.companyName || ""}
                    onChange={event => {
                      setDpoAccordion({ ...dpoAccordion, companyName: event.target.value });
                    }}
                    variant="outlined"
                    disabled={noWritePermission}
                    fullWidth
                  />
                </div>
              </Question>
              <Question>
                <div className={classes.textArea}>
                  <TextField
                    id={"dpo_street"}
                    inputProps={{ "data-testid": "dpo_street" }}
                    label={t("street")}
                    value={dpoAccordion.street || ""}
                    onChange={event => {
                      setDpoAccordion({ ...dpoAccordion, street: event.target.value });
                    }}
                    variant="outlined"
                    disabled={noWritePermission}
                    fullWidth
                  />
                </div>
              </Question>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Question>
                    <TextField
                      label={t("ZIP")}
                      value={dpoAccordion.zip || ""}
                      onChange={event => {
                        setDpoAccordion({ ...dpoAccordion, zip: event.target.value });
                      }}
                      variant="outlined"
                      disabled={noWritePermission}
                      fullWidth
                    />
                  </Question>
                </Grid>
                <Grid item xs={6}>
                  <Question>
                    <TextField
                      id={"dpo_city"}
                      label={t("city")}
                      value={dpoAccordion.city || ""}
                      onChange={event => {
                        setDpoAccordion({ ...dpoAccordion, city: event.target.value });
                      }}
                      variant="outlined"
                      disabled={noWritePermission}
                      fullWidth
                    />
                  </Question>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Question>
                    <TextField
                      id={"dpo_phone"}
                      label={t("phone")}
                      value={dpoAccordion.phone || ""}
                      onChange={event => {
                        setDpoAccordion({ ...dpoAccordion, phone: event.target.value });
                      }}
                      error={shouldShowPhoneInputError(dpoAccordion?.phone)}
                      helperText={shouldShowPhoneInputError(dpoAccordion?.phone) && t("phoneBadFormat")}
                      variant="outlined"
                      disabled={noWritePermission}
                      fullWidth
                    />
                  </Question>
                </Grid>
                <Grid item xs={6}>
                  <Question>
                    <TextField
                      id={"dpo_fax"}
                      label={t("fax")}
                      value={dpoAccordion.fax || ""}
                      onChange={event => {
                        setDpoAccordion({ ...dpoAccordion, fax: event.target.value });
                      }}
                      variant="outlined"
                      disabled={noWritePermission}
                      fullWidth
                    />
                  </Question>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Question>
                    <TextField
                      id={"dpo_email"}
                      label={t("email")}
                      value={dpoAccordion.email || ""}
                      onChange={event => {
                        setDpoAccordion({ ...dpoAccordion, email: event.target.value });
                      }}
                      error={shouldShowEmailInputError(dpoAccordion?.email)}
                      helperText={shouldShowEmailInputError(dpoAccordion?.email) && t("emailBadFormat")}
                      variant="outlined"
                      disabled={noWritePermission}
                      fullWidth
                    />
                  </Question>
                </Grid>
                <Grid xs={6} item>
                  <Question>
                    <TextField
                      id={"dpo_website"}
                      label={t("website")}
                      value={dpoAccordion.website || ""}
                      onChange={event => {
                        setDpoAccordion({ ...dpoAccordion, website: event.target.value });
                      }}
                      variant="outlined"
                      disabled={noWritePermission}
                      fullWidth
                    />
                  </Question>
                </Grid>
              </Grid>
            </AccordionMultiField>
          </Question>
          <QuestionnaireSubHeader text={t("export")} />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 4, width: 250 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={downloadDataBreachDoc}
              disabled={loadingNotificationDocument}
              sx={{ paddingX: -2 }}
            >
              {t("data_breaches_authority_notification_page:download_word")}
              {loadingNotificationDocument && (
                <CircularProgress color="inherit" size={14} className={classes.loadingIcon} />
              )}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={exportToXLSX}
              disabled={loadingExport}
              sx={{ paddingX: 2 }}
            >
              {t("data_breaches_authority_notification_page:download_excel")}
              {loadingExport && <CircularProgress color="inherit" size={14} className={classes.loadingIcon} />}
            </Button>
          </Box>
          <DataBreachesPageButtons />
        </>
      )}
    </DocView>
  );

  if (documentNotFound) {
    return <DocumentNotFound collection={COLLECTIONS.DATA_BREACHES} />;
  } else {
    return (
      <DocMetaView
        docViewContent={docViewContent}
        metaViewContent={<DataBreachesMetaView docName={dataBreach?.title} />}
      />
    );
  }
}
