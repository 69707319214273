import { useTranslation } from "react-i18next";
import { useTom } from "../../../contexts/tom-context";
import { useErrorSnackbar } from "../../../../hook/errorSnackbar";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { MeasureIDAccordions } from "../../../../components/Measures/MeasureIDAccordions";
import Button from "@material-ui/core/Button";
import { useRisk } from "../../../contexts/risk-context";
import { measuresAvailableForRisks } from "../../../handlers/risksHandler";

export const RiskImplementedMeasureIDs = () => {
  const { t } = useTranslation();
  const { risk, updateBasicInfoHook, riskId } = useRisk();
  const { catchAsSnackbar } = useErrorSnackbar();

  const updateImplementedMeasureIds = useCallback(
    measureIds => {
      return updateBasicInfoHook({ implementedMeasureIds: measureIds }).catch(
        catchAsSnackbar("failed to update measures")
      );
    },
    [updateBasicInfoHook, catchAsSnackbar]
  );

  const { riskMeasureInitialized, suggestedMeasureIds, assessmentMeasureGrouping, riskMeasuresGroupsOrder } =
    useRiskMeasures();

  const [updatingMeasure, setUpdatingMeasure] = useState(false);
  const autoAddLinkedMeasured = useCallback(async () => {
    const measureIds = [...risk.implementedMeasureIds, ...suggestedMeasureIds];

    setUpdatingMeasure(true);
    try {
      await updateImplementedMeasureIds(measureIds);
      setUpdatingMeasure(false);
    } catch (error) {
      setUpdatingMeasure(false);
    }
  }, [suggestedMeasureIds, updateImplementedMeasureIds, risk.implementedMeasureIds]);

  return (
    <>
      {updatingMeasure && <CircularProgress />}
      {!updatingMeasure && (
        <MeasureIDAccordions
          measureIDs={risk.implementedMeasureIds}
          onMeasureIDChange={updateImplementedMeasureIds}
          whitelistedTOMIds={suggestedMeasureIds}
          whitelistTooltip={t("risk_first_assessment_page:addMeasureTooltip")}
          optionalRiskIdForNewTOM={riskId}
          customMeasureGroupingFn={assessmentMeasureGrouping}
          customMeasureGroupsOrder={riskMeasuresGroupsOrder}
          additionalAction={
            suggestedMeasureIds.length > 0 && (
              <Tooltip title={t("risk_first_assessment_page:autoAddMeasuresTooltip")}>
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={autoAddLinkedMeasured}
                    disabled={!riskMeasureInitialized}
                  >
                    {t("risk_first_assessment_page:autoAddMeasures")}
                  </Button>
                </span>
              </Tooltip>
            )
          }
        />
      )}
    </>
  );
};

export const useRiskMeasures = () => {
  const { t } = useTranslation();
  const { risk, initialized: riskInitialized } = useRisk();
  const { toms, tomInitiated: tomInitialized } = useTom();
  const [riskMeasureInitialized, setRiskMeasureInitialized] = useState(false);
  useEffect(() => {
    if (riskMeasureInitialized) {
      return;
    }

    setRiskMeasureInitialized(riskInitialized && tomInitialized);
  }, [riskInitialized, tomInitialized, riskMeasureInitialized]);

  const [measureIdsWithCommonProtectionObjective, setMeasureIdsWithCommonProtectionObjective] = useState([]);
  useEffect(() => {
    const measureIdsLinkedToProtectionObjectives = toms
      .filter(tom => measuresAvailableForRisks(tom, { protectionObjectiveIds: risk.protectionObjectiveIds }))
      .map(tom => tom.id);

    setMeasureIdsWithCommonProtectionObjective(measureIdsLinkedToProtectionObjectives);
  }, [toms, risk.protectionObjectiveIds]);

  const suggestedMeasureIds = useMemo(() => {
    return measureIdsWithCommonProtectionObjective.filter(measureId => !risk.implementedMeasureIds.includes(measureId));
  }, [risk.implementedMeasureIds, measureIdsWithCommonProtectionObjective]);

  const assessmentMeasureGrouping = useCallback(
    measureId => {
      if (!measureIdsWithCommonProtectionObjective.includes(measureId)) {
        return t("risk_first_assessment_page:noCommonProtectionObjectives");
      }

      const measure = toms.find(tom => tom.id === measureId);
      if (!measure) {
        return "";
      }

      if (measure.processSpecific) {
        return t("tom_measures_overview:tab_process_specific");
      }

      return t("tom_measures_overview:tab_general");
    },
    [toms, t, measureIdsWithCommonProtectionObjective]
  );

  const treatmentMeasureGrouping = useCallback(
    measureId => {
      if ((risk?.implementedMeasureIds || []).includes(measureId)) {
        return t("risk_first_assessment_page:alreadyImplementedMeasures");
      }
      return assessmentMeasureGrouping(measureId);
    },
    [assessmentMeasureGrouping, t, risk?.implementedMeasureIds]
  );

  const riskMeasuresGroupsOrder = useMemo(() => {
    return [
      t("tom_measures_overview:tab_general"),
      t("tom_measures_overview:tab_process_specific"),
      t("risk_first_assessment_page:alreadyImplementedMeasures"),
      t("risk_first_assessment_page:noCommonProtectionObjectives")
    ];
  }, [t]);

  return {
    riskMeasureInitialized,
    measureIdsWithCommonProtectionObjective,
    suggestedMeasureIds,
    riskMeasuresGroupsOrder,
    assessmentMeasureGrouping,
    treatmentMeasureGrouping
  };
};
