import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useErrorSnackbar } from "../../../../hook/errorSnackbar";
import React, { useCallback, useEffect, useState } from "react";
import {
  getRisksUsingTomProtectionObjectives,
  ProtectionObjectiveUsedInRiskError,
  updateTOMProtectionObjectiveIds
} from "../../../handlers/tomHandler";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTom } from "../../../contexts/tom-context";
import { ResourceField } from "../../../../components/ResourceField";
import { RESOURCE_TYPES } from "../../../handlers/resourceHandler";
import { useUserRisks } from "../../../../hook/useUserRisks";

export const TomProtectionObjective = ({
  tomId,
  initialProtectionObjectiveIds,
  onProtectionObjectiveIdsChanged,
  explicitRiskId,
  onFocus
}) => {
  const { t } = useTranslation("tom_description_tab");

  const { enqueueSnackbar } = useSnackbar();
  const { catchAsSnackbar } = useErrorSnackbar();
  const [protectionObjectiveIds, setProtectionObjectiveIds] = useState(initialProtectionObjectiveIds);

  const { risks, risksLoaded, reloadRisks } = useUserRisks();

  const updateProtectionObjectiveIds = useCallback(
    async updatedProtectionObjectiveIds => {
      setProtectionObjectiveIds(updatedProtectionObjectiveIds);

      try {
        await onProtectionObjectiveIdsChanged(updatedProtectionObjectiveIds);
      } catch (error) {
        if (error instanceof ProtectionObjectiveUsedInRiskError) {
          enqueueSnackbar("Protection objective is used in risks", { variant: "error" });
          reloadRisks().catch(catchAsSnackbar("failed to reload risks"));
          return;
        }
        throw error;
      }
    },
    [enqueueSnackbar, onProtectionObjectiveIdsChanged, reloadRisks, catchAsSnackbar]
  );

  const isProtectionObjectiveStillUsed = useCallback(
    protectionObjectiveId => {
      const usedRisks = getRisksUsingTomProtectionObjectives(tomId, protectionObjectiveId, risks, explicitRiskId);
      return !!usedRisks?.length;
    },
    [risks, tomId, explicitRiskId]
  );

  const getProtectionObjectiveNotDeletableTooltip = useCallback(
    protectionObjectiveId => {
      const usedRisks = getRisksUsingTomProtectionObjectives(tomId, protectionObjectiveId, risks, explicitRiskId);
      return `${t("stillUsedInRisks")}:\n${usedRisks.map(risk => `- ${risk.title}`).join("\n")}`;
    },
    [tomId, risks, t, explicitRiskId]
  );

  if (!risksLoaded) {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <ResourceField
      label={t("protectionObjective")}
      resourceType={RESOURCE_TYPES.PROTECTION_OBJECTIVE}
      value={protectionObjectiveIds}
      onChange={updateProtectionObjectiveIds}
      onFocus={onFocus}
      isNotDeletable={isProtectionObjectiveStillUsed}
      notDeletableTooltip={getProtectionObjectiveNotDeletableTooltip}
      allowAdd={true}
    />
  );
};

TomProtectionObjective.propTypes = {
  tomId: PropTypes.string.isRequired,
  initialProtectionObjectiveIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onProtectionObjectiveIdsChanged: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  textFieldNotchedOutlineClassName: PropTypes.string,
  onChangeSaved: PropTypes.func,
  explicitRiskId: PropTypes.string
};

TomProtectionObjective.defaultProps = {
  textFieldNotchedOutlineClassName: "",
  onChangeSaved: () => {}
};

export const TomProtectionObjectiveAutoSave = ({ tomId, onFocus, textFieldNotchedOutlineClassName, onChangeSaved }) => {
  const {
    auth: { tenantId }
  } = useAuthentication();
  const { catchAsSnackbar } = useErrorSnackbar();
  const { tomInitiated, getTomHook } = useTom();
  const [tomLoaded, setTomLoaded] = useState(false);
  const [protectionObjectiveIds, setProtectionObjectiveIds] = useState(null);
  useEffect(() => {
    if (!tomInitiated) {
      return;
    }

    const loadTom = async () => {
      const tom = await getTomHook(tomId);
      setProtectionObjectiveIds(tom.protectionObjectiveIds || []);
      setTomLoaded(true);
    };

    loadTom().catch(catchAsSnackbar("failed to load tom protection objectives"));
  }, [tenantId, catchAsSnackbar, tomId, tomInitiated, getTomHook]);

  const saveProtectionObjectiveIds = useCallback(
    async protectionObjectiveIds => {
      await updateTOMProtectionObjectiveIds(tomId, protectionObjectiveIds);
      onChangeSaved(protectionObjectiveIds);
    },
    [tenantId, tomId, onChangeSaved]
  );

  if (!tomLoaded) {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <TomProtectionObjective
      tomId={tomId}
      initialProtectionObjectiveIds={protectionObjectiveIds}
      onProtectionObjectiveIdsChanged={saveProtectionObjectiveIds}
      onFocus={onFocus}
      onChangeSaved={onChangeSaved}
      textFieldNotchedOutlineClassName={textFieldNotchedOutlineClassName}
    />
  );
};

TomProtectionObjectiveAutoSave.propTypes = {
  tomId: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  textFieldNotchedOutlineClassName: PropTypes.string,
  onChangeSaved: PropTypes.func
};

TomProtectionObjectiveAutoSave.defaultProps = {
  textFieldNotchedOutlineClassName: "",
  onChangeSaved: () => {}
};
