import { TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { QuestionProps } from "../../Question";

const TextAreaQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onFocus,
  onBlur,
  onChange
}: QuestionProps) => {
  const [_value, _setValue] = useState<string | undefined>("");
  useEffect(() => {
    return _setValue((Array.isArray(value) ? value[0] : value) as string);
  }, [value]);
  const onChangeCallback = useCallback((e: any) => {
    _setValue(e.target.value);
  }, []);
  const onBlurCallback = useCallback(() => {
    onChange?.(_value);
    onBlur?.();
  }, [_value, onBlur, onChange]);
  return (
    <TextField
      fullWidth
      maxRows={1000}
      minRows={1}
      multiline
      value={_value}
      onChange={onChangeCallback}
      variant="outlined"
      onFocus={onFocus}
      onBlur={onBlurCallback}
      label={questionName}
      InputLabelProps={inputLabelProps}
      disabled={disabled}
      error={error}
      helperText={helperText}
    />
  );
};

const inputLabelProps = {};

export default React.memo(TextAreaQuestion);
