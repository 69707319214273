import React from "react";
import LabelIcon from "../assets/images/icons/labels.svg";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { RESOURCE_TYPES } from "../app/handlers/resourceHandler";
import { ResourceField } from "./ResourceField";

export const LabelField = ({
  onFocus,
  onBlur,
  selectedIDs,
  onSelectionChanged,
  disabled,
  orgUnitIds,
  error,
  helperText
}: {
  onFocus?: () => void;
  onBlur?: () => void;
  selectedIDs: string[];
  onSelectionChanged: (input: string[]) => void;
  disabled: boolean;
  orgUnitIds: string[];
  error?: boolean;
  helperText?: string;
}) => {
  const { t } = useTranslation();
  return (
    <ResourceField
      onChange={onSelectionChanged as any}
      label={t("labels:label")}
      value={selectedIDs}
      resourceType={RESOURCE_TYPES.LABEL}
      id={"label"}
      icon={labelIcon}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      multiSelect={true}
      allowAdd={true}
      docOrgUnitIds={orgUnitIds}
      error={error}
      helperText={helperText}
    />
  );
};

const labelIcon = (
  <div>
    <LabelIcon />
  </div>
);

LabelField.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  selectedIDs: PropTypes.arrayOf(PropTypes.string),
  onSelectionChanged: PropTypes.func,
  orgUnitIds: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.bool,
  helperText: PropTypes.string
};

LabelField.defaultProps = {
  className: "",
  selectedIDs: [],
  onSelectionChanged: () => {
    /* empty */
  },
  disabled: false,

  orgUnitIds: []
};
