export const sourceLanguages = [
  "DE",
  "EN",
  "CS",
  "DA",
  "EL",
  "ES",
  "ET",
  "FI",
  "FR",
  "HU",
  "IT",
  "JA",
  "LT",
  "LV",
  "NL",
  "PL",
  "PT",
  "RO",
  "RU",
  "SK",
  "SL",
  "SV",
  "ZH"
];

export const targetLanguages = [
  "DE",
  "EN-GB",
  "EN-US",
  "CS",
  "DA",
  "EL",
  "ES",
  "ET",
  "FI",
  "FR",
  "HU",
  "IT",
  "JA",
  "LT",
  "LV",
  "NL",
  "PL",
  "PT-PT",
  "PT-BR",
  "RO",
  "RU",
  "SK",
  "SL",
  "SV",
  "ZH"
];
