import React, { useCallback, useMemo, useState } from "react";
import { Box, Card, IconButton } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import TextBodySecondary from "../TextBodySecondary/TextBodySecondary";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import EditInfoCardModal from "./EditInfoCardModal";
import TextEditor from "app/pages/questionnaires/utils/TextEditor";
import { useMetaView } from "app/contexts/meta-view-context";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { useAuthentication } from "app/handlers/authentication/authentication-context";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    padding: "16px",
    borderRadius: "16px",
    "& .MuiCardHeader-root": {
      padding: "0",
      margin: "0px"
    },
    "& .MuiCardContent-root": {
      padding: "0px",
      margin: "0px"
    }
  },
  infoCardHeader: {
    fontSize: "16px",
    color: theme.palette.common.black,
    padding: 0
  },
  infoCardContent: {
    lineHeight: "24px",
    padding: 0
  },
  infoCardIcon: {
    color: theme.palette.common.black
  },
  readOnlyEditor: {
    border: "none !important",
    padding: 0,
    margin: 0,
    color: "#000",
    "& .rdw-editor-wrapper": {
      border: "none !important"
    },
    "& .rdw-link-decorator-icon": {
      display: "none"
    },
    "& .rdw-editor-main": {
      padding: 0
    },
    "& a": {
      cursor: "pointer"
    }
  }
}));

interface InfoCardProps {
  readonly editable?: boolean;
  readonly infoCardHeader?: React.ReactNode;
  readonly infoCardContent?: React.ReactNode | string;
}

const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const InfoCard = ({ editable, infoCardHeader, infoCardContent }: InfoCardProps) => {
  const cls = useStyles();
  const { infoId, setInfoId } = useMetaView();
  const { auth } = useAuthentication();

  const customInfoCardEnable =
    useIsFeaturePresent(FEATURES.CUSTOM_INFO_CARD) && auth?.permissions.includes("infovault_write_all");

  const [infoCardModalOpen, setInfoCardModelopen] = useState<boolean>(false);

  const onOpenInfoCardModal = useCallback(() => {
    setInfoCardModelopen(true);
  }, []);
  const onCancelInfoCardModal = useCallback(() => {
    setInfoCardModelopen(false);
  }, []);
  const onSaveInfoCard = useCallback(() => {
    if (infoId) {
      setInfoId(null);
      setInfoId(infoId);
    }
    setInfoCardModelopen(false);
  }, [infoId, setInfoId]);

  const onRestoreInfocCard = useCallback(() => {
    if (infoId) {
      setInfoId(null);
      setInfoId(infoId);
    }
  }, [infoId, setInfoId]);

  const contentEl = useMemo(() => {
    if (typeof infoCardContent === "string") {
      if (isJsonString(infoCardContent)) {
        return (
          <TextEditor
            testId={"info-card-description"}
            inputValue={infoCardContent}
            titleComponent={undefined}
            focused={undefined}
            disabled={true}
            className={cls.readOnlyEditor}
          />
        );
      } else {
        return <TextBodySecondary text={infoCardContent} parseHTML={true} />;
      }
    } else {
      return infoCardContent;
    }
  }, [cls.readOnlyEditor, infoCardContent]);
  return (
    <>
      <Card className={cls.root}>
        {customInfoCardEnable && editable && infoId ? (
          <Box display="flex">
            <Box flex={1} />
            <IconButton size="small" onClick={onOpenInfoCardModal}>
              <EditIcon />
            </IconButton>
          </Box>
        ) : (
          <Box mt={1} />
        )}
        {infoCardHeader && <CardHeader title={<Box className={cls.infoCardHeader}>{infoCardHeader}</Box>} />}
        {infoCardContent && (
          <Box className={cls.infoCardContent}>
            <CardContent>{contentEl}</CardContent>
          </Box>
        )}
      </Card>
      {infoId && editable && (
        <EditInfoCardModal
          open={infoCardModalOpen}
          infoCardId={infoId}
          onCancel={onCancelInfoCardModal}
          onSave={onSaveInfoCard}
          onRestore={onRestoreInfocCard}
        />
      )}
    </>
  );
};

export default InfoCard;
