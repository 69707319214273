import { useEffect, useState } from "react";
import { fetchDataFromApi } from "app/handlers/connectionTestHandler";
import { apiEndpoints } from "app/api/apiEndpoint";

export function useAvailabilityOfServices() {
  const [servicesStatuses, setServicesStatuses] = useState<Record<string, string | any>>({
    ...Object.entries(apiEndpoints).reduce((acc, [apiName]) => ({ ...acc, [apiName]: "connected" }), {}),
    statusFirebase: "connected",
    statusCloudFunctions: "connected",
    statusAll: "connected"
  });

  const testApi = () => {
    return Promise.all(
      Object.entries(fetchDataFromApi()).map(([apiName, apiCheckPromise]) => {
        return apiCheckPromise.catch(error => {
          setServicesStatuses(servicesStatuses => {
            return { ...servicesStatuses, [apiName]: error, statusAll: error };
          });
        });
      })
    );
  };

  const [testFinished, setTestFinished] = useState(false);
  useEffect(() => {
    const checkAvailabilityOfservices = async () => {
      await Promise.all([testApi()]);

      setTestFinished(true);
    };

    checkAvailabilityOfservices();
  }, []);

  useEffect(() => {
    if (!testFinished) {
      return;
    }

    if (servicesStatuses?.statusAll?.error) {
      console.warn("Connectivity test failed", servicesStatuses);
    } else {
      console.debug("Connectivity test succeeded", servicesStatuses);
    }
  }, [testFinished, servicesStatuses]);

  return servicesStatuses;
}
