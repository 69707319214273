import React, { useCallback, useEffect, useState } from "react";
import { QuestionProps } from "../../Question";
import { MeasureIDAccordions } from "../../../Measures/MeasureIDAccordions";
import { TomsProvider } from "../../../../app/contexts/tom-context";

const MeasuresQuestion = ({ value, disabled = false, onFocus, onBlur, onChange }: QuestionProps) => {
  const [currentValue, setCurrentValue] = useState<string[]>([]);

  useEffect(() => {
    const initialValue: string[] = (Array.isArray(value) ? value : [value]) as string[];
    return setCurrentValue(initialValue);
  }, [value]);

  const onChangeCallback = useCallback(
    ids => {
      onChange?.(ids);
    },
    [onChange]
  );
  return (
    <TomsProvider>
      <MeasureIDAccordions
        measureIDs={currentValue}
        onMeasureIDChange={onChangeCallback}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      />
    </TomsProvider>
  );
};

export default React.memo(MeasuresQuestion);
