import React, { useCallback, useEffect, useState } from "react";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import PropTypes from "prop-types";
import { useUserGroups } from "../../../contexts/group-context";
import GroupIconCircle from "../../../../assets/images/tasks/group-circle.svg";

AssignGroupsMultiAutocomplete.propTypes = {
  docAssignedGroupIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDocAssignedGroupIdsChanged: PropTypes.func.isRequired,
  freeSolo: PropTypes.bool.isRequired,
  disableClearable: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  hasMultiSelect: PropTypes.bool.isRequired,
  onFocus: PropTypes.func.isRequired
};

AssignGroupsMultiAutocomplete.defaultProps = {
  freeSolo: false,
  disableClearable: false,
  hasMultiSelect: true,
  onFocus: () => {}
};

export function AssignGroupsMultiAutocomplete({
  docAssignedGroupIds,
  onDocAssignedGroupIdsChanged,
  freeSolo,
  disableClearable,
  label,
  hasMultiSelect,
  onFocus
}) {
  const { groups, groupsLoaded, getGroupNameHook } = useUserGroups();
  const [initialized, setInitialized] = useState(false);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [multiAutoCompleteSelected, setMultiAutoCompleteSelected] = useState([]);

  useEffect(() => {
    if (!groupsLoaded) {
      return;
    }
    let selectedGroupIds = docAssignedGroupIds || [];
    if (!selectedGroupIds[0] && !(selectedGroupIds.length > 1)) {
      // default value if empty
      if (selectedGroupIds.length >= 1) {
        onDocAssignedGroupIdsChanged(selectedGroupIds);
      }
    }
    setSelectedGroupIds(selectedGroupIds);
    setInitialized(true);
  }, [docAssignedGroupIds, groups, groupsLoaded, onDocAssignedGroupIdsChanged]);

  const updateSelected = useCallback(
    selectedValueSingleOrArray => {
      const selectedValues = Array.isArray(selectedValueSingleOrArray)
        ? selectedValueSingleOrArray
        : [selectedValueSingleOrArray];
      const groupsSelected = groups.filter(group => selectedValues.includes(group.id));
      onDocAssignedGroupIdsChanged(groupsSelected.map(group => group.id));
    },
    [onDocAssignedGroupIdsChanged, groups]
  );

  const [options, setOptions] = useState([]);
  useEffect(() => {
    setOptions(groups.map(group => group.id));
  }, [groups]);

  useEffect(() => {
    const derivedMultiAutoCompleteSelected = hasMultiSelect ? selectedGroupIds : selectedGroupIds[0] || null;
    setMultiAutoCompleteSelected(derivedMultiAutoCompleteSelected);
  }, [selectedGroupIds, setMultiAutoCompleteSelected, hasMultiSelect]);

  return initialized ? (
    <MultiAutocomplete
      icon={<GroupIconCircle color="primary" />}
      selected={multiAutoCompleteSelected}
      updateSelected={updateSelected}
      freeSolo={freeSolo}
      disableClearable={disableClearable}
      getOptionLabel={getGroupNameHook}
      options={options}
      hasMultiSelect={hasMultiSelect}
      label={label}
      onFocus={onFocus}
    />
  ) : (
    <></>
  );
}

export default AssignGroupsMultiAutocomplete;
