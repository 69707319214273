import React, { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const useStylesBlue = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.primary.light,
    padding: 25,
    paddingBottom: 15,
    borderRadius: "10px"
  },
  button: {
    color: theme.palette.primary.main,
    fontWeight: "bold"
  }
}));

const useStylesYellow = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.warning.light,
    padding: 25,
    paddingBottom: 15,
    borderRadius: "10px"
  },
  button: {
    color: theme.palette.alert.warning,
    fontWeight: "bold"
  }
}));

const useStylesGreen = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.success.light,
    padding: 25,
    paddingBottom: 15,
    borderRadius: "10px"
  },
  button: {
    color: theme.palette.success.main,
    fontWeight: "bold"
  }
}));

const useStylesRed = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.error.light,
    padding: 25,
    paddingBottom: 15,
    borderRadius: "10px"
  },
  button: {
    color: theme.palette.error.main,
    fontWeight: "bold"
  }
}));

export default function CardWithTextButton({ text, buttonText, onClick, colorTheme }) {
  const [classes, setClasses] = useState("");
  const blueClasses = useStylesBlue();
  const yellowClasses = useStylesYellow();
  const greenClasses = useStylesGreen();
  const redClasses = useStylesRed();

  useEffect(() => {
    switch (colorTheme) {
      case "yellow":
        setClasses(yellowClasses);
        break;
      case "green":
        setClasses(greenClasses);
        break;
      case "red":
        setClasses(redClasses);
        break;
      case "blue":
      default:
        setClasses(blueClasses);
        break;
    }
  }, [blueClasses, colorTheme, greenClasses, redClasses, yellowClasses]);

  return (
    <Card className={classes.card} elevation={0}>
      <Grid container justifyContent={"center"} spacing={4}>
        <Grid item>
          <span>{text}</span>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} spacing={4}>
        <Grid item>
          {buttonText && (
            <Button className={classes.button} onClick={onClick}>
              {buttonText}
            </Button>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
