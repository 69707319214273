import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  circle: {
    cursor: "pointer"
  }
}));
interface CircleWithNumberProps {
  readonly risks: any[];
  readonly sizeSVG: number;
  readonly radiusCircle: number;
  readonly selected: boolean;
  readonly onClick: (ids: string[], x: number, y: number) => void;
}

export const CircleWithNumber = ({ risks, onClick, sizeSVG, radiusCircle, selected }: CircleWithNumberProps) => {
  const classes = useStyles();
  const onClickCallback = useCallback(
    () =>
      onClick(
        risks.map(({ id }) => id),
        risks[0].damageExtendValue,
        risks[0].occurrenceValue
      ),
    [onClick, risks]
  );
  return (
    <svg height={sizeSVG} width={sizeSVG} onClick={onClickCallback} className={classes.circle}>
      {selected && (
        <>
          <circle cx={sizeSVG / 2} cy={sizeSVG / 2} r={radiusCircle} fill="#306AFF" stroke="#306AFF" strokeWidth="2" />
          <text x="50%" y="50%" textAnchor="middle" stroke="#FCFCFC" strokeWidth="1px" dy=".35em">
            {risks.length}
          </text>
        </>
      )}
      {!selected && (
        <>
          <circle cx={sizeSVG / 2} cy={sizeSVG / 2} r={radiusCircle} fill="#FCFCFC" stroke="#306AFF" strokeWidth="2" />
          <text x="50%" y="50%" textAnchor="middle" stroke="#306AFF" strokeWidth="1px" dy=".35em">
            {risks.length}
          </text>
        </>
      )}
    </svg>
  );
};

CircleWithNumber.propTypes = {
  number: PropTypes.number,
  onCLick: PropTypes.func,
  sizeSVG: PropTypes.number,
  radiusCircle: PropTypes.number,
  selected: PropTypes.bool
};

CircleWithNumber.defaultProps = {
  sizeSVG: 50,
  radiusCircle: 20,
  selected: false
};
