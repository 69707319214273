import { tDeletedEntry } from "../handlers/dataTypeTranslatorHandler";
import { DataLocationDTO, ExternalRecipientDTO } from "../api/externalRecipientApi";
import { TFunction } from "i18next";

export function getDataLocationWithExternalRecipient(
  dataLocationId: string,
  dataLocations: DataLocationDTO[],
  externalRecipients: ExternalRecipientDTO[],
  t: TFunction
) {
  const dataLocation = dataLocations.find(dataLocation => dataLocation.id === dataLocationId);
  if (!dataLocation) {
    return dataLocationId;
  }

  const dataLocationName = t(`lists_data_locations:${dataLocation.nameKey}`, dataLocation.nameKey);
  if (!dataLocation.externalRecipientId) {
    return dataLocationName;
  }

  const dataLocationExternalRecipient = externalRecipients.find(
    externalRecipient => externalRecipient.id === dataLocation.externalRecipientId
  );
  if (!dataLocationExternalRecipient) {
    return `${dataLocationName} [${tDeletedEntry({ t })}]`;
  }
  return `${dataLocationName} [${dataLocationExternalRecipient.name}]`;
}
