import { apiEndpoints } from "app/api/apiEndpoint";
import { AxiosResponse } from "axios";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";

const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.resourceUrl}/api/resources`
});

export interface ResourceDTO {
  readonly id: string;
  readonly type?: string;
  readonly nameKey?: string;
  readonly mergedIntoId?: string | null;
  readonly defaultResource?: boolean;
  readonly orgUnitIds?: string[];
  readonly featureToggle?: string;
  readonly metadata?: object | null;
}

export interface FirestoreOrgUnit {
  readonly id: string;
  readonly name: string;
  readonly parentId: string | null;
}
export type OrgUnitDTO = FirestoreOrgUnit;
export interface OverviewItemBadgeDTO {
  readonly kind: string;
}
export interface DisableActionDTO {
  readonly action: string;
}
export interface OverviewItemDTO {
  readonly id: string;
  readonly title: string;
  readonly subTitle: string;
  readonly badge: OverviewItemBadgeDTO | null;
  readonly disableActions: DisableActionDTO[];
  readonly orgUnitIds: string[];
}

export interface ResourceTypeOverviewDTO {
  readonly items: OverviewItemDTO[];
  readonly orgUnits: OrgUnitDTO[];
}

export interface ResourcesUnseen {
  readonly _count: number;
  readonly type: string;
}

export interface ResourcesDTO {
  readonly resources: Record<string, ResourceDTO[]>;
}

export async function getAllResourcesApi(
  {
    showMerged,
    showAllSetsIgnoringTenantFeatures
  }: {
    readonly showMerged: boolean;
    readonly showAllSetsIgnoringTenantFeatures: boolean;
  } = { showMerged: false, showAllSetsIgnoringTenantFeatures: false }
): Promise<Record<string, ResourceDTO[]>> {
  const axiosResponse = await axiosInstance.get<void, AxiosResponse<ResourcesDTO>>("/", {
    params: {
      showMerged,
      showAllSetsIgnoringTenantFeatures
    }
  });
  return axiosResponse.data.resources || {};
}

export async function getResourcesOfTypeApi(resourceType: string) {
  const axiosResponse = await axiosInstance.get<void, AxiosResponse<Record<string, ResourceDTO[]>>>(`/${resourceType}`);
  return axiosResponse.data[resourceType] || [];
}

export async function getResourceApi(resourceType: string, resourceId: string): Promise<ResourceDTO> {
  const axiosResponse = await axiosInstance.get<void, AxiosResponse<ResourceDTO>>(`/${resourceType}/${resourceId}`);
  return axiosResponse.data;
}

export async function createResourceApi({
  resourceType,
  nameKey,
  wait
}: {
  resourceType: string;
  nameKey: string;
  wait?: boolean;
}) {
  const response = await axiosInstance.post(
    `/${resourceType}`,
    {
      nameKey: nameKey
    },
    {
      params: { wait }
    }
  );
  return response.headers["x-resource-id"] || "";
}

export async function updateResourceApi(
  resourceType: string,
  resourceId: string,
  {
    nameKey,
    mergedIntoId,
    orgUnitIds,
    wait
  }: { nameKey?: string; mergedIntoId?: string; orgUnitIds?: string[]; wait?: boolean }
) {
  await axiosInstance.patch(
    `/${resourceType}/${resourceId}`,
    {
      nameKey: nameKey,
      mergedIntoId: mergedIntoId,
      orgUnitIds: orgUnitIds
    },
    {
      params: {
        wait
      }
    }
  );
}

export async function updateResourceMetadataApi(
  resourceType: string,
  resourceId: string,
  metadata: object | null,
  wait?: boolean
) {
  await axiosInstance.put(
    `/${resourceType}/${resourceId}/metadatas`,
    {
      metadata: metadata
    },
    {
      params: {
        wait
      }
    }
  );
}

export async function deleteResourceApi(resourceType: string, resourceId: string, { wait }: { wait?: boolean } = {}) {
  await axiosInstance.delete(`/${resourceType}/${resourceId}`, {
    params: {
      wait
    }
  });
}
export interface ResourceMultiMergeDTO {
  readonly toMergeIDs: string[];
  readonly mergeIntoId?: string;
  readonly nameKey?: string;
}
export async function mergeResourceApi(resourceType: string, payload: ResourceMultiMergeDTO): Promise<string | null> {
  const axiosResponse = await axiosInstance.post(`/${resourceType}/merges`, payload);
  return axiosResponse.headers["x-resource-id"] || null;
}

export async function getResourcesOverviewApi(
  resourceType: string,
  options: {
    readonly abortController?: AbortController;
  } = {}
): Promise<ResourceTypeOverviewDTO> {
  const axiosResponse = await axiosInstance.get<ResourceTypeOverviewDTO>(`/${resourceType}/overview`, {
    signal: options.abortController?.signal
  });
  return axiosResponse.data;
}

export async function getNumberUnseenResourcesOfUserApi(
  httpOptions: {
    abortController?: AbortController;
  } = {}
): Promise<number> {
  const response = await axiosInstance.get("/unseen", { signal: httpOptions.abortController?.signal });
  return response.data.unseenCount;
}

export async function getNumberUnseenResourcesPerResourceTypeApi() {
  const response = await axiosInstance.get("/unseen");
  return response.data.unseenCountPerType;
}
