import { useTranslation } from "react-i18next";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { useParams } from "react-router-dom";
import { useProcessPage } from "../../../contexts/process-page-context";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../../features";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import Question from "../../../../components/Question/Question";
import TextEditor from "../utils/TextEditor";
import { checkIfTextEditorContainsText } from "../utils/textEditorConverter";
import { useMetaView } from "../../../contexts/meta-view-context";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import AssetsMultiSelect from "../../assets/components/AssetMultiSelect";
import PurposeAccordion, { Purpose } from "./PurposeAccordion";
import PersonGroupAccordion, { PersonGroupAccordionData } from "./PersonGroupAccordion";
import { COLLECTIONS } from "../../../collections";
import { AttachmentsOverviewOBS } from "../../shared/Attachments/AttachmentsOverviewOBS";
import { useProcessing } from "../../../api/process/processingPageApi";
import { useEnteringInfoCard } from "hook/useEnteringInfoCard";

const ProcessProcessingPage = (props: { readonly readonly?: boolean }) => {
  const { t } = useTranslation("questionnaires");
  const { auth } = useAuthentication();
  const { id } = useParams();
  const { setInfo, setInfoId } = useMetaView();
  const { onBeforeProcessUpdate, setProcessMeta, processMeta } = useProcessPage();
  useEnteringInfoCard({ pathName: `/processes/${id}/description`, infoId: "infocard.pa.page2" });

  const [description, setDescription] = useState<string>("");
  const [assetIds, setAssetIds] = useState<string[]>([]);
  const [purposes, setPurposes] = useState<Purpose[]>([]);
  const [personGroups, setPersonGroups] = useState<PersonGroupAccordionData[]>([]);

  const {
    createdNewPersonGroupIds,
    createdNewPurposeIds,
    isPurposeCreating,
    isPersonGroupCreating,
    isLoading,
    data,
    actions
  } = useProcessing({ documentId: id || "" });

  useEffect(() => {
    if (data) {
      setProcessMeta(data.processMeta);
    }
  }, [auth, data, id, setProcessMeta]);

  const loadingAnimationEl = useMemo(
    () => (
      <Box textAlign={"center"} mt={8}>
        <CircularProgress />
      </Box>
    ),
    []
  );

  /*  PAGE ELEMENTS - Description */
  useEffect(() => {
    if (data?.processPage.description) {
      setDescription(data?.processPage.description);
    }
  }, [data?.processPage.description]);
  const updateDescription = useCallback(
    async (description: string) => {
      await onBeforeProcessUpdate(async () => {
        await actions.updateDescription(id || "", { description: description });
      });
    },
    [actions, id, onBeforeProcessUpdate]
  );

  const [showDescriptionErrorMessage, setShowDescriptionErrorMessage] = useState(false);
  const onDescriptionBlur = useCallback(async () => {
    await updateDescription(description);
  }, [description, updateDescription]);

  const onDescriptionFocus = useCallback(async () => {
    setShowDescriptionErrorMessage(true);
    setInfoId("infocard.pa.page2.description");
  }, [setInfoId]);
  const descriptionEl = (
    <>
      <QuestionnaireSubHeader text={t("process_description_page:page_title")} />
      <Question
        questionId={"description"}
        questionName={t("0_0_summary:questionTitle")}
        title={t("0_0_summary:questionTitle")}
        translatable={description}
        disabled={props.readonly}
      >
        <TextEditor
          onChange={setDescription}
          inputValue={description}
          onFocus={onDescriptionFocus}
          onBlur={onDescriptionBlur}
          disabled={props.readonly}
        />
        {showDescriptionErrorMessage && !checkIfTextEditorContainsText(description) && (
          <Typography color="error">{t("questionnaires:mandatoryField")}</Typography>
        )}
      </Question>
    </>
  );

  /*  PAGE ELEMENTS - ASSETS */
  useEffect(() => {
    if (data?.processPage.assetIds) {
      setAssetIds(data?.processPage.assetIds);
    }
  }, [data?.processPage.assetIds]);
  const updateAssetIds = useCallback(
    async (assetIds: string[]) => {
      await onBeforeProcessUpdate(async () => {
        await actions.updateAssetIds(id || "", { assetIds: assetIds });
      });
    },
    [actions, id, onBeforeProcessUpdate]
  );
  const isAssetFeatureAllowed = useIsFeaturePresent(FEATURES.ASSETS);
  const assetEl = isAssetFeatureAllowed && (
    <Box mt={4}>
      <QuestionnaireSubHeader text={t("asset_details:affected_assets")} />
      <Question questionId={"assetIds"} questionName={t("asset_details:affected_assets")} disabled={props.readonly}>
        <AssetsMultiSelect
          selectedAssetIds={assetIds}
          orgUnitIds={processMeta?.relativeOrgUnitIds}
          onChange={updateAssetIds}
          disabled={props.readonly}
        />
      </Question>
    </Box>
  );
  /*  PAGE ELEMENTS - ATTACHMENTS */
  const isFileUploadOnProcessingActivitiesAllowed = useIsFeaturePresent(FEATURES.FILE_UPLOAD_ON_PROCESSING_ACTIVITIES);
  const attachmentEl = isFileUploadOnProcessingActivitiesAllowed ? (
    <Box mt={4}>
      <QuestionnaireSubHeader text={t("attachments")} />
      <AttachmentsOverviewOBS docId={id} category={COLLECTIONS.PROCESSES + "-description"} disabled={props.readonly} />
    </Box>
  ) : (
    <></>
  );

  /*  PAGE ELEMENTS - Purposes */
  useEffect(() => {
    if (data?.processPage.purposes) {
      setPurposes(data?.processPage.purposes);
    }
  }, [data?.processPage.purposes]);
  const createPurpose = useCallback(async () => {
    await onBeforeProcessUpdate(async () => {
      const newId = v4();
      await actions.createPurpose(id || "", newId);
    });
  }, [actions, id, onBeforeProcessUpdate]);
  const savePurpose = useCallback(
    async updatedPurpose => {
      await onBeforeProcessUpdate(async () => {
        await actions.updatePurpose(id || "", updatedPurpose.id, { ...updatedPurpose, id: undefined });
      });
    },
    [actions, id, onBeforeProcessUpdate]
  );
  const deletePurpose = useCallback(
    async purposeId => {
      await onBeforeProcessUpdate(async () => {
        await actions.deletePurpose(id || "", purposeId);
      });
    },
    [actions, id, onBeforeProcessUpdate]
  );
  const purposesEl = (
    <>
      <Box mt={4}>
        <QuestionnaireSubHeader text={t("process_description_page:purpose_of_processing_title")} />
      </Box>
      {purposes.map(purpose => (
        <Question
          key={purpose.id}
          questionId={"purposes " + purpose.id}
          questionName={purpose.purposeDescription}
          disabled={props.readonly}
        >
          <PurposeAccordion
            disabled={props.readonly}
            purpose={purpose}
            paOrgUnitIds={processMeta?.relativeOrgUnitIds || []}
            onDelete={deletePurpose}
            onSave={savePurpose}
            isCreatedNew={createdNewPurposeIds.includes(purpose.id)}
          />
        </Question>
      ))}
      <Box mb={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={createPurpose}
          disabled={props.readonly || isPurposeCreating}
        >
          <Box display="flex" alignItems="center">
            {t("process_description_page:add_new")}
            {isPurposeCreating && (
              <Box ml={1} display="flex" alignItems="center">
                <CircularProgress size="1rem" />
              </Box>
            )}
          </Box>
        </Button>
      </Box>
    </>
  );

  /*  PAGE ELEMENTS - Person Groups */
  useEffect(() => {
    const newPersonGroups = data?.processPage.personGroups;
    if (newPersonGroups) {
      setPersonGroups(newPersonGroups);
    }
  }, [data?.processPage.personGroups]);
  const newPersonalData = useMemo(
    () => ({
      title: t("newPersonalDataTitle"),
      text: t("newPersonalDataText")
    }),
    [t]
  );
  const createPersonGroup = useCallback(async () => {
    await onBeforeProcessUpdate(async () => {
      setInfo(newPersonalData);
      const newId = v4();
      await actions.createPersonGroup(id || "", newId);
    });
  }, [actions, id, newPersonalData, onBeforeProcessUpdate, setInfo]);
  const savePersonGroup = useCallback(
    async updatedPersonGroup => {
      await onBeforeProcessUpdate(async () => {
        await actions.updatePersonGroup(id || "", updatedPersonGroup.id, { ...updatedPersonGroup, id: undefined });
      });
    },
    [actions, id, onBeforeProcessUpdate]
  );
  const deletePersonGroup = useCallback(
    async personGroupId => {
      await onBeforeProcessUpdate(async () => {
        await actions.deletePersonGroup(id || "", personGroupId);
      });
    },
    [actions, id, onBeforeProcessUpdate]
  );
  const personGroupsEl = (
    <>
      <Box mt={6}>
        <QuestionnaireSubHeader text={t("1_table:descriptionSidebar")} />
      </Box>
      {personGroups.map(personGroup => (
        <PersonGroupAccordion
          key={personGroup.id}
          personGroup={personGroup}
          onDelete={deletePersonGroup}
          onSave={savePersonGroup}
          isCreatedNew={createdNewPersonGroupIds.includes(personGroup.id)}
          disabled={props.readonly}
        />
      ))}
      <Box mb={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={createPersonGroup}
          disabled={props.readonly || isPersonGroupCreating}
        >
          <Box display="flex" alignItems="center">
            {t("process_description_page:add_new")}
            {isPersonGroupCreating && (
              <Box ml={1} display="flex" alignItems="center">
                <CircularProgress size="1rem" />
              </Box>
            )}
          </Box>
        </Button>
      </Box>
    </>
  );

  if (isLoading) {
    return loadingAnimationEl;
  } else {
    return (
      <>
        {descriptionEl}
        {attachmentEl}
        {assetEl}
        {purposesEl}
        {personGroupsEl}
      </>
    );
  }
};

export default ProcessProcessingPage;
