import { Box, Chip, TextField, Theme, Typography, makeStyles } from "@material-ui/core";
import { isAxiosErrorWithCode } from "app/api/axios/axiosErrorHandler";
import { useDataTypeTreeManager } from "app/api/dataAssetApi";
import { DataCategory } from "app/api/generated/asset-service";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import Question from "components/Question/Question";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import { useSnackbar } from "notistack";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import StorageIcon from "@material-ui/icons/Storage";

const useStyle = makeStyles((theme: Theme) => {
  return {
    dataCategoryIcon: {
      color: theme.palette.primary.main,
      fontSize: "16px"
    },
    chip: {
      background: "#EBF1FF",
      marginRight: "8px",
      paddingLeft: "4px"
    }
  };
});

export interface DataCategotyMoveModalDataProps {
  readonly open: boolean;
  readonly dataCategoryIds: string[];
  readonly personGroupId: string;
}

interface DataCategotyOverviewMoveModalProps {
  readonly dataCategoryMoveModalData: DataCategotyMoveModalDataProps | null;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
}

const DataCategotyOverviewMoveModal = ({
  dataCategoryMoveModalData,
  onCancel,
  onConfirm
}: DataCategotyOverviewMoveModalProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { actions, dataById } = useDataTypeTreeManager(true);
  const cls = useStyle();

  const [selectedPersonGroupId, setSelectedPersonGroupId] = useState<string>("");
  const [dataCategoryKey, setDataCategoryKey] = useState<string>("");

  /* CHECK NAME */
  const theSameKey = useCallback(
    (keyOne: string, keyTwo: string): boolean => {
      return (
        t(`lists_data_types_categories_person_groups:${keyOne}`, keyOne) ===
        t(`lists_data_types_categories_person_groups:${keyTwo}`, keyTwo)
      );
    },
    [t]
  );

  const onChangePersonGroup = useCallback(id => {
    setSelectedPersonGroupId(id || "");
  }, []);

  /* SAME NAME */
  /* only for single item case */
  const singleItem = useMemo(() => {
    if (dataCategoryMoveModalData?.dataCategoryIds.length === 1) {
      return dataById?.dataCategories[dataCategoryMoveModalData?.dataCategoryIds[0]] || null;
    } else {
      return null;
    }
  }, [dataById?.dataCategories, dataCategoryMoveModalData?.dataCategoryIds]);

  useEffect(() => {
    setDataCategoryKey(singleItem?.dataCategoryKey || "");
  }, [singleItem?.dataCategoryKey]);

  /* SINGLE ITEM CASE */
  const sameNameDataCategoryInNewParent = useMemo(() => {
    if (singleItem && selectedPersonGroupId) {
      const dataCategoriesInNewParent = dataById?.personGroups[selectedPersonGroupId]?.dataCategories;
      return dataCategoriesInNewParent?.find(dataCategory =>
        theSameKey(dataCategory.dataCategoryKey, singleItem?.dataCategoryKey)
      );
    }
  }, [dataById?.personGroups, selectedPersonGroupId, singleItem, theSameKey]);

  /* MULTIPLE ITEMS CASE */
  const sameNameDataCategoriesInNewParent = useMemo(() => {
    if (!singleItem && selectedPersonGroupId) {
      const dataCategoriesInNewParent = dataById?.personGroups[selectedPersonGroupId]?.dataCategories;
      return dataCategoriesInNewParent
        ?.map(dataCategory => {
          return dataCategoryMoveModalData?.dataCategoryIds.find(id => {
            return theSameKey(dataCategory?.dataCategoryKey, dataById?.dataCategories[id]?.dataCategoryKey || "");
          })
            ? dataCategory
            : null;
        })
        .filter(notNull => notNull) as DataCategory[];
    } else return [];
  }, [
    dataById?.dataCategories,
    dataById?.personGroups,
    dataCategoryMoveModalData?.dataCategoryIds,
    selectedPersonGroupId,
    singleItem,
    theSameKey
  ]);

  const sameNameDataCategoriesEl = useMemo(() => {
    if (sameNameDataCategoriesInNewParent?.length) {
      return (
        <>
          <Typography color="secondary">
            {t("lists_data_types_categories_person_groups:moveMultipleDataCategoryAlreadyExist")}
          </Typography>
          <Box mt={2} p={0} display="flex">
            {sameNameDataCategoriesInNewParent.map(({ dataCategoryKey }, index) => (
              <Chip
                key={index}
                icon={<StorageIcon fontSize="small" className={cls.dataCategoryIcon} />}
                label={t(`lists_data_types_categories_person_groups:${dataCategoryKey}`, dataCategoryKey)}
                className={cls.chip}
              />
            ))}
          </Box>
        </>
      );
    } else {
      return <></>;
    }
  }, [cls.chip, cls.dataCategoryIcon, sameNameDataCategoriesInNewParent, t]);

  const onChangeCallback = useCallback(e => {
    setDataCategoryKey(e.target.value || "");
  }, []);
  const itemNameEl = useMemo(() => {
    if (singleItem) {
      return (
        <Box mb={3}>
          <TextField
            fullWidth
            maxRows={1000}
            minRows={1}
            multiline
            value={dataCategoryKey}
            onChange={onChangeCallback}
            variant="outlined"
            label={"Data type name"}
            disabled={!sameNameDataCategoryInNewParent}
          />
        </Box>
      );
    } else {
      return <></>;
    }
  }, [dataCategoryKey, onChangeCallback, sameNameDataCategoryInNewParent, singleItem]);

  const helperText = useMemo(() => {
    return singleItem &&
      sameNameDataCategoryInNewParent &&
      theSameKey(sameNameDataCategoryInNewParent.dataCategoryKey, dataCategoryKey)
      ? t("lists_data_types_categories_person_groups:moveSingleDataCategoryAlreadyExist")
      : "";
  }, [dataCategoryKey, sameNameDataCategoryInNewParent, singleItem, t, theSameKey]);

  const error = useMemo(() => {
    if (singleItem) {
      return (
        sameNameDataCategoryInNewParent && theSameKey(sameNameDataCategoryInNewParent.dataCategoryKey, dataCategoryKey)
      );
    } else {
      return !!sameNameDataCategoriesInNewParent?.length;
    }
  }, [
    singleItem,
    sameNameDataCategoryInNewParent,
    theSameKey,
    dataCategoryKey,
    sameNameDataCategoriesInNewParent?.length
  ]);

  /* BODY */
  const modalBody = (
    <Box mt={3} mb={4}>
      <Box mt={3}>
        {itemNameEl}
        <Typography>{t("common:moveTo")}</Typography>
        <Box mt={-1} />
        <Question
          qType={QUESTION_TYPE.PERSON_GROUP}
          onChange={onChangePersonGroup}
          multiSelectHiddenIds={
            dataCategoryMoveModalData?.personGroupId ? [dataCategoryMoveModalData?.personGroupId] : []
          }
          error={error}
          helperText={helperText}
        />
        {sameNameDataCategoriesEl}
      </Box>
    </Box>
  );

  const clearCallback = useCallback(() => {
    setSelectedPersonGroupId("");
  }, []);

  const onCancelCallback = useCallback(() => {
    clearCallback();
    onCancel();
  }, [clearCallback, onCancel]);

  const onConfirmCallback = useCallback(async () => {
    if (dataCategoryMoveModalData && selectedPersonGroupId) {
      if (
        singleItem?.id &&
        sameNameDataCategoryInNewParent &&
        !theSameKey(sameNameDataCategoryInNewParent?.dataCategoryKey, dataCategoryKey)
      ) {
        // first rename data type to be moved
        await actions.updateDataCategory({
          personGroupId: dataCategoryMoveModalData.personGroupId,
          dataCategoryId: singleItem.id,
          updates: { name: dataCategoryKey }
        });

        await actions.moveDataCategory({
          formerPersonGroupId: dataCategoryMoveModalData.personGroupId,
          newPersonGroupId: selectedPersonGroupId,
          dataCategoryId: singleItem.id
        });
      } else {
        for (const dataCategoryId of dataCategoryMoveModalData.dataCategoryIds) {
          try {
            await actions.moveDataCategory({
              formerPersonGroupId: dataCategoryMoveModalData.personGroupId,
              newPersonGroupId: selectedPersonGroupId,
              dataCategoryId
            });
          } catch (error) {
            onCancel();
            clearCallback();
            if (isAxiosErrorWithCode(error, 409)) {
              enqueueSnackbar(`${t("error_messages:generic_already_exists")}`, { variant: "error" });
              return;
            }
          }
        }
      }

      onConfirm();
      clearCallback();
    }

    onCancelCallback();
  }, [
    actions,
    clearCallback,
    dataCategoryKey,
    dataCategoryMoveModalData,
    enqueueSnackbar,
    onCancel,
    onCancelCallback,
    onConfirm,
    sameNameDataCategoryInNewParent,
    selectedPersonGroupId,
    singleItem?.id,
    t,
    theSameKey
  ]);

  const onMergeSingleCallback = useCallback(async () => {
    if (selectedPersonGroupId && dataCategoryMoveModalData && sameNameDataCategoryInNewParent && singleItem) {
      try {
        await actions.mergeDataCategories({
          personGroupId: selectedPersonGroupId,
          dataCategories: [sameNameDataCategoryInNewParent, singleItem],
          newName: sameNameDataCategoryInNewParent.dataCategoryKey
        });
      } catch (error) {
        onCancel();
        clearCallback();
        if (isAxiosErrorWithCode(error, 409)) {
          enqueueSnackbar(`${t("error_messages:generic_already_exists")}`, { variant: "error" });
          return;
        }
      }

      onConfirm();
      clearCallback();
    }

    onCancelCallback();
  }, [
    actions,
    clearCallback,
    dataCategoryMoveModalData,
    enqueueSnackbar,
    onCancel,
    onCancelCallback,
    onConfirm,
    sameNameDataCategoryInNewParent,
    selectedPersonGroupId,
    singleItem,
    t
  ]);

  const onMergeMultipleCallback = useCallback(async () => {
    if (dataCategoryMoveModalData && selectedPersonGroupId && sameNameDataCategoriesInNewParent?.length) {
      // in case of merge
      for (const dataCategoryId of dataCategoryMoveModalData.dataCategoryIds) {
        const sameNameDataCategory = sameNameDataCategoriesInNewParent.find(({ dataCategoryKey }) =>
          theSameKey(dataCategoryKey, dataById?.dataCategories[dataCategoryId]?.dataCategoryKey || "")
        );

        try {
          if (sameNameDataCategory && dataById?.dataCategories?.[dataCategoryId]) {
            await actions.mergeDataCategories({
              personGroupId: selectedPersonGroupId,
              dataCategories: [sameNameDataCategory, dataById?.dataCategories?.[dataCategoryId]],
              newName: sameNameDataCategory.dataCategoryKey
            });
          }
          // in case of move
          else {
            await actions.moveDataCategory({
              formerPersonGroupId: dataCategoryMoveModalData.personGroupId,
              newPersonGroupId: selectedPersonGroupId,
              dataCategoryId
            });
          }

          onConfirm();
          clearCallback();
        } catch (error) {
          onCancel();
          clearCallback();
          if (isAxiosErrorWithCode(error, 409)) {
            enqueueSnackbar(`${t("error_messages:generic_already_exists")}`, { variant: "error" });
            return;
          }
        }
      }

      onCancelCallback();
    }
  }, [
    actions,
    clearCallback,
    dataById?.dataCategories,
    dataCategoryMoveModalData,
    enqueueSnackbar,
    onCancel,
    onCancelCallback,
    onConfirm,
    sameNameDataCategoriesInNewParent,
    selectedPersonGroupId,
    t,
    theSameKey
  ]);

  const buttons: ConfirmationModalButtonProps[] = useMemo(
    () =>
      [
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onCancelCallback
        },
        sameNameDataCategoryInNewParent
          ? {
              confirmButton: true,
              title: t("common:merge"),
              variant: "contained",
              color: "primary",
              size: "medium",
              onClick: onMergeSingleCallback,
              disabled: !theSameKey(sameNameDataCategoryInNewParent.dataCategoryKey, dataCategoryKey)
            }
          : null,
        sameNameDataCategoriesInNewParent?.length
          ? {
              confirmButton: true,
              title: t("common:merge"),
              variant: "contained",
              color: "primary",
              size: "medium",
              onClick: onMergeMultipleCallback
            }
          : null,
        !sameNameDataCategoriesInNewParent?.length
          ? {
              confirmButton: true,
              title: t("common:confirm"),
              variant: "contained",
              color: "primary",
              size: "medium",
              disabled:
                !selectedPersonGroupId ||
                (sameNameDataCategoryInNewParent &&
                  theSameKey(sameNameDataCategoryInNewParent.dataCategoryKey, dataCategoryKey)),
              onClick: onConfirmCallback
            }
          : null
      ].filter(notNull => notNull) as ConfirmationModalButtonProps[],
    [
      dataCategoryKey,
      onCancelCallback,
      onConfirmCallback,
      onMergeMultipleCallback,
      onMergeSingleCallback,
      sameNameDataCategoriesInNewParent?.length,
      sameNameDataCategoryInNewParent,
      selectedPersonGroupId,
      t,
      theSameKey
    ]
  );

  return (
    <ConfirmationModal
      modalOpen={Boolean(dataCategoryMoveModalData?.open)}
      onClose={onCancel}
      modalTitle={t(`dataCategory:moveModalHeader`)}
      buttons={buttons}
      modalBody={modalBody}
      modalText={t(`dataCategory:moveModalBody`)}
    />
  );
};

export default DataCategotyOverviewMoveModal;
