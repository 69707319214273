import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { useMetaView } from "../../../contexts/meta-view-context";
import { TomProtectionObjectiveAutoSave } from "../fields/TomProtectionObjective";
import { TomStatus } from "../fields/TomStatus";
import { useTom } from "../../../contexts/tom-context";
import { useErrorSnackbar } from "../../../../hook/errorSnackbar";
import { debounce } from "lodash-es";
import { CircularProgress, Typography } from "@material-ui/core";
import Question from "components/Question/Question";
import { toJSDateObjectIfFirebaseDate } from "../../../handlers/utility/date-helper";
import { AttachmentsOverviewOBS } from "../../shared/Attachments/AttachmentsOverviewOBS";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";

interface TomDetailsProps {
  readonly tomId: string;
  readonly onNameChange?: (title: string) => void;
}

const TomDetails = ({ tomId, onNameChange }: TomDetailsProps) => {
  const { toms, tomInitiated, getTomHook, updateTomHook } = useTom();
  const [pageLoaded, setPageLoaded] = useState(false);
  const { catchAsSnackbar } = useErrorSnackbar();
  const { setInfo, setMeta } = useMetaView();
  const { t } = useTranslation("tom_description_tab");

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [labels, setLabels] = useState([]);
  const [orgUnitId, setOrgUnitId] = useState("");
  const [furtherAffectedOrgUnitIds, setFurtherAffectedOrgUnitIds] = useState([]);
  const [statusData, setStatusData] = useState({
    status: null,
    statusDate: null
  });

  const cardInfo = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoTitle"),
        text: t("enteringInfoText")
      },
      name: {
        title: t("title"),
        text: t("titleText")
      },
      description: {
        title: t("title"),
        text: t("descriptionText")
      },
      labels: {
        title: t("labels:info_header"),
        text: t("labels:info_text_measure")
      },
      protectionObjective: {
        title: t("title"),
        text: t("protectionObjectiveText")
      },
      orgUnit: {
        title: t("title"),
        text: t("orgUnitText")
      },
      furtherAffectedOrgUnit: {
        title: t("title"),
        text: t("furtherOrgUnitText")
      }
    }),
    [t]
  );

  const setEnteredInfo = useCallback(() => {
    setInfo(cardInfo.entering);
  }, [cardInfo.entering, setInfo]);

  useEffect(() => {
    setEnteredInfo();
  }, [setEnteredInfo]);

  useEffect(() => {
    if (!tomInitiated || pageLoaded) {
      return;
    }

    const init = async () => {
      const tom: any = await getTomHook(tomId);
      setName(tom?.name || "");
      setDescription(tom?.description || "");
      setLabels(tom?.labelIds || []);
      setOrgUnitId(tom?.orgUnitId || "");
      setFurtherAffectedOrgUnitIds(tom?.furtherAffectedOrgUnitIds || []);
      setStatusData({
        status: tom?.status || "",
        statusDate: toJSDateObjectIfFirebaseDate(tom?.statusDate) || null
      });
      setPageLoaded(true);
    };

    init().catch(catchAsSnackbar("failed to load TOM"));
  }, [tomInitiated, toms, catchAsSnackbar, getTomHook, pageLoaded, tomId]);

  const debouncedSaveTom = useMemo(() => debounce(updateTomHook, 600), [updateTomHook]);
  useEffect(() => {
    if (!pageLoaded) {
      return;
    }

    debouncedSaveTom(
      {
        name,
        description,
        labelIds: labels,
        status: statusData.status || "PLANNED", // some old TOMs have undefined status but has to be defined in payload
        statusDate: statusData.statusDate,
        orgUnitId,
        furtherAffectedOrgUnitIds
      },
      tomId
    );
  }, [
    name,
    description,
    labels,
    statusData,
    debouncedSaveTom,
    tomId,
    pageLoaded,
    orgUnitId,
    furtherAffectedOrgUnitIds
  ]);

  const onNameChangeCallback = useCallback(
    name => {
      setName(name);
      onNameChange?.(name);
    },
    [onNameChange]
  );

  const onChangeOrgUnitId = useCallback(orgunit => {
    setOrgUnitId(orgunit);
  }, []);

  const onFurtherAffectedOrgUnitIdsChanges = useCallback(furtherOrgUnitIds => {
    setFurtherAffectedOrgUnitIds(furtherOrgUnitIds);
  }, []);

  if (!pageLoaded) {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Question
        qType={QUESTION_TYPE.TEXT_AREA}
        questionId={"name"}
        translatable={name}
        questionName={t("name")}
        value={name}
        info={cardInfo.name}
        onChange={onNameChangeCallback}
        onBlur={setEnteredInfo}
      />
      <Question
        qType={QUESTION_TYPE.RESPONSIBLE_ORG_UNIT}
        questionId={"orgUnit"}
        questionName={t("data_breaches_general_page:org_unit")}
        value={orgUnitId}
        info={cardInfo.orgUnit}
        onChange={onChangeOrgUnitId}
        onBlur={setEnteredInfo}
      />
      <Question
        qType={QUESTION_TYPE.FURTHER_ORG_UNITS}
        questionId={"furtherAffectedOrgUnit"}
        questionName={t("risk_general_page:furtherAffectedOrgUnit")}
        value={furtherAffectedOrgUnitIds}
        info={cardInfo.furtherAffectedOrgUnit}
        onChange={onFurtherAffectedOrgUnitIdsChanges}
        onBlur={setEnteredInfo}
      />
      <TomStatus initialStatusData={statusData} onStatusDataChanged={setStatusData} onFocus={setEnteredInfo} />
      <Question
        qType={QUESTION_TYPE.TEXT_EDITOR}
        questionId={"description"}
        translatable={description}
        questionName={t("description")}
        value={description}
        info={cardInfo.description}
        onChange={setDescription}
        onBlur={setEnteredInfo}
      />
      <Question questionId={"protectionObjective"} questionName={t("protectionObjective")}>
        <TomProtectionObjectiveAutoSave tomId={tomId} />
      </Question>
      <Question
        qType={QUESTION_TYPE.LABELS}
        questionId={"labels"}
        questionName={t("labels:label")}
        value={labels}
        info={cardInfo.labels}
        onChange={setLabels}
        onBlur={setEnteredInfo}
      />
      <Question questionId={"attachments"} questionName={t("attachments")}>
        <Typography variant="h5" component="div">
          {t("attachments")}
        </Typography>
        <AttachmentsOverviewOBS docId={tomId} category={"tom"} setMeta={setMeta} />
      </Question>
    </>
  );
};

export default TomDetails;
