import { downloadAuditReportWordDocumentFromAPI } from "../api/auditApi";

export async function downloadAuditReportWordDocument(auditId: string, language: string) {
  const { data, fileName } = await downloadAuditReportWordDocumentFromAPI(auditId, language);

  const url = window.URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}
