import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMetaView } from "app/contexts/meta-view-context";
import Overview from "components/Overview/Overview";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import LinkIcon from "@material-ui/icons/Link";
import UploadFileIcon from "../../../../../assets/images/icons/uploadFile.svg";
import { OVERVIEW_ACTIONS, useOverviewDispatch, useOverviewState } from "app/contexts/overview-context";
import { ResourceDTO } from "app/api/resourceApi";
import { getSingleResource, RESOURCE_TYPE, RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { ResourceMergeItem, ResourceMergeModal } from "../../modals/resources/ResourceMergeModal";
import { useUserAndTenantData } from "../../../../handlers/userAndTenant/user-tenant-context";
import { useResources } from "../../../../contexts/resource-context";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import { OverviewRowActionProps } from "../../../../../components/Overview/controls/OverviewRowAction";

import SettingsIcon from "@material-ui/icons/Settings";
import { ResourceDialogProps, ResourceMetaviewProps } from "../../ResourceTypeOverviewDeps";
import { LegalBasisMetaview } from "../../metaview/LegalBasisMetaview";
import { LegalRetentionPeriodMetadataDialogWithSave } from "../../modals/legal-retention-period/LegalRetentionPeriodMetadataDialog";
import { DSRTemplateUploadDialog } from "../../modals/dsr/DSRTemplateUploadDialog";
import { useLocation } from "react-router-dom";
import { resourcesWritePermissions } from "../../../../handlers/permissionHandler";
import { useAuthentication } from "../../../../handlers/authentication/authentication-context";
import ResourceTypeOverviewOrgUnitPicker from "../../modals/resources/ResourceTypeOverviewOrgUnitPicker";

const ResourceTypeOverview = ({ resourceType }: { readonly resourceType: RESOURCE_TYPE }) => {
  const resourceOverviewTranslationContext = `resources_${resourceType}_overview`;
  const { t } = useTranslation(resourceOverviewTranslationContext);
  const { setInfo } = useMetaView();
  const dispatch = useOverviewDispatch();
  const { resources } = useOverviewState();
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();
  const { resourcesLoaded, refreshResources } = useResources();
  const location = useLocation();
  const { auth } = useAuthentication();

  useEffect(() => {
    dispatch({ type: OVERVIEW_ACTIONS.SET_SELECTED_ID, collection: COLLECTIONS.RESOURCES, selectedId: null });
  }, [dispatch, location]);

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t(`enteringInfoCardTitle`),
        text: t(`enteringInfoCardText`)
      },
      creating: {
        title: t(`creating_info_card_title`),
        text: t(`creating_info_card_text`)
      }
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);

  const showEnterInfo = useCallback(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);

  const showCreateInfo = useCallback(() => {
    setInfo(infoCard?.creating);
  }, [infoCard, setInfo]);

  const onRowOver = useCallback(() => {
    // do nothing
  }, []);

  const toolbarActions = useMemo(
    () => [
      { action: "sort" },
      { action: "filter" },
      { action: "add" },
      {
        action: "mark-all-as-read"
      }
    ],
    []
  );
  const addActions = useMemo(() => [{ action: OVERVIEW_ADD_TYPE.SINGLE }], []);
  const [toMergeItems, setToMergeItems] = useState<Pick<ResourceMergeItem, "id">[]>([]);
  const onResourceMerges = useCallback((items: ResourceMergeItem[]) => {
    setToMergeItems(items);
  }, []);

  useEffect(() => {
    if (!resourcesLoaded) {
      return;
    }
  }, [resourcesLoaded]);

  const selectionActions = useMemo(
    () => [
      {
        action: "merge",
        onHandle: onResourceMerges,
        title: t("common:merge"),
        icon: <MergeTypeIcon />
      },
      { action: "remove" }
    ],
    [onResourceMerges, t]
  );
  const onCancelMergeDialog = useCallback(() => {
    setToMergeItems([]);
  }, []);
  const onResourceMergesClose = useCallback(async () => {
    onCancelMergeDialog();
    setToMergeItems([]);
    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.RESOURCES,
      reloadOverview: Date.now()
    });
    await refreshResources();
  }, [dispatch, onCancelMergeDialog, refreshResources]);
  const [selectedResourceForLinking, setSelectedResourceForLinking] = useState<ResourceDTO | null>(null);
  const closeOrgUnitPicker = useCallback(async () => {
    setSelectedResourceForLinking(null);
    await refreshResources();
  }, [refreshResources]);

  const onOrgUnitLinking = useCallback(
    async (id: string) => {
      setSelectedResourceForLinking(await getSingleResource(resourceType, id));
    },
    [resourceType]
  );
  const onRowMergeTrigger = useCallback(async (id: string) => {
    setToMergeItems([{ id }]);
  }, []);

  const [dialogResourceId, setDialogResourceId] = useState<string | null>(null);
  const onCustomDialogClicked = useCallback(async (id: string) => {
    setDialogResourceId(id);
  }, []);
  const onCustomDialogClosed = useCallback(() => {
    setDialogResourceId(null);
  }, []);
  const onCustomDialogConfirmed = useCallback(() => {
    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.RESOURCES,
      reloadOverview: Date.now()
    });
    return refreshResources();
  }, [dispatch, refreshResources]);
  const ResourceCustomDialog = DialogByResourceType[resourceType];

  const rowActions = useMemo(() => {
    const defaultResourceActions: OverviewRowActionProps[] = [
      {
        action: "linkOrgUnit",
        onHandle: onOrgUnitLinking,
        title: t("resources_overview:linkToolTipText"),
        icon: <LinkIcon />
      },
      {
        action: "merge",
        onHandle: onRowMergeTrigger,
        title: t("common:merge"),
        icon: <MergeTypeIcon />
      },
      { action: "edit" },
      { action: "remove" }
    ];

    if (resourceType === RESOURCE_TYPES.PROCESSING_CATEGORY) {
      return [{ action: "edit" }, { action: "remove" }] satisfies OverviewRowActionProps[];
    }

    if (resourceType === RESOURCE_TYPES.DSR_REQUEST_TYPE) {
      return [
        {
          action: "uploadTemplate",
          onHandle: onCustomDialogClicked,
          title: t("uploadToolTipText"),
          icon: <UploadFileIcon />
        },
        ...defaultResourceActions
      ] satisfies OverviewRowActionProps[];
    }

    if (resourceType === RESOURCE_TYPES.LEGAL_RETENTION_PERIOD) {
      return [
        {
          action: "updateMetadata",
          onHandle: onCustomDialogClicked,
          title: t("common:settings"),
          icon: <SettingsIcon />
        },
        ...defaultResourceActions
      ] satisfies OverviewRowActionProps[];
    }

    return defaultResourceActions;
  }, [onOrgUnitLinking, t, onRowMergeTrigger, resourceType, onCustomDialogClicked]);

  const rowClick = useCallback(
    async item => {
      if (item.unseen === true) {
        await addToSeenItemsOfUserHook(COLLECTIONS.RESOURCES, item.id);
        dispatch({
          type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
          collection: COLLECTIONS.RESOURCES,
          reloadOverview: Date.now(),
          selectedId: item.id
        });
      }
    },
    [addToSeenItemsOfUserHook, dispatch]
  );

  const collectionParams = useMemo(() => ({ resourceType: resourceType }), [resourceType]);

  const showAddActions = auth?.permissions.find(permission => resourcesWritePermissions.includes(permission));

  const docViewContent = (
    <>
      <Overview
        selectionActions={selectionActions}
        onAddOpen={showCreateInfo}
        onAddClose={showEnterInfo}
        onRowOver={onRowOver}
        onRowLeave={showEnterInfo}
        header={t(`header`)}
        collection={COLLECTIONS.RESOURCES}
        collectionParams={collectionParams}
        rowActions={rowActions}
        toolbarActions={toolbarActions}
        addActions={showAddActions ? addActions : undefined}
        checkable={true}
        selectable={true}
        onRowClick={rowClick}
        translationDomainName={`resources_${resourceType}`}
      />
      {ResourceCustomDialog && (
        <ResourceCustomDialog
          open={!!dialogResourceId}
          resourceId={dialogResourceId || ""}
          resourceType={resourceType}
          onClose={onCustomDialogClosed}
          onConfirm={onCustomDialogConfirmed}
        />
      )}
      <ResourceTypeOverviewOrgUnitPicker
        resourceType={resourceType}
        selectedResourceForLinking={selectedResourceForLinking}
        onClose={closeOrgUnitPicker}
        t={t}
      />
      <ResourceMergeModal
        resourceType={resourceType}
        selectedResources={toMergeItems}
        onClose={onResourceMergesClose}
        onCancel={onCancelMergeDialog}
      />
    </>
  );
  const MetaViewByResourceTypeComponent = MetaViewByResourceType[resourceType];
  return (
    <DocMetaView
      metaViewContent={
        MetaViewByResourceTypeComponent && resources.selectedId ? (
          <MetaViewByResourceTypeComponent resourceId={resources.selectedId} />
        ) : (
          <MetaView translationKey={resourceOverviewTranslationContext} />
        )
      }
    >
      {docViewContent}
    </DocMetaView>
  );
};

const MetaViewByResourceType: {
  [key in RESOURCE_TYPE]?: React.FC<ResourceMetaviewProps>;
} = {
  [RESOURCE_TYPES.LEGAL_BASIS]: LegalBasisMetaview
};

const DialogByResourceType: {
  [key in RESOURCE_TYPE]?: React.FC<ResourceDialogProps>;
} = {
  [RESOURCE_TYPES.DSR_REQUEST_TYPE]: DSRTemplateUploadDialog,
  [RESOURCE_TYPES.LEGAL_RETENTION_PERIOD]: LegalRetentionPeriodMetadataDialogWithSave
};

export default ResourceTypeOverview;
