import { OverviewResult, OverviewResultDecorator } from "../overviewBaseController";
import { getAllResources } from "../../../../app/handlers/resourceHandler";
import { ResourceDTO } from "../../../../app/api/resourceApi";

export const resourcesDecorator: OverviewResultDecorator<{ readonly _resources: Record<string, ResourceDTO[]> }> = {
  async decorate(overviewResult: OverviewResult): Promise<{ readonly _resources: Record<string, ResourceDTO[]> }> {
    if (overviewResult._resources) {
      return { _resources: overviewResult._resources };
    }

    const resources = await getAllResources({ showAllSetsIgnoringTenantFeatures: true, showMerged: false });
    return {
      _resources: resources || {}
    };
  }
};
