import React, { useCallback, useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  adornmentIcon: {
    color: theme.palette.grey[700]
  }
}));

export default function OverviewSearchTextField({
  searchKeyword,
  setSearchKeyword,
  size,
  placeholder,
  variant,
  fullWidth = false
}) {
  const classes = useStyles();

  const inputProps = useMemo(() => {
    return {
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon className={classes.adornmentIcon} />
        </InputAdornment>
      )
    };
  }, [classes.adornmentIcon]);

  const onChange = useCallback(
    event => {
      setSearchKeyword(event.target.value);
    },
    [setSearchKeyword]
  );

  return (
    <TextField
      fullWidth={fullWidth}
      id="overview-search-input"
      variant={variant}
      size={size}
      placeholder={placeholder}
      value={searchKeyword}
      onChange={onChange}
      InputProps={inputProps}
    />
  );
}
