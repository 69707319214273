import { copyRiskApi, createRiskApi, deleteRiskApi, getRiskApi, getRisksApi, updateRiskApi } from "../api/riskApi";
import { retryUntil } from "./utility/retry";
import { uniq } from "lodash-es";

export function getAllRisks() {
  return getRisksApi();
}

export function getGeneralRisks() {
  return getRisksApi("general");
}

export function getProcessRisks() {
  return getRisksApi("processing-activity");
}

export async function getRisk(id) {
  try {
    return await getRiskApi(id);
  } catch (error) {
    if (error.response?.status === 404) {
      // 404 is not found, just return null to determine that the data breach does not exists
      return null;
    }
    throw error;
  }
}

export function awaitRiskVersion(id, { riskVersion, assessmentsVersion, treatmentVersion } = {}) {
  return retryUntil(async () => {
    const risk = await getRisk(id);
    if (!risk) {
      throw new Error(`Risk still not exists ${id}`);
    }

    if (riskVersion !== undefined && risk.version < riskVersion) {
      throw new Error(`Existing risk version ${risk.version}, waiting for version ${riskVersion}. Risk ${id}.`);
    }

    if (assessmentsVersion) {
      for (const [phaseId, versions] of Object.entries(assessmentsVersion)) {
        const allAssessmentsInThisPhase = [
          ...risk.assessments[phaseId].individualAssessments,
          risk.assessments[phaseId].combinedAssessment
        ].filter(assessment => assessment);

        for (const [protectionObjectiveId, minVersion] of Object.entries(versions)) {
          const assessment = allAssessmentsInThisPhase.find(
            assessment => assessment.protectionObjectiveId === protectionObjectiveId
          );
          const assessmentVersion = assessment?.version;
          if (assessment === undefined || assessmentVersion < minVersion) {
            throw new Error(
              `Existing risk ${protectionObjectiveId} assessment version ${assessmentVersion}, waiting for version ${minVersion}. Risk ${id}.`
            );
          }
        }
      }
    }

    if (
      treatmentVersion !== undefined &&
      (risk.treatment === undefined || risk.treatment?.version < treatmentVersion)
    ) {
      throw new Error(
        `Existing risk treatment version ${risk.treatment?.version}, waiting for version ${treatmentVersion}. Risk ${id}.`
      );
    }

    return risk;
  });
}

export function deleteRisk(id, version) {
  return deleteRiskApi(id, version);
}

export function awaitRiskDelete(id) {
  return retryUntil(async () => {
    const risk = await getRisk(id);
    if (risk) {
      throw new Error(`Risk ${id} is still not yet deleted`);
    }
  });
}

export function createRisk(title, additionalProperties = {}) {
  return createRiskApi(title, additionalProperties);
}

export async function updateRisk(
  id,
  version,
  {
    title,
    type,
    orgUnitId,
    furtherAffectedOrgUnitIds,
    description,
    ownerUID,
    privacyRelevant,
    riskSourceIds,
    riskAssetIds,
    dataLocationIds,
    protectionObjectiveIds,
    implementedMeasureIds,
    labelIds
  } = {}
) {
  try {
    return await updateRiskApi(id, version, {
      title,
      type,
      orgUnitId,
      furtherAffectedOrgUnitIds,
      description,
      ownerUID,
      privacyRelevant,
      riskSourceIds,
      riskAssetIds,
      dataLocationIds,
      protectionObjectiveIds,
      implementedMeasureIds,
      labelIds
    });
  } catch (error) {
    if (error.response?.status === 409) {
      throw new RiskVersionError(id, version);
    }
    throw error;
  }
}

export const RISK_LEVEL = {
  notApplicable: "notApplicable",
  low: "low",
  medium: "medium",
  high: "high",
  veryHigh: "veryHigh"
};

export function riskRatingToLevel(rating) {
  if (rating === undefined || rating === null || !isFinite(rating)) {
    return RISK_LEVEL.notApplicable;
  }

  if (rating < 4) {
    return RISK_LEVEL.low;
  }
  if (rating >= 4 && rating <= 8) {
    return RISK_LEVEL.medium;
  }
  if (rating > 8 && rating <= 12) {
    return RISK_LEVEL.high;
  }
  if (rating > 12) {
    return RISK_LEVEL.veryHigh;
  }
}

export function incrementVersion(existingVersion) {
  if (existingVersion === null || existingVersion === undefined || !isFinite(existingVersion)) {
    return 0;
  }

  return existingVersion + 1;
}

export function tenantRiskId(risk) {
  return `RI-${risk.tenantRiskId}`;
}

export class RiskVersionError extends Error {
  constructor(riskId, version) {
    super(`Risk ${riskId} latest version is not ${version}`);
  }
}

export const RISK_TREATMENT_TYPES = {
  measures: "measures",
  transfer: "transfer",
  prevention: "prevention",
  accept: "accept"
};

export async function copyRiskAsProcessSpecific(riskId) {
  const copiedRiskId = await copyRiskApi(riskId);
  await awaitRiskVersion(copiedRiskId, { riskVersion: 0 });
  await updateRisk(copiedRiskId, 0, { type: "processing-activity", privacyRelevant: true });
  return await awaitRiskVersion(copiedRiskId, { riskVersion: 1 });
}

/**
 * @param processes {ProcessingActivityOverviewDTO[]}
 */
export function risksAvailableForProcessingActivities(risks, processes, currentProcessId) {
  const generalRisks = risks.filter(risk => risk.type === "general");
  const processRisks = risks.filter(risk => risk.type === "processing-activity");

  const riskIdsWithProcess = processes.reduce((riskIdsSet, nextProcess) => {
    if (nextProcess.id === currentProcessId) {
      return riskIdsSet; // if it's current process, we treat as if the risks are not yet assigned
    }

    for (const riskId of nextProcess.allRiskIds || []) {
      riskIdsSet.add(riskId);
    }
    return riskIdsSet;
  }, new Set());

  const currentProcess = currentProcessId ? processes.find(process => process.id === currentProcessId) : null;
  const currentProcessRiskIds = currentProcess?.allRiskIds || [];

  const processRisksWithoutAssignment = processRisks.filter(
    // only show process risk which is not yet assigned to any process
    // or is assigned to the current process for whatever reason (as we don't want to hide them)
    processRisk => !riskIdsWithProcess.has(processRisk.id) || currentProcessRiskIds.includes(processRisk.id)
  );

  return [...generalRisks, ...processRisksWithoutAssignment];
}

export function measuresAvailableForRisks(measure, risk) {
  const riskProtectionObjectiveIds = risk.protectionObjectiveIds || [];
  const measureProtectionObjectiveIds = measure.protectionObjectiveIds || [];
  return riskProtectionObjectiveIds.some(riskProtectionObjectiveId =>
    measureProtectionObjectiveIds.includes(riskProtectionObjectiveId)
  );
}

export function connectedMeasuresByProtectionObjectiveInRisk(protectionObjectiveId, risk, measures) {
  const allUsedMeasureIDs = uniq([...(risk.implementedMeasureIds || []), ...(risk.treatment?.measureIds || [])]);
  const allUsedMeasures = allUsedMeasureIDs
    .map(measureID => measures.find(measure => measure.id === measureID))
    .filter(nonNullMeasure => nonNullMeasure);
  return allUsedMeasures.filter(measure => (measure.protectionObjectiveIds || []).includes(protectionObjectiveId));
}

export function getAssessmentWithHighestRating(risk, phaseId) {
  for (let assessment of risk?.assessments?.[phaseId]?.individualAssessments || []) {
    if (assessment.rating === risk?.assessments?.[phaseId]?.rating) {
      return assessment;
    }
  }
}
