import ConfirmationModal, {
  ConfirmationModalButtonProps
} from "../../../components/ConfirmationModal/ConfirmationModal";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetProcessorApi, useUpdateProcessorApi } from "../../api/processorPAApi";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

export interface ProcessorPAEditConfirmationContextProps {
  readonly beforeProcessUpdate: (
    execute: () => void | Promise<void>,
    notExecuted?: () => void | Promise<void>
  ) => Promise<void>;
  readonly openModalIfApproved?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  readonly hardResetTrigger: number;
}

const ProcessorPAEditConfirmationContext = createContext<ProcessorPAEditConfirmationContextProps>({
  beforeProcessUpdate: Promise.reject,
  openModalIfApproved: () => {},
  hardResetTrigger: 0
});

export const ProcessorPAEditConfirmationProvider = ({ children }: { readonly children?: React.ReactNode }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useGetProcessorApi({ documentId: id || "" });

  const [open, setOpen] = useState(false);

  const openModalIfApproved = useCallback(
    (e?: React.ChangeEvent<HTMLInputElement>) => {
      if (data?.processorPA?.status === "approved") {
        // need to blur current target before opening modal
        // otherwise it's going to refocus when modal is closed, triggering this again
        e?.target?.blur();
        setOpen(true);
      }
    },
    [data?.processorPA?.status]
  );

  const [hardResetTrigger, setHardResetTrigger] = useState(0);
  const [queuedUpdateFn, setQueuedUpdateFn] = useState<(() => void | Promise<void>) | null>(null);
  const [queuedCancelFn, setQueuedCancelFn] = useState<(() => void | Promise<void>) | null>(null);
  const beforeProcessUpdate = useCallback(
    async (execute: () => void | Promise<void>, notExecuted?: () => void | Promise<void>) => {
      if (data?.processorPA?.status !== "approved") {
        return await execute();
      }
      setQueuedUpdateFn(() => execute);
      setQueuedCancelFn(() => notExecuted || null);
      setOpen(true);
    },
    [data?.processorPA?.status]
  );

  const onClose = useCallback(() => {
    if (queuedCancelFn) {
      queuedCancelFn();
    }
    setQueuedUpdateFn(null);
    setQueuedCancelFn(null);
    setOpen(false);
    setHardResetTrigger(prevValue => prevValue + 1);
  }, [queuedCancelFn]);

  useEffect(() => {
    setQueuedUpdateFn(null);
    setQueuedCancelFn(null);
    setOpen(false);
  }, [id]);

  const { updateProcessor } = useUpdateProcessorApi({ documentId: id || "" });

  const editCallback = useCallback(async () => {
    await updateProcessor({ status: "edit" });
    enqueueSnackbar(t("processor_pa_page:edit_snackbar"));
    if (queuedUpdateFn) {
      queuedUpdateFn();
    }
    setQueuedUpdateFn(null);
    setQueuedCancelFn(null);
    setOpen(false);
  }, [enqueueSnackbar, t, updateProcessor, queuedUpdateFn]);

  const confirmationModalButton = useMemo<ConfirmationModalButtonProps[]>(
    () => [
      {
        confirmButton: false,
        title: t("common:no"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: onClose
      },
      {
        confirmButton: true,
        title: t("common:yes"),
        variant: "contained",
        color: "primary",
        size: "medium",
        onClick: editCallback
      }
    ],
    [t, onClose, editCallback]
  );

  const contextValue = useMemo<ProcessorPAEditConfirmationContextProps>(
    () => ({
      beforeProcessUpdate,
      openModalIfApproved,
      hardResetTrigger
    }),
    [beforeProcessUpdate, openModalIfApproved, hardResetTrigger]
  );

  return (
    <ProcessorPAEditConfirmationContext.Provider value={contextValue}>
      {children}
      <ConfirmationModal
        modalOpen={open}
        onClose={onClose}
        modalTitle={t("processor_pa_page:edit_modal_title")}
        modalText={t("processor_pa_page:edit_modal_text")}
        buttons={confirmationModalButton}
      />
    </ProcessorPAEditConfirmationContext.Provider>
  );
};

export const useProcessorPAEditConfirmation = () => useContext(ProcessorPAEditConfirmationContext);
