import React, { useEffect, useState } from "react";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { AlertProps } from "@material-ui/lab/Alert/Alert";

const useStylesInfo = makeStyles((theme: any) => ({
  root: {
    alignItems: "center",
    color: theme.palette.alert.info,
    backgroundColor: theme.palette.alert.infoBackground,
    "& .MuiAlert-icon": {
      color: theme.palette.alert.info
    },
    borderRadius: 6
  }
}));

const useStylesSuccess = makeStyles((theme: any) => ({
  root: {
    alignItems: "center",
    color: theme.palette.alert.success,
    backgroundColor: theme.palette.alert.successBackground,
    "& .MuiAlert-icon": {
      color: theme.palette.alert.success
    },
    borderRadius: 6
  }
}));

const useStylesWarning = makeStyles((theme: any) => ({
  root: {
    alignItems: "center",
    color: theme.palette.alert.warning,
    backgroundColor: theme.palette.alert.warningBackground,
    "& .MuiAlert-icon": {
      color: theme.palette.alert.warning
    },
    borderRadius: 6
  }
}));

const useStylesError = makeStyles((theme: any) => ({
  root: {
    alignItems: "center",
    color: theme.palette.alert.error,
    backgroundColor: theme.palette.alert.errorBackground,
    "& .MuiAlert-icon": {
      color: theme.palette.alert.error
    },
    paddingRight: "25px",
    paddingBottom: "8px",
    borderRadius: 6
  }
}));

const useStylesFullWidthMessage = makeStyles(() => ({
  message: {
    width: "100%"
  }
}));

export interface CustomAlertProps extends Pick<AlertProps, "severity" | "onClose" | "icon" | "id" | "style"> {
  readonly children?: React.ReactNode;
  readonly fullWidth?: boolean;
}

export default function CustomAlert({ severity, onClose, icon, id, children, style, fullWidth }: CustomAlertProps) {
  const [classes, setClasses] = useState<AlertProps["classes"]>({});
  const infoClasses = useStylesInfo();
  const successClasses = useStylesSuccess();
  const warningClasses = useStylesWarning();
  const errorClasses = useStylesError();
  const fullWidthClases = useStylesFullWidthMessage();
  useEffect(() => {
    const fullWidthClasses = fullWidth ? fullWidthClases : {};
    switch (severity) {
      case "info":
        setClasses({
          ...fullWidthClasses,
          ...infoClasses
        });
        break;
      case "warning":
        setClasses({
          ...fullWidthClasses,
          ...warningClasses
        });
        break;
      case "success":
        setClasses({
          ...fullWidthClasses,
          ...successClasses
        });
        break;
      case "error":
        setClasses({
          ...fullWidthClasses,
          ...errorClasses
        });
        break;
      default:
        setClasses({
          ...fullWidthClasses,
          ...infoClasses
        });
    }
  }, [severity, infoClasses, successClasses, warningClasses, errorClasses, fullWidth, fullWidthClases]);

  return (
    <Alert classes={classes} style={style} severity={severity} onClose={onClose} icon={icon} id={id}>
      {children}
    </Alert>
  );
}
