import React, { useCallback } from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { alpha, withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { Department } from "../../../handlers/departmentHandler";
import { createNaturalSorter } from "../../../utils/naturalSort";

const StyledTreeItemWithCheckbox = withStyles((theme: any) => ({
  group: {
    marginLeft: -22,
    borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.15)}`
  }
}))(TreeItem);

function TreeNodeLabel({ node }: { readonly node: Department }) {
  return <span>{node.name}</span>;
}

function TreeNode({
  node,
  allDepartments,
  availableDepartments,
  checkedDepartmentIds,
  onChecked
}: {
  readonly node: Department;
  readonly allDepartments: Department[];
  readonly availableDepartments?: Department[];
  readonly checkedDepartmentIds: string[];
  readonly onChecked: (department: Department, checked: boolean) => void;
}) {
  const disabled = availableDepartments && !availableDepartments.some(({ id }) => node.id === id);
  const children = allDepartments.filter(department => department.parentId === node.id);
  const naturalSorter = createNaturalSorter();
  const checked = checkedDepartmentIds.includes(node.id);
  const handleCheckboxChange = useCallback(() => {
    onChecked(node, !checked);
  }, [node, onChecked, checked]);

  if (!node?.id) {
    return <div>An error occurred, no data was found.</div>;
  }

  return (
    <Box sx={{ display: "flex" }} data-testid={`org-unit-tree-node-${node.id}${disabled ? "-disabled" : ""}`}>
      <Box>
        <Checkbox
          color="primary"
          checked={checked}
          onChange={handleCheckboxChange}
          disabled={disabled}
          data-testid={`org-unit-tree-node-${node.id}-checkbox-${checked ? "checked" : "unchecked"}`}
        />
      </Box>
      <Box pt={1.125}>
        <StyledTreeItemWithCheckbox nodeId={node.id} label={<TreeNodeLabel node={node} />}>
          {children
            .sort((a, b) => naturalSorter(a.name, b.name))
            .map(child => (
              <TreeNode
                key={child.id}
                node={child}
                allDepartments={allDepartments}
                availableDepartments={availableDepartments}
                checkedDepartmentIds={checkedDepartmentIds}
                onChecked={onChecked}
              />
            ))}
        </StyledTreeItemWithCheckbox>
      </Box>
    </Box>
  );
}

export default function OrgUnitTree({
  allDepartments,
  availableDepartments,
  checkedDepartmentIds,
  onCheckedChanged
}: {
  readonly allDepartments: Department[];
  readonly availableDepartments?: Department[];
  readonly checkedDepartmentIds: string[];
  readonly onCheckedChanged: (input: string[]) => void;
}) {
  const getRootDepartments = (): Department[] => {
    const noParentDepartments = allDepartments.find(({ parentId }) => !parentId);
    if (noParentDepartments) {
      return [noParentDepartments];
    } else {
      return allDepartments.filter(({ parentId }) => !allDepartments.some(({ id }) => id === parentId));
    }
  };
  const onChecked = useCallback(
    (department, checked) => {
      const getChildrenOf = (parentId: string): Department[] => {
        return allDepartments.filter(department => department.parentId === parentId);
      };

      const getRecursiveChildrenOf = (parentId: string): Department[] => {
        return getChildrenOf(parentId).flatMap(department => [department, ...getRecursiveChildrenOf(department.id)]);
      };

      const impactedDepartmentIDs = [department, ...getRecursiveChildrenOf(department.id)].map(
        department => department.id
      );
      if (checked) {
        onCheckedChanged([...new Set([...checkedDepartmentIds, ...impactedDepartmentIDs])]);
      } else {
        onCheckedChanged(checkedDepartmentIds.filter(departmentId => !impactedDepartmentIDs.includes(departmentId)));
      }
    },
    [allDepartments, onCheckedChanged, checkedDepartmentIds]
  );

  return (
    <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
      {getRootDepartments().map(node => (
        <TreeNode
          key={node.id}
          node={node}
          allDepartments={allDepartments}
          availableDepartments={availableDepartments}
          checkedDepartmentIds={checkedDepartmentIds}
          onChecked={onChecked}
        />
      ))}
    </TreeView>
  );
}
