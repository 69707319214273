import { IconButton, Tooltip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  emptyButton: {
    width: "36px",
    height: "36px",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main
    }
  },
  nonEmptyButton: {
    width: "36px",
    height: "36px",
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main
    }
  }
}));

interface OverviewIconButtonProps {
  readonly tooltip?: React.ReactNode;
  readonly nonEmpty?: boolean;
  readonly disabled?: boolean;
  readonly onClick?: (val: any) => void;
  readonly children: React.ReactNode;
}

const OverviewIconButton = ({ tooltip, nonEmpty, disabled, children, onClick }: OverviewIconButtonProps) => {
  const cls = useStyles();
  const btnEl = (
    <IconButton
      className={`${nonEmpty ? cls.nonEmptyButton : cls.emptyButton}`}
      aria-controls="simple-menu"
      aria-haspopup="true"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </IconButton>
  );

  if (tooltip) {
    return <Tooltip title={tooltip}>{btnEl}</Tooltip>;
  } else return btnEl;
};

export default OverviewIconButton;
