import React, { useCallback, useEffect, useState } from "react";
import { Box, ClickAwayListener, IconButton, makeStyles, Popper } from "@material-ui/core";
import QuestionTitle from "components/QuestionTitle/QuestionTitle";
import { useParams } from "react-router-dom";
import { getTextFromTextEditorJsonString } from "app/pages/questionnaires/utils/textEditorConverter";
import TranslateOutlinedIcon from "@material-ui/icons/TranslateOutlined";
import TranslatorMenu from "app/pages/shared/TranslatorMenu/TranslatorMenu";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { useTranslator } from "app/contexts/translator-context";

interface TranslationQuestionProps {
  readonly title?: string;
  readonly questionId?: string;
  readonly questionName?: string;
  readonly disabled?: boolean;
  readonly translatable?: string;
  readonly translationId?: string;
  readonly onFocus?: () => void;
  readonly children?: React.ReactNode;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    position: "relative",
    cursor: "pointer"
  },
  translateTextBox: {
    borderRadius: 6,
    background: theme.palette.lila[50]
  },
  translateText: {
    width: "100%",
    color: theme.palette.lila[500],
    whiteSpace: "pre-wrap",
    fontSize: 14
  },
  translationButton: {
    position: "absolute",
    top: 44,
    right: -22
  },
  translationButtonNoTitle: {
    position: "absolute",
    top: 16,
    right: -22
  }
}));

// popper doesn't have z-index for philosophical reason
// https://github.com/mui/material-ui/issues/18905
// however, sometimes we open stuffs inside a modal, so it have to be higher
const zIndexHigherThanInsideOfAModal = 1301;
export const TranslationPopper = ({
  translationId,
  anchorTranslate,
  hasContentToTranslate,
  onSelectLanguage
}: {
  readonly translationId: string;
  readonly anchorTranslate: any;
  readonly hasContentToTranslate: boolean;
  readonly onSelectLanguage?: () => void;
}) => {
  const hasTranslationFeature = useIsFeaturePresent(FEATURES.DEEP_L);

  return hasTranslationFeature && hasContentToTranslate ? (
    <Popper
      open={!!anchorTranslate}
      anchorEl={anchorTranslate}
      placement={"right-start"}
      style={{ zIndex: zIndexHigherThanInsideOfAModal }}
    >
      <Box ml={1}>
        <TranslatorMenu translationId={translationId} onSelectLanguage={onSelectLanguage} />
      </Box>
    </Popper>
  ) : (
    <></>
  );
};

export const TranslationResult = ({
  translationId,
  contentToTranslate
}: {
  readonly translationId: string;
  readonly contentToTranslate: string;
}) => {
  const cls = useStyles();
  const hasTranslationFeature = useIsFeaturePresent(FEATURES.DEEP_L);
  const { findTranslation, translateHook, setTextToTranslateHook } = useTranslator();
  const [translatedText, setTranslatedText] = useState("");

  useEffect(() => {
    setTextToTranslateHook(translationId, contentToTranslate);
    const newTranslation = { ...findTranslation(translationId), textToTranslate: contentToTranslate };
    translateHook(newTranslation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationId, contentToTranslate, setTextToTranslateHook, translateHook]);

  useEffect(() => {
    const foundTranslation = findTranslation(translationId);
    if (foundTranslation) {
      setTranslatedText(foundTranslation.translatedText || "");
    } else {
      setTranslatedText("");
    }
  }, [findTranslation, translationId]);

  const boxResultEl = (
    <Box className={cls.translateTextBox} mt={1} p={2}>
      <p className={cls.translateText} data-testid="form-helper-text">
        {translatedText}
      </p>
    </Box>
  );

  return hasTranslationFeature && translatedText ? boxResultEl : <></>;
};

export const TranslationQuestion = ({ title, translatable, translationId, children }: TranslationQuestionProps) => {
  const cls = useStyles();
  const [focused, setFocused] = useState(false);
  const [anchorTranslate, setAnchorTranslate] = useState(null);
  const [contentToTranslate, setContentToTranslate] = useState("");
  const hasTranslationFeature = useIsFeaturePresent(FEATURES.DEEP_L);
  useEffect(() => {
    setContentToTranslate(getTextFromTextEditorJsonString(translatable || ""));
  }, [translatable]);

  const params = useParams();
  const documentId = params?.id;
  const id = `translationId_${documentId}_${translationId}`;

  const onOpen = useCallback(event => {
    setAnchorTranslate(event.currentTarget);
    setFocused(true);
  }, []);

  const onClose = useCallback(() => {
    setTimeout(() => {
      setAnchorTranslate(null);
      setFocused(false);
    }, 1);
  }, []);

  const onFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const onBlur = useCallback(() => {
    // do
  }, []);

  const titleEl = title && <QuestionTitle>{title}</QuestionTitle>;

  const translateButtonEl = focused && (
    <IconButton
      color="primary"
      aria-label="translation"
      className={`${title ? cls.translationButton : cls.translationButtonNoTitle}`}
      onClick={onOpen}
    >
      <TranslateOutlinedIcon fontSize="small" />
    </IconButton>
  );

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box className={cls.root} pt={3} pr={3} mr={-3} onFocus={onFocus} onBlur={onBlur} tabIndex={1}>
        {titleEl}
        {children}
        {hasTranslationFeature && (
          <>
            {translateButtonEl}
            <TranslationPopper
              translationId={id}
              anchorTranslate={anchorTranslate}
              hasContentToTranslate={!!contentToTranslate}
              onSelectLanguage={onClose}
            />
            <TranslationResult translationId={id} contentToTranslate={contentToTranslate} />
          </>
        )}
      </Box>
    </ClickAwayListener>
  );
};
