import React, { useCallback, useEffect, useMemo, useState } from "react";
import Overview from "../../../components/Overview/Overview";
import { COLLECTIONS } from "../../collections";
import { useTranslation } from "react-i18next";
import { OVERVIEW_ADD_TYPE } from "../../../components/Overview/constants/OverviewConstants";
import { useMetaView } from "../../contexts/meta-view-context";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "../../contexts/overview-context";
import { useUserAndTenantData } from "../../handlers/userAndTenant/user-tenant-context";
import LinkIcon from "@material-ui/icons/Link";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import { OverviewItem } from "../../../components/Overview/controllers/overviewBaseController";
import { DataLocationMergeItem, DataLocationMergeModal } from "./DataLocationMergeModal";
import { DataLocationLinkItem, DataLocationLinkModal } from "./DataLocationLinkModal";
import UnlinkIcon from "@material-ui/icons/LinkOff";
import { DataLocationUnlinkItem, DataLocationUnlinkModal } from "./DataLocationUnlinkModal";

const DataLocationOverview = ({
  externalRecipientId,
  hideTitle,
  onDataLocationClicked,
  onReloadMetaview,
  dataLocationIds,
  hideActions
}: {
  externalRecipientId?: string;
  hideTitle?: boolean;
  dataLocationIds?: string[] | undefined;
  hideActions?: boolean;
  onDataLocationClicked?: (dataLocation: { readonly id: string }) => void;
  onReloadMetaview?: (reloadKey: { readonly reloadKey: string }) => void;
}) => {
  const { t } = useTranslation("service_providers_overview");
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();
  const { setInfo } = useMetaView();
  const dispatch = useOverviewDispatch();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t(externalRecipientId ? "product_tab:infoTitle" : "enteringDataSourcesLocationOverviewInfoCardTitle"),
        text: t(externalRecipientId ? "product_tab:infoText" : "enteringDataSourcesLocationOverviewInfoCardText")
      }
    }),
    [t, externalRecipientId]
  );

  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard.entering, setInfo]);

  const toolbarActions = useMemo(
    () => [
      { action: "sort" },
      { action: "filter" },
      { action: "add" },
      {
        action: "mark-all-as-read"
      }
    ],
    []
  );

  const addActions = useMemo(() => [{ action: OVERVIEW_ADD_TYPE.SINGLE }], []);

  const onRowClick = useCallback(
    async (item: OverviewItem) => {
      if (onDataLocationClicked) {
        await onDataLocationClicked({ id: item.id });
      }

      if (!item.badges?.some(badge => badge.kind === "seen")) {
        return;
      }
      await addToSeenItemsOfUserHook(COLLECTIONS.DATA_LOCATIONS, item.id);
      dispatch({
        type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
        collection: COLLECTIONS.DATA_LOCATIONS,
        reloadOverview: Date.now()
      });
    },
    [addToSeenItemsOfUserHook, dispatch, onDataLocationClicked]
  );

  const collectionParams = useMemo(
    () => ({ externalRecipientId, dataLocationIds }),
    [externalRecipientId, dataLocationIds]
  );

  const [toMergeItems, setToMergeItems] = useState<DataLocationMergeItem[]>([]);
  const [toLinkItems, setToLinkItems] = useState<DataLocationLinkItem[]>([]);
  const [toUnlinkItems, setToUnlinkItems] = useState<DataLocationUnlinkItem[]>([]);
  const onDataLocationMerges = useCallback((items: DataLocationOverviewItem[]) => {
    setToMergeItems(items);
  }, []);
  const onDataLocationLinks = useCallback((items: DataLocationOverviewItem[]) => {
    setToLinkItems(items);
  }, []);
  const onDataLocationUnlinks = useCallback((items: DataLocationOverviewItem[]) => {
    setToUnlinkItems(items);
  }, []);
  const onCancelDialog = useCallback(() => {
    setToMergeItems([]);
    setToLinkItems([]);
    setToUnlinkItems([]);
    if (onReloadMetaview) {
      onReloadMetaview({ reloadKey: Date.now().toLocaleString() });
    }
  }, [onReloadMetaview]);
  const onDataLocationMergesClose = useCallback(() => {
    onCancelDialog();
    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.DATA_LOCATIONS,
      reloadOverview: Date.now()
    });
  }, [dispatch, onCancelDialog]);
  const onDataLocationLinksClose = useCallback(() => {
    onCancelDialog();
    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.DATA_LOCATIONS,
      reloadOverview: Date.now()
    });
  }, [dispatch, onCancelDialog]);
  const onDataLocationUnlinksClose = useCallback(() => {
    onCancelDialog();
    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.DATA_LOCATIONS,
      reloadOverview: Date.now()
    });
  }, [dispatch, onCancelDialog]);

  const selectionActions = useMemo(
    () => [
      {
        action: "dataLocationMerges",
        onHandle: onDataLocationMerges,
        title: t("common:merge"),
        icon: <MergeTypeIcon />
      },
      {
        action: "dataLocationLinks",
        onHandle: onDataLocationLinks,
        title: t("common:link"),
        icon: <LinkIcon />
      },
      {
        action: "dataLocationUnlinks",
        onHandle: onDataLocationUnlinks,
        title: t("common:unlink"),
        icon: <UnlinkIcon />
      },
      { action: "remove" }
    ],
    [t, onDataLocationMerges, onDataLocationLinks, onDataLocationUnlinks]
  );

  const rowUnlinkCallback = useCallback((itemId: string) => {
    setToUnlinkItems([{ id: itemId }]);
  }, []);
  const rowLinkCallback = useCallback((itemId: string) => {
    setToLinkItems([{ id: itemId }]);
  }, []);
  const rowActions = useMemo(
    () => [
      {
        action: "edit"
      },
      {
        action: "link",
        onHandle: rowLinkCallback,
        title: t("common:link"),
        icon: <LinkIcon />
      },
      {
        action: "unlink",
        onHandle: rowUnlinkCallback,
        title: t("common:unlink"),
        icon: <UnlinkIcon />
      },
      {
        action: "remove"
      }
    ],
    [t, rowUnlinkCallback, rowLinkCallback]
  );

  return (
    <>
      <Overview
        selectionActions={selectionActions}
        onRowClick={onRowClick}
        rowActions={hideActions ? [] : rowActions}
        toolbarActions={hideActions ? [] : toolbarActions}
        addActions={hideActions ? [] : addActions}
        header={hideTitle ? undefined : t("service_providers_overview:data_tab_title")}
        dnd={false}
        collection={COLLECTIONS.DATA_LOCATIONS}
        collectionParams={collectionParams}
        onRowOver={undefined}
        onRowLeave={undefined}
        onAddOpen={undefined}
        onAddClose={undefined}
        checkable={hideActions ? false : true}
      />
      <DataLocationMergeModal
        selectedDataLocations={toMergeItems}
        onClose={onDataLocationMergesClose}
        onCancel={onCancelDialog}
      />
      <DataLocationLinkModal
        selectedDataLocations={toLinkItems}
        onClose={onDataLocationLinksClose}
        onCancel={onCancelDialog}
      />
      <DataLocationUnlinkModal
        selectedDataLocations={toUnlinkItems}
        onClose={onDataLocationUnlinksClose}
        onCancel={onCancelDialog}
      />
    </>
  );
};

export interface DataLocationOverviewItem extends OverviewItem {
  readonly externalRecipientId: string | null;
}

export default React.memo(DataLocationOverview);
