import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMetaView } from "../../../contexts/meta-view-context";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { Box, Grid, TextField } from "@material-ui/core";
import Question from "components/Question/Question";
import TextEditor from "app/pages/questionnaires/utils/TextEditor";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { ASSET_STATUS, AssetUpdateDTO } from "../types/AssetTypes";
import { LabelField } from "components/LabelField";
import { updateAsset } from "../handler/assetHandler";
import AssignUsersMultiAutocomplete from "../../questionnaires/utils/AssignUsersMultiAutocomplete/AssignUsersMultiAutocomplete";
import { AttachmentsOverviewOBS } from "../../shared/Attachments/AttachmentsOverviewOBS";
import { ResourceField } from "components/ResourceField";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";

const AssetsDetails = ({
  assetData,
  onSetAssetName
}: {
  assetData?: AssetUpdateDTO | null;
  onSetAssetName?: (name: string) => void;
}) => {
  const { t } = useTranslation("asset_details");
  const { setInfo } = useMetaView();
  const [asset, setAsset] = useState<AssetUpdateDTO | null>(null);

  useEffect(() => {
    if (assetData && assetData.id) {
      return setAsset(assetData);
    }
  }, [assetData]);

  const updateData = useCallback(
    async data => {
      if (asset && asset.id) {
        return await updateAsset(asset?.id, data);
      }
    },
    [asset]
  );

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringInfoCardText")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  /* name */
  const setName = useCallback(
    async event => {
      const name = event.target.value;
      await updateData({ name });
      onSetAssetName?.(name);
    },
    [onSetAssetName, updateData]
  );
  const changeName = useCallback(event => {
    setAsset(asset => ({ ...asset, name: event.target.value }));
  }, []);

  /* description */
  const setDescription = useCallback(() => {
    updateData({ description: asset?.description });
  }, [asset?.description, updateData]);

  const changeDescription = useCallback(description => {
    setAsset(asset => ({ ...asset, description }));
  }, []);

  /* types */
  const setType = useCallback(
    resourceAssetTypeId => {
      setAsset(asset => ({ ...asset, resourceAssetTypeId }));
      updateData({ resourceAssetTypeId });
    },
    [updateData]
  );

  /* amount */
  const setAmount = useCallback(
    event => {
      updateData({ amount: +event.target.value });
    },
    [updateData]
  );
  const changeAmount = useCallback(event => {
    setAsset(asset => ({ ...asset, amount: event.target.value }));
  }, []);

  /* assetOwnerId */
  const setAssetOwnerId = useCallback(
    assetOwnerIds => {
      const assetOwnerId = assetOwnerIds.length ? assetOwnerIds[0] : null;
      setAsset(asset => ({ ...asset, assetOwnerId }));
      updateData({ assetOwnerId });
    },
    [updateData]
  );

  /* riskOwnerId */
  const setRiskOwnerId = useCallback(
    riskOwnerIds => {
      const riskOwnerId = riskOwnerIds.length ? riskOwnerIds[0] : null;
      setAsset(asset => ({ ...asset, riskOwnerId }));
      updateData({ riskOwnerId });
    },
    [updateData]
  );
  /* status */
  const setStatus = useCallback(
    status => {
      setAsset(asset => ({ ...asset, status }));
      updateData({ status });
    },
    [updateData]
  );
  const getStatusLabels = useCallback(
    statusVal => {
      return t(`assets_overview:${statusVal.toLowerCase()}`);
    },
    [t]
  );
  const statuses = useMemo(() => Object.values(ASSET_STATUS), []);

  /* labelIds */
  const setLabelIds = useCallback(() => {
    updateData({ labelIds: asset?.labelIds });
  }, [asset?.labelIds, updateData]);
  const changeLabelIds = useCallback(labelIds => {
    setAsset(asset => ({ ...asset, labelIds }));
  }, []);

  return (
    asset && (
      <>
        <QuestionnaireSubHeader text={t("generalInformation")} />
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Question>
              <TextField
                id={"name"}
                fullWidth={true}
                label={t("name")}
                variant="outlined"
                value={asset.name || ""}
                onChange={changeName}
                onBlur={setName}
              />
            </Question>
          </Grid>
          <Grid item xs={6}>
            <Question>
              <TextField
                id={"assetId"}
                fullWidth={true}
                label={t("assetId")}
                variant="outlined"
                disabled
                InputLabelProps={{ shrink: true }}
                value={asset?.assetId}
              />
            </Question>
          </Grid>
        </Grid>
        <Question translatable={asset.description} translationId={"asset-description"} title={t("description")}>
          <TextEditor
            testId={"description"}
            inputValue={asset.description || ""}
            onBlur={setDescription}
            onChange={changeDescription}
            titleComponent={undefined}
            focused={undefined}
          />
        </Question>
        <Grid container>
          <Grid item xs={6}>
            <Box mr={3}>
              <Question>
                <ResourceField
                  id="request_type"
                  value={asset.resourceAssetTypeId || ""}
                  onChange={setType}
                  resourceType={RESOURCE_TYPES.ASSET_TYPE}
                  label={t("resourceAssetTypeId")}
                  multiSelect={false}
                />
              </Question>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box ml={3}>
              <Question>
                <TextField
                  id={"amount"}
                  fullWidth={true}
                  label={t("amount")}
                  variant="outlined"
                  value={asset.amount}
                  type={"number"}
                  onBlur={setAmount}
                  onChange={changeAmount}
                  InputLabelProps={{ shrink: true }}
                />
              </Question>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Box mr={3}>
              <Question>
                <AssignUsersMultiAutocomplete
                  id={"assetOwnerId"}
                  docAssignedUserIds={asset.assetOwnerId ? [asset.assetOwnerId] : []}
                  onDocAssignedUserIdsChanged={setAssetOwnerId}
                  freeSolo={true}
                  disableClearable={true}
                  label={t("assetOwnerId")}
                  excludedUserIds={[]}
                  hasMultiSelect={false}
                  hasDefaultValue={false}
                />
              </Question>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box ml={3}>
              <Question>
                <AssignUsersMultiAutocomplete
                  id={"riskOwnerId"}
                  docAssignedUserIds={asset.riskOwnerId ? [asset.riskOwnerId] : []}
                  onDocAssignedUserIdsChanged={setRiskOwnerId}
                  freeSolo={true}
                  disableClearable={true}
                  label={t("riskOwenrId")}
                  excludedUserIds={[]}
                  hasMultiSelect={false}
                  hasDefaultValue={false}
                />
              </Question>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Question>
              <MultiAutocomplete<string, false, true, false>
                id="status"
                label={t("status")}
                hasMultiSelect={false}
                selected={asset.status || ""}
                updateSelected={setStatus}
                options={statuses}
                getOptionLabel={getStatusLabels}
                onFocus={undefined}
                onBlur={undefined}
              />
            </Question>
          </Grid>
          <Grid item xs={6}>
            <Question>
              <LabelField onBlur={setLabelIds} selectedIDs={asset.labelIds} onSelectionChanged={changeLabelIds} />
            </Question>
          </Grid>
        </Grid>
        <Question>
          <QuestionnaireSubHeader text={t("common:attachments")} />
          <AttachmentsOverviewOBS docId={asset.id} category={"assets"} />
        </Question>
      </>
    )
  );
};

export default React.memo(AssetsDetails);
