import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAuthentication } from "../handlers/authentication/authentication-context";
import {
  ExternalRecipientDocumentDTO,
  ExternalRecipientDTO,
  ExternalRecipientPayload
} from "../api/externalRecipientApi";
import {
  approveExternalRecipient,
  createExternalRecipient,
  createExternalRecipientsFromTemplate,
  deleteExternalRecipient,
  getExternalRecipient,
  getExternalRecipients,
  unApproveExternalRecipient,
  updateExternalRecipient
} from "../handlers/externalRecipientHandler";
import { useUserAndTenantData } from "../handlers/userAndTenant/user-tenant-context";
import { COLLECTIONS } from "../collections";
import { keyBy } from "lodash-es";
import { useSidebarSWR } from "../pages/shared/Sidebar/useSidebarUnseen";

export interface ExternalRecipientsContextType {
  readonly initialized: boolean;
  readonly externalRecipient: ExternalRecipientDocumentDTO | null;
  readonly externalRecipients: ExternalRecipientDTO[];
  readonly externalRecipientsDict: Record<string, ExternalRecipientDTO>;
  readonly loadExternalRecipientHook: (externalRecipientId: string) => Promise<void>;
  readonly updateExternalRecipientHook: (
    externalRecipientId: string,
    fieldValue: ExternalRecipientPayload
  ) => Promise<void>;
  readonly loadExternalRecipientsHook: () => Promise<void>;
  readonly createExternalRecipientHook: (
    createPayload: ExternalRecipientPayload,
    addToSeenItemsOfUser: boolean
  ) => Promise<string | undefined>;
  readonly deleteExternalRecipientHook: (externalRecipientId: string) => Promise<void>;
  readonly approveExternalRecipientHook: (externalRecipientId: string) => Promise<void>;
  readonly unApproveExternalRecipientHook: (externalRecipientId: string) => Promise<void>;
  readonly createExternalRecipientFromTemplateHook: (templateIds: string[]) => Promise<void>;
}

export const ExternalRecipientNewContext = createContext<ExternalRecipientsContextType>({
  // initialize initial values, for those who needs explicit return types, throw an error
  initialized: false,
  externalRecipient: null,
  externalRecipients: [],
  externalRecipientsDict: {},
  loadExternalRecipientHook: async () => {
    return;
  },
  loadExternalRecipientsHook: async () => {
    return;
  },
  createExternalRecipientHook: async () => {
    throw new Error("Yet not initialized");
  },
  deleteExternalRecipientHook: async () => {
    return;
  },
  updateExternalRecipientHook: async () => {
    return;
  },
  approveExternalRecipientHook: async () => {
    return;
  },
  unApproveExternalRecipientHook: async () => {
    return;
  },
  createExternalRecipientFromTemplateHook: async () => {
    return;
  }
});

export const ExternalRecipientProvider = ({ children }: { readonly children?: React.ReactNode }) => {
  const [initialized, setInitialized] = useState(false);
  const [externalRecipient, setExternalRecipient] = useState<ExternalRecipientDocumentDTO | null>(null);
  const [externalRecipients, setExternalRecipients] = useState<ExternalRecipientDTO[]>([]);
  const [externalRecipientsDict, setExternalRecipientsDict] = useState<Record<string, ExternalRecipientDTO>>({});
  const { unseenERsMutate, unseenDLsMutate } = useSidebarSWR();
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();

  const { auth } = useAuthentication();

  const loadExternalRecipientsHook = useCallback(async () => {
    if (auth) {
      const externalRecipients = await getExternalRecipients();
      setExternalRecipients(externalRecipients);
      setExternalRecipientsDict(keyBy(externalRecipients, "id"));
      if (!initialized) {
        setInitialized(true);
      }
    }
  }, [auth, initialized]);

  useEffect(() => {
    if (auth?.tenantId) {
      loadExternalRecipientsHook();
    }
  }, [auth?.tenantId, loadExternalRecipientsHook]);

  const loadExternalRecipientHook = useCallback(
    async externalRecipientID => {
      if (auth) {
        const externalRecipientDocument = await getExternalRecipient(externalRecipientID);
        setExternalRecipient(externalRecipientDocument);
      }
    },
    [auth]
  );

  const deleteExternalRecipientHook = useCallback(
    async (externalRecipientId: string) => {
      if (auth) {
        await deleteExternalRecipient(externalRecipientId);
        loadExternalRecipientsHook();
      }
    },
    [auth, loadExternalRecipientsHook]
  );

  const createExternalRecipientHook = useCallback(
    async (createPayload: ExternalRecipientPayload, addToSeenItems?: boolean) => {
      if (auth) {
        const createdExternalRecipientId = (await createExternalRecipient(createPayload)).id;
        if (addToSeenItems) {
          await addToSeenItemsOfUserHook(COLLECTIONS.EXTERNAL_RECIPIENTS, createdExternalRecipientId);
        } else {
          unseenERsMutate();
        }
        return createdExternalRecipientId;
      }
    },
    [addToSeenItemsOfUserHook, auth, unseenERsMutate]
  );

  const updateExternalRecipientHook = useCallback(
    async (externalRecipientId: string, fieldValue: ExternalRecipientPayload) => {
      await updateExternalRecipient(externalRecipientId, fieldValue);
      loadExternalRecipientsHook();
    },
    [loadExternalRecipientsHook]
  );

  const approveExternalRecipientHook = useCallback(
    async (externalRecipientId: string) => {
      await approveExternalRecipient(externalRecipientId);
      loadExternalRecipientsHook();
    },
    [loadExternalRecipientsHook]
  );

  const unApproveExternalRecipientHook = useCallback(
    async (externalRecipientId: string) => {
      await unApproveExternalRecipient(externalRecipientId);
      loadExternalRecipientsHook();
    },
    [loadExternalRecipientsHook]
  );

  const createExternalRecipientFromTemplateHook = useCallback(
    async templateIds => {
      if (auth?.uid) {
        await createExternalRecipientsFromTemplate(templateIds, auth.uid, auth?.tenantId);
        loadExternalRecipientsHook();
        unseenERsMutate();
        unseenDLsMutate();
      }
    },
    [auth?.uid, auth?.tenantId, loadExternalRecipientsHook, unseenERsMutate, unseenDLsMutate]
  );

  return (
    <ExternalRecipientNewContext.Provider
      value={{
        initialized,
        externalRecipient,
        externalRecipients,
        externalRecipientsDict,
        loadExternalRecipientHook,
        updateExternalRecipientHook,
        approveExternalRecipientHook,
        unApproveExternalRecipientHook,
        loadExternalRecipientsHook,
        createExternalRecipientHook,
        deleteExternalRecipientHook,
        createExternalRecipientFromTemplateHook
      }}
    >
      {children}
    </ExternalRecipientNewContext.Provider>
  );
};

export const useExternalRecipients = () => useContext(ExternalRecipientNewContext);
