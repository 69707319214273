import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAuthentication } from "../handlers/authentication/authentication-context";
import { useTranslation } from "react-i18next";
import {
  createNewResource,
  filterOutSetsIfNoFeatures,
  getAllResources,
  RESOURCE_TYPE,
  translateResource,
  translateResourceById,
  traverseResourceById
} from "../handlers/resourceHandler";
import { ResourceDTO } from "app/api/resourceApi";
import { useUserAndTenantData } from "../handlers/userAndTenant/user-tenant-context";
import useSWR from "swr";
import { SWR_KEYS } from "app/swrKeys";

export interface ResourceContextValue {
  readonly resourcesLoaded: boolean;
  readonly resources: Record<string, ResourceDTO[]>;
  readonly resourcesWithMergedAndDisabled: Record<string, ResourceDTO[]>;
  readonly translate: (resourceType: RESOURCE_TYPE, nameKey: string) => string;
  readonly translateById: (resourceType: RESOURCE_TYPE, id: string) => string;
  readonly traverseMergedId: (resourceType: RESOURCE_TYPE, id: string) => string | null;
  readonly create: (resourceType: RESOURCE_TYPE, nameKey: string) => Promise<string>;
  readonly refreshResources: () => Promise<void>;
}

export const ResourcesContext = createContext<ResourceContextValue>({
  resourcesLoaded: false,
  resources: {},
  resourcesWithMergedAndDisabled: {},
  translate: () => "",
  translateById: () => "",
  traverseMergedId: () => "",
  create: () => Promise.reject(),
  refreshResources: () => Promise.reject()
});

export const ResourceProvider = ({ children }: { readonly children?: React.ReactNode }) => {
  const { auth } = useAuthentication();
  const { t } = useTranslation("");
  const { tenantData } = useUserAndTenantData();

  const [resources, setResources] = useState<Record<string, ResourceDTO[]>>({});

  const [resourcesWithMergedAndDisabled, setResourcesWithMergedAndDisabled] = useState<Record<string, ResourceDTO[]>>(
    {}
  );

  const { data, isValidating, isLoading, mutate } = useSWR(SWR_KEYS.ResourceProvider, () =>
    getAllResources({ showMerged: true, showAllSetsIgnoringTenantFeatures: true })
  );

  useEffect(() => {
    if (auth && data && !isValidating && !isLoading) {
      const resourcesWithoutMergedAndDisabled = Object.entries(data).reduce<Record<string, ResourceDTO[]>>(
        (acc, [resourceType, resources]) => {
          const resourcesWithoutMergedInto = resources.filter(r => !r.mergedIntoId);
          return {
            ...acc,
            [resourceType]: filterOutSetsIfNoFeatures(resourcesWithoutMergedInto, tenantData?.features || [])
          };
        },
        {}
      );
      setResourcesWithMergedAndDisabled(data);
      setResources(resourcesWithoutMergedAndDisabled);
    }
  }, [auth, data, isLoading, isValidating, tenantData?.features]);

  const { loadSeenItemsOfUserHook } = useUserAndTenantData();

  const translateCallback = useCallback(
    (resourceType: RESOURCE_TYPE, nameKey: string) => {
      return translateResource({ t, resourceType, nameKey });
    },
    [t]
  );

  const translateByIdCallback = useCallback(
    (resourceType: RESOURCE_TYPE, resourceId: string) => {
      return translateResourceById({ t, resourceType, resourceId, resources: resourcesWithMergedAndDisabled });
    },
    [resourcesWithMergedAndDisabled, t]
  );

  const createCallback = useCallback(
    async (resourceType: RESOURCE_TYPE, nameKey: string) => {
      const createdResourceId = await createNewResource({ resourceType, nameKey });
      await loadSeenItemsOfUserHook();
      await mutate();
      return createdResourceId;
    },
    [loadSeenItemsOfUserHook, mutate]
  );

  const traverseMergedIdCallback = useCallback(
    (resourceType: RESOURCE_TYPE, id: string): string | null => {
      return (
        traverseResourceById({
          resourceType,
          resourceId: id,
          resourcesWithMerged: resourcesWithMergedAndDisabled
        })?.id || null
      );
    },
    [resourcesWithMergedAndDisabled]
  );

  const refreshResources = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return (
    <ResourcesContext.Provider
      value={{
        resourcesLoaded: !isLoading && !isValidating,
        resources,
        resourcesWithMergedAndDisabled,
        translate: translateCallback,
        translateById: translateByIdCallback,
        traverseMergedId: traverseMergedIdCallback,
        create: createCallback,
        refreshResources
      }}
    >
      {children}
    </ResourcesContext.Provider>
  );
};
export const useResources = () => useContext(ResourcesContext);
