import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import QuestionTitle from "components/QuestionTitle/QuestionTitle";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    cursor: "pointer"
  }
}));

interface SimpleQuestionProps {
  readonly title?: string;
  readonly pt?: number;
  readonly pb?: number;
  readonly onFocus?: () => void;
  readonly children?: React.ReactNode;
}

const SimpleQuestion = ({ title, pt, pb, onFocus, children }: SimpleQuestionProps) => {
  const cls = useStyles();

  return (
    <Box className={cls.root} pt={pt === undefined ? 3 : pt} pb={pb === undefined ? 3 : pb} onClick={onFocus}>
      {title && <QuestionTitle>{title}</QuestionTitle>}
      {children}
    </Box>
  );
};

export default SimpleQuestion;
