import React from "react";
import { Box, Typography, SvgIconProps } from "@material-ui/core";
import { styled } from "@mui/system";

export type TileProps = {
  icon: React.ReactElement<SvgIconProps>;
  title: string;
  description: string;
  selected: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

const TileWrapper = styled(Box)<{ selected: boolean; disabled?: boolean }>(({ theme, selected, disabled }) => ({
  opacity: disabled ? 0.6 : 1,
  border: selected ? "1px solid #306AFF" : "1px solid #757575",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: disabled ? "default" : "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  height: "100%",
  boxSizing: "border-box",
  alignItems: "center",
  transition: "border-color 0.3s",
  "&:hover": {
    borderColor: disabled ? "#757575" : "#306AFF"
  }
}));

const Tile: React.FC<TileProps> = ({ icon, title, description, selected, disabled, onClick }) => {
  return (
    <TileWrapper selected={selected} disabled={disabled} onClick={disabled ? undefined : onClick}>
      {React.cloneElement(icon, { style: { color: selected ? "#306AFF" : "#757575", width: "24px", height: "24px" } })}
      <Typography
        variant="h6"
        gutterBottom
        style={{
          fontFamily: "OpenSans",
          fontSize: "14px",
          paddingTop: "8px",
          paddingBottom: "4px",
          color: "var(--Neutral-900, #05090F)"
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ fontFamily: "OpenSans", fontSize: "12px", color: "var(--Neutral-900, #05090F)" }}
      >
        {description}
      </Typography>
    </TileWrapper>
  );
};

export default Tile;
