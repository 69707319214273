import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import { ListItemText, Menu, MenuItem } from "@material-ui/core";
import {
  ListViewItemProps,
  listItemDefaultStatuses,
  StyledListItemIcon,
  LazySvgIcon,
  ItemStatus
} from "./ListViewItem";
import { usePromisedCallback } from "./usePromisedCallback";

export const StatusDropdown = (props: Pick<ListViewItemProps, "allowedStatuses" | "status" | "onStatusChange">) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { allowedStatuses = listItemDefaultStatuses, status, onStatusChange } = props;
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(status);
  const { trigger, pending } = usePromisedCallback(
    useCallback(async (status: string) => onStatusChange?.(status), [onStatusChange])
  );
  useEffect(() => {
    setSelectedStatus(status);
  }, [status]);
  const didMount = useRef(false);
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    selectedStatus && trigger(selectedStatus);
  }, [selectedStatus, trigger]);
  const itemStatusByStatuses = useMemo(() => {
    return allowedStatuses.reduce(
      (acc, allowedStatus) => {
        acc[allowedStatus.status] = allowedStatus;
        return acc;
      },
      {} as Record<string, ItemStatus>
    );
  }, [allowedStatuses]);
  const activeStatus = itemStatusByStatuses[selectedStatus ?? allowedStatuses[0].status];
  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  }, []);
  const handleClose = useCallback((event: React.MouseEvent) => {
    setAnchorEl(null);
    event.stopPropagation();
  }, []);
  const isOpen = Boolean(anchorEl);
  return (
    <Box>
      <Tooltip title={activeStatus.label ?? activeStatus.status} placement="top">
        <Box
          onClick={onStatusChange ? handleClick : undefined}
          sx={{
            cursor: onStatusChange ? "pointer" : undefined,
            height: 24,
            opacity: pending ? 0.5 : 1
          }}
        >
          {activeStatus.icon}
        </Box>
      </Tooltip>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        {allowedStatuses.map(({ status: allowedStatus, label, icon }) => (
          <MenuItem
            key={allowedStatus}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(event: React.MouseEvent<HTMLLIElement>) => {
              setSelectedStatus(allowedStatus);
              handleClose(event);
            }}
          >
            <StyledListItemIcon>{icon}</StyledListItemIcon>
            <ListItemText>{label ?? allowedStatus}</ListItemText>
            {selectedStatus === allowedStatus ? <LazySvgIcon name="Check" /> : <Box sx={{ width: 24, height: 24 }} />}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
