import { useMetaView } from "app/contexts/meta-view-context";
import React, { useCallback } from "react";
import AdvancedQuestion from "./AdvancedQuestion";
import { QUESTION_TYPE, QUESTION_TYPE_TYPES } from "./QuestionTypes";
import SimpleQuestion from "./SimpleQuestion";
import { TranslationQuestion } from "./TranslationQuestion";
import TextAreaQuestion from "./types/basic/TextAreaQuestion";
import ResponsibleOrgUnitQuestion from "./types/orgUnits/ResponsibleOrgUnitQuestion";
import FurtherOrgUnitQuestion from "./types/orgUnits/FurtherOrgUnitQuestion";
import AssignToUserQuestion from "./types/users/AssignToUserQuestion";
import DateQuestion from "./types/basic/DateQuestion";
import LabelsQuestion from "./types/resources/LabelsQuestion";
import PrivacyExpertUserQuestion from "./types/users/PrivacyExpertUserQuestion";
import LegalRetentionPeriodsQuestion from "./types/resources/LegalRetentionPeriodsQuestion";
import NumberQuestion from "./types/basic/NumberQuestion";
import UnitOfTimeQuestion from "./types/basic/UnitOfTimeQuestion";
import DeletionTriggerQuestion from "./types/resources/DeletionTriggerQuestion";
import TextEditorQuestion from "./types/basic/TextEditorQuestion";
import DataDeletionTypesQuestion from "./types/resources/DataDeletionTypesQuestion";
import { COLLECTION_TYPES } from "app/collections";
import DocumentTypesQuestion from "./types/resources/DocumentTypesQuestion";
import DataSourceDataStorageQuestion from "./types/resources/DataSourceDataStorageQuestion";
import InternalDataRecipientsQuestion from "./types/resources/InternalDataRecipientsQuestion";
import ExternalDataRecipientsQuestion from "./types/resources/ExternalDataRecipientsQuestion";
import AffectedOrgUnitQuestion from "./types/orgUnits/AffectedOrgUnitQuestion";
import { RESOURCE_TYPE } from "app/handlers/resourceHandler";
import ResourceQuestion from "./types/resources/ResourceQuestion";
import DateTimeQuestion from "./types/basic/DateTimeQuestion";
import PersonGroupsQuestion from "./types/resources/PersonGroupsQuestion";
import DataCategoryQuestion from "./types/resources/DataCategoryQuestion";
import DpoUserQuestion from "./types/users/DpoUserQuestion";
import ProtectionObjectiveQuestion from "./types/resources/ProtectionObjectiveQuestion";
import PAsQuestions from "./types/pas/PAsQuestions";
import LanguageQuestion from "./types/basic/LanguageQuestion";
import MeasuresQuestion from "./types/resources/MeasuresQuestion";
import { ASSIGNABLE_USER_FIELD } from "../../app/api/user/userApi";

export type QUESTION_ACTION_TYPES = "tasks" | "comments" | "remarks" | "translate";
export const QUESTION_ACTION = {
  TASKS: "tasks",
  COMMENTS: "comments",
  REMARKS: "remarks",
  TRANSLATE: "translate"
} as const satisfies Record<string, QUESTION_ACTION_TYPES>;

export type QUESTION_CHIP_TYPES = "userAvatar" | "tasks" | "comments" | "remarks" | "auditActionTasks";
export const QUESTION_CHIP = {
  USER_AVATAR: "userAvatar",
  TASKS: "tasks",
  COMMENTS: "comments",
  REMARKS: "remarks",
  AUDIT_ACTION_TASKS: "auditActionTasks"
} as const satisfies Record<string, QUESTION_CHIP_TYPES>;

export interface QuestionProps {
  readonly qType?: QUESTION_TYPE_TYPES;
  readonly actions?: QUESTION_ACTION_TYPES[];
  readonly chips?: QUESTION_CHIP_TYPES[];
  readonly title?: string;
  readonly questionId?: string;
  readonly questionName?: string;
  readonly valueName?: string;
  readonly value?: string | string[] | number;
  readonly disabled?: boolean;
  readonly translatable?: string;
  readonly translationId?: string;
  readonly info?: { readonly title: string; readonly text: string };
  readonly infoId?: string;
  readonly required?: boolean;
  readonly documentId?: string;
  readonly collection?: COLLECTION_TYPES;
  readonly disablePast?: boolean;
  readonly orgUnitIds?: string[];
  readonly resourceType?: RESOURCE_TYPE;
  readonly multiSelect?: boolean;
  readonly allowAdd?: boolean;
  readonly personGroupId?: string;
  readonly hidden?: boolean | null;
  readonly multiSelectHiddenIds?: string[];
  readonly options?: { id: string; name: string }[];
  readonly error?: boolean;
  readonly helperText?: string;
  readonly textEditorToolbarOptions?: string[];
  readonly pt?: number;
  readonly pb?: number;
  readonly assignType?: ASSIGNABLE_USER_FIELD;
  readonly onFocus?: (event?: any) => void;
  readonly onBlur?: (event?: any) => void;
  readonly onChange?: (value?: any, valueName?: string) => void;
  readonly onChipClick?: (val?: any) => void;
  readonly isNotDeletableChip?: (id: string) => boolean;
  readonly notDeletableChipTooltip?: (id: string) => string;

  readonly children?: React.ReactNode;
}

const QuestionBody = ({
  qType,
  actions,
  chips,
  title,
  questionId,
  questionName,
  value,
  disabled = false,
  translatable,
  translationId,
  info,
  required,
  documentId,
  collection,
  disablePast,
  orgUnitIds,
  resourceType,
  multiSelect,
  allowAdd,
  personGroupId,
  multiSelectHiddenIds,
  options,
  error,
  helperText,
  textEditorToolbarOptions,
  assignType,

  onFocus,
  onBlur,
  onChange,
  onChipClick,
  isNotDeletableChip,
  notDeletableChipTooltip
}: QuestionProps) => {
  const qProps = {
    qType,
    actions,
    chips,
    title,
    questionId,
    questionName,
    value,
    disabled,
    translatable,
    translationId,
    info,
    required,
    documentId,
    collection,
    disablePast,
    orgUnitIds,
    resourceType,
    multiSelect,
    allowAdd,
    personGroupId,
    multiSelectHiddenIds,
    options,
    error,
    helperText,
    textEditorToolbarOptions,
    assignType,

    onFocus,
    onBlur,
    onChange,
    onChipClick,
    isNotDeletableChip,
    notDeletableChipTooltip
  };

  /* BASIC */
  if (qType === QUESTION_TYPE.NUMBER) {
    return React.createElement(NumberQuestion, qProps);
  } else if (qType === QUESTION_TYPE.TEXT_AREA) {
    return React.createElement(TextAreaQuestion, qProps);
  } else if (qType === QUESTION_TYPE.TEXT_EDITOR) {
    return React.createElement(TextEditorQuestion, qProps);
  } else if (qType === QUESTION_TYPE.UNIT_OF_TIME) {
    return React.createElement(UnitOfTimeQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DATE) {
    return React.createElement(DateQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DATE_TIME) {
    return React.createElement(DateTimeQuestion, qProps);
  } else if (qType === QUESTION_TYPE.LANGUAGE) {
    return React.createElement(LanguageQuestion, qProps);
  } else if (qType === QUESTION_TYPE.RESPONSIBLE_ORG_UNIT) {
    /* ORG UNIT */
    return React.createElement(ResponsibleOrgUnitQuestion, qProps);
  } else if (qType === QUESTION_TYPE.FURTHER_ORG_UNITS) {
    return React.createElement(FurtherOrgUnitQuestion, qProps);
  } else if (qType === QUESTION_TYPE.AFFECTED_ORG_UNITS) {
    return React.createElement(AffectedOrgUnitQuestion, qProps);
  } else if (qType === QUESTION_TYPE.ASSIGNED_TO_USER) {
    /* USERS */
    return React.createElement(AssignToUserQuestion, qProps);
  } else if (qType === QUESTION_TYPE.PRIVACY_EXPERT_USER) {
    return React.createElement(PrivacyExpertUserQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DPO_USER) {
    return React.createElement(DpoUserQuestion, qProps);
  } else if (qType === QUESTION_TYPE.RESOURCE) {
    /* RESOURCES */
    return React.createElement(ResourceQuestion, qProps);
  } else if (qType === QUESTION_TYPE.LABELS) {
    return React.createElement(LabelsQuestion, qProps);
  } else if (qType === QUESTION_TYPE.LEGAL_RETENTION_PERIODS) {
    return React.createElement(LegalRetentionPeriodsQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DELETION_TRIGGER) {
    return React.createElement(DeletionTriggerQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DATA_DELETION_TYPES) {
    return React.createElement(DataDeletionTypesQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DOCUMENT_TYPES) {
    return React.createElement(DocumentTypesQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DATA_SOURCE) {
    return React.createElement(DataSourceDataStorageQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DATA_STORAGE) {
    return React.createElement(DataSourceDataStorageQuestion, qProps);
  } else if (qType === QUESTION_TYPE.INTERNAL_DATA_RECIPIENTS) {
    return React.createElement(InternalDataRecipientsQuestion, qProps);
  } else if (qType === QUESTION_TYPE.MEASURE) {
    return React.createElement(MeasuresQuestion, qProps);
  } else if (qType === QUESTION_TYPE.PERSON_GROUP) {
    return React.createElement(PersonGroupsQuestion, qProps);
  } else if (qType === QUESTION_TYPE.DATA_CATEGORY) {
    return React.createElement(DataCategoryQuestion, qProps);
  } else if (qType === QUESTION_TYPE.EXTERNAL_DATA_RECIPIENTS) {
    return React.createElement(ExternalDataRecipientsQuestion, qProps);
  } else if (qType === QUESTION_TYPE.PROTECTION_OBJECTIVE) {
    return React.createElement(ProtectionObjectiveQuestion, qProps);
  } else if (qType === QUESTION_TYPE.PAS) {
    return React.createElement(PAsQuestions, qProps);
  }

  return <>{qType}</>;
};

const Question = ({
  qType,
  actions,
  chips,
  title,
  questionId,
  questionName,
  valueName,
  value,
  disabled = false,
  translatable,
  translationId,
  info,
  infoId,
  required,
  documentId,
  collection,
  disablePast,
  orgUnitIds,
  resourceType,
  multiSelect,
  allowAdd,
  personGroupId,
  hidden,
  multiSelectHiddenIds,
  options,
  error,
  helperText,
  textEditorToolbarOptions,
  pt,
  pb,
  assignType,

  onFocus,
  onBlur,
  onChange,
  onChipClick,
  isNotDeletableChip,
  notDeletableChipTooltip,

  children
}: QuestionProps) => {
  const { setInfo, setInfoId } = useMetaView();

  /* EVENTS */
  const onFocusCallback = useCallback(async () => {
    if (info) {
      setInfo(info);
    }
    if (infoId) {
      setInfoId(infoId);
    }
    if (onFocus) {
      onFocus(questionId);
    }
  }, [info, infoId, onFocus, setInfo, setInfoId, questionId]);

  const onChangeCallback = useCallback(
    (value?: string | string[] | number) => {
      onChange?.(value, valueName);
    },
    [onChange, valueName]
  );

  /* QUESTION BODY */
  const questionBody = (
    <QuestionBody
      qType={qType}
      questionName={questionName}
      value={value}
      onChange={onChangeCallback}
      disabled={disabled}
      required={required}
      documentId={documentId}
      collection={collection}
      disablePast={disablePast}
      orgUnitIds={orgUnitIds}
      resourceType={resourceType}
      multiSelect={multiSelect}
      allowAdd={allowAdd}
      personGroupId={personGroupId}
      multiSelectHiddenIds={multiSelectHiddenIds}
      isNotDeletableChip={isNotDeletableChip}
      notDeletableChipTooltip={notDeletableChipTooltip}
      error={error}
      helperText={helperText}
      textEditorToolbarOptions={textEditorToolbarOptions}
      options={options}
      onFocus={onFocus}
      onBlur={onBlur}
      onChipClick={onChipClick}
      assignType={assignType}
    />
  );

  if (hidden) {
    return <></>;
  } else if (questionId) {
    return (
      <AdvancedQuestion
        actions={actions}
        chips={chips}
        title={title}
        questionId={questionId}
        questionName={questionName}
        disabled={disabled}
        translatable={translatable}
        onFocus={onFocusCallback}
      >
        {questionBody}
        {children}
      </AdvancedQuestion>
    );
  } else if (translatable !== undefined) {
    return (
      <TranslationQuestion
        title={title}
        translatable={translatable}
        translationId={translationId}
        onFocus={onFocusCallback}
      >
        {questionBody}
        {children}
      </TranslationQuestion>
    );
  } else
    return (
      <SimpleQuestion title={title} onFocus={onFocusCallback} pt={pt} pb={pb}>
        {questionBody}
        {children}
      </SimpleQuestion>
    );
};

export default Question;
