import { Box, Fab, TextField, Tooltip, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useCallback, useMemo } from "react";
import { AUDIT_QUESTION } from "./AuditQuestionType";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";

export interface AuditQuestionSelectorItemProps {
  readonly category: string;
  readonly name: string;
  readonly type: string;
}

export interface AuditBlockSelectorProps {
  readonly onSelect: (type: string) => void;
  readonly onClose: () => void;
}

const AuditBlockSelector = ({ onSelect, onClose }: AuditBlockSelectorProps) => {
  const { t } = useTranslation("audit_details");
  const isPaCheckEnabled = useIsFeaturePresent("AuditBuildingBlock-PAOrgUnitCheck");
  const premiumBlocks = React.useMemo<AuditQuestionSelectorItemProps[]>(
    () =>
      isPaCheckEnabled
        ? [
            {
              category: t("premium"),
              name: t("question_types_orgprocessingconsent"),
              type: AUDIT_QUESTION.ORG_PROCESSING_CONSENT
            }
          ]
        : [],
    [isPaCheckEnabled, t]
  );
  const onClickCallback = useCallback(event => event.stopPropagation(), []);
  const onSelectCallback = useCallback((event, { type }) => onSelect(type), [onSelect]);

  const allAuditQuestions: AuditQuestionSelectorItemProps[] = useMemo(
    () => [
      {
        category: t("general"),
        name: t("question_types_heading"),
        type: AUDIT_QUESTION.HEADING
      },
      {
        category: t("general"),
        name: t("question_types_subheading"),
        type: AUDIT_QUESTION.SUB_HEADING
      },
      {
        category: t("general"),
        name: t("question_types_paragraph"),
        type: AUDIT_QUESTION.PARAGRAPH
      },
      {
        category: t("general"),
        name: t("question_types_divider"),
        type: AUDIT_QUESTION.DIVIDER
      },
      {
        category: t("input"),
        name: t("question_types_textinputsingle"),
        type: AUDIT_QUESTION.TEXT_INPUT_SINGLE
      },
      {
        category: t("input"),
        name: t("question_types_textinputmultiple"),
        type: AUDIT_QUESTION.TEXT_INPUT_MULTIPLE
      },
      {
        category: t("input"),
        name: t("question_types_selectboxsingle"),
        type: AUDIT_QUESTION.SELECT_BOX_SINGLE
      },
      {
        category: t("input"),
        name: t("question_types_selectboxmultiple"),
        type: AUDIT_QUESTION.SELECT_BOX_MULTIPLE
      },
      {
        category: t("input"),
        name: t("question_types_checkbox"),
        type: AUDIT_QUESTION.CHECK_BOX
      },
      {
        category: t("input"),
        name: t("question_types_radiobox"),
        type: AUDIT_QUESTION.RADIO_BOX
      },
      {
        category: t("input"),
        name: t("question_types_date"),
        type: AUDIT_QUESTION.DATE
      },
      {
        category: t("input"),
        name: t("question_types_group"),
        type: AUDIT_QUESTION.GROUP
      },
      ...premiumBlocks
    ],
    [t, premiumBlocks]
  );

  const getOptionLabel = useCallback(option => option.name, []);
  const renderOption = useCallback(
    option => (
      <Box py={2} display="flex" alignItems={"center"}>
        <Typography>{option.name}</Typography>
      </Box>
    ),
    []
  );
  const renderInput = useCallback(
    params => (
      <TextField
        {...params}
        variant="outlined"
        label={t("select_block_placeholder")}
        placeholder={t("select_block_placeholder")}
      />
    ),
    [t]
  );
  const groupBy = useCallback(option => option.category, []);

  return (
    <Box mx={4} my={4} textAlign="center" onClick={onClickCallback}>
      <Tooltip title={t("cancel_new_question_tooltip")} aria-label="add">
        <Fab color="primary" onClick={onClose}>
          <CloseIcon fontSize="large" />
        </Fab>
      </Tooltip>
      <Box mb={2} />
      <Autocomplete
        id="audit-block-selector"
        options={allAuditQuestions}
        groupBy={groupBy}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        onChange={onSelectCallback}
        renderInput={renderInput}
      />
    </Box>
  );
};

export default React.memo(AuditBlockSelector);
