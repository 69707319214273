import { OrgUnitTreeStructure } from "../../organization/OrganizationOverview";
import { Department } from "../../../handlers/departmentHandler";
import { orgUnitAndChildrens } from "../../../handlers/orgUnitHandler";

const toOrgUnitTreeRecord = (input: Department[]) => {
  return input.reduce<Record<string, OrgUnitTreeStructure>>((acc, el) => {
    acc[el.id] = {
      id: el.id,
      data: {
        name: el.name,
        parentId: el.parentId || ""
      },
      sortId: "",
      children: []
    } satisfies OrgUnitTreeStructure;
    return acc;
  }, {});
};
export const buildTree = (items: Department[]): OrgUnitTreeStructure[] | null => {
  const idMapping = toOrgUnitTreeRecord(items);

  let root: OrgUnitTreeStructure | null = null;
  Object.values(idMapping).forEach(el => {
    // Handle the root element
    if (!el.data.parentId) {
      root = el;
      return;
    }
    // Use our mapping to locate the parent element in our data array
    const parentEl = idMapping[el.data.parentId];
    if (parentEl) {
      // Add our current el to its parent's `children` array
      parentEl.children.push(el);
    }
  });
  return root ? [root] : null;
};

export const buildTreebyParents = (items: Department[], parents: string[]): OrgUnitTreeStructure[] | null => {
  const idMapping = toOrgUnitTreeRecord(items);

  const root: OrgUnitTreeStructure[] | null = [];
  Object.values(idMapping).forEach(el => {
    // Handle the root element
    if (parents.includes(el.id)) {
      root?.push(el);
      return;
    }
    // Use our mapping to locate the parent element in our data array
    const parentEl = idMapping[el.data.parentId];
    if (parentEl) {
      // Add our current el to its parent's `children` array
      parentEl.children.push(el);
    }
  });
  return root ? [...root] : null;
};

export const removeSameParentOrgUnit = (
  allOrgUnits: Department[],
  inputOrgUnitIds: (string | undefined)[]
): string[] => {
  const orgUnitIds = [...new Set(inputOrgUnitIds.filter((it): it is string => !!it))];
  if (orgUnitIds.length === 0) {
    return [];
  }

  const idToChildrenIds = orgUnitIds.map(it => ({
    id: it,
    childrenIds: orgUnitAndChildrens(it, allOrgUnits)
      .map(it => it.id)
      .filter(orgUnitId => orgUnitId !== it)
  }));

  const allChildrenIds = new Set(idToChildrenIds.flatMap(it => it.childrenIds));

  const highestParentIDs: Set<string> = new Set<string>();
  for (const orgUnitId of orgUnitIds) {
    if (!allChildrenIds.has(orgUnitId)) {
      highestParentIDs.add(orgUnitId);
    }
  }

  return [...highestParentIDs];
};
