import { useTranslation } from "react-i18next";
import { useMetaView } from "app/contexts/meta-view-context";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { useUserAndTenantData } from "../../../handlers/userAndTenant/user-tenant-context";
import DateDisplay from "components/DateDisplay";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";
import { createQuestionnaireFromJsonData, postAudit } from "../../../api/auditApi";
import { FileReaderComponent } from "../../../../components/UploadFileReader/FileReaderComponent";
import { useSnackbar } from "notistack";
import { auditWritePermissions } from "../../../handlers/permissionHandler";
import { AUDIT_METHODOLOGY, AUDIT_METHODOLOGY_TYPES } from "../AuditTypes";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import AssessmentIcon from "@mui/icons-material/Assessment";
import GavelIcon from "@mui/icons-material/Gavel";
import { TileProps } from "components/Tiles/Tile";
import MainTiles from "components/Tiles/MainTiles";
import Modal from "@mui/material/Modal";
import { Box, Paper } from "@mui/material";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import AuditOverviewRow from "../../../../components/Overview/collections/audits/AuditOverviewRow";

export const AuditsOverview = () => {
  const { t } = useTranslation();
  const dispatch = useOverviewDispatch();
  const currentPage = useParams()?.page || "";
  const { setInfo, setMeta } = useMetaView();
  const { auth } = useAuthentication();
  const { enqueueSnackbar } = useSnackbar();
  const isPublicAssessmentActivated = useIsFeaturePresent("publicAssessment");
  const navigate = useNavigate();
  const location = useLocation();

  const [showTiles, setShowTiles] = useState<boolean>(false);
  const [methodology, setMethodology] = useState<AUDIT_METHODOLOGY_TYPES | null>(null);

  const infoCard = useMemo(
    () => ({
      enteringInstances: {
        title: t("audits_overview:enteringInfoCardTitle"),
        text: t("audits_overview:enteringInfoCardTextInstances")
      },
      enteringTemplates: {
        title: t("audits_overview:enteringInfoCardTitle"),
        text: t("audits_overview:enteringInfoCardTextTemplates")
      },
      creating: {
        title: t("audits_overview:creating_info_card_title"),
        text: t("audits_overview:creating_info_card_text")
      }
    }),
    [t]
  );

  useEffect(() => {
    if (currentPage === "instances") {
      setInfo(infoCard?.enteringInstances);
    } else {
      setInfo(infoCard?.enteringTemplates);
    }
  }, [currentPage, infoCard, setInfo]);

  const showEnterInfo = useCallback(() => {
    if (currentPage === "instances") {
      setInfo(infoCard?.enteringInstances);
    } else {
      setInfo(infoCard?.enteringTemplates);
    }
  }, [currentPage, infoCard?.enteringInstances, infoCard?.enteringTemplates, setInfo]);
  const showCreateInfo = useCallback(() => {
    setInfo(infoCard?.creating);
  }, [infoCard, setInfo]);

  const isAllowedToWrite = useMemo(
    () => auth?.permissions?.some?.(permission => permission.startsWith("audit_write")),
    [auth?.permissions]
  );

  const toolbarActions = useMemo(() => [{ action: "sort" }, { action: "filter" }], []);

  const { tenantData } = useUserAndTenantData();
  const showCreateFromTemplate = useMemo(
    () =>
      currentPage === "templates" &&
      !!tenantData?.features.find(feature => feature.includes("auditQuestionnaireTemplate")),
    [currentPage, tenantData?.features]
  );
  const showCreateTemplateFromJson = currentPage === "templates" && auth?.permissions.includes("super_admin");
  const onSelectCreateFromJson = useCallback(() => {
    document.getElementById("fileReader")?.click();
  }, []);

  const onFileRead = useCallback(
    async (fileText: string) => {
      try {
        await createQuestionnaireFromJsonData({ payload: JSON.parse(fileText) });
      } catch (error) {
        enqueueSnackbar(`${t("audits_overview:fileReadError")}`, { variant: "error" });
        throw error;
      }
    },
    [enqueueSnackbar, t]
  );
  const onReadFinished = useCallback(() => {
    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.AUDITS,
      reloadOverview: Date.now()
    });
  }, [dispatch]);

  /* TILES */
  const getMethodologyLabels = useCallback(
    statusVal => {
      return t(`audit_methodologies:${statusVal.toLowerCase()}`);
    },
    [t]
  );
  const methodologies = useMemo(() => Object.values(AUDIT_METHODOLOGY), []);
  const tiles: TileProps[] = useMemo(
    () => [
      {
        icon: <AssessmentIcon fontSize="large" color="primary" />,
        title: t("auditTilesModal:assessmentType"),
        description: t("auditTilesModal:comingSoon"),
        selected: false,
        disabled: true
      },
      // left
      {
        icon: <GavelIcon fontSize="large" color="action" />,
        title: t("auditTilesModal:auditType"),
        description: t("auditTilesModal:auditDescription"),
        selected: true
      }
    ],
    [t]
  );
  const onTileClick = useCallback(() => {
    // do
  }, []);

  const onMethodologyChange = useCallback(methodology => {
    setMethodology(methodology as AUDIT_METHODOLOGY_TYPES);
  }, []);

  const onAddAudit = useCallback(async () => {
    if (methodology) {
      const title = createOverviewItemDefaultName(`${COLLECTIONS.AUDITS}_${currentPage}`);
      const auditId = await postAudit({ methodology, title });
      if (auditId) {
        navigate(`/audits/instances/${auditId}/general`);
      }
      setMethodology(null);
      setShowTiles(false);
    }
  }, [currentPage, methodology, navigate]);

  const onCloseTiles = useCallback(() => {
    setShowTiles(false);
    setMethodology(null);
  }, []);

  const onShowTiles = useCallback(() => {
    setShowTiles(true);
  }, []);

  const singleAddAction = useMemo(
    () =>
      isPublicAssessmentActivated && currentPage === "instances"
        ? { action: OVERVIEW_ADD_TYPE.CUSTOM_SINGLE, onHandle: onShowTiles }
        : { action: OVERVIEW_ADD_TYPE.SINGLE },
    [currentPage, isPublicAssessmentActivated, onShowTiles]
  );

  const addActions = useMemo(
    () =>
      (isAllowedToWrite && [
        singleAddAction,
        ...(!isPublicAssessmentActivated ? [{ action: OVERVIEW_ADD_TYPE.MULTIPLE }] : []),
        ...(showCreateFromTemplate ? [{ action: OVERVIEW_ADD_TYPE.TEMPLATE }] : []),
        ...(showCreateTemplateFromJson
          ? [
              {
                action: OVERVIEW_ADD_TYPE.CUSTOM,
                title: t("audits_overview:createFromJson"),
                placeholder: t("audits_overview:createFromJson"),
                onHandle: onSelectCreateFromJson
              }
            ]
          : [])
      ]) ||
      [],
    [
      isAllowedToWrite,
      singleAddAction,
      isPublicAssessmentActivated,
      showCreateFromTemplate,
      showCreateTemplateFromJson,
      t,
      onSelectCreateFromJson
    ]
  );
  const rowActions = useMemo(() => [{ action: "edit" }, { action: "remove" }], []);

  const selectionActions = useMemo(
    () => (currentPage === "instances" ? [{ action: "remove" }] : [{ action: "duplicate" }, { action: "remove" }]),
    [currentPage]
  );
  const onRowOver = useCallback(
    item => {
      setMeta({
        department: item.subTitle,
        created: <DateDisplay timestamp={new Date(item.createdAt)} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={item.createdBy} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updatedBy ? <AutomaticUserDataDisplay uid={item.updatedBy} separator={undefined} /> : "-",
        status: t("audit_status:" + item.status)
      });
    },
    [setMeta, t]
  );
  const pages = useMemo(
    () => [
      { title: t("audits_overview:instances"), route: "instances", current: currentPage === "instances" },
      { title: t("audits_overview:templates"), route: "templates", current: currentPage === "templates" }
    ],
    [currentPage, t]
  );

  const onPageChange = useCallback(
    ({ route }) => {
      navigate(`/audits/${route}`);
      dispatch({ type: OVERVIEW_ACTIONS.SET_SELECTED_ID, collection: COLLECTIONS.AUDITS, selectedId: null });
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW, collection: COLLECTIONS.AUDITS, reloadOverview: Date.now() });
    },
    [dispatch, navigate]
  );

  const showAddActions = auth?.permissions.find(permission => auditWritePermissions.includes(permission));
  const docViewContent = (
    <>
      <FileReaderComponent
        id={"fileReader"}
        accept={"application/JSON"}
        onFileRead={onFileRead}
        onReadFinished={onReadFinished}
      />
      <Overview
        collection={COLLECTIONS.AUDITS}
        // the only way to force the overview to reload, since the controller cannot listen to history changes
        key={location.pathname}
        onAddOpen={showCreateInfo}
        onAddClose={showEnterInfo}
        onRowOver={onRowOver}
        onRowLeave={showEnterInfo}
        rowActions={rowActions}
        toolbarActions={toolbarActions}
        addActions={showAddActions ? addActions : undefined}
        selectionActions={selectionActions}
        header={t("audits_overview:header")}
        pages={isAllowedToWrite ? pages : undefined}
        onPageChange={onPageChange}
        checkable={true}
        toolbarMode={"tabs"}
        CustomRowComponent={isPublicAssessmentActivated ? AuditOverviewRow : undefined}
      />
      <Modal open={showTiles} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "80px" }}>
          <Paper sx={{ width: "40%", padding: "32px" }}>
            <MainTiles
              tiles={tiles}
              dropdownOptions={methodologies}
              dropDownGetOptionsLabel={getMethodologyLabels}
              dropdownOnChange={onMethodologyChange}
              dropdownLabel={t("auditTilesModal:assessmentMethod")}
              title={t("auditTilesModal:title")}
              tileOnClick={onTileClick}
              onCancel={onCloseTiles}
              onCreate={onAddAudit}
            />
          </Paper>
        </Box>
      </Modal>
    </>
  );
  return (
    <DocMetaView docViewContent={docViewContent} metaViewContent={<MetaView translationKey={"audits_overview"} />} />
  );
};
