export type QUESTION_TYPE_TYPES =
  | "number"
  | "textArea"
  | "textEditor"
  | "unitOfTime"
  | "responsibleOrgUnit"
  | "furtherOrgUnits"
  | "affectedOrgUnits"
  | "assignedToUser"
  | "privacyExpertUser"
  | "dpoUser"
  | "dueDate"
  | "dateTime"
  | "labels"
  | "legalRetentionPeriods"
  | "deletionTrigger"
  | "dataDeletionTypes"
  | "documentTypes"
  | "dataSource"
  | "dataStorage"
  | "internalDataRecipients"
  | "externalDataRecipients"
  | "protection-objective"
  | "personGroup"
  | "dataCategory"
  | "dataType"
  | "resource"
  | "language"
  | "measure"
  | "pas";

export const QUESTION_TYPE = {
  NUMBER: "number",
  TEXT_AREA: "textArea",
  TEXT_EDITOR: "textEditor",
  UNIT_OF_TIME: "unitOfTime",
  RESPONSIBLE_ORG_UNIT: "responsibleOrgUnit",
  FURTHER_ORG_UNITS: "furtherOrgUnits",
  PAS: "pas",
  AFFECTED_ORG_UNITS: "affectedOrgUnits",
  ASSIGNED_TO_USER: "assignedToUser",
  PRIVACY_EXPERT_USER: "privacyExpertUser",
  DPO_USER: "dpoUser",
  DATE: "dueDate",
  DATE_TIME: "dateTime",
  LABELS: "labels",
  LEGAL_RETENTION_PERIODS: "legalRetentionPeriods",
  DELETION_TRIGGER: "deletionTrigger",
  DATA_DELETION_TYPES: "dataDeletionTypes",
  DOCUMENT_TYPES: "documentTypes",
  DATA_SOURCE: "dataSource",
  DATA_STORAGE: "dataStorage",
  MEASURE: "measure",
  INTERNAL_DATA_RECIPIENTS: "internalDataRecipients",
  EXTERNAL_DATA_RECIPIENTS: "externalDataRecipients",
  PROTECTION_OBJECTIVE: "protection-objective",
  PERSON_GROUP: "personGroup",
  DATA_CATEGORY: "dataCategory",
  DATA_TYPE: "dataType",
  RESOURCE: "resource",
  LANGUAGE: "language"
} as const satisfies Record<string, QUESTION_TYPE_TYPES>;
