import { useCallback, useEffect, useState } from "react";
import { getTextFromTextEditorJsonString } from "../app/pages/questionnaires/utils/textEditorConverter";
import { useTranslation } from "react-i18next";

interface UseReasonTextEditingInput {
  dpiaActions: {
    updateDpia: (payload: Record<string, string | number | boolean | undefined>) => void;
  };
  processPage:
    | {
        dpiaRequiredDecision?: "yes" | "no";
        dpiaRequiredStandardAnswerExplanation?: string;
        dpiaRequiredStandardAnswerIndex?: number;
        dpiaRequiredUsersChoice?: boolean;
      }
    | undefined;
  setDataToConfirm: (payload: Record<string, string | number | boolean | undefined> | null) => void;
}

const useReasonTextEditing = ({ processPage, dpiaActions, setDataToConfirm }: UseReasonTextEditingInput) => {
  const [isReasonHovered, setIsReasonHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [reasonText, setReasonText] = useState<string>(processPage?.dpiaRequiredStandardAnswerExplanation || "");
  const { t } = useTranslation("questionnaire_fourth_page");

  useEffect(() => {
    let translatedReasonText = "";
    const dpiaRequiredStandardAnswerIndex = processPage?.dpiaRequiredStandardAnswerIndex;
    const dpiaRequiredStandardAnswerExplanation = processPage?.dpiaRequiredStandardAnswerExplanation;
    const dpiaRequiredUsersChoice = processPage?.dpiaRequiredUsersChoice;
    const dpiaRequiredDecision = processPage?.dpiaRequiredDecision;

    switch (dpiaRequiredStandardAnswerIndex) {
      case 0:
        translatedReasonText = dpiaRequiredStandardAnswerExplanation || "";
        break;
      case 1:
        translatedReasonText = t("reason_one");
        break;
      case 2:
        translatedReasonText = t("reason_two");
        break;
      case 3:
        translatedReasonText = t("reason_three");
        break;
      case 4:
        translatedReasonText = t("reason_four");
        break;
      case 5:
        translatedReasonText = t("reason_five");
        break;
    }

    if (dpiaRequiredUsersChoice) {
      const textIsSystemGenerated = dpiaRequiredStandardAnswerIndex !== 0;
      const textIsUserGeneratedButEmptyAndNeverModified =
        dpiaRequiredStandardAnswerIndex === 0 && !translatedReasonText;
      const shouldSetStandardText = textIsSystemGenerated || textIsUserGeneratedButEmptyAndNeverModified;
      if (shouldSetStandardText && dpiaRequiredDecision === "yes") {
        translatedReasonText = t("reason_manual_info_yes");
      }
      if (shouldSetStandardText && dpiaRequiredDecision === "no") {
        translatedReasonText = t("reason_manual_info_no");
      }

      // just to make sure the user can click on it when they empty the field
      const textIsEmpty = !getTextFromTextEditorJsonString(translatedReasonText);
      if (textIsEmpty) {
        setIsEditing(true);
      }
    }

    setReasonText(translatedReasonText || "");
  }, [
    processPage?.dpiaRequiredDecision,
    processPage?.dpiaRequiredStandardAnswerExplanation,
    processPage?.dpiaRequiredStandardAnswerIndex,
    processPage?.dpiaRequiredUsersChoice,
    t
  ]);

  const handleMouseEnter = useCallback(() => {
    setIsReasonHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsReasonHovered(false);
  }, []);

  const onEditReasonCallback = useCallback(() => {
    if (processPage?.dpiaRequiredStandardAnswerIndex !== 0 && !processPage?.dpiaRequiredUsersChoice) {
      const payload = {
        dpiaRequiredStandardAnswerIndex: 0,
        dpiaRequiredStandardAnswerExplanation: reasonText || ""
      };
      setDataToConfirm(payload);
    } else {
      setIsEditing(true);
    }
  }, [
    processPage?.dpiaRequiredStandardAnswerIndex,
    processPage?.dpiaRequiredUsersChoice,
    reasonText,
    setDataToConfirm
  ]);

  const updateReasonText = useCallback(() => {
    // check if page loaded
    if (!processPage) {
      return;
    }
    const { dpiaRequiredStandardAnswerExplanation, dpiaRequiredStandardAnswerIndex, dpiaRequiredUsersChoice } =
      processPage;
    // check if user made changes
    if (
      reasonText === undefined ||
      getTextFromTextEditorJsonString(reasonText) === dpiaRequiredStandardAnswerExplanation
    ) {
      return;
    }
    const payload = {
      dpiaRequiredStandardAnswerIndex: 0,
      dpiaRequiredStandardAnswerExplanation: reasonText || ""
    };
    if (dpiaRequiredStandardAnswerIndex !== 0 && !dpiaRequiredUsersChoice) {
      setDataToConfirm(payload);
    } else {
      dpiaActions.updateDpia(payload);
    }
  }, [processPage, reasonText, setDataToConfirm, dpiaActions]);

  return {
    isReasonHovered,
    isEditing,
    setIsEditing,
    reasonText,
    setReasonText,
    handleMouseEnter,
    handleMouseLeave,
    onEditReasonCallback,
    updateReasonText
  };
};

export default useReasonTextEditing;
