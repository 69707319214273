export function translateKeys(data, translationKey, i18n, t, objectKeysToTranslationKeyMapping) {
  let objectWithTranslatedKeys = {};
  Object.entries(data).forEach(([key, value]) => {
    const valueTranslationKey = objectKeysToTranslationKeyMapping[key];
    const translatedValue = i18n.exists(translationKey + ":" + valueTranslationKey)
      ? t(translationKey + ":" + valueTranslationKey)
      : key;
    objectWithTranslatedKeys[translatedValue] = value;
  });
  return objectWithTranslatedKeys;
}
