import React, { useMemo } from "react";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AccountNotice } from "../../app/pages/authentication/AccountNotice";
import { useTranslation } from "react-i18next";
import DocTitle, { DocProperty, DocStatus } from "components/DocTitle/DocTitle";

interface DocViewProps {
  readonly header?: string;
  readonly children?: React.ReactNode;
  readonly pagination?: React.ReactNode;
  readonly icons?: React.ReactNode;
  readonly disableSave?: boolean;
  readonly disableCancel?: boolean;
  readonly saving?: boolean;
  readonly statusId?: string;
  readonly statuses?: DocStatus[];
  readonly disabled?: boolean;
  readonly docProperties?: DocProperty[];
  readonly onStatusChange?: (status: string) => void;
  readonly onHeaderChange?: (val: string) => void;
  readonly onSave?: () => void;
  readonly onCancel?: () => void;
  readonly onHeaderFocus?: (event?: any) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden"
  }
}));

export default function DocView({
  header,
  children,
  pagination,
  icons,
  disableSave,
  disableCancel,
  saving,
  statusId,
  statuses,
  disabled,
  docProperties,
  onSave,
  onCancel,
  onHeaderChange,
  onStatusChange,
  onHeaderFocus
}: DocViewProps) {
  const { t } = useTranslation("");
  const cls = useStyles();

  const buttonsEl = useMemo(() => {
    return (
      (onSave || onCancel) && (
        <Box display={"flex"} mt={3}>
          <Box flex={1} />
          <Box display={"flex"}>
            {onCancel && (
              <Button variant="outlined" onClick={onCancel} disabled={disableCancel}>
                {t("common:cancel")}
              </Button>
            )}
            {onSave && (
              <>
                <Box mx={1} />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onSave}
                  disabled={disableSave}
                  startIcon={saving && <CircularProgress size={14} />}
                >
                  {t("common:save")}
                </Button>
              </>
            )}
          </Box>
        </Box>
      )
    );
  }, [disableCancel, disableSave, onCancel, onSave, saving, t]);

  return (
    <Box pb={4} px={2} className={cls.root}>
      <AccountNotice mt={2} />
      <Box px={4}>
        {(header || icons) && (
          <>
            <Box display="flex" mt={4} alignItems={"center"}>
              <Box flex={1}>
                <DocTitle
                  title={header || ""}
                  statusId={statusId}
                  statuses={statuses}
                  disabled={disabled}
                  properties={docProperties}
                  onTitleChange={onHeaderChange}
                  onStatusChange={onStatusChange}
                  onFocus={onHeaderFocus}
                />
              </Box>
              <Box>{icons}</Box>
            </Box>
          </>
        )}
        {pagination}
        {children}
        {buttonsEl}
      </Box>
    </Box>
  );
}
