import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

export default function TextBodyPrimary({ text, translationKey }) {
  const { t } = useTranslation(translationKey);

  return (
    <Typography variant="body1" component="span">
      {translationKey ? t(text) : text}
    </Typography>
  );
}
