import React, { useCallback, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { useMetaView } from "../../../contexts/meta-view-context";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { triggerResetPasswordApi } from "app/api/user/userPasswordResetApi";
import { useSnackbar } from "notistack";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import DeleteIcon from "@material-ui/icons/Delete";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useNavigate } from "react-router-dom";
import { userWritePermissions } from "../../../handlers/permissionHandler";

const UsersOverview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useAuthentication();
  const dispatch = useOverviewDispatch();
  const { setInfo, setMeta } = useMetaView();
  const { enqueueSnackbar } = useSnackbar();
  const [currentPage, setCurrentPage] = useState<string>("users");

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("users_overview:defaultInfoTitle"),
        text: t("users_overview:defaultInfoText")
      }
    }),
    [t]
  );

  const showGroups = useIsFeaturePresent(FEATURES.USERGROUPS_FEATURES) && auth?.permissions.includes("group_read_all");
  const showRoles = useIsFeaturePresent(FEATURES.USERROLES_FEATURES) && auth?.permissions.includes("role_read_all");

  const pages = useMemo(() => {
    const pages = [{ title: t("users_overview:users"), route: "users", current: currentPage === "users" }];
    if (showGroups) {
      pages.push({ title: t("users_overview:groups"), route: "groups", current: currentPage === "groups" });
    }
    if (showRoles && auth?.permissions.includes("super_admin")) {
      pages.push({ title: t("users_overview:roles"), route: "roles", current: currentPage === "roles" });
    }
    return pages;
  }, [t, currentPage, showGroups, showRoles, auth?.permissions]);

  const onPageChange = useCallback(
    ({ route }) => {
      navigate("/" + route);
      dispatch({ type: OVERVIEW_ACTIONS.SET_SELECTED_ID, collection: COLLECTIONS.USER, selectedId: null });
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW, collection: COLLECTIONS.USER, reloadOverview: Date.now() });
    },
    [dispatch, navigate]
  );

  const showEnterInfo = useCallback(() => {
    setMeta(null);
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo, setMeta]);

  const sendResetEmail = useCallback(
    async (selectedUserEmail: string) => {
      if (!selectedUserEmail) {
        throw new Error("Selected user have no email");
      }

      try {
        await triggerResetPasswordApi({ email: selectedUserEmail });
        enqueueSnackbar(t("manage-user-page:user_resetted"), { variant: "success" });
      } catch (error) {
        console.error("failure on sending password reset email", error);
      }
    },
    [enqueueSnackbar, t]
  );

  const resetHandler = useCallback(
    async (id, item) => {
      await sendResetEmail(item.email);
    },
    [sendResetEmail]
  );

  const toolbarActions = useMemo(() => [{ action: "sort" }, { action: "filter" }], []);
  const addActions = useMemo(() => [{ action: OVERVIEW_ADD_TYPE.SINGLE }], []);
  const rowActions = useMemo(
    () => [
      { action: "reset", title: t("manage-user-page:reset"), icon: <VpnKeyIcon />, onHandle: resetHandler },
      { action: "remove", title: t("manage-user-page:delete"), icon: <DeleteIcon /> }
    ],
    [t, resetHandler]
  );

  const showAddActions = auth?.permissions.find(permission => userWritePermissions.includes(permission));

  return (
    <DocMetaView metaViewContent={<MetaView translationKey={"users_overview"} />}>
      <Overview
        pages={pages}
        onPageChange={onPageChange}
        collection={COLLECTIONS.USER}
        onAddClose={showEnterInfo}
        onRowLeave={showEnterInfo}
        header={t("users_overview:header")}
        rowActions={rowActions}
        addActions={showAddActions ? addActions : undefined}
        toolbarActions={toolbarActions}
        checkable={true}
      />
    </DocMetaView>
  );
};

export default React.memo(UsersOverview);
