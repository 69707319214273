export const mapYesNoToBoolean = string => {
  if (string === "yes") {
    return true;
  }
  if (string === "no") {
    return false;
  }
  return null;
};

export const mapBooleanToYesNo = boolean => {
  if (boolean === true) {
    return "yes";
  }
  if (boolean === false) {
    return "no";
  }
};
