import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Question from "components/Question/Question";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import {
  deleteProcessRecipient,
  getProcessRecipentsPageData,
  getRelatedExternalDataRecipientIds,
  patchProcessRecipient,
  postProcessRecipient
} from "app/api/paApi";
import ChipsMergingConfirmationModal from "../utils/ChipsMergingConfirmationModal/ChipsMergingConfirmationModal";
import { useMetaView } from "app/contexts/meta-view-context";
import CardWithTextButton from "components/CardWithButton/CardWithTextButton";
import { debounce, isEqual, omit } from "lodash-es";
import { useProcessPage } from "app/contexts/process-page-context";
import { accordionEquality, getSelectedChips } from "../generic-page/ProcessGenericPage";
import { PersonGroupCategoryChip } from "../PersonGroupCategoryChip";
import { CategoryTypeTupleModel } from "../CategoryTypeTupleModel";
import { v4 } from "uuid";
import CustomAlert from "components/CustomAlert/CustomAlert";
import useSWR, { mutate } from "swr";
import { SWR_KEYS } from "app/swrKeys";
import useSWRMutation from "swr/mutation";
import { useEnteringInfoCard } from "hook/useEnteringInfoCard";

export interface ProcessRecipient {
  readonly id?: string;
  readonly accordionId?: string;
  readonly dataSourceIds: string[];
  readonly dataStorageIds: string[];
  readonly internalDataRecipientIds: string[];
  readonly externalDataRecipientIds: string[];
  readonly categoryTypeTuples: CategoryTypeTupleModel[];
}

export interface ProcessRecipientsPageModel {
  items: ProcessRecipient[];
}

const ProcessRecipientAccordion = ({
  saving,
  processId,
  forceCollapse,
  selectedChipIds,
  processRecipient,
  onSave,
  onChipClick,
  disabled
}: {
  readonly forceCollapse?: string;
  readonly saving?: boolean;
  readonly processId: string;
  readonly selectedChipIds: string[];
  readonly processRecipient: ProcessRecipient;
  readonly disabled?: boolean;
  readonly onSave: (recipient: Partial<ProcessRecipient>) => void;
  readonly onChipClick?: (
    partialRecipient: Partial<ProcessRecipient>,
    categoryTypeTuple: CategoryTypeTupleModel
  ) => Promise<void>;
}) => {
  const { t } = useTranslation("questionnaires");

  const [recipient, setRecipient] = useState<ProcessRecipient | null>(null);
  const [partialRecipientToSave, setPartialRecipientToSave] = useState<Partial<ProcessRecipient>>({});
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [loadRelatedExteranalDataRecipient, setLoadRelatedExteranalDataRecipient] = useState<boolean>(false);

  const chipClickHandler = useSWRMutation(
    // using swr mutation to prevent double click by accident, subsequent clicks will be disabled while mutation is in progress
    [SWR_KEYS.ProcessRecipientsPage, partialRecipientToSave, recipient],
    async (
      _,
      {
        arg: categoryTypeTuple
      }: {
        arg: CategoryTypeTupleModel;
      }
    ) => {
      if (!recipient) return;
      const recipientToCopy = {
        ...recipient,
        ...partialRecipientToSave
      };
      await onChipClick?.(omit(recipientToCopy, ["categoryTypeTuples"]), categoryTypeTuple);
    },
    {
      onSuccess: mutate
    }
  );

  useEffect(() => {
    if (forceCollapse) {
      _setExpanded(false);
    }
  }, [forceCollapse]);

  useEffect(() => setRecipient(processRecipient), [processRecipient]);
  useEffect(() => {
    const dirty =
      !isEqual({}, partialRecipientToSave) &&
      Object.keys(partialRecipientToSave).some(
        key =>
          !isEqual(
            partialRecipientToSave[key as keyof ProcessRecipient],
            processRecipient[key as keyof ProcessRecipient]
          )
      );
    setIsDirty(dirty);
    if (!dirty && !isEqual({}, partialRecipientToSave)) {
      setPartialRecipientToSave({});
    }
  }, [partialRecipientToSave, processRecipient]);

  const [_expanded, _setExpanded] = useState<boolean>(true);

  const handleRelatedExternalDataRecipients = useCallback(
    async (currentData: ProcessRecipient, newData: Partial<ProcessRecipient>) => {
      const newDataLocationIds = [...new Set([...(newData.dataSourceIds || []), ...(newData.dataStorageIds || [])])];
      const currentDataLocationIds = [
        ...new Set([...(currentData?.dataSourceIds || []), ...(currentData?.dataStorageIds || [])])
      ];

      if (newDataLocationIds.length && !isEqual(newDataLocationIds, currentDataLocationIds)) {
        setLoadRelatedExteranalDataRecipient(true);
        const relatedExternalDataRecipientIds = await getRelatedExternalDataRecipientIds({
          processId,
          dataLocationIds: newDataLocationIds
        });

        if (relatedExternalDataRecipientIds && relatedExternalDataRecipientIds.length) {
          const externalDataRecipientIds = [
            ...new Set([...(currentData?.externalDataRecipientIds || []), ...relatedExternalDataRecipientIds])
          ];
          setRecipient(current => (current ? { ...current, externalDataRecipientIds } : null));
          setPartialRecipientToSave(current => ({ ...current, externalDataRecipientIds }));
        }
        setLoadRelatedExteranalDataRecipient(false);
      }
    },
    [processId]
  );

  const debounceHandleRelatedExternalDataRecipients = useRef(
    debounce(handleRelatedExternalDataRecipients, 500)
  ).current;

  const onCancelCallback = useCallback(() => {
    setRecipient(processRecipient);
    setPartialRecipientToSave({});
    setIsDirty(false);
    _setExpanded(false);
  }, [processRecipient]);

  const onSaveCallback = useCallback(() => {
    if (recipient && !recipient?.id) {
      onSave(recipient);
    } else if (!isEqual({}, partialRecipientToSave)) {
      onSave({ ...partialRecipientToSave, id: recipient?.id, accordionId: recipient?.accordionId });
    }
    setPartialRecipientToSave({});
    setIsDirty(false);
    _setExpanded(false);
  }, [onSave, partialRecipientToSave, recipient]);

  const patch = useCallback(
    (data: Partial<ProcessRecipient>) => {
      if (recipient) {
        debounceHandleRelatedExternalDataRecipients(recipient, data);
        setRecipient(current => (current ? { ...current, ...data } : null));
        setPartialRecipientToSave(current => ({ ...current, ...data }));
      }
    },
    [debounceHandleRelatedExternalDataRecipients, recipient]
  );

  /* dataSourceIds */
  const dataSourceIdsChangeCallback = useCallback((dataSourceIds: string[]) => patch({ dataSourceIds }), [patch]);

  /* dataStorageIds */
  const dataStorageIdsChangeCallback = useCallback((dataStorageIds: string[]) => patch({ dataStorageIds }), [patch]);

  /* internalDataRecipientIds */
  const internalDataRecipientIdsChangeCallback = useCallback(
    (internalDataRecipientIds: string[]) => patch({ internalDataRecipientIds }),
    [patch]
  );

  /* externalDataRecipientIds */
  const externalDataRecipientIdsChangeCallback = useCallback(
    (externalDataRecipientIds: string[]) => patch({ externalDataRecipientIds }),
    [patch]
  );

  const toggleExpanded = useCallback(() => {
    _setExpanded(v => !v);
  }, []);

  const chips = useMemo(
    () =>
      processRecipient.categoryTypeTuples.reduce((result: CategoryTypeTupleModel[], next: CategoryTypeTupleModel) => {
        if (result.some(r => r.personGroup === next.personGroup && r.category === next.category)) {
          return result;
        } else return [...result, next];
      }, []),
    [processRecipient.categoryTypeTuples]
  );

  const chipsEl = chips.map((categoryTypeTupleModel: CategoryTypeTupleModel) => (
    <PersonGroupCategoryChip
      selected={selectedChipIds.includes("*") || selectedChipIds.includes(categoryTypeTupleModel.id)}
      key={categoryTypeTupleModel.id}
      categoryTypeTuple={categoryTypeTupleModel}
      onClick={chipClickHandler.isMutating || disabled ? undefined : chipClickHandler.trigger}
    />
  ));
  const dirtyEl = !saving && isDirty && (
    <CustomAlert severity="error" icon={<ErrorOutlineIcon />}>
      {t("common:unsavedChanges")}
    </CustomAlert>
  );
  const loaderEl = saving && <CircularProgress />;

  return (
    <Accordion key={recipient?.id || ""} expanded={_expanded}>
      <AccordionSummary onClick={toggleExpanded} expandIcon={<ExpandMoreIcon />}>
        <Box>
          <Box display={"flex"}>
            {dirtyEl}
            <Box flex={1} />
          </Box>

          <Box display={"flex"} width={"100%"}>
            <Box flexWrap={"wrap"} display={"flex"} flex={1}>
              {chipsEl}
            </Box>
            <Box display={"flex"} alignItems={"center"}>
              {loaderEl}
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%">
          <Box>
            <QuestionnaireSubHeader text={t("dataSource:headline1")} />
            <Question
              qType={QUESTION_TYPE.DATA_SOURCE}
              value={recipient?.dataSourceIds || []}
              questionName={t("data_subject_requests_data_page:datasource")}
              infoId={"infocard.pa.page3.accordion.dataSources"}
              onChange={dataSourceIdsChangeCallback}
              disabled={saving || disabled}
            />
            <Question
              qType={QUESTION_TYPE.DATA_STORAGE}
              value={recipient?.dataStorageIds || []}
              questionName={t("data_subject_requests_data_page:datalocation")}
              infoId={"infocard.pa.page3.accordion.dataStorages"}
              onChange={dataStorageIdsChangeCallback}
              disabled={saving || disabled}
            />
            <QuestionnaireSubHeader text={t("dataSource:headline2")} />
            <Question
              qType={QUESTION_TYPE.INTERNAL_DATA_RECIPIENTS}
              value={recipient?.internalDataRecipientIds || []}
              infoId={"infocard.pa.page3.accordion.internalDataRecipients"}
              onChange={internalDataRecipientIdsChangeCallback}
              disabled={saving || disabled}
            />
            <Question
              qType={QUESTION_TYPE.EXTERNAL_DATA_RECIPIENTS}
              value={recipient?.externalDataRecipientIds || []}
              infoId={"infocard.pa.page3.accordion.externalDataRecipients"}
              onChange={externalDataRecipientIdsChangeCallback}
              disabled={saving || loadRelatedExteranalDataRecipient || disabled}
            />
          </Box>
          <Box display={"flex"} width={"100%"}>
            <Box flex={1} />
            <Button variant="outlined" color="primary" onClick={onCancelCallback} disabled={disabled}>
              {t("common:cancel")}
            </Button>
            <Box mx={1} />
            <Button variant="contained" color="primary" onClick={onSaveCallback} disabled={disabled}>
              {t("common:save")}
            </Button>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const ProcessRecipientsPage = (props: { readonly readonly?: boolean }) => {
  const { t } = useTranslation("questionnaires");
  const { id } = useParams();
  const { onBeforeProcessUpdate, setProcessMeta } = useProcessPage();
  const { setInfoId, meta, info } = useMetaView();
  const navigate = useNavigate();
  useEnteringInfoCard({
    pathName: `/processes/${id}/recipients`,
    infoId: "infocard.pa.page3"
  });

  const [items, setItems] = useState<ProcessRecipient[]>([]);
  const [selectedChipIds, setSelectedChipIds] = useState<string[]>([]);
  const [showMergeConfirm, setShowMergeConfirm] = useState<boolean>(false);
  const [categoryTuplesToMerge, setCategoryTuplesToMerge] = useState<CategoryTypeTupleModel | null>(null);
  const [partialRecipientToMerge, setPartialRecipientToMerge] = useState<Partial<ProcessRecipient> | null>(null);

  const [savingRecipientIds, setSavingRecipientIds] = useState<string[]>([]);

  /* FETCH PAGE */
  const fetchRecipientsPage = useSWR(
    [SWR_KEYS.ProcessRecipientsPage, id],
    async () =>
      await getProcessRecipentsPageData({
        processId: id || ""
      }),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  const { mutate: fetchRecipientsPageMutate } = fetchRecipientsPage;

  useEffect(() => {
    if (!fetchRecipientsPage.data) {
      return;
    }

    setItems(fetchRecipientsPage.data.processPage?.items);
    setProcessMeta(fetchRecipientsPage.data.processMeta);
  }, [fetchRecipientsPage.data, fetchRecipientsPage.isLoading, setProcessMeta]);

  useEffect(() => {
    if (meta === null && info === null) {
      setInfoId("infocard.pa.page3.accordion.externalDataRecipients");
    }
  }, [meta, info, setInfoId, t]);

  useEffect(() => {
    setSelectedChipIds(getSelectedChips(items));
  }, [items]);

  const onPatchRecipient = useCallback(
    async (partialRecipient: Partial<ProcessRecipient>) => {
      // if no ID yet
      // nothing to PATCH
      if (!partialRecipient.id) {
        return;
      }

      const currentRecipient = items.find(({ id }) => id === partialRecipient.id);
      // if recipient not exist
      // nothing to PATCH
      if (!currentRecipient) {
        return;
      }

      setSavingRecipientIds(current => [...current, partialRecipient.id] as string[]);
      await patchProcessRecipient({
        processId: id || "",
        recipientId: partialRecipient.id,
        payload: partialRecipient
      });
      setSavingRecipientIds(current => current.filter(id => id !== partialRecipient.id) as string[]);
    },
    [id, items]
  );

  const onPostRecipient = useCallback(
    async (partialRecipient: Partial<ProcessRecipient>) => {
      // if id is exist
      // nothing to POST
      if (partialRecipient.id) {
        return;
      }

      // if accordionId not exist
      // nothing to POST
      if (!partialRecipient.accordionId) {
        return;
      }

      setSavingRecipientIds(["*"]);
      await postProcessRecipient({
        processId: id || "",
        payload: partialRecipient
      });
      setSavingRecipientIds([]);
    },
    [id]
  );

  const onDeleteRecipient = useCallback(
    async (recipientId: string) => {
      setSavingRecipientIds(["*"]);
      await deleteProcessRecipient({ processId: id || "", recipientId });
      setSavingRecipientIds([]);
    },
    [id]
  );

  const onChangeRecipient = useCallback(
    async (partialRecipient: Partial<ProcessRecipient>) => {
      await onBeforeProcessUpdate(async () => {
        if (partialRecipient.id) {
          await onPatchRecipient(partialRecipient);
        } else if (partialRecipient.accordionId) {
          await onPostRecipient(partialRecipient);
        }
        await fetchRecipientsPageMutate();
      });
    },
    [onBeforeProcessUpdate, onPatchRecipient, onPostRecipient, fetchRecipientsPageMutate]
  );

  const onChipClickCallback = useCallback(
    async (partialRecipient: Partial<ProcessRecipient>, categoryTypeTuple: CategoryTypeTupleModel) => {
      await onBeforeProcessUpdate(async () => {
        if (!id) {
          return;
        }
        // create new accordion
        if (selectedChipIds.includes("*") || selectedChipIds.includes(categoryTypeTuple.id)) {
          const currentRecipient = items.find(item => accordionEquality(item, partialRecipient));
          if (currentRecipient) {
            const categoryTypeTuplesForNewRecipient = currentRecipient.categoryTypeTuples.filter(
              ({ personGroup, category }) =>
                categoryTypeTuple.personGroup === personGroup && categoryTypeTuple.category === category
            );
            const newRecipient: ProcessRecipient = {
              ...currentRecipient,
              ...partialRecipient,
              id: undefined,
              accordionId: v4(),
              categoryTypeTuples: categoryTypeTuplesForNewRecipient
            };
            const itemsToSave = items.map(item => {
              if (accordionEquality(item, partialRecipient)) {
                return { ...item, ...partialRecipient };
              }
              return item;
            });
            itemsToSave.push(newRecipient);

            // save current if need
            const needSaveCurrent = partialRecipient.id && !isEqual(omit(partialRecipient, ["id", "accordionId"]), {});
            if (needSaveCurrent) {
              await onPatchRecipient(partialRecipient);
            }

            // save new
            for (const item of itemsToSave) {
              await onPostRecipient(item);
            }
            // refresh recipients after post
            await fetchRecipientsPageMutate();
          }
        } else {
          // join accordions
          setCategoryTuplesToMerge(categoryTypeTuple);
          setPartialRecipientToMerge(partialRecipient);
          setShowMergeConfirm(true);
        }
      });
    },
    [fetchRecipientsPageMutate, id, items, onBeforeProcessUpdate, onPatchRecipient, onPostRecipient, selectedChipIds]
  );

  const onMergeConfirmCallback = useCallback(async () => {
    if (categoryTuplesToMerge && partialRecipientToMerge) {
      const itemsToSave = items
        .filter(
          (item, index) => index === 0 || !item.categoryTypeTuples.some(({ id }) => id === categoryTuplesToMerge.id)
        )
        .map(item => {
          if (accordionEquality(item, partialRecipientToMerge)) {
            return { ...item, ...partialRecipientToMerge };
          }
          return item;
        });

      const currentIds = items.map(({ id }) => id);
      const newIds = itemsToSave.map(({ id }) => id);
      const toDeleteIds = currentIds.filter(item => newIds.indexOf(item) < 0).filter(notNull => notNull) as string[];

      // save current
      await onPatchRecipient(partialRecipientToMerge);

      // delete
      for (const id of toDeleteIds) {
        await onDeleteRecipient(id);
      }
      await fetchRecipientsPageMutate();

      setCategoryTuplesToMerge(null);
      setPartialRecipientToMerge(null);
    }
  }, [
    categoryTuplesToMerge,
    fetchRecipientsPageMutate,
    items,
    onDeleteRecipient,
    onPatchRecipient,
    partialRecipientToMerge
  ]);

  const onMergeCloseCallback = useCallback(() => {
    setCategoryTuplesToMerge(null);
    setShowMergeConfirm(false);
  }, []);

  const recipientPageNavigateCallback = useCallback(() => {
    navigate(`/processes/${id}/description`);
  }, [id, navigate]);

  if (fetchRecipientsPage.isLoading) {
    return (
      <Box textAlign={"center"} mt={8}>
        <CircularProgress />
      </Box>
    );
  }

  if (!fetchRecipientsPage.isLoading && !items.find(item => item.categoryTypeTuples.length > 0)) {
    return (
      <CardWithTextButton
        text={t("specifyPersonalData")}
        buttonText={t("goToPage2")}
        colorTheme={"blue"}
        onClick={recipientPageNavigateCallback}
      />
    );
  }

  const accordionsEl = items
    ?.filter(recipient => recipient.categoryTypeTuples.length)
    ?.map((recipient, index) => (
      <Box key={`${recipient.id || recipient.accordionId}-${index}`}>
        <Question
          questionId={"dataSourceAndLocation " + (recipient.id || recipient.accordionId)}
          questionName={t("dataSource:page_title")}
          disabled={props.readonly}
        >
          <ProcessRecipientAccordion
            saving={Boolean(
              savingRecipientIds.includes("*") ||
                (recipient.id && savingRecipientIds.includes(recipient.id)) ||
                (recipient.accordionId && savingRecipientIds.includes(recipient.accordionId))
            )}
            processId={id || ""}
            selectedChipIds={index === 0 ? selectedChipIds : ["*"]}
            processRecipient={recipient}
            onSave={onChangeRecipient}
            onChipClick={index === 0 ? onChipClickCallback : undefined}
            disabled={props.readonly}
          />
        </Question>
      </Box>
    ));

  const mergeConfirmDialogEl = (
    <ChipsMergingConfirmationModal
      chipTitleModalOpen={showMergeConfirm}
      handleChipTitleModalClose={onMergeCloseCallback}
      currentSelectedChipTitle={undefined}
      dispatch={onMergeConfirmCallback}
    />
  );

  return (
    <>
      <QuestionnaireSubHeader text={t("dataSource:page_title")} />
      {accordionsEl}
      {mergeConfirmDialogEl}
    </>
  );
};

export default ProcessRecipientsPage;
