import React, { useCallback, useEffect, useState } from "react";
import { Box, Divider, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextBodySecondary from "components/TextBodySecondary/TextBodySecondary";
import PropTypes from "prop-types";
import BasicPagination from "components/BasicPagination/BasicPagination";
import RowItem from "./RowItem";

const useStylesBlue = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "10px"
  },
  button: {
    color: theme.palette.primary.main,
    fontWeight: "bold"
  },
  cardHeader: {
    fontWeight: 700
  }
}));

const useStylesYellow = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.warning.light,
    padding: 25,
    paddingBottom: 15,
    borderRadius: "10px",
    marginTop: "20px"
  },
  button: {
    color: theme.palette.alert.warning,
    fontWeight: "bold"
  },
  cardHeader: {
    fontWeight: 700
  }
}));

const useStylesGreen = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.success.light,
    padding: 25,
    paddingBottom: 15,
    borderRadius: "10px",
    marginTop: "20px"
  },
  button: {
    color: theme.palette.success.main,
    fontWeight: "bold"
  },
  cardHeader: {
    fontWeight: 700
  }
}));

const useStylesRed = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.error.light,
    padding: 25,
    paddingBottom: 15,
    borderRadius: "10px",
    marginTop: "20px"
  },
  button: {
    color: theme.palette.error.main,
    fontWeight: "bold"
  },
  cardHeader: {
    fontWeight: 700
  }
}));

export default function CardItemList({
  header,
  headerIcon,
  headerIconTooltip,
  itemList,
  colorTheme,
  text,
  elementsPerPage,
  disablePagination
}) {
  const [classes, setClasses] = useState({});
  const blueClasses = useStylesBlue();
  const yellowClasses = useStylesYellow();
  const greenClasses = useStylesGreen();
  const redClasses = useStylesRed();
  const [page, setPage] = useState(1);

  useEffect(() => {
    switch (colorTheme) {
      case "yellow":
        setClasses(yellowClasses);
        break;
      case "green":
        setClasses(greenClasses);
        break;
      case "red":
        setClasses(redClasses);
        break;
      case "blue":
      default:
        setClasses(blueClasses);
        break;
    }
  }, [blueClasses, colorTheme, greenClasses, redClasses, yellowClasses]);

  const isNotLastRow = useCallback(
    function (index) {
      return (page - 1) * elementsPerPage + index < itemList.length - 1 && index < page * elementsPerPage - 1;
    },
    [page, elementsPerPage, itemList]
  );

  const currentPageItems = useCallback(
    function (element, index) {
      return index < page * elementsPerPage && index >= (page - 1) * elementsPerPage;
    },
    [page, elementsPerPage]
  );

  const entireRow = useCallback(
    function (item, index) {
      return (
        <React.Fragment key={"cardItemList_" + index}>
          <RowItem
            button
            {...item}
            classes={classes}
            primaryTypographyProps={{
              style: {
                width: item.actionIcons ? "80%" : "100%"
              }
            }}
            secondaryTypographyProps={{
              style: {
                width: item.actionIcons ? "65%" : "100%"
              }
            }}
          />
          {isNotLastRow(index) && <Divider />}
        </React.Fragment>
      );
    },
    [isNotLastRow, classes]
  );

  return (
    <Box className={classes.card} p={3}>
      <RowItem
        primaryIcon={headerIcon}
        primaryIconTooltip={headerIconTooltip}
        primaryText={header}
        key={"cardItemListHeaderRow"}
        classes={classes}
        primaryTypographyProps={{
          variant: "h4",
          style: {
            fontWeight: 700
          }
        }}
      />
      {text && (
        <Box mt={2}>
          <TextBodySecondary text={text} />
        </Box>
      )}
      <List>
        {itemList.filter(currentPageItems).map(entireRow)}
        {!disablePagination && itemList.length > elementsPerPage && (
          <BasicPagination
            elementsPerPage={elementsPerPage}
            page={page}
            setPage={setPage}
            numberElements={itemList.length}
          />
        )}
      </List>
    </Box>
  );
}

CardItemList.propTypes = {
  header: PropTypes.string,
  headerIcon: PropTypes.string,
  colorTheme: PropTypes.string,
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      primaryIcon: PropTypes.string,
      primaryIconTooltip: PropTypes.string,
      onPrimaryIconClick: PropTypes.func,
      actionIcons: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.string,
          tooltip: PropTypes.string,
          onClick: PropTypes.func
        })
      ),
      onClick: PropTypes.func,
      primaryText: PropTypes.string,
      secondaryText: PropTypes.string
    })
  ),
  text: PropTypes.string,
  elementsPerPage: PropTypes.number,
  disablePagination: PropTypes.bool
};

CardItemList.defaultProps = {
  itemList: [],
  elementsPerPage: 5,
  disablePagination: false
};
