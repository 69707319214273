import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@material-ui/core/Box";
import QuestionTitle from "../QuestionTitle/QuestionTitle";
import { FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import CustomAlert from "../CustomAlert/CustomAlert";

const useStyles = makeStyles(theme => ({
  radioChecked: {
    "&$checked": {
      color: theme.palette.primary.main
    }
  },
  checked: {
    color: theme.palette.primary.main
  },
  alertText: {
    fontSize: 10
  },
  radioLabel: {
    paddingLeft: "5px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: theme.palette.grey[300]
    }
  }
}));

export default function RadioButtonQuestion({
  id,
  defaultValue,
  questionId,
  radioOptions,
  disabledOptions,
  infoAlertText,
  question,
  initialValue,
  onChange,
  index,
  optionLabels,
  labelPlacement = "start",
  onFocus,
  infoIcon,
  disabled
}) {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const handleChange = useCallback(
    event => {
      setValue(event.target.value);
      onChange(questionId, event.target.value, index);
    },
    [onChange, questionId, index]
  );

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const radioClasses = useMemo(() => ({ root: classes.radioChecked, checked: classes.checked }), [classes]);
  return (
    <Box display="flex" alignItems="center" className={classes.radioLabel}>
      <Box flex={1} pt={1} pr={2}>
        <QuestionTitle>{question}</QuestionTitle>
        {infoAlertText && (
          <CustomAlert severity={"info"}>
            <span className={classes.alertText}>{infoAlertText}</span>
          </CustomAlert>
        )}
      </Box>
      {infoIcon && <Box>{infoIcon}</Box>}
      <Box>
        <RadioGroup
          row
          id={id}
          name={questionId}
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          onFocus={onFocus}
          disabled={disabled}
        >
          {radioOptions.map((option, index) => (
            <Box mx={2} key={`${questionId}_${index}`}>
              <FormControlLabel
                disabled={disabledOptions?.includes(option) || disabled}
                labelPlacement={labelPlacement}
                value={option}
                control={<Radio classes={radioClasses} disabled={disabled} />}
                label={optionLabels && optionLabels[index]}
              />
            </Box>
          ))}
        </RadioGroup>
      </Box>
    </Box>
  );
}
