import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { AssessmentMeasureDTO } from "app/api/generated/process-service";
import { useFinalAssessmentPageApi } from "app/api/process/finalAssessmentPageApi";
import AccordionMultiField from "components/AccordionMultiField/AccordionMultiField";
import AutocompleteElement from "components/BoxedList/AutocompleteElement";
import TextArea from "components/TextArea/TextArea";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { isEqual } from "lodash-es";

const useStyles = makeStyles(theme => ({
  accordionMultiFieldContent: {
    position: "relative",
    width: "92.7%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "-5px",
    marginTop: -10
  },
  measureContainerLeft: {
    width: "92%",
    marginTop: 16,
    marginRight: 20
  },
  measureContainerRight: {
    width: "92%"
  }
}));

const priorities = ["low", "medium", "high"];

export default function AssessmentMeasureAccordion({
  assessmentMeasure,
  readonly
}: {
  assessmentMeasure: AssessmentMeasureDTO;
  readonly: boolean;
}) {
  const { t } = useTranslation("questionnaire_four_five_page");
  const classes = useStyles();

  const [currentAssessmentMeasure, setCurrentAssessmentMeasure] = React.useState(assessmentMeasure);
  React.useEffect(() => {
    if (assessmentMeasure) {
      setCurrentAssessmentMeasure(assessmentMeasure);
    }
  }, [assessmentMeasure]);

  const [dirty, setDirty] = React.useState<boolean>(false);
  React.useEffect(() => {
    const diff = Object.keys(currentAssessmentMeasure).some(
      key =>
        !isEqual(
          currentAssessmentMeasure[key as keyof AssessmentMeasureDTO],
          assessmentMeasure[key as keyof AssessmentMeasureDTO]
        )
    );
    setDirty(diff);
  }, [currentAssessmentMeasure, assessmentMeasure]);

  const [isSaving, setIsSaving] = React.useState(false);

  const { id } = useParams();
  const finalAssessment = useFinalAssessmentPageApi({ documentId: id as string });
  const onAssessmentMeasureChange = React.useCallback(
    async measure => {
      await finalAssessment.actions.updateAssessmentMeasure(
        measure.id,
        {
          measure: measure.measure,
          priority: measure.priority
        },
        () => {
          setIsSaving(false);
        }
      );
    },
    [finalAssessment.actions]
  );
  const onClickSave = React.useCallback(async () => {
    setIsSaving(true);
    try {
      await onAssessmentMeasureChange(currentAssessmentMeasure);
      setDirty(false);
    } catch (error: unknown) {
      setIsSaving(false);
      throw error;
    }
    setIsSaving(false);
  }, [currentAssessmentMeasure, onAssessmentMeasureChange]);

  // update assessment measures field entry at the index
  const updateAssessmentMeasures = React.useCallback(
    (key: string) => (value: string) => {
      setCurrentAssessmentMeasure(state => ({
        ...state,
        [key]: value
      }));
    },
    []
  );

  const deleteMeasurement = React.useCallback(
    async function () {
      await finalAssessment.actions.deleteAssessmentMeasure(assessmentMeasure.id);
    },
    [finalAssessment.actions, assessmentMeasure.id]
  );

  const onClickCancel = React.useCallback(() => {
    setCurrentAssessmentMeasure(assessmentMeasure);
  }, [assessmentMeasure]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore jsx
    <AccordionMultiField
      id={"assessmentMeasures " + assessmentMeasure.id}
      key={assessmentMeasure.id}
      field={"assessmentMeasures"}
      deleteButtonText={t("questionnaires:delete")}
      cancelButtonText={t("questionnaires:cancel")}
      saveButtonText={t("questionnaires:save")}
      disableButton={readonly}
      deleteMultiField={deleteMeasurement}
      loading={isSaving}
      isNewMultiFiled={!assessmentMeasure.measure && !assessmentMeasure.priority}
      onClickSave={onClickSave}
      hasCancelAndSave={true}
      onClickCancel={onClickCancel}
      titleType={"text"}
      title={
        <Box display="flex" alignItems="center">
          <Typography>{currentAssessmentMeasure.measure}</Typography>
          {isSaving && <CircularProgress size="1rem" />}
        </Box>
      }
      dirty={dirty}
    >
      <div className={classes.accordionMultiFieldContent}>
        <div className={classes.measureContainerLeft}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore jsx */}
          <TextArea
            rowsMin={1}
            value={currentAssessmentMeasure.measure || ""}
            disabled={readonly}
            onChange={updateAssessmentMeasures("measure")}
            label={t("measure")}
            id={"measure " + assessmentMeasure.id}
          />
        </div>
        <div className={classes.measureContainerRight}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore jsx */}
          <AutocompleteElement
            value={currentAssessmentMeasure.priority}
            inputValue={t(currentAssessmentMeasure.priority)}
            options={priorities}
            getOptionLabel={t}
            onChange={updateAssessmentMeasures("priority")}
            label={t("priority")}
            id={"priority " + assessmentMeasure.id}
          />
        </div>
      </div>
    </AccordionMultiField>
  );
}
