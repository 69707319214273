import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

let useStyles = makeStyles(theme => {
  return {
    questionTitleText: {
      marginLeft: 0,
      userSelect: "none",
      color: props => (props.disabled ? theme.palette.text.disabled : "inherit")
    }
  };
});

export default function QuestionTitle({ children, disabled }) {
  const classes = useStyles({ disabled });

  return (
    <Typography variant="subtitle1" component="div" className={classes.questionTitleText}>
      {children}
    </Typography>
  );
}

QuestionTitle.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool
};

QuestionTitle.defaultProps = {
  children: null,
  disabled: false
};
