import { FEATURES } from "../features";

export const allAvailableLanguages = [
  "en",
  "de",
  "fr",
  "cz",
  "pl",
  "de-CH",
  "da",
  "nl",
  "fi",
  "hu",
  "it",
  "ko",
  "no",
  "pt",
  "ro",
  "sk",
  "sl",
  "es",
  "sv"
] as const;
export const defaultLng = (features: string[]) => {
  if (features.includes(FEATURES.LANGUAGE_DECH)) return "de-CH";
  if (features.includes(FEATURES.LANGUAGE_PL)) return "pl";
  if (features.includes(FEATURES.LANGUAGE_FR)) return "fr";
  if (features.includes(FEATURES.LANGUAGE_CZ)) return "cz";
  if (features.includes(FEATURES.LANGUAGE_DE)) return "de";
  if (features.includes(FEATURES.LANGUAGE_DA)) return "da";
  if (features.includes(FEATURES.LANGUAGE_NL)) return "nl";
  if (features.includes(FEATURES.LANGUAGE_FI)) return "fi";
  if (features.includes(FEATURES.LANGUAGE_HU)) return "hu";
  if (features.includes(FEATURES.LANGUAGE_IT)) return "it";
  if (features.includes(FEATURES.LANGUAGE_KO)) return "ko";
  if (features.includes(FEATURES.LANGUAGE_NO)) return "no";
  if (features.includes(FEATURES.LANGUAGE_PT)) return "pt";
  if (features.includes(FEATURES.LANGUAGE_RO)) return "ro";
  if (features.includes(FEATURES.LANGUAGE_SK)) return "sk";
  if (features.includes(FEATURES.LANGUAGE_SL)) return "sl";
  if (features.includes(FEATURES.LANGUAGE_ES)) return "es";
  if (features.includes(FEATURES.LANGUAGE_SV)) return "sv";
  else return "en";
};

export const languageToggles: Record<string, string> = {
  fr: FEATURES.LANGUAGE_FR,
  cz: FEATURES.LANGUAGE_CZ,
  pl: FEATURES.LANGUAGE_PL,
  en: FEATURES.LANGUAGE_EN,
  de: FEATURES.LANGUAGE_DE,
  nl: FEATURES.LANGUAGE_NL,
  fi: FEATURES.LANGUAGE_FI,
  hu: FEATURES.LANGUAGE_HU,
  it: FEATURES.LANGUAGE_IT,
  da: FEATURES.LANGUAGE_DA,
  ko: FEATURES.LANGUAGE_KO,
  no: FEATURES.LANGUAGE_NO,
  pt: FEATURES.LANGUAGE_PT,
  ro: FEATURES.LANGUAGE_RO,
  sk: FEATURES.LANGUAGE_SK,
  sl: FEATURES.LANGUAGE_SL,
  es: FEATURES.LANGUAGE_ES,
  sv: FEATURES.LANGUAGE_SV,
  "de-CH": FEATURES.LANGUAGE_DECH
};
