import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { OVERVIEW_ADD_TYPE } from "../constants/OverviewConstants";
import Add from "@material-ui/icons/Add";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiButtonGroup-groupedContainedPrimary:not(:last-child)": {
      borderColor: "#fff"
    },
    "& .MuiButtonGroup-groupedHorizontal:not(:first-child)": {
      minWidth: "40px",
      width: "40px"
    }
  }
}));

export interface OverviewAddButtonActionProps {
  readonly action: string;
  readonly title?: string;
  readonly placeholder?: string;
  readonly onBefore?: () => void;
  readonly onHandle?: (title?: string) => void;
  readonly onAfter?: () => void;
  readonly onClick?: (action: OverviewAddButtonActionProps) => void;
}

export interface OverviewAddButtonProps {
  readonly actions: OverviewAddButtonActionProps[];
  readonly onAdd: (action: OverviewAddButtonActionProps) => void;
}

const OverviewAddButtonItem = ({
  action,
  title,
  placeholder,
  onBefore,
  onHandle,
  onAfter,
  onClick
}: OverviewAddButtonActionProps) => {
  const { t } = useTranslation("overview");

  const [itemTitle, setItemTitle] = useState("");
  useEffect(() => {
    if (action === OVERVIEW_ADD_TYPE.MULTIPLE) {
      return setItemTitle(title || t("add_button_multiple"));
    } else if (action === OVERVIEW_ADD_TYPE.SECTION) {
      return setItemTitle(title || t("add_button_section"));
    } else if (action === OVERVIEW_ADD_TYPE.CUSTOM || action === OVERVIEW_ADD_TYPE.CUSTOM_MULTIPLE) {
      return setItemTitle(title || "");
    } else if (action === OVERVIEW_ADD_TYPE.TEMPLATE) {
      return setItemTitle(title || t("add_button_template"));
    } else return setItemTitle("");
  }, [action, t, title]);

  const onClickCallback = useCallback(
    () => onClick?.({ action, title, placeholder, onBefore, onHandle, onAfter }),
    [action, onAfter, onBefore, onClick, onHandle, placeholder, title]
  );

  return itemTitle ? <MenuItem onClick={onClickCallback}>{itemTitle}</MenuItem> : <></>;
};

const OverviewAddButton = ({ onAdd, actions }: OverviewAddButtonProps) => {
  const cls = useStyles();
  const { t } = useTranslation("overview");
  const [open, setOpen] = React.useState(false);
  const [anchorRef, setAnchorRef] = useState(null);

  /* ACTIONS */
  const handleToggle = useCallback(event => {
    setAnchorRef(event.currentTarget);
    setOpen(prevOpen => !prevOpen);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setAnchorRef(null);
  }, []);

  const onClickCallback = useCallback(
    async data => {
      handleClose();
      onAdd(data);
    },
    [handleClose, onAdd]
  );

  const singleCreateAction = actions.find(
    a => a.action === OVERVIEW_ADD_TYPE.CUSTOM_SINGLE || OVERVIEW_ADD_TYPE.SINGLE
  );
  const addSingleItem = useCallback(async () => {
    handleClose();
    onAdd(singleCreateAction as OverviewAddButtonActionProps);
  }, [handleClose, onAdd, singleCreateAction]);

  /* ACTIONS */
  const addButtonEl = (
    <ButtonGroup color="primary" variant="contained" aria-label="split button" className={cls.root}>
      {singleCreateAction && (
        <Button data-qa="add_overview_item" onClick={addSingleItem} startIcon={<Add />}>
          {singleCreateAction.title || t("add_button")}
        </Button>
      )}
      {actions?.length > 1 && (
        <Button
          size="medium"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      )}
    </ButtonGroup>
  );
  const menuItemsEl = actions
    .filter(action => action !== singleCreateAction)
    .map(({ action, title, placeholder, onBefore, onHandle, onAfter }, index) => (
      <OverviewAddButtonItem
        key={index}
        action={action}
        title={title}
        placeholder={placeholder}
        onBefore={onBefore}
        onHandle={onHandle}
        onAfter={onAfter}
        onClick={onClickCallback}
      />
    ));
  const popperEl = (
    <Popper open={open} anchorEl={anchorRef} placement={"bottom-end"} transition style={zIndexStyle}>
      {({ TransitionProps }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu" autoFocusItem>
                {menuItemsEl}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
  return (
    <Box display="flex" justifyContent={"end"}>
      {addButtonEl}
      {popperEl}
    </Box>
  );
};

const zIndexStyle: CSSProperties = {
  zIndex: 1000
};

export default OverviewAddButton;
