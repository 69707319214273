import Button from "@material-ui/core/Button";
import React, { useCallback, useState } from "react";
import MeasureAccordion, { MeasureAccordionInput } from "./MeasureAccordion";
import { v4 } from "uuid";
import { Grid, Tooltip } from "@material-ui/core";
import Question from "components/Question/Question";

export interface MeasureAccordionsProps {
  className?: string;
  inputMeasures: MeasureAccordionInput[];
  onSave: (modifiedMeasure: MeasureAccordionInput) => void;
  onDelete: (deletedMeasure: MeasureAccordionInput) => void;
  onFocus?: (measure: MeasureAccordionInput) => void;
  accordionStyle?: (measure: MeasureAccordionInput) => string;
  addNewText: string;
  autoSave: boolean;
  optionalRiskIdForNewTOM?: string;
  additionalAction?: React.ReactNode;
  whitelistedTOMIds?: string[];
  whitelistTooltip?: string;
  customMeasureGroupingFn?: (tomId: string) => string;
  customMeasureGroupsOrder?: string[];
  questionnaire?: boolean;
  disabled?: boolean;
}

export default function MeasureAccordions({
  className,
  inputMeasures,
  onSave,
  onDelete,
  onFocus,
  accordionStyle,
  addNewText,
  autoSave,
  optionalRiskIdForNewTOM,
  additionalAction,
  whitelistedTOMIds,
  whitelistTooltip,
  customMeasureGroupingFn,
  customMeasureGroupsOrder,
  questionnaire = false,
  disabled
}: MeasureAccordionsProps) {
  const [measures, setMeasures] = useState<MeasureAccordionInput[]>([...inputMeasures]);

  const addNewMeasure = useCallback(function () {
    setMeasures(measures => [
      ...measures,
      {
        tomId: "",
        measureName: "",
        measureDescription: "",
        protectionObjective: [],
        isCreatedNew: true,
        id: v4()
      }
    ]);
  }, []);

  const onSaveCallback = useCallback(
    modifiedMeasure => {
      const { isCreatedNew, ...modifiedMeasureWithoutCreatedNew } = modifiedMeasure;
      setMeasures(measures =>
        measures.map(measure => (measure.id === modifiedMeasure.id ? modifiedMeasureWithoutCreatedNew : measure))
      );

      onSave(modifiedMeasure);
    },
    [onSave]
  );

  const onDeleteCallback = useCallback(
    deletedMeasure => {
      setMeasures(measures => measures.filter(measureItem => measureItem.id !== deletedMeasure.id));
      if (!deletedMeasure.isCreatedNew || autoSave) {
        onDelete(deletedMeasure);
      }
    },
    [onDelete, autoSave]
  );

  const onCancelCallback = useCallback(cancelledMeasure => {
    if (cancelledMeasure.isCreatedNew) {
      setMeasures(measures => measures.filter(measureItem => measureItem.id !== cancelledMeasure.id));
    }
  }, []);

  const addNewButton = (
    <Button variant="contained" color="primary" onClick={addNewMeasure} disabled={disabled}>
      {addNewText}
    </Button>
  );

  return (
    <div className={className ?? ""}>
      {measures.map(questionnaireMeasure => {
        const accordion = (
          <MeasureAccordion
            inputMeasure={questionnaireMeasure}
            onSave={onSaveCallback}
            onDelete={onDeleteCallback}
            onCancel={onCancelCallback}
            onFocus={onFocus}
            accordionStyle={accordionStyle}
            autoSave={autoSave}
            optionalRiskIdForNewTOM={optionalRiskIdForNewTOM}
            whitelistedTOMIds={whitelistedTOMIds}
            customMeasureGroupingFn={customMeasureGroupingFn}
            customMeasureGroupsOrder={customMeasureGroupsOrder}
            disabled={!!disabled}
          />
        );
        return questionnaire ? (
          <Question
            key={questionnaireMeasure.id}
            questionId={"measure " + questionnaireMeasure.id}
            disabled={questionnaireMeasure.isCreatedNew || disabled}
          >
            {accordion}
          </Question>
        ) : (
          <React.Fragment key={questionnaireMeasure.id}>{accordion}</React.Fragment>
        );
      })}
      <Grid container spacing={1} alignItems="center" justifyContent="flex-start">
        <Grid item>
          {whitelistTooltip && <Tooltip title={whitelistTooltip}>{addNewButton}</Tooltip>}
          {!whitelistTooltip && addNewButton}
        </Grid>
        {additionalAction && <Grid item>{additionalAction}</Grid>}
      </Grid>
    </div>
  );
}
