import React, { ReactNode, Suspense, lazy, memo, useCallback, useState } from "react";
import { SvgIcon, Box, useTheme, IconButton } from "@mui/material";
import { makeStyles, styled } from "@material-ui/core/styles";
import { Checkbox, ListItemIcon, Typography } from "@material-ui/core";
import { RowDeletePrompt } from "./RowDeletePrompt";
import { AssigneDropdown } from "./AssigneDropdown";
import { StatusDropdown } from "./StatusDropdown";
import { Link } from "react-router-dom";
import colors from "theme/palette/colors";

function _LazySvgIcon(props: { name: string; onClick?: () => void; color?: string; disabled?: boolean }) {
  const { name, onClick, color, disabled } = props;
  const LazyIcon = lazy(() => {
    return import(`../../assets/images/icons/${name}.svg`);
  });
  return (
    <SvgIcon
      onClick={disabled ? undefined : onClick}
      sx={{
        height: 24,
        width: 24,
        cursor: disabled || !onClick ? undefined : "pointer",
        color,
        opacity: disabled ? 0.5 : 1
      }}
    >
      <Suspense fallback={<div />}>
        <LazyIcon />
      </Suspense>
    </SvgIcon>
  );
}

export const LazySvgIcon = memo(_LazySvgIcon);

export type Assignee = {
  id: string;
  name: string;
  profile?: string;
  is_deleted?: boolean;
  is_group: boolean;
};

export type ItemStatus = {
  icon: React.ReactNode;
  status: string;
  label?: string;
};

export const listItemDefaultStatuses: ItemStatus[] = [
  { icon: <LazySvgIcon name="Draft" />, status: "Draft" },
  { icon: <LazySvgIcon name="Not_Started" />, status: "Not_Started" },
  { icon: <LazySvgIcon name="In_Progress" />, status: "In_Progress" },
  { icon: <LazySvgIcon name="Quarter" />, status: "Quarter" },
  { icon: <LazySvgIcon name="Half" />, status: "Half" },
  { icon: <LazySvgIcon name="Blocked" />, status: "Blocked" },
  { icon: <LazySvgIcon name="On_Halt" />, status: "On_Halt" },
  { icon: <LazySvgIcon name="Pending" />, status: "Pending" },
  { icon: <LazySvgIcon name="In_Review" />, status: "In_Review" },
  { icon: <LazySvgIcon name="Completed" />, status: "Completed" },
  { icon: <LazySvgIcon name="Canceled" />, status: "Canceled" }
];

export type ListViewItemProps = {
  title: string;
  onClick?: () => void;
  onDelete?: () => Promise<void>;
  deletable?: boolean;
  isNew?: boolean;
  assignables?: Assignee[];
  assignee?: Assignee;
  onAssigneeChange?: (assignee?: Assignee) => void;
  selected?: boolean;
  onSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: React.ReactNode;
  status?: string;
  allowedStatuses?: ItemStatus[];
  onStatusChange?: (status: string) => Promise<void>;
  children?: React.ReactNode;
};

export const useListItemViewStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(3),
    padding: theme.spacing(2),
    height: 58
  },
  delete: {
    backgroundColor: theme.palette.error.light
  },
  default: {
    cursor: "pointer",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    "&:hover": {
      backgroundColor: theme.palette.action.hover
    },
    "& .hoverIcon": {
      opacity: 0
    },
    "&:hover .hoverIcon, & .selected": {
      opacity: 1
    }
  },
  content: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    gap: theme.spacing(2),
    justifyContent: "flex-start"
  },
  isNew: {
    background: theme.palette.primary.main,
    borderRadius: "50%",
    width: 8,
    height: 8
  },
  icon: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: theme.spacing(1)
  },
  assignee: {
    display: "flex",
    alignItems: "center"
  },
  hoverIcon: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    "& > *": {
      margin: 5
    }
  }
}));

export const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 24 + 9
});

export default function ListViewItem(props: ListViewItemProps) {
  const styles = useListItemViewStyles();
  const theme = useTheme();
  const {
    isNew,
    assignables,
    assignee,
    title,
    onClick,
    onAssigneeChange,
    onDelete,
    deletable,
    selected,
    onSelect,
    icon,
    status,
    allowedStatuses = listItemDefaultStatuses,
    onStatusChange,
    children
  } = props;
  const [rowState, setRowState] = useState<"default" | "delete">("default");
  const handleStartDelete = useCallback((event: React.MouseEvent) => {
    setRowState("delete");
    event.stopPropagation();
  }, []);
  const handleCancel = useCallback(() => {
    setRowState("default");
  }, []);
  const handleStopPropagation = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  const canDelete = onDelete && rowState === "delete";
  return (
    <Box onClick={onClick} className={styles.container + " " + styles[canDelete ? "delete" : "default"]}>
      {canDelete ? (
        <RowDeletePrompt onDelete={onDelete} onCancel={handleCancel} />
      ) : (
        <>
          <Box className={styles.content}>
            <Checkbox
              className={`hoverIcon ${selected ? "selected" : ""}`}
              checked={selected}
              onChange={onSelect ?? undefined}
              onClick={handleStopPropagation}
              color="primary"
              style={{
                color: selected ? theme.palette.primary.main : colors.grey.grey500
              }}
            />
            <StatusDropdown status={status} allowedStatuses={allowedStatuses} onStatusChange={onStatusChange} />
            {isNew && <Box className={styles.isNew} />}
            <Typography
              variant="body1"
              style={{
                fontWeight: isNew ? "bold" : "normal"
              }}
            >
              {title}
            </Typography>

            {children}
          </Box>
          <Box className={styles.icon}>
            {assignables?.length ? (
              <Box className={styles.assignee}>
                <AssigneDropdown assignables={assignables} assignee={assignee} onAssigneeChange={onAssigneeChange} />
              </Box>
            ) : null}
            <Box className={`hoverIcon ${styles.hoverIcon}`}>
              {icon}
              {onDelete ? (
                <IconButton onClick={handleStartDelete} disabled={!deletable}>
                  <LazySvgIcon name="Delete" />
                </IconButton>
              ) : null}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
