export type PAGE_ACCESS =
  | "tasks"
  | "notifications"
  | "processes"
  | "service_providers"
  | "dpa_check"
  | "user_management"
  | "requests"
  | "data_breaches"
  | "impact_assessment"
  | "help"
  | "profile"
  | "settings"
  | "security"
  | "tom"
  | "resources"
  | "deletion_concept"
  | "assets"
  | "org_management"
  | "dashboard"
  | "version_history"
  | "data_subject_requests"
  | "risks"
  | "audits"
  | "groups"
  | "roles"
  | "importer"
  | "administration"
  | "document_center"
  | "tokens"
  | "processor-pas";

export const defaultPageAccess: PAGE_ACCESS[] = [
  "tasks",
  "notifications",
  "help",
  "profile",
  "settings",
  "security",
  "dashboard"
];

export const accessiblePagesBasic: PAGE_ACCESS[] = [...defaultPageAccess, "audits", "processes", "document_center"];
export const accessiblePagesBasicReduced: PAGE_ACCESS[] = [...accessiblePagesBasic];
export const accessiblePagesBasicPlus: PAGE_ACCESS[] = [
  ...accessiblePagesBasic,
  "version_history",
  "impact_assessment"
];
export const accessiblePagesExpert: PAGE_ACCESS[] = [
  "audits",
  "processes",
  "tasks",
  "notifications",
  "processes",
  "service_providers",
  "dpa_check",
  "requests",
  "data_breaches",
  "impact_assessment",
  "help",
  "profile",
  "settings",
  "security",
  "tom",
  "resources",
  "deletion_concept",
  "assets",
  "dashboard",
  "version_history",
  "data_subject_requests",
  "risks",
  "document_center",
  "processor-pas"
];
export const accessiblePagesOrgUnitAdmin: PAGE_ACCESS[] = [
  "audits",
  "processes",
  "tasks",
  "notifications",
  "processes",
  "service_providers",
  "dpa_check",
  "user_management",
  "requests",
  "data_breaches",
  "impact_assessment",
  "help",
  "profile",
  "settings",
  "security",
  "tom",
  "resources",
  "deletion_concept",
  "assets",
  "org_management",
  "dashboard",
  "version_history",
  "data_subject_requests",
  "risks",
  "administration",
  "document_center",
  "processor-pas",
  "groups",
  "roles"
];

const paReadPermissions = ["pa_read_all", "pa_read_org", "pa_read_min_org", "pa_read_basic_org"];
export const paReadPermissionExists = (permissions: string[]): boolean => {
  return !!permissions.find(permission => paReadPermissions.includes(permission));
};

export const accessiblePagesAdmin: PAGE_ACCESS[] = [...accessiblePagesOrgUnitAdmin];
export const accessiblePagesTenantAdmin: PAGE_ACCESS[] = [...accessiblePagesAdmin];

export const accessiblePagesReadOnly: PAGE_ACCESS[] = [...accessiblePagesExpert];
