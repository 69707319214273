import { apiEndpoints } from "app/api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { ProcessGeneralPageModel } from "app/pages/questionnaires/general-page/ProcessGeneralPage";
import { ProcessPeriod } from "app/pages/questionnaires/periods-page/ProcessPeriodsPage";
import { DisableActionDTO, OverviewItemBadgeDTO } from "./resourceApi";
import { ProcessRecipient } from "app/pages/questionnaires/recipients-page/ProcessRecipientsPage";
import i18n from "app/i18n";
import { ProcessRisksPageModel } from "app/pages/questionnaires/risks-page/ProcessRisksPage";
import { ProcessMeasuresPageUpdateModel } from "app/pages/questionnaires/measures-page/ProcessMeasuresPage";
import { Content as PdfMakeContent } from "pdfmake/interfaces.js";

const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.paUrl}/api/v1/pa`,
  timeout: 0 // pa service is slow, no timeout for now
});

export interface PAStatValue {
  readonly edit: number;
  readonly review: number;
  readonly approved: number;
}

export interface PAStat {
  readonly date: string;
  readonly value: PAStatValue;
}

export interface PAStats {
  readonly stats: PAStat[];
}

export interface ProcessingActivityOverviewDTO {
  readonly id: string;
  readonly title: string;
  readonly subTitle: string;
  readonly version: number;
  readonly status: string;
  readonly labelIds: string[];
  readonly orgUnitIds: string[];
  readonly assignedUserId: string;
  readonly allOrgUnitIds: string[];
  readonly furtherOrgUnitIds: string[];
  readonly createdBy: string;
  readonly createdAt: string;
  readonly dueAt?: string | null;
  readonly badges: OverviewItemBadgeDTO[];
  readonly openTasks: string;
  readonly responsibleExpertId?: string;
  readonly disableActions: DisableActionDTO[];
  readonly isDPIA: boolean;
  readonly allExternalRecipientIds: string[];
  readonly allDataLocationIds: string[];
  readonly allRiskIds: string[];
  readonly allMeasureIds: string[];
  readonly allAssetIds: string[];
  readonly allDataTypeIds: string[];
  readonly allInternalRecipientIds: string[];
  readonly allLegalRetentionPeriodIds: string[];
  readonly allDeletionTypeIds: string[];
  readonly allPersonGroupIds: string[];
  readonly allLegalBasisIds: string[];
  readonly allDataSourceIds: string[];
  readonly allDataStorageIds: string[];
}

export interface ProcessingActivitiesOverviewDTO {
  readonly items: ProcessingActivityOverviewDTO[];
  readonly orgUnits: {
    readonly id: string;
    readonly name: string;
    readonly parentId: string | null;
  }[];
  readonly templates: {
    readonly id: string;
    readonly name: string;
    readonly category: string;
  }[];
  readonly responsibleExpertIds: string[];
  readonly assignedUserIds: string[];
}

export interface ProcessFilterOptionsQueryParam {
  readonly dpiaOnly: boolean;
  readonly dataLocationIDs: string[];
  readonly assetIDs: string[];
  readonly externalRecipientIDs: string[];
  readonly internalRecipientIDs: string[];
  readonly dataTypeIDs: string[];
  readonly personGroupIDs: string[];
  readonly tomIDs: string[];
  readonly riskIDs: string[];
  readonly legalRetentionPeriodIDs: string[];
  readonly deletionTypeIDs: string[];
}

interface Info {
  readonly ZIP: string;
  readonly city: string;
  readonly contactPerson: string;
  readonly email: string;
  readonly name: string;
  readonly phone: string;
  readonly street: string;
}

export interface ExportInfo {
  readonly controllerRepresentative: Info;
  readonly dataController: Info;
  readonly dataProtectionOfficer: Info;
  readonly jointController: Info;
}

export const getStatsApi = async (params: {
  readonly onlyDPIA: boolean;
  readonly labelId?: string;
  readonly orgUnitId?: string;
}): Promise<PAStat[]> => {
  const response = await axiosInstance.get<PAStats>("/stats", {
    params: params
  });
  return response.data.stats || [];
};

export const getPAsForOverview = async (
  params: Partial<ProcessFilterOptionsQueryParam> = {}
): Promise<Partial<ProcessingActivitiesOverviewDTO>> => {
  const response = await axiosInstance.get<Partial<ProcessingActivitiesOverviewDTO>>(`/overview`, {
    params
  });
  return response.data || {};
};

export const importPAJob = async (params: { readonly base64Excel: string }): Promise<{ readonly runId: string }> => {
  const response = await axiosInstance.post<{ readonly runId: string }>(`/imports`, {
    base64Excel: params.base64Excel
  });
  return response.data;
};

export interface PasPdfMarkup {
  readonly coverPage: PdfMakeContent;
  readonly tableOfContent: PdfMakeContent;
  readonly exportInformation: PdfMakeContent;
  readonly processes: (PaPdfMarkup | DpiaPdfMarkup)[];
}

export interface PaPdfMarkup {
  readonly title: PdfMakeContent;
  readonly controllerName: PdfMakeContent;
  readonly departmentAndAssignedUser: PdfMakeContent;
  readonly description: PdfMakeContent;
  readonly purposes: PdfMakeContent;
  readonly personGroups: PdfMakeContent;
  readonly recipients: PdfMakeContent;
  readonly periods: PdfMakeContent;
  readonly measures: PdfMakeContent;
}

export interface DpiaPdfMarkup {
  readonly title: PdfMakeContent;
  readonly description: PdfMakeContent;
  readonly dpiaReason: PdfMakeContent;
  readonly assessment: PdfMakeContent;
  readonly dpiaReasonRequired: PdfMakeContent;
  readonly purposes: PdfMakeContent;
  readonly personGroups: PdfMakeContent;
  readonly recipients: PdfMakeContent;
  readonly periods: PdfMakeContent;
  readonly proportionality: PdfMakeContent;
  readonly risks: PdfMakeContent;
  readonly dataSubjectRights: PdfMakeContent;
  readonly testAndManagement: PdfMakeContent;
  readonly measures: PdfMakeContent;
}
export const exportPAsAsPDF = async (params: {
  readonly paIDs: string[];
  readonly exportInfo?: ExportInfo;
  readonly isDPIA?: boolean;
}) => {
  return await axiosInstance.post<PasPdfMarkup>(`/overview/exports/pdf`, params, {
    headers: {
      "Accept-Language": i18n.language || "en-US"
    },
    timeout: 300000 // 5 minutes
  });
};

export const exportPAsAsExcel = async (params: { readonly paIDs: string[] }): Promise<Blob> => {
  const response = await axiosInstance.post(
    `/overview/exports/excel`,
    {
      paIDs: params.paIDs
    },
    {
      headers: {
        "Accept-Language": i18n.language || "en-US"
      },
      responseType: "blob",
      timeout: 300000 // 5 minutes
    }
  );
  return new Blob([response.data]);
};

/* PAGES */

/* PAGE-1: GENERAL */
export const getProcessPageData = async (params: { readonly processId: string; readonly page: string }) => {
  const response = await axiosInstance.get(`${params.processId}/pages/${params.page}`);
  return response.data || {};
};
export const patchProcessGeneralPageData = async (params: {
  readonly processId: string;
  readonly payload: Partial<ProcessGeneralPageModel>;
}) => {
  await axiosInstance.patch(`${params.processId}/pages/general`, params.payload);
};

/* PAGE-3: RECIPIENTS */
export const getProcessRecipentsPageData = async (params: { readonly processId: string }) => {
  const response = await axiosInstance.get(`${params.processId}/pages/recipients`);
  return response.data || {};
};
export const getRelatedExternalDataRecipientIds = async (params: {
  readonly processId: string;
  readonly dataLocationIds: string[];
}) => {
  const { processId, dataLocationIds } = params;
  const response = await axiosInstance.get(`${processId}/pages/recipients/related-external-data-recipients`, {
    params: { dataLocationIds }
  });
  return response.data || {};
};
export const patchProcessRecipient = async (params: {
  readonly processId: string;
  readonly recipientId: string;
  readonly payload: Partial<ProcessRecipient>;
}) => {
  await axiosInstance.patch(`${params.processId}/pages/recipients/${params.recipientId}`, params.payload);
};
export const postProcessRecipient = async (params: {
  readonly processId: string;
  readonly payload: Partial<ProcessRecipient>;
}) => {
  await axiosInstance.post(`${params.processId}/pages/recipients/`, params.payload);
};
export const deleteProcessRecipient = async (params: { readonly processId: string; readonly recipientId: string }) => {
  await axiosInstance.delete(`${params.processId}/pages/recipients/${params.recipientId}`);
};

/* PAGE-4: PERIODS */
export const getLongestLegalRetentionPeriod = async (params: {
  readonly processId: string;
  readonly legalRetentionPeriodIds: string[];
}) => {
  const response = await axiosInstance.get(`${params.processId}/pages/periods/longest`, {
    params: { retentionPeriodIds: params.legalRetentionPeriodIds }
  });
  return response.data || null;
};

export const getProcessPeriodsPageData = async (params: { readonly processId: string }) => {
  const response = await axiosInstance.get(`${params.processId}/pages/periods`);
  return response.data || {};
};
export const patchProcessPeriod = async (params: {
  readonly processId: string;
  readonly periodId: string;
  readonly payload: Partial<ProcessPeriod>;
}) => {
  await axiosInstance.patch(`${params.processId}/pages/periods/${params.periodId}`, params.payload);
};
export const postProcessPeriod = async (params: {
  readonly processId: string;
  readonly payload: Partial<ProcessPeriod>;
}) => {
  await axiosInstance.post(`${params.processId}/pages/periods/`, params.payload);
};
export const deleteProcessPeriod = async (params: { readonly processId: string; readonly periodId: string }) => {
  await axiosInstance.delete(`${params.processId}/pages/periods/${params.periodId}`);
};

/* PAGE-5: MEASUERES */
export const patchProcessMeasuresPageData = async (params: {
  readonly processId: string;
  readonly payload: Partial<ProcessMeasuresPageUpdateModel>;
}) => {
  await axiosInstance.patch(`${params.processId}/pages/measures/`, params.payload);
};

/* PAGE-8: RISKS */
export const patchProcessRisksPageData = async (params: {
  readonly processId: string;
  readonly payload: ProcessRisksPageModel;
}) => {
  await axiosInstance.patch(`${params.processId}/pages/risks`, params.payload);
};
