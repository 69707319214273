import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box, IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { Check, Clear } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: "8px",
    textAlign: "left",
    background: theme.palette.error.light,
    boxShadow: "0px 3px 6px 0px #00000026",
    width: "auto"
  }
}));

const TaskDeleteConfirmation = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation("task_details");
  const cls = useStyles();
  return (
    <Box className={cls.root} pt={2} px={2}>
      <Box>
        <Typography>{t("delete_confirmation")}</Typography>
      </Box>
      <Box display="flex" justifyContent={"flex-end"}>
        <Tooltip title={t("inline_icons_tooltips:deleteTooltip")}>
          <IconButton color="primary" onClick={onConfirm}>
            <Check />
          </IconButton>
        </Tooltip>
        <Box />
        <Tooltip title={t("inline_icons_tooltips:cancel_tooltip")}>
          <IconButton color="secondary" onClick={onCancel}>
            <Clear />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default TaskDeleteConfirmation;
