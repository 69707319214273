import OrgunitsPathsAutocomplete from "components/OrgunitsPathsAutocomplete/OrgunitsPathsAutocomplete";
import React from "react";
import { QuestionProps } from "../../Question";
import { useTranslation } from "react-i18next";

const AffectedOrgUnitQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onChange,
  onFocus
}: QuestionProps) => {
  const { t } = useTranslation();
  const _value: string = ((Array.isArray(value) ? value[0] : value) as string) || "";
  return (
    <OrgunitsPathsAutocomplete
      value={_value}
      onChange={onChange}
      label={questionName || t("filter_criteria:affectedDepartment")}
      disabled={disabled}
      error={error}
      helperText={helperText}
      onFocus={onFocus}
    />
  );
};

export default React.memo(AffectedOrgUnitQuestion);
