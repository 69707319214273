import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Question from "components/Question/Question";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { statusRequireDate, TOM_STATUSES } from "../../../handlers/tomHandler";
import { toJSDateObjectIfFirebaseDate } from "../../../handlers/utility/date-helper";
import { DateField } from "../../../../components/DateField";

export const TomStatus = ({ initialStatusData, onStatusDataChanged, onFocus }) => {
  const { t } = useTranslation();

  const [loaded, setLoaded] = useState(false);
  const [status, setStatus] = useState(null);
  const [statusDate, setStatusDate] = useState(null);
  useEffect(() => {
    if (loaded) {
      return;
    }

    setStatus(initialStatusData.status);
    setStatusDate(toJSDateObjectIfFirebaseDate(initialStatusData.statusDate));
    setLoaded(true);
  }, [initialStatusData?.status, initialStatusData?.statusDate, loaded]);

  useEffect(() => {
    if (!loaded) {
      return;
    }

    onStatusDataChanged({
      status,
      statusDate
    });
  }, [onStatusDataChanged, status, statusDate, loaded]);

  const [statuses] = useState(Object.values(TOM_STATUSES));
  const [isDateRequired, setIsDateRequired] = useState(false);
  useEffect(() => {
    setIsDateRequired(statusRequireDate(status));
  }, [status]);

  const getStatusLabel = useCallback(
    status => {
      return t(`tom_description_tab:status_${status}`, status);
    },
    [t]
  );

  if (!loaded) {
    return <></>;
  }

  return (
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item xs={isDateRequired ? 6 : 12}>
        <Box mr={isDateRequired ? 3 : 0}>
          <Question questionId={"status"} questionName={t("processes_overview:status")}>
            <MultiAutocomplete
              id="status"
              label={t("processes_overview:status")}
              hasMultiSelect={false}
              selected={status}
              updateSelected={setStatus}
              onFocus={onFocus}
              options={statuses}
              getOptionLabel={getStatusLabel}
            />
          </Question>
        </Box>
      </Grid>
      {isDateRequired && (
        <Grid item xs={6}>
          <Box ml={3}>
            <Question questionId={"dueDate"} questionName={t("processes_overview:implementationDate")}>
              <DateField
                margin="none"
                date={statusDate}
                onDateChange={setStatusDate}
                label={t("processes_overview:implementationDate")}
              />
            </Question>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

TomStatus.propTypes = {
  initialStatusData: PropTypes.shape({
    status: PropTypes.string,
    statusDate: PropTypes.instanceOf(Date)
  }).isRequired,
  onStatusDataChanged: PropTypes.func.isRequired,
  onFocus: PropTypes.func
};

TomStatus.defaultProps = {
  onStatusDataChanged: () => {}
};
