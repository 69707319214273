import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { useNavigate, useParams } from "react-router-dom";
import TaskDetails from "../details/TaskDetails";
import { OVERVIEW_ACTIONS, useOverviewDispatch, useOverviewState } from "app/contexts/overview-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import {
  createRecurringTask,
  deleteRecurringTask,
  deleteTask,
  getTaskDetailTypeByTaskId,
  isMyTask
} from "../../../handlers/tasksHandler";
import { useMetaView } from "app/contexts/meta-view-context";
import { useUserAndTenantData } from "../../../handlers/userAndTenant/user-tenant-context";
import { TaskRepetitionModal } from "../details/components/recurring/TaskRepetitionModal";
import { FEATURES } from "../../../features";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { OverviewAddButtonActionProps } from "components/Overview/controls/OverviewAddButton";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import { Box, Slide, makeStyles } from "@material-ui/core";
import TaskOverviewRow from "components/Overview/collections/task/TaskOverviewRow";
import { useSnackbar } from "notistack";
import { getNumberUnseenTasksApi } from "app/api/taskApi";
import { deleteGroupTaskApi } from "app/api/groupTaskApi";

const useStyles = makeStyles(theme => ({
  taskDetailsOverlay: {
    position: "absolute",
    right: 0,
    top: theme.spacing(3),
    bottom: theme.spacing(3),
    width: "calc(33.33% - 48px)",
    zIndex: 1300,
    borderRadius: "6px 0 0 6px",
    background: "white",
    boxShadow: "0px 0px 40px 0px rgb(204 204 204 / 50%)",
    overflow: "auto"
  }
}));

const TaskOverview = () => {
  const cls = useStyles();
  const { t } = useTranslation("tasks_page");
  const { page, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useOverviewDispatch();
  const { auth } = useAuthentication();
  const { setInfo } = useMetaView();
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();
  const { selectedId, reloadMetaview } = useOverviewState()[COLLECTIONS.TASKS];
  const isRecurringTaskFeatureEnabled = useIsFeaturePresent(FEATURES.RECURRING_TASK);
  const overviewSetup = useOverviewState()[COLLECTIONS.TASKS];
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRow, setSelectedRow] = useState<OverviewItem | null>(null);
  const [currentPage, setCurrentPage] = useState<string | null>(null);
  const [taskRepetitionTitle, setTaskRepetitionTitle] = useState<string>("");
  const [repetitionModalOpen, setRepetitionModalOpen] = useState<boolean>(false);
  const [unseenCount, setUnseenCount] = useState<number>(0);
  const [isSlidedIn, setIsSlidedIn] = useState<boolean>(false);

  // fix for old url
  // tasks/{taskId}
  if (!["my", "other"].includes(page || "")) {
    navigate(`/tasks/my`);
  }

  const setInfoCard = useCallback(
    () =>
      setInfo({
        title: t("card_title"),
        text: t("card_text")
      }),
    [setInfo, t]
  );

  useEffect(() => {
    setInfoCard();
  }, [setInfo, setInfoCard, t]);

  // set selected id from url
  useEffect(() => {
    dispatch({
      type: OVERVIEW_ACTIONS.SET_SELECTED_ID,
      collection: COLLECTIONS.TASKS,
      selectedId: id || null
    });
  }, [dispatch, id]);

  useEffect(() => {
    if (page) {
      setCurrentPage(page);
    }
  }, [page]);

  const fetchUnseen = useCallback(async () => {
    const stats = await getNumberUnseenTasksApi();
    setUnseenCount(stats);
  }, []);

  useEffect(() => {
    fetchUnseen();
  }, [fetchUnseen, overviewSetup.reloadOverview]);

  useEffect(() => {
    if (!selectedId) {
      setSelectedRow(null);
      setIsSlidedIn(false);
    }
  }, [selectedId]);

  const reload = useCallback(
    (input?: { readonly id?: string | null; readonly shadowLoading?: boolean }) => {
      const reload = {
        shadowLoading: input?.shadowLoading || false,
        reloadOverview: Date.now(),
        reloadMetaview: Date.now(),
        selectedId: input?.id !== undefined ? input?.id : undefined
      };
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.TASKS, reload });
      setInfoCard();
    },
    [dispatch, setInfoCard]
  );

  const pages = useMemo(() => {
    return [
      { title: t("myTask"), route: "my", current: currentPage === "my", unseenCount },
      { title: t("delegatedTasks"), route: "other", current: currentPage === "other" }
    ];
  }, [currentPage, t, unseenCount]);

  /* ACTION */
  const onRowClick = useCallback(
    async task => {
      if (task) {
        if (selectedId !== task.id) {
          setIsSlidedIn(!isSlidedIn);
        } else {
          setIsSlidedIn(true);
        }
        setSelectedRow(task);
        window.history.pushState("", "", `/tasks/${currentPage}/${task.id}`);
        if (task.seen === false) {
          await addToSeenItemsOfUserHook(COLLECTIONS.TASKS, task.id);
          reload({ id: task.id, shadowLoading: true });
        }
      }
    },
    [selectedId, currentPage, isSlidedIn, addToSeenItemsOfUserHook, reload]
  );

  const onGroupTaskDeleteInMeta = useCallback(
    async taskId => {
      await deleteGroupTaskApi(taskId);
      window.history.replaceState("", "", "/tasks/" + currentPage);
      reload({ id: null, shadowLoading: true });
    },
    [currentPage, reload]
  );

  const onRecurringTaskDeleteInMeta = useCallback(
    async taskId => {
      await deleteRecurringTask(taskId);
      window.history.replaceState("", "", "/tasks/" + currentPage);
      reload({ id: null, shadowLoading: true });
    },
    [currentPage, reload]
  );

  const onTaskDeleteInMeta = useCallback(
    async taskId => {
      if (currentPage === "recurring") {
        await deleteRecurringTask(taskId);
      } else {
        await deleteTask(taskId);
      }
      window.history.replaceState("", "", "/tasks/" + currentPage);
      reload({ id: null, shadowLoading: true });
    },
    [currentPage, reload]
  );

  const onCloseTaskDetails = useCallback(() => {
    navigate("/tasks/" + currentPage);
    setSelectedRow(null);
    setIsSlidedIn(false);
    dispatch({ type: OVERVIEW_ACTIONS.SET_SELECTED_ID, collection: COLLECTIONS.TASKS, selectedId: null });
  }, [currentPage, dispatch, navigate]);

  const onPageChange = useCallback(
    ({ route, id, detailType = "DEFAULT", withReload = true }) => {
      navigate("/tasks/" + route);
      setSelectedRow({ id, detailType, title: "", subTitle: "" });
      if (id) {
        setIsSlidedIn(true);
      }
      if (withReload) {
        reload({ id: id || null, shadowLoading: false });
      }
    },
    [navigate, reload]
  );

  const switchPage = useCallback(
    task => {
      if (auth?.uid) {
        const { id } = task;
        const myTask = isMyTask(auth?.uid, task);
        if (myTask) {
          setCurrentPage("my");
          navigate("/tasks/my/" + id);
        } else {
          setCurrentPage("other");
          navigate("/tasks/other/" + id);
        }
      }
    },
    [auth?.uid, navigate]
  );

  const onTaskEditInMeta = useCallback(
    (taskId, field, task, withNavigation = false) => {
      if (["description", "labels"].includes(field)) {
        return;
      }
      if (field === "status" && task.status === "DONE") {
        enqueueSnackbar(t("task_details:taskSuccessfullyCompleted"), { variant: "success" });
        window.history.replaceState("", "", "/tasks/" + currentPage);
      } else if (field === "assigneeUID" && task.assigneeUID !== auth?.uid) {
        window.history.replaceState("", "", "/tasks/" + currentPage);
      } else {
        if (withNavigation) {
          switchPage(task);
        }
      }
      reload({
        id:
          (field === "status" && task.status === "DONE") || (field === "assigneeUID" && task.assigneeUID !== auth?.uid)
            ? null
            : undefined,
        shadowLoading: true
      });
    },
    [auth?.uid, currentPage, enqueueSnackbar, reload, switchPage, t]
  );

  const onSelectToCreateRecurringTask = useCallback(({ title }) => {
    setTaskRepetitionTitle(title || "");
    setRepetitionModalOpen(true);
  }, []);

  const onChangeRepetition = useCallback(
    async data => {
      const newRecurringTask = {
        title: taskRepetitionTitle,
        ...data
      };
      const recurringTaskId = await createRecurringTask(newRecurringTask);
      window.history.replaceState("", "", `/tasks/recurring/${recurringTaskId}`);
      reload({ id: recurringTaskId, shadowLoading: true });
      setTimeout(() => {
        setIsSlidedIn(true);
      }, 600);
    },
    [reload, taskRepetitionTitle]
  );

  const switchToMy = useCallback(() => {
    if (currentPage !== "my") {
      window.history.replaceState("", "", "/tasks/my");
      setCurrentPage("my");
      const reload = {
        reloadOverview: Date.now(),
        reloadMetaview: Date.now(),
        selectedId: null
      };
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.TASKS, reload });
    }
  }, [currentPage, dispatch]);

  const onTaskCreated = useCallback(() => {
    setTimeout(() => {
      setIsSlidedIn(true);
    }, 600);
  }, []);

  /* ACTIONS */
  const toolbarActions = useMemo(() => [{ action: "sort" }, { action: "filter" }, { action: "mark-all-as-read" }], []);

  const addActions = useMemo(() => {
    const addActions: OverviewAddButtonActionProps[] = [
      { action: OVERVIEW_ADD_TYPE.SINGLE, onBefore: switchToMy, onAfter: onTaskCreated },
      {
        action: OVERVIEW_ADD_TYPE.MULTIPLE,
        title: t("createMultipleTask"),
        onBefore: switchToMy,
        onAfter: onTaskCreated
      }
    ];
    if (isRecurringTaskFeatureEnabled) {
      addActions.push({
        action: OVERVIEW_ADD_TYPE.CUSTOM_MULTIPLE,
        title: t("createRecurringTask"),
        placeholder: t("createRecurringTask"),
        onBefore: switchToMy,
        onHandle: onSelectToCreateRecurringTask
      });
    }
    return addActions;
  }, [isRecurringTaskFeatureEnabled, onSelectToCreateRecurringTask, onTaskCreated, switchToMy, t]);

  const taskDetailsOverlay = useMemo(() => {
    if (selectedRow) {
      return (
        <Slide direction="left" in={isSlidedIn} mountOnEnter unmountOnExit>
          <Box className={cls.taskDetailsOverlay}>
            <TaskDetails
              detailType={selectedRow.detailType}
              onCloseTaskDetails={onCloseTaskDetails}
              onTaskDelete={onTaskDeleteInMeta}
              onGroupTaskDelete={onGroupTaskDeleteInMeta}
              onRecurringTaskDelete={onRecurringTaskDeleteInMeta}
              onTaskEdit={onTaskEditInMeta}
              onPageChange={onPageChange}
              taskId={selectedRow.id}
              setIsSlidedIn={setIsSlidedIn}
              reload={reloadMetaview || undefined}
            />
          </Box>
        </Slide>
      );
    } else if (!selectedRow && id) {
      const fetchTask = async () => {
        const taskInfo = await getTaskDetailTypeByTaskId(id);
        if (taskInfo) {
          const { task, detailType } = taskInfo;
          if (task) {
            if (task.seen === false) {
              addToSeenItemsOfUserHook(COLLECTIONS.TASKS, id);
            }
            if (id) {
              setTimeout(() => {
                setIsSlidedIn(true);
              }, 600);
            }

            return (
              <Slide direction="left" in={isSlidedIn} mountOnEnter unmountOnExit>
                <Box className={cls.taskDetailsOverlay}>
                  <TaskDetails
                    detailType={detailType}
                    onCloseTaskDetails={onCloseTaskDetails}
                    onTaskDelete={onTaskDeleteInMeta}
                    onGroupTaskDelete={onGroupTaskDeleteInMeta}
                    onRecurringTaskDelete={onRecurringTaskDeleteInMeta}
                    onTaskEdit={onTaskEditInMeta}
                    onPageChange={onPageChange}
                    taskId={id}
                    setIsSlidedIn={setIsSlidedIn}
                    reload={reloadMetaview || undefined}
                  />
                </Box>
              </Slide>
            );
          }
        }
      };
      fetchTask();
    }
    return <></>;
  }, [
    addToSeenItemsOfUserHook,
    cls.taskDetailsOverlay,
    id,
    isSlidedIn,
    onCloseTaskDetails,
    onGroupTaskDeleteInMeta,
    onPageChange,
    onRecurringTaskDeleteInMeta,
    onTaskDeleteInMeta,
    onTaskEditInMeta,
    reloadMetaview,
    selectedRow
  ]);

  const collectionParams = useMemo(() => {
    return {
      setSelectedRow: (row: OverviewItem | null) => {
        setSelectedRow(row);
      }
    };
  }, []);
  const docViewContent = useMemo(
    () => (
      <>
        <Overview
          collectionParams={collectionParams}
          onPageChange={onPageChange}
          pages={pages}
          onRowClick={onRowClick}
          toolbarActions={toolbarActions}
          addActions={addActions}
          header={t("tasks_page:tasks")}
          collection={COLLECTIONS.TASKS}
          checkable={true}
          selectable={true}
          rowComponent={TaskOverviewRow}
          toolbarMode="tabs"
          paginated={true}
        />
        <TaskRepetitionModal
          onChangeRepetition={onChangeRepetition}
          active={repetitionModalOpen}
          setActive={setRepetitionModalOpen}
          endTime={null}
          startTime={null}
          dayOfWeek={null}
          type={null}
        />
      </>
    ),
    [
      collectionParams,
      onPageChange,
      pages,
      onRowClick,
      toolbarActions,
      addActions,
      t,
      onChangeRepetition,
      repetitionModalOpen
    ]
  );

  return (
    <>
      <DocMetaView docViewContent={docViewContent} metaViewContent={<MetaView translationKey={"tasks_page"} />} />
      {taskDetailsOverlay}
    </>
  );
};

export default React.memo(TaskOverview);
