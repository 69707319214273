import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DocView from "../../../components/DocView/DocView";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView, { META_VIEW_TABS } from "../../../components/MetaView/MetaView";
import { useMetaView } from "../../contexts/meta-view-context";
import QuestionnaireSubHeader from "../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { RiskPageButtons, RiskPageStepper } from "./RiskPagination";
import { makeStyles } from "@material-ui/core/styles";
import { useRisk } from "../../contexts/risk-context";
import { CircularProgress, FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { useErrorSnackbar } from "../../../hook/errorSnackbar";
import { RISK_TREATMENT_TYPES } from "../../handlers/risksHandler";
import { AttachmentsOverviewOBS } from "../shared/Attachments/AttachmentsOverviewOBS";
import TextEditor from "../questionnaires/utils/TextEditor";
import { MeasureIDAccordions } from "../../../components/Measures/MeasureIDAccordions";
import { debounce } from "lodash-es";
import { useRiskMeasures } from "./assessments/RiskImplementedMeasureIDs";
import Question from "components/Question/Question";
import { COLLECTIONS } from "../../collections";

const useStyles = makeStyles(theme => ({
  header: {
    marginTop: 50
  },
  container: {
    marginTop: 20
  },
  checked: {
    color: theme.palette.primary.main
  },
  radioChecked: {
    "&$checked": {
      color: theme.palette.primary.main
    }
  }
}));

const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS];

export const RiskTreatmentPage = () => {
  const { t } = useTranslation("risk_treatment_page");
  const classes = useStyles();
  const {
    risk: { title: riskTitle, riskId }
  } = useRisk();

  return (
    <DocMetaView
      metaViewContent={
        <MetaView
          translationKey={"risk_treatment_page"}
          docName={riskTitle}
          docId={riskId}
          collection={COLLECTIONS.RISK}
          tabs={metaViewTabIds}
        />
      }
    >
      <DocView header={riskTitle} pagination={<RiskPageStepper />}>
        <div className={classes.header}>
          <QuestionnaireSubHeader text={t("subTitle")} />
        </div>
        <RiskTreatment />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RiskPageButtons />
          </Grid>
        </Grid>
      </DocView>
    </DocMetaView>
  );
};

export const RiskTreatment = () => {
  const { t } = useTranslation("risk_treatment_page");
  const classes = useStyles();
  const spacing = 3;

  const { initialized: riskInitialized, risk, updateTreatmentHook, riskId } = useRisk();
  const { setInfo, setMeta } = useMetaView();
  const { catchAsSnackbar } = useErrorSnackbar();

  const [loaded, setLoaded] = useState(false);
  const [focusedElement, setFocusedElement] = useState("");
  const [treatmentType, setTreatmentType] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (!riskInitialized || loaded) {
      // do not update from remote after initial update
      return;
    }
    setTreatmentType(risk?.treatment?.type || "");
    setDescription(risk?.treatment?.description || "");

    setLoaded(true);
  }, [riskInitialized, risk?.treatment?.type, risk?.treatment?.description, risk?.treatment?.measureIds, loaded]);

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("risk_treatment_page:enteringInfoCardTitle"),
        text: t("risk_treatment_page:enteringInfoCardText")
      },
      measures: {
        title: t("risk_treatment_page:measuresTitle"),
        text: t("risk_treatment_page:measuresCard")
      },
      transfer: {
        title: t("risk_treatment_page:transferTitle"),
        text: t("risk_treatment_page:transferCard")
      },
      prevention: {
        title: t("risk_treatment_page:preventionTitle"),
        text: t("risk_treatment_page:preventionCard")
      },
      accept: {
        title: t("risk_treatment_page:acceptTitle"),
        text: t("risk_treatment_page:acceptCard")
      }
    }),
    [t]
  );

  useEffect(() => {
    return setInfo(infoCard[treatmentType || "entering"]);
  }, [setInfo, infoCard, treatmentType]);

  const debouncedUpdateTreatmentTypeHook = useMemo(() => {
    return debounce(type => {
      return updateTreatmentHook({ type: type }).catch(catchAsSnackbar("failed to update treatment type"));
    }, 600);
  }, [updateTreatmentHook, catchAsSnackbar]);

  const onTreatmentTypeChange = useCallback(
    event => {
      const newValue = event.target.value;
      setTreatmentType(newValue);
      debouncedUpdateTreatmentTypeHook(newValue);
    },
    [debouncedUpdateTreatmentTypeHook]
  );

  const [shouldShowDescription, setShouldShowDescription] = useState(false);
  useEffect(() => {
    switch (treatmentType) {
      case RISK_TREATMENT_TYPES.measures:
        setShouldShowDescription(false);
        break;
      default:
        setShouldShowDescription(true);
    }
  }, [treatmentType]);

  const [shouldShowMeasures, setShouldShowMeasures] = useState(false);
  useEffect(() => {
    switch (treatmentType) {
      case RISK_TREATMENT_TYPES.measures:
        setShouldShowMeasures(true);
        break;
      default:
        setShouldShowMeasures(false);
    }
  }, [treatmentType]);

  const onDescriptionBlur = useCallback(() => {
    updateTreatmentHook({ description }).catch(catchAsSnackbar("failed to update description"));
  }, [updateTreatmentHook, description, catchAsSnackbar]);

  const onDescriptionFocus = useCallback(() => {
    setFocusedElement("description");
  }, []);

  return (
    <Grid container spacing={spacing} className={classes.container}>
      <Grid item xs={12}>
        <RadioGroup name="treatmentType" value={treatmentType} onChange={onTreatmentTypeChange}>
          {Object.values(RISK_TREATMENT_TYPES).map(treatmentType => {
            return (
              <FormControlLabel
                key={treatmentType}
                label={t(treatmentType)}
                value={treatmentType}
                control={
                  <Radio
                    classes={{
                      root: classes.radioChecked,
                      checked: classes.checked
                    }}
                  />
                }
              />
            );
          })}
        </RadioGroup>
      </Grid>
      {shouldShowMeasures && (
        <Grid item xs={12}>
          <TreatmentMeasureIDs />
        </Grid>
      )}
      {shouldShowDescription && (
        <Grid item xs={12}>
          {loaded && ( // text edit input value is not controlled, have to prevent render before description have value
            <Question
              translatable={description}
              translationId={"description"}
              title={t("description")}
              questionId={"description"}
              questionName={t("description")}
            >
              <TextEditor
                onFocus={onDescriptionFocus}
                onBlur={onDescriptionBlur}
                onChange={setDescription}
                inputValue={description}
                toolbarHidden={focusedElement !== "description"}
              />
            </Question>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <QuestionnaireSubHeader text={t("common:attachments")} />
          </Grid>
          <Grid item xs={12}>
            <AttachmentsOverviewOBS docId={`${riskId}-treatment`} category={"risks"} setMeta={setMeta} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const TreatmentMeasureIDs = () => {
  const { initialized: riskInitialized, risk, updateTreatmentHook, riskId } = useRisk();
  const { catchAsSnackbar } = useErrorSnackbar();

  const [selectedMeasureIds, setSelectedMeasureIds] = useState([]);

  const [selectableMeasuresLoaded, setSelectableMeasuresLoaded] = useState(false);

  const {
    riskMeasureInitialized,
    treatmentMeasureGrouping,
    measureIdsWithCommonProtectionObjective,
    riskMeasuresGroupsOrder
  } = useRiskMeasures();

  const [selectableMeasureIds, setSelectableMeasureIds] = useState([]);
  useEffect(() => {
    if (!riskInitialized || !riskMeasureInitialized) {
      return;
    }

    const alreadyImplementedMeasureIds = risk?.implementedMeasureIds || [];
    setSelectableMeasureIds(
      measureIdsWithCommonProtectionObjective
        // only show the options for non implemented measure IDs
        .filter(measureId => !alreadyImplementedMeasureIds.includes(measureId))
        .filter(measureId => !selectedMeasureIds.includes(measureId))
    );
    setSelectableMeasuresLoaded(true);
  }, [
    riskInitialized,
    riskMeasureInitialized,
    measureIdsWithCommonProtectionObjective,
    risk?.implementedMeasureIds,
    selectedMeasureIds
  ]);

  const [selectedMeasuredLoaded, setSelectedMeasuredLoaded] = useState(false);
  useEffect(() => {
    if (!riskInitialized) {
      return;
    }

    const alreadyImplementedMeasureIds = risk?.implementedMeasureIds || [];
    const treatmentMeasureIds = risk?.treatment?.measureIds || [];
    setSelectedMeasureIds(treatmentMeasureIds.filter(tomId => !alreadyImplementedMeasureIds.includes(tomId)));
    setSelectedMeasuredLoaded(true);
  }, [riskInitialized, risk?.implementedMeasureIds, risk?.treatment?.measureIds]);

  const updateImplementedMeasureIds = useCallback(
    measureIds => {
      return updateTreatmentHook({ measureIds }).catch(catchAsSnackbar("failed to update measures"));
    },
    [updateTreatmentHook, catchAsSnackbar]
  );

  return (
    <>
      {(!selectableMeasuresLoaded || !selectedMeasuredLoaded) && <CircularProgress />}
      {selectableMeasuresLoaded && selectedMeasuredLoaded && (
        <MeasureIDAccordions
          measureIDs={selectedMeasureIds}
          onMeasureIDChange={updateImplementedMeasureIds}
          whitelistedTOMIds={selectableMeasureIds}
          optionalRiskIdForNewTOM={riskId}
          customMeasureGroupingFn={treatmentMeasureGrouping}
          customMeasureGroupsOrder={riskMeasuresGroupsOrder}
        />
      )}
    </>
  );
};
