import { Box, Button } from "@material-ui/core";
import {
  InfoCardProps,
  deleteInfoCardByIdApi,
  getInfoCardByIdApi,
  patchInfoCardByIdApi,
  postInfoCardByIdApi
} from "app/api/info-card/infoCardApi";
import i18n from "app/i18n";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import Question from "components/Question/Question";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReplayIcon from "@material-ui/icons/Replay";
import { useSnackbar } from "notistack";
import { getTextFromTextEditorJsonString } from "app/pages/questionnaires/utils/textEditorConverter";

interface EditInfoCardModalProps {
  readonly open: boolean;
  readonly infoCardId: string;
  readonly onCancel: () => void;
  readonly onSave?: () => void;
  readonly onRestore?: () => void;
}

const EditInfoCardModal = ({ open, infoCardId, onCancel, onSave, onRestore }: EditInfoCardModalProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  /* DATA */
  /**
   * {"en": InfoCardProps, "de": nfoCardProps ...}
   */
  const [modalData, setModalData] = useState<Record<string, InfoCardProps>>({});
  const [initialModalData, setInitialModalData] = useState<Record<string, InfoCardProps>>({});
  const [language, setLanguage] = useState<string>(i18n.language || "en");

  useEffect(() => {
    const fetch = async function (id: string) {
      const infoCardByLanguage: InfoCardProps | null = await getInfoCardByIdApi({ id, language });
      if (infoCardByLanguage) {
        setModalData(current => ({ ...current, [language]: infoCardByLanguage }));
        setInitialModalData(current => ({ ...current, [language]: infoCardByLanguage }));
      }
    };
    if (infoCardId && open) {
      fetch(infoCardId);
    }
  }, [infoCardId, language, open]);

  const setTitleCallback = useCallback(
    (title: string) => {
      setModalData(current => ({ ...current, [language]: { ...modalData[language], title } }));
    },
    [modalData, language]
  );
  const setDescriptionCallback = useCallback(
    (description: string) => {
      setModalData(current => ({ ...current, [language]: { ...modalData[language], description } }));
    },
    [modalData, language]
  );

  const onRestoreCallback = useCallback(async () => {
    await deleteInfoCardByIdApi({ id: infoCardId, language });

    const infoCardByLanguage: InfoCardProps | null = await getInfoCardByIdApi({ id: infoCardId, language });
    if (infoCardByLanguage) {
      setModalData(current => ({ ...current, [language]: infoCardByLanguage }));
      setInitialModalData(current => ({ ...current, [language]: infoCardByLanguage }));
    }
    onRestore?.();
  }, [infoCardId, language, onRestore]);

  const emptyTitle: boolean = useMemo(() => {
    return modalData[language] && modalData[language]?.title === "";
  }, [language, modalData]);

  const emptyDescription: boolean = useMemo(() => {
    return modalData[language] && getTextFromTextEditorJsonString(modalData[language]?.description) === "";
  }, [language, modalData]);

  const modalBody = (
    <Box>
      <Question qType={QUESTION_TYPE.LANGUAGE} value={language} onChange={setLanguage} pt={2} pb={2} />
      <Question
        qType={QUESTION_TYPE.TEXT_AREA}
        questionName={t("edit_info_card_modal:title")}
        value={modalData[language]?.title || ""}
        onChange={setTitleCallback}
        error={emptyTitle}
        helperText={emptyTitle ? t("fields:required") : ""}
        pt={2}
        pb={2}
      />
      <Question
        qType={QUESTION_TYPE.TEXT_EDITOR}
        questionName={t("edit_info_card_modal:description")}
        value={modalData[language]?.description || ""}
        onChange={setDescriptionCallback}
        error={emptyDescription}
        helperText={emptyDescription ? t("fields:required") : ""}
        textEditorToolbarOptions={["inline", "emoji", "list", "link"]}
        pt={2}
        pb={2}
      />
      <Box mt={1}>
        <Button
          color="primary"
          startIcon={<ReplayIcon />}
          onClick={onRestoreCallback}
          disabled={modalData[language]?.defaultValue}
          style={{ textTransform: "none" }}
          size="large"
        >
          {t("edit_info_card_modal:restoreDefault")}
        </Button>
      </Box>
    </Box>
  );

  /* BUTTON ACTIONS */
  const onCancelCallback = useCallback(() => {
    setModalData({});
    onCancel();
  }, [onCancel]);
  const onSaveCallback = useCallback(async () => {
    for (const data of Object.values(modalData)) {
      // run SAVE action ONLY
      // if user change title or description for info card by language
      if (
        initialModalData[data.language].title !== data.title ||
        initialModalData[data.language].description !== data.description
      ) {
        // POST if defaultValue
        if (data.defaultValue) {
          await postInfoCardByIdApi({
            id: infoCardId,
            language: data.language,
            title: data.title,
            description: data.description
          });
        }
        // PATCH for non defaultValue
        else {
          await patchInfoCardByIdApi({
            id: infoCardId,
            language: data.language,
            title: data.title,
            description: data.description
          });
        }
      }
    }
    setModalData({});
    onSave?.();
    enqueueSnackbar(t("edit_info_card_modal:infoCardUpdatedMessage"), { variant: "success" });
  }, [onSave, enqueueSnackbar, t, modalData, initialModalData, infoCardId]);

  const buttons: ConfirmationModalButtonProps[] = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("common:cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: onCancelCallback
      },
      {
        confirmButton: true,
        title: t("common:save"),
        variant: "contained",
        color: "primary",
        size: "medium",
        disabled: emptyDescription || emptyTitle,
        onClick: onSaveCallback
      }
    ],
    [emptyDescription, emptyTitle, onCancelCallback, onSaveCallback, t]
  );

  return (
    <ConfirmationModal
      variant={"info"}
      modalOpen={Boolean(open)}
      onClose={onCancel}
      modalTitle={t(`edit_info_card_modal:header`)}
      buttons={buttons}
      modalBody={modalBody}
      modalText={""}
    />
  );
};

export default EditInfoCardModal;
