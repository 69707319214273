import { Box, Chip, TextField, Typography, makeStyles } from "@material-ui/core";
import { isAxiosErrorWithCode } from "app/api/axios/axiosErrorHandler";
import { useDataTypeTreeManager } from "app/api/dataAssetApi";
import { DataType } from "app/api/generated/asset-service";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import Question from "components/Question/Question";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import { useSnackbar } from "notistack";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DescriptionIcon from "@material-ui/icons/Description";
import { Theme } from "@mui/material";

const useStyle = makeStyles((theme: Theme) => {
  return {
    dataTypeIcon: {
      color: theme.palette.primary.main,
      fontSize: "16px"
    },
    chip: {
      background: "#EBF1FF",
      marginRight: "8px",
      paddingLeft: "4px"
    }
  };
});

export interface DataTypeMoveModalMoveModalDataProps {
  readonly open: boolean;
  readonly dataCategoryId: string;
  readonly dataTypeIds: string[];
}

interface DataTypeOverviewMoveModalMoveModalProps {
  readonly dataTypeMoveModalData: DataTypeMoveModalMoveModalDataProps | null;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
}

const DataTypeOverviewMoveModal = ({
  dataTypeMoveModalData,
  onCancel,
  onConfirm
}: DataTypeOverviewMoveModalMoveModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { actions, data, dataById } = useDataTypeTreeManager(true);
  const { t } = useTranslation();
  const cls = useStyle();

  const [dataTypeKey, setDataTypeKey] = useState<string>("");

  const personGroup = data?.find(({ dataCategories }) =>
    dataCategories.some(({ id }) => dataTypeMoveModalData?.dataCategoryId === id)
  );

  /* CHECK NAME */
  const theSameKey = useCallback(
    (keyOne: string, keyTwo: string): boolean => {
      return (
        t(`lists_data_types_categories_person_groups:${keyOne}`, keyOne) ===
        t(`lists_data_types_categories_person_groups:${keyTwo}`, keyTwo)
      );
    },
    [t]
  );

  /* PERSON GROUP SELECTOR */
  const [selectedPersonGroupId, setSelectedPersonGroupId] = useState<string>("");
  const onChangePersonGroupId = useCallback(id => {
    setSelectedPersonGroupId(id || "");
  }, []);

  /* DATA CATEGORY SELECTOR */
  const [selectedDataCategoryId, setSelectedDataCategoryId] = useState<string>("");
  const onChangeDataCategoryId = useCallback(id => {
    setSelectedDataCategoryId(id || "");
  }, []);

  /* SAME NAME */
  /* only for single item case */
  const singleItem = useMemo(() => {
    if (dataTypeMoveModalData?.dataTypeIds.length === 1) {
      return dataById?.dataTypes[dataTypeMoveModalData?.dataTypeIds[0]] || null;
    } else {
      return null;
    }
  }, [dataById?.dataTypes, dataTypeMoveModalData?.dataTypeIds]);

  useEffect(() => {
    setDataTypeKey(singleItem?.dataTypeKey || "");
  }, [singleItem?.dataTypeKey]);

  /* SINGLE ITEM CASE */
  const sameNameDataTypeInNewParent = useMemo(() => {
    if (singleItem && selectedDataCategoryId) {
      const dataTypesInNewParent = dataById?.dataCategories[selectedDataCategoryId].dataTypes;
      return dataTypesInNewParent?.find(dataType => theSameKey(dataType.dataTypeKey, singleItem?.dataTypeKey));
    }
  }, [dataById?.dataCategories, selectedDataCategoryId, singleItem, theSameKey]);

  /* MULTIPLE ITEMS CASE */
  const sameNameDataTypesInNewParent = useMemo(() => {
    if (!singleItem && selectedDataCategoryId) {
      const dataTypesInNewParent = dataById?.dataCategories[selectedDataCategoryId].dataTypes;
      return dataTypesInNewParent
        ?.map(dataType => {
          return dataTypeMoveModalData?.dataTypeIds.find(id => {
            return theSameKey(dataType?.dataTypeKey, dataById?.dataTypes[id]?.dataTypeKey || "");
          })
            ? dataType
            : null;
        })
        .filter(notNull => notNull) as unknown as DataType[];
    } else return [];
  }, [
    dataById?.dataCategories,
    dataById?.dataTypes,
    dataTypeMoveModalData?.dataTypeIds,
    selectedDataCategoryId,
    singleItem,
    theSameKey
  ]);

  const sameNameDataTypesEl = useMemo(() => {
    if (sameNameDataTypesInNewParent?.length) {
      return (
        <>
          <Typography color="secondary">
            {t("lists_data_types_categories_person_groups:moveMultipleDataTypeAlreadyExist")}
          </Typography>
          <Box mt={2} p={0} display="flex">
            {sameNameDataTypesInNewParent.map(({ dataTypeKey }, index) => (
              <Chip
                key={index}
                icon={<DescriptionIcon fontSize="small" className={cls.dataTypeIcon} />}
                label={t(`lists_data_types_categories_person_groups:${dataTypeKey}`, dataTypeKey)}
                className={cls.chip}
              />
            ))}
          </Box>
        </>
      );
    } else {
      return <></>;
    }
  }, [cls.chip, cls.dataTypeIcon, sameNameDataTypesInNewParent, t]);

  const onChangeCallback = useCallback(e => {
    setDataTypeKey(e.target.value || "");
  }, []);
  const itemNameEl = useMemo(() => {
    if (singleItem) {
      return (
        <Box mb={3}>
          <TextField
            fullWidth
            maxRows={1000}
            minRows={1}
            multiline
            value={dataTypeKey}
            onChange={onChangeCallback}
            variant="outlined"
            label={"Data type name"}
            disabled={!sameNameDataTypeInNewParent}
          />
        </Box>
      );
    } else {
      return <></>;
    }
  }, [dataTypeKey, onChangeCallback, sameNameDataTypeInNewParent, singleItem]);

  const helperText = useMemo(() => {
    return singleItem && sameNameDataTypeInNewParent && theSameKey(sameNameDataTypeInNewParent.dataTypeKey, dataTypeKey)
      ? t("lists_data_types_categories_person_groups:moveSingleDataTypeAlreadyExist")
      : "";
  }, [dataTypeKey, sameNameDataTypeInNewParent, singleItem, t, theSameKey]);

  const error = useMemo(() => {
    if (singleItem) {
      return sameNameDataTypeInNewParent && theSameKey(sameNameDataTypeInNewParent.dataTypeKey, dataTypeKey);
    } else {
      return !!sameNameDataTypesInNewParent?.length;
    }
  }, [dataTypeKey, sameNameDataTypeInNewParent, sameNameDataTypesInNewParent?.length, singleItem, theSameKey]);

  /* BODY */
  const modalBody = (
    <Box mt={3} mb={4}>
      <Box mt={3}>
        {itemNameEl}
        <Typography>{t("common:moveTo")}</Typography>
        <Box mt={-1} />
        <Question qType={QUESTION_TYPE.PERSON_GROUP} onChange={onChangePersonGroupId} />
        <Box mt={-3} />
        <Question
          qType={QUESTION_TYPE.DATA_CATEGORY}
          personGroupId={selectedPersonGroupId}
          disabled={!selectedPersonGroupId}
          onChange={onChangeDataCategoryId}
          multiSelectHiddenIds={dataTypeMoveModalData?.dataCategoryId ? [dataTypeMoveModalData?.dataCategoryId] : []}
          error={error}
          helperText={helperText}
        />
        {sameNameDataTypesEl}
      </Box>
    </Box>
  );

  const clearCallback = useCallback(() => {
    setSelectedDataCategoryId("");
    setSelectedPersonGroupId("");
  }, []);

  const onCancelCallback = useCallback(() => {
    clearCallback();
    onCancel();
  }, [clearCallback, onCancel]);

  const onConfirmCallback = useCallback(async () => {
    if (personGroup?.id && dataTypeMoveModalData && selectedPersonGroupId && selectedDataCategoryId) {
      if (singleItem && sameNameDataTypeInNewParent && sameNameDataTypeInNewParent?.dataTypeKey !== dataTypeKey) {
        // first rename data type to be moved
        await actions.updateDataType({
          personGroupId: personGroup.id,
          dataCategoryId: dataTypeMoveModalData.dataCategoryId,
          dataTypeId: singleItem.id,
          updates: { name: dataTypeKey }
        });

        await actions.moveDataType({
          personGroupId: personGroup?.id,
          formerDataCategoryId: dataTypeMoveModalData.dataCategoryId,
          dataTypeId: singleItem.id,
          newDataCategoryId: selectedDataCategoryId
        });
      } else {
        for (const dataTypeId of dataTypeMoveModalData.dataTypeIds) {
          try {
            await actions.moveDataType({
              personGroupId: personGroup?.id,
              formerDataCategoryId: dataTypeMoveModalData.dataCategoryId,
              dataTypeId: dataTypeId,
              newDataCategoryId: selectedDataCategoryId
            });
          } catch (error) {
            onCancel();
            clearCallback();
            if (isAxiosErrorWithCode(error, 409)) {
              enqueueSnackbar(`${t("error_messages:generic_already_exists")}`, { variant: "error" });
              return;
            }
          }
        }
      }

      onConfirm();
      clearCallback();
    }

    onCancelCallback();
  }, [
    actions,
    clearCallback,
    dataTypeKey,
    dataTypeMoveModalData,
    enqueueSnackbar,
    onCancel,
    onCancelCallback,
    onConfirm,
    personGroup?.id,
    sameNameDataTypeInNewParent,
    selectedDataCategoryId,
    selectedPersonGroupId,
    singleItem,
    t
  ]);

  const onMergeSingleCallback = useCallback(async () => {
    if (
      personGroup?.id &&
      dataTypeMoveModalData &&
      selectedPersonGroupId &&
      selectedDataCategoryId &&
      sameNameDataTypeInNewParent &&
      singleItem
    ) {
      try {
        await actions.mergeDataTypes({
          personGroupId: selectedPersonGroupId,
          dataCategoryId: selectedDataCategoryId,
          dataTypes: [sameNameDataTypeInNewParent, singleItem],
          newName: sameNameDataTypeInNewParent.dataTypeKey
        });
      } catch (error) {
        onCancel();
        clearCallback();
        if (isAxiosErrorWithCode(error, 409)) {
          enqueueSnackbar(`${t("error_messages:generic_already_exists")}`, { variant: "error" });
          return;
        }
      }

      onConfirm();
      clearCallback();
    }

    onCancelCallback();
  }, [
    actions,
    clearCallback,
    dataTypeMoveModalData,
    enqueueSnackbar,
    onCancel,
    onCancelCallback,
    onConfirm,
    personGroup?.id,
    sameNameDataTypeInNewParent,
    selectedDataCategoryId,
    selectedPersonGroupId,
    singleItem,
    t
  ]);

  const onMergeMultipleCallback = useCallback(async () => {
    if (
      personGroup?.id &&
      dataTypeMoveModalData &&
      selectedPersonGroupId &&
      selectedDataCategoryId &&
      sameNameDataTypesInNewParent?.length
    ) {
      // in case of merge
      for (const dataTypeId of dataTypeMoveModalData.dataTypeIds) {
        const sameNameDataType = sameNameDataTypesInNewParent.find(({ dataTypeKey }) =>
          theSameKey(dataTypeKey, dataById?.dataTypes[dataTypeId]?.dataTypeKey || "")
        );

        try {
          if (sameNameDataType && dataById?.dataTypes?.[dataTypeId]) {
            await actions.mergeDataTypes({
              personGroupId: selectedPersonGroupId,
              dataCategoryId: selectedDataCategoryId,
              dataTypes: [sameNameDataType, dataById?.dataTypes?.[dataTypeId]],
              newName: sameNameDataType.dataTypeKey
            });
          }
          // in case of move
          else {
            await actions.moveDataType({
              personGroupId: personGroup?.id,
              formerDataCategoryId: dataTypeMoveModalData.dataCategoryId,
              dataTypeId: dataTypeId,
              newDataCategoryId: selectedDataCategoryId
            });
          }

          onConfirm();
          clearCallback();
        } catch (error) {
          onCancel();
          clearCallback();
          if (isAxiosErrorWithCode(error, 409)) {
            enqueueSnackbar(`${t("error_messages:generic_already_exists")}`, { variant: "error" });
            return;
          }
        }
      }

      onCancelCallback();
    }
  }, [
    actions,
    clearCallback,
    dataById?.dataTypes,
    dataTypeMoveModalData,
    enqueueSnackbar,
    onCancel,
    onCancelCallback,
    onConfirm,
    personGroup?.id,
    sameNameDataTypesInNewParent,
    selectedDataCategoryId,
    selectedPersonGroupId,
    t,
    theSameKey
  ]);

  const buttons: ConfirmationModalButtonProps[] = useMemo(
    () =>
      [
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onCancelCallback
        },
        sameNameDataTypeInNewParent
          ? {
              confirmButton: true,
              title: t("common:merge"),
              variant: "contained",
              color: "primary",
              size: "medium",
              onClick: onMergeSingleCallback,
              disabled: !theSameKey(sameNameDataTypeInNewParent.dataTypeKey, dataTypeKey)
            }
          : null,
        sameNameDataTypesInNewParent?.length
          ? {
              confirmButton: true,
              title: t("common:merge"),
              variant: "contained",
              color: "primary",
              size: "medium",
              onClick: onMergeMultipleCallback
            }
          : null,
        !sameNameDataTypesInNewParent?.length
          ? {
              confirmButton: true,
              title: t("common:confirm"),
              variant: "contained",
              color: "primary",
              size: "medium",
              disabled:
                !selectedPersonGroupId ||
                !selectedDataCategoryId ||
                (sameNameDataTypeInNewParent && theSameKey(sameNameDataTypeInNewParent.dataTypeKey, dataTypeKey)),
              onClick: onConfirmCallback
            }
          : null
      ].filter(notNull => notNull) as ConfirmationModalButtonProps[],
    [
      dataTypeKey,
      onCancelCallback,
      onConfirmCallback,
      onMergeMultipleCallback,
      onMergeSingleCallback,
      sameNameDataTypeInNewParent,
      sameNameDataTypesInNewParent?.length,
      selectedDataCategoryId,
      selectedPersonGroupId,
      t,
      theSameKey
    ]
  );

  return (
    <ConfirmationModal
      modalOpen={Boolean(dataTypeMoveModalData?.open)}
      onClose={onCancel}
      modalTitle={singleItem ? t(`dataType:moveModalHeader`) : t(`dataType:moveMultipleModalHeader`)}
      buttons={buttons}
      modalBody={modalBody}
      modalText={t(`dataType:moveModalBody`)}
    />
  );
};

export default DataTypeOverviewMoveModal;
