import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, Tooltip } from "@material-ui/core";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";

const useStyles = makeStyles(theme => ({
  avatar: {
    background: theme.palette.primary.main,
    boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)"
  }
}));

export const UserAvatar = ({ userId }: { readonly userId?: string }) => {
  const cls = useStyles();
  const { getUserNameHook } = useUserAndTenantData();
  const [userTitle, setUserTitle] = useState("");
  useEffect(() => {
    setUserTitle((userId && getUserNameHook(userId)) || "");
  }, [userId, getUserNameHook]);

  return (
    <Tooltip title={userTitle}>
      <Avatar aria-label="headerIcon" className={cls.avatar}>
        {userTitle?.substring(1, 0).toUpperCase()}
      </Avatar>
    </Tooltip>
  );
};

export default UserAvatar;
