import {
  accessiblePagesAdmin,
  accessiblePagesBasic,
  accessiblePagesBasicPlus,
  accessiblePagesBasicReduced,
  accessiblePagesExpert,
  accessiblePagesOrgUnitAdmin,
  accessiblePagesReadOnly,
  accessiblePagesTenantAdmin,
  defaultPageAccess,
  PAGE_ACCESS
} from "app/defaultRolePageAccess";
import { useAuthentication } from "./authentication/authentication-context";
import { useCallback, useEffect, useState } from "react";

export type ServicePermission = {
  name: string;
  permissions: string[];
  dependencies?: Record<string, string[]>;
};

export const oldRoleOptions = [
  "readOnly",
  "basicNoPADelete",
  "basicReduced",
  "basic",
  "basicPlus",
  "expert",
  "orgUnitExpert",
  "dpo",
  "admin",
  "orgUnitAdmin",
  "orgUnitDPO"
];

export const paWritePermissions = ["pa_write_org", "pa_write_basic_org", "pa_write_min_org"];
export const processorPAWritePermissions = ["processor_pa_write_org"];
export const externalRecipientWritePermissions = ["er_write_org", "er_approve_org"];
export const auditWritePermissions = ["audit_write_all", "audit_write_org"];
export const userWritePermissions = ["user_write_all", "user_write_org"];
export const dataBreachWritePermissions = ["db_write_all", "db_write_org"];
export const dsrWritePermissions = ["dsr_write_all", "dsr_write_org"];
export const tomWritePermissions = ["tom_write_all", "tom_write_org"];
export const resourcesWritePermissions = ["resource_write_all", "resource_write_org"];
export const riskWritePermissions = ["risk_write_all", "risk_write_org"];
export const publicLinkWritePermissions = ["public-link_write_all"];

export const allPermissions: ServicePermission[] = [
  {
    name: "User",
    permissions: ["user_read_all", "user_read_org", "user_write_all", "user_write_org"]
  },
  {
    name: "User group",
    permissions: ["group_read_all", "group_write_all"]
  },
  {
    name: "Org Unit",
    permissions: ["orgunit_read_all"]
  },
  {
    name: "Asset",
    permissions: ["asset_read_all", "asset_read_org", "asset_write_all", "asset_write_org"]
  },
  {
    name: "Audit",
    permissions: [
      "audit_read_all",
      "audit_read_org",
      "audit_write_all",
      "audit_write_org",
      "audit_delete_all",
      "audit_delete_org"
    ]
  },
  {
    name: "Data breaches",
    permissions: ["db_read_all", "db_read_org", "db_write_all", "db_write_org"]
  },
  {
    name: "Risk",
    permissions: ["risk_read_all", "risk_read_org", "risk_write_all", "risk_write_org"]
  },
  {
    name: "External Recipients",
    permissions: ["er_read_org", "er_write_org", "er_approve_org"],
    dependencies: {
      er_write_org: ["er_read_org"],
      er_approve_org: ["er_read_org", "er_write_org"]
    }
  },
  {
    name: "Measures",
    permissions: ["tom_read_all", "tom_write_all"]
  },
  {
    name: "Processing activities",
    permissions: [
      "pa_read_org",
      "pa_write_org",
      "pa_read_basic_org",
      "pa_write_basic_org",
      "pa_read_min_org",
      "pa_write_min_org",
      "pa_delete_org",
      "pa_approve_org",
      "pa_read_risk_org",
      "pa_write_risk_org",
      "pa_read_legalbasis_org",
      "pa_write_legalbasis_org"
    ],
    dependencies: {
      pa_write_org: ["pa_read_org"],
      pa_write_basic_org: ["pa_read_basic_org"],
      pa_write_min_org: ["pa_read_min_org"],
      pa_delete_org: [],
      pa_approve_org: ["pa_read_org", "pa_write_org"],
      pa_write_legalbasis_org: ["pa_read_legalbasis_org"],
      pa_write_risk_org: ["pa_read_risk_org"]
    }
  },
  {
    name: "Requests",
    permissions: ["dsr_read_all", "dsr_read_org", "dsr_write_all", "dsr_write_org"]
  },
  {
    name: "Resources",
    permissions: ["resource_read_all", "resource_write_all"]
  },
  {
    name: "Deletion concept",
    permissions: ["dc_read_all"]
  },
  {
    name: "File storage",
    permissions: ["filestorage_write_all"]
  },
  {
    name: "Info Card",
    permissions: ["infovault_write_all"]
  },
  {
    name: "Document center",
    permissions: [
      "filestoragefolders_write_all",
      "filestoragefolders_read_org",
      "filestoragefolders_write_org",
      "filestoragefolders_delete_org"
    ]
  }
];

export const getUserPermissionsFromRole = (userRole: string, permissions: string[]) => {
  if (oldRoleOptions.includes(userRole)) {
    return pageAccessFromOldRole(userRole);
  } else {
    return pageAccessFromPermissions(permissions);
  }
};

export const pageAccessFromPermissions = async (permissions: string[]) => {
  const pageAccess: PAGE_ACCESS[] = pagesAccessFromPermission(permissions || []);
  return {
    pageAccess: pageAccess
  };
};

export const pagesAccessFromPermission = (permissions: string[]) => {
  const pageAccess = [...defaultPageAccess];
  for (let i = 0; i < permissions.length; i++) {
    const permission = permissions[i].split("_read");
    switch (permission[0]) {
      case "db":
        pageAccess.push("data_breaches");
        break;
      case "pa":
        pageAccess.push("processes");
        break;
      case "er":
        pageAccess.push("service_providers");
        break;
      case "user":
        pageAccess.push("user_management");
        pageAccess.push("administration");
        break;
      case "dsr":
        pageAccess.push("data_subject_requests");
        break;
      case "resource":
        pageAccess.push("resources");
        break;
      case "dc":
        pageAccess.push("deletion_concept");
        break;
      case "audit":
        pageAccess.push("audits");
        break;
      case "asset":
        pageAccess.push("assets");
        break;
      case "risk":
        pageAccess.push("risks");
        break;
      case "orgunit":
        pageAccess.push("org_management");
        pageAccess.push("administration");
        break;
      case "task":
        pageAccess.push("tasks");
        break;
      case "group":
        pageAccess.push("groups");
        break;
      case "role":
        pageAccess.push("roles");
        break;
      case "tom":
        pageAccess.push("tom");
        break;
      case "filestoragefolders":
        pageAccess.push("document_center");
        break;
      default:
    }
  }
  if (permissions.includes("filestoragefolders_write_all")) pageAccess.push("document_center");
  if (permissions.includes("pa_read_all") || permissions.includes("pa_read_org")) {
    pageAccess.push("impact_assessment");
  }
  return pageAccess;
};

export type USER_ROLES_TYPES =
  | "readOnly"
  | "basic"
  | "basicPlus"
  | "basicReduced"
  | "expert"
  | "orgUnitDPO"
  | "orgUnitExpert"
  | "dpo"
  | "orgUnitAdmin"
  | "admin"
  | "tenantAdmin";

export const USER_ROLES = {
  READ_ONLY: "readOnly",
  BASIC: "basic",
  BASIC_PLUS: "basicPlus",
  BASIC_REDUCED: "basicReduced",
  EXPERT: "expert",
  ORG_UNIT_DPO: "orgUnitDPO",
  ORG_UNIT_EXPERT: "orgUnitExpert",
  DPO: "dpo",
  ORG_UNIT_ADMIN: "orgUnitAdmin",
  ADMIN: "admin",
  TENANT_ADMIN: "tenantAdmin"
} as const satisfies Record<string, USER_ROLES_TYPES>;

export const pageAccessFromOldRole = (userRole: string): { pageAccess: PAGE_ACCESS[] } => {
  switch (userRole) {
    case "readOnly":
      return {
        pageAccess: accessiblePagesReadOnly
      };
    case "basicNoPADelete":
    case "basic":
      return {
        pageAccess: accessiblePagesBasic
      };
    case "basicPlus":
      return {
        pageAccess: accessiblePagesBasicPlus
      };
    case "basicReduced":
      return {
        pageAccess: accessiblePagesBasicReduced
      };
    case "expert":
    case "orgUnitDPO":
    case "orgUnitExpert":
    case "dpo":
      return {
        pageAccess: accessiblePagesExpert
      };
    case "orgUnitAdmin":
      return {
        pageAccess: accessiblePagesOrgUnitAdmin
      };
    case "admin":
      return {
        pageAccess: accessiblePagesAdmin
      };
    case "tenantAdmin":
      return {
        pageAccess: accessiblePagesTenantAdmin
      };
    default:
      throw new Error(`${userRole} is not a known role`);
  }
};
export const isUserRoleAdminOrExpertOrRoot = (userData: { role: string }): boolean => {
  const role = userData.role;
  return ["orgUnitExpert", "expert", "dpo", "admin", "tenantAdmin", "orgUnitAdmin", "orgUnitDPO"].includes(role);
};

export const isUserDepartmentBound = (userData: { role: string }): boolean => {
  const role = userData.role;
  return (
    !isUserRoleAdminOrExpertOrRoot(userData) ||
    role === "orgUnitExpert" ||
    role === "orgUnitAdmin" ||
    role === "orgUnitDPO"
  );
};

export default isUserRoleAdminOrExpertOrRoot;

export const usePageAccess = () => {
  const { auth } = useAuthentication();

  const [pageAccess, setPageAccess] = useState<PAGE_ACCESS[]>([]);

  const setAccessOfPage = useCallback(async () => {
    if (!auth?.role) {
      return;
    }

    const result = await getUserPermissionsFromRole(auth.role, auth.permissions);
    setPageAccess([...result.pageAccess]);
  }, [auth?.permissions, auth?.role]);

  useEffect(() => {
    if (!auth?.role) {
      return;
    }
    setAccessOfPage();
  }, [auth?.role, setAccessOfPage]);

  const isMissingPageAccess = useCallback(
    (requestedPageAccesses: PAGE_ACCESS[]): PAGE_ACCESS[] => {
      if (auth?.permissions.includes("super_admin")) {
        return [];
      }
      return requestedPageAccesses.filter(requestedPageAccess => !pageAccess.includes(requestedPageAccess));
    },
    [auth?.permissions, pageAccess]
  );

  const hasPageAccess = useCallback(
    (requestedPageAccesses: PAGE_ACCESS[]): boolean => {
      const missingPageAccess = isMissingPageAccess(requestedPageAccesses);
      return missingPageAccess.length === 0;
    },
    [isMissingPageAccess]
  );

  return { hasPageAccess, isMissingPageAccess, pageAccess };
};
