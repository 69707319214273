import { useAuthentication } from "app/handlers/authentication/authentication-context";
import React from "react";
import useSWR from "swr";
import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { DataAsset, DataAssetApi, DataAssetType, DataCategory, DataType, PersonGroup } from "./generated/asset-service";
import i18n from "app/i18n";
import { useTranslation } from "react-i18next";
import { createAndDownloadFile } from "../export/createAndDownloadFile";

export const dataAssetClient = new DataAssetApi(undefined, apiEndpoints.assetUrl, defaultOTCAuthenticatedAxios());

export const flattenPersonGroupsData = (
  data: PersonGroup[] = []
): {
  readonly personGroups: Record<string, PersonGroup>;
  readonly dataCategories: Record<string, DataCategory>;
  readonly dataTypes: Record<string, DataType>;
  readonly unseenCount: number;
} => {
  const personGroups: Record<string, PersonGroup> = {};
  const dataCategories: Record<string, DataCategory> = {};
  const dataTypes: Record<string, DataType> = {};

  let unseenCount = 0;

  // loop only once, since this can cause CPU issues on FE
  for (const personGroup of data) {
    personGroups[personGroup.id as string] = personGroup;
    for (const dataCategory of personGroup.dataCategories) {
      dataCategories[dataCategory.id as string] = dataCategory;
      for (const dataType of dataCategory.dataTypes) {
        dataTypes[dataType.id as string] = dataType;
        if (dataType.unseen) {
          unseenCount++;
        }
      }
    }
  }

  return {
    personGroups,
    dataCategories,
    dataTypes,
    unseenCount
  };
};

export type DataTypeTreeData = PersonGroup[];

const toPersonGroupData = (
  personGroups: PersonGroup[],
  indexed = true
): {
  readonly personGroups: PersonGroup[];
  readonly dataTypeByIDs: {
    readonly personGroups: Record<string, PersonGroup>;
    readonly dataCategories: Record<string, DataCategory>;
    readonly dataTypes: Record<string, DataType>;
  };
  readonly unseenCount: number;
} => {
  if (!indexed) {
    return {
      personGroups,
      dataTypeByIDs: {
        personGroups: {},
        dataCategories: {},
        dataTypes: {}
      },
      unseenCount: 0
    };
  }

  const flattenedData = flattenPersonGroupsData(personGroups);
  return {
    personGroups,
    dataTypeByIDs: {
      personGroups: flattenedData.personGroups,
      dataCategories: flattenedData.dataCategories,
      dataTypes: flattenedData.dataTypes
    },
    unseenCount: flattenedData.unseenCount
  };
};

export const useDataTypeTree = (indexed = true) => {
  const personGroups = useSWR<ReturnType<typeof toPersonGroupData>>(["person-groups", indexed], async () => {
    const response = await dataAssetClient.getPersonGroups(undefined, {
      timeout: 60000
    });
    const personGroups = response.data.items || [];
    return toPersonGroupData(personGroups, indexed);
  });
  return {
    ...personGroups,
    data: personGroups.data?.personGroups satisfies DataTypeTreeData | undefined,
    dataById: personGroups.data?.dataTypeByIDs,
    unseenCount: personGroups.data?.unseenCount || 0
  };
};

export const useDataTypeTreeManager = (indexed = false) => {
  const { t } = useTranslation("personGroup");
  const { auth } = useAuthentication();
  const dataTypeTreeData = useDataTypeTree(indexed);
  const isAllowedMergeByRename = React.useMemo(() => {
    return auth?.permissions?.includes("super_admin") || false;
  }, [auth]);

  const { mutate, data = [], unseenCount } = dataTypeTreeData;

  // ADD
  const addPersonGroup = React.useCallback(
    async (
      name: string,
      {
        populateLocalCache
      }: {
        populateLocalCache?: boolean;
      } = {}
    ) => {
      let createdId = "";
      await mutate(
        async data => {
          const createResponse = await dataAssetClient.createDataAsset({
            name,
            assetType: DataAssetType.PersonGroup
          });
          createdId = createResponse.headers["x-resource-id"];

          if (!populateLocalCache) {
            return data;
          }

          const optimisticPersonGroup: PersonGroup = {
            id: createdId,
            personGroupKey: name,
            dataCategories: []
          };

          const currentData = data as ReturnType<typeof toPersonGroupData> | undefined;
          return toPersonGroupData([optimisticPersonGroup, ...(currentData?.personGroups || [])]);
        },
        {
          populateCache: Boolean(populateLocalCache)
        }
      );
      return createdId;
    },
    [mutate]
  );
  const addDataCategory = React.useCallback(
    async ({
      dataCategory,
      personGroupId,
      populateLocalCache
    }: {
      personGroupId: string;
      dataCategory: string;
      populateLocalCache?: boolean;
    }) => {
      let createdId = "";
      await mutate(
        async data => {
          const createResponse = await dataAssetClient.createDataAsset({
            assetType: DataAssetType.DataCategory,
            name: dataCategory,
            parentDataAssetId: personGroupId
          });
          createdId = createResponse.headers["x-resource-id"];

          if (!populateLocalCache) {
            return data;
          }

          const optimisticDataCategory: DataCategory = {
            id: createdId,
            dataCategoryKey: dataCategory,
            dataTypes: []
          };

          const currentData = data as ReturnType<typeof toPersonGroupData> | undefined;
          return toPersonGroupData(
            (currentData?.personGroups || []).map(personGroup =>
              personGroup.id === personGroupId
                ? { ...personGroup, dataCategories: [optimisticDataCategory, ...personGroup.dataCategories] }
                : personGroup
            )
          );
        },
        {
          populateCache: Boolean(populateLocalCache)
        }
      );
      return createdId;
    },
    [mutate]
  );
  const addDataType = React.useCallback(
    async ({
      personGroupId,
      dataCategoryId,
      dataType,
      unseen,
      populateLocalCache
    }: {
      personGroupId: string;
      dataCategoryId: string;
      dataType: string;
      unseen?: boolean;
      populateLocalCache?: boolean;
    }) => {
      let createdId = "";
      await mutate(
        async data => {
          const createResponse = await dataAssetClient.createDataAsset({
            assetType: DataAssetType.DataType,
            name: dataType,
            parentDataAssetId: dataCategoryId,
            unseen
          });
          createdId = createResponse.headers["x-resource-id"];

          if (!populateLocalCache) {
            return data;
          }

          const optimisticDataType: DataType = {
            id: createdId,
            dataTypeKey: dataType,
            unseen
          };
          const currentData = data as ReturnType<typeof toPersonGroupData> | undefined;

          return toPersonGroupData(
            (currentData?.personGroups || []).map(personGroup =>
              personGroup.id === personGroupId
                ? {
                    ...personGroup,
                    dataCategories: personGroup.dataCategories.map(dataCategory =>
                      dataCategory.id === dataCategoryId
                        ? { ...dataCategory, dataTypes: [optimisticDataType, ...dataCategory.dataTypes] }
                        : dataCategory
                    )
                  }
                : personGroup
            )
          );
        },
        {
          populateCache: Boolean(populateLocalCache)
        }
      );
      return createdId;
    },
    [mutate]
  );

  // DELETE
  const deletePersonGroup = React.useCallback(
    async ({ personGroupId }: { personGroupId: string }) => {
      mutate(
        dataAssetClient.deleteDataAsset(personGroupId).then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate]
  );
  const deleteDataCategory = React.useCallback(
    async ({ personGroupId, dataCategoryId }: { personGroupId: string; dataCategoryId: string }) => {
      mutate(
        dataAssetClient.deleteDataAsset(dataCategoryId).then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate]
  );
  const deleteDataType = React.useCallback(
    async ({
      personGroupId,
      dataCategoryId,
      dataTypeId
    }: {
      personGroupId: string;
      dataCategoryId: string;
      dataTypeId: string;
    }) => {
      mutate(
        dataAssetClient.deleteDataAsset(dataTypeId).then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate]
  );

  // MOVE
  const moveDataType = React.useCallback(
    async ({
      personGroupId,
      formerDataCategoryId,
      dataTypeId,
      newDataCategoryId
    }: {
      personGroupId: string;
      formerDataCategoryId: string;
      dataTypeId: string;
      newDataCategoryId: string;
    }) => {
      await mutate(
        dataAssetClient
          .updateDataAsset(dataTypeId, { parentDataAssetId: newDataCategoryId }, "true")
          .then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate]
  );
  const moveDataCategory = React.useCallback(
    async ({
      formerPersonGroupId,
      dataCategoryId,
      newPersonGroupId
    }: {
      formerPersonGroupId: string;
      dataCategoryId: string;
      newPersonGroupId: string;
    }) => {
      return await mutate(
        dataAssetClient
          .updateDataAsset(dataCategoryId, { parentDataAssetId: newPersonGroupId }, "true")
          .then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate]
  );

  // UPDATE
  const updatePersonGroup = React.useCallback(
    async ({ personGroupId, updates }: { personGroupId: string; updates: Pick<DataAsset, "name"> }) => {
      // if name is updated to the same name in this tenant, merge this person group into the other one
      // move all data categories to the other person group and remove this person group
      const existingPersonGroup = data.find(pg => pg.personGroupKey === updates.name);
      const currentPersonGroupIdx = data.findIndex(pg => pg.id === personGroupId);
      const currentPersonGroup = data[currentPersonGroupIdx];
      if (existingPersonGroup && currentPersonGroup && existingPersonGroup.id !== currentPersonGroup.id) {
        // only super admin can merge person groups this way
        if (!isAllowedMergeByRename) return;
        for (const dataCategory of currentPersonGroup.dataCategories) {
          await dataAssetClient.updateDataAsset(
            dataCategory.id as string,
            {
              parentDataAssetId: existingPersonGroup.id
            },
            "true"
          );
        }
        await dataAssetClient.deleteDataAsset(personGroupId);
        return mutate();
      }

      return mutate(
        dataAssetClient.updateDataAsset(personGroupId, updates).then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate, data, isAllowedMergeByRename]
  );
  const updateDataCategory = React.useCallback(
    async ({
      personGroupId,
      dataCategoryId,
      updates
    }: {
      personGroupId: string;
      dataCategoryId: string;
      updates: Pick<DataAsset, "name">;
    }) => {
      // if name is updated to the same name in the same group, merge this data category into the other one
      // move all data types to the other data category and remove this data category
      const pg = data.find(pg => pg.id === personGroupId);
      const existingCategory = pg?.dataCategories.find(dc => dc.dataCategoryKey === updates.name);
      const currentCategory = pg?.dataCategories.find(dc => dc.id === dataCategoryId);
      if (existingCategory && currentCategory && existingCategory.id !== currentCategory.id) {
        // only super admin can merge person groups this way
        if (!isAllowedMergeByRename) return;
        for (const dataType of currentCategory.dataTypes) {
          await dataAssetClient.updateDataAsset(dataType.id, { parentDataAssetId: existingCategory.id }, "true");
        }
        await dataAssetClient.deleteDataAsset(dataCategoryId);
        return await mutate();
      }

      const shouldIncludeParentDataAssetId = pg?.id !== personGroupId;
      await mutate(
        dataAssetClient
          .updateDataAsset(
            dataCategoryId,
            {
              ...updates,
              ...(shouldIncludeParentDataAssetId ? { parentDataAssetId: personGroupId } : {})
            },
            "true"
          )
          .then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate, data, isAllowedMergeByRename]
  );
  const updateDataType = React.useCallback(
    async ({
      personGroupId,
      dataCategoryId,
      dataTypeId,
      updates
    }: {
      personGroupId: string;
      dataCategoryId: string;
      dataTypeId: string;
      updates: Partial<DataAsset>;
    }) => {
      // if name is updated to the same name in the same group, merge this data type into the other one
      // invoke mergeDataTypes
      const pg = data.find(pg => pg.id === personGroupId);
      const dataCategory = pg?.dataCategories.find(dc => dc.id === dataCategoryId);
      const existingDataType = dataCategory?.dataTypes.find(dt => dt.dataTypeKey === updates.name);
      const currentDataType = dataCategory?.dataTypes.find(dt => dt.id === dataTypeId);
      if (existingDataType && currentDataType && existingDataType.id !== currentDataType.id) {
        // only super admin can merge person groups this way
        if (!isAllowedMergeByRename) return;
        await dataAssetClient.updateDataAsset(dataTypeId, {
          parentDataAssetId: dataCategoryId,
          mergedIntoId: existingDataType.id
        });
        return await mutate();
      }

      const shouldIncludeParentDataAssetId = dataCategoryId !== updates.parentDataAssetId;

      await mutate(
        dataAssetClient
          .updateDataAsset(
            dataTypeId,
            {
              ...updates,
              ...(shouldIncludeParentDataAssetId ? { parentDataAssetId: dataCategoryId } : {})
            },
            "true"
          )
          .then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate, data, isAllowedMergeByRename]
  );

  // MERGE
  const mergeDataTypes = React.useCallback(
    async ({
      personGroupId,
      dataCategoryId,
      dataTypes,
      newName
    }: {
      personGroupId: string;
      dataCategoryId: string;
      dataTypes: DataType[];
      newName: string;
    }) => {
      const pg = data.find(pg => pg.id === personGroupId);
      const dc = pg?.dataCategories.find(dc => dc.id === dataCategoryId);
      const existingDataTypeWithNewName = dc?.dataTypes.find(dt => dt.dataTypeKey === newName);
      const dataTypeToBeMergedInto = existingDataTypeWithNewName || dataTypes[0];
      const theRestItems = dataTypes.filter(dt => dt.id !== dataTypeToBeMergedInto.id);
      const mergeDataTypes = async () => {
        // only rename if there is no existing data type with the new name
        if (!existingDataTypeWithNewName) {
          await dataAssetClient.updateDataAsset(dataTypeToBeMergedInto.id, {
            name: newName,
            parentDataAssetId: dataCategoryId
          });
        }

        for (const item of theRestItems) {
          await dataAssetClient.updateDataAsset(
            item.id,
            {
              parentDataAssetId: dataCategoryId,
              mergedIntoId: dataTypeToBeMergedInto.id
            },
            "true"
          );
        }
      };
      await mutate(
        mergeDataTypes().then(() => toPersonGroupData([], false)),
        {
          populateCache: false
        }
      );
    },
    [mutate, data]
  );
  const mergeDataCategories = React.useCallback(
    async ({
      personGroupId,
      dataCategories,
      newName
    }: {
      personGroupId: string;
      dataCategories: DataCategory[];
      newName: string;
    }) => {
      const pg = data.find(pg => pg.id === personGroupId);
      const existingDataCategoryWithNewName = pg?.dataCategories.find(dc => dc.dataCategoryKey === newName);
      const dataCategoriesToBeMergedInto = existingDataCategoryWithNewName || dataCategories[0];
      const theRestItems = dataCategories.filter(dt => dt.id !== dataCategoriesToBeMergedInto.id);
      const tDataType = (key: string) => t(`lists_data_types_categories_person_groups:${key}`, key);

      const mergeDataCategories = async () => {
        // only rename if there is no existing data category with the new name
        if (!existingDataCategoryWithNewName && dataCategoriesToBeMergedInto.id) {
          await dataAssetClient.updateDataAsset(dataCategoriesToBeMergedInto.id, {
            name: newName,
            parentDataAssetId: personGroupId
          });
        }

        const dataTypesByNameInNewParent = dataCategoriesToBeMergedInto.dataTypes.reduce(
          (acc, dt) => {
            acc[tDataType(dt.dataTypeKey)] = dt;
            return acc;
          },
          {} as Record<string, DataType>
        );

        for (const item of theRestItems) {
          // move all child of data types to new data category
          for (const dataType of item.dataTypes) {
            const sameNameDataType = dataTypesByNameInNewParent[tDataType(dataType.dataTypeKey)];
            await dataAssetClient.updateDataAsset(
              dataType.id,
              {
                parentDataAssetId: dataCategoriesToBeMergedInto.id,
                mergedIntoId: sameNameDataType ? sameNameDataType.id : undefined
              },
              "true"
            );
          }

          // after that, delete childless data category
          if (item.id) {
            await dataAssetClient.deleteDataAsset(item.id);
          }
        }
      };
      await mutate(
        mergeDataCategories().then(() => toPersonGroupData([])),
        {
          populateCache: false
        }
      );
    },
    [data, mutate, t]
  );
  const mergePersonGroups = React.useCallback(
    async ({ personGroups, newName }: { personGroups: PersonGroup[]; newName: string }) => {
      const existingPersonGroupWithNewName = data.find(pg => pg.personGroupKey === newName);
      const personGroupsToBeMergedInto = existingPersonGroupWithNewName || personGroups[0];
      const theRestItems = personGroups.filter(dt => dt.id !== personGroupsToBeMergedInto.id);
      const tDataType = (key: string) => t(`lists_data_types_categories_person_groups:${key}`, key);

      const mergePersonGroups = async () => {
        // only rename if there is no existing data category with the new name
        if (!existingPersonGroupWithNewName && personGroupsToBeMergedInto.id) {
          await dataAssetClient.updateDataAsset(personGroupsToBeMergedInto.id, {
            name: newName
          });
        }
        const dataCategoriesByNameInNewParent = personGroupsToBeMergedInto.dataCategories.reduce(
          (acc, dc) => {
            acc[tDataType(dc.dataCategoryKey)] = dc;
            return acc;
          },
          {} as Record<string, DataCategory>
        );

        for (const item of theRestItems) {
          // move all child of data category to new person group
          for (const dataCategory of item.dataCategories) {
            const sameNameDataCategory = dataCategoriesByNameInNewParent[tDataType(dataCategory.dataCategoryKey)];
            if (sameNameDataCategory && item.id && dataCategory.id && personGroupsToBeMergedInto.id) {
              const dataTypesByNameInNewParent = sameNameDataCategory.dataTypes.reduce(
                (acc, dt) => {
                  acc[tDataType(dt.dataTypeKey)] = dt;
                  return acc;
                },
                {} as Record<string, DataType>
              );
              for (const dataType of dataCategory.dataTypes) {
                const sameNameDataType = dataTypesByNameInNewParent[tDataType(dataType.dataTypeKey)];

                await dataAssetClient.updateDataAsset(
                  dataType.id,
                  {
                    parentDataAssetId: sameNameDataCategory.id,
                    mergedIntoId: sameNameDataType ? sameNameDataType.id : undefined
                  },
                  "true"
                );
              }
              await dataAssetClient.deleteDataAsset(dataCategory.id as string);
            } else {
              await dataAssetClient.updateDataAsset(
                dataCategory.id as string,
                { parentDataAssetId: personGroupsToBeMergedInto.id },
                "true"
              );
            }
          }

          // after that, delete PG
          if (item.id) {
            await dataAssetClient.deleteDataAsset(item.id);
          }
        }
      };
      await mutate(
        mergePersonGroups().then(() => toPersonGroupData([])),
        {
          populateCache: false
        }
      );
    },
    [data, mutate, t]
  );

  // DUPLICATE
  const duplicate = React.useCallback(
    async (dataAssetId: string) => {
      await mutate(
        async () => {
          const createResponse = await dataAssetClient.duplicateDataAsset(dataAssetId, i18n.language || "en-US", {
            timeout: 30000
          });
          return createResponse.headers["x-resource-id"];
        },
        {
          populateCache: false
        }
      );
    },
    [mutate]
  );

  // MARK ALL AS READ
  const markAllAsRead = React.useCallback(async () => {
    await dataAssetClient.markAllDataAssetsAsRead();
    await mutate();
    // wait for 3s and revalidate
    await new Promise(resolve => setTimeout(resolve, 3000));
    await mutate();
  }, [mutate]);

  return {
    ...dataTypeTreeData,
    data: dataTypeTreeData.data,
    unseenCount,
    actions: {
      // ADD
      addPersonGroup,
      addDataCategory,
      addDataType,
      // DELETE
      deletePersonGroup,
      deleteDataCategory,
      deleteDataType,
      // MOVE
      moveDataType,
      moveDataCategory,
      // UPDATE
      updatePersonGroup,
      updateDataCategory,
      updateDataType,
      // MERGE
      mergePersonGroups,
      mergeDataCategories,
      mergeDataTypes,

      // DUPLICATE
      duplicate,
      // MARK ALL AS READ
      markAllAsRead
    }
  };
};

export const exportDataTypes = async (filename?: string): Promise<void> => {
  const response = await dataAssetClient.getPersonGroups(undefined, {
    timeout: 0
  });
  const personGroups = response.data.items || [];

  const flatDataTypes = personGroups.flatMap(personGroup =>
    personGroup.dataCategories.length
      ? personGroup.dataCategories.flatMap(dataCategory =>
          dataCategory.dataTypes.length
            ? dataCategory.dataTypes.map(dataType => ({
                id: dataType.id,
                dataTypeKey: dataType.dataTypeKey || "-",
                personGroupKey: personGroup.personGroupKey || "-",
                dataCategoryKey: dataCategory.dataCategoryKey || "-",
                mergedInto: dataType.mergedIntoId || "-",
                dataClassificationId: dataType.dataClassificationId || "-"
              }))
            : [
                {
                  id: `empty-category-${dataCategory.dataCategoryKey}`,
                  dataTypeKey: "-",
                  personGroupKey: personGroup.personGroupKey || "-",
                  dataCategoryKey: dataCategory.dataCategoryKey || "-",
                  mergedInto: "-",
                  dataClassificationId: "-"
                }
              ]
        )
      : [
          {
            id: `empty-persongroup-${personGroup.personGroupKey}`,
            dataTypeKey: "-",
            personGroupKey: personGroup.personGroupKey || "-",
            dataCategoryKey: "-",
            mergedInto: "-",
            dataClassificationId: "-"
          }
        ]
  );

  const headers = ["Id", "Person Group", "Data Category", "Data Type"];
  const data = [
    headers,
    ...flatDataTypes
      .map(it =>
        [it.id, it.personGroupKey, it.dataCategoryKey, it.dataTypeKey].map(it =>
          i18n.t(`lists_data_types_categories_person_groups:${it}`, it)
        )
      )
      .map(idPgDcDt => idPgDcDt.map(it => it.replaceAll("\n", " ").replaceAll("\t", " ")))
      .sort((a, b) => a.slice(1).join(":").localeCompare(b.slice(1).join(":")))
  ].reduce((acc, row) => acc + row.join("\t") + "\n", "");
  await createAndDownloadFile(new Blob([data], { type: "plain/text" }), filename || "data_types.tsv");
};
