import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: theme.shape.borderWidth,
      borderColor: theme.palette.text.disabled
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: theme.shape.borderWidth,
      borderColor: theme.palette.text.disabled
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: theme.shape.borderWidth,
      borderColor: theme.palette.text.disabled
    }
  },
  radioChecked: {
    "&$checked": {
      color: theme.palette.primary.main
    }
  },
  checked: {}
}));

export default function AutocompleteElement({
  inputValue,
  onInputChange,
  value,
  onChange,
  onFocus,
  onBlur,
  options,
  id,
  getOptionLabel,
  label,
  renderOption
}) {
  const classes = useStyles();

  return (
    <Autocomplete
      onFocus={onFocus}
      onBlur={onBlur}
      value={value ?? ""}
      onChange={(event, value) => {
        onChange(value);
      }}
      inputValue={inputValue}
      onInputChange={onInputChange}
      className={classes.root}
      classes={{ root: classes.root, input: classes.input }}
      id={id}
      disableClearable
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={params => (
        <TextField
          {...params}
          margin="normal"
          InputProps={{ ...params.InputProps, type: "search" }}
          variant="outlined"
          label={label}
        />
      )}
      renderOption={renderOption || getOptionLabel}
    />
  );
}
