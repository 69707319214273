import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, Grid, Tooltip, Box } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import CustomAlert from "components/CustomAlert/CustomAlert";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const useStyles = makeStyles(theme => ({
  placeholder: {
    fontStyle: "italic",
    color: theme.palette.grey[500],
    marginTop: 5
  },
  accordionDetails: {
    display: "block"
  },
  buttonsContainer: {
    padding: "20px 0px 5px 0px"
  },
  container: {
    marginBottom: 25
  }
}));

export default function AccordionMultiField({
  id,
  title,
  placeholder,
  index,
  field,
  isNewMultiFiled,
  titleType,
  deleteMultiField,
  deleteButtonText,
  deleteButtonHint,
  cancelButtonText,
  saveButtonText,
  disableButton,
  disableOnlySaveButton = false,
  onClickSave,
  onClickCancel,
  children,
  onFocus,
  getStyle: accordionClassName,
  hasCancelAndSave = true,
  hasDelete = true,
  accordionsExpanded,
  setAccordionsExpanded,
  editButtonText,
  onClickEdit,
  additionalLeftButton,
  loading,
  dirty = false
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isNewMultiFiled) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [isNewMultiFiled]);
  // used for closing all accordions with a external save button
  useEffect(() => {
    if (accordionsExpanded === false) {
      setIsExpanded(false);
      setAccordionsExpanded(true);
    }
  }, [accordionsExpanded, setAccordionsExpanded]);

  const toggleExpanded = useCallback(() => {
    setIsExpanded(isExpanded => !isExpanded);
  }, []);

  const dirtyEl = dirty && (
    <CustomAlert severity="error" icon={<ErrorOutlineIcon />}>
      {t("common:unsavedChanges")}
    </CustomAlert>
  );

  const renderTitle = (titleType, accordionTitle) => {
    // titleType used to be three types, text, double inputs, and default
    // double inputs are un-used => removed
    // for text and default, the difference is, for text, it supports stuffs like chips (even though type is text),
    // and it has the option to not expand when clicking the chip => replaced by stopping event propagation on the chip
    // nonetheless, keeping titleType here since it might be used again
    return (
      <AccordionSummary
        onClick={toggleExpanded}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box width="100%">
          {dirty && (
            <Box display="flex" mb={1}>
              <Box>{dirtyEl}</Box>
              <Box flexgrow={1} />
            </Box>
          )}

          <Box display={"flex"}>
            {placeholder && !accordionTitle ? <div className={classes.placeholder}>{placeholder}</div> : accordionTitle}
          </Box>
        </Box>
      </AccordionSummary>
    );
  };

  const saveButtonClicked = useCallback(() => {
    setIsExpanded(false);
    onClickSave?.(index);
  }, [onClickSave, index]);

  const cancelButtonClicked = useCallback(() => {
    setIsExpanded(false);
    onClickCancel?.();
  }, [onClickCancel]);

  const onClickDelete = useCallback(() => {
    deleteMultiField(index, field);
    setIsExpanded(false);
  }, [deleteMultiField, index, field]);

  const deleteButton = (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<DeleteIcon />}
      onClick={onClickDelete}
      disabled={loading || disableButton}
    >
      {deleteButtonText || t("questionnaires:delete")}
    </Button>
  );

  return (
    <div className={classes.container}>
      <Accordion expanded={isExpanded} className={accordionClassName} id={id} onFocus={onFocus} data-testid={id}>
        {renderTitle(titleType, title)}
        <AccordionDetails className={classes.accordionDetails}>
          {children}
          <Grid container className={classes.buttonsContainer} alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container spacing={1}>
                {hasDelete && (
                  <Grid item>
                    {deleteButtonHint && <Tooltip title={deleteButtonHint}>{deleteButton}</Tooltip>}
                    {!deleteButtonHint && deleteButton}
                  </Grid>
                )}
                {onClickEdit && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<EditIcon />}
                      onClick={onClickEdit}
                      disabled={loading || disableButton}
                    >
                      {editButtonText}
                    </Button>
                  </Grid>
                )}
                {additionalLeftButton && <Grid item>{additionalLeftButton}</Grid>}
              </Grid>
            </Grid>

            <Grid item>
              {hasCancelAndSave && (
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      data-testid="accordion_cancel_button"
                      variant="outlined"
                      color="primary"
                      onClick={cancelButtonClicked}
                      disabled={loading || disableButton}
                    >
                      {cancelButtonText || t("questionnaires:cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      data-testid="accordion_save_button"
                      variant="contained"
                      color="primary"
                      onClick={saveButtonClicked}
                      disabled={loading || disableButton || disableOnlySaveButton}
                    >
                      {saveButtonText || t("questionnaires:save")}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
