import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress, Grid, makeStyles, Switch } from "@material-ui/core";
import TextBodySecondary from "../../../../components/TextBodySecondary/TextBodySecondary";
import { useTranslation } from "react-i18next";
import { useErrorSnackbar } from "../../../../hook/errorSnackbar";
import AccordionMultiField from "../../../../components/AccordionMultiField/AccordionMultiField";
import { OccurrenceAssessment } from "./RiskAssessment";
import { RiskAssessmentAutoSave } from "./RiskAssessmentAutoSave";
import { RiskRatingAlert } from "./RiskRating";
import CardWithTextButton from "../../../../components/CardWithButton/CardWithTextButton";
import { useNavigate } from "react-router-dom";
import { useRisk } from "../../../contexts/risk-context";
import { RiskHeatMap } from "./RiskHeatMap";
import PropTypes from "prop-types";
import { getAssessmentWithHighestRating } from "../../../handlers/risksHandler";
import { RESOURCE_TYPES, translateResourceById } from "../../../handlers/resourceHandler";
import { useResources } from "../../../contexts/resource-context";

const useStyles = makeStyles(theme => ({
  accordion: {
    margin: "0px 0px 15px 0px",
    border: "1px solid " + theme.palette.grey[400],
    borderRadius: "5px"
  },
  riskAlert: {
    marginTop: 8
  }
}));

export const RiskPhaseAssessment = ({ phaseId, enableIndividualAssessment, children }) => {
  const { t } = useTranslation("risk_assessment");
  const navigate = useNavigate();
  const classes = useStyles();

  const { initialized, risk, toggleIndividualAssessmentHook, updateAllIndividualAssessmentsOccurrence, riskId } =
    useRisk();
  const { resourcesLoaded, resourcesWithMergedAndDisabled } = useResources();
  const { catchAsSnackbar } = useErrorSnackbar();

  const [individualAssessment, setIndividualAssessment] = useState(false);

  useEffect(() => {
    const phaseAssessment = risk.assessments?.[phaseId] || null;
    if (!phaseAssessment) {
      return;
    }

    const individualAssessment = phaseAssessment.individualAssessmentEnabled;
    setIndividualAssessment(individualAssessment);
  }, [risk, phaseId]);

  const protectionObjectiveName = useCallback(
    protectionObjectiveId => {
      return translateResourceById({
        t,
        resourceType: RESOURCE_TYPES.PROTECTION_OBJECTIVE,
        resources: resourcesWithMergedAndDisabled,
        resourceId: protectionObjectiveId
      });
    },
    [resourcesWithMergedAndDisabled, t]
  );
  const [isChangingAllOccurrenceIds, setIsChangingAllOccurrenceIds] = useState(false);
  const changeAllOccurrenceIds = useCallback(
    async occurrenceId => {
      setIsChangingAllOccurrenceIds(true);
      try {
        await updateAllIndividualAssessmentsOccurrence(phaseId, { occurrenceId });
        setIsChangingAllOccurrenceIds(false);
      } catch (error) {
        setIsChangingAllOccurrenceIds(false);
        throw error;
      }
    },
    [phaseId, updateAllIndividualAssessmentsOccurrence]
  );
  const changeAllReasonDescriptions = useCallback(
    async reasonDescription => {
      await updateAllIndividualAssessmentsOccurrence(phaseId, { reasonDescription });
    },
    [phaseId, updateAllIndividualAssessmentsOccurrence]
  );

  const [isTogglingIndividualAssessment, setIsTogglingIndividualAssessment] = useState(false);
  const changeIndividualAssessment = useCallback(
    async event => {
      const enabled = event.target.checked;

      setIsTogglingIndividualAssessment(true);

      setIndividualAssessment(enabled);

      try {
        await toggleIndividualAssessmentHook(phaseId, enabled).catch(
          catchAsSnackbar(`failed to update individual assessment setting for ${phaseId}`)
        );
        setIsTogglingIndividualAssessment(false);
      } catch (error) {
        setIsTogglingIndividualAssessment(false);
        throw error;
      }
    },
    [toggleIndividualAssessmentHook, catchAsSnackbar, phaseId]
  );

  const navigateToGeneral = useCallback(() => {
    navigate(`/risks/${riskId}/general`);
  }, [navigate, riskId]);

  const getHighestRatingAssessment = useCallback(() => {
    return getAssessmentWithHighestRating(risk, phaseId);
  }, [phaseId, risk]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {enableIndividualAssessment && (
          <>
            <TextBodySecondary text={t("individualAssessment")} />
            {initialized && (
              <Switch
                data-testid="individualAssessmentSwitch"
                checked={individualAssessment}
                color="primary"
                onChange={changeIndividualAssessment}
              />
            )}
          </>
        )}
      </Grid>
      {children && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
      {isTogglingIndividualAssessment && (
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      )}
      {initialized && individualAssessment === false && !isTogglingIndividualAssessment && (
        <Grid item xs={12}>
          <RiskAssessmentAutoSave phaseId={phaseId} protectionObjectiveId="main" />
        </Grid>
      )}
      {initialized && resourcesLoaded && individualAssessment === true && !isTogglingIndividualAssessment && (
        <>
          <Grid item xs={12}>
            <OccurrenceAssessment
              occurrenceId={
                risk.assessments[phaseId].individualAssessments
                  .map(assessment => assessment.occurrenceId)
                  .find(occurrenceId => occurrenceId) || null
              }
              onOccurrenceIdChange={changeAllOccurrenceIds}
              defaultReasonDescription={
                risk.assessments[phaseId].individualAssessments
                  .map(assessment => assessment.reasonDescription)
                  .find(reasonDescription => reasonDescription) || null
              }
              onReasonDescriptionChange={changeAllReasonDescriptions}
            />
          </Grid>
          <Grid item xs={12}>
            {risk.protectionObjectiveIds.length === 0 && (
              <CardWithTextButton
                text={t("specifyProtectionObjective")}
                buttonText={t("goToPage1")}
                colorTheme={"blue"}
                onClick={navigateToGeneral}
              />
            )}
            {risk.protectionObjectiveIds.length > 0 &&
              risk.protectionObjectiveIds.map(protectionObjectiveId => (
                <AccordionMultiField
                  id={`accordion-${protectionObjectiveId}`}
                  key={protectionObjectiveId}
                  hasCancelAndSave={false}
                  hasDelete={false}
                  titleType={"text"}
                  accordionsExpanded={true}
                  title={protectionObjectiveName(protectionObjectiveId)}
                  getStyle={classes.accordion}
                >
                  {isChangingAllOccurrenceIds && (
                    <Grid container justifyContent="center">
                      <Grid item>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  )}
                  {!isChangingAllOccurrenceIds && (
                    <RiskAssessmentAutoSave phaseId={phaseId} protectionObjectiveId={protectionObjectiveId} />
                  )}
                </AccordionMultiField>
              ))}
            <Grid direction={"row"} container className={classes.riskAlert} justifyContent={"center"}>
              <RiskRatingAlert
                riskRating={risk.assessments[phaseId].rating}
                riskRatingIsLoading={isChangingAllOccurrenceIds}
              />
              <RiskHeatMap
                damageExtendId={getHighestRatingAssessment()?.damageExtendId}
                occurrenceId={getHighestRatingAssessment()?.occurrenceId}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

RiskPhaseAssessment.propTypes = {
  enableIndividualAssessment: PropTypes.bool,
  phaseId: PropTypes.string
};

RiskPhaseAssessment.defaultProps = {
  enableIndividualAssessment: true
};
