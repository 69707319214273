import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import ServiceProviderSectionHeader from "components/ServiceProviderSectionHeader/ServiceProviderSectionHeader";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import RadioButtonQuestion from "components/RadioButtonQuestion/RadioButtonQuestion";
import { getCountriesAndAreas, isEUPlusAdequacyDecisionCountryOrArea } from "../../../handlers/countryHandler";
import { translateAndSortCountriesOrAreas } from "../../../utils/translateAndSortCountriesOrAreas";
import Question from "components/Question/Question";
import { useExternalRecipients } from "../../../contexts/external-recipient-context";
import { mapBooleanToYesNo, mapYesNoToBoolean } from "./helper";
import { ResourceField } from "../../../../components/ResourceField";
import { RESOURCE_TYPES } from "../../../handlers/resourceHandler";

export default function DataProcessingSection({
  setInfo,
  comment: { disableComment },
  answers,
  updateAnswers,
  fieldsToDisplay
}) {
  const { t, i18n } = useTranslation("compliance_page");

  const [sortedCountriesAndAreaIDs, setSortedCountriesAndAreaIDs] = useState([]);
  const [nonEUNorAdequacyCountryIDs, setNonEUNorAdequacyCountryIDs] = useState([]);
  const [countryOrAreaById, setCountryOrAreaById] = useState({});

  useEffect(() => {
    const translatedAndSortedCountryOrAreas = translateAndSortCountriesOrAreas(getCountriesAndAreas(), t);
    const translatedAndSortedCountryIDs = translatedAndSortedCountryOrAreas.map(countryOrArea => countryOrArea.id);

    setSortedCountriesAndAreaIDs(translatedAndSortedCountryIDs);
    setNonEUNorAdequacyCountryIDs(
      translatedAndSortedCountryIDs.filter(countryOrAreaId => !isEUPlusAdequacyDecisionCountryOrArea(countryOrAreaId))
    );
    setCountryOrAreaById(
      translatedAndSortedCountryOrAreas.reduce(
        (accumulatedCountryAreaByIdDict, nextItem) => ({ ...accumulatedCountryAreaByIdDict, [nextItem.id]: nextItem }),
        {}
      )
    );
  }, [i18n.language]);

  const { updateExternalRecipientHook, loadExternalRecipientHook, loadExternalRecipientsHook } =
    useExternalRecipients();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) {
      return;
    }

    if (!answers.id) {
      return;
    }
    setLoaded(true);
  }, [loaded, answers]);

  // meta view info data
  const locationInfoCard = {
    title: t("info_card_title_location"),
    text: t("info_card_content_location")
  };

  const processingOutsideEuInfoCard = {
    title: t("info_card_title_processing_outside"),
    text: t("info_card_contentprocessing_outside")
  };

  const encryptionInfoCard = {
    title: t("info_card_title_encryption"),
    text: t("info_card_content_encryption")
  };

  const pseudonymizationInfoCard = {
    title: t("info_card_title_pseudonomyzation"),
    text: t("info_card_content_pseudonomyzation")
  };

  const safeguardInfoCard = {
    title: t("info_card_title_safeguards"),
    text: t("info_card_content_safeguards")
  };

  const derogationInfoCard = {
    title: t("info_card_title_derogations"),
    text: t("info_card_content_derogations")
  };

  const updateExternalRecipientAndReloadFieldsToDisplay = async payload => {
    await updateExternalRecipientHook(answers.id, payload);
    await loadExternalRecipientHook(answers.id);
  };

  const onRemovalItemSelectedOrChanged = useCallback(
    async (removalId, itemIDs, fieldName) => {
      if (loaded) {
        let toSaveItems = [];
        if (itemIDs.includes(removalId) && itemIDs.length > 1) {
          const removalIndex = itemIDs.findIndex(item => item === removalId);
          const removalItemIsLastAddedItem = removalIndex === itemIDs.length - 1;
          if (removalItemIsLastAddedItem) {
            toSaveItems = [removalId];
          } else {
            toSaveItems = itemIDs.filter(item => item !== removalId);
          }
        } else {
          toSaveItems = itemIDs;
        }
        await updateAnswers({ [fieldName]: toSaveItems });
        if (fieldName === "safeguards") {
          // to update ExpiringSCCNotice if old safeguard is selected/deselected
          await loadExternalRecipientsHook();
        }
      }
    },
    [loadExternalRecipientsHook, loaded, updateAnswers]
  );

  return (
    <>
      <ServiceProviderSectionHeader text={t("data_processing")} />
      {loaded && (
        <>
          {sortedCountriesAndAreaIDs.length && fieldsToDisplay?.dpLocations && (
            <Question
              questionId={"dataProcessing.location"}
              questionName={t("data_processing_location")}
              disabled={disableComment}
            >
              <MultiAutocomplete
                id="data_processing_location"
                label={t("data_processing_location")}
                hasMultiSelect={true}
                options={sortedCountriesAndAreaIDs}
                selected={answers.dpLocations}
                getOptionLabel={countryOrAreaId => countryOrAreaById[countryOrAreaId]?.name || countryOrAreaId}
                onFocus={() => {
                  setInfo(locationInfoCard);
                }}
                updateSelected={value => {
                  updateAnswers({ dpLocations: value });
                  updateExternalRecipientAndReloadFieldsToDisplay({ dpLocations: value });
                }}
              />
            </Question>
          )}
          {fieldsToDisplay?.dpExcludedDPOutsideEU && (
            <Question
              questionId={"dataProcessing.excludedDataProcessingOutsideEU"}
              questionName={t("data_processing_excluded_data_processing_outside_adequacy_title")}
              disabled={disableComment}
            >
              <RadioButtonQuestion
                rowQuestion={false}
                index={0}
                questionId="dataProcessing.excludedDataProcessingOutsideEU"
                radioOptions={["yes", "no"]}
                question={t("data_processing_excluded_data_processing_outside_adequacy_title")}
                initialValue={mapBooleanToYesNo(answers.dpExcludedDPOutsideEU)}
                onChange={(questionId, value) => {
                  updateAnswers({ dpExcludedDPOutsideEU: mapYesNoToBoolean(value) });
                  updateExternalRecipientAndReloadFieldsToDisplay({
                    dpExcludedDPOutsideEU: mapYesNoToBoolean(value)
                  });
                }}
                onFocus={() => {
                  setInfo(processingOutsideEuInfoCard);
                }}
                optionLabels={[t("yes"), t("no")]}
              />
            </Question>
          )}
          {fieldsToDisplay?.dpEnsuredAllServicesProvided && (
            <Question
              questionId={"dataProcessing.ensuredContractuallyAllServicesProvidedInEU"}
              questionName={t("data_processing_ensured_contractually_all_service_provided_in_adequacy_title")}
              disabled={disableComment}
            >
              <RadioButtonQuestion
                rowQuestion={false}
                index={0}
                questionId="dataProcessing.ensuredContractuallyAllServicesProvidedInEU"
                radioOptions={["yes", "no"]}
                question={t("data_processing_ensured_contractually_all_service_provided_in_adequacy_title")}
                initialValue={mapBooleanToYesNo(answers.dpEnsuredAllServicesProvided)}
                onChange={(questionId, value) => {
                  updateAnswers({
                    dpEnsuredAllServicesProvided: mapYesNoToBoolean(value)
                  });
                  updateExternalRecipientAndReloadFieldsToDisplay({
                    dpEnsuredAllServicesProvided: mapYesNoToBoolean(value)
                  });
                }}
                onFocus={() => {
                  setInfo(locationInfoCard);
                }}
                optionLabels={[t("yes"), t("no")]}
              />
            </Question>
          )}
          {fieldsToDisplay?.dpNotExcludableLocations && (
            <Question questionId={"dataProcessing.notExcludableLocations"} disabled={disableComment}>
              <MultiAutocomplete
                id="data_processing_non_excludable_location"
                label={t("data_processing_non_excludable_location")}
                hasMultiSelect={true}
                options={nonEUNorAdequacyCountryIDs}
                selected={answers.dpNotExcludableLocations}
                getOptionLabel={countryOrAreaId => countryOrAreaById[countryOrAreaId]?.name || countryOrAreaId}
                onFocus={() => {
                  setInfo(locationInfoCard);
                }}
                updateSelected={value => {
                  updateAnswers({ dpNotExcludableLocations: value });
                }}
                onBlur={() =>
                  updateExternalRecipientAndReloadFieldsToDisplay({
                    dpNotExcludableLocations: answers.dpNotExcludableLocations
                  })
                }
              />
            </Question>
          )}
          {fieldsToDisplay?.safeguards && (
            <Question
              questionId={"safeguards"}
              questionName={t("safeguardsQuestionTitle")}
              title={t("safeguardsQuestionTitle")}
              disabled={disableComment}
            >
              <Box mt={2} />
              <ResourceField
                id="safeguards"
                label={t("safeguards")}
                value={answers.safeguards}
                onChange={value => {
                  onRemovalItemSelectedOrChanged("5ba0de6450fc10429397", value, "safeguards");
                  updateExternalRecipientAndReloadFieldsToDisplay({ safeguards: value });
                }}
                resourceType={RESOURCE_TYPES.SAFEGUARD}
                allowAdd={true}
                onFocus={() => {
                  setInfo(safeguardInfoCard);
                }}
              />
            </Question>
          )}
          {fieldsToDisplay?.derogations && (
            <Question
              questionId={"derogations"}
              questionName={t("derogationsQuestionTitle")}
              title={t("derogationsQuestionTitle")}
              disabled={disableComment}
            >
              <Box mt={2} />
              <ResourceField
                id="derogations"
                label={t("derogations")}
                value={answers.derogations}
                onChange={value => onRemovalItemSelectedOrChanged("pi1c9mq99cmhy6n05uwo", value, "derogations")}
                resourceType={RESOURCE_TYPES.DEROGATION}
                onFocus={() => {
                  setInfo(derogationInfoCard);
                }}
                onBlur={() => updateExternalRecipientAndReloadFieldsToDisplay({ derogations: answers.derogations })}
              />
            </Question>
          )}
          {fieldsToDisplay?.transferAssessment && (
            <Question
              questionId={"transferRisk"}
              questionName={t("transfer_impact_assessment")}
              disabled={disableComment}
            >
              <RadioButtonQuestion
                rowQuestion={false}
                index={0}
                questionId="transferRisk"
                radioOptions={["yes", "no"]}
                question={t("transfer_impact_assessment")}
                initialValue={mapBooleanToYesNo(answers.transferAssessment)}
                onFocus={() => {
                  setInfo();
                }}
                onChange={(questionId, value) => {
                  updateAnswers({ transferAssessment: mapYesNoToBoolean(value) });
                  updateExternalRecipientAndReloadFieldsToDisplay({ transferAssessment: mapYesNoToBoolean(value) });
                }}
                optionLabels={[t("yes"), t("no")]}
              />
            </Question>
          )}
          {fieldsToDisplay?.encryptions && (
            <Question
              questionId={"encryption"}
              questionName={t("encryptionQuestionTitle")}
              title={t("encryptionQuestionTitle")}
              disabled={disableComment}
            >
              <Box mt={2} />
              <ResourceField
                id="encryption"
                label={t("encryption")}
                value={answers.encryptions}
                onChange={value => onRemovalItemSelectedOrChanged("b2e7f2935f9987eb30f61", value, "encryptions")}
                resourceType={RESOURCE_TYPES.ENCRYPTION}
                allowAdd={true}
                onFocus={() => {
                  setInfo(encryptionInfoCard);
                }}
                onBlur={() => updateExternalRecipientAndReloadFieldsToDisplay({ encryptions: answers.encryptions })}
              />
            </Question>
          )}
          {fieldsToDisplay?.pseudonymizations && (
            <Question
              questionId={"pseudonymization"}
              questionName={t("pseudonymizationQuestionTitle")}
              title={t("pseudonymizationQuestionTitle")}
              disabled={disableComment}
            >
              <Box mt={2} />
              <ResourceField
                id="pseudonymization"
                label={t("pseudonymization")}
                value={answers.pseudonymizations}
                onChange={value => onRemovalItemSelectedOrChanged("0c59efc87c96fe490e22", value, "pseudonymizations")}
                resourceType={RESOURCE_TYPES.PSEUDONYMIZATION}
                allowAdd={true}
                onFocus={() => {
                  setInfo(pseudonymizationInfoCard);
                }}
                onBlur={() =>
                  updateExternalRecipientAndReloadFieldsToDisplay({
                    pseudonymizations: answers.pseudonymizations
                  })
                }
              />
            </Question>
          )}
        </>
      )}
    </>
  );
}
