import { OverviewResult, OverviewResultDecorator } from "../overviewBaseController";
import { getUsers } from "../../../../app/handlers/userAndTenant/userHandler";
import { UserDTO } from "../../../../app/api/user/userApi";

export const usersDecorator: OverviewResultDecorator<{ readonly _tenantUsers: UserDTO[] }> = {
  async decorate(overviewResult: OverviewResult): Promise<{ readonly _tenantUsers: UserDTO[] }> {
    if (overviewResult._tenantUsers) {
      return { _tenantUsers: overviewResult._tenantUsers };
    }

    const users = await getUsers();
    return {
      _tenantUsers: users || []
    };
  }
};
