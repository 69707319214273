import { Box } from "@material-ui/core";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useGetProcessorApi, useUpdateProcessorApi } from "../../../api/processorPAApi";
import { useProcessorPAEditConfirmation } from "../ProcessorPAEditConfirmation";
import { useParams } from "react-router-dom";
import { useMetaView } from "../../../contexts/meta-view-context";
import { PatchProcessorPADTO } from "../../../api/generated/process-service";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";
import Question from "../../../../components/Question/Question";
import { useUpdateQueues } from "../../../../hook/useUpdateQueues";

export const ProcessorMeasure = ({ readonly }: { readonly?: boolean }) => {
  const { id } = useParams();
  const { t } = useTranslation("processor_pa_page");
  const { data } = useGetProcessorApi({ documentId: id || "" });
  const { updateProcessor } = useUpdateProcessorApi({ documentId: id || "" });
  const { queueUpdates } = useUpdateQueues<PatchProcessorPADTO, PatchProcessorPADTO>({
    triggerUpdate: updateProcessor
  });
  const { beforeProcessUpdate, hardResetTrigger } = useProcessorPAEditConfirmation();

  const [measureIds, setMeasureIds] = useState<string[]>([]);

  useEffect(() => {
    if (data?.processorPA) {
      setMeasureIds(data.processorPA.measureIds || []);
    }
  }, [data?.processorPA]);

  const onMeasureIdsChanged = useCallback(
    (measureIds: string[]) => {
      return beforeProcessUpdate(async () => {
        setMeasureIds(measureIds);
        return queueUpdates({ measureIds });
      });
    },
    [beforeProcessUpdate, queueUpdates]
  );

  const infoCard = useMemo(
    () => ({
      enteringPA: {
        title: t("enteringInfoCardTitle", ""),
        text: t("enteringInfoCardText", "")
      },
      measureIds: {
        title: t("measureIds-title", ""),
        text: t("measureIds-info", "")
      }
    }),
    [t]
  );

  const { setInfo } = useMetaView();
  useEffect(() => {
    setInfo(infoCard.enteringPA);
  }, [setInfo, infoCard.enteringPA]);

  return (
    <Box>
      <Box>
        <QuestionnaireSubHeader text={t("measure")} />
      </Box>
      <Box>
        <Question
          // this component have problem with controlled state, so force re-render when dialog is changed
          key={`measureIds-${hardResetTrigger}`}
          qType={QUESTION_TYPE.MEASURE}
          questionId={"measureIds"}
          questionName={t("measureIds")}
          value={measureIds}
          info={infoCard.measureIds}
          onChange={onMeasureIdsChanged}
          disabled={readonly}
        />
      </Box>
    </Box>
  );
};
