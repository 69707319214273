import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo, useState } from "react";
import TabsLayout from "../../../components/TabsLayout/TabsLayout";
import { RiskGeneral } from "./RiskGeneralPage";
import { RiskFirstAssessment } from "./RiskFirstAssessmentPage";
import { RiskTreatment } from "./RiskTreatmentPage";
import { RiskSecondAssessment } from "./RiskSecondAssessmentPage";
import { RiskProvider, useRisk } from "../../contexts/risk-context";
import { CircularProgress, Dialog, DialogContent, Grid } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import { EntityMetaViewProvider } from "../../contexts/meta-view-context";
import { makeStyles } from "@material-ui/core/styles";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";
import { tenantRiskId } from "../../handlers/risksHandler";
import { LinkText } from "../../router/router-filters";
import GeneralPageButtons from "../../../components/Pagination/GeneralPageButtons";
import RenderIcon from "components/RenderIcon/RenderIcon";

const useStyles = makeStyles(theme => ({
  actionsPadding: {
    padding: "0px 48px 24px 48px"
  },
  pinDialogToTop: {
    alignItems: "flex-start"
  },
  buttonsContainer: {
    paddingTop: 20
  },
  closeIcon: {
    float: "right"
  }
}));

export const RiskAsTabs = ({ onClose, selectedTab, updateSelectedTab }) => {
  const { t } = useTranslation("risks_overview");
  const { initialized, risk } = useRisk();
  const classes = useStyles();

  const infoBox = useMemo(() => {
    if (risk?.type !== "general") {
      return <></>;
    }

    return <CustomAlert severity="info">{t("alert_general")}</CustomAlert>;
  }, [risk?.type, t]);

  const tabContents = useMemo(() => {
    return [
      {
        title: t("pagination:general"),
        content: (
          <RelativeBox>
            {infoBox}
            <RiskGeneral />
          </RelativeBox>
        )
      },
      {
        title: t("pagination:risk_assessments_first"),
        content: (
          <RelativeBox>
            {infoBox}
            <RiskFirstAssessment />
          </RelativeBox>
        )
      },
      {
        title: t("pagination:risk_treatment"),
        content: (
          <RelativeBox>
            {infoBox}
            <RiskTreatment />
          </RelativeBox>
        )
      },
      {
        title: t("pagination:risk_assessments_second"),
        content: (
          <RelativeBox>
            {infoBox}
            <RiskSecondAssessment />
          </RelativeBox>
        )
      }
    ];
  }, [t, infoBox]);

  if (!initialized) {
    return (
      <Grid container spacing={1} justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <LinkText pathname={`/risks/${risk.id}/general`} onClick={onClose}>
          {tenantRiskId(risk)}
        </LinkText>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.closeIcon}>
          <RenderIcon key="closePreview" icon="CloseIcon" callBackFunction={() => onClose()} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TabsLayout tabContents={tabContents} setSelectedTab={updateSelectedTab} selectedTab={selectedTab} />
      </Grid>
    </Grid>
  );
};

const RelativeBox = ({ children }) => {
  return <div style={{ position: "relative" }}>{children}</div>;
};

const TabsEnum = {
  general: 0,
  firstAssessment: 1,
  treatment: 2,
  secondAssessment: 3
};

export const RiskDialog = ({ riskId, open, onClose }) => {
  const { t } = useTranslation("risk-dialog");
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0);

  const riskCustomLoadingScreen = useMemo(() => {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }, []);

  const goToNextTabCallback = useCallback(
    () => setSelectedTab(selectedTab => Math.min(selectedTab + 1, TabsEnum.secondAssessment)),
    []
  );
  const goToPreviousTabCallback = useCallback(
    () => setSelectedTab(selectedTab => Math.max(selectedTab - 1, TabsEnum.general)),
    []
  );

  const primaryButton = useMemo(() => {
    const isLastTab = selectedTab === TabsEnum.secondAssessment;
    if (isLastTab) {
      return {
        onClick: onClose,
        label: t("common:close")
      };
    }

    return {
      onClick: goToNextTabCallback
    };
  }, [selectedTab, goToNextTabCallback, t, onClose]);

  const secondaryButton = useMemo(() => {
    const isFirstTab = selectedTab === TabsEnum.general;
    if (isFirstTab) {
      return null;
    }
    return {
      onClick: goToPreviousTabCallback
    };
  }, [selectedTab, goToPreviousTabCallback]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="md"
      classes={{
        scrollPaper: classes.pinDialogToTop
      }}
    >
      <DialogContent>
        <EntityMetaViewProvider>
          <RiskProvider riskId={riskId} customLoadScreen={riskCustomLoadingScreen}>
            <RiskAsTabs onClose={onClose} updateSelectedTab={setSelectedTab} selectedTab={selectedTab} />
          </RiskProvider>
        </EntityMetaViewProvider>
      </DialogContent>
      <DialogActions className={classes.actionsPadding}>
        <Grid container justifyContent="space-between">
          <Grid item />
          <Grid item>
            <Grid container justifyContent="space-between" spacing={1} className={classes.buttonsContainer}>
              <Grid item>
                <GeneralPageButtons primaryButton={primaryButton} secondaryButton={secondaryButton} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
