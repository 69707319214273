import React, { useCallback } from "react";
import { QuestionProps } from "../../Question";
import { ResourceField } from "components/ResourceField";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { useTranslation } from "react-i18next";

const DocumentTypesQuestion = ({ value, disabled = false, error, helperText, onFocus, onChange }: QuestionProps) => {
  const { t } = useTranslation(`resources_legal-retention-period_overview`);
  const _value: string[] = (Array.isArray(value) ? value : [value]) as string[];
  const onChangeCallback = useCallback(
    (deletiontriggerId: string | string[] | null) => {
      onChange?.(deletiontriggerId);
    },
    [onChange]
  );
  return (
    <ResourceField
      value={_value}
      resourceType={RESOURCE_TYPES.DOCUMENT_TYPE}
      label={t("docTypes")}
      onChange={onChangeCallback}
      disabled={disabled}
      error={error}
      helperText={helperText}
      onFocus={onFocus}
    />
  );
};

export default React.memo(DocumentTypesQuestion);
