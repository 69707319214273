import { useMemo } from "react";
import { COLLECTIONS } from "app/collections";
import Overview, { OverviewParams } from "components/Overview/Overview";
import { ProcessesOverviewCollectionParams, ProcessFilterOptions } from "./ProcessesOverviewController";
import { OverviewRowActionProps } from "../../../../components/Overview/controls/OverviewRowAction";
import { OverviewProvider } from "../../../contexts/overview-context";

export interface ProcessesOverviewReadOnlyProps
  extends Pick<OverviewParams, "onRowOver" | "onRowLeave" | "noEntriesText" | "header"> {
  readonly filter: Partial<ProcessFilterOptions>;
}

export const ProcessesOverviewReadOnly = (props: ProcessesOverviewReadOnlyProps) => {
  const collectionParams = useMemo<ProcessesOverviewCollectionParams>(
    () => ({
      filter: props.filter,
      exportToPdfDialog: Promise.reject,
      exportToXLSX: Promise.reject,
      exportToAttachments: Promise.reject,
      openInNewTab: true
    }),
    [props.filter]
  );

  return (
    // this new overview provider is so that it would not share the same state as the main pa overview e.g. applied filter
    <OverviewProvider>
      <Overview
        collection={COLLECTIONS.PROCESSES}
        collectionParams={collectionParams}
        rowActions={emptyRowActions}
        onRowOver={props.onRowOver}
        onRowLeave={props.onRowLeave}
        header={props.header}
        noEntriesText={props.noEntriesText}
      />
    </OverviewProvider>
  );
};

const emptyRowActions: OverviewRowActionProps[] = [];
