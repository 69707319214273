import React, { useMemo } from "react";
import { Box, CircularProgress, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { COLLECTION_TYPES, COLLECTIONS } from "app/collections";
import DocumentNotFound from "app/pages/shared/DocumentNotFound/DocumentNotFound";

const useStyles = makeStyles(() => ({
  paperDoc: {
    height: "calc(100% - 24px)",
    boxShadow: "0px 0px 40px 0px rgb(204 204 204 / 50%)"
  },
  paperMeta: {
    height: "calc(100% - 24px)",
    margin: "0 24px",
    boxShadow: "0px 0px 40px 0px rgb(204 204 204 / 50%)",
    overflow: "auto"
  },
  root: {
    display: "flex",
    height: "100%",
    width: "100%"
  }
}));

/**
 *This component houses the Document and Meta View and it accepts docviewcontent and metaviewcontent as props
 */
export default function DocMetaView({
  docViewContent,
  metaViewContent,
  notFound,
  loading,
  collection,
  children
}: {
  readonly docViewContent?: React.ReactNode;
  readonly metaViewContent?: React.ReactNode;
  readonly notFound?: boolean | undefined;
  readonly loading?: boolean | undefined;
  readonly collection?: COLLECTION_TYPES;
  readonly children?: React.ReactNode;
}) {
  const cls = useStyles();
  const notFoundEl = useMemo(
    () => notFound && <DocumentNotFound collection={collection || COLLECTIONS.PROCESSES} />,
    [collection, notFound]
  );
  const loadingEl = useMemo(
    () =>
      loading && (
        <Box textAlign={"center"} mt={8}>
          <CircularProgress />
        </Box>
      ),
    [loading]
  );
  const heightSettings = useMemo(() => ({ height: "100%" }), []);

  const contentEl = !notFound && !loading && (
    <Box className={cls.root} pl={12}>
      <Grid container>
        <Grid style={heightSettings} xs={metaViewContent ? 8 : 12} item>
          <Paper className={cls.paperDoc}>{children || docViewContent}</Paper>
        </Grid>
        {metaViewContent && (
          <Grid style={heightSettings} xs={4} item>
            <Paper className={cls.paperMeta} data-qa={"doc_meta_view"}>
              {metaViewContent}
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );

  return (
    <>
      {notFoundEl}
      {loadingEl}
      {contentEl}
    </>
  );
}
