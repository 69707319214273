import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { HasPageAccess } from "../../../router/router-filters";
import { useNavigate } from "react-router-dom";
import { usePathName } from "../../../router/router-custom-hooks";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { sidebarZIndex } from "./sidebarZIndex";

export const AdministrationSidebar = ({ sidebarState, classes, onClicked }) => {
  const { t } = useTranslation("sidebar");
  const navigate = useNavigate();
  const path = usePathName();
  const omPath = path.includes("/org_management");
  const usersPath = path.includes("/users");

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = useCallback(event => {
    // seems like event object is re-used by browser, so we need to extract it before we pass it to set state
    // since we use function setter in set state, it can run later, and by then the browser probably have re-used
    // the event object, and changed the current target to something else it wanted
    const currentTarget = event.currentTarget;
    setAnchorEl(anchorEl => (anchorEl ? null : currentTarget));
  }, []);
  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
  }, []);
  useEffect(() => {
    setAnchorEl(null);
  }, [sidebarState]);

  const onNavigate = useCallback(
    path => {
      setAnchorEl(null);
      onClicked?.();
      navigate(path);
    },
    [onClicked, navigate]
  );

  const onOrgManagementClicked = useCallback(() => {
    onNavigate("/org_management");
  }, [onNavigate]);

  const onUsersClicked = useCallback(() => {
    onNavigate("/users");
  }, [onNavigate]);

  const onTokensClicked = useCallback(() => {
    onNavigate("/tokens");
  }, [onNavigate]);

  const isTokenEnabled = useIsFeaturePresent(FEATURES.TOKEN);
  const menus = useMemo(
    () =>
      [
        {
          id: "organization",
          name: "organization_management",
          onClick: onOrgManagementClicked,
          access: "org_management"
        },
        { id: "user", name: "users", onClick: onUsersClicked, access: "user_management" },
        { id: "token", name: "tokens", onClick: onTokensClicked, access: "tokens" }
      ].filter(({ id }) => id !== "token" || isTokenEnabled),
    [isTokenEnabled, onOrgManagementClicked, onTokensClicked, onUsersClicked]
  );

  return (
    <>
      <HasPageAccess requiredPageAccess={["administration"]} returnNothing={true}>
        <ListItem className={omPath || usersPath ? classes.activeItem : ""} button onClick={handleClick}>
          <ListItemIcon className={omPath || usersPath ? classes.activeIcon : classes.icon}>
            <MoreHorizIcon />
          </ListItemIcon>
          <ListItemText primary={t("management")} />
          <ArrowRightIcon />
        </ListItem>
      </HasPageAccess>
      <Popper
        open={anchorEl !== null}
        anchorEl={anchorEl}
        placement="right-start"
        transition
        style={{ zIndex: sidebarZIndex + 1 }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper className={classes.paperTranslationContainer}>
            <MenuList id="composition-menu" aria-labelledby="composition-button">
              {menus.map(i => (
                <HasPageAccess key={i.id} requiredPageAccess={[i.access]} returnNothing={true}>
                  <MenuItem onClick={i.onClick}>{t(i.name)}</MenuItem>
                </HasPageAccess>
              ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default AdministrationSidebar;
