import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAuthentication } from "../handlers/authentication/authentication-context";
import { useErrorSnackbar } from "../../hook/errorSnackbar";
import DocMetaView from "../../components/DocMetaView/DocMetaView";
import DocView from "../../components/DocView/DocView";
import { CircularProgress } from "@material-ui/core";
import DocumentNotFound from "../pages/shared/DocumentNotFound/DocumentNotFound";
import { COLLECTIONS } from "../collections";
import {
  assignsDataSubjectRequest,
  BasicDSRUpdatePayload,
  changeDataSubjectRequestDueDate,
  DataSubjectRequest,
  deleteDataSubjectRequest,
  getDataSubjectRequest,
  markDataSubjectRequestAsDone,
  renameDataSubjectRequest,
  updateBasicDataSubjectRequestData
} from "../handlers/dataSubjectRequestHandler";

export interface DataSubjectRequestContextType {
  readonly dataSubjectRequest: DataSubjectRequest | null;
  readonly loading: boolean;
  readonly initialized: boolean;
  readonly reloadHook: () => Promise<DataSubjectRequest | null>;
  readonly deleteHook: () => Promise<void>;
  readonly renameHook: (title: string) => Promise<void>;
  readonly markAsDoneHook: () => Promise<void>;
  readonly updateBasicDataHook: (payload: BasicDSRUpdatePayload) => Promise<void>;
  readonly assignsToHook: (assigneeUID: string) => Promise<void>;
  readonly changeDueDateHook: (dueDate: string | null) => Promise<void>;
}

export const DataSubjectRequestContext = createContext<DataSubjectRequestContextType>({
  dataSubjectRequest: null,
  loading: true,
  initialized: false,
  reloadHook: Promise.reject,
  deleteHook: Promise.reject,
  renameHook: Promise.reject,
  markAsDoneHook: Promise.reject,
  updateBasicDataHook: Promise.reject,
  assignsToHook: Promise.reject,
  changeDueDateHook: Promise.reject
});
export const DataSubjectRequestProvider = function ({
  children,
  dataSubjectRequestId
}: {
  readonly children: React.ReactNode;
  readonly dataSubjectRequestId: string;
}) {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataSubjectRequest, setDataSubjectRequest] = useState<DataSubjectRequest | null>(null);
  const [dataSubjectRequestMissing, setDataSubjectRequestMissing] = useState(false);

  const { auth } = useAuthentication();
  const tenantId = auth?.tenantId;
  const { catchAsSnackbar } = useErrorSnackbar();

  const reloadHook = useCallback(async () => {
    setLoading(true);
    const dsr = await getDataSubjectRequest(dataSubjectRequestId);
    if (!dsr) {
      setDataSubjectRequestMissing(true);
    } else {
      setDataSubjectRequest(dsr);
    }
    setLoading(false);
    return dsr;
  }, [dataSubjectRequestId]);

  useEffect(() => {
    reloadHook()
      .then(dsr => setInitialized(!!dsr))
      .catch(catchAsSnackbar("Failed to load data subject request"));
  }, [tenantId, catchAsSnackbar, reloadHook]);

  const deleteHook = useCallback(async () => {
    setLoading(true);
    await deleteDataSubjectRequest(dataSubjectRequestId);
    await reloadHook();
  }, [reloadHook, dataSubjectRequestId]);

  const renameHook = useCallback(
    async title => {
      setLoading(true);
      if (title && title.length) {
        await renameDataSubjectRequest(dataSubjectRequestId, title);
      }
      await reloadHook();
    },
    [reloadHook, dataSubjectRequestId]
  );

  const markAsDoneHook = useCallback(async () => {
    setLoading(true);
    await markDataSubjectRequestAsDone(dataSubjectRequestId);
    await reloadHook();
  }, [reloadHook, dataSubjectRequestId]);

  const updateBasicDataHook = useCallback(
    async ({
      orgUnitId,
      furtherOrgUnitIds,
      type,
      description,
      receivedOn,
      personGroups,
      dataTypeIds,
      isDataSubjectVerified,
      labelIds,
      firstName,
      lastName,
      email
    }) => {
      setLoading(true);
      await updateBasicDataSubjectRequestData(dataSubjectRequestId, {
        orgUnitId,
        furtherOrgUnitIds,
        type,
        description,
        receivedOn,
        personGroups,
        dataTypeIds,
        isDataSubjectVerified,
        labelIds,
        firstName,
        lastName,
        email
      });
      await reloadHook();
    },
    [reloadHook, dataSubjectRequestId]
  );

  const changeDueDateHook = useCallback(
    async dueDate => {
      setLoading(true);
      await changeDataSubjectRequestDueDate(dataSubjectRequestId, dueDate);
      await reloadHook();
    },
    [reloadHook, dataSubjectRequestId]
  );

  const assignsToHook = useCallback(
    async assigneeUID => {
      setLoading(true);
      await assignsDataSubjectRequest(dataSubjectRequestId, assigneeUID);
      await reloadHook();
    },
    [reloadHook, dataSubjectRequestId]
  );

  return (
    <DataSubjectRequestContext.Provider
      value={{
        initialized,
        loading,
        dataSubjectRequest,
        reloadHook,
        renameHook,
        deleteHook,
        markAsDoneHook,
        updateBasicDataHook,
        changeDueDateHook,
        assignsToHook
      }}
    >
      {!initialized && !dataSubjectRequestMissing && (
        <DocMetaView
          docViewContent={
            <DocView>
              <CircularProgress color="inherit" />
            </DocView>
          }
        />
      )}
      {dataSubjectRequestMissing && <DocumentNotFound collection={COLLECTIONS.DATA_SUBJECT_REQUESTS} />}
      {initialized && dataSubjectRequest && children}
    </DataSubjectRequestContext.Provider>
  );
};
export const useDataSubjectRequest = () => useContext(DataSubjectRequestContext);
