import CardItemList from "../../../../components/CardItemList/CardItemList";
import React, { useEffect, useState } from "react";
import { getDepartmentName } from "../../../utils/getFirstDepartmentName";
import { useTranslation } from "react-i18next";
import { useUserDepartments } from "app/contexts/department-context";
import { getProcessStatusIconProps } from "../../questionnaires/utils/get-process-status-icon-props";
import { useUserProcesses } from "../../../../hook/useUserProcesses";
import { flattenDataTypes } from "../../../utils/flattenDataTypes";
import { ProcessingActivityOverviewDTO } from "../../../api/paApi";
import { useDataTypeTree } from "app/api/dataAssetApi";
import { Box } from "@material-ui/core";

export default function ProcessesInMetaView({ selectedNode }: { selectedNode?: string }) {
  const { processes } = useUserProcesses();
  const dataTypeTree = useDataTypeTree();

  const [dataResourceToPAIds, setDataResourceToPAIds] = useState<Map<string, string[]>>(new Map<string, string[]>());
  useEffect(() => {
    const dataResourceToPAIds = new Map<string, string[]>();
    const flatDataType = flattenDataTypes(dataTypeTree.data || []);
    for (const process of processes) {
      const dataTypes = process.allDataTypeIds
        .flatMap(dataTypeId => {
          const dataType = flatDataType.find((dataType: any) => dataType.id === dataTypeId);
          return dataType ? [dataType] : [];
        })
        .filter(it => !it.mergedInto);
      for (const dataType of dataTypes) {
        const personGroupKey = dataType.personGroupKey;
        const dataCategoryKey = dataType.dataCategoryKey;
        const dataTypeId = dataType.id;
        dataResourceToPAIds.set(dataTypeId, [...(dataResourceToPAIds.get(dataTypeId) || []), process.id]);
        if (dataCategoryKey) {
          dataResourceToPAIds.set(dataCategoryKey, [...(dataResourceToPAIds.get(dataCategoryKey) || []), process.id]);
        }
        if (personGroupKey) {
          dataResourceToPAIds.set(personGroupKey, [...(dataResourceToPAIds.get(personGroupKey) || []), process.id]);
        }
      }
    }
    setDataResourceToPAIds(dataResourceToPAIds);
  }, [processes, dataTypeTree.data]);

  const { departments } = useUserDepartments();

  const { t } = useTranslation("resources_lists_data_types_categories_person_groups");

  const [relatedProcesses, setRelatedProcesses] = useState<ProcessingActivityOverviewDTO[]>([]);
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    if (!selectedNode) {
      return;
    }
    let processIds: string[] = [];
    const [selectedId] = selectedNode.split("_").reverse();
    processIds = dataResourceToPAIds.get(selectedId) || [];
    if (dataTypeTree.dataById?.dataTypes[selectedId]) {
      setSelectedType("dataType");
    } else if (dataTypeTree.dataById?.dataCategories[selectedId]) {
      setSelectedType("category");
    } else if (dataTypeTree.dataById?.personGroups[selectedId]) {
      setSelectedType("personGroup");
    }
    const processesForMetaView = processes.filter(process => processIds.includes(process.id));
    setRelatedProcesses(processesForMetaView);
  }, [selectedNode, processes, dataResourceToPAIds, dataTypeTree.dataById]);

  const [relatedProcessingActivitiesCardItemList, setRelatedProcessingActivitiesCardItemList] = useState<any[]>([]);
  useEffect(() => {
    setRelatedProcessingActivitiesCardItemList(
      relatedProcesses.map(process => ({
        primaryIcon: getProcessStatusIconProps(process.status).icon,
        primaryIconTooltip: t("processes_overview:" + process.status),
        onClick: () => window.open("/processes/" + process.id + "/general"),
        primaryText: process.title,
        secondaryText: process.orgUnitIds?.length ? getDepartmentName(departments, process.orgUnitIds[0]) : ""
      }))
    );
  }, [departments, relatedProcesses, t, dataTypeTree.dataById]);

  return selectedType ? (
    <Box p={3}>
      <CardItemList
        header={t("processes_overview:list_processes")}
        headerIcon={"InsertDriveFileIcon"}
        itemList={relatedProcesses.length > 0 ? relatedProcessingActivitiesCardItemList : []}
        text={(relatedProcesses.length === 0 && t(`no_processes_${selectedType}`)) || ""}
        headerIconTooltip={undefined}
      />
    </Box>
  ) : (
    <></>
  );
}
