import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Popper,
  Tooltip,
  Typography
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SortByAlpha from "@material-ui/icons/SortByAlpha";
import { OverviewContextSort } from "app/contexts/overview-context";
import { isEmpty, isEqual } from "lodash-es";
import OverviewIconButton from "./OverviewIconButton";

const useStyles = makeStyles(theme => ({
  emptyButton: {
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main
    }
  },
  nonEmptyButton: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main
    }
  },
  sort: {
    minWidth: "200px",
    maxHeight: "400px",
    width: "auto",
    overflow: "auto",
    "& .sortItem": {
      padding: "4px 24px"
    }
  }
}));

export interface SortingItemProps {
  readonly field: string;
  readonly type: string;
  readonly label: string;
}

export interface OverviewSortProps {
  readonly sortings: SortingItemProps[];
  readonly sort: OverviewContextSort;
  readonly onSort: (val: SortingItemProps) => void;
  readonly onReset: (val: string) => void;
}

export interface SortMenuItemProps {
  readonly menuItem: SortingItemProps;
  readonly selected: boolean;
  readonly onSort: (val: SortingItemProps) => void;
}

const SortMenuItem = ({ menuItem, selected, onSort }: SortMenuItemProps) => {
  const onSortCallback = useCallback(() => {
    onSort(menuItem);
  }, [menuItem, onSort]);
  return (
    <ListItem className="sortItem" selected={selected} button onClick={onSortCallback}>
      <ListItemText primary={menuItem.label} />
    </ListItem>
  );
};

export const OverviewSort = ({ sortings, sort, onSort, onReset }: OverviewSortProps) => {
  const cls = useStyles();
  const { t } = useTranslation("filter_criteria");
  const [open, setOpen] = React.useState(false);
  const [anchorRef, setAnchorRef] = useState(null);

  /* ACTIONS */
  const handleToggle = useCallback(event => {
    setAnchorRef(event.currentTarget);
    setOpen(prevOpen => !prevOpen);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setAnchorRef(null);
  }, []);

  const onResetSort = useCallback(() => onReset("sort"), [onReset]);

  /* ACTIONS */

  const addButtonEl = (
    <OverviewIconButton tooltip={t("sort")} nonEmpty={!isEmpty(sort)} onClick={handleToggle}>
      <SortByAlpha />
    </OverviewIconButton>
  );

  const sortEl = (
    <>
      <Box pt={2} pb={1} px={3}>
        <Typography align="left" style={{ fontWeight: 700 }} variant="h4">
          {t("sort")}
        </Typography>
      </Box>
      <List component="nav">
        {sortings?.map((sortType: SortingItemProps, index: number) => (
          <SortMenuItem
            key={index}
            menuItem={sortType}
            selected={isEqual(sort, { [sortType.field]: sortType.type })}
            onSort={onSort}
          />
        ))}
      </List>
    </>
  );

  const resetEl = (
    <Collapse in={!isEmpty(sort)}>
      <Box px={2} py={1}>
        <Button size="medium" fullWidth={true} color="primary" onClick={onResetSort}>
          {t("undoFilter")}
        </Button>
      </Box>
      <Divider />
    </Collapse>
  );
  const popperEl = (
    <Popper open={open} anchorEl={anchorRef} placement={"bottom-start"} transition disablePortal>
      {() => (
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <Box py={2} className={cls.sort}>
              {resetEl}
              {sortEl}
            </Box>
          </ClickAwayListener>
        </Paper>
      )}
    </Popper>
  );

  return (
    <Box display="flex">
      {addButtonEl}
      {popperEl}
    </Box>
  );
};
