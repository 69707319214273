import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import Paper from "@material-ui/core/Paper";
import LoginLogo from "./../../../assets/images/logo_login.svg?img";
import { useTranslation } from "react-i18next";
import { Box, Button, CircularProgress, Menu, MenuItem, Typography } from "@material-ui/core";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";
import Link from "@material-ui/core/Link";
import { AlertProps } from "@material-ui/lab/Alert/Alert";
import { useAvailableLanguages } from "../../../hook/useAvailableLanguages";
import LanguageIcon from "@mui/icons-material/Language";

export interface AuthenticationAlert extends Pick<AlertProps, "severity"> {
  readonly header?: string;
  readonly title: string;
}

export default function AuthenticationPage({
  alertNotification,
  additionalButtons,
  children
}: {
  alertNotification?: AuthenticationAlert | null;
  additionalButtons?: React.ReactNode;
  children?: React.ReactNode;
}) {
  const { loading } = useAuthentication();
  const { i18n } = useTranslation();
  const { t } = useTranslation("sidebar");
  const { availableLanguages } = useAvailableLanguages();
  const [openLanguageMenu, setOpenLanguageMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const matchedLanguage = useMemo(
    () =>
      availableLanguages.find(it => it === i18n.language) ||
      availableLanguages.find(it => it === i18n.language.split("-")[0]) ||
      "en",
    [availableLanguages, i18n.language]
  );

  const changeLanguageCallback = useCallback(
    (languageCode: string) => () => {
      setOpenLanguageMenu(false);
      return i18n.changeLanguage(languageCode);
    },
    [i18n]
  );

  const languageOptions = useMemo(
    () =>
      availableLanguages
        .map(
          l =>
            [
              t(l),
              <MenuItem key={l} onClick={changeLanguageCallback(l)}>
                {t(l)}
              </MenuItem>
            ] as [string, React.JSX.Element]
        )
        .sort(([translatedLang1], [translatedLang2]) => translatedLang1.localeCompare(translatedLang2))
        .map(([, menuItem]) => menuItem),
    [t, changeLanguageCallback, availableLanguages]
  );

  const handleButtonLanguageClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenLanguageMenu(true);
  }, []);

  const closeLanguageMenu = useCallback(() => {
    setOpenLanguageMenu(false);
  }, []);

  const style = useMemo<CSSProperties>(
    () => ({
      marginLeft: 1,
      fontSize: "14px",
      fontFamily: "OpenSans",
      borderRadius: "21px",
      border: "1px solid rgba(0,0, 0, 0.12)",
      color: "#A4A7A8",
      textTransform: "none"
    }),
    []
  );

  return (
    <Box height="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Box maxWidth={700} width="100%" overflow="auto" p={2}>
        <Paper style={paperStyle}>
          <Box p={1}>
            <Box mt={1} mr={1} display="flex" justifyContent="flex-end">
              {additionalButtons}
              <Button
                variant="outlined"
                onClick={handleButtonLanguageClick}
                disabled={availableLanguages.length <= 1}
                startIcon={<LanguageIcon />}
                style={style}
              >
                <Typography component="div" style={{ fontFamily: "OpenSans", color: "#A4A7A8", fontWeight: 600 }}>
                  {matchedLanguage.toUpperCase()}
                </Typography>
              </Button>
              <Menu
                style={{ fontFamily: "OpenSans", fontSize: "14px" }}
                id="basic-menu"
                open={openLanguageMenu}
                onClose={closeLanguageMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button"
                }}
                anchorEl={anchorEl}
              >
                {languageOptions}
              </Menu>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box maxWidth={350} mt={4} mb={2} ml={1} width="100%">
                <Box mb={2}>
                  <Link href="/">
                    <LoginLogo alt="caralegal Logo" style={imageStyle} />
                  </Link>
                </Box>
                {alertNotification && (
                  <CustomAlert severity={alertNotification.severity}>
                    {alertNotification.header && (
                      <Box fontWeight={600}>
                        <Typography component="div">{alertNotification.header}</Typography>
                      </Box>
                    )}
                    <Typography component="span">{alertNotification.title}</Typography>
                  </CustomAlert>
                )}
                {loading && (
                  <Box m={4} display="flex" justifyContent="center">
                    <CircularProgress color="inherit" size={24} />
                  </Box>
                )}
                {!loading && children}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

const imageStyle: CSSProperties = {
  maxWidth: 425,
  maxHeight: 86,
  height: "auto",
  width: "100%",
  borderRadius: 8,
  cursor: "pointer",
  userSelect: "none"
};

const paperStyle: CSSProperties = { width: "100%" };
