import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  makeStyles,
  Paper,
  Popper,
  Switch,
  Tooltip,
  Typography
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FilterList from "@material-ui/icons/FilterList";
import TreeFilter from "components/TreeFilter/TreeFilter";
import { OverviewContextFilter } from "app/contexts/overview-context";
import { isEmpty } from "lodash-es";

import { sidebarZIndex } from "../../../app/pages/shared/Sidebar/sidebarZIndex";
import { FilterItemProps, FiterTreeItemProps } from "../controllers/overviewBaseController";
import OverviewIconButton from "./OverviewIconButton";

const useStyles = makeStyles(theme => ({
  emptyButton: {
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main
    }
  },
  nonEmptyButton: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main
    }
  },
  filter: {
    minWidth: "400px",
    maxHeight: "400px",
    width: "auto",
    overflow: "auto",
    zIndex: sidebarZIndex + 1
  }
}));

export interface OverviewFilterProps {
  readonly filters: FilterItemProps[];
  readonly filter: OverviewContextFilter;
  readonly onFilter: (val: object) => void;
  readonly onReset: (val: string) => void;
}

const FilterTooltip = ({ filter, filters }: Pick<OverviewFilterProps, "filter" | "filters">) => {
  const { t } = useTranslation("filter_criteria");

  const [selectedFilterTooltip, setSelectedFilterTooltip] = useState("");
  useEffect(() => {
    setSelectedFilterTooltip(getFilterText({ filter, filters }));
  }, [filter, filters]);

  if (!selectedFilterTooltip) {
    return <>{t("filter")}</>;
  }

  return <div style={preLineStyle}>{`${t("activated_filter")}\n\n${selectedFilterTooltip}`}</div>;
};
const preLineStyle: CSSProperties = { whiteSpace: "pre-line" };

const getFilterText = (input: Pick<OverviewFilterProps, "filter" | "filters">): string => {
  if (!input.filter) {
    return "";
  }

  const getFilterText = (
    filterField: string,
    filterValue: string | string[]
  ): { readonly field: string; readonly selectedValues: string[] } | null => {
    const filter = input.filters.find(filter => filter.filterField === filterField);
    if (!filter) {
      return null;
    }

    const filterTree = filter.filterTree?.[0];
    if (!filterTree) {
      return null;
    }

    const values = Array.isArray(filterValue) ? filterValue : [filterValue];

    const withChildren = (input: FiterTreeItemProps): FiterTreeItemProps[] => [
      input,
      ...(input.children || []).flatMap(withChildren)
    ];
    const filterTreeFlatChildren = withChildren(filterTree);

    return {
      field: filterTree.name || "",
      selectedValues: values
        .map(value => filterTreeFlatChildren.find(it => it?.id === value)?.name)
        .filter((it): it is string => !!it)
    };
  };

  return Object.entries(input.filter)
    .map(([filterField, filterValue]) => {
      return getFilterText(filterField, filterValue);
    })
    .filter((it): it is NonNullable<ReturnType<typeof getFilterText>> => !!it)
    .filter(it => it.selectedValues.length > 0)
    .map(it => {
      const valueText = it.selectedValues.map(it => `- ${it}`).join("\n");
      return `${it.field}:\n${valueText}`;
    })
    .join("\n\n");
};

interface SwitchFilterProps {
  readonly label: string;
  readonly value: boolean;
  readonly filterField: string;
  readonly defaultValue: string[];
  readonly onChange: (val: object) => void;
}
const SwitchFilter = ({ label, value, filterField, defaultValue, onChange }: SwitchFilterProps) => {
  const onChangeCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange({ [filterField]: checked ? defaultValue : [] });
    },
    [defaultValue, filterField, onChange]
  );
  return (
    <Box mx={1} my={1}>
      <FormControlLabel
        control={
          <Box ml={3}>
            <Switch size="small" checked={value} color="primary" name={label} onChange={onChangeCallback} />
          </Box>
        }
        label={label}
        labelPlacement="start"
      />
    </Box>
  );
};

export interface OverviewFilterProps {
  readonly filters: FilterItemProps[];
  readonly filter: OverviewContextFilter;
  readonly onFilter: (val: object) => void;
  readonly onReset: (val: string) => void;
}

export const OverviewFilter = ({ filters, filter, onFilter, onReset }: OverviewFilterProps) => {
  const { t } = useTranslation("filter_criteria");
  const cls = useStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorRef, setAnchorRef] = useState(null);

  /* ACTIONS */
  const handleToggle = useCallback(event => {
    setAnchorRef(event.currentTarget);
    setOpen(prevOpen => !prevOpen);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setAnchorRef(null);
  }, []);
  const onResetFilter = useCallback(() => onReset("filter"), [onReset]);

  /* ACTIONS */

  const filterButtonEl = useMemo(
    () => (
      <OverviewIconButton
        tooltip={<FilterTooltip filter={filter} filters={filters} />}
        nonEmpty={!isEmpty(filter)}
        onClick={handleToggle}
      >
        <FilterList />
      </OverviewIconButton>
    ),
    [filter, filters, handleToggle]
  );

  const filterEl = (
    <>
      <Box py={2} pl={6} pr={3}>
        <Typography align="left" style={{ fontWeight: 700 }} variant="h4">
          {t("filter")}
        </Typography>
      </Box>
      {filters
        ?.filter(f => f)
        .map((f, index) => {
          const { filterField, filterTree, singleSelect, switchControl } = f;
          type key = keyof typeof f;
          const keyValue = f.filterField as key;
          const checkedItems = filter && filter?.[keyValue];

          return (
            <Box key={`${filterField}-${filterTree?.[0]?.name}-${index}`}>
              {switchControl && (
                <SwitchFilter
                  label={switchControl.label}
                  value={!!checkedItems?.length}
                  filterField={f.filterField}
                  defaultValue={switchControl.defaultValue}
                  onChange={onFilter}
                />
              )}
              <Collapse in={!!(!switchControl || checkedItems)}>
                <TreeFilter
                  field={filterField}
                  checkedItems={checkedItems}
                  tree={filterTree}
                  onChange={onFilter}
                  singleSelect={singleSelect}
                />
              </Collapse>
            </Box>
          );
        })}
    </>
  );

  const resetEl = (
    <Collapse in={!isEmpty(filter)}>
      <Box px={2} py={1}>
        <Button size="medium" fullWidth={true} color="primary" onClick={onResetFilter}>
          {t("undoFilter")}
        </Button>
      </Box>
      <Divider />
    </Collapse>
  );

  const popperEl = (
    <Popper open={open} anchorEl={anchorRef} placement={"bottom-start"} transition disablePortal>
      {() => (
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <Box py={2} className={cls.filter}>
              {resetEl}
              {filterEl}
            </Box>
          </ClickAwayListener>
        </Paper>
      )}
    </Popper>
  );

  return (
    <Box display="flex">
      {filterButtonEl}
      {popperEl}
    </Box>
  );
};
