import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { getAsset } from "./handler/assetHandler";
import { useNavigate, useParams } from "react-router-dom";
import { AssetUpdateDTO } from "./types/AssetTypes";
import AssetDetails from "./components/AssetDetails";
import DocView from "components/DocView/DocView";
import TabsLayout from "components/TabsLayout/TabsLayout";
import AssetProcessesOverview from "./components/AssetProcessesOverview";
import { useTranslation } from "react-i18next";
import DataLocationOverview from "../datalocations/DataLocationOverview";
import { COLLECTIONS } from "app/collections";

const Asset = () => {
  const { id } = useParams();
  const { t } = useTranslation("asset_details");
  const [asset, setAsset] = useState<AssetUpdateDTO>({});
  const [documentNotFound, setDocumentNotFound] = useState(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [assetName, setAssetName] = useState<string>("");
  const { page } = useParams();
  const navigate = useNavigate();

  const tabContents = useMemo(() => {
    return [
      { title: t("tabGeneral"), tabId: "general" },
      { title: t("tabProcess"), tabId: "processes" },
      { title: t("tabDataLocations"), tabId: "data-locations" }
    ];
  }, [t]);

  const onTabChange = useCallback(
    tab => {
      const tabName = tabContents[tab].tabId || "general";
      navigate(`/asset-management/${id}/${tabName}`);
    },
    [id, navigate, tabContents]
  );

  useEffect(() => {
    const fetch = async () => {
      try {
        if (id) {
          const data = await getAsset(id);
          if (data) {
            setAsset(data);
            setAssetName(data.name);
          } else {
            setDocumentNotFound(true);
          }
        }
      } catch (e) {
        setDocumentNotFound(true);
        throw e;
      }
    };
    fetch();
  }, [id]);

  useEffect(() => {
    if (page) {
      return setSelectedTab(tabContents.map(({ tabId }: { tabId: string }) => tabId).indexOf(page));
    }
  }, [page, tabContents]);

  const getCurrenttabContentEl = useCallback(() => {
    switch (page) {
      case "general": {
        return <AssetDetails onSetAssetName={setAssetName} assetData={asset} />;
      }
      case "processes": {
        return <AssetProcessesOverview assetId={id || ""} />;
      }
      case "data-locations": {
        return <DataLocationOverview dataLocationIds={asset.dataLocationIds} hideTitle={true} hideActions={true} />;
      }
    }
  }, [asset, id, page]);

  const docViewContentElements = useMemo(
    () => (
      <DocView header={assetName}>
        <TabsLayout
          selectedTab={selectedTab}
          tabContents={tabContents}
          setSelectedTab={onTabChange}
          toolbarComponent={undefined}
        />
        {getCurrenttabContentEl()}
      </DocView>
    ),
    [assetName, getCurrenttabContentEl, onTabChange, selectedTab, tabContents]
  );

  return (
    <DocMetaView
      notFound={documentNotFound}
      loading={!asset.id && !documentNotFound}
      docViewContent={docViewContentElements}
      metaViewContent={<MetaView translationKey={"asset_details"} />}
      collection={COLLECTIONS.ASSETS}
    />
  );
};

export default React.memo(Asset);
