import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { AuditOverviewItem, patchAuditDetail, patchAuditTemplate } from "../../../../app/api/auditApi";
import ListViewItem, { ItemStatus, LazySvgIcon } from "components/ListViewItem/ListViewItem";
import { CustomRowComponentProps } from "components/Overview/controls/OverviewRow";
import { AUDIT_STATUS, AUDIT_TEMPLATE_STATUS } from "../../../../app/pages/audits/AuditTypes";
import { Chip, Tooltip } from "@mui/material";
import colors from "theme/palette/colors";
import { useNavigate } from "react-router-dom";

const chipStyle = {
  padding: "6px 10px",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  height: "24px",
  backgroundColor: colors.grey.grey200,
  "& .MuiChip-label": {
    fontSize: "11px",
    lineHeight: "12px",
    textTransform: "uppercase",
    fontWeight: 600,
    color: colors.grey.grey700,
    padding: 0
  }
};

export default function AuditOverviewRow({
  item,
  onDelete,
  onChecked,
  checkable,
  checked
}: CustomRowComponentProps<AuditOverviewItem>) {
  const { t } = useTranslation();
  const onDeleteItem = useCallback(async () => {
    await onDelete(item.id);
  }, [onDelete, item.id]);
  const isTemplate = "inlineTemplate" in item;
  const auditStatuses: ItemStatus[] = useMemo(
    () => [
      {
        icon: <LazySvgIcon name="Draft" />,
        label: t(`audit_status:${AUDIT_STATUS.DRAFT}`),
        status: AUDIT_STATUS.DRAFT
      },
      {
        icon: <LazySvgIcon name="In_Progress" />,
        label: t(`audit_status:${AUDIT_STATUS.EDIT}`),
        status: AUDIT_STATUS.EDIT
      },
      {
        icon: <LazySvgIcon name="In_Review" />,
        label: t(`audit_status:${AUDIT_STATUS.REVIEW}`),
        status: AUDIT_STATUS.REVIEW
      },
      {
        icon: <LazySvgIcon name="Completed" />,
        label: t(`audit_status:${AUDIT_STATUS.COMPLETED}`),
        status: AUDIT_STATUS.COMPLETED
      }
    ],
    [t]
  );
  const templateStatuses: ItemStatus[] = useMemo(
    () => [
      {
        icon: <LazySvgIcon name="Completed" />,
        label: t(`audit_status:${AUDIT_TEMPLATE_STATUS.ACTIVE}`),
        status: AUDIT_TEMPLATE_STATUS.ACTIVE
      },
      {
        icon: <LazySvgIcon name="On_Halt" />,
        label: t(`audit_status:${AUDIT_TEMPLATE_STATUS.INACTIVE}`),
        status: AUDIT_TEMPLATE_STATUS.INACTIVE
      }
    ],
    [t]
  );
  const handleAuditStatusChange = useCallback(
    async (status: string) => {
      await patchAuditDetail({ id: item.id, payload: { status } });
    },
    [item.id]
  );
  const handleTemplateStatusChange = useCallback(
    async (status: string) => {
      await patchAuditTemplate({ id: item.id, payload: { status } });
    },
    [item.id]
  );
  const deleteDisabled = useMemo(
    () => item.disableActions?.find(({ action }) => action === "remove"),
    [item.disableActions]
  );
  const editDisabled = useMemo(
    () => item.disableActions?.find(({ action }) => action === "edit"),
    [item.disableActions]
  );
  const navigate = useNavigate();
  const onNavigate = useCallback(() => {
    navigate(isTemplate ? `/audits/templates/${item.id}` : `/audits/instances/${item.id}/general`);
  }, [navigate, item.id, isTemplate]);
  return (
    <ListViewItem
      title={item.title}
      onClick={onNavigate}
      icon={item.icon}
      status={item.status}
      allowedStatuses={isTemplate ? templateStatuses : auditStatuses}
      onDelete={onDeleteItem}
      deletable={!deleteDisabled}
      onSelect={checkable ? onChecked : undefined}
      selected={checked}
      onStatusChange={editDisabled ? undefined : isTemplate ? handleTemplateStatusChange : handleAuditStatusChange}
    >
      {isTemplate ? null : <LazySvgIcon color={colors.grey.grey500} name="Assessment" />}
      {item.auditReportDueDate && (
        <Tooltip title={t("audit_details:auditReportDueDate")}>
          <Chip sx={chipStyle} label={new Date(item.auditReportDueDate).toLocaleDateString()} />
        </Tooltip>
      )}
      {editDisabled && <Chip title={t("common:readOnly")} sx={chipStyle} label={t("common:readOnly")} />}
    </ListViewItem>
  );
}
