import React, { useCallback, useEffect, useMemo } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { useMetaView } from "app/contexts/meta-view-context";
import { triggerScan } from "app/api/websiteApi";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";

const WebsiteOverview = () => {
  const { t } = useTranslation();
  const { setInfo } = useMetaView();
  const dispatch = useOverviewDispatch();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("websites_overview:enteringInfoCardTitle"),
        text: t("websites_overview:enteringInfoCardText")
      },
      creating: {
        title: t("websites_overview:creating_info_card_title"),
        text: t("websites_overview:creating_info_card_text")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);

  const showEnterInfo = useCallback(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);

  const showCreateInfo = useCallback(() => {
    setInfo(infoCard?.creating);
  }, [infoCard, setInfo]);

  const onRowOver = useCallback(item => {
    // do nothing
  }, []);

  const toolbarActions = useMemo(() => [{ action: "sort" }, { action: "filter" }], []);
  const addActions = useMemo(() => [{ action: OVERVIEW_ADD_TYPE.SINGLE }], []);
  const onTriggerScan = useCallback(
    async (id: string) => {
      await triggerScan(id);
      dispatch({
        type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
        collection: COLLECTIONS.WEBSITES,
        reloadOverview: Date.now()
      });
    },
    [dispatch]
  );
  const rowActions = useMemo(
    () => [
      {
        action: "scan",
        onHandle: onTriggerScan,
        title: t("websites_overview:scan"),
        icon: <PlayArrowIcon />
      },
      { action: "edit" },
      { action: "remove" }
    ],
    [onTriggerScan, t]
  );

  const docViewContent = (
    <Overview
      onAddOpen={showCreateInfo}
      onAddClose={showEnterInfo}
      onRowOver={onRowOver}
      onRowLeave={showEnterInfo}
      header={t("websites_overview:header")}
      collection={COLLECTIONS.WEBSITES}
      rowActions={rowActions}
      toolbarActions={toolbarActions}
      addActions={addActions}
    />
  );
  return (
    <DocMetaView docViewContent={docViewContent} metaViewContent={<MetaView translationKey={"websites_overview"} />} />
  );
};

export default React.memo(WebsiteOverview);
