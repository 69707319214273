import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import MetaView from "../../../components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { useMetaView } from "app/contexts/meta-view-context";
import OrganizationTree from "app/pages/organization/OrganizationTree";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import { Close } from "@material-ui/icons";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { debounce, isEmpty, isEqual } from "lodash-es";
import { buildTree, buildTreebyParents, removeSameParentOrgUnit } from "../shared/Filters/OrganizationFilter";
import { Sorter } from "./Sorter";
import { getFilter, sortings, sortTreeByName } from "./OrganizationHelper";
import { OverviewFilter } from "../../../components/Overview/controls/OverviewFilter";
import { OverviewContextFilter } from "app/contexts/overview-context";
import { useUserDepartments } from "../../contexts/department-context";
import { AccountNotice } from "../authentication/AccountNotice";
import {
  addOrganisationalUnit,
  deleteOrganisationalUnits,
  editNameOfOrganisationalUnit,
  getOrgUnits
} from "../../handlers/departmentHandler";
import OrgUnitDependenciesDialog from "./OrgUnitDependenciesDialog";
import { COLLECTION_TYPES, COLLECTIONS } from "app/collections";
import { FilterItemProps } from "../../../components/Overview/controllers/overviewBaseController";

const useStyles = makeStyles(() => ({
  header: {
    top: 0,
    zIndex: 900,
    position: "sticky",
    background: "white",
    boxShadow: "0 8px 6px -6px #ccc",
    "& .MuiButtonGroup-groupedContainedPrimary:not(:last-child)": {
      borderColor: "#fff"
    },
    "& .MuiButtonGroup-groupedHorizontal:not(:first-child)": {
      minWidth: "40px",
      width: "40px"
    }
  }
}));

export interface DataStructure {
  readonly name: string;
  readonly parentId: string;
}

export interface OrgUnitTreeStructure {
  readonly id: string;
  readonly data: DataStructure;
  readonly sortId: string;
  readonly children: OrgUnitTreeStructure[];
}

export default function OrganizationOverview() {
  /* CONTEXT */
  const cls = useStyles();
  const { t } = useTranslation("organisationManagement");
  const { setInfo } = useMetaView();
  const { auth } = useAuthentication();
  const { departments, getDepartmentRecursively, reloadDepartments, departmentsLoaded } = useUserDepartments();

  /* STATE */
  const [items, setItems] = useState<OrgUnitTreeStructure[]>([]);
  const [itemsAfterSearch, setItemsAfterSearch] = useState<OrgUnitTreeStructure[]>([]);
  const [countText, setCountText] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sortingProps, setSortingProps] = useState<null | {
    order: keyof OrgUnitTreeStructure;
  }>();
  const [filters, setFilters] = useState<FilterItemProps[]>([]);
  const [filter, setFilter] = useState<OverviewContextFilter>({});
  const [orgUnitDependenciesDialogMessage, setOrgUnitDependenciesDialogMessage] = useState<string | null>(null);

  const closeOrgUnitDependenciesDialog = useCallback(() => {
    setOrgUnitDependenciesDialogMessage(null);
  }, []);

  const countTree = useCallback(
    (items: OrgUnitTreeStructure[]) => {
      if (items.length === 0) {
        setCountText("");
        return;
      }
      let counter = items.length;
      items.forEach(child => {
        counter += child.children ? child.children.length : 0;
      });
      setCountText(t(counter === 1 ? "overview:item_single" : "overview:items_number", { number: counter }));
    },
    [t]
  );

  const fetch = useCallback(
    async (dontLoad?: boolean) => {
      if (!auth?.tenantId) {
        return;
      }
      if (!departmentsLoaded) {
        return;
      }
      if (!dontLoad) {
        setIsLoading(true);
      }
      const resultOrgUnitsIncludingDeleted = await getOrgUnits();
      const resultOrgUnits = resultOrgUnitsIncludingDeleted.filter(org => org.deleted !== true);
      const filterOrgUnit: string[] = isEmpty(filter)
        ? []
        : removeSameParentOrgUnit(resultOrgUnits, [...(filter.id || [])]);

      const userOrgUnits = removeSameParentOrgUnit(resultOrgUnits, [
        auth?.orgUnitId,
        ...(auth?.furtherOrgUnitIds || [])
      ]);

      let tree;
      if (filterOrgUnit.length > 0) {
        tree = buildTreebyParents(resultOrgUnits, filterOrgUnit);
      } else if (auth?.isUserDepartmentBound) {
        tree = buildTreebyParents(resultOrgUnits, userOrgUnits);
      } else {
        tree = buildTree(resultOrgUnits);
      }

      if (tree) {
        countTree(tree);
        setItems(tree);
        if (sortingProps) {
          setItemsAfterSearch(sortTreeByName(tree, sortingProps.order));
        } else {
          setItemsAfterSearch(sortTreeByName(tree, "asc"));
        }

        let filterOrgUnits: string[] = [];
        if (!departments) return;

        if (auth?.isUserDepartmentBound) {
          filterOrgUnits = userOrgUnits;
        }
        const result = getFilter(departments, filterOrgUnits) || [];
        if (result) {
          setFilters(result);
        }
      }
      setIsLoading(false);
    },
    [
      auth?.tenantId,
      departmentsLoaded,
      sortingProps,
      departments,
      auth?.furtherOrgUnitIds,
      auth?.isUserDepartmentBound,
      auth?.orgUnitId,
      countTree,
      filter
    ]
  );

  useEffect(() => {
    if (!auth?.tenantId) {
      return;
    }
    if (!departmentsLoaded) {
      return;
    }
    if (!filter) {
      return;
    }

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.tenantId, departmentsLoaded, filter]);

  const sortItemsCallBack = useCallback(
    (field, order) => {
      setItemsAfterSearch(sortTreeByName(items, order));
      setSortingProps({ order });
    },
    [items, setItemsAfterSearch]
  );

  useEffect(() => {
    setInfo({ title: t("metaViewInfoCardCreateFirstTitle"), text: t("metaViewInfoCardCreateFirstText") });
  }, [setInfo, t]);

  /* SEARCH */
  const [searchText, setSearchText] = useState<string>("");

  const search = useCallback(() => {
    const s = searchText.toLowerCase();
    if (s) {
      const getChildrens = (result: OrgUnitTreeStructure[], item: OrgUnitTreeStructure) => {
        if (item.data.name.toLowerCase().includes(s)) {
          const nodes = item.children?.reduce(getChildrens, []);
          result.push({ ...item, children: nodes });
          return result;
        }
        if (Array.isArray(item.children)) {
          const nodes = item.children.reduce(getChildrens, []);
          if (nodes.length) result.push({ ...item, children: nodes });
        }
        return result;
      };
      const itemsAfterSearch = items.reduce(getChildrens, []);
      setItemsAfterSearch(itemsAfterSearch);
    } else if (!isEqual(items, itemsAfterSearch)) {
      setItemsAfterSearch(items);
    }
  }, [items, itemsAfterSearch, searchText]);

  const debouncedOnSearch = useMemo(() => debounce(search, 1000), [search]);
  const onChangeSearchText = useCallback(event => {
    setSearchText(event.target.value);
  }, []);
  const clearSearch = useCallback(() => setSearchText(""), []);
  const searchEl = useMemo(
    () => (
      <Box flex={1}>
        <TextField
          style={{ width: "240px" }}
          id="search-input"
          variant="outlined"
          value={searchText}
          onChange={onChangeSearchText}
          placeholder={t("common:search")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="disabled" />
              </InputAdornment>
            ),
            endAdornment: searchText && (
              <InputAdornment position="end">
                <Tooltip title={t("clear")}>
                  <IconButton onClick={clearSearch} size="small">
                    <Close />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
      </Box>
    ),
    [clearSearch, onChangeSearchText, searchText, t]
  );
  useEffect(() => {
    debouncedOnSearch();
  }, [debouncedOnSearch, searchText]);

  const onCreateCallback = useCallback(
    async (parent: OrgUnitTreeStructure, name: string) => {
      const data = {
        name: name.trim(),
        parentId: parent.id
      };
      await addOrganisationalUnit({ data });
      await reloadDepartments();
      fetch(true);
    },
    [fetch, reloadDepartments]
  );

  const onRenameCallback = useCallback(
    async (name: string, organisationalUnitId: string) => {
      await editNameOfOrganisationalUnit({
        name: name.trim(),
        docId: organisationalUnitId
      });
      await reloadDepartments();
      await fetch(true);
    },
    [fetch, reloadDepartments]
  );

  const onDeleteCallback = useCallback(
    async (organisationalUnitId: string) => {
      const orgUnitAndChildrens = getDepartmentRecursively(organisationalUnitId).map(id => id.id);

      const check: COLLECTION_TYPES | null = await deleteOrganisationalUnits({
        docIds: orgUnitAndChildrens
      });

      if (check === COLLECTIONS.FILE_STORAGE) {
        setOrgUnitDependenciesDialogMessage(t("organisationManagement:orgUnitDeleteBlockMessage"));
      } else {
        await reloadDepartments();
        fetch(true);
      }
    },
    [getDepartmentRecursively, t, reloadDepartments, fetch]
  );

  const itemsNumberEl = useMemo(
    () => (
      <Tooltip title={countText}>
        <Typography noWrap>{countText}</Typography>
      </Tooltip>
    ),
    [countText]
  );

  const sortEl = useMemo(
    () => (
      <React.Fragment>
        <Sorter sortings={sortings} sortItemsCallBack={sortItemsCallBack} />
        <Box mr={1} />
      </React.Fragment>
    ),
    [sortItemsCallBack]
  );
  const onFilterCallback = useCallback(filters => {
    setFilter({ ...filters });
  }, []);
  const onResetCallback = useCallback(() => {
    setFilter({});
  }, []);

  const filterEl = useMemo(
    () => (
      <React.Fragment>
        <OverviewFilter filters={filters} filter={filter} onFilter={onFilterCallback} onReset={onResetCallback} />
        <Box mr={1} />
      </React.Fragment>
    ),
    [filters, onFilterCallback, onResetCallback, filter]
  );

  const boxStyle = useMemo(() => ({ height: "100%", overflow: "auto" }), []);
  const docViewContent = (
    <Box style={boxStyle}>
      <AccountNotice mt={2} mb={2} mr={2} ml={2} />
      <Box px={6} py={4} pb={0}>
        <Typography variant="h1">{t("sidebar:organization_management")}</Typography>
      </Box>
      <Box className={cls.header} mb={1} px={6} py={4}>
        <Box display="flex" alignItems={"center"}>
          <Box width={240} mr={4}>
            {searchEl}
          </Box>
          <Box mr={2}>{itemsNumberEl}</Box>
          <Box mr={2}>{sortEl}</Box>
          {filterEl}
        </Box>
      </Box>
      <Box mb={2} />
      {isLoading ? (
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <OrganizationTree
          items={itemsAfterSearch}
          searchText={searchText}
          onRename={onRenameCallback}
          onDelete={onDeleteCallback}
          onAddChild={onCreateCallback}
        />
      )}
      <OrgUnitDependenciesDialog
        openModal={Boolean(orgUnitDependenciesDialogMessage)}
        message={orgUnitDependenciesDialogMessage || ""}
        onCLose={closeOrgUnitDependenciesDialog}
      />
    </Box>
  );

  return (
    <DocMetaView metaViewContent={<MetaView translationKey={"attachmentsOverviewMetaView"} />}>
      {docViewContent}
    </DocMetaView>
  );
}
