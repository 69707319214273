import { useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../app/handlers/authentication/authentication-context";
import { getAllRisks } from "../app/handlers/risksHandler";

export const useUserRisks = () => {
  const [risksLoaded, setRisksLoaded] = useState(false);
  const [risks, setRisks] = useState([]);

  const { auth } = useAuthentication();

  const reloadRisks = useCallback(async () => {
    if (!auth?.uid) {
      return;
    }

    const risks = await getAllRisks();
    setRisks(risks);
    setRisksLoaded(true);
  }, [auth?.uid]);

  useEffect(() => {
    reloadRisks();
  }, [reloadRisks]);

  return { risksLoaded, risks, reloadRisks };
};
