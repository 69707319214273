import overviewBaseController, {
  OverviewController,
  OverviewNewItem,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { COLLECTIONS } from "app/collections";
import { AxiosInstance } from "axios";
import { useUserAndTenantData } from "../../handlers/userAndTenant/user-tenant-context";
import i18n from "../../i18n";
import { getDataLocationConnectedToExternalRecipient } from "../shared/Filters/filters";

const DataLocationOverviewController = (
  axiosInstance: AxiosInstance,
  options: {
    readonly externalRecipientId?: string;
    readonly dataLocationIds?: string;
  }
): OverviewController => {
  // this class should not use states, but this one works since it's only a method where updates is irrelevant
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();

  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.DATA_LOCATIONS, item => ({
    ...item,
    title: i18n.t(`lists_data_locations:${item.title}`, item.title)
  }));

  const sortings = [
    {
      field: "title",
      type: "asc",
      label: i18n.t("filter_criteria:aToZ")
    },
    {
      field: "title",
      type: "desc",
      label: i18n.t("filter_criteria:zToA")
    },
    {
      field: "createdAt",
      type: "desc",
      label: i18n.t("filter_criteria:newFirst")
    },
    {
      field: "createdAt",
      type: "asc",
      label: i18n.t("filter_criteria:oldFirst")
    }
  ];

  const filters = [getDataLocationConnectedToExternalRecipient()];

  const getOverviewPath = () => {
    if (options?.externalRecipientId) {
      return `/external-recipients/${options.externalRecipientId}/data-locations/overview`;
    }
    return "/data-locations/overview";
  };

  const params: { dataLocationIds: null | string[] } = {
    dataLocationIds: null
  };

  if (Array.isArray(options.dataLocationIds)) {
    params.dataLocationIds = options.dataLocationIds.length === 0 ? ["none"] : options.dataLocationIds;
  }

  const getOverview = async (setup: OverviewSetup) => {
    const overview = await baseController.getOverview(setup, getOverviewPath(), { params });
    if (!overview) {
      return null;
    }
    return {
      ...overview,
      items: overview?.items || [],
      sortings: sortings,
      filters: filters
    };
  };

  const addItem = async (data: OverviewNewItem) => {
    const response = await baseController.addItem(
      {
        nameKey: data?.title || createOverviewItemDefaultName(COLLECTIONS.DATA_LOCATIONS),
        externalRecipientId: options.externalRecipientId
      },
      "/data-locations"
    );
    await addToSeenItemsOfUserHook(COLLECTIONS.DATA_LOCATIONS, response.headers["x-resource-id"]);
    return response;
  };

  const goToItem = async () => {
    // do nothing
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    await addItem(data);
  };

  const patchItem = async (id: string, data: object) => {
    return await baseController.patchItem(
      id,
      {
        nameKey: "title" in data && data.title
      },
      `/data-locations/${id}`
    );
  };

  const deleteItem = async (id: string) => {
    return await baseController.deleteItem(id, `/data-locations/${id}`);
  };

  const deleteItems = async (ids: string[]) => {
    await Promise.all(ids.map(deleteItem));
  };

  const markAllAsRead = async () => {
    await baseController?.markAllAsRead?.("/data-locations");
  };

  const validateItem = (data: object) => {
    const title = ("title" in data && (data.title as string)) || "";
    if (!title) {
      return "Invalid";
    }
    return null;
  };

  return {
    ...baseController,
    getOverview,
    addItem,
    goToItem,
    addItemAndGo,
    patchItem,
    deleteItem,
    deleteItems,
    markAllAsRead,
    validateItem
  };
};

export default DataLocationOverviewController;
