import AccordionMultiField from "../../../../components/AccordionMultiField/AccordionMultiField";
import { Box, CircularProgress, Grid, makeStyles, Tooltip } from "@material-ui/core";
import TextEditor from "../utils/TextEditor";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiskRatingAlert } from "../../risks/assessments/RiskRating";
import {
  awaitRiskVersion,
  copyRiskAsProcessSpecific,
  createRisk,
  getAllRisks,
  risksAvailableForProcessingActivities
} from "../../../handlers/risksHandler";
import MultiAutocomplete from "../../../../components/MultiAutocomplete/MultiAutocomplete";
import Button from "@material-ui/core/Button";
import { v4 } from "uuid";
import uniq from "lodash-es/uniq";
import isEqual from "lodash-es/isEqual";
import { useErrorSnackbar } from "../../../../hook/errorSnackbar";
import { RiskDialog } from "../../risks/RiskDialog";
import EditIcon from "@material-ui/icons/Edit";
import { WindowFocus } from "../../../router/router-filters";
import { tDeletedEntry } from "../../../handlers/dataTypeTranslatorHandler";
import Question from "components/Question/Question";
import { useUserProcesses } from "../../../../hook/useUserProcesses";

const useStyles = makeStyles(theme => ({
  accordion: {
    border: "1px solid " + theme.palette.grey[400],
    borderRadius: "5px"
  },
  loading: {
    margin: "0 0 16px 0"
  }
}));

const RiskIdAccordion = ({
  id,
  riskId,
  risks,
  onChangeCallback,
  onFocusCallback,
  onDeleteCallback,
  onEditCallback,
  onCreateCallback,
  onCopyCallback,
  disabled
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { catchAsSnackbar } = useErrorSnackbar();

  const [initiallyExpanded] = useState(!riskId);

  const [isLoading, setIsLoading] = useState(false);
  const [risk, setRisk] = useState(null);
  useEffect(() => {
    const risk = risks.find(risk => risk.id === riskId);
    setRisk(risk || null);
    setIsLoading(false);
  }, [riskId, risks]);

  const editRisk = useCallback(async () => {
    try {
      setIsLoading(true);
      await onEditCallback?.({ id, riskId });
    } catch (error) {
      setIsLoading(false);
      catchAsSnackbar("failed to edit risk")(error);
    }
  }, [id, onEditCallback, riskId, catchAsSnackbar]);

  const onFocus = useCallback(() => {
    onFocusCallback?.({ id, riskId });
  }, [id, onFocusCallback, riskId]);

  const onDelete = useCallback(() => {
    onDeleteCallback?.({ id, riskId });
  }, [id, onDeleteCallback, riskId]);

  const onChange = useCallback(
    newRiskId => {
      onChangeCallback?.({ id, riskId: newRiskId });
    },
    [onChangeCallback, id]
  );

  const copyRisk = useCallback(async () => {
    try {
      setIsLoading(true);
      await onCopyCallback?.({ id, riskId });
    } catch (error) {
      setIsLoading(false);
      catchAsSnackbar("failed to copy risk")(error);
    }
  }, [id, onCopyCallback, riskId, catchAsSnackbar]);

  const [selectableRiskIds, setSelectableRiskIds] = useState([]);
  useEffect(() => {
    setSelectableRiskIds(risks.map(risk => risk.id));
  }, [risks]);

  const getRiskName = useCallback(
    riskId => {
      const risk = risks.find(risk => risk.id === riskId);
      if (!risk?.title) {
        return riskId;
      }

      return risk.title;
    },
    [risks]
  );

  const updateRiskOption = useCallback(
    async options => {
      const newlyAddedRiskOption = options[options.length - 1];

      try {
        setIsLoading(true);
        await onCreateCallback?.({ id, title: newlyAddedRiskOption });
      } catch (error) {
        setIsLoading(false);
        catchAsSnackbar("failed to create new risk")(error);
      }
    },
    [onCreateCallback, id, catchAsSnackbar]
  );

  const editButtons = useMemo(() => {
    if (!risk?.type) {
      return <></>;
    }

    const normalEditButton = (
      <Tooltip title={t("dpia_four_four_page:edit_process_risk_hint")}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<EditIcon />}
          onClick={editRisk}
          disabled={isLoading || disabled}
        >
          {t("dpia_four_four_page:edit_process_risk")}
        </Button>
      </Tooltip>
    );
    const normalEditButtonButWithEditGeneralText = (
      <Tooltip title={t("dpia_four_four_page:edit_general_risk_hint")}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<EditIcon />}
          onClick={editRisk}
          disabled={isLoading || disabled}
        >
          {t("dpia_four_four_page:edit_general_risk")}
        </Button>
      </Tooltip>
    );
    const copyAsProcessSpecificEditButton = (
      <Tooltip title={t("dpia_four_four_page:copy_as_process_risk_hint")}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<EditIcon />}
          onClick={copyRisk}
          disabled={isLoading || disabled}
        >
          {t("dpia_four_four_page:copy_as_process_risk")}
        </Button>
      </Tooltip>
    );

    return (
      <Grid container spacing={1}>
        {risk.type === "general" && (
          <>
            <Grid item>{copyAsProcessSpecificEditButton}</Grid>
            <Grid item>{normalEditButtonButWithEditGeneralText}</Grid>
          </>
        )}
        {risk.type === "processing-activity" && <Grid item>{normalEditButton}</Grid>}
      </Grid>
    );
  }, [t, editRisk, risk?.type, isLoading, copyRisk, disabled]);

  const [riskName, setRiskName] = useState("");
  useEffect(() => {
    if (!risk?.title) {
      setRiskName(riskId ? tDeletedEntry({ t }) : ""); // if risk id is not null, show deleted text
      return;
    }

    setRiskName(risk.title);
  }, [risk?.title, risk?.type, t, riskId]);

  const [multiAutoCompleteInput, setMultiAutoCompleteInput] = useState("");
  const onMultiAutoCompleteInputChange = useCallback((event, value) => {
    setMultiAutoCompleteInput(value);
  }, []);
  useEffect(() => {
    if (riskName === tDeletedEntry({ t })) {
      setMultiAutoCompleteInput("");
      return;
    }

    let nameWithType = riskName;
    if (risk?.type === "general") {
      nameWithType = `${t("risks_overview:general")}: ${riskName}`;
    }

    setMultiAutoCompleteInput(nameWithType);
  }, [riskName, t, risk?.type]);

  const getRiskGroup = useCallback(
    riskId => {
      const risk = risks.find(risk => risk.id === riskId);
      if (risk?.type === "general") {
        return t("risks_overview:generalRisks");
      }

      return t("risks_overview:processRisks");
    },
    [risks, t]
  );

  return (
    <AccordionMultiField
      id={id}
      getStyle={classes.accordion}
      index={id}
      key={id}
      onFocus={onFocus}
      isNewMultiFiled={initiallyExpanded}
      cancelButtonText={t("questionnaires:cancel")}
      saveButtonText={t("questionnaires:save")}
      deleteButtonText={t("dpia_four_four_page:delete_risk")}
      deleteButtonHint={t("dpia_four_four_page:delete_risk_hint")}
      deleteMultiField={onDelete}
      placeholder={t("dpia_four_four_page:add_risk_placeholder")}
      title={riskName}
      hasCancelAndSave={false}
      additionalLeftButton={editButtons}
      loading={isLoading}
      disableButton={disabled}
    >
      <Grid container spacing={3}>
        {isLoading && (
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isLoading && (
          <Grid item xs={12}>
            <MultiAutocomplete
              label={t("dpia_four_four_page:risk_label")}
              hasMultiSelect={false}
              options={selectableRiskIds}
              selected={riskId}
              updateSelected={onChange}
              getOptionLabel={getRiskName}
              groupBy={getRiskGroup}
              placeholder={""}
              id={id}
              disabled={disabled}
              updateOptions={updateRiskOption}
              addText={`${t("risks_overview:create_new_process_specific_risk")}: `}
              inputValue={multiAutoCompleteInput}
              onInputChange={onMultiAutoCompleteInputChange}
            />
          </Grid>
        )}
        {!isLoading && risk !== null && (
          <Grid item xs={12} key={`${risk.id}-description`}>
            <TextEditor
              testId={`risk-${risk.id}-description`}
              title={t("risk_general_page:description")}
              inputValue={risk.description || ""}
              disabled={true}
            />
          </Grid>
        )}
        {!isLoading && risk !== null && (
          <Grid item xs={12} key={`${risk.id}-rating`}>
            <RiskRatingAlert riskRating={risk.rating} />
          </Grid>
        )}
      </Grid>
    </AccordionMultiField>
  );
};

export const RiskIdsAccordion = ({ initialRiskIds, title, onRiskIdsChange, processingActivityId, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { catchAsSnackbar } = useErrorSnackbar();

  const [pageLoaded, setPageLoaded] = useState(false);
  const { processes, processesLoaded } = useUserProcesses();
  const [risks, setRisks] = useState([]);
  const [dataLocationsIds, setDataLocationsIds] = useState([]);

  const reloadRisks = useCallback(async () => {
    const allRisks = await getAllRisks();
    setRisks(risksAvailableForProcessingActivities(allRisks, processes, processingActivityId));
    setDataLocationsIds(processes.find(pa => pa.id === processingActivityId)?.allDataLocationIds || []);
    setPageLoaded(true);
  }, [processes, processingActivityId]);

  const reloadIfProcessLoaded = useCallback(() => {
    if (!processesLoaded) {
      return;
    }

    reloadRisks().catch(catchAsSnackbar("failed to load risks"));
  }, [reloadRisks, catchAsSnackbar, processesLoaded]);

  useEffect(() => {
    reloadIfProcessLoaded();
  }, [reloadIfProcessLoaded]);

  const [riskAccordions, setRiskAccordions] = useState(
    initialRiskIds.map(riskId => ({
      id: riskId,
      riskId: riskId
    }))
  );

  const addNewAccordion = useCallback(() => {
    setRiskAccordions(riskAccordions => [...riskAccordions, { id: v4(), riskId: null, isCreatedNew: true }]);
  }, []);

  const onRiskIdChange = useCallback(({ id, riskId }) => {
    setRiskAccordions(riskAccordions =>
      riskAccordions.map(riskAccordion => (riskAccordion.id === id ? { id, riskId } : riskAccordion))
    );
  }, []);

  const onRiskIdDeleted = useCallback(({ id }) => {
    setRiskAccordions(riskAccordions => riskAccordions.filter(riskAccordion => riskAccordion.id !== id));
  }, []);

  const onRiskCreate = useCallback(
    async ({ id, title }) => {
      const riskId = await createRisk(title, {
        type: "processing-activity",
        privacyRelevant: true,
        dataLocationIds: dataLocationsIds
      });
      await awaitRiskVersion(riskId);
      await reloadRisks();
      setRiskAccordions(riskAccordions =>
        riskAccordions.map(riskAccordion => (riskAccordion.id === id ? { id, riskId } : riskAccordion))
      );
      setEditedRiskId(riskId);
    },
    [reloadRisks, dataLocationsIds]
  );

  const onRiskCopy = useCallback(
    async ({ id, riskId }) => {
      const copiedRisk = await copyRiskAsProcessSpecific(riskId);
      await reloadRisks();
      setRiskAccordions(riskAccordions =>
        riskAccordions.map(riskAccordion => (riskAccordion.id === id ? { id, riskId: copiedRisk.id } : riskAccordion))
      );
      setEditedRiskId(copiedRisk.id);
    },
    [reloadRisks]
  );

  const [editedRiskId, setEditedRiskId] = useState("");
  const onRiskEdit = useCallback(({ id, riskId }) => {
    setEditedRiskId(riskId);
  }, []);
  const onRiskEditClose = useCallback(async () => {
    setEditedRiskId("");
    await reloadRisks();
  }, [reloadRisks]);

  const [currentRiskIds, setCurrentRiskIds] = useState(initialRiskIds || []);
  useEffect(() => {
    if (!pageLoaded) {
      return;
    }

    const uniqRiskIds = uniq(riskAccordions.map(riskAccordion => riskAccordion.riskId).filter(riskId => riskId));
    if (!isEqual(uniqRiskIds, currentRiskIds)) {
      setCurrentRiskIds(uniqRiskIds);
      onRiskIdsChange(uniqRiskIds);
    }
  }, [riskAccordions, currentRiskIds, pageLoaded, onRiskIdsChange]);

  return (
    <>
      <WindowFocus onFocus={reloadIfProcessLoaded} />
      {!pageLoaded && (
        <Grid item xs={12}>
          <CircularProgress className={classes.loading} />
        </Grid>
      )}
      {pageLoaded &&
        riskAccordions.map(riskAccordion => (
          <Question
            questionId={"riskId_" + riskAccordion.id}
            key={riskAccordion.id}
            questionName={title}
            disabled={disabled}
          >
            <RiskIdAccordion
              id={riskAccordion.id}
              riskId={riskAccordion.riskId}
              risks={risks}
              onChangeCallback={onRiskIdChange}
              onDeleteCallback={onRiskIdDeleted}
              onCreateCallback={onRiskCreate}
              onEditCallback={onRiskEdit}
              onCopyCallback={onRiskCopy}
              disabled={disabled}
            />
          </Question>
        ))}
      {editedRiskId && <RiskDialog riskId={editedRiskId} open={!!editedRiskId} onClose={onRiskEditClose} />}
      <Box mt={3}>
        <Button variant="contained" color="primary" onClick={addNewAccordion} disabled={disabled}>
          {t("dpia_four_four_page:add_risk")}
        </Button>
      </Box>
    </>
  );
};
