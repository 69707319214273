import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import OrgUnitTree from "app/pages/shared/OrgUnitPickerModal/OrgUnitTree";
import { Box } from "@material-ui/core";
import TextBodySecondary from "components/TextBodySecondary/TextBodySecondary";
import { useUserDepartments } from "app/contexts/department-context";
import { orgUnitAndChildrens } from "app/handlers/orgUnitHandler";
import { Department } from "app/handlers/departmentHandler";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";

interface OrgUnitPickerModalProps {
  readonly open: boolean;
  readonly selectedDepartmentIds: string[];
  readonly authorizedOrgUnitIds?: string[];
  readonly availableOrgUnitIds?: string[];
  readonly linkingOrgUnitsModalHeading?: string;
  readonly linkingOrgUnitsModalSubHeading?: string;
  readonly onClose: () => void;
  readonly onConfirm: (departmentIds: string[]) => void;
}

export default function OrgUnitPickerModal({
  open,
  selectedDepartmentIds,
  authorizedOrgUnitIds,
  availableOrgUnitIds,
  linkingOrgUnitsModalHeading,
  linkingOrgUnitsModalSubHeading,
  onClose,
  onConfirm
}: OrgUnitPickerModalProps) {
  const { t } = useTranslation("organisation");

  const { departments } = useUserDepartments();

  /**
   * Visible departments in Tree
   */
  const userDepartments = useMemo(() => {
    // if availableOrgUnitIds is set
    // filter departments by availableOrgUnitIds
    if (departments && authorizedOrgUnitIds && !authorizedOrgUnitIds.includes("*")) {
      return (authorizedOrgUnitIds || []).reduce<Department[]>((acc, next) => {
        return [...acc, ...orgUnitAndChildrens(next, departments)];
      }, []);
    }
    // if availableOrgUnitIds NOT set
    // return all departments
    else if (departments && (!authorizedOrgUnitIds || authorizedOrgUnitIds?.includes("*"))) {
      return departments;
    } else return [];
  }, [departments, authorizedOrgUnitIds]);

  /**
   * Non disabled departments in Tree
   */
  const availableDepartments = useMemo(() => {
    if (availableOrgUnitIds && !availableOrgUnitIds.includes("*")) {
      return [
        ...new Set(
          availableOrgUnitIds.reduce<Department[]>((acc, next) => {
            return [...acc, ...orgUnitAndChildrens(next, userDepartments)];
          }, [])
        )
      ];
    } else return userDepartments;
  }, [availableOrgUnitIds, userDepartments]);

  const filterCheckedByAvailable = useCallback(
    (departmentId: string) => availableDepartments.some(({ id }) => id === "*" || departmentId === id),
    [availableDepartments]
  );

  const [checkedDepartmentIds, setCheckedDepartmentIds] = useState<string[]>([]);
  useEffect(() => {
    setCheckedDepartmentIds(
      selectedDepartmentIds.includes("*") ? userDepartments.map(department => department.id) : selectedDepartmentIds
    );
  }, [selectedDepartmentIds, userDepartments]);

  const confirmSelection = useCallback(() => {
    const filteredCheckedDepartmentIds = checkedDepartmentIds.filter(filterCheckedByAvailable);
    onConfirm(filteredCheckedDepartmentIds);
    onClose();
  }, [checkedDepartmentIds, filterCheckedByAvailable, onConfirm, onClose]);

  const buttons = useMemo(
    () =>
      [
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onClose
        },
        {
          confirmButton: true,
          title: t("common:confirm"),
          variant: "contained",
          color: "primary",
          size: "medium",
          disabled: checkedDepartmentIds.filter(filterCheckedByAvailable).length === 0,
          onClick: confirmSelection
        }
      ] as ConfirmationModalButtonProps[],
    [checkedDepartmentIds, confirmSelection, filterCheckedByAvailable, onClose, t]
  );

  const stopEvenPopagation = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);

  const modalBody = useMemo(
    () => (
      <Box p={2} height={400} overflow={"auto"} onClick={stopEvenPopagation}>
        <TextBodySecondary text={linkingOrgUnitsModalSubHeading || t("resources_overview:linkingModalSubHeading")} />
        <OrgUnitTree
          allDepartments={userDepartments}
          availableDepartments={availableDepartments}
          checkedDepartmentIds={checkedDepartmentIds}
          onCheckedChanged={setCheckedDepartmentIds}
        />
      </Box>
    ),
    [stopEvenPopagation, linkingOrgUnitsModalSubHeading, t, userDepartments, availableDepartments, checkedDepartmentIds]
  );

  return (
    <ConfirmationModal
      variant={"info"}
      modalBody={modalBody}
      modalOpen={open}
      modalTitle={linkingOrgUnitsModalHeading || t("resources_overview:linkingModalHeading")}
      onClose={onClose}
      buttons={buttons}
      modalText={""}
    />
  );
}
