import React, { useCallback, useEffect, useMemo, useState } from "react";

import HeatMap from "../../../components/HeatMap/HeatMap";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { getAllRisks, getAssessmentWithHighestRating } from "../../handlers/risksHandler";
import { useAssessmentResources } from "./assessments/RiskAssessment";
import CardWithTextButton from "../../../components/CardWithButton/CardWithTextButton";
import { CircleWithNumber } from "../../../components/CircleWithNumber/CircleWithNumber";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useUserDepartments } from "app/contexts/department-context";
import Overview from "components/Overview/Overview";
import { COLLECTIONS } from "app/collections";

export const RiskMatrixPage = () => {
  const { auth } = useAuthentication();
  const { t } = useTranslation("risks_overview");
  const [mapSize, setMapSize] = useState(400);
  const [risks, setRisks] = useState([]);
  const [riskMarkersToDisplay, setRiskMarkersToDisplay] = useState<
    {
      readonly xPosition: number;
      readonly yPosition: number;
      readonly items: any[];
      readonly objectToRender: JSX.Element;
    }[]
  >([]);
  const [selectedCircle, setSelectedCircle] = useState<{
    readonly xPosition: number;
    readonly yPosition: number;
  } | null>(null);
  const { departmentsLoaded, isPartOfUserDepartments } = useUserDepartments();
  const { occurrenceIdToValue, damageExtendIdToValue } = useAssessmentResources();
  const [riskIds, setRiskIds] = useState<string[]>([]);
  const [updateId, setUpdateId] = useState<number>(Date.now());
  const getRisks = useCallback(async () => {
    const allRisks = await getAllRisks();
    let filteredRisks = allRisks;
    if (auth?.isUserDepartmentBound) {
      filteredRisks = allRisks.filter((risk: any) =>
        risk.orgUnitId ? isPartOfUserDepartments(...[risk.orgUnitId, ...risk.furtherAffectedOrgUnitIds]) : true
      );
    }
    setRisks(filteredRisks);
  }, [auth?.isUserDepartmentBound, isPartOfUserDepartments]);

  const onCircleClick = useCallback((riskIds, x, y) => {
    setRiskIds(riskIds);
    setSelectedCircle({ yPosition: y, xPosition: x });
    setUpdateId(Date.now());
  }, []);

  const getCircleSVG = useCallback(
    (risks: any[]) => {
      let selected = false;
      if (
        risks[0].damageExtendValue === selectedCircle?.xPosition &&
        risks[0].occurrenceValue === selectedCircle?.yPosition
      ) {
        selected = true;
      }

      return (
        <CircleWithNumber risks={risks} onClick={onCircleClick} sizeSVG={50} radiusCircle={20} selected={selected} />
      );
    },
    [onCircleClick, selectedCircle?.xPosition, selectedCircle?.yPosition]
  );

  const getAssessmentRiskItemsPerCoordinate = useCallback(
    (finalAssessmentRiskItems: any[]) => {
      const coordinateObjects = [];
      for (let y = 0; y <= 3; y++) {
        for (let x = 0; x <= 3; x++) {
          const itemsOnCoordinate = finalAssessmentRiskItems
            .map(item => {
              if (item.occurrenceValue === y && item.damageExtendValue === x) {
                return item;
              }
              return null;
            })
            .filter(item => item);
          if (itemsOnCoordinate?.length > 0) {
            coordinateObjects.push({
              xPosition: x,
              yPosition: y,
              items: itemsOnCoordinate,
              objectToRender: getCircleSVG(itemsOnCoordinate)
            });
          }
        }
      }
      setRiskMarkersToDisplay(coordinateObjects);
    },
    [getCircleSVG]
  );

  const updateRisksToDisplay = useCallback(() => {
    const finalAssessmentRiskItems = risks
      .map((risk: any) => {
        if (risk.assessments.second.individualAssessmentEnabled === true) {
          const highestRatingSecondAssessment = getAssessmentWithHighestRating(risk, "second");
          if (!highestRatingSecondAssessment) {
            return null;
          }
          return {
            id: risk.id,
            title: risk.title,
            rating: risk.rating,
            occurrenceValue: occurrenceIdToValue(highestRatingSecondAssessment.occurrenceId) - 1,
            damageExtendValue: damageExtendIdToValue(highestRatingSecondAssessment.damageExtendId) - 1
          };
        }
        if (risk.assessments.second.individualAssessmentEnabled === false) {
          const combinedAssessment = risk.assessments.second.combinedAssessment;
          if (combinedAssessment) {
            return {
              id: risk.id,
              title: risk.title,
              rating: risk.rating,
              occurrenceValue: occurrenceIdToValue(combinedAssessment.occurrenceId) - 1,
              damageExtendValue: damageExtendIdToValue(combinedAssessment.damageExtendId) - 1
            };
          }
        }
        return null;
      })
      .filter(item => item);
    getAssessmentRiskItemsPerCoordinate(finalAssessmentRiskItems);
  }, [damageExtendIdToValue, getAssessmentRiskItemsPerCoordinate, occurrenceIdToValue, risks]);

  const parentRef = React.useRef<HTMLInputElement>(null);
  const estimateMapSize = useCallback(() => {
    if (parentRef.current) {
      if (parentRef.current.clientWidth >= 1200) {
        setMapSize(650);
        return;
      }
      if (parentRef.current.clientWidth >= 1000) {
        setMapSize(600);
        return;
      }
      if (parentRef.current.clientWidth >= 800) {
        setMapSize(550);
        return;
      }
      if (parentRef.current.clientWidth >= 600) {
        setMapSize(500);
        return;
      }
      if (parentRef.current.clientWidth >= 500) {
        setMapSize(400);
        return;
      }
      if (parentRef.current.clientWidth < 400) {
        setMapSize(250);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", estimateMapSize);
  }, [estimateMapSize]);

  useEffect(() => {
    getRisks();
  }, [departmentsLoaded, getRisks]);

  useEffect(() => {
    if (risks) {
      updateRisksToDisplay();
    }
  }, [risks, selectedCircle, updateRisksToDisplay]);

  const collectionParams = useMemo(() => {
    return { riskIds: riskIds || [], openInNewTab: true };
  }, [riskIds]);

  return (
    <>
      <div ref={parentRef}>
        <HeatMap
          mapSize={mapSize}
          numberSquaresPerRow={4}
          labelYAxis={t("risk_assessment:occurrence")}
          labelXAxis={t("risk_assessment:damageExtend")}
          markersToDisplay={riskMarkersToDisplay}
        />
      </div>
      <Box mt={4}>
        {riskIds.length > 0 && (
          <Overview key={updateId} collection={COLLECTIONS.RISK_MATRIX} collectionParams={collectionParams} />
        )}
        {riskIds.length === 0 && (
          <CardWithTextButton
            text={t("matrixInfo")}
            buttonText={undefined}
            onClick={undefined}
            colorTheme={undefined}
          />
        )}
      </Box>
    </>
  );
};
