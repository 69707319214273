import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import overviewBaseController, {
  OverviewController,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { COLLECTIONS } from "app/collections";
import { useParams } from "react-router-dom";
import {
  getDataStorageLocationFilter,
  getLabelsFilter,
  getOrganizationFilter,
  getProcessTaskFilter,
  getRiskLevelFilter,
  getRiskPrivacyRelevantFilter,
  getRiskTreatmentFilter
} from "app/pages/shared/Filters/filters";
import { dataLocationsDecorator } from "../../../../components/Overview/controllers/decorator/dataLocationsDecorator";
import { AxiosInstance } from "axios";
import { resourcesDecorator } from "components/Overview/controllers/decorator/resourcesDecorator";
import { tDeletedEntry } from "app/handlers/dataTypeTranslatorHandler";
import { departmentsDecorator } from "../../../../components/Overview/controllers/decorator/departmentsDecorator";
import { SingleOverviewContext } from "app/contexts/overview-context";
import i18n from "../../../i18n";
import { RiskPages } from "./RiskOverview";

const RiskOverviewController = (
  axiosInstance: AxiosInstance,
  collectionParams: {
    readonly type?: string;
    readonly tomId?: string;
    readonly riskIds?: string;
    readonly openInNewTab?: boolean;
    readonly exportToXLSX: (ids: string[]) => Promise<any>;
  }
): OverviewController => {
  const { page } = useParams();
  const { t } = useTranslation("risks_overview");
  const translateItem = useCallback(
    obj => ({ ...obj, subTitle: obj.subTitle === "deleted" ? tDeletedEntry({ t }) : obj.subTitle }),
    [t]
  );
  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.RISK, translateItem, [
    dataLocationsDecorator,
    resourcesDecorator,
    departmentsDecorator
  ]);

  const { t: tFilter } = useTranslation("filter_criteria");

  const getSortings = useCallback(
    () => [
      {
        field: "title",
        type: "asc",
        label: tFilter("aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: tFilter("zToA")
      },
      {
        field: "createdAt",
        type: "desc",
        label: tFilter("newFirst")
      },
      {
        field: "createdAt",
        type: "asc",
        label: tFilter("oldFirst")
      },
      {
        field: "rating",
        type: "desc",
        label: tFilter("riskLevelHighToLow")
      },
      {
        field: "rating",
        type: "asc",
        label: tFilter("riskLevelLowToHigh")
      }
    ],
    [tFilter]
  );

  const getCurrentPage = () => window.location.pathname.split("/").pop();
  const getUrl = () => {
    const currentPage = getCurrentPage();
    if (currentPage && ["general", "processing-activity"].includes(currentPage)) {
      return `risks?type=${currentPage}`;
    } else {
      return `risks`;
    }
  };

  const getFilters = useCallback(
    data => [
      getOrganizationFilter("orgUnitId", data._departments, t),
      getDataStorageLocationFilter("dataLocationIds", data._dataLocations, t),
      getRiskLevelFilter("riskLevel", t),
      getRiskTreatmentFilter("treatment", t),
      getRiskPrivacyRelevantFilter("privacyRelevant", t),
      getLabelsFilter("labelIds", data._resources, t),
      getProcessTaskFilter("openTasks", t)
    ],
    [t]
  );

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    const currentPage = getCurrentPage();
    if (currentPage === RiskPages.RISK_MATRIX) {
      return {
        items: [],
        allItems: [],
        responseItems: [],
        selectedItem: null,
        moreItemsExist: false
      };
    } else {
      const data = await baseController.getOverview(
        setup,
        getUrl(),
        collectionParams
          ? {
              params: {
                tomId: collectionParams?.tomId,
                riskIds: collectionParams?.riskIds
              },
              headers: { "Accept-Language": i18n.language }
            }
          : { headers: { "Accept-Language": i18n.language } }
      );
      if (!data) {
        return null;
      }

      return {
        ...data,
        filters: getFilters(data),
        sortings: getSortings()
      };
    }
  };

  const goToItem = (id: string) => {
    if (collectionParams && collectionParams.openInNewTab) {
      window.open(`/risks/${id}/general`);
    } else baseController.goToItem(`/risks/${id}/general`);
  };

  const addItem = async (data: OverviewNewItem) => {
    const currentPage = getCurrentPage();
    return await baseController.addItem(
      {
        title: data?.title || createOverviewItemDefaultName("risks"),
        type: currentPage
      },
      "risks"
    );
  };

  const patchItem = async (id: string, data: object) => {
    const title = ("title" in data && (data.title as string)) || "";
    const item = baseController.getById(id);
    const payload = {
      title: title,
      version: item?.version
    };
    return await baseController.patchItem(id, payload, `/risks/${id}`);
  };

  const deleteItem = async (id: string) => {
    const item = baseController.getById(id);
    return await baseController.deleteItem(id, `/risks/${id}?version=${item?.version}`);
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    const currentPage = getCurrentPage();
    const response = await baseController.addItem(
      {
        title: data?.title || createOverviewItemDefaultName("risks"),
        type: currentPage
      },
      "risks"
    );
    goToItem(response.headers["x-resource-id"] || "");
  };

  const exportItems = async (format: string, ids: string[]) => {
    await collectionParams.exportToXLSX(ids);
  };
  const exportAllItems = async (format: string, setup: SingleOverviewContext) => {
    const data = await baseController.getOverview(setup);
    if (data) {
      const ids = data.allItems.map(({ id }) => id);
      await collectionParams.exportToXLSX(ids);
    }
  };

  const addItemsFromTemplates = async (data: { templateIds: string[] }) => {
    const currentPage = getCurrentPage();
    return axiosInstance.post(
      `/risks/templates`,
      {
        templateIds: data.templateIds,
        type: currentPage
      },
      {
        headers: { "Accept-Language": i18n.language },
        // create from template can take very long, we should not restrict it
        timeout: 0
      }
    );
  };

  return {
    ...baseController,
    goToItem,
    addItem,
    patchItem,
    deleteItem,
    addItemAndGo,
    getOverview,
    exportItems,
    exportAllItems,
    addItemsFromTemplates
  };
};

export default RiskOverviewController;
