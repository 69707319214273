import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";

const conditionalPermission = [
  "pa_delete_org",
  "pa_read_risk_org",
  "pa_write_risk_org",
  "pa_read_legalbasis_org",
  "pa_write_legalbasis_org"
];

export type PermissionCheckboxesProps = {
  readonly name: string;
  readonly permissions: string[];
  readonly dependencies?: Record<string, string[]>;
  readonly selectedPermissions: string[];
  readonly updateSelected: (selectedPermissions: string[]) => void;
};

export const PermissionCheckboxes = ({
  name,
  permissions,
  dependencies,
  selectedPermissions,
  updateSelected
}: PermissionCheckboxesProps) => {
  const { t } = useTranslation("manage-user-page");
  const getLabel = useCallback(
    permission => {
      if (
        permission === "asset_write_all" ||
        permission === "asset_write_org" ||
        permission === "er_write_all" ||
        permission === "er_write_org" ||
        permission.startsWith("pa_")
      ) {
        return t(permission);
      }

      return permission === "orgunit_read_all" || permission === "filestoragefolders_write_all"
        ? t("orgunit_read_all")
        : t(permission.slice(permission.indexOf("_") + 1));
    },
    [t]
  );

  const { enqueueSnackbar } = useSnackbar();

  // todo for later: simplify if possible
  const handleCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const permission = event.target.value;
      const checkedPermissionLevel = permission.substring(permission.length - 3);
      const currentlyChecked = selectedPermissions.includes(permission);
      let newSelectedPermissions: string[];
      // user clicks on _all
      if (checkedPermissionLevel === "all") {
        if (currentlyChecked) {
          // if checked uncheck

          newSelectedPermissions = selectedPermissions.filter(selectedPermission => selectedPermission !== permission);
        } else {
          // if unchecked check _all and _org
          const orgPermission = permission.replace("_all", "_org");
          if (permissions.includes(orgPermission)) {
            newSelectedPermissions = [...selectedPermissions, permission, orgPermission];
          } else {
            newSelectedPermissions = [...selectedPermissions, permission];
          }
        }
        // user clicks on _org
      } else {
        const isAllPermissionChecked = selectedPermissions.includes(permission.replace("_org", "_all"));
        // if _all is already selected uncheck both
        if (isAllPermissionChecked) {
          newSelectedPermissions = [...selectedPermissions, permission];
        } else {
          // else if _org is checked uncheck it
          if (currentlyChecked) {
            newSelectedPermissions = selectedPermissions.filter(
              selectedPermission => selectedPermission !== permission
            );
            // if unchecked check _org
          } else {
            const dependentPermissions = dependencies?.[permission] || [];
            const addedPermissions = dependentPermissions.filter(
              dependentPermission => !selectedPermissions.includes(dependentPermission)
            );
            if (addedPermissions.length > 0) {
              enqueueSnackbar(
                t("dependent_permissions", {
                  permissions: addedPermissions.map(dependentPermission => getLabel(dependentPermission)).join(", "),
                  selected: getLabel(permission)
                }),
                {
                  variant: "info",
                  autoHideDuration: 5000
                }
              );
            }
            newSelectedPermissions = [...selectedPermissions, permission, ...dependentPermissions];
          }
        }
      }
      updateSelected(newSelectedPermissions);
    },
    [selectedPermissions, updateSelected, permissions, dependencies, t, getLabel, enqueueSnackbar]
  );

  const selectedPermissionsSet = useMemo(() => new Set(selectedPermissions), [selectedPermissions]);

  const dependentsOfSelectedItems = useMemo(() => {
    const dependents = new Set<string>();
    selectedPermissions.forEach(permission => {
      const permissionDependencies = dependencies?.[permission];
      if (permissionDependencies) {
        permissionDependencies.forEach(dep => dependents.add(dep));
      }
    });
    return dependents;
  }, [dependencies, selectedPermissions]);

  const paWritePermissionIsSelected =
    selectedPermissionsSet.has("pa_write_org") ||
    selectedPermissionsSet.has("pa_write_basic_org") ||
    selectedPermissionsSet.has("pa_write_min_org");

  const paReadPermissionIsSelected =
    selectedPermissionsSet.has("pa_read_org") ||
    selectedPermissionsSet.has("pa_read_basic_org") ||
    selectedPermissionsSet.has("pa_read_min_org");

  const paReadBasisPermissionIsSelected =
    selectedPermissionsSet.has("pa_read_org") || selectedPermissionsSet.has("pa_read_basic_org");

  const paWriteBasisPermissionIsSelected =
    selectedPermissionsSet.has("pa_write_org") || selectedPermissionsSet.has("pa_write_basic_org");

  const bodyPermissionEl = useCallback(
    (permission: string, enabled: boolean) => (
      <Box mb={1} key={"box " + permission}>
        <FormControlLabel
          disabled={!enabled || dependentsOfSelectedItems.has(permission)}
          key={permission}
          control={
            <Checkbox
              checked={selectedPermissionsSet.has(permission)}
              value={permission}
              onChange={handleCheck}
              color="primary"
              name={permission}
              disabled={!enabled || dependentsOfSelectedItems.has(permission)}
            />
          }
          label={getLabel(permission)}
        />
      </Box>
    ),
    [getLabel, handleCheck, selectedPermissionsSet, dependentsOfSelectedItems]
  );

  const disabledDependencySelected = useCallback(
    (permission: string, enabled: boolean) => {
      if (!enabled && selectedPermissionsSet.has(permission)) {
        const newPermission = selectedPermissions.filter(value => value !== permission);
        updateSelected(newPermission);
      }
    },
    [updateSelected, selectedPermissions, selectedPermissionsSet]
  );

  const tooltipEl = useCallback(
    (permission: string) => {
      switch (permission) {
        case "pa_delete_org":
          disabledDependencySelected(permission, paWritePermissionIsSelected);
          return (
            <Tooltip title={!paWritePermissionIsSelected ? t("pa_delete_permission_disabled") : ""} key={""}>
              {bodyPermissionEl(permission, paWritePermissionIsSelected)}
            </Tooltip>
          );
        case "pa_read_legalbasis_org":
          disabledDependencySelected(permission, paReadPermissionIsSelected);
          return (
            <Tooltip title={!paReadPermissionIsSelected ? t("pa_read_permission_disabled") : ""} key={""}>
              {bodyPermissionEl(permission, paReadPermissionIsSelected)}
            </Tooltip>
          );
        case "pa_write_legalbasis_org":
          disabledDependencySelected(permission, paWritePermissionIsSelected);
          return (
            <Tooltip title={!paWritePermissionIsSelected ? t("pa_delete_permission_disabled") : ""} key={""}>
              {bodyPermissionEl(permission, paWritePermissionIsSelected)}
            </Tooltip>
          );
        case "pa_read_risk_org":
          disabledDependencySelected(permission, paReadBasisPermissionIsSelected);
          return (
            <Tooltip title={!paReadBasisPermissionIsSelected ? t("pa_read_risk_permission_disabled") : ""} key={""}>
              {bodyPermissionEl(permission, paReadBasisPermissionIsSelected)}
            </Tooltip>
          );
        case "pa_write_risk_org":
          disabledDependencySelected(permission, paWriteBasisPermissionIsSelected);
          return (
            <Tooltip title={!paWriteBasisPermissionIsSelected ? t("pa_write_risk_permission_disabled") : ""} key={""}>
              {bodyPermissionEl(permission, paWriteBasisPermissionIsSelected)}
            </Tooltip>
          );
        default:
          return (
            <Tooltip title={""} key={""}>
              <Box />
            </Tooltip>
          );
      }
    },
    [
      t,
      bodyPermissionEl,
      paReadBasisPermissionIsSelected,
      paReadPermissionIsSelected,
      paWriteBasisPermissionIsSelected,
      paWritePermissionIsSelected,
      disabledDependencySelected
    ]
  );

  const permissionsEl = permissions.map(permission =>
    conditionalPermission.includes(permission) ? (
      tooltipEl(permission)
    ) : (
      <Box mb={1} key={"box " + permission}>
        <FormControlLabel
          key={permission}
          control={
            <Checkbox
              checked={selectedPermissionsSet.has(permission)}
              value={permission}
              onChange={handleCheck}
              color="primary"
              name={permission}
              disabled={selectedPermissionsSet.has(permission) && dependentsOfSelectedItems.has(permission)}
            />
          }
          label={getLabel(permission)}
        />
      </Box>
    )
  );

  return (
    <Grid item xs={6} key={"grid-item " + name}>
      <Box mt={3}>
        <Box mb={2}>
          <Typography className="pre-wrap" variant="h2">
            {t(name.toLowerCase().replace(" ", "_"))}
          </Typography>
        </Box>
        <div className="list-container">{permissionsEl}</div>
      </Box>
    </Grid>
  );
};
