import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.deeplUrl}/translate`
});

export const getTranslationAndSourceLanguage = async (
  targetLanguage: string,
  textToBeTranslated: string,
  sourceLanguage: string
) => {
  const response = await axiosInstance.get("", {
    params: {
      text: textToBeTranslated,
      // eslint-disable-next-line camelcase
      target_lang: targetLanguage,
      // eslint-disable-next-line camelcase
      source_lang: sourceLanguage,
      // eslint-disable-next-line camelcase
      preserve_formatting: 1
    }
  });
  return response ? response.data : null;
};
