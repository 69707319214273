import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Avatar, Box } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import Link from "@material-ui/core/Link";
import { useNavigate } from "react-router-dom";
import UserDataDisplay from "../../../../components/UserDataDisplay";
import Tooltip from "@material-ui/core/Tooltip";
import { LoggedInUser } from "../../../handlers/authentication/authenticationHandlerInterfaces";

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main
  },
  link: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main
    }
  }
}));

export const UserAvatarSidebar = ({ userData }: { readonly userData: LoggedInUser | null }) => {
  const { t } = useTranslation("sidebar");
  const navigate = useNavigate();
  const classes = useStyles();
  const navigateToAccountSettings = useCallback(() => {
    navigate("/account-settings");
  }, [navigate]);
  const navigateToLogout = useCallback(() => {
    navigate("/logout");
  }, [navigate]);
  const avatarLabel = useMemo(
    () => (userData?.firstName || userData?.email || "").substring(1, 0).toUpperCase(),
    [userData]
  );

  return (
    <Box display="flex" mt={1} width="100%">
      <Box ml={2}>
        <Link underline="none" className={classes.link} onClick={navigateToAccountSettings}>
          <Avatar aria-label="headerIcon" className={classes.avatar}>
            {avatarLabel}
          </Avatar>
        </Link>
      </Box>
      <Box ml={3} mr={1} flex={1} maxWidth={200}>
        <Box>
          <Link underline="none" className={classes.link} onClick={navigateToAccountSettings}>
            <strong>
              <UserDataDisplay userData={userData} ellipsis={true} />
            </strong>
          </Link>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Link underline="none" className={classes.link} onClick={navigateToAccountSettings}>
            {t("account_settings")}
          </Link>
          <Box pr={0.5}>
            <Tooltip title={t("logout")}>
              <IconButton
                data-qa="logout"
                className={`${classes.link}`}
                aria-label="logout"
                size="small"
                onClick={navigateToLogout}
              >
                <PowerSettingsNewIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserAvatarSidebar;
