import React, { useCallback, useEffect, useMemo } from "react";
import { usePathParamByKey } from "../../../../router/router-custom-hooks";
import { ProcessesOverviewReadOnly } from "../../../processes/overview/ProcessesOverviewReadOnly";
import { useMetaView } from "../../../../contexts/meta-view-context";
import { useTranslation } from "react-i18next";
import { useUserDepartments } from "../../../../contexts/department-context";
import DateDisplay from "../../../../../components/DateDisplay";
import { AutomaticUserDataDisplay } from "../../../../../components/UserDataDisplay";
import { getDepartmentName } from "../../../../utils/getFirstDepartmentName";

const ExternalRecipientProcessOverview = () => {
  const { t } = useTranslation("processes_overview");
  const externalRecipientId = usePathParamByKey("id") || "";
  const filter = useMemo(() => ({ externalRecipientIDs: [externalRecipientId] }), [externalRecipientId]);

  const { setInfo, setMeta } = useMetaView();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("processes_tab:enteringInfoCardTitle"),
        text: t("processes_tab:enteringInfoCardText")
      }
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard.entering, setInfo]);

  const showEnterInfo = useCallback(() => {
    setInfo(infoCard.entering);
  }, [infoCard.entering, setInfo]);

  const { departments } = useUserDepartments();

  const onRowOver = useCallback(
    item => {
      setMeta({
        [t("version")]: item.version,
        [t("created")]: (
          <DateDisplay timestamp={item.createdAt ? new Date(item.createdAt) : null} displaySeconds={undefined} />
        ),
        [t("createdBy")]: <AutomaticUserDataDisplay uid={item.creatorUID} separator={undefined} />,
        [t("department")]: getDepartmentName(departments, item.department),
        [t("status")]: t(`filter_criteria:status_${item.status}`),
        [t("dueDate")]: <DateDisplay timestamp={item.dueAt ? new Date(item.dueAt) : null} displaySeconds={undefined} />
      });
    },
    [setMeta, departments, t]
  );

  if (!externalRecipientId) {
    return <></>;
  }

  return <ProcessesOverviewReadOnly filter={filter} onRowOver={onRowOver} onRowLeave={showEnterInfo} />;
};

export default React.memo(ExternalRecipientProcessOverview);
