import { Avatar } from "@material-ui/core";
import { Assignee, LazySvgIcon } from "./ListViewItem";

export const AssigneeAvatar = (props: { assignee?: Assignee }) => {
  const { assignee } = props;
  if (!assignee) return <LazySvgIcon name="Unassigned" />;
  return assignee.is_group ? (
    <LazySvgIcon name="GroupUser" />
  ) : assignee.is_deleted ? (
    <LazySvgIcon name="DeletedUser" />
  ) : assignee.profile ? (
    <Avatar style={{ width: 24, height: 24 }} src={assignee.profile} alt={assignee.name} />
  ) : (
    <Avatar style={{ width: 24, height: 24, background: "#306AFF", fontSize: 10 }} alt={assignee.name}>
      {assignee.name
        .split(" ")
        .map((name: string) => name[0])
        .slice(0, 2)
        .join("")}
    </Avatar>
  );
};
