import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMetaView } from "app/contexts/meta-view-context";
import { useParams } from "react-router-dom";
import { AuditRemarkContext, deleteAuditRemark, postAuditRemark } from "app/api/auditApi";
import AuditRemarkEdit from "app/pages/audits/remarks/AuditRemarkEdit";
import { useAuditRemarks } from "app/contexts/remarks-context";
import AuditRemarkView from "app/pages/audits/remarks/AuditRemarkView";
import { Box, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    borderRadius: "6px",
    background: theme.palette.primary.light
  }
}));

const MetaViewRemarksTab = () => {
  const cls = useStyles();
  const { t } = useTranslation();
  const params = useParams();
  const documentId = params?.id;
  const [auditRemarkForQuestion, setAuditRemarkForQuestion] = useState<AuditRemarkContext | null>(null);

  const { questionId, newRemarkForm, setNewRemarkForm } = useMetaView();
  const { reloadAuditRemarks, auditRemarks } = useAuditRemarks();

  useEffect(() => {
    if (auditRemarks && questionId) {
      const remark = auditRemarks.find(remark => remark.questionId === questionId);
      setAuditRemarkForQuestion(remark || null);
    }
  }, [auditRemarks, questionId]);

  const cancelEditRemark = useCallback(() => setNewRemarkForm(false), [setNewRemarkForm]);
  const createAuditRemark = useCallback(
    async (value: string) => {
      if (documentId && questionId) {
        await postAuditRemark({
          auditId: documentId,
          questionId: questionId,
          payload: value
        });
        setNewRemarkForm(false);
        reloadAuditRemarks();
      }
    },
    [documentId, questionId, reloadAuditRemarks, setNewRemarkForm]
  );

  const onEditCallback = useCallback(() => setNewRemarkForm(true), [setNewRemarkForm]);
  const onDeleteCallback = useCallback(async () => {
    if (documentId && questionId) {
      await deleteAuditRemark({
        auditId: documentId,
        questionId: questionId
      });
      setNewRemarkForm(false);
      reloadAuditRemarks();
    }
  }, [documentId, questionId, reloadAuditRemarks, setNewRemarkForm]);

  return (
    <>
      {newRemarkForm && (
        <AuditRemarkEdit
          value={auditRemarkForQuestion?.value || ""}
          onSave={createAuditRemark}
          onCancel={cancelEditRemark}
        />
      )}
      {!newRemarkForm && auditRemarkForQuestion && (
        <AuditRemarkView onEdit={onEditCallback} onDelete={onDeleteCallback} auditRemark={auditRemarkForQuestion} />
      )}
      <Box className={cls.info} p={3} mt={3}>
        {t("audit_details:remarkInfoMessage")}
      </Box>
    </>
  );
};

export default MetaViewRemarksTab;
