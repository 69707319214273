import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  makeStyles
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Question from "components/Question/Question";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteProcessPeriod,
  getLongestLegalRetentionPeriod,
  getProcessPeriodsPageData,
  patchProcessPeriod,
  postProcessPeriod
} from "app/api/paApi";
import { ConfirmAutoFill } from "./ConfirmAutoFill";
import ChipsMergingConfirmationModal from "../utils/ChipsMergingConfirmationModal/ChipsMergingConfirmationModal";
import CardWithTextButton from "components/CardWithButton/CardWithTextButton";
import { debounce, isEqual, omit } from "lodash-es";
import { v4 } from "uuid";
import { COLLECTIONS } from "app/collections";
import { useProcessPage } from "app/contexts/process-page-context";
import { CategoryTypeTupleModel } from "../CategoryTypeTupleModel";
import { PersonGroupCategoryChip } from "../PersonGroupCategoryChip";
import { accordionEquality } from "../generic-page/ProcessGenericPage";
import useSWR, { mutate } from "swr";
import { SWR_KEYS } from "app/swrKeys";
import useSWRMutation from "swr/mutation";
import { useEnteringInfoCard } from "hook/useEnteringInfoCard";

const useStyles = makeStyles(() => ({
  accordionWrapper: {
    position: "relative",
    "&.savinig": {
      "& .MuiPaper-elevation1": {
        boxShadow: "none"
      }
    }
  }
}));

export interface ProcessPeriod {
  readonly id?: string;
  readonly accordionId?: string;
  readonly legalRetentionPeriodIds: string[];
  readonly storagePeriodValue: string;
  readonly storagePeriodUnit: string;
  readonly deletionTriggerIds: string[];
  readonly storagePeriodJustification: string;
  readonly deletionTypeIds: string[];
  readonly deletionPractice: string;
  readonly categoryTypeTuples: CategoryTypeTupleModel[];
}

const getSelectedChipsForFirstPeriod = (processPeriods: ProcessPeriod[]) => {
  if (processPeriods.length > 1) {
    const [firstPeriod, ...restPeriods] = processPeriods;

    const categoryTypeTuplesForFirstPeriod = firstPeriod.categoryTypeTuples;
    const categoryTypeTuplesForOther = restPeriods.flatMap(({ categoryTypeTuples }) => categoryTypeTuples || []);

    return categoryTypeTuplesForFirstPeriod
      .filter(({ category, personGroup }) => {
        return !categoryTypeTuplesForOther.some(otherCategoryTuple => {
          return otherCategoryTuple?.personGroup === personGroup && otherCategoryTuple?.category === category;
        });
      })
      .map(({ id }) => id);
  } else return ["*"];
};

const ProcessDeletionPeriodAccordion = ({
  saving,
  forceCollapse,
  processId,
  selectedChipIds,
  processDeletionPeriod,
  orgUnitIds,
  onSave,
  onChipClick,
  disabled
}: {
  readonly forceCollapse?: string;
  readonly saving?: boolean;
  readonly processId: string;
  readonly selectedChipIds: string[];
  readonly processDeletionPeriod: ProcessPeriod;
  readonly orgUnitIds: string[];
  readonly disabled?: boolean;
  readonly onSave: (period: Partial<ProcessPeriod>) => void;
  readonly onChipClick?: (
    partialPeriod: Partial<ProcessPeriod>,
    categoryTypeTuple: CategoryTypeTupleModel
  ) => Promise<void>;
}) => {
  const cls = useStyles();
  const { t } = useTranslation("questionnaires");

  useEffect(() => {
    if (forceCollapse) {
      _setExpanded(false);
    }
  }, [forceCollapse]);

  const [period, setPeriod] = useState<ProcessPeriod | null>(null);
  const [partialPeriodToSave, setPartialPeriodToSave] = useState<Partial<ProcessPeriod>>({});
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const chipClickHandler = useSWRMutation(
    // using swr mutation to prevent double click by accident, subsequent clicks will be disabled while mutation is in progress
    [SWR_KEYS.ProcessPeriodsPage, partialPeriodToSave, period],
    async (
      _,
      {
        arg: categoryTypeTuple
      }: {
        arg: CategoryTypeTupleModel;
      }
    ) => {
      if (!period) return;
      const periodToCopy = {
        ...period,
        ...partialPeriodToSave
      };
      await onChipClick?.(omit(periodToCopy, ["categoryTypeTuples"]), categoryTypeTuple);
    },
    {
      onSuccess: mutate
    }
  );

  const [legalRetentionPeriodConfirmation, setLegalRetentionPeriodConfirmation] = useState<boolean>(false);
  const [confirmLegalRetentionPeriodIds, seConfirmtLegalRetentionPeriodIds] = useState<string[]>([]);
  const [_expanded, _setExpanded] = useState<boolean>(true);
  const [loadLongestPeriod, setLoadLongestPeriod] = useState<boolean>(false);

  const handleLongestPeriodForce = useCallback(
    async (legalRetentionPeriodIds: string[]) => {
      if (legalRetentionPeriodIds.length) {
        setLoadLongestPeriod(true);
        const longestPeriod = await getLongestLegalRetentionPeriod({
          processId,
          legalRetentionPeriodIds
        });

        if (longestPeriod) {
          setPartialPeriodToSave(current => ({ ...current, ...longestPeriod }));
          setPeriod(current => ({ ...current, ...longestPeriod }));
        }
        setLoadLongestPeriod(false);
      }
    },
    [processId]
  );

  const debounceHandleLongestPeriodForce = useRef(debounce(handleLongestPeriodForce, 500)).current;

  useEffect(() => setPeriod(processDeletionPeriod), [processDeletionPeriod]);
  useEffect(() => {
    const dirty =
      !isEqual({}, partialPeriodToSave) &&
      Object.keys(partialPeriodToSave).some(
        key =>
          !isEqual(partialPeriodToSave[key as keyof ProcessPeriod], processDeletionPeriod[key as keyof ProcessPeriod])
      );
    setIsDirty(dirty);
    if (!dirty && !isEqual({}, partialPeriodToSave)) {
      setPartialPeriodToSave({});
    }
  }, [partialPeriodToSave, processDeletionPeriod]);

  const patch = useCallback(
    (data: Partial<ProcessPeriod>) => {
      if (period) {
        setPartialPeriodToSave(current => ({ ...current, ...data }));
        setPeriod(current => (current ? { ...current, ...data } : null));
      }
    },
    [period]
  );

  const onCancelCallback = useCallback(() => {
    setPeriod(processDeletionPeriod);
    setPartialPeriodToSave({});
    setIsDirty(false);
    _setExpanded(false);
  }, [processDeletionPeriod]);

  const onSaveCallback = useCallback(() => {
    if (period && !period?.id) {
      onSave(period);
    } else if (!isEqual({}, partialPeriodToSave)) {
      onSave({ ...partialPeriodToSave, id: period?.id, accordionId: period?.accordionId });
    }
    setPartialPeriodToSave({});
    setIsDirty(false);
    _setExpanded(false);
  }, [onSave, partialPeriodToSave, period]);

  const setRetentionPeriod = useCallback(
    (ids: string[]) => {
      patch({ legalRetentionPeriodIds: ids });
      setLegalRetentionPeriodConfirmation(false);
      seConfirmtLegalRetentionPeriodIds([]);
      debounceHandleLongestPeriodForce(ids);
    },
    [debounceHandleLongestPeriodForce, patch]
  );
  const fillLegalRetentionPeriodConfirm = useCallback(
    async () => setRetentionPeriod(confirmLegalRetentionPeriodIds),
    [confirmLegalRetentionPeriodIds, setRetentionPeriod]
  );

  const fillLegalRetentionPeriodClose = useCallback(async () => {
    setLegalRetentionPeriodConfirmation(false);
    seConfirmtLegalRetentionPeriodIds([]);
  }, []);

  const isStorageDurationEmpty = useCallback((): boolean => {
    return (period &&
      !period.storagePeriodValue &&
      !period.storagePeriodUnit &&
      !period.deletionTriggerIds.length &&
      !period.storagePeriodJustification) as boolean;
  }, [period]);

  const legalRetentionPeriodIdsChangeCallback = useCallback(
    (legalRetentionPeriodIds: string[]) => {
      // if user delete all legalRetentionPeriod
      if (!legalRetentionPeriodIds.length) {
        patch({
          legalRetentionPeriodIds,
          storagePeriodValue: "",
          storagePeriodUnit: "",
          deletionTriggerIds: [],
          storagePeriodJustification: ""
        });
      }
      // if user set first legalRetentionPeriod
      else if (!period?.legalRetentionPeriodIds?.length && legalRetentionPeriodIds.length) {
        // if no data was filled no need show confirmation dialog
        if (isStorageDurationEmpty()) {
          setRetentionPeriod(legalRetentionPeriodIds);
        }
        // if some data was added to Storage duration
        // show confirmation dialog
        else {
          seConfirmtLegalRetentionPeriodIds(legalRetentionPeriodIds);
          setLegalRetentionPeriodConfirmation(true);
        }
      }
      // if user set multiple legalRetentionPeriod
      else {
        setRetentionPeriod(legalRetentionPeriodIds);
      }
    },
    [isStorageDurationEmpty, patch, period?.legalRetentionPeriodIds?.length, setRetentionPeriod]
  );

  const storagePeriodValueChangeCallback = useCallback(
    (storagePeriodValue: string) => patch({ storagePeriodValue: storagePeriodValue.toString() }),
    [patch]
  );
  const storagePeriodUnitChangeCallback = useCallback(
    (storagePeriodUnit: string) => patch({ storagePeriodUnit }),
    [patch]
  );
  const deletionTriggerIdsChangeCallback = useCallback(
    (deletionTriggerId: string) => patch({ deletionTriggerIds: [deletionTriggerId] }),
    [patch]
  );
  const storagePeriodJustificationChangeCallback = useCallback(
    (storagePeriodJustification: string) => patch({ storagePeriodJustification }),
    [patch]
  );
  const deletionTypeIdsChangeCallback = useCallback((deletionTypeIds: string[]) => patch({ deletionTypeIds }), [patch]);
  const deletionPracticeChangeCallback = useCallback(
    (deletionPractice: string) => patch({ deletionPractice }),
    [patch]
  );

  const toggleExpanded = useCallback(() => {
    _setExpanded(v => !v);
  }, []);

  const chips = useMemo(
    () =>
      (processDeletionPeriod.categoryTypeTuples || []).reduce(
        (result: CategoryTypeTupleModel[], next: CategoryTypeTupleModel) => {
          if (result.some(r => r.personGroup === next.personGroup && r.category === next.category)) {
            return result;
          } else return [...result, next];
        },
        []
      ),
    [processDeletionPeriod.categoryTypeTuples]
  );

  const chipsEl = chips.map((categoryTypeTupleModel: CategoryTypeTupleModel) => (
    <PersonGroupCategoryChip
      selected={selectedChipIds.includes("*") || selectedChipIds.includes(categoryTypeTupleModel.id)}
      key={categoryTypeTupleModel.id}
      categoryTypeTuple={categoryTypeTupleModel}
      onClick={chipClickHandler.isMutating || disabled ? undefined : chipClickHandler.trigger}
    />
  ));

  const dirtyEl = !saving && isDirty && (
    <CustomAlert severity="error" icon={<ErrorOutlineIcon />}>
      {t("common:unsavedChanges")}
    </CustomAlert>
  );
  const loaderEl = saving && <CircularProgress />;

  return (
    <Box className={`${cls.accordionWrapper} ${saving ? "savinig" : ""}`}>
      <Accordion key={period?.id || ""} expanded={_expanded}>
        <AccordionSummary onClick={toggleExpanded} expandIcon={<ExpandMoreIcon />}>
          <Box>
            <Box display={"flex"}>
              {dirtyEl}
              <Box flex={1} />
            </Box>
            <Box display={"flex"} width={"100%"}>
              <Box flexWrap={"wrap"} display={"flex"} flex={1}>
                {chipsEl}
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                {loaderEl}
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%">
            <Box width="100%">
              <Question
                qType={QUESTION_TYPE.LEGAL_RETENTION_PERIODS}
                collection={COLLECTIONS.PROCESSES}
                documentId={processId}
                questionName={t("resources_legal-retention-period_overview:header")}
                value={period?.legalRetentionPeriodIds || []}
                orgUnitIds={orgUnitIds}
                infoId={"infocard.pa.page4.accordion.legalRetentionPeriods"}
                onChange={legalRetentionPeriodIdsChangeCallback}
                disabled={saving || disabled}
              />
              <Box mt={2} />
              <QuestionnaireSubHeader text={t("dpia_storage_table:heading")} />

              {Boolean(period?.legalRetentionPeriodIds?.length) && (
                <CustomAlert severity="info">
                  <Box whiteSpace="pre-wrap">{t("deletionStorageFieldsInfoText")}</Box>
                </CustomAlert>
              )}

              <Box display="flex">
                <Box mr={3} flex={1}>
                  <Question
                    disabled={
                      Boolean(period?.legalRetentionPeriodIds?.length) || saving || loadLongestPeriod || disabled
                    }
                    qType={QUESTION_TYPE.NUMBER}
                    questionName={t("businessRetentionPeriod:inputLabel")}
                    value={period?.storagePeriodValue}
                    onChange={storagePeriodValueChangeCallback}
                    infoId={"infocard.pa.page4.accordion.storagePeriodValue"}
                  />
                </Box>
                <Box ml={3} flex={1}>
                  <Question
                    disabled={
                      Boolean(period?.legalRetentionPeriodIds?.length) || saving || loadLongestPeriod || disabled
                    }
                    qType={QUESTION_TYPE.UNIT_OF_TIME}
                    value={period?.storagePeriodUnit}
                    onChange={storagePeriodUnitChangeCallback}
                    infoId={"infocard.pa.page4.accordion.storagePeriodUnit"}
                  />
                </Box>
              </Box>
              <Question
                qType={QUESTION_TYPE.DELETION_TRIGGER}
                disabled={Boolean(period?.legalRetentionPeriodIds?.length) || saving || loadLongestPeriod || disabled}
                value={period?.deletionTriggerIds}
                onChange={deletionTriggerIdsChangeCallback}
                infoId={"infocard.pa.page4.accordion.deletionTriggers"}
              />
              <Question
                qType={QUESTION_TYPE.TEXT_EDITOR}
                disabled={Boolean(period?.legalRetentionPeriodIds?.length) || saving || loadLongestPeriod || disabled}
                value={period?.storagePeriodJustification}
                questionName={t("justificationOfStorage")}
                onChange={storagePeriodJustificationChangeCallback}
                infoId={"infocard.pa.page4.accordion.storagePeriodJustification"}
              />
              <Box mt={2} />
              <QuestionnaireSubHeader text={t("deletionPractice")} />
              <Question
                qType={QUESTION_TYPE.DATA_DELETION_TYPES}
                value={period?.deletionTypeIds}
                onChange={deletionTypeIdsChangeCallback}
                infoId={"infocard.pa.page4.accordion.deletionTypes"}
                disabled={saving || disabled}
              />
              <Question
                qType={QUESTION_TYPE.TEXT_EDITOR}
                value={period?.deletionPractice}
                questionName={t("deletionPractice:inputLabel")}
                onChange={deletionPracticeChangeCallback}
                infoId={"infocard.pa.page4.accordion.deletionPractice"}
                disabled={saving || disabled}
              />
            </Box>
            <Box display={"flex"} width={"100%"}>
              <Box flex={1} />
              <Button variant="outlined" color="primary" onClick={onCancelCallback} disabled={disabled}>
                {t("common:cancel")}
              </Button>
              <Box mx={1} />
              <Button variant="contained" color="primary" onClick={onSaveCallback} disabled={disabled}>
                {t("common:save")}
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <ConfirmAutoFill
        onClose={fillLegalRetentionPeriodClose}
        onConfirm={fillLegalRetentionPeriodConfirm}
        open={legalRetentionPeriodConfirmation}
      />
    </Box>
  );
};

const ProcessPeriodsPage = (props: { readonly readonly?: boolean }) => {
  const { t } = useTranslation("questionnaires");
  const { id } = useParams();
  const navigate = useNavigate();
  const { onBeforeProcessUpdate, processMeta, setProcessMeta } = useProcessPage();
  useEnteringInfoCard({
    pathName: `/processes/${id}/periods`,
    infoId: "infocard.pa.page4"
  });

  const [items, setItems] = useState<ProcessPeriod[]>([]);
  const [selectedChipIds, setSelectedChipIds] = useState<string[]>([]);
  const [showMergeConfirm, setShowMergeConfirm] = useState<boolean>(false);
  const [categoryTuplesToMerge, setCategoryTuplesToMerge] = useState<CategoryTypeTupleModel | null>(null);
  const [partialPeriodToMerge, setPartialPeriodToMerge] = useState<Partial<ProcessPeriod> | null>(null);

  const [savingPeriodIds, setSavingPeriodIds] = useState<string[]>([]);

  /* FETCH PAGE */
  const fetchProcessPage = useSWR(
    [SWR_KEYS.ProcessPeriodsPage, id],
    async () =>
      await getProcessPeriodsPageData({
        processId: id || ""
      }),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  const { mutate: fetchProcessPageMutate } = fetchProcessPage;

  useEffect(() => {
    if (!fetchProcessPage?.data) {
      return;
    }

    setItems(fetchProcessPage.data.processPage?.items || []);
    setProcessMeta(fetchProcessPage.data.processMeta);
  }, [fetchProcessPage.data, setProcessMeta]);

  useEffect(() => {
    setSelectedChipIds(getSelectedChipsForFirstPeriod(items));
  }, [items]);

  const onPatchPeriod = useCallback(
    async (partialPeriod: Partial<ProcessPeriod>) => {
      // if no id yet
      // nothing to PATCH
      if (!partialPeriod.id) {
        return;
      }

      const currentPeriod = items.find(({ id }) => id === partialPeriod.id);
      // if recipient not exist
      // nothing to PATCH
      if (!currentPeriod) {
        return;
      }

      setSavingPeriodIds(current => [...current, partialPeriod.id] as string[]);
      await patchProcessPeriod({
        processId: id || "",
        periodId: partialPeriod.id,
        payload: partialPeriod
      });
      setSavingPeriodIds(current => current.filter(id => id !== partialPeriod.id) as string[]);
    },
    [id, items]
  );

  const onPostPeriod = useCallback(
    async (partialPeriod: Partial<ProcessPeriod>) => {
      // if id is exist
      // nothing to POST
      if (partialPeriod.id) {
        return;
      }

      // if accordionId not exist
      // nothing to POST
      if (!partialPeriod.accordionId) {
        return;
      }

      setSavingPeriodIds(["*"]);
      await postProcessPeriod({
        processId: id || "",
        payload: partialPeriod
      });
      setSavingPeriodIds([]);
      await fetchProcessPageMutate();
    },
    [fetchProcessPageMutate, id]
  );

  const onDeletePeriod = useCallback(
    async (periodId: string) => {
      setSavingPeriodIds(["*"]);
      await deleteProcessPeriod({ processId: id || "", periodId });
      setSavingPeriodIds([]);
    },
    [id]
  );

  const onChangePeriod = useCallback(
    async (partialPeriod: Partial<ProcessPeriod>) => {
      await onBeforeProcessUpdate(async () => {
        if (partialPeriod.id) {
          await onPatchPeriod(partialPeriod);
        } else if (partialPeriod.accordionId) {
          await onPostPeriod(partialPeriod);
        }
        await fetchProcessPageMutate();
      });
    },
    [onBeforeProcessUpdate, onPatchPeriod, onPostPeriod, fetchProcessPageMutate]
  );

  const onChipClickCallback = useCallback(
    async (partialPeriod: Partial<ProcessPeriod>, categoryTypeTuple: CategoryTypeTupleModel) => {
      await onBeforeProcessUpdate(async () => {
        if (!id) {
          return;
        }
        // create new accordion
        if (selectedChipIds.includes("*") || selectedChipIds.includes(categoryTypeTuple.id)) {
          const currentPeriod = items.find(item => accordionEquality(item, partialPeriod));
          if (currentPeriod) {
            const categoryTypeTuplesForNewPeriod = currentPeriod.categoryTypeTuples.filter(
              ({ personGroup, category }) =>
                categoryTypeTuple.personGroup === personGroup && categoryTypeTuple.category === category
            );

            const newPeriod = {
              ...currentPeriod,
              ...partialPeriod,
              id: undefined,
              accordionId: v4(),
              categoryTypeTuples: categoryTypeTuplesForNewPeriod
            };
            const itemsToSave = items.map(item => {
              if (accordionEquality(item, partialPeriod)) {
                return { ...item, ...partialPeriod };
              }
              return item;
            });
            itemsToSave.push(newPeriod);

            // save current
            const needSaveCurrent = partialPeriod.id && !isEqual(omit(partialPeriod, ["id", "accordionId"]), {});
            if (needSaveCurrent) {
              await onPatchPeriod(partialPeriod);
            }

            for (const item of itemsToSave) {
              await onPostPeriod(item);
            }
            await fetchProcessPageMutate();
          }
        }
        // join accordions
        else {
          setCategoryTuplesToMerge(categoryTypeTuple);
          setPartialPeriodToMerge(partialPeriod);
          setShowMergeConfirm(true);
        }
      });
    },
    [fetchProcessPageMutate, id, items, onBeforeProcessUpdate, onPatchPeriod, onPostPeriod, selectedChipIds]
  );

  const onMergeConfirmCallback = useCallback(async () => {
    if (categoryTuplesToMerge && partialPeriodToMerge) {
      const itemsToSave = items
        .filter(
          (item, index) => index === 0 || !item.categoryTypeTuples.some(({ id }) => id === categoryTuplesToMerge.id)
        )
        .map(item => {
          if (accordionEquality(item, partialPeriodToMerge)) {
            return { ...item, ...partialPeriodToMerge };
          }
          return item;
        });

      const currentIds = items.map(({ id }) => id);
      const newIds = itemsToSave.map(({ id }) => id);
      const toDeleteIds = currentIds.filter(item => newIds.indexOf(item) < 0).filter(notNull => notNull) as string[];

      // save current
      await onPatchPeriod(partialPeriodToMerge);

      // delete
      for (const id of toDeleteIds) {
        await onDeletePeriod(id);
      }
      await fetchProcessPageMutate();

      setCategoryTuplesToMerge(null);
      setPartialPeriodToMerge(null);
    }
  }, [categoryTuplesToMerge, fetchProcessPageMutate, items, onDeletePeriod, onPatchPeriod, partialPeriodToMerge]);

  const onMergeCloseCallback = useCallback(() => {
    setCategoryTuplesToMerge(null);
    setShowMergeConfirm(false);
  }, []);

  const recipientPageNavigateCallback = useCallback(() => {
    navigate("/processes/" + id + "/description");
  }, [id, navigate]);

  if (fetchProcessPage.isLoading) {
    return (
      <Box textAlign={"center"} mt={8}>
        <CircularProgress />
      </Box>
    );
  }

  if (!fetchProcessPage.isLoading && !items.find(item => item.categoryTypeTuples.length > 0)) {
    return (
      <CardWithTextButton
        text={t("specifyPersonalData")}
        buttonText={t("goToPage2")}
        colorTheme={"blue"}
        onClick={recipientPageNavigateCallback}
      />
    );
  }

  const accordionsEl = items
    ?.filter(period => period.categoryTypeTuples.length > 0)
    .map((period, index) => (
      <Box key={`${period.id || period.accordionId}-${index}`}>
        <Question
          questionId={"retentionData " + (period.id || period.accordionId)}
          questionName={t("retentionDataTitle")}
          disabled={props.readonly}
        >
          <ProcessDeletionPeriodAccordion
            saving={Boolean(
              savingPeriodIds.includes("*") ||
                (period.id && savingPeriodIds.includes(period.id)) ||
                (period.accordionId && savingPeriodIds.includes(period.accordionId))
            )}
            processId={id || ""}
            selectedChipIds={index === 0 ? selectedChipIds : ["*"]}
            processDeletionPeriod={period}
            orgUnitIds={processMeta?.relativeOrgUnitIds || []}
            onSave={onChangePeriod}
            onChipClick={index === 0 ? onChipClickCallback : undefined}
            disabled={props.readonly}
          />
        </Question>
      </Box>
    ));

  const mergeConfirmDialogEl = (
    <ChipsMergingConfirmationModal
      chipTitleModalOpen={showMergeConfirm}
      handleChipTitleModalClose={onMergeCloseCallback}
      currentSelectedChipTitle={undefined}
      dispatch={onMergeConfirmCallback}
    />
  );

  return (
    <>
      <Box>
        <QuestionnaireSubHeader text={t("questionnaires:retentionDataTitle")} />
      </Box>
      {accordionsEl}
      {mergeConfirmDialogEl}
    </>
  );
};

export default ProcessPeriodsPage;
