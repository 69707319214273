import React, { useCallback, useEffect, useState, useMemo } from "react";
import { AuditDetailDTO, getAuditDetail, patchAuditDetail } from "app/api/auditApi";
import { useTranslation } from "react-i18next";
import { useMetaView } from "app/contexts/meta-view-context";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { Box, Button, CircularProgress } from "@material-ui/core";
import TextEditor from "app/pages/questionnaires/utils/TextEditor";
import Question from "../../../../components/Question/Question";
import { AttachmentsOverviewOBS } from "../../shared/Attachments/AttachmentsOverviewOBS";
import { COLLECTIONS } from "../../../collections";
import { downloadAuditReportWordDocument } from "app/handlers/auditHandler";
import { exportAuditTasksExcel } from "app/export/createdExcelExportData";
import { useAuthentication } from "app/handlers/authentication/authentication-context";

export const AuditSummary = ({ auditId }: { readonly auditId: string }) => {
  const { t, i18n } = useTranslation("audit_details");
  const { setInfo, setMeta } = useMetaView();
  const { auth } = useAuthentication();
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [auditData, setAuditData] = useState<Partial<AuditDetailDTO> | null>(null);
  const [auditResult, setAuditResult] = useState<string>("");

  useEffect(() => {
    setInfo({
      title: t("enteringInfoCardTitle"),
      text: t("enteringResultPage")
    });
  }, [setInfo, t]);
  useEffect(() => {
    getAuditDetail({ id: auditId }).then(setAuditData);
  }, [auditId]);

  useEffect(() => {
    if (auditData) {
      setAuditResult(auditData.auditResult || "");
    }
  }, [auditData]);

  const onSaveAuditResults = useCallback(async () => {
    if (auditData && auditData.id) {
      await patchAuditDetail({ id: auditData.id, payload: { auditResult } });
    }
  }, [auditData, auditResult]);

  const noWritePermission = useMemo(
    () => !auditData?.pagesWithWrite?.includes?.("result"),
    [auditData?.pagesWithWrite]
  );

  const downloadAuditReportDoc = useCallback(async () => {
    if (!auditData?.id) {
      return;
    }

    try {
      setLoadingDocument(true);
      await downloadAuditReportWordDocument(auditData.id, i18n.language);
    } finally {
      setLoadingDocument(false);
    }
  }, [auditData?.id, i18n.language]);

  const downloadAuditTaskReportExcel = useCallback(async () => {
    if (!auditData?.id || !auditData?.title) {
      return;
    }

    try {
      setLoadingDocument(true);
      if (auth?.tenantId) {
        await exportAuditTasksExcel(auth?.tenantId, auditData.id, auditData.title, i18n.language, t);
      }
    } finally {
      setLoadingDocument(false);
    }
  }, [auth?.tenantId, auditData?.id, auditData?.title, i18n.language, t]);

  return (
    <Box>
      <QuestionnaireSubHeader text={t("auditResultHeadline")} />
      <TextEditor
        onBlur={onSaveAuditResults}
        onChange={setAuditResult}
        inputValue={auditResult}
        title={t("auditResultSubHeadline")}
        titleComponent={undefined}
      />{" "}
      <Question>
        <QuestionnaireSubHeader text={t("auditReport")} />
        <Button
          style={{ marginBottom: "2rem" }}
          variant="contained"
          color="primary"
          onClick={downloadAuditReportDoc}
          disabled={loadingDocument}
        >
          {t("common:downloadReport")}
          {loadingDocument && <CircularProgress color="inherit" size={14} />}
        </Button>
        <Button
          style={{ marginBottom: "2rem", marginLeft: "2rem" }}
          variant="contained"
          color="primary"
          onClick={downloadAuditTaskReportExcel}
          disabled={loadingDocument}
        >
          {t("common:downloadTask")}
          {loadingDocument && <CircularProgress color="inherit" size={14} />}
        </Button>
        <QuestionnaireSubHeader text={t("common:attachments")} />
        <AttachmentsOverviewOBS
          docId={auditId}
          category={`${COLLECTIONS.AUDITS}-result`}
          setMeta={setMeta}
          disabled={noWritePermission}
        />
      </Question>
    </Box>
  );
};
