import { useIsFeaturePresent } from "../../../hook/useIsFeaturePresent";
import Assessment from "./Assessment";
import Audit from "./Audit";
import React from "react";
export const AssessmentLegacyWrapper = () => {
  const isPublicAssessmentActivated = useIsFeaturePresent("publicAssessment");
  if (isPublicAssessmentActivated === null) {
    return <></>;
  }
  if (isPublicAssessmentActivated) {
    return <Assessment />;
  }
  return <Audit />;
};
