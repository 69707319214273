import overviewBaseController, {
  OverviewController,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { useCallback } from "react";
import { COLLECTIONS } from "app/collections";
import { getOrganizationFilter } from "app/pages/shared/Filters/filters";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { AxiosInstance } from "axios";
import { useNavigateWithQueryParams } from "app/router/router-custom-hooks";
import { useTranslation } from "react-i18next";

const TokenOverviewController = (axiosInstance: AxiosInstance): OverviewController => {
  // the controller is a pure TS file, if it uses state, the value will not be updated
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const { t } = useTranslation();

  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.TOKEN);

  const getFilters = useCallback(
    data => [
      getOrganizationFilter("orgUnitId", data._departments, t, "department"),
      getOrganizationFilter("furtherOrgUnitIds", data._departments, t, "furtherDepartments")
    ],
    [t]
  );

  const getSortings = () => [
    {
      field: "title",
      type: "asc",
      label: t("filter_criteria:aToZ")
    },
    {
      field: "title",
      type: "desc",
      label: t("filter_criteria:zToA")
    }
  ];

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    const data = await baseController.getOverview(setup);
    if (!data) {
      return null;
    }

    return {
      ...data,
      filters: getFilters(data),
      sortings: getSortings()
    };
  };

  const goToItem = (id: string) => {
    baseController.goToItem(`/tokens/${id}`);
  };

  const addItem = async (data: OverviewNewItem) => {
    return await baseController.addItem({
      title: data?.title || createOverviewItemDefaultName("token")
    });
  };

  const patchItem = async (id: string, data: object) => {
    return await baseController.patchItem(id, data);
  };

  const addItemAndGo = async () => {
    navigateWithQueryParams("/tokens/new");
  };

  const deleteItem = async (id: string) => {
    return await baseController.deleteItem(id, `/${id}`);
  };

  return {
    ...baseController,
    getOverview,
    goToItem,
    addItem,
    patchItem,
    deleteItem,
    addItemAndGo
  };
};

export default TokenOverviewController;
