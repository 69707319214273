import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMetaView } from "../../../contexts/meta-view-context";
import { ProcessesOverviewReadOnly } from "../../processes/overview/ProcessesOverviewReadOnly";

const AssetsProcessesOverview = ({ assetId }: { assetId: string }) => {
  const { t } = useTranslation("asset_details");
  const { setInfo } = useMetaView();
  const filter = useMemo(() => ({ assetIDs: [assetId] }), [assetId]);

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringInfoCardTextProcessView")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  return <ProcessesOverviewReadOnly filter={filter} />;
};

export default React.memo(AssetsProcessesOverview);
