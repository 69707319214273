import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useErrorSnackbar } from "../../hook/errorSnackbar";
import { isUndefined, omitBy } from "lodash-es";
import isEqual from "lodash-es/isEqual";
import { updateRiskAssessmentApi, updateRiskAssessmentSettingApi, updateRiskTreatmentApi } from "../api/riskApi";
import DocMetaView from "../../components/DocMetaView/DocMetaView";
import DocView from "../../components/DocView/DocView";
import { CircularProgress } from "@material-ui/core";
import DocumentNotFound from "../pages/shared/DocumentNotFound/DocumentNotFound";
import { awaitRiskVersion, getRisk, incrementVersion, RiskVersionError, updateRisk } from "../handlers/risksHandler";
import { COLLECTIONS } from "app/collections";

export const RiskContext = createContext({
  riskId: "",
  risk: {
    id: "",
    title: "",
    type: "",
    orgUnitId: "",
    furtherAffectedOrgUnitIds: "",
    description: "",
    ownerUID: "",
    privacyRelevant: false,
    riskSourceIds: [],
    riskAssetIds: [],
    dataLocationIds: [],
    protectionObjectiveIds: [],
    implementedMeasureIds: [],
    labelIds: []
  },
  loading: true,
  initialized: false,
  reloadHook: async () => {},
  reloadToVersionHook: async ({ riskVersion, assessmentsVersion, treatmentVersion }) => {},
  updateBasicInfoHook: async ({
    title,
    type,
    orgUnitId,
    furtherAffectedOrgUnitIds,
    description,
    ownerUID,
    privacyRelevant,
    riskSourceIds,
    riskAssetIds,
    dataLocationIds,
    protectionObjectiveIds,
    implementedMeasureIds,
    labelIds
  }) => {},
  toggleIndividualAssessmentHook: async (phaseId, enabled) => {},
  updateAllIndividualAssessmentsOccurrence: async (phaseId, { occurrenceId, reasonDescription }) => {},
  updateAssessmentHook: async (
    phaseId,
    protectionObjectiveId,
    { occurrenceId, damageExtendId, impactDescription, reasonDescription }
  ) => {},
  updateTreatmentHook: async ({ type, measureIds, description, version }) => {}
});
export const RiskProvider = ({ children, riskId, customLoadScreen }) => {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [risk, setRisk] = useState({ id: riskId });
  const [isMissing, setIsMissing] = useState(false);

  const { catchAsSnackbar } = useErrorSnackbar();

  const onLoading = useCallback(
    async actionFn => {
      setLoading(true);

      try {
        const result = await actionFn();
        setLoading(false);
        return result;
      } catch (error) {
        setLoading(false);
        throw error;
      }
    },
    [setLoading]
  );

  const reloadHook = useCallback(async () => {
    return onLoading(async () => {
      const loadedRisk = await getRisk(riskId);
      if (!loadedRisk) {
        setIsMissing(true);
        setRisk({ id: riskId });
        return loadedRisk;
      }

      setRisk(loadedRisk);
      return loadedRisk;
    });
  }, [onLoading, riskId, setIsMissing, setRisk]);

  useEffect(() => {
    reloadHook()
      .then(risk => setInitialized(!!risk))
      .catch(catchAsSnackbar("Failed to load risk"));
  }, [catchAsSnackbar, reloadHook, setInitialized]);

  const reloadToVersionHook = useCallback(
    async ({ riskVersion, assessmentsVersion, treatmentVersion }) => {
      const risk = await awaitRiskVersion(riskId, { riskVersion, assessmentsVersion, treatmentVersion });
      setRisk(risk);
    },
    [riskId]
  );

  const retryWithLatestRisk = useCallback(
    async (actionFn, name, debugPayload) => {
      let currentRisk = await getRisk(riskId);

      const maxTries = 3;
      let tryCount = 1;

      const applyChange = async () => {
        try {
          return await actionFn(currentRisk);
        } catch (error) {
          if (!(error instanceof RiskVersionError) || tryCount >= maxTries) {
            throw error;
          }
          tryCount += 1;
          console.warn(`${name || "anonymous"} concurrent edit. ${tryCount} attempt.`, debugPayload);
          currentRisk = await getRisk(riskId);
          return await applyChange();
        }
      };

      return await applyChange();
    },
    [riskId]
  );

  const nonUndefinedPayload = input => {
    const withoutUndefined = omitBy(input || {}, isUndefined);
    if (Object.keys(withoutUndefined).length === 0) {
      return null;
    }
    return withoutUndefined;
  };

  const updateBasicInfoHook = useCallback(
    ({
      title,
      type,
      orgUnitId,
      furtherAffectedOrgUnitIds,
      description,
      ownerUID,
      privacyRelevant,
      riskSourceIds,
      riskAssetIds,
      dataLocationIds,
      protectionObjectiveIds,
      implementedMeasureIds,
      labelIds
    } = {}) => {
      const updatePayload = nonUndefinedPayload({
        title,
        type,
        orgUnitId,
        furtherAffectedOrgUnitIds,
        description,
        ownerUID,
        privacyRelevant,
        riskSourceIds,
        riskAssetIds,
        dataLocationIds,
        protectionObjectiveIds,
        implementedMeasureIds,
        labelIds
      });
      if (!updatePayload) {
        return;
      }

      return onLoading(async () => {
        return retryWithLatestRisk(
          async risk => {
            const changed = Object.entries(updatePayload).some(([key, value]) => !isEqual(risk[key], value));
            if (!changed) {
              return;
            }

            await updateRisk(riskId, risk.version, updatePayload);
            await reloadToVersionHook({ riskVersion: incrementVersion(risk.version) });
          },
          "update-basic",
          updatePayload
        );
      });
    },
    [onLoading, riskId, retryWithLatestRisk, reloadToVersionHook]
  );

  const toggleIndividualAssessmentHook = useCallback(
    (phaseId, enabled) => {
      if (enabled === undefined || enabled === null) {
        return;
      }
      return onLoading(async () => {
        return retryWithLatestRisk(
          async risk => {
            const existingValue = risk.assessments[phaseId].individualAssessmentEnabled;
            if (enabled === existingValue) {
              return;
            }

            await updateRiskAssessmentSettingApi(riskId, phaseId, risk.version, {
              individualAssessmentEnabled: enabled
            });
            await reloadToVersionHook({ riskVersion: incrementVersion(risk.version) });
          },
          "toggle-individual-assessment",
          enabled
        );
      });
    },
    [onLoading, riskId, reloadToVersionHook, retryWithLatestRisk]
  );

  const updateAssessmentHook = useCallback(
    (phaseId, protectionObjectiveId, { occurrenceId, damageExtendId, impactDescription, reasonDescription }) => {
      const updatePayload = nonUndefinedPayload({
        occurrenceId,
        damageExtendId,
        impactDescription,
        reasonDescription
      });
      if (!updatePayload) {
        return;
      }

      return onLoading(async () => {
        return retryWithLatestRisk(
          async risk => {
            let existingAssessment;
            if (protectionObjectiveId === "main") {
              existingAssessment = risk.assessments[phaseId].combinedAssessment || {};
            } else {
              existingAssessment =
                risk.assessments[phaseId].individualAssessments.find(
                  assessment => assessment.protectionObjectiveId === protectionObjectiveId
                ) || {};
            }

            const changed = Object.entries(updatePayload).some(
              ([key, value]) => !isEqual(existingAssessment[key], value)
            );
            if (!changed) {
              return;
            }

            let autoOccurrenceOrDamageExtend = {};
            if (
              !existingAssessment.occurrenceId &&
              !existingAssessment.damageExtendId &&
              (updatePayload.occurrenceId || updatePayload.damageExtendId)
            ) {
              autoOccurrenceOrDamageExtend = {
                occurrenceId: "probability-low",
                damageExtendId: "damage-low"
              };
            }

            await updateRiskAssessmentApi(riskId, phaseId, protectionObjectiveId, existingAssessment.version || 0, {
              ...autoOccurrenceOrDamageExtend,
              ...updatePayload
            });
            await reloadToVersionHook({
              assessmentsVersion: {
                [phaseId]: { [protectionObjectiveId]: incrementVersion(existingAssessment?.version) }
              }
            });
          },
          "update-assessment",
          { ...updatePayload, phaseId, protectionObjectiveId }
        );
      });
    },
    [onLoading, retryWithLatestRisk, reloadToVersionHook, riskId]
  );

  const updateAllIndividualAssessmentsOccurrence = useCallback(
    (phaseId, { occurrenceId, reasonDescription }) => {
      if (occurrenceId === undefined && reasonDescription === undefined) {
        return;
      }

      return onLoading(async () => {
        return retryWithLatestRisk(
          async risk => {
            const protectionObjectiveIds = risk.protectionObjectiveIds;

            const batchUpdates = protectionObjectiveIds
              .map(protectionObjectiveId => {
                const existingAssessment = risk.assessments[phaseId].individualAssessments.find(
                  assessment => assessment.protectionObjectiveId === protectionObjectiveId
                );
                return { protectionObjectiveId, existingAssessment };
              })
              .map(({ protectionObjectiveId, existingAssessment }) => {
                const updatePromise = updateRiskAssessmentApi(
                  riskId,
                  phaseId,
                  protectionObjectiveId,
                  existingAssessment?.version || 0,
                  {
                    occurrenceId,
                    reasonDescription,
                    // set to low if empty, otherwise undefined for no change
                    damageExtendId: occurrenceId && !existingAssessment?.damageExtendId ? "damage-low" : undefined
                  }
                );
                return {
                  protectionObjectiveId,
                  existingAssessment,
                  updatePromise,
                  expectedVersion: incrementVersion(existingAssessment?.version)
                };
              });

            await Promise.all(batchUpdates.map(update => update.updatePromise));

            const expectedAssessmentsVersions = batchUpdates.reduce(
              (versions, { protectionObjectiveId, expectedVersion }) => ({
                ...versions,
                [protectionObjectiveId]: expectedVersion
              }),
              {}
            );
            await reloadToVersionHook({ assessmentsVersion: { [phaseId]: expectedAssessmentsVersions } });
          },
          "update-all-occurrences",
          occurrenceId
        );
      });
    },
    [onLoading, retryWithLatestRisk, reloadToVersionHook, riskId]
  );

  const updateTreatmentHook = useCallback(
    async ({ type, measureIds, description }) => {
      const updatePayload = nonUndefinedPayload({
        type,
        measureIds,
        description
      });
      if (!updatePayload) {
        return;
      }

      return onLoading(async () => {
        return retryWithLatestRisk(
          async risk => {
            const existingTreatment = risk.treatment || {};
            const changed = Object.entries(updatePayload).some(
              ([key, value]) => !isEqual(existingTreatment[key], value)
            );
            if (!changed) {
              return;
            }

            await updateRiskTreatmentApi(riskId, existingTreatment.version || 0, updatePayload);
            await reloadToVersionHook({ treatmentVersion: incrementVersion(existingTreatment?.version) });
          },
          "update-treatment",
          updatePayload
        );
      });
    },
    [onLoading, retryWithLatestRisk, reloadToVersionHook, riskId]
  );

  return (
    <RiskContext.Provider
      value={{
        initialized,
        loading,
        riskId,
        risk,
        reloadHook,
        reloadToVersionHook,
        updateBasicInfoHook,
        toggleIndividualAssessmentHook,
        updateAssessmentHook,
        updateAllIndividualAssessmentsOccurrence,
        updateTreatmentHook
      }}
    >
      {!initialized &&
        !isMissing &&
        (customLoadScreen || (
          <DocMetaView
            docViewContent={
              <DocView>
                <CircularProgress color="inherit" />
              </DocView>
            }
          />
        ))}
      {isMissing && <DocumentNotFound collection={COLLECTIONS.RISK} />}
      {initialized && risk && children}
    </RiskContext.Provider>
  );
};
export const useRisk = () => useContext(RiskContext);
