import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, LinearProgress, ListItem, ListItemIcon } from "@material-ui/core";
import RenderIcon from "components/RenderIcon/RenderIcon";
import TextBodyPrimary from "components/TextBodyPrimary/TextBodyPrimary";

const useStyles = makeStyles(theme => ({
  uploadDocumentRow: {
    borderTop: "2px hidden",
    borderBottom: "2px hidden"
  },
  publishIcon: {
    color: theme.palette.text.primary
  },
  singleLine: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

export default function OverviewListItemFileUploadBar({
  title,
  allowedFileTypes = [],
  onSelectFilesForUpload,
  uploadStatus,
  disabled,
  customUploadButton = null,
  showDividers = true
}) {
  const classes = useStyles();

  const handleChange = e => {
    const tempArray = [...e.target.files];
    onSelectFilesForUpload(tempArray);
  };

  const constructFileExtensionString = allowedFileTypes => {
    return allowedFileTypes.length > 0 ? allowedFileTypes.reduce((a, b) => a + ", " + b) : "";
  };

  const fileInputRef = useRef(null);
  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  if (customUploadButton) {
    return (
      <>
        {showDividers && <Divider />}
        {["downloading", "uploading", "editing"].includes(uploadStatus) && <LinearProgress />}
        {uploadStatus === "deleting" && <LinearProgress color={"secondary"} />}
        {React.cloneElement(customUploadButton, { onClick: handleFileInputClick })}
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={handleChange}
          accept={constructFileExtensionString(allowedFileTypes)}
          multiple
        />
        {showDividers && <Divider />}
      </>
    );
  }

  return (
    <>
      {showDividers && <Divider />}
      <ListItem className={classes.uploadDocumentRow} button component={"label"}>
        <ListItemIcon className={classes.publishIcon}>
          <RenderIcon icon={"PublishIcon"} iconSize={"medium"} callBackFunction={() => {}} />
        </ListItemIcon>
        <TextBodyPrimary text={title} />
        <input
          disabled={disabled}
          accept={constructFileExtensionString(allowedFileTypes)}
          id="raised-button-file"
          style={{ display: "none" }}
          onChange={e => {
            handleChange(e);
            e.target.value = null;
          }}
          multiple
          type="file"
        />
      </ListItem>
      {["downloading", "uploading", "editing"].includes(uploadStatus) && <LinearProgress />}
      {uploadStatus === "deleting" && <LinearProgress color={"secondary"} />}
      {showDividers && <Divider />}
    </>
  );
}
