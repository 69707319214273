import React, { useCallback, useEffect, useMemo, useState } from "react";
import { QuestionProps } from "../../Question";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { UserDTO } from "app/api/user/userApi";
import { t } from "i18next";

import PrivacyExpertIcon from "assets/images/icons/dataPrivacyExpert.svg";

/*
  We have to allow set ANY user as PrivacyExpertUser
  because the case can be
  user is Exper and we set this user as PrivacyExpertUser
  then we change the role for that user
  but we still have to see this user as PrivacyExpertUser in component
  but we CAN NOT see this user in Autocomplete list
*/
const PrivacyExpertUserQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onChange,
  onBlur,
  onFocus
}: QuestionProps) => {
  const { getUserNameEmailHook, getUsersByAssignableFieldHook } = useUserAndTenantData();
  const [assignableUsers, setAssignableUsers] = useState<UserDTO[]>([]);

  const getUsersByAssignableField = useCallback(async () => {
    const users = await getUsersByAssignableFieldHook("privacyExpert");
    setAssignableUsers(users);
  }, [getUsersByAssignableFieldHook]);

  useEffect(() => {
    getUsersByAssignableField();
  }, [getUsersByAssignableField]);

  const _value: string = Array.isArray(value) ? value[0] : (value as string);

  const optionIds: string[] = useMemo(
    () =>
      assignableUsers.reduce<string[]>((acc, next) => {
        if (next.id) {
          return [...acc, next.id];
        } else return acc;
      }, []),
    [assignableUsers]
  );

  const updateSelected = useCallback(
    userId => {
      onChange?.([userId]);
    },
    [onChange]
  );

  return (
    <MultiAutocomplete
      id={"PrivacyExpertUserQuestion"}
      selected={_value}
      options={optionIds}
      icon={<PrivacyExpertIcon />}
      disableClearable={true}
      freeSolo={false}
      updateSelected={updateSelected}
      getOptionLabel={getUserNameEmailHook}
      disabled={disabled}
      hasMultiSelect={false}
      onBlur={onBlur}
      label={questionName || t("general_page:responsible_expert")}
      error={error}
      helperText={helperText}
      onFocus={onFocus}
    />
  );
};

export default React.memo(PrivacyExpertUserQuestion);
