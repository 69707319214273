import { Department } from "./departmentHandler";
import { groupBy } from "lodash-es";

export const orgUnitAndChildrens = (orgUnitId: string, orgUnits: Department[]) => {
  if (!orgUnitId || orgUnits.length <= 0) {
    return orgUnits;
  }

  const parentIdToOrgUnits = groupBy(orgUnits, "parentId");

  const root = orgUnits.find(orgUnit => {
    return orgUnit.id === orgUnitId;
  });

  if (root) {
    const childrenOrgUnits = [];
    childrenOrgUnits.push(root);

    // recurse data, starting with root node; pass in empty array
    const childrens = recurseTreeMapData(parentIdToOrgUnits, root, []);
    childrenOrgUnits.push(...childrens);

    return childrenOrgUnits;
  } else return [];
};

export const expandOrgUnitIds = (orgUnitIds: string[], parentIdToOrgUnits: Map<string, Department[]>): Set<string> => {
  const result = new Set<string>();

  const orgUnitIdsToCheck = [...orgUnitIds];
  const recursedOrgUnitIds = new Set<string>();
  while (orgUnitIdsToCheck.length) {
    const orgUnitId = orgUnitIdsToCheck.pop();
    if (!orgUnitId) {
      break;
    }

    if (recursedOrgUnitIds.has(orgUnitId)) {
      continue;
    }

    result.add(orgUnitId);
    recursedOrgUnitIds.add(orgUnitId);

    const children = parentIdToOrgUnits.get(orgUnitId) || [];
    for (const newOrgUnit of children) {
      orgUnitIdsToCheck.push(newOrgUnit.id);
    }
  }

  return result;
};

function recurseTreeMapData(data: Record<string, Department[]>, root: Department, newData: Department[]) {
  // passing in the root node, get children, recurse until leaf is reached.
  const child = data[root.id] || [];

  if (child.length > 0) {
    if (!newData) {
      newData = [];
    }

    for (let i = 0; i < child.length; i++) {
      newData.push(child[i]);
      // recursve with current child record
      recurseTreeMapData(data, child[i], newData);
    }
  }
  return newData;
}
