import { Overrides } from "@material-ui/core/styles/overrides";
import { openSansFont, sanchezFont } from "theme/fonts/fonts";

const overrides: Overrides = {
  MuiOutlinedInput: {
    root: {
      "&$focused $notchedOutline": {
        borderWidth: 1
      }
    }
  },
  MuiCssBaseline: {
    "@global": {
      "@font-face": [openSansFont, sanchezFont]
    }
  }
};

export default overrides;
