import { AllProfileData } from "../handlers/tenantProfileInformationHandler";
import { DpiaPdfMarkup, exportPAsAsPDF, PaPdfMarkup } from "../api/paApi";
import { getPdfMaker } from "../utils/pdfLoader";
import i18n from "../i18n";
import { Alignment, Content, Style, StyleDictionary, TDocumentDefinitions } from "pdfmake/interfaces";
import { getTomExportsPdf } from "app/api/tomApi";
import { t } from "i18next";
import { COLLECTION_TYPES, COLLECTIONS } from "app/collections";
import { exportPAProcessorAsPdfMarkupApi } from "../api/processorPAApi";

const styles: StyleDictionary = {
  title: {
    fontSize: 22,
    marginBottom: 15,
    alignment: "center" as Alignment
  },
  header: {
    fontSize: 20,
    marginTop: 20,
    marginBottom: 10
  },
  subHeader: {
    fontSize: 14,
    marginTop: 20,
    marginBottom: 10
  },
  table: {
    marginTop: 10,
    marginBottom: 10,
    lineHeight: 1
  },
  tableHeader: {
    fillColor: "#EBF1FF",
    bold: true,
    lineHeight: 1
  },
  content: {},
  noContent: {
    italics: true,
    color: "#9e9e9e"
  },
  textCenter: {
    alignment: "center" as Alignment
  },
  break: {
    marginBottom: 10
  }
};

const downloadPdf = async (input: {
  readonly collection: COLLECTION_TYPES;
  readonly content: any;
  readonly docName: string;
  readonly lng?: string;
}) => {
  const { collection, content, docName } = input;
  const defaultStyle: Style = {
    fontSize: 12,
    lineHeight: 1.4,
    font: i18n.language === "ko" ? "NotoSansKR" : "Roboto"
  };
  const docDefinition: TDocumentDefinitions = {
    footer: (page: number) => {
      if (page > 1) {
        return getFooter(collection, page);
      }
    },
    content,
    styles,
    defaultStyle,
    pageMargins: 55
  };
  const pdfMaker = await getPdfMaker();
  pdfMaker.createPdf(docDefinition).download(docName);
};

const downloadPdfFromPdfMakeSyntax = async (input: {
  readonly collection: COLLECTION_TYPES;
  readonly pdfMakeSyntax: TDocumentDefinitions;
  readonly docName: string;
}) => {
  const { collection, docName } = input;
  const defaultStyle: Style = {
    font: i18n.language === "ko" ? "NotoSansKR" : "Roboto"
  };
  const docDefinition: TDocumentDefinitions = {
    ...input.pdfMakeSyntax,
    footer: (page: number) => {
      if (page > 1) {
        return getFooter(collection, page);
      }
    },
    defaultStyle
  };
  const pdfMaker = await getPdfMaker();
  pdfMaker.createPdf(docDefinition).download(docName);
};

const getFooter = (collection: COLLECTION_TYPES, page: number): Content => {
  let title = "";
  if (collection === COLLECTIONS.PROCESSES) {
    title = t("pa_export_template:process_heading");
  } else if (collection === COLLECTIONS.DPIAS) {
    title = t("pa_export_template:dpia_heading_singular");
  } else if (collection === COLLECTIONS.TOM) {
    title = t("pa_export_template:measures");
  } else if (collection === COLLECTIONS.PROCESSOR_PAS) {
    title = t("processor_pa_overview:exportFilename");
  }

  return {
    marginTop: 20,
    fontSize: 9,
    columns: [
      {
        alignment: "left",
        text: t("pa_export_template:date") + ": " + new Date().toLocaleDateString("de"),
        marginLeft: 55
      },
      {
        alignment: "center",
        text: title
      },
      { text: t("pa_export_template:page") + page.toString(), alignment: "right", marginRight: 60 }
    ]
  };
};

export const exportProcessesPDF = async (input: {
  readonly paIds: string[];
  readonly exportInfo: AllProfileData;
  readonly isDPIA?: boolean;
}) => {
  const { paIds, isDPIA, exportInfo } = input;
  const exportData = (await exportPAsAsPDF({ paIDs: paIds, exportInfo, isDPIA })).data;

  const t = i18n.t.bind(i18n);
  const processBloclNames = isDPIA
    ? ([
        "title",
        "description",
        "dpiaReason",
        "assessment",
        "dpiaReasonRequired",
        "purposes",
        "personGroups",
        "recipients",
        "periods",
        "proportionality",
        "risks",
        "dataSubjectRights",
        "testAndManagement",
        "measures"
      ] satisfies (keyof DpiaPdfMarkup)[])
    : ([
        "title",
        "controllerName",
        "departmentAndAssignedUser",
        "description",
        "purposes",
        "personGroups",
        "recipients",
        "periods",
        "measures"
      ] satisfies (keyof PaPdfMarkup)[]);

  const processesBlocks = exportData.processes.flatMap((process: any) => {
    return processBloclNames.flatMap(blockname => process[blockname]);
  });

  const content: Content[] = [
    ...(Array.isArray(exportData.coverPage) ? exportData.coverPage : [exportData.coverPage]),
    ...(Array.isArray(exportData.tableOfContent) ? exportData.tableOfContent : [exportData.tableOfContent]),
    ...(Array.isArray(exportData.exportInformation) ? exportData.exportInformation : [exportData.exportInformation]),
    ...processesBlocks
  ];

  const paTypeKey = isDPIA ? "dpia_heading_singular" : "process_heading";
  const docName = `${new Date().toLocaleDateString()} - ${exportInfo.dataController.name} - ${t(
    "pa_export_template:" + paTypeKey
  )}.pdf`;

  await downloadPdf({
    collection: isDPIA ? COLLECTIONS.DPIAS : COLLECTIONS.PROCESSES,
    docName,
    content
  });
};

export const exportTomsPdf = async (tomIDs: string[]) => {
  const exportData = await getTomExportsPdf({ tomIDs });

  const tomBlocks = ["title", "description", "protectionObjectives", "risks", "labels"];
  const content = [
    ...exportData.coverPage,
    ...exportData.toms.flatMap((tom: any) => tomBlocks.flatMap(blockname => tom[blockname]))
  ];

  const docName = new Date().toLocaleDateString() + " - " + t("tom_export_template:tom_header") + ".pdf";
  await downloadPdf({
    collection: COLLECTIONS.TOM,
    docName,
    content
  });
};

export const exportProcessorPAsPDF = async (input: {
  readonly processorPAIds: string[];
  readonly exportInfo: AllProfileData;
}) => {
  const exportData = (await exportPAProcessorAsPdfMarkupApi(input)).data;

  const docName = `${new Date().toLocaleDateString()} - ${input.exportInfo.dataController.name} - ${t(
    "processor_pa_overview:exportFilename"
  )}.pdf`;

  await downloadPdfFromPdfMakeSyntax({
    collection: COLLECTIONS.PROCESSOR_PAS,
    docName,
    pdfMakeSyntax: exportData
  });
};
