import { CircularProgress } from "@material-ui/core";
import { getProcessPageData, patchProcessRisksPageData } from "app/api/paApi";
import { useProcessPage } from "app/contexts/process-page-context";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { RiskIdsAccordion } from "./RiskIdsAccordion";
import { isEqual } from "lodash-es";
import { useEnteringInfoCard } from "hook/useEnteringInfoCard";

export interface ProcessRisksPageModel {
  readonly riskIds: string[];
}

const ProcessRisksPage = (props: { readonly readonly?: boolean }) => {
  const { t } = useTranslation("dpia_four_four_page");
  const { id } = useParams();
  const { onBeforeProcessUpdate, setProcessMeta } = useProcessPage();
  useEnteringInfoCard({
    pathName: `/processes/${id}/risks`,
    infoId: "infocard.dpia.page8"
  });

  const [pageModel, setPageModel] = useState<ProcessRisksPageModel | null>(null);

  useEffect(() => {
    const get = async () => {
      const { processPage, processMeta } = await getProcessPageData({ processId: id || "", page: "risks" });

      setPageModel(processPage);
      setProcessMeta(processMeta);
    };

    if (id) {
      get();
    }
  }, [id, setProcessMeta]);

  const updateRiskIdsCallback = useCallback(
    async (riskIds: string[]) => {
      if (id && pageModel?.riskIds && !isEqual(riskIds, pageModel?.riskIds)) {
        await onBeforeProcessUpdate(async () => {
          setPageModel({ riskIds });
          await patchProcessRisksPageData({ processId: id, payload: { riskIds } });
        });
      }
    },
    [id, onBeforeProcessUpdate, pageModel?.riskIds]
  );

  return (
    <>
      <QuestionnaireSubHeader text={t("title")} />
      {!pageModel && <CircularProgress size={24} />}
      {pageModel && (
        <RiskIdsAccordion
          title={t("title")}
          onRiskIdsChange={updateRiskIdsCallback}
          initialRiskIds={pageModel.riskIds}
          processingActivityId={id}
          disabled={props.readonly === true}
        />
      )}
    </>
  );
};

export default ProcessRisksPage;
