import IconButton from "@material-ui/core/IconButton";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import React, { useCallback } from "react";
import { useTenantsAutoSelect } from "hook/useTenants";
import { useNavigate } from "react-router-dom";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";

export const TenantSidebar = ({ open }: { open: boolean }) => {
  const { t } = useTranslation("sidebar");
  const navigate = useNavigate();
  const dispatch = useOverviewDispatch();

  const navigateToTenants = useCallback(() => {
    dispatch({
      type: OVERVIEW_ACTIONS.RESET_ALL
    });
    navigate("/tenants");
  }, [dispatch, navigate]);
  const { hasMultiTenants } = useTenantsAutoSelect();
  const { tenantData } = useUserAndTenantData();

  if (!hasMultiTenants) {
    return <></>;
  }

  return (
    <Box
      mt={0.5}
      ml={1}
      mr={0.8} // due to other using px instead of 8px
      mb={1.5}
      pl={open ? 3 : 1}
      pr={open ? 2.2 : 1}
      height={37}
      borderRadius={6}
      style={cursorStyle}
      display="flex"
      justifyContent={open ? "space-between" : "center"}
      alignItems="center"
      bgcolor="alert.infoBackground"
      onClick={navigateToTenants}
    >
      {open && (
        <Box color="alert.info" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" unselectable="on">
          <Tooltip title={tenantData?.name || ""}>
            <Typography variant="h4" align="center" noWrap={true} color="inherit">
              {tenantData?.name || ""}
            </Typography>
          </Tooltip>
        </Box>
      )}
      <Box color="alert.info">
        <Tooltip title={t("switchTenant")}>
          <IconButton
            data-qa="change-tenant"
            aria-label="change-tenant"
            size="small"
            onClick={navigateToTenants}
            color="inherit"
          >
            <SwapHorizIcon color="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

const cursorStyle = { cursor: "pointer" };

export default TenantSidebar;
