import React, { useCallback, useEffect, useMemo, useState } from "react";
import { QuestionProps } from "../../Question";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { UserDTO } from "app/api/user/userApi";
import { t } from "i18next";
import DpoUserIcon from "assets/images/icons/dpoUser.svg";

const DpoUserQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onChange,
  onFocus
}: QuestionProps) => {
  const { getUserNameEmailHook, getUsersByAssignableFieldHook } = useUserAndTenantData();
  const [assignableUsers, setAssignableUsers] = useState<UserDTO[]>([]);

  const getUsersByAssignableField = useCallback(async () => {
    setAssignableUsers(await getUsersByAssignableFieldHook("dpo"));
  }, [getUsersByAssignableFieldHook]);

  useEffect(() => {
    getUsersByAssignableField();
  }, [getUsersByAssignableField]);

  const _value: string = Array.isArray(value) ? value[0] : (value as string);

  const optionIds: string[] = useMemo(
    () =>
      assignableUsers.reduce<string[]>((acc, next) => {
        if (next.id) {
          return [...acc, next.id];
        } else return acc;
      }, []),
    [assignableUsers]
  );

  const updateSelected = useCallback(
    userId => {
      onChange?.([userId]);
    },
    [onChange]
  );

  return (
    <MultiAutocomplete
      id={"DpoUserQuestion"}
      selected={_value}
      options={optionIds}
      icon={<DpoUserIcon />}
      disableClearable={true}
      freeSolo={false}
      updateSelected={updateSelected}
      getOptionLabel={getUserNameEmailHook}
      disabled={disabled}
      hasMultiSelect={false}
      label={questionName || t("general_page:dpo")}
      error={error}
      helperText={helperText}
      onFocus={onFocus}
    />
  );
};

export default React.memo(DpoUserQuestion);
