/* tslint:disable */
/* eslint-disable */
/**
 * Asset Service API
 * Asset Service API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'creatorUID': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'updaterUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'tenantId': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'assetId': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'resourceAssetTypeId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'assetOwnerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'riskOwnerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'status'?: AssetStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Asset
     */
    'labelIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Asset
     */
    'orgUnitIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<DataLocation>}
     * @memberof Asset
     */
    'dataLocations': Array<DataLocation> | null;
}

export const AssetStatusEnum = {
    Planned: 'PLANNED',
    Testing: 'TESTING',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type AssetStatusEnum = typeof AssetStatusEnum[keyof typeof AssetStatusEnum];

/**
 * 
 * @export
 * @interface AssetAllOf
 */
export interface AssetAllOf {
    /**
     * 
     * @type {string}
     * @memberof AssetAllOf
     */
    'tenantId': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOf
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOf
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOf
     */
    'assetId': string;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOf
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOf
     */
    'resourceAssetTypeId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AssetAllOf
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOf
     */
    'assetOwnerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOf
     */
    'riskOwnerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOf
     */
    'status'?: AssetAllOfStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetAllOf
     */
    'labelIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetAllOf
     */
    'orgUnitIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<DataLocation>}
     * @memberof AssetAllOf
     */
    'dataLocations': Array<DataLocation> | null;
}

export const AssetAllOfStatusEnum = {
    Planned: 'PLANNED',
    Testing: 'TESTING',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type AssetAllOfStatusEnum = typeof AssetAllOfStatusEnum[keyof typeof AssetAllOfStatusEnum];

/**
 * 
 * @export
 * @interface AssetBadge
 */
export interface AssetBadge {
    /**
     * The kind of badge, such as \"warning\" or \"success\".
     * @type {string}
     * @memberof AssetBadge
     */
    'kind': string;
}
/**
 * 
 * @export
 * @interface AssetDTO
 */
export interface AssetDTO {
    /**
     * 
     * @type {string}
     * @memberof AssetDTO
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDTO
     */
    'assetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetDTO
     */
    'resourceAssetTypeId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AssetDTO
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AssetDTO
     */
    'assetOwnerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetDTO
     */
    'riskOwnerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetDTO
     */
    'status'?: AssetDTOStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetDTO
     */
    'labelIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetDTO
     */
    'orgUnitIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetDTO
     */
    'dataLocationIds'?: Array<string> | null;
}

export const AssetDTOStatusEnum = {
    Planned: 'PLANNED',
    Testing: 'TESTING',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Null: 'null'
} as const;

export type AssetDTOStatusEnum = typeof AssetDTOStatusEnum[keyof typeof AssetDTOStatusEnum];

/**
 * 
 * @export
 * @interface AssetOverviewDTO
 */
export interface AssetOverviewDTO {
    /**
     * 
     * @type {string}
     * @memberof AssetOverviewDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssetOverviewDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AssetOverviewDTO
     */
    'creatorUID': string;
    /**
     * 
     * @type {string}
     * @memberof AssetOverviewDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AssetOverviewDTO
     */
    'subTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetOverviewDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof AssetOverviewDTO
     */
    'resourceAssetTypeId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetOverviewDTO
     */
    'labelIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetOverviewDTO
     */
    'orgUnitIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetOverviewDTO
     */
    'dataLocationIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<DataLocation>}
     * @memberof AssetOverviewDTO
     */
    'dataLocations'?: Array<DataLocation> | null;
    /**
     * 
     * @type {Array<AssetOverviewDTODisableActionsInner>}
     * @memberof AssetOverviewDTO
     */
    'disableActions'?: Array<AssetOverviewDTODisableActionsInner> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetOverviewDTO
     */
    'seen'?: boolean | null;
    /**
     * 
     * @type {Array<AssetOverviewDTOBadgesInner>}
     * @memberof AssetOverviewDTO
     */
    'badges': Array<AssetOverviewDTOBadgesInner>;
}
/**
 * 
 * @export
 * @interface AssetOverviewDTOBadgesInner
 */
export interface AssetOverviewDTOBadgesInner {
    /**
     * 
     * @type {string}
     * @memberof AssetOverviewDTOBadgesInner
     */
    'kind'?: string;
}
/**
 * 
 * @export
 * @interface AssetOverviewDTODisableActionsInner
 */
export interface AssetOverviewDTODisableActionsInner {
    /**
     * 
     * @type {string}
     * @memberof AssetOverviewDTODisableActionsInner
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface AssetPayload
 */
export interface AssetPayload {
    /**
     * 
     * @type {string}
     * @memberof AssetPayload
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AssetPayload
     */
    'assetId': string;
    /**
     * 
     * @type {string}
     * @memberof AssetPayload
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetPayload
     */
    'resourceAssetTypeId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AssetPayload
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AssetPayload
     */
    'assetOwnerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetPayload
     */
    'riskOwnerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetPayload
     */
    'status'?: AssetPayloadStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetPayload
     */
    'labelIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetPayload
     */
    'orgUnitIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetPayload
     */
    'dataLocationIds'?: Array<string> | null;
}

export const AssetPayloadStatusEnum = {
    Planned: 'PLANNED',
    Testing: 'TESTING',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type AssetPayloadStatusEnum = typeof AssetPayloadStatusEnum[keyof typeof AssetPayloadStatusEnum];

/**
 * 
 * @export
 * @interface AssetShortDTO
 */
export interface AssetShortDTO {
    /**
     * 
     * @type {string}
     * @memberof AssetShortDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetShortDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetShortDTO
     */
    'status'?: AssetShortDTOStatusEnum;
}

export const AssetShortDTOStatusEnum = {
    Planned: 'PLANNED',
    Testing: 'TESTING',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type AssetShortDTOStatusEnum = typeof AssetShortDTOStatusEnum[keyof typeof AssetShortDTOStatusEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const AssetStatus = {
    Planned: 'PLANNED',
    Testing: 'TESTING',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type AssetStatus = typeof AssetStatus[keyof typeof AssetStatus];


/**
 * 
 * @export
 * @interface AssetsDTO
 */
export interface AssetsDTO {
    /**
     * 
     * @type {Array<AssetDTO>}
     * @memberof AssetsDTO
     */
    'items'?: Array<AssetDTO>;
}
/**
 * 
 * @export
 * @interface AssetsInProces
 */
export interface AssetsInProces {
    /**
     * The unique identifier of the asset being processed.
     * @type {string}
     * @memberof AssetsInProces
     */
    'id': string;
    /**
     * The name of the asset being processed.
     * @type {string}
     * @memberof AssetsInProces
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetsInProces
     */
    'assetIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AssetsOverviewDTO
 */
export interface AssetsOverviewDTO {
    /**
     * 
     * @type {Array<AssetOverviewDTO>}
     * @memberof AssetsOverviewDTO
     */
    'items'?: Array<AssetOverviewDTO>;
    /**
     * 
     * @type {Array<LabelDTO>}
     * @memberof AssetsOverviewDTO
     */
    'labels'?: Array<LabelDTO>;
    /**
     * 
     * @type {Array<OrgUnitDTO>}
     * @memberof AssetsOverviewDTO
     */
    'orgUnits'?: Array<OrgUnitDTO>;
    /**
     * 
     * @type {Array<DataLocation>}
     * @memberof AssetsOverviewDTO
     */
    'dataLocations'?: Array<DataLocation>;
}
/**
 * 
 * @export
 * @interface AssetsShortDTO
 */
export interface AssetsShortDTO {
    /**
     * 
     * @type {Array<AssetShortDTO>}
     * @memberof AssetsShortDTO
     */
    'items'?: Array<AssetShortDTO>;
}
/**
 * 
 * @export
 * @interface Badge
 */
export interface Badge {
    /**
     * 
     * @type {boolean}
     * @memberof Badge
     */
    'seen'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    'kind'?: string;
}
/**
 * 
 * @export
 * @interface CopyDataAsset
 */
export interface CopyDataAsset {
    /**
     * 
     * @type {string}
     * @memberof CopyDataAsset
     */
    'parentDataAssetId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CopyDataAsset
     */
    'dataAssetName': string;
}
/**
 * 
 * @export
 * @interface CreateAssetDTO
 */
export interface CreateAssetDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateAssetDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetDTO
     */
    'resourceAssetTypeId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateAssetDTO
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetDTO
     */
    'assetOwnerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetDTO
     */
    'riskOwnerId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAssetDTO
     */
    'labelIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAssetDTO
     */
    'orgUnitIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface CreateDataAssetRequest
 */
export interface CreateDataAssetRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDataAssetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDataAssetRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {DataAssetType}
     * @memberof CreateDataAssetRequest
     */
    'assetType': DataAssetType;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDataAssetRequest
     */
    'unseen'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDataAssetRequest
     */
    'parentDataAssetId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDataAssetRequest
     */
    'dataClassificationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDataAssetRequest
     */
    'mergedIntoId'?: string | null;
}


/**
 * 
 * @export
 * @interface DataAsset
 */
export interface DataAsset {
    /**
     * 
     * @type {string}
     * @memberof DataAsset
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAsset
     */
    'description'?: string | null;
    /**
     * 
     * @type {DataAssetType}
     * @memberof DataAsset
     */
    'assetType'?: DataAssetType;
    /**
     * 
     * @type {boolean}
     * @memberof DataAsset
     */
    'unseen'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DataAsset
     */
    'parentDataAssetId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataAsset
     */
    'dataClassificationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataAsset
     */
    'mergedIntoId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataAsset
     */
    'creatorUID': string;
    /**
     * 
     * @type {string}
     * @memberof DataAsset
     */
    'updaterUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAsset
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DataAsset
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAsset
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAsset
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<DataAsset>}
     * @memberof DataAsset
     */
    'dataAssets'?: Array<DataAsset>;
}


/**
 * 
 * @export
 * @interface DataAssetAllOf
 */
export interface DataAssetAllOf {
    /**
     * 
     * @type {string}
     * @memberof DataAssetAllOf
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAssetAllOf
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<DataAsset>}
     * @memberof DataAssetAllOf
     */
    'dataAssets'?: Array<DataAsset>;
}
/**
 * 
 * @export
 * @interface DataAssetDTO
 */
export interface DataAssetDTO {
    /**
     * 
     * @type {string}
     * @memberof DataAssetDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAssetDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {DataAssetType}
     * @memberof DataAssetDTO
     */
    'assetType'?: DataAssetType;
    /**
     * 
     * @type {boolean}
     * @memberof DataAssetDTO
     */
    'unseen'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DataAssetDTO
     */
    'parentDataAssetId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataAssetDTO
     */
    'dataClassificationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataAssetDTO
     */
    'mergedIntoId'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DataAssetType = {
    PersonGroup: 'PERSON_GROUP',
    DataCategory: 'DATA_CATEGORY',
    DataType: 'DATA_TYPE'
} as const;

export type DataAssetType = typeof DataAssetType[keyof typeof DataAssetType];


/**
 * 
 * @export
 * @interface DataCategory
 */
export interface DataCategory {
    /**
     * 
     * @type {string}
     * @memberof DataCategory
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataCategory
     */
    'dataCategoryKey': string;
    /**
     * 
     * @type {Array<DataType>}
     * @memberof DataCategory
     */
    'dataTypes': Array<DataType>;
}
/**
 * 
 * @export
 * @interface DataLocation
 */
export interface DataLocation {
    /**
     * 
     * @type {string}
     * @memberof DataLocation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DataLocation
     */
    'nameKey': string;
    /**
     * 
     * @type {string}
     * @memberof DataLocation
     */
    'mergedIntoId'?: string | null;
}
/**
 * 
 * @export
 * @interface DataType
 */
export interface DataType {
    /**
     * 
     * @type {string}
     * @memberof DataType
     */
    'dataTypeKey': string;
    /**
     * 
     * @type {string}
     * @memberof DataType
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DataType
     */
    'dataClassificationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataType
     */
    'mergedIntoId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DataType
     */
    'unseen'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface GetAssetProcesses200Response
 */
export interface GetAssetProcesses200Response {
    /**
     * 
     * @type {Array<ProcessingActivityOverviewDTO>}
     * @memberof GetAssetProcesses200Response
     */
    'items'?: Array<ProcessingActivityOverviewDTO>;
}
/**
 * 
 * @export
 * @interface GetAssets200Response
 */
export interface GetAssets200Response {
    /**
     * 
     * @type {Array<AssetShortDTO>}
     * @memberof GetAssets200Response
     */
    'items'?: Array<AssetShortDTO>;
}
/**
 * 
 * @export
 * @interface GetDataAssets200Response
 */
export interface GetDataAssets200Response {
    /**
     * 
     * @type {GetDataAssets200ResponsePagination}
     * @memberof GetDataAssets200Response
     */
    'pagination'?: GetDataAssets200ResponsePagination;
    /**
     * 
     * @type {Array<DataAsset>}
     * @memberof GetDataAssets200Response
     */
    'items'?: Array<DataAsset>;
}
/**
 * 
 * @export
 * @interface GetDataAssets200ResponsePagination
 */
export interface GetDataAssets200ResponsePagination {
    /**
     * 
     * @type {string}
     * @memberof GetDataAssets200ResponsePagination
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetDataAssets200ResponsePagination
     */
    'hasMore'?: boolean;
}
/**
 * 
 * @export
 * @interface GetTenantPersonGroups200Response
 */
export interface GetTenantPersonGroups200Response {
    /**
     * 
     * @type {Array<PersonGroup>}
     * @memberof GetTenantPersonGroups200Response
     */
    'items'?: Array<PersonGroup>;
}
/**
 * 
 * @export
 * @interface LabelDTO
 */
export interface LabelDTO {
    /**
     * The unique identifier of the label.
     * @type {string}
     * @memberof LabelDTO
     */
    'id': string;
    /**
     * The key of the label name that can be used to look up the localized name in the UI.
     * @type {string}
     * @memberof LabelDTO
     */
    'nameKey': string;
}
/**
 * 
 * @export
 * @interface MarkAllAssetsAsRead200Response
 */
export interface MarkAllAssetsAsRead200Response {
    /**
     * 
     * @type {number}
     * @memberof MarkAllAssetsAsRead200Response
     */
    'marked'?: number;
}
/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'creatorUID': string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'updaterUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface OkResponse
 */
export interface OkResponse {
    /**
     * 
     * @type {string}
     * @memberof OkResponse
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface OrgUnitDTO
 */
export interface OrgUnitDTO {
    /**
     * The unique identifier of the organizational unit.
     * @type {string}
     * @memberof OrgUnitDTO
     */
    'id': string;
    /**
     * The name of the organizational unit.
     * @type {string}
     * @memberof OrgUnitDTO
     */
    'name': string;
    /**
     * The unique identifier of the parent organizational unit. If null, then this is a top-level unit.
     * @type {string}
     * @memberof OrgUnitDTO
     */
    'parentId'?: string;
}
/**
 * 
 * @export
 * @interface PersonGroup
 */
export interface PersonGroup {
    /**
     * 
     * @type {string}
     * @memberof PersonGroup
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonGroup
     */
    'personGroupKey': string;
    /**
     * 
     * @type {Array<DataCategory>}
     * @memberof PersonGroup
     */
    'dataCategories': Array<DataCategory>;
}
/**
 * 
 * @export
 * @interface ProcessingActivitiesOverviewDTO
 */
export interface ProcessingActivitiesOverviewDTO {
    /**
     * 
     * @type {Array<ProcessingActivityOverviewDTO>}
     * @memberof ProcessingActivitiesOverviewDTO
     */
    'items'?: Array<ProcessingActivityOverviewDTO>;
}
/**
 * 
 * @export
 * @interface ProcessingActivityOverviewDTO
 */
export interface ProcessingActivityOverviewDTO {
    /**
     * 
     * @type {string}
     * @memberof ProcessingActivityOverviewDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessingActivityOverviewDTO
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessingActivityOverviewDTO
     */
    'subTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessingActivityOverviewDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessingActivityOverviewDTO
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<Badge>}
     * @memberof ProcessingActivityOverviewDTO
     */
    'badges'?: Array<Badge>;
}
/**
 * 
 * @export
 * @interface UpdateAssetDTO
 */
export interface UpdateAssetDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetDTO
     */
    'resourceAssetTypeId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAssetDTO
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetDTO
     */
    'assetOwnerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetDTO
     */
    'riskOwnerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetDTO
     */
    'status'?: UpdateAssetDTOStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAssetDTO
     */
    'labelIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAssetDTO
     */
    'orgUnitIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAssetDTO
     */
    'dataLocationIds'?: Array<string> | null;
}

export const UpdateAssetDTOStatusEnum = {
    Planned: 'PLANNED',
    Testing: 'TESTING',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Null: 'null'
} as const;

export type UpdateAssetDTOStatusEnum = typeof UpdateAssetDTOStatusEnum[keyof typeof UpdateAssetDTOStatusEnum];

/**
 * 
 * @export
 * @interface UpdateDataAssetRequest
 */
export interface UpdateDataAssetRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDataAssetRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataAssetRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataAssetRequest
     */
    'parentDataAssetId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataAssetRequest
     */
    'dataClassificationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataAssetRequest
     */
    'mergedIntoId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDataAssetRequest
     */
    'unseen'?: boolean | null;
}

/**
 * AssetInternalApi - axios parameter creator
 * @export
 */
export const AssetInternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create tenant asset
         * @summary Create tenant asset
         * @param {string} tenantId Tenant id
         * @param {CreateAssetDTO} createAssetDTO Asset model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenantAsset: async (tenantId: string, createAssetDTO: CreateAssetDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createTenantAsset', 'tenantId', tenantId)
            // verify required parameter 'createAssetDTO' is not null or undefined
            assertParamExists('createTenantAsset', 'createAssetDTO', createAssetDTO)
            const localVarPath = `/api/v1/internal/tenants/{tenantId}/assets`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAssetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get tenant assets
         * @summary Get tenant assets
         * @param {string} tenantId Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantAssets: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTenantAssets', 'tenantId', tenantId)
            const localVarPath = `/api/v1/internal/tenants/{tenantId}/assets`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get tenant person groups
         * @summary Get tenant person groups
         * @param {string} tenantId Tenant id
         * @param {string} [showMerged] Show merged when true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantPersonGroups: async (tenantId: string, showMerged?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTenantPersonGroups', 'tenantId', tenantId)
            const localVarPath = `/api/v1/internal/tenants/{tenantId}/overview/person-groups`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (showMerged !== undefined) {
                localVarQueryParameter['showMerged'] = showMerged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetInternalApi - functional programming interface
 * @export
 */
export const AssetInternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetInternalApiAxiosParamCreator(configuration)
    return {
        /**
         * Create tenant asset
         * @summary Create tenant asset
         * @param {string} tenantId Tenant id
         * @param {CreateAssetDTO} createAssetDTO Asset model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTenantAsset(tenantId: string, createAssetDTO: CreateAssetDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTenantAsset(tenantId, createAssetDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get tenant assets
         * @summary Get tenant assets
         * @param {string} tenantId Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantAssets(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAssets200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantAssets(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get tenant person groups
         * @summary Get tenant person groups
         * @param {string} tenantId Tenant id
         * @param {string} [showMerged] Show merged when true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantPersonGroups(tenantId: string, showMerged?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTenantPersonGroups200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantPersonGroups(tenantId, showMerged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetInternalApi - factory interface
 * @export
 */
export const AssetInternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetInternalApiFp(configuration)
    return {
        /**
         * Create tenant asset
         * @summary Create tenant asset
         * @param {string} tenantId Tenant id
         * @param {CreateAssetDTO} createAssetDTO Asset model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenantAsset(tenantId: string, createAssetDTO: CreateAssetDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createTenantAsset(tenantId, createAssetDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Get tenant assets
         * @summary Get tenant assets
         * @param {string} tenantId Tenant id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantAssets(tenantId: string, options?: any): AxiosPromise<GetAssets200Response> {
            return localVarFp.getTenantAssets(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get tenant person groups
         * @summary Get tenant person groups
         * @param {string} tenantId Tenant id
         * @param {string} [showMerged] Show merged when true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantPersonGroups(tenantId: string, showMerged?: string, options?: any): AxiosPromise<GetTenantPersonGroups200Response> {
            return localVarFp.getTenantPersonGroups(tenantId, showMerged, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetInternalApi - object-oriented interface
 * @export
 * @class AssetInternalApi
 * @extends {BaseAPI}
 */
export class AssetInternalApi extends BaseAPI {
    /**
     * Create tenant asset
     * @summary Create tenant asset
     * @param {string} tenantId Tenant id
     * @param {CreateAssetDTO} createAssetDTO Asset model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetInternalApi
     */
    public createTenantAsset(tenantId: string, createAssetDTO: CreateAssetDTO, options?: AxiosRequestConfig) {
        return AssetInternalApiFp(this.configuration).createTenantAsset(tenantId, createAssetDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get tenant assets
     * @summary Get tenant assets
     * @param {string} tenantId Tenant id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetInternalApi
     */
    public getTenantAssets(tenantId: string, options?: AxiosRequestConfig) {
        return AssetInternalApiFp(this.configuration).getTenantAssets(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get tenant person groups
     * @summary Get tenant person groups
     * @param {string} tenantId Tenant id
     * @param {string} [showMerged] Show merged when true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetInternalApi
     */
    public getTenantPersonGroups(tenantId: string, showMerged?: string, options?: AxiosRequestConfig) {
        return AssetInternalApiFp(this.configuration).getTenantPersonGroups(tenantId, showMerged, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssetServiceApi - axios parameter creator
 * @export
 */
export const AssetServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create data asset
         * @summary Create data asset
         * @param {CreateDataAssetRequest} createDataAssetRequest Data asset model
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataAsset: async (createDataAssetRequest: CreateDataAssetRequest, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDataAssetRequest' is not null or undefined
            assertParamExists('createDataAsset', 'createDataAssetRequest', createDataAssetRequest)
            const localVarPath = `/api/v1/data-assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDataAssetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete data asset
         * @summary Delete data asset
         * @param {string} id Data asset id
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataAsset: async (id: string, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDataAsset', 'id', id)
            const localVarPath = `/api/v1/data-assets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Duplicate data asset
         * @summary Duplicate data asset
         * @param {string} id Data asset id
         * @param {string} acceptLanguage Accept-Language from i18n
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateDataAsset: async (id: string, acceptLanguage: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('duplicateDataAsset', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('duplicateDataAsset', 'acceptLanguage', acceptLanguage)
            const localVarPath = `/api/v1/data-assets/{id}/duplicate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data asset
         * @summary Get data asset
         * @param {string} id Data asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAsset: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDataAsset', 'id', id)
            const localVarPath = `/api/v1/data-assets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data assets
         * @summary Get data assets
         * @param {DataAssetType} [assetType] Asset type
         * @param {string} [parentDataAssetId] Parent data asset id
         * @param {boolean} [unseen] Unseen
         * @param {string} [name] Asset name
         * @param {number} [limit] Limit
         * @param {string} [cursor] Cursor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAssets: async (assetType?: DataAssetType, parentDataAssetId?: string, unseen?: boolean, name?: string, limit?: number, cursor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/data-assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assetType !== undefined) {
                localVarQueryParameter['assetType'] = assetType;
            }

            if (parentDataAssetId !== undefined) {
                localVarQueryParameter['parentDataAssetId'] = parentDataAssetId;
            }

            if (unseen !== undefined) {
                localVarQueryParameter['unseen'] = unseen;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get person groups
         * @summary Get person groups
         * @param {string} [showMerged] Show merged when true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonGroups: async (showMerged?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/data-assets/person-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (showMerged !== undefined) {
                localVarQueryParameter['showMerged'] = showMerged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update data asset
         * @summary Update data asset
         * @param {string} id Data asset id
         * @param {UpdateDataAssetRequest} updateDataAssetRequest Data asset model
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataAsset: async (id: string, updateDataAssetRequest: UpdateDataAssetRequest, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDataAsset', 'id', id)
            // verify required parameter 'updateDataAssetRequest' is not null or undefined
            assertParamExists('updateDataAsset', 'updateDataAssetRequest', updateDataAssetRequest)
            const localVarPath = `/api/v1/data-assets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataAssetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetServiceApi - functional programming interface
 * @export
 */
export const AssetServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * Create data asset
         * @summary Create data asset
         * @param {CreateDataAssetRequest} createDataAssetRequest Data asset model
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDataAsset(createDataAssetRequest: CreateDataAssetRequest, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDataAsset(createDataAssetRequest, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete data asset
         * @summary Delete data asset
         * @param {string} id Data asset id
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDataAsset(id: string, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDataAsset(id, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Duplicate data asset
         * @summary Duplicate data asset
         * @param {string} id Data asset id
         * @param {string} acceptLanguage Accept-Language from i18n
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateDataAsset(id: string, acceptLanguage: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateDataAsset(id, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get data asset
         * @summary Get data asset
         * @param {string} id Data asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataAsset(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataAsset(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get data assets
         * @summary Get data assets
         * @param {DataAssetType} [assetType] Asset type
         * @param {string} [parentDataAssetId] Parent data asset id
         * @param {boolean} [unseen] Unseen
         * @param {string} [name] Asset name
         * @param {number} [limit] Limit
         * @param {string} [cursor] Cursor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataAssets(assetType?: DataAssetType, parentDataAssetId?: string, unseen?: boolean, name?: string, limit?: number, cursor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDataAssets200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataAssets(assetType, parentDataAssetId, unseen, name, limit, cursor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get person groups
         * @summary Get person groups
         * @param {string} [showMerged] Show merged when true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonGroups(showMerged?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTenantPersonGroups200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonGroups(showMerged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update data asset
         * @summary Update data asset
         * @param {string} id Data asset id
         * @param {UpdateDataAssetRequest} updateDataAssetRequest Data asset model
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDataAsset(id: string, updateDataAssetRequest: UpdateDataAssetRequest, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDataAsset(id, updateDataAssetRequest, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetServiceApi - factory interface
 * @export
 */
export const AssetServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetServiceApiFp(configuration)
    return {
        /**
         * Create data asset
         * @summary Create data asset
         * @param {CreateDataAssetRequest} createDataAssetRequest Data asset model
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataAsset(createDataAssetRequest: CreateDataAssetRequest, wait?: string, options?: any): AxiosPromise<void> {
            return localVarFp.createDataAsset(createDataAssetRequest, wait, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete data asset
         * @summary Delete data asset
         * @param {string} id Data asset id
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataAsset(id: string, wait?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDataAsset(id, wait, options).then((request) => request(axios, basePath));
        },
        /**
         * Duplicate data asset
         * @summary Duplicate data asset
         * @param {string} id Data asset id
         * @param {string} acceptLanguage Accept-Language from i18n
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateDataAsset(id: string, acceptLanguage: string, options?: any): AxiosPromise<void> {
            return localVarFp.duplicateDataAsset(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data asset
         * @summary Get data asset
         * @param {string} id Data asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAsset(id: string, options?: any): AxiosPromise<DataAsset> {
            return localVarFp.getDataAsset(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data assets
         * @summary Get data assets
         * @param {DataAssetType} [assetType] Asset type
         * @param {string} [parentDataAssetId] Parent data asset id
         * @param {boolean} [unseen] Unseen
         * @param {string} [name] Asset name
         * @param {number} [limit] Limit
         * @param {string} [cursor] Cursor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAssets(assetType?: DataAssetType, parentDataAssetId?: string, unseen?: boolean, name?: string, limit?: number, cursor?: string, options?: any): AxiosPromise<GetDataAssets200Response> {
            return localVarFp.getDataAssets(assetType, parentDataAssetId, unseen, name, limit, cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * Get person groups
         * @summary Get person groups
         * @param {string} [showMerged] Show merged when true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonGroups(showMerged?: string, options?: any): AxiosPromise<GetTenantPersonGroups200Response> {
            return localVarFp.getPersonGroups(showMerged, options).then((request) => request(axios, basePath));
        },
        /**
         * Update data asset
         * @summary Update data asset
         * @param {string} id Data asset id
         * @param {UpdateDataAssetRequest} updateDataAssetRequest Data asset model
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataAsset(id: string, updateDataAssetRequest: UpdateDataAssetRequest, wait?: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateDataAsset(id, updateDataAssetRequest, wait, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetServiceApi - object-oriented interface
 * @export
 * @class AssetServiceApi
 * @extends {BaseAPI}
 */
export class AssetServiceApi extends BaseAPI {
    /**
     * Create data asset
     * @summary Create data asset
     * @param {CreateDataAssetRequest} createDataAssetRequest Data asset model
     * @param {string} [wait] Wait for the command to be projected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetServiceApi
     */
    public createDataAsset(createDataAssetRequest: CreateDataAssetRequest, wait?: string, options?: AxiosRequestConfig) {
        return AssetServiceApiFp(this.configuration).createDataAsset(createDataAssetRequest, wait, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete data asset
     * @summary Delete data asset
     * @param {string} id Data asset id
     * @param {string} [wait] Wait for the command to be projected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetServiceApi
     */
    public deleteDataAsset(id: string, wait?: string, options?: AxiosRequestConfig) {
        return AssetServiceApiFp(this.configuration).deleteDataAsset(id, wait, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Duplicate data asset
     * @summary Duplicate data asset
     * @param {string} id Data asset id
     * @param {string} acceptLanguage Accept-Language from i18n
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetServiceApi
     */
    public duplicateDataAsset(id: string, acceptLanguage: string, options?: AxiosRequestConfig) {
        return AssetServiceApiFp(this.configuration).duplicateDataAsset(id, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data asset
     * @summary Get data asset
     * @param {string} id Data asset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetServiceApi
     */
    public getDataAsset(id: string, options?: AxiosRequestConfig) {
        return AssetServiceApiFp(this.configuration).getDataAsset(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data assets
     * @summary Get data assets
     * @param {DataAssetType} [assetType] Asset type
     * @param {string} [parentDataAssetId] Parent data asset id
     * @param {boolean} [unseen] Unseen
     * @param {string} [name] Asset name
     * @param {number} [limit] Limit
     * @param {string} [cursor] Cursor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetServiceApi
     */
    public getDataAssets(assetType?: DataAssetType, parentDataAssetId?: string, unseen?: boolean, name?: string, limit?: number, cursor?: string, options?: AxiosRequestConfig) {
        return AssetServiceApiFp(this.configuration).getDataAssets(assetType, parentDataAssetId, unseen, name, limit, cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get person groups
     * @summary Get person groups
     * @param {string} [showMerged] Show merged when true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetServiceApi
     */
    public getPersonGroups(showMerged?: string, options?: AxiosRequestConfig) {
        return AssetServiceApiFp(this.configuration).getPersonGroups(showMerged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update data asset
     * @summary Update data asset
     * @param {string} id Data asset id
     * @param {UpdateDataAssetRequest} updateDataAssetRequest Data asset model
     * @param {string} [wait] Wait for the command to be projected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetServiceApi
     */
    public updateDataAsset(id: string, updateDataAssetRequest: UpdateDataAssetRequest, wait?: string, options?: AxiosRequestConfig) {
        return AssetServiceApiFp(this.configuration).updateDataAsset(id, updateDataAssetRequest, wait, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataAssetApi - axios parameter creator
 * @export
 */
export const DataAssetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create data asset
         * @summary Create data asset
         * @param {CreateDataAssetRequest} createDataAssetRequest Data asset model
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataAsset: async (createDataAssetRequest: CreateDataAssetRequest, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDataAssetRequest' is not null or undefined
            assertParamExists('createDataAsset', 'createDataAssetRequest', createDataAssetRequest)
            const localVarPath = `/api/v1/data-assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDataAssetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete data asset
         * @summary Delete data asset
         * @param {string} id Data asset id
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataAsset: async (id: string, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDataAsset', 'id', id)
            const localVarPath = `/api/v1/data-assets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Duplicate data asset
         * @summary Duplicate data asset
         * @param {string} id Data asset id
         * @param {string} acceptLanguage Accept-Language from i18n
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateDataAsset: async (id: string, acceptLanguage: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('duplicateDataAsset', 'id', id)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('duplicateDataAsset', 'acceptLanguage', acceptLanguage)
            const localVarPath = `/api/v1/data-assets/{id}/duplicate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data asset
         * @summary Get data asset
         * @param {string} id Data asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAsset: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDataAsset', 'id', id)
            const localVarPath = `/api/v1/data-assets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data assets
         * @summary Get data assets
         * @param {DataAssetType} [assetType] Asset type
         * @param {string} [parentDataAssetId] Parent data asset id
         * @param {boolean} [unseen] Unseen
         * @param {string} [name] Asset name
         * @param {number} [limit] Limit
         * @param {string} [cursor] Cursor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAssets: async (assetType?: DataAssetType, parentDataAssetId?: string, unseen?: boolean, name?: string, limit?: number, cursor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/data-assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assetType !== undefined) {
                localVarQueryParameter['assetType'] = assetType;
            }

            if (parentDataAssetId !== undefined) {
                localVarQueryParameter['parentDataAssetId'] = parentDataAssetId;
            }

            if (unseen !== undefined) {
                localVarQueryParameter['unseen'] = unseen;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get person groups
         * @summary Get person groups
         * @param {string} [showMerged] Show merged when true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonGroups: async (showMerged?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/data-assets/person-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (showMerged !== undefined) {
                localVarQueryParameter['showMerged'] = showMerged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mark all data assets as read
         * @summary Mark all data assets as read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAllDataAssetsAsRead: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/data-assets/overview/mark-all-as-read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update data asset
         * @summary Update data asset
         * @param {string} id Data asset id
         * @param {UpdateDataAssetRequest} updateDataAssetRequest Data asset model
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataAsset: async (id: string, updateDataAssetRequest: UpdateDataAssetRequest, wait?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDataAsset', 'id', id)
            // verify required parameter 'updateDataAssetRequest' is not null or undefined
            assertParamExists('updateDataAsset', 'updateDataAssetRequest', updateDataAssetRequest)
            const localVarPath = `/api/v1/data-assets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (wait !== undefined) {
                localVarQueryParameter['wait'] = wait;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataAssetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataAssetApi - functional programming interface
 * @export
 */
export const DataAssetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataAssetApiAxiosParamCreator(configuration)
    return {
        /**
         * Create data asset
         * @summary Create data asset
         * @param {CreateDataAssetRequest} createDataAssetRequest Data asset model
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDataAsset(createDataAssetRequest: CreateDataAssetRequest, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDataAsset(createDataAssetRequest, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete data asset
         * @summary Delete data asset
         * @param {string} id Data asset id
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDataAsset(id: string, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDataAsset(id, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Duplicate data asset
         * @summary Duplicate data asset
         * @param {string} id Data asset id
         * @param {string} acceptLanguage Accept-Language from i18n
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateDataAsset(id: string, acceptLanguage: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateDataAsset(id, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get data asset
         * @summary Get data asset
         * @param {string} id Data asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataAsset(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataAsset(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get data assets
         * @summary Get data assets
         * @param {DataAssetType} [assetType] Asset type
         * @param {string} [parentDataAssetId] Parent data asset id
         * @param {boolean} [unseen] Unseen
         * @param {string} [name] Asset name
         * @param {number} [limit] Limit
         * @param {string} [cursor] Cursor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataAssets(assetType?: DataAssetType, parentDataAssetId?: string, unseen?: boolean, name?: string, limit?: number, cursor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDataAssets200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataAssets(assetType, parentDataAssetId, unseen, name, limit, cursor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get person groups
         * @summary Get person groups
         * @param {string} [showMerged] Show merged when true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonGroups(showMerged?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTenantPersonGroups200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonGroups(showMerged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mark all data assets as read
         * @summary Mark all data assets as read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAllDataAssetsAsRead(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkAllAssetsAsRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAllDataAssetsAsRead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update data asset
         * @summary Update data asset
         * @param {string} id Data asset id
         * @param {UpdateDataAssetRequest} updateDataAssetRequest Data asset model
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDataAsset(id: string, updateDataAssetRequest: UpdateDataAssetRequest, wait?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDataAsset(id, updateDataAssetRequest, wait, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataAssetApi - factory interface
 * @export
 */
export const DataAssetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataAssetApiFp(configuration)
    return {
        /**
         * Create data asset
         * @summary Create data asset
         * @param {CreateDataAssetRequest} createDataAssetRequest Data asset model
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataAsset(createDataAssetRequest: CreateDataAssetRequest, wait?: string, options?: any): AxiosPromise<void> {
            return localVarFp.createDataAsset(createDataAssetRequest, wait, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete data asset
         * @summary Delete data asset
         * @param {string} id Data asset id
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataAsset(id: string, wait?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDataAsset(id, wait, options).then((request) => request(axios, basePath));
        },
        /**
         * Duplicate data asset
         * @summary Duplicate data asset
         * @param {string} id Data asset id
         * @param {string} acceptLanguage Accept-Language from i18n
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateDataAsset(id: string, acceptLanguage: string, options?: any): AxiosPromise<void> {
            return localVarFp.duplicateDataAsset(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data asset
         * @summary Get data asset
         * @param {string} id Data asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAsset(id: string, options?: any): AxiosPromise<DataAsset> {
            return localVarFp.getDataAsset(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data assets
         * @summary Get data assets
         * @param {DataAssetType} [assetType] Asset type
         * @param {string} [parentDataAssetId] Parent data asset id
         * @param {boolean} [unseen] Unseen
         * @param {string} [name] Asset name
         * @param {number} [limit] Limit
         * @param {string} [cursor] Cursor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAssets(assetType?: DataAssetType, parentDataAssetId?: string, unseen?: boolean, name?: string, limit?: number, cursor?: string, options?: any): AxiosPromise<GetDataAssets200Response> {
            return localVarFp.getDataAssets(assetType, parentDataAssetId, unseen, name, limit, cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * Get person groups
         * @summary Get person groups
         * @param {string} [showMerged] Show merged when true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonGroups(showMerged?: string, options?: any): AxiosPromise<GetTenantPersonGroups200Response> {
            return localVarFp.getPersonGroups(showMerged, options).then((request) => request(axios, basePath));
        },
        /**
         * Mark all data assets as read
         * @summary Mark all data assets as read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAllDataAssetsAsRead(options?: any): AxiosPromise<MarkAllAssetsAsRead200Response> {
            return localVarFp.markAllDataAssetsAsRead(options).then((request) => request(axios, basePath));
        },
        /**
         * Update data asset
         * @summary Update data asset
         * @param {string} id Data asset id
         * @param {UpdateDataAssetRequest} updateDataAssetRequest Data asset model
         * @param {string} [wait] Wait for the command to be projected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataAsset(id: string, updateDataAssetRequest: UpdateDataAssetRequest, wait?: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateDataAsset(id, updateDataAssetRequest, wait, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataAssetApi - object-oriented interface
 * @export
 * @class DataAssetApi
 * @extends {BaseAPI}
 */
export class DataAssetApi extends BaseAPI {
    /**
     * Create data asset
     * @summary Create data asset
     * @param {CreateDataAssetRequest} createDataAssetRequest Data asset model
     * @param {string} [wait] Wait for the command to be projected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAssetApi
     */
    public createDataAsset(createDataAssetRequest: CreateDataAssetRequest, wait?: string, options?: AxiosRequestConfig) {
        return DataAssetApiFp(this.configuration).createDataAsset(createDataAssetRequest, wait, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete data asset
     * @summary Delete data asset
     * @param {string} id Data asset id
     * @param {string} [wait] Wait for the command to be projected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAssetApi
     */
    public deleteDataAsset(id: string, wait?: string, options?: AxiosRequestConfig) {
        return DataAssetApiFp(this.configuration).deleteDataAsset(id, wait, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Duplicate data asset
     * @summary Duplicate data asset
     * @param {string} id Data asset id
     * @param {string} acceptLanguage Accept-Language from i18n
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAssetApi
     */
    public duplicateDataAsset(id: string, acceptLanguage: string, options?: AxiosRequestConfig) {
        return DataAssetApiFp(this.configuration).duplicateDataAsset(id, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data asset
     * @summary Get data asset
     * @param {string} id Data asset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAssetApi
     */
    public getDataAsset(id: string, options?: AxiosRequestConfig) {
        return DataAssetApiFp(this.configuration).getDataAsset(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data assets
     * @summary Get data assets
     * @param {DataAssetType} [assetType] Asset type
     * @param {string} [parentDataAssetId] Parent data asset id
     * @param {boolean} [unseen] Unseen
     * @param {string} [name] Asset name
     * @param {number} [limit] Limit
     * @param {string} [cursor] Cursor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAssetApi
     */
    public getDataAssets(assetType?: DataAssetType, parentDataAssetId?: string, unseen?: boolean, name?: string, limit?: number, cursor?: string, options?: AxiosRequestConfig) {
        return DataAssetApiFp(this.configuration).getDataAssets(assetType, parentDataAssetId, unseen, name, limit, cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get person groups
     * @summary Get person groups
     * @param {string} [showMerged] Show merged when true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAssetApi
     */
    public getPersonGroups(showMerged?: string, options?: AxiosRequestConfig) {
        return DataAssetApiFp(this.configuration).getPersonGroups(showMerged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mark all data assets as read
     * @summary Mark all data assets as read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAssetApi
     */
    public markAllDataAssetsAsRead(options?: AxiosRequestConfig) {
        return DataAssetApiFp(this.configuration).markAllDataAssetsAsRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update data asset
     * @summary Update data asset
     * @param {string} id Data asset id
     * @param {UpdateDataAssetRequest} updateDataAssetRequest Data asset model
     * @param {string} [wait] Wait for the command to be projected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAssetApi
     */
    public updateDataAsset(id: string, updateDataAssetRequest: UpdateDataAssetRequest, wait?: string, options?: AxiosRequestConfig) {
        return DataAssetApiFp(this.configuration).updateDataAsset(id, updateDataAssetRequest, wait, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create asset
         * @summary Create asset
         * @param {CreateAssetDTO} createAssetDTO Asset model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAsset: async (createAssetDTO: CreateAssetDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAssetDTO' is not null or undefined
            assertParamExists('createAsset', 'createAssetDTO', createAssetDTO)
            const localVarPath = `/api/v1/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAssetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete asset
         * @summary Delete asset
         * @param {string} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteAsset', 'assetId', assetId)
            const localVarPath = `/api/v1/assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get asset
         * @summary Get asset
         * @param {string} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getAsset', 'assetId', assetId)
            const localVarPath = `/api/v1/assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get asset processes
         * @summary Get asset processes
         * @param {string} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetProcesses: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getAssetProcesses', 'assetId', assetId)
            const localVarPath = `/api/v1/assets/{assetId}/processes`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get assets
         * @summary Get assets
         * @param {Array<string>} [assetIds] Asset ids
         * @param {Array<string>} [orgUnitIds] Org unit ids
         * @param {Array<string>} [statuses] Statuses
         * @param {Array<string>} [resourceAssetTypeIds] Resource asset type ids
         * @param {Array<string>} [dataLocationIds] Data location ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssets: async (assetIds?: Array<string>, orgUnitIds?: Array<string>, statuses?: Array<string>, resourceAssetTypeIds?: Array<string>, dataLocationIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assetIds) {
                localVarQueryParameter['assetIds'] = assetIds;
            }

            if (orgUnitIds) {
                localVarQueryParameter['orgUnitIds'] = orgUnitIds;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (resourceAssetTypeIds) {
                localVarQueryParameter['resourceAssetTypeIds'] = resourceAssetTypeIds;
            }

            if (dataLocationIds) {
                localVarQueryParameter['dataLocationIds'] = dataLocationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Health check
         * @summary Health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mark all assets as read
         * @summary Mark all assets as read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAllAssetsAsRead: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/assets/overview/mark-all-as-read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update asset
         * @summary Update asset
         * @param {string} assetId Asset id
         * @param {UpdateAssetDTO} updateAssetDTO Asset model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAsset: async (assetId: string, updateAssetDTO: UpdateAssetDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updateAsset', 'assetId', assetId)
            // verify required parameter 'updateAssetDTO' is not null or undefined
            assertParamExists('updateAsset', 'updateAssetDTO', updateAssetDTO)
            const localVarPath = `/api/v1/assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAssetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Create asset
         * @summary Create asset
         * @param {CreateAssetDTO} createAssetDTO Asset model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAsset(createAssetDTO: CreateAssetDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAsset(createAssetDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete asset
         * @summary Delete asset
         * @param {string} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAsset(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAsset(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get asset
         * @summary Get asset
         * @param {string} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAsset(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAsset(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get asset processes
         * @summary Get asset processes
         * @param {string} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetProcesses(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAssetProcesses200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetProcesses(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get assets
         * @summary Get assets
         * @param {Array<string>} [assetIds] Asset ids
         * @param {Array<string>} [orgUnitIds] Org unit ids
         * @param {Array<string>} [statuses] Statuses
         * @param {Array<string>} [resourceAssetTypeIds] Resource asset type ids
         * @param {Array<string>} [dataLocationIds] Data location ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssets(assetIds?: Array<string>, orgUnitIds?: Array<string>, statuses?: Array<string>, resourceAssetTypeIds?: Array<string>, dataLocationIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAssets200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssets(assetIds, orgUnitIds, statuses, resourceAssetTypeIds, dataLocationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Health check
         * @summary Health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mark all assets as read
         * @summary Mark all assets as read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAllAssetsAsRead(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkAllAssetsAsRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAllAssetsAsRead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update asset
         * @summary Update asset
         * @param {string} assetId Asset id
         * @param {UpdateAssetDTO} updateAssetDTO Asset model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAsset(assetId: string, updateAssetDTO: UpdateAssetDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAsset(assetId, updateAssetDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Create asset
         * @summary Create asset
         * @param {CreateAssetDTO} createAssetDTO Asset model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAsset(createAssetDTO: CreateAssetDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createAsset(createAssetDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete asset
         * @summary Delete asset
         * @param {string} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset(assetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAsset(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get asset
         * @summary Get asset
         * @param {string} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset(assetId: string, options?: any): AxiosPromise<AssetDTO> {
            return localVarFp.getAsset(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get asset processes
         * @summary Get asset processes
         * @param {string} assetId Asset id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetProcesses(assetId: string, options?: any): AxiosPromise<GetAssetProcesses200Response> {
            return localVarFp.getAssetProcesses(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get assets
         * @summary Get assets
         * @param {Array<string>} [assetIds] Asset ids
         * @param {Array<string>} [orgUnitIds] Org unit ids
         * @param {Array<string>} [statuses] Statuses
         * @param {Array<string>} [resourceAssetTypeIds] Resource asset type ids
         * @param {Array<string>} [dataLocationIds] Data location ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssets(assetIds?: Array<string>, orgUnitIds?: Array<string>, statuses?: Array<string>, resourceAssetTypeIds?: Array<string>, dataLocationIds?: Array<string>, options?: any): AxiosPromise<GetAssets200Response> {
            return localVarFp.getAssets(assetIds, orgUnitIds, statuses, resourceAssetTypeIds, dataLocationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Health check
         * @summary Health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<OkResponse> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
        /**
         * Mark all assets as read
         * @summary Mark all assets as read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAllAssetsAsRead(options?: any): AxiosPromise<MarkAllAssetsAsRead200Response> {
            return localVarFp.markAllAssetsAsRead(options).then((request) => request(axios, basePath));
        },
        /**
         * Update asset
         * @summary Update asset
         * @param {string} assetId Asset id
         * @param {UpdateAssetDTO} updateAssetDTO Asset model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAsset(assetId: string, updateAssetDTO: UpdateAssetDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateAsset(assetId, updateAssetDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Create asset
     * @summary Create asset
     * @param {CreateAssetDTO} createAssetDTO Asset model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createAsset(createAssetDTO: CreateAssetDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createAsset(createAssetDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete asset
     * @summary Delete asset
     * @param {string} assetId Asset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteAsset(assetId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteAsset(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get asset
     * @summary Get asset
     * @param {string} assetId Asset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAsset(assetId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAsset(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get asset processes
     * @summary Get asset processes
     * @param {string} assetId Asset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAssetProcesses(assetId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAssetProcesses(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get assets
     * @summary Get assets
     * @param {Array<string>} [assetIds] Asset ids
     * @param {Array<string>} [orgUnitIds] Org unit ids
     * @param {Array<string>} [statuses] Statuses
     * @param {Array<string>} [resourceAssetTypeIds] Resource asset type ids
     * @param {Array<string>} [dataLocationIds] Data location ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAssets(assetIds?: Array<string>, orgUnitIds?: Array<string>, statuses?: Array<string>, resourceAssetTypeIds?: Array<string>, dataLocationIds?: Array<string>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAssets(assetIds, orgUnitIds, statuses, resourceAssetTypeIds, dataLocationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Health check
     * @summary Health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public health(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mark all assets as read
     * @summary Mark all assets as read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public markAllAssetsAsRead(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).markAllAssetsAsRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update asset
     * @summary Update asset
     * @param {string} assetId Asset id
     * @param {UpdateAssetDTO} updateAssetDTO Asset model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAsset(assetId: string, updateAssetDTO: UpdateAssetDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateAsset(assetId, updateAssetDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


