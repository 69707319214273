import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import InlineIcons from "../InlineIcons/InlineIcons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  editItemTextField: {
    fontWeight: 700,
    color: theme.palette.grey[900]
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  }
}));

export default function EditTextFieldItem({
  value,
  editToolTip,
  cancelToolTip,
  onConfirm,
  onCancel,
  disableUnderline = false
}) {
  const classes = useStyles();
  const [itemName, setItemName] = useState(value ?? "");

  const handleEdit = function () {
    onConfirm(itemName);
    setItemName("");
  };

  const handleCancel = function () {
    onCancel();
    setItemName("");
  };

  return (
    <div className={classes.wrapper}>
      <TextField
        data-testid="edit-text-field"
        inputRef={input => input && input.focus()}
        size={"small"}
        variant="standard"
        value={itemName}
        fullWidth
        onChange={event => setItemName(event.target.value)}
        onKeyDown={event => {
          if (event.key === "Enter") {
            handleEdit();
          }
          if (event.key === "Escape") {
            handleCancel();
          }
        }}
        onBlur={() => {
          // this indicated no edit inline icon, hence handleEdit is triggered on blur
          if (!editToolTip) {
            handleEdit();
          }
        }}
        InputProps={{
          className: classes.editItemTextField,
          disableUnderline: disableUnderline
        }}
      />
      <InlineIcons
        iconSize={"medium"}
        data={[
          ...(editToolTip
            ? [
                {
                  icon: "CheckIcon",
                  toolTipTitle: editToolTip,
                  callBackFunction: handleEdit,
                  disabled: itemName === ""
                }
              ]
            : []),
          ...(cancelToolTip
            ? [
                {
                  icon: "ClearIcon",
                  toolTipTitle: cancelToolTip,
                  callBackFunction: handleCancel
                }
              ]
            : [])
        ]}
      />
    </div>
  );
}
