import { TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { QuestionProps } from "../../Question";

const LabelsQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onFocus,
  onBlur,
  onChange
}: QuestionProps) => {
  const [num, setNum] = useState<number | null>(null);

  const onChangeCallback = useCallback(e => {
    setNum(+e.target.value);
  }, []);

  const onBlurCallback = useCallback(() => {
    onChange?.(num);
    onBlur?.();
  }, [num, onBlur, onChange]);

  useEffect(() => {
    const _value: number = (Array.isArray(value) ? value[0] : value) as number;
    setNum(_value);
  }, [value]);
  return (
    <TextField
      disabled={disabled}
      fullWidth
      type="number"
      value={num || ""}
      onChange={onChangeCallback}
      variant="outlined"
      onFocus={onFocus}
      onBlur={onBlurCallback}
      label={questionName}
      InputLabelProps={inputLabelProps}
      error={error}
      helperText={helperText}
    />
  );
};

const inputLabelProps = {};

export default React.memo(LabelsQuestion);
