import React, { useCallback, useEffect, useState } from "react";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import DocView from "../../../components/DocView/DocView";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import MetaView from "../../../components/MetaView/MetaView";
import { Badge, Box } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useMetaView } from "app/contexts/meta-view-context";
import { getNumberUnseenResourcesPerResourceType, RESOURCE_TYPE, RESOURCE_TYPES } from "../../handlers/resourceHandler";
import { useIsFeaturePresent } from "../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../features";
import { ResourcesUnseen } from "../../api/resourceApi";
import { getNumberUnseenDataLocationsOfUserApi } from "../../api/externalRecipientApi";
import { useDataTypeTree } from "app/api/dataAssetApi";
import { createNaturalSorter } from "../../utils/naturalSort";

const useStyles = makeStyles((theme: any) => ({
  colorSecondaryRed: {
    backgroundColor: theme.palette.error.main
  },
  newResourceBadge: {
    marginLeft: "20px"
  },
  resuourceItem: {
    cursor: "pointer",
    display: "block",
    padding: "16px 16px",
    "&:hover": {
      background: "rgb(0,0,0,0.04)"
    }
  }
}));

interface ResourceSelectionProps {
  readonly id: string;
  readonly label: string;
  readonly unseen: number;
  readonly linkOverride?: string;
}

const ResourceSelection = ({ id, label, unseen, linkOverride }: ResourceSelectionProps) => {
  const navigate = useNavigate();
  const cls = useStyles();

  const onClickCallback = useCallback(() => {
    navigate(linkOverride || `/resources/${id}/overview`);
  }, [id, navigate, linkOverride]);
  return (
    <Box onClick={onClickCallback} className={cls.resuourceItem}>
      {label}
      {unseen > 0 && (
        <Badge
          badgeContent={unseen}
          className={cls.newResourceBadge}
          classes={{
            colorSecondary: cls.colorSecondaryRed
          }}
          color={"secondary"}
          showZero={false}
          overlap="rectangular"
        />
      )}
    </Box>
  );
};

const ResourcesSelectionPage = () => {
  const { t } = useTranslation("resources_overview");
  const { setInfo } = useMetaView();
  const enableCustomContractType = useIsFeaturePresent(FEATURES.RESOURCES_CUSTOM_CONTRACT_TYPE);
  const enableCustomRequestType = useIsFeaturePresent(FEATURES.RESOURCES_CUSTOM_REQUEST_TYPE);
  const isDataClassificationEnabled = useIsFeaturePresent(FEATURES.DATA_CLASSIFICATION);
  const isProcessingCategoryType = useIsFeaturePresent(FEATURES.RESOURCES_CUSTOM_PROCESSING_CATEGORIES);
  const isDatabreachCauseType = useIsFeaturePresent(FEATURES.DATABREACH_CAUSE);
  const isDatabreachImpactType = useIsFeaturePresent(FEATURES.DATABREACH_IMPACT);
  const isDatabreachMeasureType = useIsFeaturePresent(FEATURES.DATABREACH_MEASURE);
  const isAssetManagementEnabled = useIsFeaturePresent(FEATURES.ASSETS);

  const { unseenCount: dataAssetUnseenCount } = useDataTypeTree();

  const [unseenCountPerType, setUnseenCountPerType] = useState<ResourcesUnseen[]>([]);
  const [unseenCountDL, setUnseenCountDL] = useState<number>(0);

  useEffect(() => {
    setInfo({
      title: t("enteringInfoCardTitle"),
      text: t("enteringInfoCardText")
    });
  }, [setInfo, t]);

  useEffect(() => {
    getNumberUnseenResourcesPerResourceType().then(setUnseenCountPerType);
    getNumberUnseenDataLocationsOfUserApi().then(setUnseenCountDL);
  }, []);

  const resourceBehindToggle: RESOURCE_TYPE[] = [
    RESOURCE_TYPES.EXTERNAL_RECIPIENT_CONTRACT_TYPE,
    RESOURCE_TYPES.DATABREACH_CAUSE,
    RESOURCE_TYPES.DATABREACH_IMPACT,
    RESOURCE_TYPES.DATABREACH_MEASURE,
    RESOURCE_TYPES.DATA_CLASSIFICATION,
    RESOURCE_TYPES.DSR_REQUEST_TYPE,
    RESOURCE_TYPES.ASSET_TYPE,
    RESOURCE_TYPES.PROCESSING_CATEGORY
  ];

  const naturalSorter = createNaturalSorter();
  const resourceItemEls = Object.values(RESOURCE_TYPES)
    .reduce<ResourceSelectionProps[]>((acc, resource) => {
      const unseen = unseenCountPerType.find(x => x.type === resource)?._count || 0;
      if (resourceBehindToggle.includes(resource)) {
        if (resource === RESOURCE_TYPES.DATABREACH_CAUSE && isDatabreachCauseType) {
          return [
            ...acc,
            {
              id: resource,
              label: t(`resources_data-breaches:incidentCause`),
              unseen
            }
          ];
        } else if (resource === RESOURCE_TYPES.DATABREACH_IMPACT && isDatabreachImpactType) {
          return [
            ...acc,
            {
              id: resource,
              label: t(`resources_data-breaches:incidentImpact`),
              unseen
            }
          ];
        } else if (resource === RESOURCE_TYPES.DATABREACH_MEASURE && isDatabreachMeasureType) {
          return [
            ...acc,
            {
              id: resource,
              label: t(`resources_data-breaches:measures`),
              unseen
            }
          ];
        } else if (
          (resource === RESOURCE_TYPES.EXTERNAL_RECIPIENT_CONTRACT_TYPE && enableCustomContractType) ||
          (resource === RESOURCE_TYPES.DATA_CLASSIFICATION && isDataClassificationEnabled) ||
          (resource === RESOURCE_TYPES.DSR_REQUEST_TYPE && enableCustomRequestType) ||
          (resource === RESOURCE_TYPES.ASSET_TYPE && isAssetManagementEnabled) ||
          (resource === RESOURCE_TYPES.PROCESSING_CATEGORY && isProcessingCategoryType)
        ) {
          return [
            ...acc,
            {
              id: resource,
              label: t(`resources_${resource}_overview:header`),
              unseen
            }
          ];
        } else return [...acc];
      } else if (resource === RESOURCE_TYPES.DATA_LOCATIONS) {
        return [
          ...acc,
          {
            id: resource,
            label: t("service_providers_overview:data_tab_title"),
            unseen: unseenCountDL,
            linkOverride: "/resources/data-locations"
          }
        ];
      } else if (resource === RESOURCE_TYPES.DATA_ASSETS) {
        return [
          ...acc,
          {
            id: resource,
            label: t("personGroups"),
            unseen: dataAssetUnseenCount
          }
        ];
      }
      return [
        ...acc,
        {
          id: resource,
          label: t(`resources_${resource}_overview:header`),
          unseen
        }
      ];
    }, [])
    .sort((a, b) => naturalSorter(a.label, b.label))
    .map(item => (
      <ResourceSelection
        key={item.id}
        id={item.id}
        label={item.label}
        unseen={item.unseen}
        linkOverride={item.linkOverride}
      />
    ));

  return (
    <DocMetaView metaViewContent={<MetaView translationKey={"resources_overview"} />}>
      <DocView header={t("resources")}>{resourceItemEls}</DocView>
    </DocMetaView>
  );
};

export default ResourcesSelectionPage;
