import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import DocView from "../../../components/DocView/DocView";
import { useTranslation } from "react-i18next";
import TabsLayout, { TabContent } from "components/TabsLayout/TabsLayout";
import TomDetails from "./detail-tabs/TomDetails";
import DocumentNotFound from "../shared/DocumentNotFound/DocumentNotFound";
import { useNavigate, useParams } from "react-router-dom";
import MetaView, { META_VIEW_TABS } from "../../../components/MetaView/MetaView";
import { FEATURES } from "../../features";
import { useTom } from "../../contexts/tom-context";
import { useErrorSnackbar } from "../../../hook/errorSnackbar";
import { COLLECTIONS } from "../../collections";
import { useIsFeaturePresent } from "../../../hook/useIsFeaturePresent";
import Overview from "components/Overview/Overview";
import { ProcessesOverviewCollectionParams } from "../processes/overview/ProcessesOverviewController";

import { postCubeJsEvent } from "../../api/cubeJsHelperApi";

export default function Tom() {
  const { id: tomId, page: pageParam } = useParams();
  const { t } = useTranslation("tom");
  const { tomInitiated, getTomHook } = useTom();
  const [name, setName] = useState("");
  const isShowRecentActivities = useIsFeaturePresent(FEATURES.DASHBOARD_RECENT_ACTIVITIES);
  const risksAvailable = useIsFeaturePresent(FEATURES.RISKS);
  const navigate = useNavigate();
  // can be deleted when default values are created with creation of Service Provider
  const [pageLoaded, setPageLoaded] = useState(false);
  const [documentNotFound, setDocumentNotFound] = useState(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  // state to prevent multiple activity entries when switching between pages
  const [pageAccessPublished, setPageAccessPublished] = useState(false);

  const { catchAsSnackbar } = useErrorSnackbar();
  // one time load of serviceProvider data
  useEffect(() => {
    if (!tomInitiated) {
      return;
    }

    const loadTom = async () => {
      if (!tomId) {
        return;
      }

      const tom = await getTomHook(tomId);
      if (!tom) {
        setDocumentNotFound(true);
        return;
      }

      setName(tom.name || "");
      setPageLoaded(true);
    };

    loadTom().catch(catchAsSnackbar("Failed to load TOM"));
  }, [tomId, getTomHook, tomInitiated, catchAsSnackbar]);

  useEffect(() => {
    // only publish once on first access
    if (name && !pageAccessPublished && isShowRecentActivities) {
      const payload = {
        url: "/toms/" + tomId,
        docType: "measure",
        docTitle: name,
        docId: tomId || "",
        activityType: "pageAccess",
        tableId: "activities"
      } as const;
      postCubeJsEvent(payload);
      setPageAccessPublished(true);
    }
  }, [name, pageAccessPublished, tomId, isShowRecentActivities]);

  const riskOverviewParams = useMemo(() => ({ tomId }), [tomId]);
  const paOverviewParams: ProcessesOverviewCollectionParams = useMemo(
    () => ({ filter: { tomIDs: [tomId || ""] }, openInNewTab: true }),
    [tomId]
  );

  const overviewToolbarActions = useMemo(() => [{ action: "sort" }, { action: "filter" }], []);

  const [selectedTabId, setSelectedTabId] = useState("");
  const tabContents: (TabContent & { tabId: string; number: string })[] = useMemo(
    () =>
      tomId
        ? [
            {
              tabId: "description",
              number: "1",
              title: t("descriptionTabTitle"),
              content: selectedTabId === "description" && <TomDetails onNameChange={setName} tomId={tomId} />
            },
            risksAvailable
              ? {
                  tabId: "risks",
                  number: "2",
                  title: t("pagination:risk"),
                  content: selectedTabId === "risks" && (
                    <Overview
                      toolbarActions={overviewToolbarActions}
                      collectionParams={riskOverviewParams as any} // need fix, someone just added custom params and hacked it
                      collection={COLLECTIONS.RISK}
                    />
                  )
                }
              : null,
            {
              tabId: "processes",
              number: "3",
              title: t("processTabTitle"),
              content: selectedTabId === "processes" && (
                <Overview
                  toolbarActions={overviewToolbarActions}
                  collectionParams={paOverviewParams}
                  collection={COLLECTIONS.PROCESSES}
                />
              )
            }
          ].flatMap(notNull => (notNull ? [notNull] : []))
        : [],
    [tomId, t, risksAvailable, overviewToolbarActions, riskOverviewParams, paOverviewParams, selectedTabId]
  );

  /* useEffect */
  useEffect(() => {
    if (tabContents && pageParam) {
      const field = isNaN(Number(pageParam)) ? "tabId" : "number";
      const selectedTab = tabContents.map(t => (t ? t[field] : "")).indexOf(pageParam);
      setSelectedTab(selectedTab);
      setSelectedTabId(tabContents[selectedTab]?.tabId || "");
    }
  }, [pageParam, tabContents]);

  const onTabChange = useCallback(
    (tab: number) => {
      const tabName = tabContents[tab].tabId || "description";
      navigate(`/toms/${tomId}/${tabName}`);
    },
    [tomId, navigate, tabContents]
  );

  if (documentNotFound) {
    return <DocumentNotFound collection={COLLECTIONS.TOM} />;
  }

  return (
    <DocMetaView
      metaViewContent={
        <MetaView pageId={pageParam} docName={name} docId={tomId} collection={COLLECTIONS.TOM} tabs={metaViewTabIds} />
      }
    >
      {pageLoaded && (
        <DocView header={name}>
          <TabsLayout
            tabContents={tabContents}
            selectedTab={selectedTab}
            setSelectedTab={onTabChange}
            toolbarComponent={undefined}
          />
        </DocView>
      )}
    </DocMetaView>
  );
}

const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS];
