import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import colors from "./colors";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    green: Partial<Palette["grey"]>;
    blue: Partial<Palette["grey"]>;
    red: Partial<Palette["grey"]>;
    yellow: Partial<Palette["grey"]>;
    orange: Partial<Palette["grey"]>;
    lila: Partial<Palette["grey"]>;
    alert: {
      readonly warning: string;
      readonly warningBackground: string;
      readonly error: string;
      readonly errorBackground: string;
      readonly info: string;
      readonly infoBackground: string;
      readonly success: string;
      readonly successBackground: string;
    };
    logo: {
      readonly logo500: "#f0C197F";
    };
  }

  interface PaletteOptions {
    green: Partial<Palette["grey"]>;
    blue: Partial<Palette["grey"]>;
    red: Partial<Palette["grey"]>;
    yellow: Partial<Palette["grey"]>;
    orange: Partial<Palette["grey"]>;
    lila: Partial<Palette["grey"]>;
    alert: {
      readonly warning: string;
      readonly warningBackground: string;
      readonly error: string;
      readonly errorBackground: string;
      readonly info: string;
      readonly infoBackground: string;
      readonly success: string;
      readonly successBackground: string;
    };
    logo: {
      readonly logo500: string;
    };
  }
}

const palette: PaletteOptions = {
  common: {
    black: colors.black,
    white: colors.white
  },
  type: "light",
  primary: {
    light: colors.blue.blue50,
    main: colors.blue.blue500,
    dark: colors.blue.blue600,
    contrastText: colors.white
  },
  secondary: {
    light: colors.red.red50,
    main: colors.red.red500,
    dark: colors.red.red600,
    contrastText: colors.white
  },
  error: {
    light: colors.red.red50,
    main: colors.red.red500,
    dark: colors.red.red600,
    contrastText: colors.white
  },
  warning: {
    light: colors.yellow.yellow50,
    main: colors.yellow.yellow500,
    dark: colors.yellow.yellow600,
    contrastText: colors.white
  },
  success: {
    light: colors.green.green50,
    main: colors.green.green500,
    dark: colors.green.green600,
    contrastText: colors.white
  },
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.54)",
    disabled: "rgba(0, 0, 0, 0.38)"
  },
  alert: {
    warning: colors.orange.orange500,
    warningBackground: colors.yellow.yellow50,
    error: colors.red.red500,
    errorBackground: colors.red.red100,
    info: colors.blue.blue700,
    infoBackground: colors.blue.blue50,
    success: colors.green.green500,
    successBackground: colors.green.green50
  },
  logo: {
    logo500: "#0C197F"
  },
  grey: {
    50: colors.grey.grey50,
    100: colors.grey.grey100,
    200: colors.grey.grey200,
    300: colors.grey.grey300,
    400: colors.grey.grey400,
    500: colors.grey.grey500,
    600: colors.grey.grey600,
    700: colors.grey.grey700,
    800: colors.grey.grey800,
    900: colors.grey.grey900
  },
  green: {
    50: colors.green.green50,
    100: colors.green.green100,
    200: colors.green.green200,
    300: colors.green.green300,
    400: colors.green.green400,
    500: colors.green.green500,
    600: colors.green.green600,
    700: colors.green.green700
  },
  blue: {
    50: colors.blue.blue50,
    100: colors.blue.blue100,
    200: colors.blue.blue200,
    300: colors.blue.blue300,
    400: colors.blue.blue400,
    500: colors.blue.blue500,
    600: colors.blue.blue600,
    700: colors.blue.blue700
  },
  red: {
    50: colors.red.red50,
    100: colors.red.red100,
    200: colors.red.red200,
    300: colors.red.red300,
    400: colors.red.red400,
    500: colors.red.red500,
    600: colors.red.red600,
    700: colors.red.red700
  },
  yellow: {
    50: colors.yellow.yellow50,
    100: colors.yellow.yellow100,
    200: colors.yellow.yellow200,
    300: colors.yellow.yellow300,
    400: colors.yellow.yellow400,
    500: colors.yellow.yellow500,
    600: colors.yellow.yellow600,
    700: colors.yellow.yellow700
  },
  orange: {
    50: colors.orange.orange50,
    100: colors.orange.orange100,
    200: colors.orange.orange200,
    300: colors.orange.orange300,
    400: colors.orange.orange400,
    500: colors.orange.orange500,
    600: colors.orange.orange600,
    700: colors.orange.orange700
  },
  lila: {
    50: colors.lila.lila50,
    100: colors.lila.lila100,
    200: colors.lila.lila200,
    300: colors.lila.lila300,
    400: colors.lila.lila400,
    500: colors.lila.lila500,
    600: colors.lila.lila600,
    700: colors.lila.lila700
  }
};

export default palette;
