import { useTranslation } from "react-i18next";
import { useErrorSnackbar } from "../../../hook/errorSnackbar";
import React, { useEffect, useMemo, useState } from "react";
import { useBizChartModel } from "./bizchart";
import { getAuditStatistic, STATS_TYPE } from "../../handlers/dashboardHandler";
import { GraphContainer } from "./GraphContainer";
import { TimeAndCountChart } from "./TimeAndCountChart";
import useTheme from "@material-ui/core/styles/useTheme";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  mainPieChart: {
    margin: 0
  }
}));

export const AuditsChart = ({ timePeriod, orgUnitId, labelId }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation("dashboardPage");
  const { catchAsSnackbar } = useErrorSnackbar();
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const theme = useTheme();

  const { convertStatsToBizChartModel } = useBizChartModel();

  useEffect(() => {
    const init = async () => {
      const auditStats = await getAuditStatistic(timePeriod?.id, orgUnitId, labelId);

      const chartData = convertStatsToBizChartModel(
        auditStats,
        timePeriod?.id === STATS_TYPE.ALL_TIME && {
          month: "long",
          year: "numeric"
        },
        i18n.language
      );
      setChartData(chartData);
      setLoading(false);
    };

    if (Object.values(STATS_TYPE).includes(timePeriod?.id)) {
      init().catch(catchAsSnackbar("failed to load Audit statistic data"));
    }
  }, [timePeriod?.id, i18n.language, labelId, orgUnitId, convertStatsToBizChartModel, catchAsSnackbar]);

  const chartScale = useMemo(
    () => ({
      count: { min: 0 },
      type: {
        formatter: type => {
          switch (type) {
            case "edit":
              return t("edit");
            case "review":
              return t("review");
            case "completed":
              return t("audit_status:completed");
            default:
              return t("audit_status:draft");
          }
        }
      }
    }),
    [t]
  );

  const color = useMemo(
    () => [
      "type",
      type => {
        switch (type) {
          case "edit":
            return theme.palette.yellow[500];
          case "review":
            return theme.palette.blue[500];
          case "completed":
            return theme.palette.green[500];
          default:
            return theme.palette.lila.lila500;
        }
      }
    ],
    [theme]
  );

  return (
    <GraphContainer title={t("sidebar:audits")} loading={loading} height={470}>
      <TimeAndCountChart
        className={classes.mainPieChart}
        timePeriodId={timePeriod?.id}
        chartScale={chartScale}
        chartData={chartData}
        color={color}
        explicitChartType={"pie"}
        chartHeight={390}
      />
    </GraphContainer>
  );
};
