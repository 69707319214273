import overviewBaseController, {
  OverviewController,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { useCallback } from "react";
import { COLLECTIONS } from "app/collections";
import {
  getOrganizationFilter,
  getUsersFeaturesFilter,
  getUsersUserRoleFilter
} from "app/pages/shared/Filters/filters";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { AxiosInstance } from "axios";
import { useNavigateWithQueryParams } from "app/router/router-custom-hooks";
import { useTranslation } from "react-i18next";
import { tDeletedEntry } from "app/handlers/dataTypeTranslatorHandler";
import { departmentsDecorator } from "../../../../components/Overview/controllers/decorator/departmentsDecorator";

const UsersOverviewController = (axiosInstance: AxiosInstance): OverviewController => {
  // the controller is a pure TS file, if it uses state, the value will not be updated
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const { t } = useTranslation();
  const translateItem = useCallback(
    obj => ({ ...obj, subTitle: obj.subTitle === "deleted" ? tDeletedEntry({ t }) : obj.subTitle }),
    [t]
  );
  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.USER, translateItem, [departmentsDecorator]);

  const getFilters = useCallback(
    data => {
      return [
        getOrganizationFilter("orgUnitId", data._departments, t, "department"),
        getOrganizationFilter("furtherOrgUnitIds", data._departments, t, "furtherDepartments"),
        getUsersUserRoleFilter("userRole", data.roleNamesAndIds, t),
        getUsersFeaturesFilter("featureIds", t)
      ];
    },
    [t]
  );

  const getSortings = () => [
    {
      field: "title",
      type: "asc",
      label: t("filter_criteria:aToZ")
    },
    {
      field: "title",
      type: "desc",
      label: t("filter_criteria:zToA")
    }
  ];

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    const data = await baseController.getOverview(setup);
    if (!data) {
      return null;
    }
    return {
      ...data,
      filters: getFilters(data),
      sortings: getSortings()
    };
  };

  const goToItem = (id: string) => {
    baseController.goToItem(`/users/${id}`);
  };

  const addItem = async (data: OverviewNewItem) => {
    return await baseController.addItem({
      title: data.title || createOverviewItemDefaultName("user")
    });
  };

  const patchItem = async (id: string, data: object) => {
    return await baseController.patchItem(id, data);
  };

  const addItemAndGo = async () => {
    navigateWithQueryParams("/users/new");
  };

  const deleteItem = async (id: string) => {
    return await baseController.deleteItem(id, `/${id}`);
  };

  return {
    ...baseController,
    getOverview,
    goToItem,
    addItem,
    patchItem,
    deleteItem,
    addItemAndGo
  };
};

export default UsersOverviewController;
