import Box from "@material-ui/core/Box";
import CheckMarkIcon from "../../../../assets/images/tasks/check-mark.svg";
import { MouseEventHandler, useCallback, useMemo, useState } from "react";
import { ClickAwayListener, Menu, MenuItem, Tooltip, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PRIORITIES } from "app/handlers/tasksHandler";

const useStyles = makeStyles(theme => ({
  root: {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    borderRadius: "20px",
    padding: "6px 10px",
    fontWeight: 600,
    height: theme.spacing(3),
    letterSpacing: "1px",
    fontSize: "10px",
    whiteSpace: "nowrap"
  },
  low: {
    color: "#466CF2",
    backgroundColor: "#EBF1FF"
  },
  medium: {
    color: "#BF871F",
    backgroundColor: "#FFF0D4"
  },
  high: {
    color: "#C81228",
    backgroundColor: "#FDE9E9"
  },
  highest: {
    color: "#FFFFFF",
    backgroundColor: "#F17A79"
  },
  done: {
    color: "#6C6C6C",
    backgroundColor: "#eeeeee"
  },
  menuItem: {
    width: "100%",
    display: "flex",
    alignItems: "center"
  },
  menuPaper: {
    overflow: "visible",
    boxShadow: "0px 2px 5px 0px #00000033",
    "&::before": {
      display: "block",
      content: '""',
      position: "absolute",
      top: -8,
      left: "28%",
      width: "16px",
      height: "16px",
      backgroundColor: theme.palette.background.paper,
      transform: "translate(-50%, 0) rotate(45deg)",
      boxShadow: "0px -3px 5px -3px #00000033",
      borderRadius: "2px"
    }
  }
}));

interface TaskOverviewRowPriorityMenuItemProps {
  readonly onChange: (priority: string, event: MouseEventHandler<HTMLLIElement> | undefined) => void;
  readonly priority: "low" | "medium" | "high" | "highest";
  readonly readOnly?: boolean;
  readonly selected: boolean;
}
const TaskOverviewRowPriorityMenuItem = ({
  selected,
  priority,
  onChange,
  readOnly
}: TaskOverviewRowPriorityMenuItemProps) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");

  const onCLickCallback = useCallback(
    event => {
      onChange(priority, event);
    },
    [onChange, priority]
  );
  return (
    <MenuItem onClick={onCLickCallback}>
      <Box className={`${cls.root} ${cls[priority]}`} style={!readOnly ? { cursor: "pointer" } : { cursor: "default" }}>
        <Box mx={1} flex={1}>
          {t("priority_" + priority)}
        </Box>
      </Box>
      <Box flex={1} mx={1} />
      <Box minWidth={6}>{selected ? <CheckMarkIcon /> : <></>}</Box>
    </MenuItem>
  );
};

interface TaskOverviewRowPriorityProps {
  readonly priority: keyof typeof PRIORITIES;
  readonly status?: string;
  readonly onChange: (priority: string) => void;
  readonly readOnly?: boolean;
}
const TaskOverviewRowPriority = ({ priority, status, onChange, readOnly }: TaskOverviewRowPriorityProps) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");

  const [ref, setRef] = useState<Element | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const done = useMemo(() => status === "DONE", [status]);

  const openMenuCallback = useCallback(
    event => {
      if (readOnly) {
        return;
      }
      setRef(event?.currentTarget);
      setOpen(true);
    },
    [readOnly]
  );

  const closeMenuCallback = useCallback(() => {
    setRef(null);
    setOpen(false);
  }, []);

  const onChangeCallback = useCallback(
    priority => {
      closeMenuCallback();
      onChange(priority);
    },
    [closeMenuCallback, onChange]
  );

  if (!priority) {
    return <></>;
  }

  return (
    <ClickAwayListener onClickAway={closeMenuCallback}>
      <>
        <Tooltip title={t("changePriority")} disableHoverListener={readOnly}>
          <Box
            data-testid={`task-overview-row-priority`}
            className={`${cls.root} ${!done && cls[priority]} ${done && cls.done}`}
            onClick={openMenuCallback}
            mr={2}
          >
            {t("priority_" + priority)}
          </Box>
        </Tooltip>

        <Menu
          open={open}
          anchorEl={ref}
          onClose={closeMenuCallback}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 38,
            horizontal: -16
          }}
          classes={{ paper: cls.menuPaper }}
        >
          {Object.values(PRIORITIES).map((value: string, index: number) => (
            <TaskOverviewRowPriorityMenuItem
              key={index}
              onChange={onChangeCallback}
              priority={value as "low" | "medium" | "high" | "highest"}
              readOnly={readOnly}
              selected={value === priority}
            />
          ))}
        </Menu>
      </>
    </ClickAwayListener>
  );
};

export default TaskOverviewRowPriority;
