import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DocView from "../../../components/DocView/DocView";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView, { META_VIEW_TABS } from "../../../components/MetaView/MetaView";
import { useMetaView } from "../../contexts/meta-view-context";
import QuestionnaireSubHeader from "../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { RiskPageButtons, RiskPageStepper } from "./RiskPagination";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { RiskPhaseAssessment } from "./assessments/RiskPhaseAssessment";
import { RiskImplementedMeasureIDs } from "./assessments/RiskImplementedMeasureIDs";
import { useRisk } from "../../contexts/risk-context";
import { COLLECTIONS } from "../../collections";

const useStyles = makeStyles(() => ({
  header: {
    marginTop: 50
  },
  container: {
    marginTop: 20
  },
  pageButtonPaddings: {
    marginTop: 20
  }
}));

const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS];

export const RiskFirstAssessmentPage = () => {
  const { t } = useTranslation("risk_first_assessment_page");
  const classes = useStyles();

  const {
    risk: { title: riskTitle, riskId }
  } = useRisk();
  const { setInfo } = useMetaView();

  const infoCardEntering = useMemo(
    () => ({
      title: t("risk_first_assessment_page:enteringInfoCardTitle"),
      text: t("risk_first_assessment_page:enteringInfoCardText")
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCardEntering);
  }, [setInfo, infoCardEntering]);

  return (
    <DocMetaView
      metaViewContent={
        <MetaView
          translationKey={"risk_first_assessment_page"}
          docName={riskTitle}
          docId={riskId}
          collection={COLLECTIONS.RISK}
          tabs={metaViewTabIds}
        />
      }
    >
      <DocView header={riskTitle} pagination={<RiskPageStepper />}>
        <div className={classes.header}>
          <QuestionnaireSubHeader text={t("subTitle")} />
        </div>
        <RiskFirstAssessment />
        <Grid container spacing={3} className={classes.pageButtonPaddings}>
          <Grid item xs={12}>
            <RiskPageButtons />
          </Grid>
        </Grid>
      </DocView>
    </DocMetaView>
  );
};

export const RiskFirstAssessment = () => {
  const { t } = useTranslation("risk_first_assessment_page");
  const classes = useStyles();

  const { setInfo } = useMetaView();

  const infoCardEntering = useMemo(
    () => ({
      title: t("risk_first_assessment_page:enteringInfoCardTitle"),
      text: t("risk_first_assessment_page:enteringInfoCardText")
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCardEntering);
  }, [setInfo, infoCardEntering]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <RiskPhaseAssessment phaseId="first">
          <p>
            <strong>{t("alreadyImplementedMeasures")}</strong>
          </p>
          <RiskImplementedMeasureIDs />
        </RiskPhaseAssessment>
      </Grid>
    </Grid>
  );
};
