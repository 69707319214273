import React from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  bodyWrapper: {
    marginTop: "15px"
  },
  breakLines: {
    wordBreak: "break-all"
  }
});

export default function QuestionnaireSubHeader({ text }) {
  const classes = useStyles();
  return (
    <Box mb={2}>
      <Typography variant="h2" component="p" className={classes.breakLines}>
        {text}
      </Typography>
    </Box>
  );
}
