import { Box } from "@material-ui/core";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { usePAProcessorApproveMissingFieldsApi } from "../../api/processorPAApi";

export const ProcessorPAMissingFields = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data } = usePAProcessorApproveMissingFieldsApi({ documentId: id || "" });

  if (!data || data.length === 0) {
    return <></>;
  }

  return (
    <Box mt={1.5} mb={6}>
      <CustomAlert severity="warning">
        {t("questionnaires:errorMessagesHeading")}
        <ul>
          {data.map(sectionErrorKey => (
            <li key={`approval-${sectionErrorKey}`}>{t(`processor_pa_page:${sectionErrorKey}`, sectionErrorKey)}</li>
          ))}
        </ul>
      </CustomAlert>
    </Box>
  );
};
