import React, { useEffect, useState } from "react";
import MultiAutocomplete from "../MultiAutocomplete/MultiAutocomplete";
import SearchIcon from "@material-ui/icons/Search";
import { useUserDepartments } from "app/contexts/department-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { OrgUnitSelector } from "./OrgUnitSelector";

OrgunitsPathsAutocomplete.defaultProps = {
  fullWidth: true,
  getStyle: "",
  disabled: false
};

interface OrgUnitSelector<T> {
  label?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  value?: T;
  onChange?: (value: T) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  noDefaultValue?: boolean;
  noIcon?: boolean;
  error?: boolean;
  helperText?: string;
}
type SingleOrgUnitSelectorProps = OrgUnitSelector<string>;

export default function OrgunitsPathsAutocomplete({
  label,
  onFocus,
  onBlur,
  value,
  onChange,
  fullWidth,
  disabled,
  error,
  helperText,
  noDefaultValue,
  noIcon
}: SingleOrgUnitSelectorProps) {
  const { auth } = useAuthentication();
  const { getDepartmentWithParentName, departmentsLoaded, authUserSelectableDepartmentIds } = useUserDepartments();

  const [defaultValueId, setDefaultValueId] = useState("");
  useEffect(() => {
    setDefaultValueId(noDefaultValue ? "" : auth?.orgUnitId || "");
  }, [auth?.orgUnitId, noDefaultValue]);

  useEffect(() => {
    if (!value && defaultValueId && !noDefaultValue) {
      onChange?.(defaultValueId);
    }
  }, [defaultValueId, onChange, value, noDefaultValue]);

  if (!departmentsLoaded) {
    return <></>;
  }

  return (
    <OrgUnitSelector
      disabled={disabled}
      options={authUserSelectableDepartmentIds}
      value={value || defaultValueId}
      onChange={onChange}
      getOptionLabel={getDepartmentWithParentName}
      label={label}
      onFocus={onFocus}
      onBlur={onBlur}
      fullWidth={fullWidth}
      error={error}
      helperText={helperText}
      icon={noIcon ? undefined : <SearchIcon color="primary" />}
    />
  );
}

type MultiOrgUnitsSelectorProps = OrgUnitSelector<string[]>;
export const MultiOrgUnitsSelector = ({
  label,
  onFocus,
  onBlur,
  value,
  onChange,
  fullWidth,
  disabled
}: MultiOrgUnitsSelectorProps) => {
  const { getDepartmentWithParentName, departmentsLoaded, authUserSelectableDepartmentIds } = useUserDepartments();

  const [departmentIds, setDepartmentIds] = useState<string[]>([]);
  useEffect(() => setDepartmentIds(authUserSelectableDepartmentIds), [authUserSelectableDepartmentIds]);

  if (!departmentsLoaded) {
    return <></>;
  }

  return (
    <MultiAutocomplete
      disabled={disabled}
      hasMultiSelect={true}
      options={departmentIds}
      selected={value || []}
      updateSelected={onChange}
      getOptionLabel={getDepartmentWithParentName}
      label={label}
      disableClearable={false}
      onFocus={onFocus}
      onBlur={onBlur}
      icon={<SearchIcon color="primary" />}
      fullWidth={fullWidth}
    />
  );
};

MultiOrgUnitsSelector.defaultProps = {
  fullWidth: false,
  getStyle: "",
  disabled: false
};
