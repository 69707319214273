import React, { useCallback, useState } from "react";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import { OverviewItem } from "../controllers/overviewBaseController";

export interface OverviewRowActionProps {
  readonly id?: string;
  readonly title?: string;
  readonly titles?: string[]; // title by level
  readonly children?: React.ReactNode;
  readonly icon?: React.ReactNode;
  readonly item?: OverviewItem;
  readonly action?: string;
  readonly disabled?: boolean;
  readonly placeholders?: string[]; // placeholdrs by level
  readonly onHandle?: (id: string, item?: OverviewItem) => void;
  readonly handleHook?: (runnerFn: () => Promise<void>) => void;
  readonly childrens?: {
    readonly title: string;
    readonly onHandle: (id: string) => void;
  }[];
}

const ActionMenuItem = ({
  id,
  title,
  onHandle,
  onClose
}: {
  readonly id: string;
  readonly title: string;
  readonly onHandle: (id: string) => void;
  readonly onClose: () => void;
}) => {
  const onClick = useCallback(() => {
    onClose();
    onHandle(id);
  }, [id, onClose, onHandle]);
  return (
    <MenuItem key={id} onClick={onClick}>
      {title}
    </MenuItem>
  );
};

export const OverviewRowAction = ({
  id,
  title,
  children,
  item,
  disabled,
  onHandle,
  handleHook,
  childrens
}: OverviewRowActionProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const onClick = useCallback(
    event => {
      if (childrens) {
        setAnchorEl(event.currentTarget);
      } else {
        if (!handleHook) {
          return onHandle?.(id || "", item);
        }

        return handleHook(async () => onHandle?.(id || "", item));
      }
    },
    [childrens, handleHook, onHandle, id, item]
  );

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const menuEl = childrens && (
    <Menu id="overview-row-action-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      {childrens.map(({ title, onHandle }, index) => (
        <ActionMenuItem key={`${id}-${index}`} id={id || ""} title={title} onHandle={onHandle} onClose={handleClose} />
      ))}
    </Menu>
  );

  let buttonEl = (
    <Box>
      <IconButton onClick={onClick} disabled={disabled}>
        {children}
      </IconButton>
    </Box>
  );

  if (title) {
    buttonEl = <Tooltip title={title || ""}>{buttonEl}</Tooltip>;
  }

  return (
    <>
      {buttonEl}
      {menuEl}
    </>
  );
};

OverviewRowAction.propTypes = {
  title: PropTypes.string.isRequired,
  onHandle: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  handleHook: PropTypes.func
};
