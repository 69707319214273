import { getAuditTaskExports } from "app/api/auditApi";
import { getDatabreachExports } from "app/api/dataBreachApi";
import { getERExports } from "app/api/externalRecipientApi";
import { exportPAsAsExcel } from "app/api/paApi";
import { getRisksExports } from "app/api/riskApi";
import { getTomExportsExcel } from "app/api/tomApi";
import { getTenantInformation } from "app/handlers/tenantHandler";
import { getDocumentTitle } from "app/utils/get-title-for-exported-document";
import { exportPAProcessorAsExcelApi } from "../api/processorPAApi";
import { createAndDownloadFile } from "./createAndDownloadFile";

export async function exportProcessingActivitiesExcel(tenantId: string, userId: string, t: any, paIds: string[]) {
  const paExcelBlob = await exportPAsAsExcel({ paIDs: paIds });
  const tenantData = await getTenantInformation(tenantId);
  const fileName = `${getDocumentTitle(tenantData?.name, t("paExportData:export_heading"))}.xlsx`;
  await createAndDownloadFile(paExcelBlob, fileName);
}

export async function exportProcessorProcessingActivitiesExcel(
  tenantId: string,
  userId: string,
  t: any,
  processorPAIds: string[]
) {
  const paExcelBlob = await exportPAProcessorAsExcelApi({ processorPAIds: processorPAIds });
  const tenantData = await getTenantInformation(tenantId);
  const fileName = `${getDocumentTitle(tenantData?.name, t("processor_pa_overview:exportFilename"))}.xlsx`;
  await createAndDownloadFile(paExcelBlob, fileName);
}

export async function exportExternalRecipientsExcel(tenantId: string, userId: string, t: any, erIds: string[]) {
  const erExcelBlob = await getERExports({ erIDs: erIds });
  const tenantData = await getTenantInformation(tenantId);
  const fileName = `${getDocumentTitle(tenantData?.name, t("service_providers_overview:providers_tab_title"))}.xlsx`;
  await createAndDownloadFile(erExcelBlob, fileName);
}

export async function exportTomsExcel(tenantId: string, userId: string, t: any, tomIds: string[]) {
  const tomExcelBlob = await getTomExportsExcel({ tomIDs: tomIds });
  const tenantData = await getTenantInformation(tenantId);
  const fileName = `${getDocumentTitle(tenantData?.name, t("tab_general"))}.xlsx`;
  await createAndDownloadFile(tomExcelBlob, fileName);
}

export async function exportDataBreachesExcel(tenantId: string, userId: string, t: any, dataBreachIDs: string[]) {
  const dataBreachesExcelBlob = await getDatabreachExports({ dataBreachIDs: dataBreachIDs });
  const tenantData = await getTenantInformation(tenantId);
  const fileName = `${getDocumentTitle(tenantData?.name, t("data_breaches_overview:header"))}.xlsx`;
  await createAndDownloadFile(dataBreachesExcelBlob, fileName);
}

export async function exportRisksExcel(tenantId: string, t: any, riskIDs: string[]) {
  const risksExcelBlob = await getRisksExports({ riskIDs: riskIDs });
  const tenantData = await getTenantInformation(tenantId);
  const fileName = `${getDocumentTitle(tenantData?.name, t("collection:risk"))}.xlsx`;
  await createAndDownloadFile(risksExcelBlob, fileName);
}

export async function exportAuditTasksExcel(
  tenantId: string,
  auditId: string,
  auditTitle: string,
  language: string,
  t: any
) {
  const auditTaskExcelBlob = await getAuditTaskExports(auditId, language);
  const tenantData = await getTenantInformation(tenantId);
  const fileName = `${getDocumentTitle(
    tenantData?.name,
    auditTitle.concat(" - ", t("audit_export_template:export_heading"))
  )}.xlsx`;
  await createAndDownloadFile(auditTaskExcelBlob, fileName);
}
