import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import TextEditor from "../../questionnaires/utils/TextEditor";
import { LabelField } from "../../../../components/LabelField";
import { TomProtectionObjective } from "../fields/TomProtectionObjective";
import { TomStatus } from "../fields/TomStatus";

export default function MiniTomDetail({
  tomId,
  name,
  onNameChanged,
  description,
  onDescriptionChanged,
  protectionObjectiveIDs,
  onProtectionObjectiveIDsChanged,
  labels,
  onLabelsChanged,
  status,
  onStatusChanged,
  className,
  explicitRiskId
}) {
  const { t } = useTranslation("tom_description_tab");
  const xsTextField = 12;
  const spacing = 2;

  const nameUpdatedCallback = useCallback(
    event => {
      onNameChanged(event.target.value);
    },
    [onNameChanged]
  );

  return (
    <div id={"tomModal"} className={className}>
      <Grid container spacing={spacing}>
        <Grid item xs={xsTextField}>
          <TextField
            id={"name"}
            required
            fullWidth={true}
            label={t("name")}
            variant="outlined"
            value={name}
            onChange={nameUpdatedCallback}
          />
        </Grid>
        <Grid item xs={xsTextField}>
          <TomStatus initialStatusData={status} onStatusDataChanged={onStatusChanged} />
        </Grid>
        <Grid item xs={xsTextField}>
          <TextEditor
            id={"description"}
            title={t("description")}
            onChange={onDescriptionChanged}
            inputValue={description}
          />
        </Grid>
        <Grid item xs={xsTextField}>
          <TomProtectionObjective
            tomId={tomId}
            initialProtectionObjectiveIds={protectionObjectiveIDs || []}
            onProtectionObjectiveIdsChanged={onProtectionObjectiveIDsChanged}
            explicitRiskId={explicitRiskId}
          />
        </Grid>
        <Grid item xs={xsTextField}>
          <LabelField selectedIDs={labels || []} onSelectionChanged={onLabelsChanged} />
        </Grid>
      </Grid>
    </div>
  );
}
