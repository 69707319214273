import React from "react";
import { Button, Icon, makeStyles, Typography, Box, Tooltip } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  chip: {
    background: "rgba(235, 241, 255, 1)",
    fontSize: "10px",
    letterSpacing: "0.5px",
    fontFamily: "OpenSans",
    textAlign: "left",
    height: theme.spacing(3.5),
    padding: theme.spacing(0.75, 1, 0.75, 1),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    "&:hover": {
      background: "#9E9E9E",
      cursor: "pointer"
    },
    "& svg": {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      "&:hover": {
        cursor: "pointer"
      }
    },
    "& .MuiButton-label": {
      height: "100%"
    }
  },
  dashed: {
    border: "1px dashed #9E9E9E",
    background: "transparent",
    color: "#9E9E9E",
    overflow: "hidden",
    "&:hover": {
      background: theme.palette.grey[100],
      cursor: "pointer"
    },
    "& svg": {
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(-0.5)
    },
    "& .MuiButton-label": {
      height: "100%"
    }
  },
  circleDashed: {
    background: "transparent",
    color: theme.palette.grey[600],
    border: "1px dashed rgba(164, 167, 168, 1)",
    borderRadius: "50%",
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: "unset",
    padding: 0,
    "&:hover": {
      background: theme.palette.grey[100],
      cursor: "pointer"
    },
    "& svg": {
      padding: theme.spacing(0.5),
      opacity: 0.5,
      "&:hover": {
        cursor: "pointer"
      }
    }
  },
  circleAvatar: {
    borderRadius: "50%",
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: theme.spacing(1),
    minWidth: "unset",
    "& .MuiAvatar-colorDefault": {
      background: theme.palette.primary.main
    },
    "& .MuiAvatar-root": {
      height: theme.spacing(3),
      width: theme.spacing(3),
      fontSize: "10px",
      fontWeight: 600,
      lineHeight: "24px"
    }
  },
  roundedFilled: {
    borderRadius: "22px",
    padding: theme.spacing(0.5, 1),
    fontSize: "10px",
    fontWeight: 600,
    letterSpacing: "0.5px",
    background: "rgba(235, 241, 255, 1)",
    color: theme.palette.primary.main,
    height: theme.spacing(3),
    "& svg": {
      padding: theme.spacing(0.5)
    },
    "&:hover": {
      background: theme.palette.grey[200]
    }
  }
}));

interface ChipsStackProps {
  header?: string;
  chips: {
    disabled?: boolean;
    label?: string;
    variant: string;
    leftIcon?: any;
    rightIcon?: any;
    icon?: any;
    rightIconToolTip?: string;
    onClick?: () => void;
    onRightIconClick?: (event: MouseEvent) => void;
    styles?: {
      background?: string;
      borderRadius?: string;
      color?: string;
      fontSize?: string;
      fontWeight?: number;
      height?: string;
      leftIconMarginLeft?: string;
      leftIconMarginRight?: string;
      noTextTransform?: boolean;
      rightIconColor?: string;
      rightIconMarginLeft?: string;
      rightIconMarginRight?: string;
      rightIconSize?: string;
    };
  }[];
  readOnly?: boolean;
}
export default function ChipsStack({ header, chips, readOnly = false }: ChipsStackProps) {
  const cls = useStyles();
  const primaryColor = "#306AFF";
  return (
    <Box>
      {header && (
        <Typography variant="h5" component="div">
          {header}
        </Typography>
      )}
      <Box>
        {chips.map((chip, index) =>
          chip.variant === "contained" ? (
            <Button
              className={cls.chip}
              disabled={chip.disabled}
              disableElevation={true}
              disableRipple={true}
              key={"chip-" + index}
              variant="contained"
              onClick={readOnly ? undefined : chip.onClick}
              style={{
                background: chip.styles?.background,
                borderRadius: chip.styles?.borderRadius,
                color: chip.styles?.color || primaryColor,
                fontSize: chip.styles?.fontSize,
                fontWeight: chip.styles?.fontWeight || 600,
                height: chip.styles?.height,
                textTransform: chip.styles?.noTextTransform ? "none" : "uppercase"
              }}
            >
              {chip.leftIcon && (
                <Icon
                  component={chip.leftIcon}
                  style={{
                    marginLeft: chip.styles?.leftIconMarginLeft || "4px",
                    marginRight: chip.styles?.leftIconMarginRight || "4px"
                  }}
                />
              )}
              {chip.label}
              {chip.rightIcon && (
                <Tooltip
                  key={"chip-" + index}
                  title={chip.rightIconToolTip || ""}
                  disableHoverListener={!chip.rightIconToolTip}
                >
                  <Icon
                    onClick={readOnly ? undefined : chip.onRightIconClick}
                    component={chip.rightIcon}
                    style={{
                      color: chip.styles?.rightIconColor || primaryColor,
                      marginLeft: chip.styles?.rightIconMarginLeft || "4px",
                      marginRight: chip.styles?.rightIconMarginRight || "4px"
                    }}
                  />
                </Tooltip>
              )}
            </Button>
          ) : chip.variant === "dashed" ? (
            <Button
              className={[cls.chip, cls.dashed].join(" ")}
              disabled={chip.disabled}
              disableElevation={true}
              disableRipple={true}
              key={"chip-" + index}
              variant="text"
              onClick={readOnly ? undefined : chip.onClick}
              style={{
                borderRadius: chip.styles?.borderRadius,
                color: chip.styles?.color,
                fontWeight: chip.styles?.fontWeight || 600,
                height: chip.styles?.height
              }}
            >
              {chip.leftIcon && <Icon component={chip.leftIcon} />}
              {chip.label}
              {chip.rightIcon && <Icon component={chip.rightIcon} />}
            </Button>
          ) : chip.variant === "circleDashed" ? (
            <Button
              className={cls.circleDashed}
              disabled={chip.disabled}
              disableElevation={true}
              disableRipple={true}
              key={"chip-" + index}
              variant="contained"
              onClick={readOnly ? undefined : chip.onClick}
            >
              <Icon component={chip.icon} />
            </Button>
          ) : chip.variant === "circleAvatar" ? (
            <Button
              className={cls.circleAvatar}
              disabled={chip.disabled}
              disableElevation={true}
              disableRipple={true}
              key={"chip-" + index}
              variant="text"
              onClick={readOnly ? undefined : chip.onClick}
            >
              {chip.icon}
            </Button>
          ) : chip.variant === "roundedFilled" ? (
            <Button
              className={cls.roundedFilled}
              disabled={chip.disabled}
              disableElevation={true}
              disableRipple={true}
              key={"chip-" + index}
              variant="contained"
              onClick={readOnly ? undefined : chip.onClick}
            >
              {chip.leftIcon && <Icon component={chip.leftIcon} />}
              {chip.label}
              {chip.rightIcon && <Icon component={chip.rightIcon} />}
            </Button>
          ) : null
        )}
      </Box>
    </Box>
  );
}
