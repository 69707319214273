import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { createTom, deleteTom, getAllToms, getTom, updateTomWithData } from "../handlers/tomHandler";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { createOverviewItemDefaultName } from "../utils/create-overview-item-default-name";
import { CreateTomModelDTO, TomModelDTO, UpdateTomModelDTO } from "../api/tomApi";

export interface TomContextInterface {
  toms: TomModelDTO[];
  generalTOMs: TomModelDTO[];
  processTOMs: TomModelDTO[];
  loading: boolean;
  tomInitiated: boolean;
  loadTomsHook: () => Promise<TomModelDTO[]>;
  addTomHook: (tomName: string, inputData: CreateTomModelDTO, processSpecific: boolean) => Promise<TomModelDTO>;
  updateTomHook: (tomData: UpdateTomModelDTO, tomId: string) => Promise<TomModelDTO>;
  deleteTomHook: (tomId: string) => Promise<void>;
  getTomHook: (tomId: string) => Promise<TomModelDTO | null>;
}

export const TomsContext = createContext<TomContextInterface>({
  toms: [],
  generalTOMs: [],
  processTOMs: [],
  loading: true,
  tomInitiated: false,
  loadTomsHook: async () => [],
  addTomHook: async () => Promise.reject("TomsContext not initiated"),
  updateTomHook: async () => Promise.reject("TomsContext not initiated"),
  deleteTomHook: async () => Promise.reject("TomsContext not initiated"),
  getTomHook: async () => Promise.reject("TomsContext not initiated")
});

export const TomsProvider = function ({ children }: { readonly children: React.ReactNode }) {
  const [tomInitiated, setTomInitiated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [toms, setToms] = useState<TomModelDTO[]>([]);
  const [generalTOMs, setGeneralTOMs] = useState<TomModelDTO[]>([]);
  const [processTOMs, setProcessTOMs] = useState<TomModelDTO[]>([]);
  useEffect(() => {
    setGeneralTOMs(toms.filter(tom => !tom.processSpecific));
    setProcessTOMs(toms.filter(tom => tom.processSpecific));
  }, [toms]);

  const { auth } = useAuthentication();
  const tenantId = auth?.tenantId;

  const loadTomsHook = useCallback(async () => {
    if (!tenantId) {
      return [];
    }
    setLoading(true);
    const allToms = await getAllToms();
    setToms(allToms.sort((a, b) => String(b.createdAt).localeCompare(String(a.createdAt))));
    setTomInitiated(true);
    setLoading(false);
    return allToms;
  }, [tenantId]);

  useEffect(() => {
    if (!tomInitiated) {
      loadTomsHook();
    }
  }, [tomInitiated, loadTomsHook]);

  const addTomHook = useCallback(
    async (tomName, inputData, processSpecific) => {
      setLoading(true);
      const createdTomId = await createTom(
        tomName || createOverviewItemDefaultName("measures"),
        inputData,
        processSpecific
      );
      const updatedToms = await loadTomsHook();
      const createdTom = updatedToms.find(tom => tom.id === createdTomId);
      if (!createdTom) {
        throw new Error("Tom was not created");
      }
      return createdTom;
    },
    [loadTomsHook]
  );

  const updateTomHook = useCallback(
    async (tomData, tomId) => {
      await updateTomWithData(tomId, tomData);
      const updatedToms = await loadTomsHook();
      const updatedTom = updatedToms.find(tom => tom.id === tomId);
      if (!updatedTom) {
        throw new Error("Tom was not updated");
      }
      return updatedTom;
    },
    [loadTomsHook]
  );

  const deleteTomHook = useCallback(
    async tomId => {
      await deleteTom(tomId);
      await loadTomsHook();
    },
    [loadTomsHook]
  );

  const getTomHook = useCallback(async tomId => {
    return await getTom(tomId);
  }, []);

  return (
    <TomsContext.Provider
      value={{
        toms,
        generalTOMs,
        processTOMs,
        loading,
        tomInitiated,
        loadTomsHook,
        addTomHook,
        updateTomHook,
        deleteTomHook,
        getTomHook
      }}
    >
      {children}
    </TomsContext.Provider>
  );
};

export const useTom = () => useContext(TomsContext);
