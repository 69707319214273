import { useTranslation } from "react-i18next";
import { useMetaView } from "app/contexts/meta-view-context";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useNavigate } from "react-router-dom";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";

export const GroupOverview = () => {
  const { t } = useTranslation("groups_overview");
  const navigate = useNavigate();
  const dispatch = useOverviewDispatch();
  const { setInfo } = useMetaView();
  const [currentPage, setCurrentPage] = useState<string>("groups");
  const roleFeaturesEnabled = useIsFeaturePresent(FEATURES.USERROLES_FEATURES);
  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringInfoCardText")
      }
    }),
    [t]
  );

  const showEnterInfo = useCallback(() => {
    setInfo(infoCard?.entering);
  }, [infoCard?.entering, setInfo]);

  useEffect(() => {
    showEnterInfo();
  }, [showEnterInfo]);

  const addActions = [{ action: OVERVIEW_ADD_TYPE.SINGLE }];

  const toolbarActions = useMemo(() => [{ action: "sort" }], []);

  const rowActions = useMemo(() => [{ action: "edit" }, { action: "remove" }], []);

  const selectionActions = useMemo(() => [{ action: "remove" }], []);

  const { auth } = useAuthentication();
  const showGroups = useIsFeaturePresent(FEATURES.USERGROUPS_FEATURES) && auth?.permissions.includes("group_read_all");
  const showRoles = useIsFeaturePresent(FEATURES.USERROLES_FEATURES) && auth?.permissions.includes("role_read_all");

  const pages = useMemo(() => {
    const pages = [{ title: t("users_overview:users"), route: "users", current: currentPage === "users" }];
    if (showRoles) {
      pages.push({ title: t("users_overview:roles"), route: "roles", current: currentPage === "roles" });
    }
    if (showGroups) {
      pages.push({ title: t("users_overview:groups"), route: "groups", current: currentPage === "groups" });
    }
    return pages;
  }, [t, currentPage, showRoles, showGroups]);

  const onPageChange = useCallback(
    ({ route }) => {
      navigate("/" + route);
      dispatch({ type: OVERVIEW_ACTIONS.SET_SELECTED_ID, collection: COLLECTIONS.USER, selectedId: null });
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW, collection: COLLECTIONS.USER, reloadOverview: Date.now() });
    },
    [dispatch, navigate]
  );

  const docViewContent = (
    <Overview
      pages={pages}
      onPageChange={onPageChange}
      collection={COLLECTIONS.GROUP}
      onRowLeave={showEnterInfo}
      header={t("title")}
      checkable={true}
      toolbarActions={toolbarActions}
      addActions={addActions}
      rowActions={rowActions}
      selectionActions={selectionActions}
    />
  );
  return (
    <DocMetaView docViewContent={docViewContent} metaViewContent={<MetaView translationKey={"groups_overview"} />} />
  );
};
