/* eslint-disable react/jsx-props-no-spreading */
import React, { CSSProperties, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Checkbox, IconButton, makeStyles, Typography } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Done from "@material-ui/icons/Done";
import Edit from "@material-ui/icons/Edit";
import GetApp from "@material-ui/icons/GetApp";
import { useTranslation } from "react-i18next";
import OverviewBadge, { Badge } from "components/Overview/controls/OverviewBadge";
import OverviewHighlight from "./OverviewHighlight";
import OverviewConfirmer from "./OverviewConfirmer";
import OverviewRenamer from "./OverviewRenamer";
import { OverviewRowAction, OverviewRowActionProps } from "./OverviewRowAction";
import { COLLECTION_TYPES } from "app/collections";
import { useSnackbar } from "notistack";
import { OverviewItem, OverviewNewItem } from "../controllers/overviewBaseController";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { Add, ChevronRight, ExpandMore } from "@material-ui/icons";
import OverviewAdd from "./OverviewAdd";
import OverviewInView from "./OverviewInView";
import OverviewUnseeCount from "./OverviewUnseeCount";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import { useOverviewState } from "app/contexts/overview-context";
import { AxiosRequestConfig } from "axios";
import { useInView } from "react-intersection-observer";

const useStyles = makeStyles(theme => ({
  clickable: {
    cursor: "pointer"
  },
  hoverable: {
    "&:hover": {
      borderRadius: "6px",
      backgroundColor: theme.palette.grey[100],
      "& .rowActions": {
        display: "flex"
      },
      "& .draggable": {
        visibility: "visible"
      },
      "& .rowChevron": {
        visibility: "visible"
      },
      "& .rowCheckbox": {
        visibility: "visible"
      },
      "& .ProcessEditIconCircle": {
        fill: "#FFE1A9"
      },
      "& .ProcessEditIconPath": {
        fill: "#805A15"
      },
      "& .rowCheckbox .Mui-disabled": {
        visibility: "hidden"
      }
    }
  },
  row: {
    overflow: "hidden",
    padding: "4px 32px 4px 32px",
    minHeight: "58px",
    position: "relative",
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    "&.bold": {
      "& .rowTitle": {
        fontWeight: "bold"
      }
    },
    "&.selected": {
      backgroundColor: theme.palette.grey[100],
      borderRadius: "6px"
    },
    "&.blue": {
      backgroundColor: theme.palette.primary.light
    },
    "&.red": {
      backgroundColor: "#ffecec"
    },
    "&.green": {
      backgroundColor: "#E6F4EB"
    },
    "&.isOver": {
      outline: `2px solid ${theme.palette.primary.main}`,
      borderRadius: "3px"
    },
    "&.checked": {
      "& .rowCheckbox": {
        visibility: "visible"
      }
    },
    "&.checkable": {
      paddingLeft: "8px"
    },
    "& .rowActions": {
      display: "none",
      "& .MuiIconButton-root:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main
      }
    },
    "& .rowChevron": {
      visibility: "visible",
      width: "36px",
      textAlign: "center",
      marginRight: "4px",
      "&.hidden": {
        visibility: "hidden"
      }
    },
    "& .rowCheckbox": {
      visibility: "hidden",
      width: "36px",
      textAlign: "center",
      marginRight: "4px",
      "&.hidden": {
        visibility: "hidden"
      }
    },
    "& .rowSubTitle": {
      fontSize: "0.8em",
      color: theme.palette.grey[500],
      paddingTop: "4px"
    },
    "& .deleteTitle": {
      padding: "13.5px 0px"
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
      backgroundColor: "#fff"
    },
    "& .MuiIconButton-colorPrimary": {
      color: theme.palette.primary.main
    },
    "& .MuiIconButton-colorSecondary": {
      color: theme.palette.error.main
    },
    "& .MuiCheckbox-colorSecondary": {
      color: theme.palette.grey[500]
    },
    "& mark": {
      background: "#FFE1A9"
    },
    "&.progress": {
      width: "calc(113px * 8)",
      backgroundImage: "repeating-linear-gradient(45deg, transparent, transparent 40px, #fff 40px, #fff 80px)",
      animation: "slide 2s linear infinite",
      willChange: "background-position",
      "&.blue": {
        backgroundColor: "#EBF1FF"
      },
      "&.red": {
        backgroundColor: "#FFF0F7"
      },
      "&.green": {
        backgroundColor: "#E6F4EB"
      },
      "&:hover": {
        "& .rowActions": {
          display: "none"
        }
      },
      "& .rowActions": {
        display: "none"
      }
    },
    "& .draggable": {
      position: "absolute",
      visibility: "hidden",
      marginLeft: "-30px",
      opacity: "0.6"
    }
  },
  child: {},
  emptyChild: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: "6px",
    padding: "24px",
    textAlign: "center",
    margin: "8px 48px"
  }
}));

export type CustomRowComponentProps<T extends OverviewItem> = {
  hovered: boolean;
  item: T;
  searchTerm: string;
  onDelete: (id: string) => Promise<void>;
  onRename: (id: string, title: string) => void;
  onAdd: (data: OverviewItem) => void;
  onChecked: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkable: boolean;
  checked: boolean;
};

export interface OverviewRowProps {
  readonly actions: OverviewRowActionProps[];
  readonly activeRow: string | null;
  readonly checkable: boolean | undefined;
  readonly checkedItems?: OverviewItem[];
  readonly collection: COLLECTION_TYPES;
  readonly dragItem?: OverviewItem | null;
  readonly dynamicIndentation?: boolean;
  readonly item: OverviewItem;
  readonly level?: number;
  readonly rowComponent?: React.ComponentType<any>;
  readonly CustomRowComponent?: React.ComponentType<CustomRowComponentProps<OverviewItem>>;
  readonly searchTerm: string;
  readonly setActiveRow: Dispatch<SetStateAction<string | null>>;
  readonly setItemToMove?: (item: OverviewItem | null) => void;
  readonly setOpenMoveModal?: (value: boolean) => void;
  readonly translationDomainName?: string;

  readonly onAdd: (item: OverviewItem) => void;
  readonly onCheckRow: (val: any, checked: boolean) => void;
  readonly onClick: (item: OverviewItem, event: any) => void;
  readonly onDelete: (id: string) => Promise<void>;
  readonly onDragOverController?: (draggableItem: OverviewItem, droppableItem: OverviewItem) => boolean;
  readonly onMouseEnter?: (val: any) => void;
  readonly onPatch: (id: string, data: any, url?: string, options?: AxiosRequestConfig, item?: OverviewItem) => void;
  readonly onValidate?: (val: any) => string;
}

export const OverviewRow = ({
  actions,
  activeRow,
  checkable,
  checkedItems,
  collection,
  dragItem,
  dynamicIndentation,
  item,
  level,
  rowComponent,
  CustomRowComponent,
  searchTerm,
  setActiveRow,
  setOpenMoveModal,
  setItemToMove,
  translationDomainName,
  onAdd,
  onCheckRow,
  onClick,
  onDelete,
  onDragOverController,
  onMouseEnter,
  onPatch,
  onValidate
}: OverviewRowProps) => {
  const cls = useStyles();
  const { t } = useTranslation("overview");
  const { enqueueSnackbar } = useSnackbar();
  const { selectedId } = useOverviewState()[collection];

  const hasChild = item.children !== undefined;

  const [hovered, setHovered] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(Boolean(item.expanded));
  const [rowMode, setRowMode] = useState<"basic" | "edit" | "remove" | "add" | "done" | "progress" | "reset">("basic");
  const [allowDrop, setAllowDrop] = useState<boolean>(false);

  const [progressMode, setProgressMode] = useState("");
  const [checkedItem, setCheckedItem] = useState(false);
  const [filteredActions, setFilteredActions] = useState<OverviewRowActionProps[] | null>([]);
  const defaultActions = useMemo(
    () =>
      [
        { action: hasChild ? "add" : undefined },
        { action: "edit" },
        { action: "remove" },
        { action: "checkable" }
      ].filter(({ action }) => action),
    [hasChild]
  );

  // to handle auto expand during search
  useEffect(() => {
    if (item.expanded) {
      setOpen(true);
    }
  }, [item.expanded]);

  useEffect(() => {
    return setCheckedItem(Boolean(checkedItems?.some(({ id }) => id === item.id)));
  }, [checkedItems, item.id]);

  useEffect(() => {
    if (activeRow !== item.id) {
      setRowMode("basic");
    }
  }, [activeRow, item.id]);

  useEffect(() => {
    if (item.disableActions) {
      setFilteredActions(
        (actions || defaultActions).filter(
          a => !item.disableActions?.some(disableAction => a.action === disableAction.action)
        )
      );
    } else setFilteredActions(actions || defaultActions);
  }, [actions, defaultActions, item.disableActions]);

  const addAction = filteredActions?.find(i => i.action === "add");
  const exportAction = filteredActions?.find(i => i.action === "export");
  const markAsDoneAction = filteredActions?.find(i => i.action === "done");
  const resetPasswordAction = actions?.find(i => i.action === "reset");
  const isDisableCheckable = item.disableActions?.some(i => i.action === "checkable");
  const isDisableSelectable = item.disableActions?.some(i => i.action === "selectable");
  const isDisableDraggable = item.disableActions?.some(i => i.action === "draggable");

  const { id, title, subTitle } = item;
  const [rowTitle, setRowTitle] = useState(title);

  useEffect(() => {
    if (!translationDomainName) {
      setRowTitle(title);
      return;
    }

    setRowTitle(t(`${translationDomainName}:${title}`, title));
  }, [t, title, translationDomainName]);

  const otherActions = useMemo(
    () => filteredActions?.filter(i => !["add", "edit", "remove", "export", "done", "reset"].includes(i.action || "")),
    [filteredActions]
  );

  /* ACTIONS */
  const onRowClick = useCallback(
    event => {
      if (rowMode === "basic") {
        if (hasChild) {
          setOpen(current => !current);
        }
        if (!isDisableSelectable) {
          onClick(item, event);
        }
      }
    },
    [rowMode, hasChild, isDisableSelectable, onClick, item]
  );

  const onOpenAdd = useCallback(() => {
    setRowMode("add");
    setOpen(true);
  }, []);

  const onAddClose = useCallback(() => {
    setRowMode("basic");
  }, []);
  const onAddCallback = useCallback(
    (data: OverviewNewItem) => {
      onAdd({
        id: "",
        title: data.title || "",
        subTitle: "",
        parentId: id
      });
    },
    [id, onAdd]
  );

  const onOpenEdit = useCallback(() => {
    setRowMode("edit");
    setActiveRow(item.id);
  }, [item.id, setActiveRow]);

  const onCloseEdit = useCallback(() => {
    setRowMode("basic");
    setActiveRow(null);
  }, [setActiveRow]);

  const onOpenRemove = useCallback(() => {
    setRowMode("remove");
    setActiveRow(item.id);
  }, [item.id, setActiveRow]);

  const onCloseRemove = useCallback(() => {
    setRowMode("basic");
    setActiveRow(null);
  }, [setActiveRow]);

  const onCloseDoneMode = useCallback(() => {
    setRowMode("basic");
    setActiveRow(null);
  }, [setActiveRow]);

  const onOpenDoneMode = useCallback(() => {
    setRowMode("done");
    setActiveRow(item.id);
  }, [item.id, setActiveRow]);

  const onOpenReset = useCallback(() => {
    setRowMode("reset");
    setActiveRow(item.id);
  }, [item.id, setActiveRow]);

  const onCloseReset = useCallback(() => {
    setRowMode("basic");
    setActiveRow(null);
  }, [setActiveRow]);

  const onPatchClick = useCallback(
    async (newTitle: string) => {
      const data = { title: newTitle };
      const validationErrorText = onValidate?.(data);
      if (validationErrorText) {
        enqueueSnackbar(validationErrorText, { variant: "error" });
        setRowMode("edit");
        setProgressMode("");
        return;
      }
      onCloseEdit();
      setProgressMode("blue");
      onPatch(id, data, undefined, undefined, item);
      setProgressMode("");
    },
    [enqueueSnackbar, id, item, onCloseEdit, onPatch, onValidate]
  );

  const onDeleteClick = useCallback(async () => {
    onCloseRemove();
    setProgressMode("red");
    onDelete(id || item.path);
    setProgressMode("");
  }, [id, item.path, onCloseRemove, onDelete]);

  const onResetClick = useCallback(async () => {
    onCloseReset();
    setProgressMode("blue");
    resetPasswordAction?.onHandle?.(id, item);
    setProgressMode("");
  }, [id, onCloseReset, item, resetPasswordAction]);

  const onDoneClick = useCallback(async () => {
    onCloseDoneMode();
    setProgressMode("green");
    markAsDoneAction?.onHandle?.(id || "");
    setProgressMode("");
  }, [id, markAsDoneAction, onCloseDoneMode]);

  const blueProgressBar = useCallback(runnerFn => {
    setRowMode("basic");
    setProgressMode("blue");
    runnerFn()
      .then(() => setProgressMode(""))
      .catch((error: Error) => {
        setProgressMode("");
        throw error;
      });
  }, []);

  const stopEvent = useCallback(event => event.stopPropagation(), []);

  /* CHEVRON */
  const onChevronClickCallback = useCallback(
    event => {
      stopEvent(event);
      if (hasChild) {
        setOpen(current => !current);
      }
    },
    [hasChild, stopEvent]
  );

  const chevronEl = hasChild && (
    <Box
      className={`rowChevron ${rowMode === "basic" ? "" : "hidden"}`}
      onClick={onChevronClickCallback}
      data-testid={`overview-row-chevron`}
    >
      <IconButton size="small">{open ? <ExpandMore /> : <ChevronRight />}</IconButton>
    </Box>
  );

  /* CHECKBOX */
  const onChecked = useCallback(
    event => {
      setCheckedItem(event.target.checked);
      onCheckRow(item, event.target.checked);
    },
    [item, onCheckRow]
  );
  const checkBoxEl = checkable && (
    <Box
      className={`rowCheckbox ${!isDisableCheckable && rowMode === "basic" ? "" : "hidden"}`}
      onClick={stopEvent}
      data-testid={`overview-row-checkbox`}
    >
      <Checkbox checked={checkedItem} onChange={onChecked} />
    </Box>
  );
  const showCustomComponent = !!CustomRowComponent;

  /* CUSTOM ROW COMPONENT */
  const customRowEl = (() => {
    if (showCustomComponent) {
      return React.createElement(CustomRowComponent, {
        hovered,
        item,
        searchTerm,
        onDelete,
        onRename: onOpenEdit,
        onAdd,
        onChecked,
        checkable: !!checkable,
        checked: checkedItem
      });
    }
    if (rowMode !== "basic") {
      return null;
    }
    if (rowComponent) {
      return React.createElement(rowComponent, {
        hovered,
        item,
        searchTerm,
        setItemToMove,
        setOpenMoveModal,
        onDelete: onOpenRemove,
        onRename: onOpenEdit,
        onAdd: onAdd,
        onChecked,
        checkable,
        checked: checkedItem,
        onBlur: () => setHovered(false)
      });
    }
    return null;
  })();

  /* BADGE */
  const badgesEl =
    !customRowEl &&
    (item.badges || (item.badge ? [item.badge] : [])).map((badge: Badge, index: number) => {
      return <OverviewBadge key={`${index}`} collection={collection} item={badge} data-testid={`overview-row-badge`} />;
    });

  /* UNSEEN COUNT */
  const unseenCountEl = <OverviewUnseeCount count={item.unseenCount} />;

  /* TITLE */
  const titleTextElement = !customRowEl ? (
    searchTerm ? (
      <OverviewHighlight text={rowTitle} searchTerm={searchTerm} />
    ) : (
      rowTitle
    )
  ) : (
    <></>
  );
  const titleEl = ((!customRowEl && rowMode === "basic") || rowMode === "add") && (
    <Box minWidth={0} flex={1}>
      <Box display={"flex"} alignItems={"center"}>
        <Typography data-testid={`overview-row-title`} noWrap>
          {titleTextElement}
        </Typography>
        {unseenCountEl}
      </Box>
      {subTitle && (
        <Box>
          <Typography noWrap className={"rowSubTitle"} data-testid={`overview-row-sub-title`}>
            {subTitle}
          </Typography>
        </Box>
      )}
    </Box>
  );

  /* ACTIONS */
  const anyActionExist = !!(addAction || exportAction || markAsDoneAction || filteredActions?.length);
  const actionEl = anyActionExist && rowMode === "basic" && (
    <Box className="rowActions" display="flex" ml={1} onClick={stopEvent}>
      {addAction && (
        <OverviewRowAction
          action={"add"}
          title={addAction?.titles?.[level || 0] || t("add")}
          id={id}
          onHandle={onOpenAdd}
        >
          <Add />
        </OverviewRowAction>
      )}
      {filteredActions?.some(i => i.action === "edit") && (
        <OverviewRowAction action={"edit"} title={t("edit")} id={id} onHandle={onOpenEdit}>
          <Edit />
        </OverviewRowAction>
      )}
      {otherActions?.map((otherAction, index) => (
        <OverviewRowAction
          key={`${id}-${otherAction.action}-${index}`}
          title={otherAction.title}
          action={otherAction.action}
          id={id}
          item={item}
          disabled={otherAction.disabled}
          onHandle={otherAction.onHandle}
          handleHook={blueProgressBar}
          childrens={otherAction.childrens}
        >
          {otherAction.icon}
        </OverviewRowAction>
      ))}
      {exportAction && (
        <OverviewRowAction title={t("downloadSelected")} id={id} onHandle={exportAction.onHandle}>
          <GetApp />
        </OverviewRowAction>
      )}
      {markAsDoneAction && (
        <OverviewRowAction title={t("sections:mark_done")} id={id} onHandle={onOpenDoneMode}>
          <Done />
        </OverviewRowAction>
      )}
      {filteredActions?.some(i => i?.action === "reset") && (
        <OverviewRowAction title={t("manage-user-page:reset")} id={id} onHandle={onOpenReset}>
          <VpnKeyIcon />
        </OverviewRowAction>
      )}
      {filteredActions?.some(i => i?.action === "remove") && (
        <OverviewRowAction title={t("remove")} id={id} onHandle={onOpenRemove}>
          <Delete />
        </OverviewRowAction>
      )}
    </Box>
  );
  const editEl = rowMode === "edit" && (
    <OverviewRenamer title={item.editableTitle ?? title} onCancel={onCloseEdit} onSave={onPatchClick} />
  );
  const removeEl = rowMode === "remove" && (
    <OverviewConfirmer
      title={t(`overview:delete_confirmation_${collection.toLowerCase()}`, t(`overview:delete_confirmation`), {
        name: rowTitle
      })}
      onConfirm={onDeleteClick}
      onCancel={onCloseRemove}
    />
  );
  const resetEl = rowMode === "reset" && (
    <OverviewConfirmer
      title={t("overview:reset_password_confirmation", { name: rowTitle })}
      onConfirm={onResetClick}
      onCancel={onCloseReset}
    />
  );
  const doneEl = rowMode === "done" && (
    <OverviewConfirmer
      title={t("overview:mark_as_done_confirmation", { name: rowTitle })}
      onConfirm={onDoneClick}
      onCancel={onCloseDoneMode}
    />
  );
  const onMouseEnterItemCallback = useCallback(() => {
    onMouseEnter?.(item);
    setHovered(true);
  }, [item, onMouseEnter]);
  const onMouseLeaveItemCallback = useCallback(() => {
    setHovered(false);
  }, []);

  const addPlaceholders = actions.find(({ action }) => action === "add")?.placeholders || [];

  const addItemEl = rowMode === "add" && (
    <OverviewAdd
      level={level || 0}
      placeholder={addPlaceholders[level || 0] || t("new_item_placeholder")}
      onAdd={onAddCallback}
      onClose={onAddClose}
    />
  );

  const childEl = hasChild && open && item.children?.length !== 0 && (
    <Box className={cls.child}>
      {item.children?.map(i => (
        <OverviewInView key={`${i.id || i.title}-${level || 0}`}>
          <OverviewRow
            actions={actions}
            activeRow={activeRow}
            checkable={checkable}
            checkedItems={checkedItems}
            collection={collection}
            dragItem={dragItem}
            dynamicIndentation={dynamicIndentation}
            item={i}
            level={(level || 0) + 1}
            rowComponent={rowComponent}
            searchTerm={searchTerm}
            setActiveRow={setActiveRow}
            setItemToMove={setItemToMove}
            setOpenMoveModal={setOpenMoveModal}
            translationDomainName={translationDomainName}
            onAdd={onAdd}
            onCheckRow={onCheckRow}
            onClick={onClick}
            onDelete={onDelete}
            onDragOverController={onDragOverController}
            onMouseEnter={onMouseEnter}
            onPatch={onPatch}
            onValidate={onValidate}
          />
        </OverviewInView>
      ))}
    </Box>
  );

  const emptyChildEl = hasChild && open && item.children?.length === 0 && (
    <Box className={cls.emptyChild}>{t("no_entries_found")}</Box>
  );

  /* DRAGGING */
  const {
    attributes,
    listeners,
    transform,
    setNodeRef: setNodeRefDraggable
  } = useDraggable({
    id: `draggable-${item.id}`,
    disabled: isDisableDraggable || rowMode !== "basic",
    data: {
      item
    }
  });

  const dragStyle: CSSProperties | undefined = useMemo(
    () =>
      transform
        ? {
            transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
            background: "#fff",
            zIndex: 999,
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            position: "relative",
            width: "75%"
          }
        : undefined,
    [transform]
  );

  const {
    isOver,
    over,
    setNodeRef: setNoderefDroppable
  } = useDroppable({
    id: `droppable-${item.id}`,
    data: {
      item
    }
  });

  useEffect(() => {
    if (isOver && dragItem && over && onDragOverController) {
      const draggableItem = dragItem;
      const droppableItem = over.data?.current?.item;
      if (draggableItem && droppableItem) {
        const allow = onDragOverController(draggableItem, droppableItem);
        setAllowDrop(allow);
      }
    } else {
      setAllowDrop(false);
    }
  }, [dragItem, isOver, onDragOverController, over]);

  /* ROW */

  const [ref, inView] = useInView({ triggerOnce: true });
  const minHeight: CSSProperties = useMemo<CSSProperties>(
    () => ({
      minHeight: 58
    }),
    []
  );
  const paddingLeftStyle = useMemo<CSSProperties>(
    () => ({
      paddingLeft: dynamicIndentation ? `${(level || 0) * 32 + 8}px` : paddingLeft[level || 0]
    }),
    [dynamicIndentation, level]
  );

  return (
    <div
      style={minHeight}
      ref={ref}
      data-testid={`overview-row`}
      key={item.id + rowTitle}
      onMouseEnter={onMouseEnterItemCallback}
      onMouseLeave={onMouseLeaveItemCallback}
    >
      <div ref={hasChild ? setNoderefDroppable : undefined}>
        <div ref={setNodeRefDraggable} {...listeners} {...attributes} style={dragStyle}>
          {inView ? (
            showCustomComponent ? (
              customRowEl
            ) : (
              <Box
                className={[
                  cls.row,
                  "overview-row",
                  allowDrop ? "isOver" : "",
                  rowMode === "edit" ? "blue" : "",
                  rowMode === "reset" ? "blue" : "",
                  rowMode === "remove" ? "red" : "",
                  rowMode === "done" ? "green" : "",
                  progressMode ? "progress" : "",
                  progressMode,
                  checkable ? "checkable" : "",
                  checkedItem ? "checked" : "",
                  selectedId === id ? "selected" : "",
                  onClick !== undefined ? cls.clickable : "",
                  onClick !== undefined || onMouseEnter !== undefined ? cls.hoverable : ""
                ]
                  .filter(nonEmpty => nonEmpty)
                  .join(" ")}
                style={paddingLeftStyle}
                alignItems="center"
                display="flex"
                onClick={onRowClick}
              >
                {checkBoxEl}
                {chevronEl}
                {badgesEl}
                {editEl}
                {resetEl}
                {removeEl}
                {doneEl}
                {customRowEl}
                {titleEl}
                {actionEl}
              </Box>
            )
          ) : null}
        </div>
      </div>
      {addItemEl}
      {childEl}
      {emptyChildEl}
    </div>
  );
};

const paddingLeft: Record<number, string> = {
  0: "8px",
  1: "48px",
  2: "126px"
};
