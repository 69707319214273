import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { createTheme } from "@material-ui/core";
import theme from "../../../../theme/theme";
import GenericMetaViewTabs from "../../../../components/MetaView/GenericMetaViewTabs";
import { ThemeProvider } from "@material-ui/styles";
import { ProcessesOverviewReadOnly } from "../../processes/overview/ProcessesOverviewReadOnly";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

import { ResourceMetaviewProps } from "../ResourceTypeOverviewDeps";

export const LegalBasisMetaview = (props: ResourceMetaviewProps) => {
  const { resourceId } = props;
  const { t } = useTranslation("resources_legal-basis_overview");

  const smallTheme = useMemo(
    () =>
      // render smaller spacing for the meta view
      createTheme({
        ...theme,
        spacing: 4
      }),
    []
  );
  return (
    <GenericMetaViewTabs
      tabs={[
        {
          content: (
            <ThemeProvider theme={smallTheme}>
              <ProcessesOverviewReadOnly
                key={resourceId}
                noEntriesText={t("no_processes")}
                filter={{
                  legalBasisIDs: [resourceId]
                }}
              />
            </ThemeProvider>
          ),
          icon: <InsertDriveFileIcon />,
          tabId: "processes",
          title: t("processes_overview:list_processes").toUpperCase()
        }
      ]}
    />
  );
};
