import { OverviewResult, OverviewResultDecorator } from "../overviewBaseController";
import { Department, getOrgUnits } from "../../../../app/handlers/departmentHandler";

export const departmentsDecorator: OverviewResultDecorator<{ readonly _departments: Department[] }> = {
  async decorate(overviewResult: OverviewResult): Promise<{ readonly _departments: Department[] }> {
    if (overviewResult._departments) {
      return { _departments: overviewResult._tenantUsers };
    }

    const nonDeletedDepartments = (await getOrgUnits()).filter(it => !it.deleted);
    return {
      _departments: nonDeletedDepartments || []
    };
  }
};
