import React, { useCallback, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, TextField } from "@material-ui/core";
import TextBodySecondary from "components/TextBodySecondary/TextBodySecondary";
import AuthenticationPageTemplate, { AuthenticationAlert } from "app/pages/authentication/AuthenticationPageTemplate";
import { useNavigate } from "react-router-dom";
import { triggerResetPasswordApi } from "app/api/user/userPasswordResetApi";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const toLoginPage = useCallback(() => {
    navigate("/login");
  }, [navigate]);
  const { t } = useTranslation("authentication");
  const [email, setEmail] = useState("");
  const [alertNotification, setAlertNotification] = useState<AuthenticationAlert | null>(null);

  const [isInvalidEmail, setIsInvalidEmail] = useState(true);
  useEffect(() => {
    setIsInvalidEmail(!email);
  }, [email]);

  const [resetInProgress, setResetInProgress] = useState(false);
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const sendResetEmailCallback = useCallback(async () => {
    // if user data is accessible and email was input
    if (!isInvalidEmail) {
      // checks if email belongs to existing account and sends reset email
      try {
        setResetInProgress(true);
        await triggerResetPasswordApi({ email });
        setResetSuccessful(true);
      } catch (error) {
        setResetInProgress(false);
        console.error(`error on triggering password reset`, error);
        setAlertNotification({
          title: t("error_send_reset_email"),
          severity: "error"
        });
        return;
      }

      setResetInProgress(false);
      setAlertNotification({
        title: t("reset_email_sent"),
        severity: "success"
      });
    }
  }, [t, email, isInvalidEmail]);

  useEffect(() => {
    if (resetSuccessful) {
      const timeoutId = setTimeout(() => {
        navigate("/login");
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [resetSuccessful, navigate]);

  const setEmailCallback = useCallback(
    event => {
      setEmail(event.target.value);
    },
    [setEmail]
  );

  const onEmailResetEnterCallback = useCallback(
    async event => {
      if (event.key === "Enter") {
        await sendResetEmailCallback();
      }
    },
    [sendResetEmailCallback]
  );

  return (
    <AuthenticationPageTemplate alertNotification={alertNotification}>
      <>
        {!resetSuccessful && (
          <Box>
            <Box
              mt={2}
              mb={1}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
            >
              <TextBodySecondary parseHTML={true} text={t("password_recovery_title")} />
              <TextBodySecondary text={t("password_recovery_text")} />
            </Box>
            <Box mt={2} mb={1}>
              <TextField
                fullWidth
                autoComplete="off"
                type="email"
                value={email}
                onChange={setEmailCallback}
                variant="outlined"
                label={t("email")}
                onKeyDown={onEmailResetEnterCallback}
              />
            </Box>
            <Box mt={2} mb={1} sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={isInvalidEmail || resetInProgress}
                onClick={sendResetEmailCallback}
              >
                {resetInProgress && (
                  <Box mr={1}>
                    <CircularProgress color="inherit" size={14} />
                  </Box>
                )}
                {t("reset_password")}
              </Button>
            </Box>
          </Box>
        )}
        <Box mt={2} mb={1} sx={{ display: "flex", justifyContent: "center" }}>
          <Button color="primary" onClick={toLoginPage}>
            {t("back_to_login")}
          </Button>
        </Box>
      </>
    </AuthenticationPageTemplate>
  );
}
