export const toJSDateObjectIfFirebaseDate = inputDate => {
  return inputDate?.toDate?.() || inputDate;
};

export const toJSDateObjectIfISO8601 = inputDateString => {
  if (!(inputDateString instanceof String || typeof inputDateString === "string")) {
    return inputDateString;
  }

  return new Date(inputDateString);
};

export const toYYYYMMDDInUTC = inputDate => {
  return toJSDateObjectIfFirebaseDate(inputDate)?.toISOString?.().split("T")[0] || inputDate;
};

export const yyyyMMddTODate = yyyyMMdd => {
  const parts = yyyyMMdd.split("-");
  const year = parts[0];
  const month = parseInt(parts[1]) - 1; // js month starts with 0, hence -1
  const date = parts[2];
  return new Date(Date.UTC(year, month, date, 15));
};

export const isSameYYYYMMDDInUTC = (firstDate, secondDate) => {
  return toYYYYMMDDInUTC(firstDate || null) === toYYYYMMDDInUTC(secondDate || null);
};

export const toDDMMYYYYInUTC = inputDate => {
  const padTo2Digits = num => {
    return num.toString().padStart(2, "0");
  };

  const validDate = toJSDateObjectIfFirebaseDate(inputDate);
  return [
    padTo2Digits(validDate.getUTCDate()),
    padTo2Digits(validDate.getUTCMonth() + 1),
    validDate.getUTCFullYear()
  ].join(".");
};

export const safeGetMs = it => (it.seconds && it.seconds * 1000) || (it.getTime && it.getTime());

export const getYYYYMMDDRange = (startDate, endDate) => {
  const yyyyMMDDs = [];
  const endDateWithoutTime = toYYYYMMDDInUTC(endDate);
  let currentDate = startDate;
  while (toYYYYMMDDInUTC(currentDate) <= endDateWithoutTime) {
    yyyyMMDDs.push(toYYYYMMDDInUTC(currentDate));
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }
  return yyyyMMDDs;
};

export const toStartOfMonth = inputDate => {
  return new Date(Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), 1, 15));
};

export const toStartOfWeek = inputDate => {
  const resultDate = new Date(inputDate);

  // https://stackoverflow.com/a/4156562/1765184
  const day = resultDate.getDay(); // 0 sunday, 1 monday, and so on
  const diff = resultDate.getDate() - day;
  return new Date(resultDate.setDate(diff));
};

export const convertDDMMYYYStringToDate = dateString => {
  const delimiter = dateString.includes("/") ? "/" : ".";
  let d = dateString.split(delimiter);

  return new Date(d[1] + "/" + d[0] + "/" + d[2]);
};
