import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAuthentication } from "../handlers/authentication/authentication-context";
import { useErrorSnackbar } from "../../hook/errorSnackbar";
import { useParams } from "react-router-dom";
import { AuditRemarkContext, getAuditDetail } from "app/api/auditApi";

export interface AuditRemarksContextProps {
  readonly loading: boolean;
  readonly initialized: boolean;
  readonly reloadAuditRemarks: () => void;
  readonly auditRemarks: AuditRemarkContext[];
}

export const AuditRemarksContext = createContext<AuditRemarksContextProps>({
  loading: true,
  initialized: false,
  reloadAuditRemarks: async () => {
    // do
  },
  auditRemarks: []
});

export const AuditRemarksProvider = ({ children }: { readonly children?: React.ReactNode }) => {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [auditRemarks, setAuditRemarks] = useState<AuditRemarkContext[]>([]);
  const { auth } = useAuthentication();

  const params = useParams();
  const documentId = params?.id;

  const { catchAsSnackbar } = useErrorSnackbar();

  const reloadAuditRemarks = useCallback(() => {
    const init = async () => {
      if (auth?.uid && documentId) {
        const auditDetail = await getAuditDetail({ id: documentId });
        if (auditDetail && auditDetail.questions) {
          const remarks: AuditRemarkContext[] = auditDetail.questions
            .filter(({ remark }) => remark)
            .map(({ id, remark }) => ({ questionId: id, ...remark }) as unknown as AuditRemarkContext);
          setAuditRemarks(remarks);
        }
        setLoading(false);
        setInitialized(true);
      }
    };
    setLoading(true);
    return init();
  }, [auth?.uid, documentId]);

  useEffect(() => {
    if (documentId) {
      reloadAuditRemarks().catch(catchAsSnackbar("Failed to load tasks"));
    }
  }, [documentId, catchAsSnackbar, auth, reloadAuditRemarks]);

  return (
    <AuditRemarksContext.Provider
      value={{
        loading,
        initialized,
        reloadAuditRemarks: reloadAuditRemarks,
        auditRemarks: auditRemarks
      }}
    >
      {children}
    </AuditRemarksContext.Provider>
  );
};

export const useAuditRemarks = () => useContext(AuditRemarksContext);
