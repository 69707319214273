import { CardContent, MenuList } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons";
import { sourceLanguages, targetLanguages } from "./languageLists";
import { useTranslator } from "../../../contexts/translator-context";

let useStyles = makeStyles(theme => ({
  card: {
    width: 250,
    maxHeight: 350,
    overflow: "auto"
  },
  listItemLanguage: {
    padding: 5
  },
  menuContainer: {
    marginTop: -10
  },
  listHeaderContainer: {
    marginBottom: -15
  },
  hideTranslation: {
    color: theme.palette.primary.main
  },
  arrowLeft: {
    marginLeft: -10
  },
  divider: {
    marginBottom: 5
  }
}));

export default function TranslatorMenu({ translationId, onSelectLanguage }) {
  const classes = useStyles();
  const [hiddenMenu, setHiddenMenu] = useState("sourceLanguage");
  const {
    findTranslation,
    loading,
    translateHook,
    setTargetLanguageHook,
    setSelectedSourceLanguageHook,
    hideTranslationHook
  } = useTranslator();
  const [translation, setTranslation] = useState({});
  useEffect(() => {
    setTranslation(findTranslation(translationId));
  }, [findTranslation, translationId]);

  useEffect(() => {
    if (translation?.targetLanguage) {
      translateHook(translation);
    }
  }, [translation?.targetLanguage, translation?.selectedSourceLanguage]);

  return (
    <div data-testid={"translator_menu"}>
      <div hidden={hiddenMenu === "translateLanguage"} className={classes.menuContainer}>
        <TargetLanguageMenu
          hideLanguageDisabled={loading}
          updateHiddenMenu={() => setHiddenMenu("translateLanguage")}
          selectedLanguage={translation?.targetLanguage}
          updateSelectedTargetLanguage={language => {
            setTargetLanguageHook(translationId, language);
            if (onSelectLanguage) {
              onSelectLanguage(language);
            }
          }}
          showDetected={() => {
            if (translation?.selectedSourceLanguage) {
              return false;
            } else if (translation?.detectedSourceLanguage) {
              return true;
            }
            return false;
          }}
          hideTranslation={() => {
            hideTranslationHook(translationId);
          }}
          detectedSourceLanguage={translation?.detectedSourceLanguage}
        />
      </div>
      <div className={classes.menuContainer} hidden={hiddenMenu === "sourceLanguage"}>
        <SourceLanguageMenu
          updateHiddenMenu={() => setHiddenMenu("sourceLanguage")}
          updateSelectedSourceLanguage={language => setSelectedSourceLanguageHook(translationId, language)}
          sourceLanguage={translation?.selectedSourceLanguage}
        />
      </div>
    </div>
  );
}

function TargetLanguageMenu({
  updateHiddenMenu,
  selectedLanguage,
  updateSelectedTargetLanguage,
  detectedSourceLanguage,
  hideTranslation,
  showDetected,
  hideLanguageDisabled
}) {
  const { t, i18n } = useTranslation("deepL_menu");
  const classes = useStyles();
  const [sortedTargetLanguages, setSortedTargetLanguages] = useState([]);

  useEffect(() => {
    setSortedTargetLanguages(translateAndSortDeepLLanguageCodes(["DE", "EN-US", "EN-GB"], targetLanguages, t));
  }, [i18n.language]);

  return (
    <Card className={classes.card} data-testid={"target_language_menu"}>
      <CardContent>
        <MenuList>
          <div className={classes.listHeaderContainer} />
          <ListItem
            disabled={hideLanguageDisabled}
            hidden={!selectedLanguage}
            className={classes.listItemLanguage}
            data-testid={"hide_translation"}
            button
            disableRipple
            onClick={hideTranslation}
          >
            <span className={classes.hideTranslation}>
              <b>{t("hide_translation")}</b>
            </span>
          </ListItem>
          <ListItem
            button
            disableRipple
            className={classes.listItemLanguage}
            onClick={updateHiddenMenu}
            data-testid={"switch_source_menu"}
          >
            <span>
              {t("detected_source_language")} <b>{t("deepL_language:" + detectedSourceLanguage)}</b>{" "}
              {showDetected() ? t("detected") : ""}
            </span>
            <ArrowRightOutlined />
          </ListItem>
          <Divider className={classes.divider} />
          <ListItem key={"translateTo"} className={classes.listItemLanguage} button disableRipple>
            <span>
              <b>{t("translate_to")} </b>
            </span>
          </ListItem>
          {sortedTargetLanguages.map(language => {
            return (
              <ListItem
                key={language.langCode}
                data-testid={"target_language_" + language.langCode}
                className={classes.listItemLanguage}
                button
                disableRipple
                onClick={() => updateSelectedTargetLanguage(language.langCode)}
                selected={language.langCode === selectedLanguage}
              >
                <span>{language.translation}</span>
              </ListItem>
            );
          })}
        </MenuList>
      </CardContent>
    </Card>
  );
}

function SourceLanguageMenu({ updateHiddenMenu, updateSelectedSourceLanguage, sourceLanguage }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation("deepL_menu");
  const [sortedSourceLanguages, setSortedSourceLanguages] = useState([]);

  useEffect(() => {
    setSortedSourceLanguages(translateAndSortDeepLLanguageCodes(["DE", "EN"], sourceLanguages, t));
  }, [i18n.language]);

  return (
    <Card className={classes.card} data-testid={"source_language_menu"}>
      <CardContent>
        <MenuList>
          <div className={classes.listHeaderContainer} />
          <ListItem
            className={classes.listItemLanguage}
            button
            disableRipple
            onClick={updateHiddenMenu}
            data-testid={"switch_target_menu"}
          >
            <ArrowLeftOutlined className={classes.arrowLeft} />
            <span>Select Source Language</span>
          </ListItem>

          <Divider className={classes.divider} />
          {sortedSourceLanguages.map(language => {
            return (
              <ListItem
                data-testid={"source_language_" + language.langCode}
                selected={sourceLanguage === language.langCode}
                key={language.langCode}
                className={classes.listItemLanguage}
                button
                disableRipple
                onClick={() => updateSelectedSourceLanguage(language.langCode)}
              >
                <span>{language.translation}</span>
              </ListItem>
            );
          })}
        </MenuList>
      </CardContent>
    </Card>
  );
}

// exceptions have their own sorting
const translateAndSortDeepLLanguageCodes = (exceptions, languages, t) => {
  const translatedLanguages = languages.map(language => {
    return { translation: t("deepL_language:" + language), langCode: language };
  });
  return translatedLanguages.sort((a, b) => {
    if (exceptions.includes(b.langCode) && exceptions.includes(a.langCode)) {
      return a.langCode.localeCompare(b.langCode);
    }
    if (exceptions.includes(a.langCode)) {
      return -1;
    }
    if (exceptions.includes(b.langCode)) {
      return 1;
    }
    return a.translation.localeCompare(b.translation);
  });
};
