import * as CSS from "csstype";
import { FontFaceFontWeightProperty } from "csstype";
import Sanchez from "./Sanchez/Sanchez-Regular.ttf";
import OpenSans from "./Open_Sans/OpenSans-Regular.ttf";
import NotoSansKR from "./NotoSansKR/NotoSansKR-Regular.ttf";

// Defaults fonts properties
const unicodeRange =
  "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF";
const fontStyle = "normal";
const fontDisplay = "swap";
const defaultFontWeight: FontFaceFontWeightProperty = 400;

const sanchezFont = {
  fontFamily: "Sanchez",
  fontStyle,
  fontDisplay,
  fontWeight: defaultFontWeight,
  src: `
      local('Sanchez'),
      local('Sanchez-Regular'),
      url(${Sanchez}) format('truetype')
    `,
  unicodeRange
} as const satisfies CSS.FontFace;

// OpenSans font for Headlines (H2-H4) and Body-Text
const openSansFont = {
  fontFamily: "OpenSans",
  fontStyle,
  fontDisplay,
  fontWeight: defaultFontWeight,
  src: `
      local('OpenSans'),
      local('OpenSans-Regular'),
      url(${OpenSans}) format('truetype')
    `,
  unicodeRange
} as const satisfies CSS.FontFace;

// NotoSans font for korean language
const notoSansKRFont = {
  fontFamily: "NotoSansKR",
  fontStyle,
  fontDisplay,
  fontWeight: defaultFontWeight,
  src: `
      local('NotoSansKR'),
      local('NotoSansKR-Regular'),
      url(${NotoSansKR}) format('truetype')
    `,
  unicodeRange
} as const satisfies CSS.FontFace;

export { sanchezFont, openSansFont, notoSansKRFont };
