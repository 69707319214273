import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Box, Button, Avatar, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { convertToRaw, EditorState } from "draft-js";
import { UserDTO } from "app/api/user/userApi";
import { useComments } from "app/api/commentApi";
import { sendNotificationApi } from "app/api/userNotificationApi";
import { useSnackbar } from "notistack";
import { EditorWithMention } from "components/Comment/EditorWithMention";
import { COLLECTIONS } from "app/collections";
import isUserRoleAdminOrExpertOrRoot from "app/handlers/permissionHandler";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "flex-start"
  },
  avatar: {
    width: 36,
    height: 36,
    marginTop: theme.spacing(2),
    background: theme.palette.primary.main,
    fontSize: 14
  },
  inputContainer: {
    display: "flex",
    border: "1px solid rgba(193, 194, 195, 1)",
    borderRadius: "4px",
    margin: theme.spacing(2),
    marginRight: 0,
    flexDirection: "column",
    width: "100%",
    minHeight: 36,
    justifyContent: "center"
  },
  input: {
    padding: 0,
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    caretColor: theme.palette.primary.main,
    "& .public-DraftStyleDefault-block": {
      margin: 0
    }
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: theme.spacing(2),
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }
}));

const getAvatarInitials = (userName: string) => {
  return userName?.substring(0, 2).toUpperCase() || "";
};

interface TaskCommentInputProps {
  readonly taskId: string;
  readonly groupTaskId?: string;
  readonly assigneeUID: string;
  readonly docName: string;
  readonly collection: string;
  readonly pageId: string;
  readonly setNewlyAddedCommentId: (commentId: string) => void;
  readonly onChangeParticipants: (participants: string[]) => void;
  readonly participants?: string[];
}
export const TaskCommentInput = ({
  taskId,
  groupTaskId,
  assigneeUID,
  docName,
  collection,
  pageId,
  setNewlyAddedCommentId,
  onChangeParticipants,
  participants
}: TaskCommentInputProps) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const { nonRootAdminTenantUsers, getUserNameHook } = useUserAndTenantData();
  const { auth, user } = useAuthentication();
  const { uid } = auth || { uid: "" };
  const { enqueueSnackbar } = useSnackbar();

  const [isEditing, setIsEditing] = useState(false);
  const [mentionedUsers, setMentionedUsers] = useState<(UserDTO & { checked?: boolean })[]>([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [currentMentions, setCurrentMentions] = useState<UserDTO[]>([]);

  const availableMentions = nonRootAdminTenantUsers.map(user => ({
    ...user,
    name: getUserNameHook(user?.id || "")
  }));
  const [suggestions, setSuggestions] = useState(availableMentions);

  useEffect(() => {
    if (editorState.getCurrentContent().getPlainText().length > 0) {
      setIsEditing(true);
    }
  }, [editorState]);

  const onCancel = useCallback(() => {
    setEditorState(EditorState.createEmpty());
    setIsEditing(false);
  }, []);

  const namespaceForComment = useMemo(() => {
    const isGroupComment = Boolean(groupTaskId);
    if (isGroupComment) {
      return {
        namespace: `task groupTaskId ${groupTaskId} assigneeUID ${assigneeUID}`,
        namespacePrefix: undefined
      };
    } else {
      return { namespace: "task", namespacePrefix: undefined };
    }
  }, [groupTaskId, assigneeUID]);

  const comments = useComments({
    documentId: taskId,
    namespace: namespaceForComment.namespace,
    namespacePrefix: namespaceForComment.namespacePrefix
  });

  const createNotificationMetadata = useCallback(
    function ({ origin }: { origin: Record<string, unknown> }) {
      if (!auth || !user) return;
      return {
        collection: collection || "",
        docId: taskId,
        docName: docName,
        pageId: pageId,
        origin: origin || {}
      };
    },
    [auth, user, collection, taskId, docName, pageId]
  );

  const createMentionNotification = useCallback(
    function (mentionedUsers: UserDTO[], message: string, origin: { type: string; commentId: string }) {
      const basicNotificationObject = createNotificationMetadata({ origin });
      if (!basicNotificationObject) return;
      const notificationObj = {
        ...basicNotificationObject,
        receivers: mentionedUsers.map(user => user.id).filter(nonEmpty => nonEmpty) as string[],
        title: "comment_mention",
        message: message
      };
      return sendNotificationApi(notificationObj);
    },
    [createNotificationMetadata]
  );

  const createComment = useCallback(() => {
    try {
      const contentState = editorState.getCurrentContent();
      const rawContent = convertToRaw(contentState);
      const message = rawContent.blocks.map(block => block.text).join("\n");
      setEditorState(EditorState.createEmpty());
      const assignedUsers = mentionedUsers.filter(x => x.checked);
      const usersMentionedOnly = mentionedUsers.filter(x => !assignedUsers.includes(x));
      onChangeParticipants([
        ...new Set([
          ...(participants ? participants : []),
          ...usersMentionedOnly.map(x => x.id).filter((id): id is string => !!id)
        ])
      ]);
      if (auth) {
        comments
          .addComment({
            contentState: rawContent,
            value: message,
            documentId: taskId,
            namespace: namespaceForComment.namespace,
            mentionedUsers: usersMentionedOnly.map(x => x.id).filter((id): id is string => !!id)
          })
          .then(commentId => {
            setNewlyAddedCommentId(commentId);
            setTimeout(() => setNewlyAddedCommentId(""), 3000);
            const notificationsOrigin = { type: "comment", commentId: commentId || "" };
            // don't notify if mentioned user === creator of task
            const mentionedUsersWithoutCurrent = mentionedUsers.filter(mentionedUser => mentionedUser.id !== auth.uid);
            createMentionNotification(mentionedUsersWithoutCurrent, message, notificationsOrigin);
            setMentionedUsers([]);
            comments.mutate();
            setNewlyAddedCommentId(commentId);
            setTimeout(() => setNewlyAddedCommentId(""), 3000);
          });
      }
    } catch (error) {
      enqueueSnackbar(t("common:error"), { variant: "error" });
    }
  }, [
    auth,
    comments,
    createMentionNotification,
    editorState,
    enqueueSnackbar,
    mentionedUsers,
    namespaceForComment.namespace,
    onChangeParticipants,
    participants,
    setNewlyAddedCommentId,
    t,
    taskId
  ]);

  const onSubmit = useCallback(() => {
    setIsEditing(false);
    createComment();
  }, [createComment]);

  const defaultSuggestionsFilter = React.useCallback((searchValue: string, suggestions: any[]) => {
    const value = searchValue.toLowerCase();
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        !value ||
        suggestion.email.toLowerCase().indexOf(value) > -1 ||
        suggestion.name.toLowerCase().indexOf(value) > -1
    );
    const length = filteredSuggestions.length < 5 ? filteredSuggestions.length : 5;
    return filteredSuggestions.slice(0, length);
  }, []);

  const setSuggestionsOnSearchChange = React.useCallback(
    (value: string) => {
      if (!availableMentions) {
        return;
      }
      const expertOnlyCollection = () => {
        switch (collection) {
          case COLLECTIONS.EXTERNAL_RECIPIENTS:
          case COLLECTIONS.DATA_BREACHES:
          case COLLECTIONS.TOM:
          case COLLECTIONS.RISK:
          case COLLECTIONS.AUDITS:
            return true;
          default:
            return false;
        }
      };
      const expertOnly = expertOnlyCollection();
      if (!expertOnly) {
        setSuggestions(defaultSuggestionsFilter(value, availableMentions));
        return;
      }
      const expertUsers = availableMentions.filter(element =>
        isUserRoleAdminOrExpertOrRoot({ role: element.userRole || "" })
      );
      setSuggestions(defaultSuggestionsFilter(value, expertUsers));
    },
    [availableMentions, collection, defaultSuggestionsFilter]
  );

  const onSearchChange = React.useCallback(
    ({ value }: { value: string }) => {
      setSuggestionsOnSearchChange(value);
    },
    [setSuggestionsOnSearchChange]
  );

  const onAddMention = React.useCallback(
    (newEntry: UserDTO) => {
      const temp = [...currentMentions, newEntry];
      setCurrentMentions(temp);
      setMentionedUsers(temp);
    },
    [currentMentions, setCurrentMentions]
  );

  return (
    <Box className={cls.root}>
      <Avatar className={cls.avatar}>{getAvatarInitials(getUserNameHook(uid))}</Avatar>
      <Box className={cls.inputContainer}>
        <Box p={1} className={cls.input}>
          <EditorWithMention
            text={editorState}
            setText={setEditorState}
            readOnly={false}
            onSearchChange={onSearchChange}
            suggestions={suggestions}
            onAddMention={onAddMention}
            placeholder={""}
          />
        </Box>
        {isEditing && (
          <Box p={1} className={cls.buttons}>
            <Button variant="contained" color="primary" onClick={onSubmit}>
              {t("common:comment")}
            </Button>
            <Button variant="outlined" color="primary" onClick={onCancel}>
              {t("common:cancel")}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
