/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import overviewControllerFabric from "components/Overview/controllers/overviewControllerFabric";
import { OverviewItem, OverviewNewItem } from "./controllers/overviewBaseController";
import { debounce } from "lodash-es";
import { OVERVIEW_ACTIONS, useOverviewDispatch, useOverviewState } from "app/contexts/overview-context";
import { OVERVIEW_ADD_TYPE } from "./constants/OverviewConstants";
import OverviewAdd from "./controls/OverviewAdd";
import {
  OverviewPagesToolbar,
  OverviewToolbarActionProps,
  OverviewToolbarProps
} from "./controls/OverviewPagesToolbar";
import { CustomRowComponentProps, OverviewRow, OverviewRowProps } from "./controls/OverviewRow";
import { OverviewTemplateDialog } from "./controls/OverviewTemplateDialog";
import { useSnackbar } from "notistack";
import { COLLECTION_TYPES, COLLECTIONS } from "app/collections";
import { OverviewRowActionProps } from "./controls/OverviewRowAction";
import { OverviewAddButtonActionProps } from "./controls/OverviewAddButton";
import { OverviewPageProps } from "./controls/OverviewPagesMenu";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { isAxiosErrorWithCode } from "../../app/api/axios/axiosErrorHandler";
import { useSidebarSWR } from "../../app/pages/shared/Sidebar/useSidebarUnseen";
import { AccountNotice } from "../../app/pages/authentication/AccountNotice";
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { CollectionParams, useOverviewData } from "hook/useOverviewData";
import { OverviewTabsToolbar } from "./controls/OverviewTabsToolbar";

export interface OverviewParams {
  readonly addActions?: OverviewAddButtonActionProps[];
  readonly checkable?: boolean;
  readonly children?: React.ReactNode;
  readonly collection: COLLECTION_TYPES;
  readonly collectionParams?: CollectionParams;
  readonly dnd?: boolean;
  readonly dynamicIndentation?: boolean;
  readonly header?: string;
  readonly hideCount?: boolean;
  readonly hideNoEntries?: boolean;
  readonly hideSearch?: boolean;
  readonly noEntriesText?: string;
  readonly pages?: OverviewPageProps[];
  readonly paginated?: boolean;
  readonly rowActions: OverviewRowActionProps[];
  readonly rowComponent?: React.ComponentType<any>;
  readonly CustomRowComponent?: React.ComponentType<CustomRowComponentProps<any>>;
  readonly sectionActions?: OverviewToolbarActionProps[];
  readonly selectable?: boolean;
  readonly selectionActions?: OverviewToolbarActionProps[];
  readonly setItemToMove?: (item: OverviewItem | null) => void;
  readonly setOpenMoveModal?: (value: boolean) => void;
  readonly shadowLoading?: boolean;
  readonly subHeader?: React.ReactNode;
  readonly toolbarActions?: OverviewToolbarActionProps[];
  readonly toolbarMode?: "pages" | "tabs";
  readonly translationDomainName?: string;

  readonly onAddClose?: () => void;
  readonly onAddOpen?: () => void;
  readonly onCheckedItems?: (items: OverviewItem[]) => void;
  readonly onPageChange?: (page: OverviewPageProps) => void;
  readonly onRowClick: (item: OverviewItem) => void;
  readonly onRowLeave?: () => void;
  readonly onRowOver?: (item: OverviewItem) => void;
}

const useStyles = makeStyles(() => ({
  "@global": {
    "@keyframes slide": {
      from: {
        backgroundPositionX: "0"
      },
      to: {
        backgroundPositionX: "113px"
      }
    },
    ".MuiAutocomplete-tag": {
      backgroundColor: "#EBF1FF"
    }
  },
  root: {
    height: "100%",
    overflow: "auto",
    "&.dragging": {
      pointerEvents: "none"
    }
  },
  noEntries: {
    background: "#EBF1FF",
    color: "#616161",
    borderRadius: "6px"
  },
  endOfList: {
    fontSize: "12px",
    color: "#9e9e9e"
  },
  header: {
    borderRadius: "8px 8px 0 0",
    position: "sticky",
    top: 0,
    background: "white",
    zIndex: 900,
    borderBottom: "1px solid #E0E0E0",
    "&.scrolled": {
      boxShadow: "0 8px 6px -6px #ccc"
    }
  },
  titleText: {
    fontSize: "28px",
    color: "#1C242D",
    transition: "font-size 0.3s ease-out",
    "&.scrolled": {
      transition: "font-size 0.3s ease-out",
      fontSize: "20px"
    }
  }
}));

const Overview = ({
  addActions,
  checkable,
  children,
  collection,
  collectionParams,
  dnd,
  dynamicIndentation,
  header,
  hideCount,
  hideNoEntries,
  hideSearch,
  noEntriesText,
  pages,
  paginated,
  rowActions,
  rowComponent,
  CustomRowComponent,
  selectable,
  selectionActions,
  setItemToMove,
  setOpenMoveModal,
  shadowLoading = false,
  subHeader,
  toolbarActions,
  toolbarMode,
  translationDomainName,

  onAddClose,
  onAddOpen,
  onCheckedItems,
  onPageChange,
  onRowClick,
  onRowLeave,
  onRowOver
}: OverviewParams) => {
  const cls = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();
  const { sidebarNewItemsMutate } = useSidebarSWR();
  const { t } = useTranslation("overview");
  const overviewSetup = useOverviewState()[collection];
  const dispatch = useOverviewDispatch();
  const [showAddEl, setShowAddEl] = useState(<></>);
  const [showTemplateDialog, setShowTemplateDialog] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [dragItem, setDragItem] = useState<OverviewItem | null>(null);
  const [controller] = useState(overviewControllerFabric(collection, collectionParams));
  const checkedIds = overviewSetup.checkedIds;
  const [activeRow, setActiveRow] = useState<string | null>(null);
  const [isScroll, setIsScroll] = useState<boolean>(false);

  const { overviewData, fetchNextPaginatedPage, reload, fetchNextPage } = useOverviewData({
    collection,
    collectionParams,
    controller,
    dispatch,
    overviewSetup,
    paginated,
    shadowLoading
  });

  /* CONTROLLER ACTIONS */
  const handleAPIError = useCallback(
    (error, payload) => {
      if (isAxiosErrorWithCode(error, 409)) {
        enqueueSnackbar(`${t("error_messages:generic_already_exists")}`, { variant: "error" });
        return;
      }
      if (isAxiosErrorWithCode(error, 403) && collection === COLLECTIONS.ROLE) {
        enqueueSnackbar(`${t("error_messages:role_is_used_cant_be_delete")}`, { variant: "error" });
        return;
      }
      if (error.response?.data?.message === "Not allowed to delete process") {
        enqueueSnackbar(`${t("error_messages:not_permission_delete_document")}`, { variant: "error" });
        return;
      }
      if (isAxiosErrorWithCode(error, 403)) {
        enqueueSnackbar(`${t("error_messages:no_permission")}`, { variant: "error" });
        return;
      }
      const errorMessage = error.response?.data?.message || error.message;
      enqueueSnackbar(errorMessage, { variant: "error" });
      throw error;
    },
    [enqueueSnackbar, t, collection]
  );

  const onAdd = useCallback(
    async (data: OverviewNewItem) => {
      const validationError = controller.validateItem?.(data);
      if (validationError) {
        enqueueSnackbar(validationError, { variant: "error" });
        return;
      }
      try {
        const id = await controller.addItem(data);
        reload(id);
      } catch (error) {
        handleAPIError(error, data.title || "");
      }
    },
    [controller, enqueueSnackbar, reload, handleAPIError]
  );

  const onAddAndGo = useCallback(async () => {
    const id = await controller.addItemAndGo();
    reload(id);
  }, [controller, reload]);

  const onCloseTemplateDialog = useCallback(() => setShowTemplateDialog(false), []);

  const onAddFromTemplates = useCallback(
    async templateIds => {
      if (!templateIds.length) {
        await onAddAndGo();
      } else {
        await controller.addItemsFromTemplates({ templateIds });
        reload();
        setShowTemplateDialog(false);
      }
    },
    [controller, reload, onAddAndGo]
  );

  const templateDialogEl = showTemplateDialog ? (
    <OverviewTemplateDialog
      collection={collection}
      passedTemplates={overviewData?.templates}
      getTemplates={controller.getTemplateItems}
      onClose={onCloseTemplateDialog}
      onConfirm={onAddFromTemplates}
    />
  ) : (
    <></>
  );

  useEffect(() => {
    if (checkedIds.length === 0) {
      onCheckedItems?.([]);
    }
  }, [checkedIds, onCheckedItems]);

  const onCheckRow = useCallback(
    (item, checked) => {
      const _checkedIds = checked ? [...checkedIds, item.id] : checkedIds.filter(id => id !== item.id);
      dispatch({
        type: OVERVIEW_ACTIONS.SET_CHECKED_IDS,
        collection: collection,
        checkedIds: _checkedIds
      });
      onCheckedItems?.(_checkedIds.map(id => controller.getById(id)).filter(notNull => notNull));
    },
    [checkedIds, collection, controller, dispatch, onCheckedItems]
  );

  const onResetChecked = useCallback(() => {
    dispatch({
      type: OVERVIEW_ACTIONS.SET_CHECKED_IDS,
      collection: collection,
      checkedIds: []
    });
  }, [collection, dispatch]);

  const onDuplicateItems = useCallback(
    async orgUnitIds => {
      dispatch({
        type: OVERVIEW_ACTIONS.ACTION_STARTED,
        collection
      });
      try {
        await controller.copyItems({ ids: checkedIds, orgUnitIds });
        dispatch({
          type: OVERVIEW_ACTIONS.SET_CHECKED_IDS,
          collection: collection,
          checkedIds: []
        });
        dispatch({
          type: OVERVIEW_ACTIONS.ACTION_COMPLETED,
          collection
        });
        reload();
      } catch (error) {
        dispatch({
          type: OVERVIEW_ACTIONS.ACTION_FAILED,
          collection
        });
      }
    },
    [checkedIds, collection, controller, dispatch, reload]
  );

  const onDeleteItems = useCallback(async () => {
    try {
      if (controller.deleteItems) {
        await controller.deleteItems(checkedIds);
      } else {
        await Promise.all(checkedIds.map(id => controller.deleteItem(id)));
      }
      await sidebarNewItemsMutate();

      // remove deleted items from checked ids
      const checkedIdsSet = new Set(checkedIds);
      dispatch({
        type: OVERVIEW_ACTIONS.SET_CHECKED_IDS,
        collection: collection,
        checkedIds: checkedIds.filter(id => !checkedIdsSet.has(id))
      });
    } catch (error) {
      handleAPIError(error, {});
    }
    reload();
  }, [reload, controller, sidebarNewItemsMutate, checkedIds, dispatch, collection, handleAPIError]);

  const onExportItems = useCallback(
    async format => {
      await controller.exportItems(format, checkedIds, overviewSetup);
    },
    [checkedIds, controller, overviewSetup]
  );

  const onExportAllItems = useCallback(
    async format => {
      await controller.exportAllItems(format, overviewSetup);
    },
    [controller, overviewSetup]
  );

  const onMarkAllAsRead = useCallback(async () => {
    if (!controller.markAllAsRead) return;
    await controller.markAllAsRead();
    reload();
  }, [controller, reload]);

  const onPatch = useCallback(
    async (id, data, options, url, originalItem) => {
      try {
        await controller.patchItem(id, data, options, url, originalItem);
      } catch (error) {
        handleAPIError(error, data);
      }
      reload();
    },
    [controller, reload, handleAPIError]
  );

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await controller.deleteItem(id);
      } catch (error) {
        handleAPIError(error, {});
      }
      await sidebarNewItemsMutate();
      reload();
    },
    [controller, reload, handleAPIError, sidebarNewItemsMutate]
  );

  const onAddSection = useCallback(
    async title => {
      setShowAddEl(<></>);
      await controller.addSection(title);
      reload();
    },
    [controller, reload]
  );

  const onSearch = useCallback(
    text => {
      dispatch({ type: OVERVIEW_ACTIONS.SET_SEARCH, collection: collection, search: text });
    },
    [collection, dispatch]
  );

  const onSort = useCallback(
    ({ field, type }) => {
      dispatch({ type: OVERVIEW_ACTIONS.SET_SORT, collection: collection, sort: { [field]: type } });
    },
    [collection, dispatch]
  );

  const onFilter = useCallback(
    filter => {
      dispatch({ type: OVERVIEW_ACTIONS.SET_FILTER, collection: collection, filter });
    },
    [collection, dispatch]
  );

  const onReset = useCallback(
    field => {
      const action = field === "sort" ? OVERVIEW_ACTIONS.SET_SORT : OVERVIEW_ACTIONS.SET_FILTER;
      dispatch({ type: action, collection: collection, [field]: {} });
    },
    [collection, dispatch]
  );

  useEffect(() => {
    dispatch({ type: OVERVIEW_ACTIONS.SET_LOADING, collection, loading: true });
  }, [collection, dispatch]);

  const onScroll = useCallback(
    event => {
      const target = event.target;
      if (
        (overviewData?.moreItemsExist || paginated) &&
        !overviewSetup.loading &&
        target.scrollHeight - target.scrollTop - target.clientHeight < 10
      ) {
        paginated ? fetchNextPaginatedPage() : fetchNextPage();
      }
    },
    [overviewData?.moreItemsExist, paginated, overviewSetup.loading, fetchNextPaginatedPage, fetchNextPage]
  );

  const debouncedOnScroll = useMemo(() => debounce(onScroll, 500), [onScroll]);

  const onDebouncedOnScrollCallback = useCallback(
    event => {
      if (!dragging) {
        event.persist();
        debouncedOnScroll(event);
      }
      setIsScroll(event.target.scrollTop !== 0);
    },
    [debouncedOnScroll, dragging]
  );

  const onAddCloseAndRemoveShowAdd = useCallback(() => {
    setShowAddEl(<></>);
    onAddClose?.();
  }, [onAddClose]);

  const onAddButtonClick = useCallback(
    async ({ action, placeholder, onBefore, onHandle, onAfter }) => {
      await onBefore?.();

      const addSingleWithValidateItem = action === OVERVIEW_ADD_TYPE.SINGLE && controller.validateItem;

      if (action === OVERVIEW_ADD_TYPE.MULTIPLE || addSingleWithValidateItem) {
        onAddOpen?.();
        setShowAddEl(
          <OverviewAdd
            placeholder={placeholder}
            onAdd={onAdd}
            onClose={onAddCloseAndRemoveShowAdd}
            onValidate={controller.validateItem}
          />
        );
      } else if (action === OVERVIEW_ADD_TYPE.CUSTOM) {
        onHandle?.();
      } else if (action === OVERVIEW_ADD_TYPE.CUSTOM_MULTIPLE) {
        onAddOpen?.();
        setShowAddEl(
          <OverviewAdd
            placeholder={placeholder}
            onAdd={onHandle}
            onClose={onAddCloseAndRemoveShowAdd}
            onValidate={controller.validateItem}
          />
        );
      } else if (action === OVERVIEW_ADD_TYPE.CUSTOM_SINGLE) {
        onHandle?.();
      } else if (action === OVERVIEW_ADD_TYPE.SINGLE && !controller.validateItem) {
        onAddAndGo?.();
      } else if (action === OVERVIEW_ADD_TYPE.SECTION) {
        setShowAddEl(
          <OverviewAdd
            placeholder={t("overview:new_section_placeholder")}
            onAdd={onAddSection}
            onClose={onAddCloseAndRemoveShowAdd}
          />
        );
      } else if (action === OVERVIEW_ADD_TYPE.TEMPLATE) {
        setShowTemplateDialog(true);
      }

      await onAfter?.();
    },
    [controller.validateItem, t, onAddOpen, onAdd, onAddCloseAndRemoveShowAdd, onAddAndGo, onAddSection]
  );

  const onOverviewRowClick = useCallback(
    async (item: OverviewItem) => {
      if (item && item.id) {
        controller.goToItem(item.id);
      }
      onRowClick?.(item);
      if (selectable) {
        dispatch({
          type: OVERVIEW_ACTIONS.SET_SELECTED_ID,
          collection,
          selectedId: item.id
        });
      }
      const haveUnseenBadge = (item.badges || (item.badge ? [item.badge] : [])).some(it => it.kind === "seen");
      if (haveUnseenBadge) {
        addToSeenItemsOfUserHook(collection, item.id).catch(err => {
          console.error(`Failed to set ${collection} with ${item.id} to seen.`, err);
        });
      }
    },
    [addToSeenItemsOfUserHook, collection, controller, dispatch, selectable, onRowClick]
  );

  /* CONTROLLER ACTIONS */

  /* DND */
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5
    }
  });
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);
  const onDragStart = useCallback((event: DragStartEvent) => {
    setDragItem(event?.active?.data?.current?.item || null);
    setDragging(true);
  }, []);
  const onDragEnd = useCallback(
    async (event: DragEndEvent) => {
      setDragging(false);
      setDragItem(null);
      const draggableItem = event?.active?.data?.current?.item;
      const droppableItem = event?.over?.data?.current?.item;
      if (draggableItem && droppableItem) {
        await controller.onDragEnd?.(draggableItem, droppableItem);
      }
    },
    [controller]
  );

  /* MOUSEOVER / MOUSELEAVE */

  const over = useCallback(
    item => {
      if (!dragging) onRowOver?.(item);
    },
    [dragging, onRowOver]
  );
  const debouncedOver = useMemo(() => debounce(over, 150), [over]);

  const leave = useCallback(() => {
    if (!dragging) {
      onRowLeave?.();
      debouncedOver.cancel();
    }
  }, [debouncedOver, dragging, onRowLeave]);

  /* MOUSEOVER / MOUSELEAVE */
  const toolbarEl = (() => {
    const ToolbarComponent = toolbarMode === "tabs" ? OverviewTabsToolbar : OverviewPagesToolbar;
    const toolbarProps: OverviewToolbarProps = {
      collection: collection,
      pages: pages,
      onPageChange: onPageChange,
      searchTerm: overviewSetup.search,
      filter: overviewSetup?.filter,
      sort: overviewSetup.sort,
      filters: overviewData?.filters || emptyArrayToAvoidRerender,
      sortings: overviewData?.sortings || emptyArrayToAvoidRerender,
      onAdd: onAddButtonClick,
      onSearch: onSearch,
      onSort: onSort,
      onFilter: onFilter,
      onReset: onReset,
      onResetChecked: onResetChecked,
      actions: toolbarActions,
      addActions: addActions,
      selectionActions: selectionActions,
      itemsCount: overviewData?.count,
      hideSearch,
      hideCount,
      checkedItems: checkedIds.map(id => controller.getById(id)).filter(notNull => notNull),
      onDuplicate: onDuplicateItems,
      onDelete: onDeleteItems,
      onExport: onExportItems,
      onExportAll: onExportAllItems,
      onMarkAllAsRead: onMarkAllAsRead
    };
    return (
      <Box pb={toolbarMode === "tabs" ? 0 : 4} pt={isScroll ? 1.5 : 2} pl={4} pr={4}>
        <ToolbarComponent {...toolbarProps} />
      </Box>
    );
  })();

  const getRowProps = (item: OverviewItem) =>
    ({
      actions: rowActions,
      activeRow,
      checkable,
      checkedItems: checkedIds.map(id => controller.getById(id)).filter(notNull => notNull),
      collection,
      dragItem,
      dynamicIndentation,
      item: item,
      rowComponent,
      CustomRowComponent,
      searchTerm: overviewSetup.search,
      setActiveRow,
      setItemToMove,
      setOpenMoveModal,
      translationDomainName,

      onAdd,
      onCheckRow,
      onClick: onOverviewRowClick,
      onDelete,
      onDragOverController: controller.onDragOver,
      onMouseEnter: debouncedOver,
      onPatch,
      onValidate: controller.validateItem
    }) satisfies OverviewRowProps;

  const listEl = (() => {
    if (overviewSetup.loading) {
      return <></>;
    } else {
      const itemsEl = (overviewData?.items || []).map((item, index) => (
        <OverviewRow {...getRowProps(item)} key={`${item.id || item.title}-${index}`} />
      ));
      if (dnd) {
        return (
          <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd} sensors={sensors}>
            {itemsEl}
          </DndContext>
        );
      } else return itemsEl;
    }
  })();

  const noEntriesEl = !hideNoEntries && !overviewData?.items?.length && !overviewSetup.loading && (
    <Box py={2} px={6} mx={6} mt={6} className={cls.noEntries}>
      {noEntriesText || t("no_entries_found")}
    </Box>
  );

  const fetchingEl = !overviewSetup.shadowLoading &&
    (overviewData?.moreItemsExist || overviewSetup.loading || overviewSetup.loadingMore) && (
      <Box my={4} justifyContent={"center"} display="flex">
        <CircularProgress />
      </Box>
    );

  const endOfListEl = !overviewSetup.loading &&
    !overviewData?.moreItemsExist &&
    overviewData &&
    overviewData?.items?.length > 0 && (
      <Box my={4} justifyContent={"center"} display="flex" className={cls.endOfList}>
        {t("end_of_list")}
      </Box>
    );

  const headerEl = header && (
    <>
      <AccountNotice mt={2} mb={2} mr={2} ml={2} />
      <Box pt={4} px={4} display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h1" className={`${cls.titleText} ${isScroll ? "scrolled" : ""} `}>
            {header}
          </Typography>
        </Box>
      </Box>
    </>
  );

  const childrenEl = children && <Box px={6}>{children}</Box>;
  const subHeaderEl = subHeader && (
    <Box px={4} pt={2}>
      {subHeader}
    </Box>
  );

  return (
    <Box
      className={`${cls.root} ${dragging ? "dragging" : ""}`}
      onScroll={onDebouncedOnScrollCallback}
      data-testid={`overview-root-${collection}`}
    >
      <Box className={`${cls.header} ${isScroll ? "scrolled" : ""}`}>
        {headerEl}
        {subHeaderEl}
        {toolbarEl}
      </Box>
      {showAddEl}
      <Box
        onMouseLeave={leave}
        mx={CustomRowComponent ? 0 : 2}
        mb={6}
        data-qa="overview_list"
        data-testid="overview-list"
      >
        {listEl}
        {noEntriesEl}
        {fetchingEl}
        {endOfListEl}
        {templateDialogEl}
      </Box>
      {childrenEl}
    </Box>
  );
};

Overview.defaultProps = {
  sectionActions: [],
  rowActions: [],
  addActions: [],
  collectionParams: undefined,
  onRowClick: undefined,
  pages: undefined,
  onPageChange: undefined,
  dnd: undefined
};

export default Overview;

const emptyArrayToAvoidRerender: string[] = [];
