import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GraphContainer } from "./GraphContainer";
import { getDataBreachStatistic, STATS_TYPE } from "../../handlers/dashboardHandler";
import useTheme from "@material-ui/core/styles/useTheme";
import { useBizChartModel } from "./bizchart";
import { useErrorSnackbar } from "../../../hook/errorSnackbar";
import { TimeAndCountChart } from "./TimeAndCountChart";

export const DataBreachChart = ({ timePeriod, orgUnitId, labelId }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation("dashboardPage");
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const { catchAsSnackbar } = useErrorSnackbar();
  const { convertStatsToBizChartModel } = useBizChartModel();
  useEffect(() => {
    const init = async () => {
      const dataBreachStats = await getDataBreachStatistic(timePeriod?.id, orgUnitId, labelId);
      const chartData = convertStatsToBizChartModel(
        dataBreachStats,
        timePeriod?.id === STATS_TYPE.ALL_TIME && {
          month: "long",
          year: "numeric"
        },
        i18n.language
      );
      setChartData(chartData);
      setLoading(false);
    };

    if (Object.values(STATS_TYPE).includes(timePeriod?.id) && orgUnitId) {
      init().catch(catchAsSnackbar("failed to load Data Breach statistic data"));
    }
  }, [timePeriod?.id, orgUnitId, convertStatsToBizChartModel, catchAsSnackbar, i18n.language, labelId]);

  const chartScale = useMemo(
    () => ({
      count: { min: 0 },
      type: {
        formatter: type => {
          switch (type) {
            case "deleted":
              return t("dashboardPage:deleted");
            case "reportable":
              return t("dashboardCharts:reportable");
            case "nonReportable":
              return t("dashboardCharts:nonReportable");
            case "noReportableStatus":
              return t("dashboardCharts:pendingByUs");
            default:
              return type;
          }
        }
      }
    }),
    [t]
  );

  const color = useMemo(
    () => [
      "type",
      type => {
        switch (type) {
          case "deleted":
            return theme.palette.grey[300];
          case "reportable":
            return theme.palette.red.red500;
          case "nonReportable":
            return theme.palette.blue[500];
          case "noReportableStatus":
          default:
            return theme.palette.yellow[500];
        }
      }
    ],
    [theme]
  );

  return (
    <GraphContainer title={t("dashboardCharts:dataBreaches_title")} loading={loading}>
      <TimeAndCountChart
        timePeriodId={timePeriod?.id}
        chartScale={chartScale}
        chartData={chartData}
        color={color}
        stackIfBarchart={true}
      />
    </GraphContainer>
  );
};
