import React, { useCallback, useEffect, useState } from "react";
import { Box, IconButton, makeStyles, TextField } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Done from "@material-ui/icons/Done";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
      backgroundColor: "#fff"
    }
  }
}));

export interface RenamerProps {
  readonly title: string;
  readonly onSave: (args: string) => void;
  readonly onCancel: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const OverviewRenamer = ({ title, onCancel, onSave }: RenamerProps) => {
  const cls = useStyles();
  const initialTitle = title;
  const [value, setValue] = useState(initialTitle);
  const [valid, setValid] = useState(initialTitle !== "");

  /* ACTIONS */
  const onKeyDownCallback = useCallback(
    event => {
      if (event.keyCode === 13) {
        if (title !== value) {
          onSave(value);
        } else {
          onCancel(event);
        }
      }
      event.stopPropagation();
    },
    [onCancel, onSave, title, value]
  );

  const onChangeCallback = useCallback(e => {
    setValue(e.target.value);
  }, []);

  const onSaveCallback = useCallback(() => {
    if (title !== value) {
      onSave(value);
    } else {
      onCancel();
    }
  }, [onCancel, onSave, title, value]);

  useEffect(() => {
    setValid(Boolean(value.trim()));
  }, [value]);

  return (
    <Box display="flex" flex={1} alignItems="center" className={cls.root}>
      <Box flex={1}>
        <TextField
          fullWidth
          id="overview-search-input"
          type="text"
          variant="outlined"
          onKeyDown={onKeyDownCallback}
          onChange={onChangeCallback}
          value={value}
        />
      </Box>
      <Box ml={2}>
        <IconButton disabled={!valid} color={valid ? "primary" : "inherit"} onClick={onSaveCallback}>
          <Done />
        </IconButton>
        <IconButton color="secondary" onClick={onCancel}>
          <Close />
        </IconButton>
      </Box>
    </Box>
  );
};

export default OverviewRenamer;
