import AccordionMultiField from "../AccordionMultiField/AccordionMultiField";
import Grid from "@material-ui/core/Grid";
import Question from "../Question/Question";
import CreateEditTomModal from "../../app/pages/toms/modal/CreateEditTomModal";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { useTom } from "../../app/contexts/tom-context";
import TextEditor from "../../app/pages/questionnaires/utils/TextEditor";
import MultiAutocomplete from "../MultiAutocomplete/MultiAutocomplete";
import groupBy from "lodash-es/groupBy";
import xorBy from "lodash-es/xorBy";
import { ResourceField } from "../ResourceField";
import { RESOURCE_TYPES } from "../../app/handlers/resourceHandler";
import { tDeletedEntry } from "../../app/handlers/dataTypeTranslatorHandler";
import { createNaturalSorter } from "../../app/utils/naturalSort";
import { useAuthentication } from "../../app/handlers/authentication/authentication-context";

const useStyles = makeStyles(theme => ({
  riskInput: {
    marginTop: "-25px"
  },
  accordion: {
    margin: "0px 0px 15px 0px",
    border: "1px solid " + theme.palette.grey[400],
    borderRadius: "5px"
  }
}));

export interface MeasureAccordionInput {
  readonly id?: string;
  readonly tomId?: string;
  readonly measureName?: string;
  readonly measureDescription?: string;
  readonly protectionObjective?: string[];
  readonly isCreatedNew?: boolean;
}

export interface MeasureAccordionProps {
  readonly inputMeasure: MeasureAccordionInput;
  readonly onSave: (measure: MeasureAccordionInput) => void;
  readonly onFocus?: (measure: MeasureAccordionInput) => void;
  readonly onDelete: (measure: MeasureAccordionInput) => void;
  readonly onCancel: (measure: MeasureAccordionInput) => void;
  readonly accordionStyle?: (tom: MeasureAccordionInput) => string;
  readonly autoSave: boolean;
  readonly optionalRiskIdForNewTOM?: string;
  readonly whitelistedTOMIds?: string[];
  readonly customMeasureGroupingFn?: (tomId: string) => string;
  readonly customMeasureGroupsOrder?: string[];
  readonly disabled: boolean;
}

export default function MeasureAccordion({
  inputMeasure,
  onSave,
  onDelete,
  onFocus,
  onCancel,
  accordionStyle,
  autoSave,
  optionalRiskIdForNewTOM,
  whitelistedTOMIds,
  customMeasureGroupingFn,
  customMeasureGroupsOrder,
  disabled
}: MeasureAccordionProps) {
  const classes = useStyles();
  const { t } = useTranslation("measure_accordion");
  const { toms, tomInitiated } = useTom();

  const [measure, setMeasure] = useState<MeasureAccordionInput>({});
  useEffect(() => {
    setMeasure({
      ...inputMeasure
    });
  }, [inputMeasure, tomInitiated]);
  const [measureId, setMeasureId] = useState("");
  const [modalOpen, setModalOpen] = useState<string>("");
  const [mode, setMode] = useState<"create" | "edit" | "">("create");
  const [multiAutoCompleteInput, setMultiAutoCompleteInput] = useState("");
  const onMultiAutoCompleteInputChange = useCallback((event, value) => {
    setMultiAutoCompleteInput(value);
  }, []);
  const [title, setTitle] = useState("");

  useEffect(() => {
    // if measure is not deleted
    if (measure.measureName) {
      setTitle(measure.measureName);
    } else {
      // if accordion is exist
      // but measure was deleted
      // return "Deleted" title
      if (measure.id && measure.tomId) {
        setTitle(tDeletedEntry({ t }));
      }
      // nothing to return
      // because new accordion
      else {
        return setTitle("");
      }
    }
  }, [measure, t]);

  useEffect(() => {
    if (title === tDeletedEntry({ t })) {
      setMultiAutoCompleteInput("");
      return;
    }
  }, [t, title]);

  const saveWithRemoveCreatedNew = useCallback(
    measureToSave => {
      const { isCreatedNew, ...measureWithoutCreatedNew } = measureToSave;
      setMeasure(measureWithoutCreatedNew);
      if (!onSave) {
        return;
      }
      onSave(measureToSave);
    },
    [onSave]
  );

  const modifyMeasure = useCallback(
    (measureId, accordionId) => {
      const tom = toms.find(tom => tom.id === measureId);
      const measurePayload = tom
        ? {
            tomId: tom.id,
            measureName: tom.name,
            measureDescription: tom.description || "",
            protectionObjective: tom.protectionObjectiveIds || []
          }
        : {
            tomId: "",
            measureName: "",
            measureDescription: "",
            protectionObjective: []
          };
      const updatedMeasure = {
        ...measurePayload,
        isCreatedNew: measure.isCreatedNew,
        id: accordionId
      };
      setMeasure(updatedMeasure);
      if (autoSave) {
        saveWithRemoveCreatedNew(updatedMeasure);
      }
    },
    [toms, autoSave, measure.isCreatedNew, saveWithRemoveCreatedNew]
  );

  const editMeasure = useCallback(() => {
    setMeasureId(measure.tomId || "");
    setMode("edit");
    setModalOpen(measure.measureName || "");
  }, [measure.tomId, measure.measureName]);

  const onFocusCallback = useCallback(() => {
    onFocus?.(measure);
  }, [onFocus, measure]);

  const accordionStyleResult = useMemo(() => {
    return accordionStyle?.(measure) || classes.accordion;
  }, [accordionStyle, measure, classes.accordion]);

  const onClickSave = useCallback(() => {
    return saveWithRemoveCreatedNew(measure);
  }, [measure, saveWithRemoveCreatedNew]);

  const onClickCancel = useCallback(() => {
    const originalMeasure = { ...inputMeasure };
    setMeasure(originalMeasure);
    onCancel(originalMeasure);
  }, [inputMeasure, onCancel]);

  const modalUpdateQuestionnaireMeasure = useCallback(
    createdTom => {
      const updatedMeasure = {
        ...measure,
        tomId: createdTom.tomId,
        measureName: createdTom.measureName ?? "",
        measureDescription: createdTom.measureDescription ?? "",
        protectionObjective: [...(createdTom.protectionObjective ?? [])]
      };
      setMeasure(updatedMeasure);
      if (autoSave) {
        saveWithRemoveCreatedNew(updatedMeasure);
      }
    },
    [measure, autoSave, saveWithRemoveCreatedNew]
  );

  const modalClose = useCallback(() => {
    setModalOpen("");
  }, []);

  const updateSelected = useCallback(
    tomId => {
      modifyMeasure(tomId, measure.id);
    },
    [modifyMeasure, measure.id]
  );

  const getTomName = useCallback(
    tomId => {
      const tom = toms.find(tom => tom.id === tomId);
      return tom?.name || tomId;
    },
    [toms]
  );

  const updateTomOption = useCallback(options => {
    const newlyAddedTomName = options[options.length - 1];
    setModalOpen(newlyAddedTomName);
    setMode("create");
  }, []);

  const [selectableTOMIds, setSelectableTOMIds] = useState<string[]>([]);
  useEffect(() => {
    const nonEmptyWhitelist = whitelistedTOMIds && Array.isArray(whitelistedTOMIds);
    const selectableTOMs = nonEmptyWhitelist ? toms.filter(tom => whitelistedTOMIds.includes(tom.id)) : toms;
    setSelectableTOMIds(selectableTOMs.map(tom => tom.id));
  }, [whitelistedTOMIds, toms]);

  const isTomIDNotWhitelisted = useCallback(
    (tomId: string) => {
      if (tomId.startsWith(t("tom_measures_overview:addText"))) {
        return false;
      }

      return !selectableTOMIds.includes(tomId);
    },
    [selectableTOMIds, t]
  );

  const tomGroupByCallback = useCallback(
    tomId => {
      if (customMeasureGroupingFn) {
        return customMeasureGroupingFn(tomId);
      }

      const tom = toms.find(tom => tom.id === tomId);
      if (!tom) {
        return "";
      }

      if (tom.processSpecific) {
        return t("tom_measures_overview:tab_process_specific");
      }

      return t("tom_measures_overview:tab_general");
    },
    [toms, t, customMeasureGroupingFn]
  );

  const [allTOMIds, setAllTOMIds] = useState<string[]>([]);
  useEffect(() => {
    const defaultGroups = [t("tom_measures_overview:tab_general"), t("tom_measures_overview:tab_process_specific")];
    const groupsOrder = customMeasureGroupsOrder || defaultGroups;

    const naturalSorter = createNaturalSorter();
    const sorter = (a: { readonly name?: string }, b: { readonly name?: string }) =>
      naturalSorter(a.name || "", b.name || "");

    const tomsGroupedByCategory = groupBy(toms, tom => tomGroupByCallback(tom.id));
    const sortedTOMsPerGroup = groupsOrder
      .map(group => tomsGroupedByCategory[group] || [])
      .map(groupedTOMs => {
        const selectableTOMs = groupedTOMs.filter(tom => !isTomIDNotWhitelisted(tom.id)).sort(sorter);
        const nonSelectableTOMs = groupedTOMs.filter(tom => isTomIDNotWhitelisted(tom.id)).sort(sorter);
        return [...selectableTOMs, ...nonSelectableTOMs];
      })
      .flatMap(groupedTOMs => groupedTOMs);

    const missingTOMs = xorBy(toms, sortedTOMsPerGroup, tom => tom.id);
    const allTOMs = [...sortedTOMsPerGroup, ...missingTOMs];

    setAllTOMIds(allTOMs.map(tom => tom.id));
  }, [toms, customMeasureGroupsOrder, tomGroupByCallback, t, isTomIDNotWhitelisted]);

  const alwaysNotDeletable = useCallback(() => {
    return true;
  }, []);

  const onDeleteCallback = useCallback(() => {
    onDelete(measure);
  }, [onDelete, measure]);

  const { auth } = useAuthentication();
  const userHasTomWritePermission = auth?.permissions?.some(
    permission => permission === "tom_write_all" || permission === "tom_write_org"
  );

  return (
    <AccordionMultiField
      id={"measure " + measure.id}
      getStyle={accordionStyleResult}
      index={measure.id}
      key={measure.id}
      onFocus={onFocusCallback}
      disableButton={disabled}
      isNewMultiFiled={measure.isCreatedNew}
      cancelButtonText={t("questionnaires:cancel")}
      saveButtonText={t("questionnaires:save")}
      deleteButtonText={t("questionnaires:delete")}
      deleteMultiField={onDeleteCallback}
      titleType={"text"}
      placeholder={t("dpia_four_four_page:add_mitigation_measures_placeholder")}
      title={title}
      onClickSave={onClickSave}
      onClickCancel={onClickCancel}
      hasCancelAndSave={!autoSave}
      editButtonText={t("dpia_four_four_page:edit_measure_button")}
      onClickEdit={measure.tomId ? editMeasure : null}
      field={undefined}
      deleteButtonHint={undefined}
      accordionsExpanded={undefined}
      setAccordionsExpanded={undefined}
      additionalLeftButton={undefined}
      loading={undefined}
    >
      <Grid className={classes.riskInput}>
        <Question>
          {modalOpen && (
            <CreateEditTomModal
              riskId={optionalRiskIdForNewTOM}
              updateQuestionnaireMeasure={modalUpdateQuestionnaireMeasure}
              measureName={modalOpen}
              measureData={measure}
              mode={mode}
              setMode={setMode}
              measureId={measureId}
              setMeasureId={setMeasureId}
              open={!!modalOpen}
              setOpen={modalClose}
            />
          )}
          <MultiAutocomplete
            label={t("dpia_four_four_page:measure_label")}
            hasMultiSelect={false}
            options={allTOMIds}
            isOptionDisabled={isTomIDNotWhitelisted}
            selected={measure.tomId || null}
            updateSelected={updateSelected}
            getOptionLabel={getTomName}
            placeholder={""}
            disabled={disabled}
            id={"measure"}
            updateOptions={updateTomOption}
            addText={userHasTomWritePermission ? t("tom_measures_overview:addText") : undefined}
            inputValue={multiAutoCompleteInput}
            onInputChange={onMultiAutoCompleteInputChange}
            groupBy={tomGroupByCallback}
          />
        </Question>
      </Grid>
      {measure.measureName && (
        <Grid>
          <Question>
            {/* key change (remounting) is required due to the TextEditor state management... */}
            <div key={measure.measureDescription}>
              <TextEditor
                testId={"paMeasureDescription"}
                title={t("dpia_four_four_page:measure_description")}
                inputValue={measure.measureDescription ?? ""}
                disabled={true}
              />
            </div>
          </Question>

          <Question>
            <Grid>
              <ResourceField
                disabled={true}
                id="protectionObjective"
                value={measure.protectionObjective ?? []}
                resourceType={RESOURCE_TYPES.PROTECTION_OBJECTIVE}
                label={t("dpia_four_four_page:protection_objective")}
                isNotDeletable={alwaysNotDeletable}
                onChange={doNothing}
              />
            </Grid>
          </Question>
        </Grid>
      )}
    </AccordionMultiField>
  );
}

const doNothing = () => {};
