import React, { useCallback } from "react";
import { Box, Fab, Tooltip, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";

export interface AuditAddQuestionButtonProps {
  readonly onClick: () => void;
}

export const AuditAddQuestionButton = ({ onClick }: AuditAddQuestionButtonProps) => {
  const { t } = useTranslation("audit_details");
  const onClickCallback = useCallback(
    event => {
      event.stopPropagation();
      onClick();
    },
    [onClick]
  );

  return (
    <Box textAlign="center" my={4}>
      <Typography>{t("add_new_question_message")}</Typography>
      <Box mt={1} />
      <Tooltip title={t("add_new_question_tooltip")} aria-label="add">
        <Fab color="primary" onClick={onClickCallback}>
          <AddIcon fontSize="large" />
        </Fab>
      </Tooltip>
    </Box>
  );
};
