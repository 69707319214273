import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { useMetaView } from "app/contexts/meta-view-context";
import LinkIcon from "@material-ui/icons/Link";
import OrgUnitPickerModal from "app/pages/shared/OrgUnitPickerModal/OrgUnitPickerModal";
import isEqual from "lodash-es/isEqual";
import { getAsset, updateAsset } from "../handler/assetHandler";
import { AssetDTO } from "../types/AssetTypes";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import DataLocationsPickerModal from "app/pages/datalocations/DataLocationsPickerModal";
import DateDisplay from "components/DateDisplay";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";

const AssetsOverview = () => {
  const { t } = useTranslation();
  const { setInfo, setMeta } = useMetaView();
  const [asset, setAsset] = useState<AssetDTO | null>();
  const [orgPickerOpen, setOrgPickerOpen] = useState<boolean>(false);
  const [dataLocationsPickerOpen, setDataLocationsPickerOpen] = useState<boolean>(false);

  const dispatch = useOverviewDispatch();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("assets_overview:enteringInfoCardTitle"),
        text: t("assets_overview:enteringInfoCardText")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);

  const showEnterInfo = useCallback(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);
  const toolbarActions = useMemo(
    () => [
      { action: "sort" },
      { action: "filter" },
      { action: "add", types: [OVERVIEW_ADD_TYPE.SINGLE, OVERVIEW_ADD_TYPE.MULTIPLE] },
      { action: "mark-all-as-read" }
    ],
    []
  );
  const onRowOver = useCallback(
    item => {
      setMeta({
        department: item.subTitle,
        created: <DateDisplay timestamp={new Date(item.createdAt)} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={item.creatorUID} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updatedBy ? <AutomaticUserDataDisplay uid={item.updatedBy} separator={undefined} /> : "-",
        status: t("assets_overview:" + item.status.toLowerCase())
      });
    },
    [setMeta, t]
  );
  const addActions = useMemo(() => [{ action: OVERVIEW_ADD_TYPE.SINGLE }, { action: OVERVIEW_ADD_TYPE.MULTIPLE }], []);

  const onOrgUnitPickerClose = useCallback(() => setOrgPickerOpen(false), []);
  const onOrgUnitPickerOpen = useCallback(async id => {
    const data = await getAsset(id);
    setAsset(data);
    setOrgPickerOpen(true);
  }, []);

  const onDataLocationsPickerClose = useCallback(() => setDataLocationsPickerOpen(false), []);
  const onDataLocationsPickerOpen = useCallback(async id => {
    const data = await getAsset(id);
    setAsset(data);
    setDataLocationsPickerOpen(true);
  }, []);

  const onOrgUnitPickeChange = useCallback(
    async updatedOrgUnits => {
      if (isEqual(asset?.orgUnitIds, updatedOrgUnits)) {
        setAsset(null);
      } else if (asset?.id) {
        await updateAsset(asset?.id, { orgUnitIds: updatedOrgUnits });
        dispatch({
          type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
          collection: COLLECTIONS.ASSETS,
          reloadOverview: Date.now()
        });
      }
    },
    [asset?.id, asset?.orgUnitIds, dispatch]
  );

  const onDataLocationChange = useCallback(
    async dataLocationIds => {
      onDataLocationsPickerClose();
      if (isEqual(asset?.dataLocationIds, dataLocationIds)) {
        setAsset(null);
      } else if (asset?.id) {
        await updateAsset(asset?.id, { dataLocationIds });
        dispatch({
          type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
          collection: COLLECTIONS.ASSETS,
          reloadOverview: Date.now()
        });
      }
    },
    [asset?.dataLocationIds, asset?.id, dispatch, onDataLocationsPickerClose]
  );

  const rowActions = useMemo(
    () => [
      { action: "edit" },
      { action: "remove" },
      {
        action: "link",
        title: t("common:link"),
        icon: <LinkIcon />,
        onHandle: () => {
          // do
        },
        childrens: [
          {
            title: t("assets_overview:linkDataLocationsText"),
            onHandle: onDataLocationsPickerOpen
          },
          {
            title: t("assets_overview:linkOrganizationText"),
            onHandle: onOrgUnitPickerOpen
          }
        ]
      }
    ],
    [onDataLocationsPickerOpen, onOrgUnitPickerOpen, t]
  );

  const docViewContentEl = (
    <Overview
      collection={COLLECTIONS.ASSETS}
      onAddClose={showEnterInfo}
      onRowLeave={showEnterInfo}
      onRowOver={onRowOver}
      rowActions={rowActions}
      toolbarActions={toolbarActions}
      addActions={addActions}
      header={t("assets_overview:header")}
      checkable={true}
      toolbarMode="tabs"
    >
      <OrgUnitPickerModal
        onConfirm={onOrgUnitPickeChange}
        selectedDepartmentIds={asset?.orgUnitIds || []}
        onClose={onOrgUnitPickerClose}
        open={orgPickerOpen}
      />
      <DataLocationsPickerModal
        open={dataLocationsPickerOpen}
        label={t("assets_overview:please_select_data_locations")}
        selectedIDs={asset?.dataLocationIds || []}
        onClose={onDataLocationsPickerClose}
        onChange={onDataLocationChange}
      />
    </Overview>
  );
  return (
    <DocMetaView docViewContent={docViewContentEl} metaViewContent={<MetaView translationKey={"assets_overview"} />} />
  );
};

export default React.memo(AssetsOverview);
