import React from "react";
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { RenderIconButton, RenderPlainIcon } from "components/RenderIcon/RenderIcon";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  secondaryIcon: {
    margnRight: "0px"
  }
}));

export default function RowItem({
  primaryIcon,
  primaryIconTooltip,
  onPrimaryIconClick,
  actionIcons,
  primaryText,
  secondaryText,
  onClick,
  primaryTypographyProps,
  secondaryTypographyProps,
  button
}) {
  const classes = useStyles();
  return (
    <ListItem button={button} onClick={onClick} ContainerComponent="div">
      {primaryIcon && (
        <ListItemIcon>
          {onPrimaryIconClick && (
            <RenderIconButton
              icon={primaryIcon}
              iconSize={"small"}
              callBackFunction={() => onPrimaryIconClick()}
              toolTipTitle={primaryIconTooltip}
            />
          )}
          {!onPrimaryIconClick && <RenderPlainIcon icon={primaryIcon} iconSize={"small"} />}
        </ListItemIcon>
      )}
      <ListItemText
        primary={primaryText}
        secondary={secondaryText}
        primaryTypographyProps={{
          ...primaryTypographyProps,
          noWrap: true
        }}
        secondaryTypographyProps={{
          ...secondaryTypographyProps,
          noWrap: true
        }}
      />
      <ListItemSecondaryAction>
        {actionIcons &&
          actionIcons.map((icon, index) => (
            <>
              {icon.onClick && (
                <RenderIconButton
                  key={"actionIcon_" + icon.icon + "_" + index}
                  icon={icon.icon}
                  iconType={"secondary"}
                  iconSize={"small"}
                  callBackFunction={() => icon.onClick()}
                  additionalClasses={classes}
                  toolTipTitle={icon.tooltip}
                />
              )}
              {!icon.onClick && <RenderPlainIcon icon={icon.icon} iconSize={"small"} />}
            </>
          ))}
      </ListItemSecondaryAction>
    </ListItem>
  );
}

RowItem.propTypes = {
  primaryIcon: PropTypes.string,
  primaryIconTooltip: PropTypes.string,
  onPrimaryIconClick: PropTypes.func,
  actionIcons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      tooltip: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  onClick: PropTypes.func,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  classes: PropTypes.object,
  primaryTypographyProps: PropTypes.object,
  secondaryTypographyProps: PropTypes.object,
  button: PropTypes.bool
};

RowItem.defaultProps = {
  button: false,
  classes: {},
  primaryTypographyProps: {},
  primaryIconTooltip: "",
  secondaryTypographyProps: {},
  actionIcons: []
};
