import { apiEndpoints } from "../apiEndpoint";
import { isAxiosErrorWithCode } from "../axios/axiosErrorHandler";
import { defaultOTCAuthenticatedAxios } from "../axios/loggedInAxiosProvider";

export interface FileResponse {
  readonly fileName: string;
  readonly updated: string;
  readonly uploaderUID: string;
  readonly size: number;
}

export interface GetFilesResponse {
  readonly files: FileResponse[];
}

const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.fileStorageRequestUrl}/api/v1/attachments`
});

export const getFileAsBlob = async (input: {
  readonly fileName: string;
  readonly folderName: string;
}): Promise<Blob | null> => {
  const { fileName, folderName } = input;
  try {
    const response = await axiosInstance.get(`/${folderName}/${encodeURIComponent(fileName)}`, {
      responseType: "blob",
      timeout: 0 // no timeout to accomodate large files
    });
    return new Blob([response.data]);
  } catch (error: any) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

export const getAllAttachmentsApi = async (input: {
  readonly folderName: string;
  readonly withPrefix?: boolean;
}): Promise<
  {
    readonly name: FileResponse["fileName"];
    readonly timeCreated: FileResponse["updated"];
    readonly uploadedBy: FileResponse["uploaderUID"];
  }[]
> => {
  const { withPrefix, folderName } = input;
  let response;
  if (withPrefix) {
    response = await axiosInstance.get<GetFilesResponse>(`/${folderName}`, {
      params: { withPrefix: "true" }
    });
  } else {
    response = await axiosInstance.get<GetFilesResponse>(`/${folderName}`);
  }
  return (response.data?.files || []).map(file => {
    const toReturn = {
      name: file.fileName,
      timeCreated: file.updated,
      uploadedBy: file.uploaderUID
    };
    return {
      ...toReturn
    };
  });
};

export const downloadAttachmentApi = async (input: {
  readonly fileName: string;
  readonly folderName: string;
}): Promise<void> => {
  const { folderName, fileName } = input;
  const blob = await getFileAsBlob(input);
  if (!blob) {
    throw new Error(`File not found: ${folderName}/${fileName}`);
  }
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  const name = fileName;
  link.setAttribute("download", name as string);
  document.body.appendChild(link);
  link.click();
};

export const uploadAttachmentApi = async (input: {
  readonly file: string | Blob;
  readonly folderName: string;
  readonly path?: string;
}) => {
  const { folderName, file, path } = input;
  const formData = new FormData();
  formData.append("formFile", file);

  return await axiosInstance.post(
    `/${folderName}/upload/${path && path.length > 0 && encodeURIComponent(path)}`,
    formData,
    {
      timeout: 0, // no timeout to accomodate large files
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
};

export const deleteAttachmentApi = async (input: {
  readonly fileName: string;
  readonly folderName: string;
}): Promise<void> => {
  await axiosInstance.delete(`/${input.folderName}/${encodeURIComponent(input.fileName)}`);
};

export interface AsZipFileDTO {
  readonly folders: {
    readonly tenantFolderName: string;
    readonly inZipFolderName?: string;
    readonly files?: {
      readonly fileName: string;
      readonly inZipFileName?: string;
    }[];
  }[];
}

export const getAttachmentsAsZipApi = async (input: { readonly zipSpec: AsZipFileDTO }) => {
  return await axiosInstance.post("/zips", input.zipSpec, {
    responseType: "blob",
    timeout: 0 // no timeout to accomodate large files
  });
};
