import { apiEndpoints } from "app/api/apiEndpoint";
import { AssetCreateDTO, AssetUpdateDTO } from "../pages/assets/types/AssetTypes";
import { AssetFilterProps } from "../pages/assets/handler/assetHandler";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.assetUrl}/api/v1/assets`
});

export async function createApi(payload: AssetCreateDTO) {
  const response = await axiosInstance.post("/", payload);
  return response.headers["x-resource-id"] || "";
}

export async function getApi(id?: string | null, filter?: AssetFilterProps) {
  const response = await (id
    ? axiosInstance.get(`/${id}`)
    : axiosInstance.get("", {
        params: { ...(filter || {}) }
      }));
  return response ? response.data : null;
}

export async function updateApi(id: string, payload: AssetUpdateDTO) {
  const response = await axiosInstance.patch("/" + id, payload);
  return response ? response.data : null;
}

export async function deleteApi(id: string) {
  return await axiosInstance.delete("/" + id);
}

export async function getProcesses(id: string) {
  return await axiosInstance.get(`/${id}/processes`);
}

export async function getNumberUnseenAssetsApi(
  httpOptions: {
    abortController?: AbortController;
  } = {}
) {
  const response = await axiosInstance.get(`/unseen`, { signal: httpOptions.abortController?.signal });
  return response?.data?.unseenCount || 0;
}
