import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import ViewListIcon from "@material-ui/icons/ViewList";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import WebAssetIcon from "@material-ui/icons/WebAsset";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import SecurityIcon from "@material-ui/icons/Security";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import RiskIcon from "@material-ui/icons/Speed";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { HasFeatureToggleOn, HasPageAccess, ShowPageIf } from "../../../router/router-filters";
import Divider from "@material-ui/core/Divider";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import WebIcon from "@material-ui/icons/Web";
import CastForEducationIcon from "@material-ui/icons/CastForEducation";
import FolderIcon from "@material-ui/icons/Folder";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { FEATURES, USER_FEATURE_IDS } from "app/features";
import { useNavigate } from "react-router-dom";
import { usePathName } from "../../../router/router-custom-hooks";
import Logo from "./Logo";
import TenantSidebar from "./TenantSidebar";
import UserAvatarSidebar from "./UserAvatarSidebar";
import Administration from "./Administration";
import Help from "./Help";
import Language from "./Language";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { COLLECTIONS } from "app/collections";
import { useDataTypeTree } from "app/api/dataAssetApi";
import { useSidebarUnseen } from "./useSidebarUnseen";
import { useAvailableLanguages } from "../../../../hook/useAvailableLanguages";

import PrivacyTipIconGrey from "./../../../../assets/images/icons/privacyTipGrey.svg";
import PrivacyTipIconBlue from "./../../../../assets/images/icons/privacyTipBlue.svg";
import TaskIconGrey from "../../../../../src/assets/images/icons/taskGrey.svg";
import TaskIconBlue from "../../../../../src/assets/images/icons/taskBlue.svg";
import { sidebarZIndex } from "./sidebarZIndex";

const drawerWidthClosed = 70;
const drawerWidth = 300;
const BoxShadow = "0px 0px 40px 0px rgb(204 204 204 / 50%)";
const BorderRadius = "5px";

const useStyles = makeStyles((theme: any) => ({
  listItemTranslationMenu: {
    marginBottom: 35
  },
  drawer: {
    position: "fixed",
    overflow: "unset",
    height: "calc(100% - 48px)",
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: sidebarZIndex
  },
  drawerPaper: {
    top: "24px",
    boxShadow: BoxShadow,
    borderRadius: BorderRadius,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    position: "fixed",
    height: "calc(100% - 48px)",
    width: "900px"
  },
  drawerOpen: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    width: drawerWidth,
    overflowY: "auto",
    overflowX: "hidden"
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: drawerWidthClosed,
    overflow: "hidden"
  },
  list: {
    marginTop: "20px"
  },
  listItemSubItem: {
    paddingLeft: "78px",
    fontSize: "11px",
    "& div": {
      padding: "6px 8px 6px",
      cursor: "pointer"
    },
    "& div:hover": {
      background: theme.palette.grey[100]
    }
  },
  activeItem: {
    backgroundColor: theme.palette.primary.light
  },
  activeIcon: {
    color: theme.palette.primary.main,
    marginLeft: 6,
    "& .MuiBadge-colorError": {
      color: "#ffffff",
      border: "2px solid",
      fontSize: "11px",
      padding: "4px 6px",
      textAlign: "center",
      height: "auto",
      borderRadius: "16px"
    }
  },
  icon: {
    marginLeft: 6,
    "& .MuiBadge-colorError": {
      color: "#ffffff",
      border: "2px solid",
      fontSize: "11px",
      padding: "4px 6px",
      textAlign: "center",
      height: "auto",
      borderRadius: "16px"
    }
  }
}));

export default function SidebarWrapper() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Drawer
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
      variant="permanent"
      classes={{
        root: clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        }),
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
    >
      <Sidebar sidebarOpen={open} onSidebarClose={handleDrawerClose} onSidebarOpen={handleDrawerOpen} />
    </Drawer>
  );
}

const Sidebar = function ({
  sidebarOpen,
  onSidebarOpen,
  onSidebarClose
}: {
  readonly sidebarOpen: boolean;
  readonly onSidebarOpen: () => void;
  readonly onSidebarClose: () => void;
}) {
  const classes = useStyles();
  const { auth, user } = useAuthentication();
  const { tenantData } = useUserAndTenantData();
  const { t, i18n } = useTranslation("sidebar");
  const { availableLanguages, isLoadedFromTenantData: availableLanguagesLoadedFromTenant } = useAvailableLanguages();
  const [lng, setLng] = useState("");

  const { sidebarNewItems } = useSidebarUnseen();

  const path = usePathName();

  // set language to previously selected or default value
  useEffect(() => {
    if (!tenantData?.features) {
      return;
    }
    if (!availableLanguagesLoadedFromTenant) {
      return;
    }

    const currentLanguage = i18n.language;
    if (!availableLanguages.includes(currentLanguage)) {
      const fallbackLanguage = tenantData.companyLanguage || "en";
      setLng(fallbackLanguage);
      i18n.changeLanguage(fallbackLanguage);
      return;
    }

    setLng(currentLanguage);
  }, [availableLanguagesLoadedFromTenant, tenantData?.features, availableLanguages, i18n, tenantData?.companyLanguage]);

  const onSubSideBarClicked = useCallback(() => {
    onSidebarClose?.();
  }, [onSidebarClose]);

  const userWithoutDSRPageAccess = useCallback(pageAccess => {
    return !pageAccess.includes("data_subject_requests");
  }, []);

  const onShowTrainingClick = useCallback(() => {
    window.open("https://caralegal.lawpilots.com/de/KQZ6-Z86A/");
  }, []);

  const { unseenCount } = useDataTypeTree();
  return (
    <List className={classes.list}>
      <Logo open={sidebarOpen} tenantId={auth?.tenantId || ""} tenantName={tenantData?.name || ""} />
      <TenantSidebar open={sidebarOpen} />
      <UserAvatarSidebar userData={user} />
      <HasPageAccess requiredPageAccess={["dashboard"]} returnNothing={true}>
        <NavigateItem targetPath={"/dashboard"} label={t("dashboard")}>
          <DashboardIcon />
        </NavigateItem>
      </HasPageAccess>
      <NavigateItem targetPath={"/tasks/my"} label={t("tasks")} badgeContent={sidebarNewItems[COLLECTIONS.TASKS]}>
        {path.startsWith("/task") ? <TaskIconBlue /> : <TaskIconGrey />}
      </NavigateItem>
      <NavigateItem
        targetPath={"/notifications"}
        label={t("notifications")}
        badgeContent={sidebarNewItems[COLLECTIONS.NOTIFICATIONS]}
      >
        <NotificationsIcon />
      </NavigateItem>
      <HasPageAccess requiredPageAccess={["processes"]} returnNothing={true}>
        <NavigateItem targetPath={"/processes"} label={t("process_overview")}>
          <InsertDriveFileIcon />
        </NavigateItem>
      </HasPageAccess>

      <HasPageAccess requiredPageAccess={["impact_assessment"]} returnNothing={true}>
        <NavigateItem targetPath={"/dpias"} label={t("risk_assessment")}>
          <VerifiedUserIcon />
        </NavigateItem>
      </HasPageAccess>
      <HasFeatureToggleOn feature={FEATURES.MEASURES_FEATURE} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["tom"]} returnNothing={true}>
          <NavigateItem targetPath={"/toms/general"} label={t("data_security")}>
            <SecurityIcon />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.RISKS} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["risks"]} returnNothing={true}>
          <NavigateItem targetPath={"/risks/general"} label={t("risks")}>
            <RiskIcon />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.AUDIT} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["audits"]} returnNothing={true}>
          <NavigateItem targetPath={"/audits/instances"} label={t("audits")}>
            <AssignmentTurnedInIcon />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.DATA_BREACHES} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["data_breaches"]} returnNothing={true}>
          <NavigateItem targetPath={"/data-breaches"} label={t("data_breaches")}>
            {path.startsWith("/data-breaches") ? <PrivacyTipIconBlue /> : <PrivacyTipIconGrey />}
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.DATA_SUBJECT_REQUEST} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["data_subject_requests"]} returnNothing={true}>
          <NavigateItem targetPath={"/data-subject-requests"} label={t("requests")}>
            <EmojiPeopleIcon />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.DSR_BASIC_SUBMISSION} navigateAway={false}>
        <ShowPageIf pageAccessCondition={userWithoutDSRPageAccess}>
          <NavigateItem targetPath={"/data-subject-requests-submission"} label={t("requests_submission")}>
            <EmojiPeopleIcon />
          </NavigateItem>
        </ShowPageIf>
      </HasFeatureToggleOn>
      <HasPageAccess requiredPageAccess={["resources"]} returnNothing={true}>
        <NavigateItem
          targetPath={"/resources"}
          label={t("resources")}
          badgeContent={
            [COLLECTIONS.RESOURCES, COLLECTIONS.DATA_LOCATIONS].reduce(
              (count, sidebarItemName) => count + sidebarNewItems[sidebarItemName] || 0,
              0
            ) + unseenCount
          }
        >
          <ViewListIcon />
        </NavigateItem>
      </HasPageAccess>
      <HasFeatureToggleOn feature={FEATURES.DELETION_CONCEPT} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["deletion_concept"]} returnNothing={true}>
          <NavigateItem targetPath={"/deletion-concept"} label={t("deletion_concept")}>
            <DeleteSweepIcon />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>

      <HasFeatureToggleOn feature={FEATURES.ASSETS} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["assets"]} returnNothing={true}>
          <NavigateItem
            targetPath={"/asset-management"}
            label={t("assets_overview:header")}
            badgeContent={sidebarNewItems[COLLECTIONS.ASSETS]}
          >
            <WebAssetIcon />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasPageAccess requiredPageAccess={["service_providers"]} returnNothing={true}>
        <NavigateItem
          targetPath={"/external-recipients"}
          label={t("service_providers")}
          badgeContent={sidebarNewItems[COLLECTIONS.EXTERNAL_RECIPIENTS]}
        >
          <HomeWorkIcon />
        </NavigateItem>
      </HasPageAccess>
      <HasFeatureToggleOn feature={USER_FEATURE_IDS.WEBSITES} navigateAway={false}>
        <NavigateItem targetPath={"/websites"} label={t("websites")}>
          <WebIcon />
        </NavigateItem>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.LAWPILOT_TRAINING} navigateAway={false}>
        <ListItem className={classes.icon} button onClick={onShowTrainingClick}>
          <ListItemIcon>
            <CastForEducationIcon />
          </ListItemIcon>
          <ListItemText primary={t("training")} />
        </ListItem>
      </HasFeatureToggleOn>
      <HasPageAccess requiredPageAccess={["document_center"]} returnNothing={true}>
        <NavigateItem targetPath={"/document-center"} label={t("documents")}>
          <FolderIcon />
        </NavigateItem>
      </HasPageAccess>
      <Divider />
      <Administration sidebarState={sidebarOpen} classes={classes} onClicked={onSubSideBarClicked} />
      <Help sidebarOpen={sidebarOpen} classes={classes} language={lng} onClicked={onSubSideBarClicked} />
      <Divider />
      <Language language={lng} sidebarState={sidebarOpen} onClicked={onSubSideBarClicked} />
    </List>
  );
};

const NavigateItem = ({
  targetPath,
  label,
  badgeContent,
  children
}: {
  readonly targetPath: string;
  readonly label: string;
  readonly badgeContent?: number;
  readonly children: React.ReactNode;
}) => {
  const classes = useStyles();
  const path = usePathName();
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    navigate(targetPath);
  }, [navigate, targetPath]);

  const isOnPage = useMemo(() => {
    const getFirstPath = (input: string) => input.split("/").filter(it => it)[0];
    return getFirstPath(path) === getFirstPath(targetPath);
  }, [path, targetPath]);

  return (
    <ListItem button onClick={onClick} className={isOnPage ? classes.activeItem : ""}>
      <ListItemIcon className={isOnPage ? classes.activeIcon : classes.icon}>
        <Badge badgeContent={badgeContent} color="error" overlap="rectangular">
          {children}
        </Badge>
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
};
