import React, { useCallback, useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SidebarCaralegalLogo from "./../../../../assets/images/diamond.svg?img";
import SidebarCarlegalLogoWithText from "./../../../../assets/images/logo.svg?img";
import { Box, Tooltip } from "@material-ui/core";
import { getFileAsBlob } from "app/api/file-storage/attachmentsApi";

const useStyles = makeStyles((theme: any) => ({
  root: {
    overflow: "hidden"
  },
  customLogo: {
    height: 58,
    width: 58,
    objectFit: "cover",
    marginLeft: 6,
    borderRadius: 8,
    userSelect: "none"
  },
  logo: {
    height: 58,
    width: 270,
    marginLeft: 6,
    backgroundColor: theme.palette.logo.logo500,
    borderRadius: 8,
    userSelect: "none"
  },
  logoCollapsed: {
    height: 58,
    width: 58,
    marginLeft: 6,
    backgroundColor: theme.palette.logo.logo500,
    borderRadius: 8,
    userSelect: "none"
  },
  poweredBy: {
    fontSize: 10,
    marginTop: 18,
    marginLeft: 6,
    display: "block",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main
    }
  },
  tenantDetails: {
    position: "absolute",
    marginLeft: 70,
    width: "calc(100% - 80px)"
  },
  tenantName: {
    fontSize: 18,
    marginLeft: 6,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));
const TENANT_LOGO_NAME = "logo.png";

export const LogoSidebar = ({
  open,
  tenantId,
  tenantName
}: {
  readonly open: boolean;
  readonly tenantId: string;
  readonly tenantName: string;
}) => {
  const { t } = useTranslation("sidebar");
  const navigate = useNavigate();
  const classes = useStyles();
  const [logoLoad, setLogoLoad] = useState(false);
  const [customLogoContent, setCustomLogoContent] = useState<string | null>();

  useEffect(() => {
    if (logoLoad) {
      return;
    }
    // just have tenant id so that it reloads
    if (!tenantId) {
      return;
    }

    getFileAsBlob({ folderName: "brand", fileName: TENANT_LOGO_NAME }).then(logo => {
      if (!logo) {
        // just means there is no custom logo
        setCustomLogoContent(null);
        setLogoLoad(true);
        return;
      }

      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(logo);
      setCustomLogoContent(imageUrl);
      setLogoLoad(true);
    });
  }, [tenantId, logoLoad]);

  const onClickCallback = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <Box className={classes.root} display="flex">
      <Box>
        {customLogoContent && (
          <img src={customLogoContent} alt={"Logo"} className={classes.customLogo} onClick={onClickCallback} />
        )}
        {!customLogoContent && open && (
          <SidebarCarlegalLogoWithText className={classes.logo} onClick={onClickCallback} />
        )}
        {!customLogoContent && !open && (
          <SidebarCaralegalLogo className={classes.logoCollapsed} onClick={onClickCallback} />
        )}
      </Box>
      {customLogoContent && (
        <Box className={classes.tenantDetails}>
          <Tooltip title={tenantName}>
            <div className={classes.tenantName}>{tenantName}</div>
          </Tooltip>
          <Link
            underline="none"
            color="inherit"
            className={classes.poweredBy}
            target="blank"
            href="https://caralegal.eu"
          >
            {`${t("powered_by")} caralegal`}
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default LogoSidebar;
