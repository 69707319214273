import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import LanguageIcon from "@material-ui/icons/Language";
import { useAvailableLanguages } from "../../../../hook/useAvailableLanguages";
import { sidebarZIndex } from "./sidebarZIndex";

const useStyles = makeStyles(() => ({
  listItemTranslationMenu: {
    marginBottom: 35,
    position: "relative"
  },
  icon: {
    marginLeft: 6
  }
}));

export const LanguageSideBar = ({ language, sidebarState, onClicked }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation("sidebar");
  const { availableLanguages } = useAvailableLanguages();

  // change language if changed by user in dropdown
  const changeLanguage = useCallback(
    async lang => {
      await i18n.changeLanguage(lang);
      onClicked?.();
    },
    [i18n, onClicked]
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(event => {
    // seems like event object is re-used by browser, so we need to extract it before we pass it to set state
    // since we use function setter in set state, it can run later, and by then the browser probably have re-used
    // the event object, and changed the current target to something else it wanted
    const currentTarget = event.currentTarget;
    setAnchorEl(anchorEl => (anchorEl ? null : currentTarget));
  }, []);

  useEffect(() => {
    setAnchorEl(null);
  }, [sidebarState, language]);

  const languageOptions = useMemo(
    () =>
      availableLanguages
        .map(l => [
          t(l),
          <MenuItem key={l} data-testid={`button-translate-to-${l}`} onClick={() => changeLanguage(l)}>
            {t(l)}
          </MenuItem>
        ])
        .sort(([translatedLang1], [translatedLang2]) => translatedLang1.localeCompare(translatedLang2))
        .map(([, menuItem]) => menuItem),
    [t, changeLanguage, availableLanguages]
  );

  const clickAwayCallback = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <ListItem data-testid="translate-button" className={classes.listItemTranslationMenu} button onClick={handleClick}>
        <ListItemIcon className={classes.icon}>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText primary={t("lng")} />
        <ArrowRightIcon />
      </ListItem>
      <Popper open={anchorEl !== null} anchorEl={anchorEl} placement="right-end" style={{ zIndex: sidebarZIndex + 1 }}>
        <ClickAwayListener onClickAway={clickAwayCallback}>
          <Paper>
            <MenuList id="composition-menu" aria-labelledby="composition-button">
              {languageOptions}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default LanguageSideBar;
