import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DataLocationsPicker } from "./DataLocationsPicker";
import { useTranslation } from "react-i18next";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";

export default function DataLocationsPickerModal({
  open,
  onChange,
  selectedIDs,
  label,
  onClose
}: {
  readonly open: boolean;
  readonly selectedIDs: string[];
  readonly label?: string;
  readonly onChange: (dlIDs: string[]) => void;
  readonly onClose: () => void;
}) {
  const { t } = useTranslation();
  const [selectedDataLocationIds, setSelectedDataLocationIds] = useState<string[]>([]);
  const onSave = useCallback(() => onChange(selectedDataLocationIds), [onChange, selectedDataLocationIds]);
  useEffect(() => {
    setSelectedDataLocationIds(selectedIDs);
  }, [selectedIDs]);
  const onChangeSelection = useCallback(ids => {
    setSelectedDataLocationIds(ids);
  }, []);
  const modalBodyEl = useMemo(
    () => <DataLocationsPicker onChange={onChangeSelection} selectedIDs={selectedDataLocationIds} />,
    [onChangeSelection, selectedDataLocationIds]
  );
  const buttons: ConfirmationModalButtonProps[] = [
    {
      confirmButton: false,
      title: t("common:cancel"),
      variant: "outlined",
      color: "primary",
      size: "medium",
      onClick: onClose
    },
    {
      confirmButton: true,
      title: t("service_providers_overview:save"),
      variant: "contained",
      color: "primary",
      size: "medium",
      onClick: onSave
    }
  ];
  return (
    <ConfirmationModal
      modalOpen={open}
      onClose={onClose}
      modalTitle={t("service_providers_overview:link_tooltip_activate_service_provider")}
      modalText={label || ""}
      buttons={buttons}
      modalBody={modalBodyEl}
      variant={"info"}
    />
  );
}
