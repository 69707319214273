import React, { useCallback, useEffect, useState } from "react";
import { isUserDepartmentBound } from "../../../../handlers/permissionHandler";
import MultiAutocomplete, { MultiAutocompleteProps } from "components/MultiAutocomplete/MultiAutocomplete";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { useUserDepartments } from "../../../../contexts/department-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";

import AssignNewIcon from "assets/images/icons/assignedTo.svg";
import { ASSIGNABLE_USER_FIELD, UserDTO } from "../../../../api/user/userApi";
import { expandOrgUnitIds } from "../../../../handlers/orgUnitHandler";
import { Department } from "../../../../handlers/departmentHandler";

AssignUsersMultiAutocomplete.defaultProps = {
  docOrgUnitIds: [],
  onFocus: () => {},
  onBlur: () => {},
  freeSolo: false,
  disableClearable: false,
  disabled: false,
  hasMultiSelect: false,
  excludedUserIds: [],
  hasDefaultValue: false
};

export interface AssignUsersMultiAutocompleteProps {
  readonly docOrgUnitIds: string[];
  readonly docAssignedUserIds: string[];
  readonly onDocAssignedUserIdsChanged: (docAssignedUserIds: string[]) => void;
  readonly id: string;
  readonly onFocus: MultiAutocompleteProps<string>["onFocus"];
  readonly onBlur: MultiAutocompleteProps<string>["onBlur"];
  readonly freeSolo: boolean;
  readonly disableClearable: boolean;
  readonly label: string;
  readonly disabled: boolean;
  readonly hasMultiSelect: boolean;
  readonly excludedUserIds: string[];
  readonly icon?: React.ReactNode;
  readonly hasDefaultValue: boolean;
  readonly error?: boolean;
  readonly helperText?: string;
  readonly assignType?: ASSIGNABLE_USER_FIELD;
}

export function AssignUsersMultiAutocomplete({
  docOrgUnitIds,
  docAssignedUserIds,
  onDocAssignedUserIdsChanged,
  id,
  onFocus,
  onBlur,
  freeSolo,
  disableClearable,
  label,
  disabled,
  hasMultiSelect,
  excludedUserIds,
  icon,
  hasDefaultValue,
  error,
  helperText,
  assignType
}: AssignUsersMultiAutocompleteProps) {
  const { getUserNameEmailHook, getUsersByAssignableFieldHook, loading } = useUserAndTenantData();
  const { departments, departmentsLoaded } = useUserDepartments();
  const { auth } = useAuthentication();

  const [assignableUsersLoaded, setAssignableAssignableUsersLoaded] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [formattedSelectableUsers, setFormattedSelectableUsers] = useState<UserDTO[]>([]);
  const [multiAutoCompleteSelected, setMultiAutoCompleteSelected] = useState<string | string[] | null>([]);
  const [assignableUsers, setAssignableUsers] = useState<UserDTO[]>([]);

  const getUsersByAssignableField = useCallback(async () => {
    setAssignableUsers(await getUsersByAssignableFieldHook(assignType));
    setAssignableAssignableUsersLoaded(true);
  }, [assignType, getUsersByAssignableFieldHook]);

  useEffect(() => {
    if (!assignableUsersLoaded && !loading) {
      getUsersByAssignableField();
    }
  }, [getUsersByAssignableField, loading, assignableUsersLoaded]);

  useEffect(() => {
    if (!auth?.role || !auth?.orgUnitId || !auth?.uid || !assignableUsersLoaded) {
      return;
    }
    let relevantUsers = assignableUsers || [];

    const loggedInUserDepartmentBound = isUserDepartmentBound({ role: auth.role || "" });
    if (loggedInUserDepartmentBound) {
      if (!departmentsLoaded) {
        return;
      }

      const parentIdToChildren = departments.reduce<Map<string, Department[]>>((acc, next) => {
        const parentId = next.parentId || "";
        const currentValue = acc.get(parentId) || [];
        currentValue.push(next);
        acc.set(parentId, currentValue);
        return acc;
      }, new Map());

      const relevantOrgUnitIds = [
        ...expandOrgUnitIds(
          docOrgUnitIds.length === 0 ? [auth.orgUnitId, ...(auth.furtherOrgUnitIds || [])] : docOrgUnitIds,
          parentIdToChildren
        )
      ];

      relevantUsers = relevantUsers.filter(tenantUser => {
        const tenantUserOrgUnitIds: string[] = [tenantUser.orgUnitId, ...(tenantUser?.furtherOrgUnitIds || [])].filter(
          (it): it is string => !!it
        );
        const tenantUserSelectableInFollowingOrgUnitIds = new Set(
          [...tenantUserOrgUnitIds, ...(tenantUser?.exposedOrgUnitIds || [])].filter(
            (it): it is string => !!it && it !== "*"
          )
        );
        const exposedOrgUnitsHaveWildcard = !!tenantUser.exposedOrgUnitIds?.includes("*");
        if (exposedOrgUnitsHaveWildcard) {
          const expandedOrgUnitIds = expandOrgUnitIds(tenantUserOrgUnitIds, parentIdToChildren);
          for (const expandedOrgUnitId of expandedOrgUnitIds) {
            tenantUserSelectableInFollowingOrgUnitIds.add(expandedOrgUnitId);
          }
        }

        return relevantOrgUnitIds.some(orgUnitId => tenantUserSelectableInFollowingOrgUnitIds.has(orgUnitId));
      });
    }

    const usersWithoutExcludedUsers = relevantUsers
      .filter((user): user is UserDTO & { readonly id: string } => !!user.id)
      .filter(user => !excludedUserIds.includes(user.id));
    setFormattedSelectableUsers(usersWithoutExcludedUsers);

    let selectedUserIds = docAssignedUserIds || [];
    if (!disabled && hasDefaultValue && !selectedUserIds[0] && !(selectedUserIds.length > 1)) {
      // default value if empty
      selectedUserIds = usersWithoutExcludedUsers
        .filter(user => user.id === auth.uid)
        .map(user => user.id)
        .filter((it): it is string => !!it);
      if (selectedUserIds.length >= 1) {
        onDocAssignedUserIdsChanged(selectedUserIds);
      }
    }
    setSelectedUserIds(selectedUserIds);
  }, [
    docOrgUnitIds,
    docAssignedUserIds,
    excludedUserIds,
    departments,
    departmentsLoaded,
    onDocAssignedUserIdsChanged,
    getUserNameEmailHook,
    auth?.orgUnitId,
    auth?.role,
    auth?.uid,
    hasDefaultValue,
    auth?.furtherOrgUnitIds,
    disabled,
    assignableUsers,
    assignableUsersLoaded
  ]);

  const updateSelected = useCallback(
    selectedValueSingleOrArray => {
      const selectedValues = Array.isArray(selectedValueSingleOrArray)
        ? selectedValueSingleOrArray
        : [selectedValueSingleOrArray];
      const usersSelected = formattedSelectableUsers.filter(user => selectedValues.includes(user.id));
      onDocAssignedUserIdsChanged(usersSelected.map(user => user.id).filter((it): it is string => !!it));
    },
    [onDocAssignedUserIdsChanged, formattedSelectableUsers]
  );

  const [options, setOptions] = useState<string[]>([]);
  useEffect(() => {
    setOptions(formattedSelectableUsers.map(user => user.id).filter((it): it is string => !!it));
  }, [formattedSelectableUsers]);

  useEffect(() => {
    const derivedMultiAutoCompleteSelected = hasMultiSelect ? selectedUserIds : selectedUserIds[0] || null;
    setMultiAutoCompleteSelected(derivedMultiAutoCompleteSelected);
  }, [selectedUserIds, setMultiAutoCompleteSelected, hasMultiSelect]);
  console.log(id);
  console.log(multiAutoCompleteSelected);

  return assignableUsersLoaded ? (
    <MultiAutocomplete
      icon={icon || <AssignNewIcon />}
      id={id}
      onFocus={onFocus}
      onBlur={onBlur}
      selected={multiAutoCompleteSelected}
      updateSelected={updateSelected}
      freeSolo={freeSolo}
      disableClearable={disableClearable}
      options={options}
      getOptionLabel={getUserNameEmailHook}
      disabled={disabled}
      hasMultiSelect={hasMultiSelect}
      label={label}
      error={error}
      helperText={helperText}
    />
  ) : (
    <></>
  );
}

export default AssignUsersMultiAutocomplete;
