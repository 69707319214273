import { createTheme as createMUI4Theme } from "@material-ui/core/styles";
import { createTheme as createMUI5Theme } from "@mui/material/styles";

import palette from "./palette/palette";
import typography from "./typography/typography";
import overrides from "./overrides/overrides";
import shape from "./shapes/shape";
import colors from "./palette/colors";
import breakpoints from "./breakpoints/breakpoints";

declare module "@material-ui/core/styles" {
  interface Theme {
    colors: typeof colors;
  }

  interface ThemeOptions {
    colors: typeof colors;
  }
}

export default createMUI4Theme({
  colors,
  palette,
  typography,
  overrides,
  breakpoints,
  shape
});

export const mui5Theme = createMUI5Theme({
  palette,
  typography,
  breakpoints,
  shape,
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: `${colors.grey.grey500} !important` // still doesn't work somehow :(
        }
      }
    }
  }
});
