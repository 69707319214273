import React, { useCallback, useEffect } from "react";
import { usePathName } from "../../router/router-custom-hooks";
import GeneralPageButtonPagination from "../../../components/Pagination/GeneralPageButtonPagination";
import { Box, Tooltip } from "@material-ui/core";
import { QuestionnaireStepModel, useQuestionnairePaginationSteps } from "./useQuestionnairePaginationSteps";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { changeLastUrlPath } from "../../../components/Pagination/url-handler";
import { useSnackbar } from "notistack";
import { DefaultApi, PagePermissionDTOActionsInner } from "../../api/generated/process-service";
import Button from "@material-ui/core/Button";
import { apiEndpoints } from "../../api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../../api/axios/loggedInAxiosProvider";
import { useApprovalMissingFields, useSubmissionMissingFields } from "../../api/process/submissionAndApprovalApi";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";

export function QuestionnairePageButtons({
  onSubmission,
  disabled
}: {
  readonly onSubmission: () => void;
  readonly disabled: boolean;
}) {
  const { availableSteps, currentStep } = useQuestionnairePaginationSteps();
  const haveActions = !!currentStep?.permissionDTO.actions?.length;

  return (
    <Box mt={4}>
      {haveActions && <QuestionnaireMissingFields />}
      <GeneralPageButtonPagination
        steps={availableSteps}
        rightArea={
          haveActions ? (
            <QuestionnaireActionButtons
              onSubmission={onSubmission}
              disabled={disabled}
              actions={currentStep?.permissionDTO.actions || []}
            />
          ) : (
            <></>
          )
        }
        leftArea={haveActions && <></>}
      />
    </Box>
  );
}

function QuestionnaireMissingFields() {
  const { id } = useParams();
  const { t } = useTranslation();

  const { currentStep } = useQuestionnairePaginationSteps();
  const haveSubmissionAction = !!currentStep?.permissionDTO?.actions?.some(it => it.action === "submitForReview");
  const haveApprovalAction = !!currentStep?.permissionDTO?.actions?.some(it => it.action === "approve");

  const { data: submissionMissingFields } = useSubmissionMissingFields({
    documentId: (haveSubmissionAction && id) || ""
  });
  const { data: approvalMissingFields } = useApprovalMissingFields({ documentId: (haveApprovalAction && id) || "" });

  if (!submissionMissingFields?.missingFields?.length && !approvalMissingFields?.missingFields?.length) {
    return <></>;
  }

  return (
    <Box mt={1.5} mb={6}>
      <CustomAlert severity="warning">
        {t("questionnaires:errorMessagesHeading")}
        <ul>
          {submissionMissingFields?.missingFields?.map(sectionErrorKey => (
            <li key={`submission-${sectionErrorKey}`}>{t(sectionErrorKey)}</li>
          ))}
          {approvalMissingFields?.missingFields?.map(sectionErrorKey => (
            <li key={`approval-${sectionErrorKey}`}>{t(sectionErrorKey)}</li>
          ))}
        </ul>
      </CustomAlert>
    </Box>
  );
}

function QuestionnaireActionButtons({
  onSubmission,
  disabled
}: {
  readonly onSubmission: () => void;
  readonly disabled: boolean;
  readonly actions: PagePermissionDTOActionsInner[];
}) {
  const { currentStep } = useQuestionnairePaginationSteps();
  const haveActions = !!currentStep?.permissionDTO.actions?.length;
  return (
    <Box display="flex" gridGap={8}>
      {haveActions &&
        currentStep?.permissionDTO.actions?.map((actionData, index) => (
          <Box key={`submission-button-${index}`}>
            <SubmissionButton actionData={actionData} disabled={disabled} onSubmission={onSubmission} />
          </Box>
        ))}
    </Box>
  );
}

const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());

interface SubmissionButtonProps<T extends string> {
  readonly onSubmission: (input?: { readonly skipSuccessScreen: true }) => void;
  readonly disabled: boolean;
  readonly actionData: {
    readonly action: T;
    readonly disabledReason: string | null;
  };
}

function SubmissionButton({ onSubmission, disabled, actionData }: SubmissionButtonProps<string>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anyActionData = actionData as any;
  if (actionData.action === "setToEdit") {
    return <QuestionnaireSetToEdit onSubmission={onSubmission} disabled={disabled} actionData={anyActionData} />;
  }
  if (actionData.action === "requestEdit") {
    return <QuestionnaireRequestEdit onSubmission={onSubmission} disabled={disabled} actionData={anyActionData} />;
  }
  if (actionData.action === "approve") {
    return <QuestionnaireApprove onSubmission={onSubmission} disabled={disabled} actionData={anyActionData} />;
  }
  if (actionData.action === "submitForReview") {
    return <QuestionnaireSubmitForReview onSubmission={onSubmission} disabled={disabled} actionData={anyActionData} />;
  }
  return <></>;
}

function BasicSubmissionButton({
  onClick,
  disableTooltip,
  label,
  disabled
}: {
  readonly onClick: () => void;
  readonly disableTooltip: string;
  readonly label: string;
  readonly disabled: boolean;
}) {
  return (
    <Tooltip title={disableTooltip}>
      <span>
        <Button disabled={disabled || !!disableTooltip} variant="contained" color="primary" onClick={onClick}>
          {label}
        </Button>
      </span>
    </Tooltip>
  );
}

function QuestionnaireSetToEdit({ onSubmission, disabled, actionData }: SubmissionButtonProps<"setToEdit">) {
  const { id } = useParams();
  const { t } = useTranslation("pagination");
  const { enqueueSnackbar } = useSnackbar();

  const onClickCallback = useCallback(async () => {
    await processClient.editProcess(id || "");
    enqueueSnackbar(t("setBackToEditSuccessMessage"), { variant: "success" });
    onSubmission({ skipSuccessScreen: true });
  }, [id, enqueueSnackbar, t, onSubmission]);

  return (
    <BasicSubmissionButton
      onClick={onClickCallback}
      disableTooltip={actionData.disabledReason ? t(actionData.disabledReason) : ""}
      label={t("setBackToEdit")}
      disabled={disabled}
    />
  );
}

function QuestionnaireRequestEdit({ onSubmission, disabled, actionData }: SubmissionButtonProps<"requestEdit">) {
  const { id } = useParams();
  const { t } = useTranslation("pagination");
  const { enqueueSnackbar } = useSnackbar();

  const onClickCallback = useCallback(async () => {
    await processClient.requestEditProcess(id || "");
    enqueueSnackbar(t("requestEditSuccessMessage"), { variant: "success" });
    onSubmission({ skipSuccessScreen: true });
  }, [id, enqueueSnackbar, t, onSubmission]);

  return (
    <BasicSubmissionButton
      onClick={onClickCallback}
      disableTooltip={actionData.disabledReason ? t(actionData.disabledReason) : ""}
      label={t("requestEdit")}
      disabled={disabled}
    />
  );
}

function QuestionnaireApprove({ onSubmission, disabled, actionData }: SubmissionButtonProps<"approve">) {
  const { id } = useParams();
  const { t } = useTranslation("pagination");

  const { data } = useApprovalMissingFields({ documentId: id || "" });

  const onClickCallback = useCallback(async () => {
    await processClient.approveProcess(id || "");
    return onSubmission();
  }, [id, onSubmission]);

  return (
    <BasicSubmissionButton
      onClick={onClickCallback}
      disableTooltip={actionData.disabledReason ? t(actionData.disabledReason) : ""}
      label={t("finish")}
      disabled={disabled || !!data?.missingFields?.length}
    />
  );
}

function QuestionnaireSubmitForReview({
  onSubmission,
  disabled,
  actionData
}: SubmissionButtonProps<"submitForReview">) {
  const { id } = useParams();
  const { t } = useTranslation("pagination");

  const { data } = useSubmissionMissingFields({ documentId: id || "" });

  const onClickCallback = useCallback(async () => {
    await processClient.submitProcess(id || "");
    return onSubmission();
  }, [id, onSubmission]);

  return (
    <BasicSubmissionButton
      onClick={onClickCallback}
      disableTooltip={actionData.disabledReason ? t(actionData.disabledReason) : ""}
      label={t("send")}
      disabled={disabled || !!data?.missingFields?.length}
    />
  );
}

export function QuestionnaireAutoNavigateWhenAvailableStepMissing() {
  const { t } = useTranslation();
  const { availableSteps, stepsInitialized } = useQuestionnairePaginationSteps();
  const path = usePathName();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!Array.isArray(availableSteps) || !availableSteps.length || !path || !stepsInitialized) {
      return;
    }

    const lastPath: string | undefined = path.split("/").pop();
    if (lastPath) {
      const lastPathIsNumber = isFinite(parseInt(lastPath));
      const step: QuestionnaireStepModel | undefined = lastPathIsNumber
        ? availableSteps[parseInt(lastPath) - 1]
        : availableSteps.find(step => step.path === lastPath);
      if (!step) {
        navigate(changeLastUrlPath(path, availableSteps[0].path));
      }
    }
  }, [availableSteps, path, navigate, enqueueSnackbar, t, stepsInitialized]);

  return <></>;
}
