import DateFnsUtils from "@date-io/date-fns";
import {
  BaseDatePickerProps,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import enLocale from "date-fns/locale/en-GB";
import { toJSDateObjectIfFirebaseDate } from "app/handlers/utility/date-helper";
import { KeyboardDateInputProps } from "@material-ui/pickers/_shared/KeyboardDateInput";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DateValidationProps } from "@material-ui/pickers/_helpers/text-field-helper";
import { Box } from "@material-ui/core";
import deLocale from "date-fns/locale/de";
import frLocale from "date-fns/locale/fr";
import { useTranslation } from "react-i18next";

export interface DateFieldProps
  extends Pick<
      KeyboardDateInputProps,
      "className" | "onFocus" | "onBlur" | "label" | "disabled" | "focused" | "color" | "margin" | "InputProps"
    >,
    Pick<BaseDatePickerProps, "disableFuture" | "disablePast">,
    Pick<DateValidationProps, "minDateMessage" | "maxDateMessage"> {
  readonly date?: Date | null;
  readonly onDateChange: (date: Date | null) => void;
  readonly locale?: string;
  readonly minDate?: Date | number | string;
  readonly maxDate?: Date | number | string;
  readonly withTime?: boolean;
  readonly placeholder?: string | null;
}

export const DateField = ({
  className,
  onFocus,
  onBlur,
  date,
  onDateChange,
  locale,
  label,
  InputProps,
  margin,
  focused,
  color,
  disabled,
  disableFuture,
  disablePast,
  minDate,
  maxDate,
  minDateMessage,
  maxDateMessage,
  withTime,
  placeholder
}: DateFieldProps) => {
  const { t, i18n } = useTranslation("common");
  const [internalDateValue, setInternalDateValue] = useState<Date | null>(null);
  const resetDate = useCallback(() => {
    setInternalDateValue(toJSDateObjectIfFirebaseDate(date) || null);
  }, [date]);
  useEffect(() => {
    resetDate();
  }, [resetDate]);

  // mui date field now field is fixed, so we need this to make sure we can check past correctly later on
  const componentNow = useMemo(() => new Date(), []);

  const isDateValid = useCallback(
    (date: Date | null): boolean => {
      if (!date) {
        return false;
      }

      const dateValueEpochMs = new Date(date).getTime();
      if (date && isNaN(dateValueEpochMs)) {
        return false;
      }

      const nowEpochMs = componentNow.getTime();
      if (disablePast && dateValueEpochMs < nowEpochMs) {
        return false;
      }
      if (minDate && dateValueEpochMs < new Date(minDate).getTime()) {
        return false;
      }
      if (disableFuture && dateValueEpochMs > nowEpochMs) {
        return false;
      }
      if (maxDate && dateValueEpochMs > new Date(maxDate).getTime()) {
        return false;
      }

      return true;
    },
    [disablePast, disableFuture, minDate, maxDate, componentNow]
  );

  const onBlurCallback = useCallback(
    event => {
      if (!isDateValid(internalDateValue)) {
        resetDate();
      }
      return onBlur?.(event);
    },
    [onBlur, isDateValid, internalDateValue, resetDate]
  );

  const onChangeCallback = useCallback(
    (date: MaterialUiPickersDate | null, value?: string | null): void => {
      setInternalDateValue(date || null);
      if (date === null || isDateValid(date)) {
        onDateChange?.(date);
      }
    },
    [onDateChange, isDateValid]
  );

  const keyboardIcon = useMemo(() => {
    return (
      <Box color={disabled ? undefined : "primary.main"} data-testid={`${label}-calendarIcon`}>
        <EventIcon />
      </Box>
    );
  }, [disabled, label]);

  const format = withTime ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy";
  const Component = withTime ? KeyboardDateTimePicker : KeyboardDatePicker;

  return (
    <MuiPickersUtilsProvider locale={getLocale(locale || i18n?.language || "")} utils={DateFnsUtils}>
      <Component
        data-testid={`${label}-picker`}
        className={className}
        disablePast={disablePast}
        disableFuture={disableFuture}
        minDate={minDate}
        maxDate={maxDate}
        onFocus={onFocus}
        fullWidth
        disableToolbar={!withTime} // for date only picker, no toolbar
        variant="dialog"
        inputVariant="outlined"
        format={format}
        placeholder={placeholder || format}
        margin={margin || "normal"}
        value={internalDateValue}
        onChange={onChangeCallback}
        onBlur={onBlurCallback}
        keyboardIcon={keyboardIcon}
        label={label}
        minDateMessage={minDateMessage}
        maxDateMessage={maxDateMessage}
        disabled={disabled}
        InputProps={InputProps}
        clearable={true}
        focused={focused}
        color={color}
        cancelLabel={t("cancel")}
        clearLabel={t("clear")}
        okLabel={t("ok")}
      />
    </MuiPickersUtilsProvider>
  );
};

DateField.defaultProps = {
  minDate: new Date(Date.UTC(0, 0, 0, 0, 0, 0, 0)),
  maxDate: new Date(8640000000000000)
};

export const getLocale = (language: string): Locale => {
  switch (language.toLowerCase()) {
    case "en":
      return enLocale;

    case "de":
      return deLocale;

    case "fr":
      return frLocale;

    default:
      return enLocale;
  }
};
