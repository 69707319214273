import { TypographyOptions, TypographyStyleOptions } from "@material-ui/core/styles/createTypography";
import palette from "theme/palette/palette";
import { openSansFont, sanchezFont } from "../fonts/fonts";

const semiBoldFontWeight = 700;

// Sanchez font for Headlines (H1)
const h1: TypographyStyleOptions = {
  ...sanchezFont,
  fontSize: "30px",
  color: palette.text?.primary
};

// OpenSanz font for Headlines (H2)
const h2: TypographyStyleOptions = {
  ...openSansFont,
  fontSize: "20px",
  fontWeight: semiBoldFontWeight,
  color: palette.text?.primary
};

// OpenSanz font for Headlines (H3)
const h3: TypographyStyleOptions = {
  ...openSansFont,
  fontSize: "22px",
  color: palette.text?.primary
};

// OpenSanz font for Headlines (H4)
const h4: TypographyStyleOptions = {
  ...openSansFont,
  fontSize: "16px",
  color: palette.text?.primary
};

// OpenSanz font for Headlines (H5)
const h5: TypographyStyleOptions = {
  ...openSansFont,
  fontSize: "14px",
  fontWeight: semiBoldFontWeight,
  color: palette.text?.primary
};

// OpenSanz font for Headlines (H6)
const h6: TypographyStyleOptions = {
  ...openSansFont,
  fontSize: "14px",
  color: palette.text?.primary
};

// OpenSans font for subtitle1 text
const subtitle1: TypographyStyleOptions = {
  ...openSansFont,
  fontSize: "14px",
  color: palette.text?.primary
};

// OpenSans font for subtitle2 text
const subtitle2: TypographyStyleOptions = {
  ...openSansFont,
  fontSize: "16px",
  fontWeight: semiBoldFontWeight,
  color: palette.text?.primary
};

// OpenSans font for body text
const body1: TypographyStyleOptions = {
  ...openSansFont,
  fontSize: "14px",
  color: palette.text?.primary
};

// OpenSans font for body text
const body2: TypographyStyleOptions = {
  ...openSansFont,
  fontSize: "14px",
  color: palette.text?.primary
};

const typography: TypographyOptions = {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  body1,
  body2,
  subtitle1,
  subtitle2
};

export default typography;
