const colors = {
  white: "#FFFFFF",
  black: "#000000",
  grey: {
    grey50: "#FAFAFA",
    grey100: "#F5F5F5",
    grey200: "#EEEEEE",
    grey300: "#E0E0E0",
    grey400: "#BDBDBD",
    grey500: "#9E9E9E",
    grey600: "#757575",
    grey700: "#616161",
    grey800: "#424242",
    grey900: "#1C242D"
  },
  blue: {
    blue50: "#EBF1FF",
    blue100: "#D6E1FF",
    blue200: "#ACC3FF",
    blue300: "#83A6FF",
    blue400: "#5988FF",
    blue500: "#306AFF",
    blue600: "#2450BF",
    blue700: "#183580"
  },
  red: {
    red50: "#FDE9E9",
    red100: "#FAD3D2",
    red200: "#F5A6A5",
    red300: "#F17A79",
    red400: "#EC4D4C",
    red500: "#E7211F",
    red600: "#AD1917",
    red700: "#AD1917"
  },
  yellow: {
    yellow50: "#FFF8EA",
    yellow100: "#FFF0D4",
    yellow200: "#FFE1A9",
    yellow300: "#FFD27F",
    yellow400: "##FFC354",
    yellow500: "#DB9A23",
    yellow600: "#BF871F",
    yellow700: "#805A15"
  },
  green: {
    green50: "#E6F4EB",
    green100: "#CCE7D5",
    green200: "#99D0AC",
    green300: "#67B882",
    green400: "#34A159",
    green500: "#01892F",
    green600: "#016723",
    green700: "#014518"
  },
  orange: {
    orange50: "#FFEFEB",
    orange100: "#FFDED7",
    orange200: "#FFBDAE",
    orange300: "#FF9D86",
    orange400: "#FF7C5D",
    orange500: "#FF5B35",
    orange600: "#BF4428",
    orange700: "#802E1B"
  },
  lila: {
    lila50: "#F6EDFE",
    lila100: "#ECDAFD",
    lila200: "#D9B4FA",
    lila300: "#C78FF8",
    lila400: "#B469F5",
    lila500: "#A144F3",
    lila600: "#7933B6",
    lila700: "#51227A"
  }
};

export default colors;
