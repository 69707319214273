import { getTextFromTextEditorJsonString } from "../../questionnaires/utils/textEditorConverter";
import { jsonToXlsxHandler } from "../../../handlers/dataToXlsxHandler";
import { getDataSubjectRequest } from "../../../handlers/dataSubjectRequestHandler";
import { getDocumentTitle } from "../../../utils/get-title-for-exported-document";
import { translateKeys } from "../../../utils/translate-keys";
import { getUserName } from "app/utils/get-user-name";
import { getAllTasksFromForCollection } from "app/handlers/tasksHandler";
import { toJSDateObjectIfISO8601 } from "../../../handlers/utility/date-helper";
import { getUsers } from "../../../handlers/userAndTenant/userHandler";
import { getTenantInformation } from "../../../handlers/tenantHandler";
import { getAllResources, RESOURCE_TYPES, translateResourceById } from "../../../handlers/resourceHandler";
import { getOrgUnits } from "../../../handlers/departmentHandler";
import { chunk } from "lodash-es";

const objectKeysToTranslationKeyMapping = {
  requestTitle: "request_title",
  requestId: "request_id",
  requestType: "request_type",
  requestDescription: "description",
  affectedOrgUnit: "org_unit",
  receivedOn: "received_on",
  dueDate: "due_date",
  assignedTo: "assignedTo",
  createdBy: "createdBy",
  dsrCorrectlyVerified: "hasVerified",
  taskTitle: "task_title",
  status: "status",
  firstName: "firstName",
  lastName: "lastName"
};

function getColumnDataAsJson(dsrs, t, i18n, users, orgUnits, tasks, resources) {
  if (!dsrs || !dsrs.length) {
    return "";
  }
  let rows = [];
  const rowsFirstTab = getColumnDataGeneralInformation(dsrs, t, i18n, users, orgUnits, resources);
  const rowsSecondTab = getColumnDataTaskTab(dsrs, t, i18n, users, tasks);
  rows.push(rowsFirstTab);
  rows.push(rowsSecondTab);
  return rows;
}

function getColumnDataTaskTab(dsrs, t, i18n, users, tasks) {
  return dsrs
    .map(dsr => {
      return tasks
        .filter(task => task.documentId === dsr.id)
        .map(task => {
          const tasksOfDSR = {
            requestId: dsr?.inputData?.id,
            taskTitle: task.title || "",
            assignedTo: getUserName(users, task.assigneeUID) || "",
            dueDate: task.dueAt ? new Date(task.dueAt) : "",
            status: task.status ? t("tasks_page:" + task.status) : ""
          };
          const dataWithTranslatedKeys = translateKeys(
            tasksOfDSR,
            "dsrExportData",
            i18n,
            t,
            objectKeysToTranslationKeyMapping
          );
          return dataWithTranslatedKeys;
        });
    })
    .flat();
}

function getColumnDataGeneralInformation(dsrs, t, i18n, users, orgUnits, resources) {
  return dsrs.map(dsr => {
    const description = getTextFromTextEditorJsonString(dsr?.inputData?.description);
    const isDeleted = dsr?.inputData?.orgUnitId
      ? orgUnits.find(orgUnit => orgUnit.id === dsr?.inputData?.orgUnitId && orgUnit.deleted === true)
      : false;
    const orgUnit = dsr?.inputData?.orgUnitId
      ? isDeleted
        ? t("lists_data_types_categories_person_groups:deletedEntry")
        : orgUnits.find(orgUnit => orgUnit.id === dsr?.inputData?.orgUnitId)?.name
      : "";
    const data = {
      requestTitle: dsr?.inputData?.title,
      requestId: dsr?.inputData?.id,
      requestType: dsr?.inputData?.type
        ? dsr.inputData.type
            .map(type =>
              translateResourceById({
                t,
                resourceType: RESOURCE_TYPES.DSR_REQUEST_TYPE,
                resourceId: type,
                resources
              })
            )
            .join(", ")
        : "",
      requestDescription: description || "",
      affectedOrgUnit: orgUnit,
      receivedOn: toJSDateObjectIfISO8601(dsr?.inputData?.receivedOn)?.toLocaleDateString() || null,
      dueDate: toJSDateObjectIfISO8601(dsr?.inputData?.dueDate)?.toLocaleDateString() || null,
      assignedTo: dsr?.inputData?.assignedTo ? getUserName(users, dsr.inputData.assignedTo) : "",
      createdBy: dsr?.createdBy ? getUserName(users, dsr.createdBy) : "",
      dsrCorrectlyVerified: dsr?.inputData?.isDataSubjectVerified
        ? t("common:" + dsr.inputData.isDataSubjectVerified)
        : "",
      firstName: dsr?.inputData?.firstName || "",
      lastName: dsr?.inputData?.lastName || ""
    };
    const dataWithTranslatedKeys = translateKeys(data, "dsrExportData", i18n, t, objectKeysToTranslationKeyMapping);
    return dataWithTranslatedKeys;
  });
}

export async function exportDataSubjectRequest(tenantId, t, i18n, dsrIds) {
  //get necessary data from db
  const [tenantData, users, orgUnits, tasks, resources] = await Promise.all([
    getTenantInformation(tenantId),
    getUsers(),
    getOrgUnits(),
    getAllTasksFromForCollection({ collectionId: "dataSubjectRequests" }),
    getAllResources({ showAllSetsIgnoringTenantFeatures: true })
  ]);

  //dsrId is passed if a single dsr is exported otherwise all dsrs are exported
  const dsrsToExport = [];
  for (const dsrIdsChunk of chunk(dsrIds || [], 10)) {
    const dsrs = await Promise.all(dsrIdsChunk.map(getDataSubjectRequest));
    dsrsToExport.push(...dsrs);
  }

  //generate document content
  const [dsrRows, dsrTaskRows] = getColumnDataAsJson(dsrsToExport, t, i18n, users, orgUnits, tasks, resources);
  jsonToXlsxHandler(getDocumentTitle(tenantData.name, t("dsrExportData:export_heading")), [
    {
      rows: dsrRows,
      tabName: t("dsrExportData:export_heading_general"),
      columnWidths: [30, 20, 30, 30, 20, 20, 15, 20, 20, 30, 20, 20]
    },
    {
      rows: dsrTaskRows,
      tabName: t("dsrExportData:export_heading_tasks"),
      columnWidths: [15, 35, 30, 15, 15]
    }
  ]);
}
