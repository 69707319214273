import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%"
  }
}));

export default function GeneralPageButtons({
  leftArea,
  primaryButton,
  secondaryButton,
  rightArea
}: {
  readonly leftArea?: React.ReactNode;
  readonly primaryButton?: {
    readonly label?: string;
    readonly disabled?: boolean;
    readonly onClick: () => void;
  };
  readonly secondaryButton?: {
    readonly label?: string;
    readonly disabled?: boolean;
    readonly onClick: () => void;
  };
  readonly rightArea?: React.ReactNode;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      className={classes.container}
      alignContent="center"
      justifyContent={leftArea ? "space-between" : "flex-end"}
    >
      {leftArea && <Grid item>{leftArea}</Grid>}
      <Grid item>
        <Grid container alignContent="center" justifyContent="space-between" spacing={1}>
          {secondaryButton && (
            <Grid item>
              <Button disabled={secondaryButton.disabled} onClick={secondaryButton.onClick}>
                {secondaryButton.label || t("pagination:back")}
              </Button>
            </Grid>
          )}
          {primaryButton && (
            <Grid item>
              <Button
                disabled={primaryButton.disabled}
                variant="contained"
                color="primary"
                onClick={primaryButton.onClick}
              >
                {primaryButton.label || t("pagination:next")}
              </Button>
            </Grid>
          )}
          {rightArea && <Grid item>{rightArea}</Grid>}
        </Grid>
      </Grid>
    </Grid>
  );
}
