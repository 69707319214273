import { useCallback } from "react";
import overviewBaseController, {
  OverviewController,
  OverviewItem,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import {
  getAssetsStatusFilter,
  getAssetsTypeFilter,
  getDataStorageLocationFilter,
  getLabelsFilter,
  getOrganizationFilter
} from "app/pages/shared/Filters/filters";
import { AxiosInstance, AxiosResponse } from "axios";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { resourcesDecorator } from "../../../../components/Overview/controllers/decorator/resourcesDecorator";
import { getTextFromTextEditorJsonString } from "../../questionnaires/utils/textEditorConverter";
import { departmentsDecorator } from "../../../../components/Overview/controllers/decorator/departmentsDecorator";

const AssetsOverviewController = (axiosInstance: AxiosInstance): OverviewController => {
  const { t } = useTranslation();
  const { addToSeenItemsOfUserHook, loadSeenItemsOfUserHook } = useUserAndTenantData();
  const { t: tFilter } = useTranslation("filter_criteria");
  const normalize = useCallback(
    (item: Pick<OverviewItem, "title" | "subTitle" | "id">) => ({
      ...item,
      subTitle: getTextFromTextEditorJsonString(item.subTitle || "")
    }),
    []
  );
  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.ASSETS, normalize, [
    resourcesDecorator,
    departmentsDecorator
  ]);

  const getFilters = useCallback(
    (overviewResult: OverviewResult) => [
      getAssetsStatusFilter("status", t),
      getAssetsTypeFilter("resourceAssetTypeId", t),
      getLabelsFilter("labelIds", overviewResult._resources, t),
      getOrganizationFilter("orgUnitIds", overviewResult._departments, t),
      getDataStorageLocationFilter("dataLocationIds", overviewResult.dataLocations, t)
    ],
    [t]
  );

  const getSortings = useCallback(
    () => [
      {
        field: "title",
        type: "asc",
        label: tFilter("aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: tFilter("zToA")
      },
      {
        field: "createdAt",
        type: "desc",
        label: tFilter("newFirst")
      },
      {
        field: "createdAt",
        type: "asc",
        label: tFilter("oldFirst")
      }
    ],
    [tFilter]
  );
  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    const data = await baseController.getOverview(setup);
    if (!data) {
      return null;
    }

    return {
      ...data,
      filters: getFilters(data),
      sortings: getSortings()
    };
  };

  const goToItem = (id: string) => {
    baseController.goToItem(`/asset-management/${id}/general`);
  };

  const addItem = async (data: OverviewNewItem): Promise<AxiosResponse> => {
    const response = await baseController.addItem({
      name: data?.title || createOverviewItemDefaultName("asset")
    });
    const assetId = response.headers["x-resource-id"] || "";

    // add to seen items
    await addToSeenItemsOfUserHook(COLLECTIONS.ASSETS, assetId);
    await loadSeenItemsOfUserHook();
    return response;
  };

  const deleteItem = async (id: string): Promise<void> => {
    await baseController.deleteItem(id);
    await loadSeenItemsOfUserHook();
  };

  const patchItem = async (id: string, data: object) => {
    return await baseController.patchItem(id, {
      name: "title" in data && data.title
    });
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    const response = await addItem(data);
    goToItem(response.headers["x-resource-id"] || "");
  };

  return {
    ...baseController,
    getOverview,
    goToItem,
    addItem,
    deleteItem,
    patchItem,
    addItemAndGo
  };
};

export default AssetsOverviewController;
