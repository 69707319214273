import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { usePathName } from "../../../router/router-custom-hooks";
import { ClassNameMap } from "@material-ui/styles";
import { sidebarZIndex } from "./sidebarZIndex";

export const HelpSidebar = ({
  sidebarOpen,
  language,
  classes,
  onClicked
}: {
  readonly sidebarOpen: boolean;
  readonly language: string;
  readonly classes: ClassNameMap;
  readonly onClicked?: () => void;
}) => {
  const { t } = useTranslation("sidebar");
  const path = usePathName();
  const faqPath = path.includes("/help/faq");
  const onPrivacyClick = useCallback(() => {
    window.open(
      language === "de" ? "https://caralegal.eu/app/datenschutzerklaerung" : "https://caralegal.eu/app/privacynotice"
    );
    setAnchorEl(null);
    onClicked?.();
  }, [language, onClicked]);
  const onSupportClick = useCallback(() => {
    window.open("https://caralegal.atlassian.net/servicedesk/customer/portals");
    setAnchorEl(null);
    onClicked?.();
  }, [onClicked]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = useCallback(event => {
    // seems like event object is re-used by browser, so we need to extract it before we pass it to set state
    // since we use function setter in set state, it can run later, and by then the browser probably have re-used
    // the event object, and changed the current target to something else it wanted
    const currentTarget = event.currentTarget;
    setAnchorEl(anchorEl => (anchorEl ? null : currentTarget));
  }, []);
  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
  }, []);
  useEffect(() => {
    setAnchorEl(null);
  }, [sidebarOpen]);

  return (
    <>
      <ListItem className={faqPath ? classes.activeItem : ""} button onClick={handleClick}>
        <ListItemIcon className={faqPath ? classes.activeIcon : classes.icon}>
          <ContactSupportIcon />
        </ListItemIcon>
        <ListItemText primary={t("help")} />
        <ArrowRightIcon />
      </ListItem>
      <Popper open={anchorEl !== null} anchorEl={anchorEl} placement="right-start" transition style={popperZIndex}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper className={classes.paperTranslationContainer}>
            <MenuList id="composition-menu" aria-labelledby="composition-button">
              {[
                { name: t("support"), onClick: onSupportClick },
                { name: t("private_notice"), onClick: onPrivacyClick }
              ]
                .flatMap(it => (it ? [it] : []))
                .map(i => (
                  <MenuItem key={i.name} onClick={i.onClick}>
                    {t(i.name)}
                  </MenuItem>
                ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

const popperZIndex: CSSProperties = { zIndex: sidebarZIndex + 1 };

export default HelpSidebar;
