import { useUserAndTenantData } from "../../../handlers/userAndTenant/user-tenant-context";
import useSWR from "swr";
import {
  getNumberUnseenDataLocationsOfUserApi,
  getNumberUnseenExternalRecipientsOfUserApi
} from "../../../api/externalRecipientApi";
import {
  getNumberUnseenResourcesOfUserApi,
  getNumberUnseenResourcesPerResourceTypeApi
} from "../../../api/resourceApi";
import { getNumberUnseenAssetsApi } from "../../../api/assetApi";
import { COLLECTIONS } from "../../../collections";
import { getNumberUnseenTasksApi } from "../../../api/taskApi";
import { useUserNotifications } from "../../../../hook/useUserNotifications";
import { useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";

export interface SidebarUnseen {
  readonly tasks: number;
  readonly notifications: number;
  readonly serviceProviders: number;
  readonly resources: number;
  readonly resourcesByType: [{ type: string; _count: number }] | [];
  readonly dataLocations: number;
  readonly assets: number;
}

export const useSidebarSWR = () => {
  const {
    data: unseenTasks,
    isValidating: unseenTasksValidating,
    mutate: unseenTasksMutate
  } = useSWR("unseen-tasks", () => getNumberUnseenTasksApi());

  const {
    data: unseenERs,
    isValidating: unseenERsValidating,
    mutate: unseenERsMutate
  } = useSWR("unseen-ers", () => getNumberUnseenExternalRecipientsOfUserApi());

  const {
    data: unseenDLs,
    isValidating: unseenDLsValidating,
    mutate: unseenDLsMutate
  } = useSWR("unseen-dls", () => getNumberUnseenDataLocationsOfUserApi());

  const {
    data: unseenResources,
    isValidating: unseenResourcesValidating,
    mutate: unseenResourcesMutate
  } = useSWR("unseen-resources", () => getNumberUnseenResourcesOfUserApi());

  const {
    data: unseenResourcesPerType,
    isValidating: unseenResourcesPerTypeValidating,
    mutate: unseenResourcesPerTypeMutate
  } = useSWR("unseen-resources-per-type", () => getNumberUnseenResourcesPerResourceTypeApi());

  const {
    data: unseenAssets,
    isValidating: unseenAssetsValidating,
    mutate: unseenAssetsMutate
  } = useSWR("unseen-assets", () => getNumberUnseenAssetsApi());

  const sidebarNewItemsMutate = useCallback(() => {
    return Promise.all([
      unseenDLsMutate(),
      unseenERsMutate(),
      unseenTasksMutate(),
      unseenResourcesMutate(),
      unseenResourcesPerTypeMutate(),
      unseenAssetsMutate()
    ]);
  }, [
    unseenDLsMutate,
    unseenERsMutate,
    unseenTasksMutate,
    unseenResourcesMutate,
    unseenResourcesPerTypeMutate,
    unseenAssetsMutate
  ]);

  return {
    unseenTasks,
    unseenTasksValidating,
    unseenTasksMutate,
    unseenERs,
    unseenERsValidating,
    unseenERsMutate,
    unseenDLs,
    unseenDLsValidating,
    unseenDLsMutate,
    unseenResources,
    unseenResourcesValidating,
    unseenResourcesMutate,
    unseenResourcesPerType,
    unseenResourcesPerTypeValidating,
    unseenResourcesPerTypeMutate,
    unseenAssets,
    unseenAssetsValidating,
    unseenAssetsMutate,
    sidebarNewItemsMutate
  };
};

export const useSidebarUnseen = () => {
  const { auth } = useAuthentication();
  const { seenItems } = useUserAndTenantData();

  const [sidebarNewItems, setSidebarNewItems] = useState<SidebarUnseen>({
    [COLLECTIONS.TASKS]: 0,
    [COLLECTIONS.NOTIFICATIONS]: 0,
    [COLLECTIONS.EXTERNAL_RECIPIENTS]: 0,
    [COLLECTIONS.RESOURCES]: 0,
    resourcesByType: [],
    [COLLECTIONS.DATA_LOCATIONS]: 0,
    [COLLECTIONS.ASSETS]: 0
  });

  const { notifications } = useUserNotifications();
  useEffect(() => {
    if (notifications?.length) {
      setSidebarNewItems(sidebarNewItems => ({
        ...sidebarNewItems,
        [COLLECTIONS.NOTIFICATIONS]: notifications.filter(i => !i.readAt && i.senderUID !== auth?.uid).length
      }));
    }
  }, [notifications, auth?.uid]);

  const {
    unseenTasks,
    unseenERs,
    unseenDLs,
    unseenResources,
    unseenResourcesPerType,
    unseenAssets,
    sidebarNewItemsMutate
  } = useSidebarSWR();

  useEffect(() => {
    setSidebarNewItems(sidebarNewItems => ({
      ...sidebarNewItems,
      [COLLECTIONS.TASKS]: unseenTasks || 0
    }));
  }, [unseenTasks]);
  useEffect(() => {
    setSidebarNewItems(sidebarNewItems => ({
      ...sidebarNewItems,
      [COLLECTIONS.EXTERNAL_RECIPIENTS]: unseenERs || 0
    }));
  }, [unseenERs]);
  useEffect(() => {
    setSidebarNewItems(sidebarNewItems => ({
      ...sidebarNewItems,
      [COLLECTIONS.DATA_LOCATIONS]: unseenDLs || 0
    }));
  }, [unseenDLs]);
  useEffect(() => {
    setSidebarNewItems(sidebarNewItems => ({
      ...sidebarNewItems,
      [COLLECTIONS.RESOURCES]: unseenResources || 0
    }));
  }, [unseenResources]);
  useEffect(() => {
    if (unseenResourcesPerType) {
      setSidebarNewItems(sidebarNewItems => ({
        ...sidebarNewItems,
        resourcesByType: unseenResourcesPerType
      }));
    }
  }, [unseenResourcesPerType]);
  useEffect(() => {
    setSidebarNewItems(sidebarNewItems => ({
      ...sidebarNewItems,
      [COLLECTIONS.ASSETS]: unseenAssets || 0
    }));
  }, [unseenAssets]);

  useEffect(() => {
    if (seenItems?.length) {
      sidebarNewItemsMutate();
    }
  }, [sidebarNewItemsMutate, seenItems?.length]);

  return {
    sidebarNewItems
  };
};
