import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Typography, Grid } from "@material-ui/core";
import Tile, { TileProps } from "./Tile";
import { useTranslation } from "react-i18next";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";

export type MainTilesProps = {
  title: string;
  tilesPerRow?: number;
  tiles: TileProps[];
  dropdownOptions: string[];
  dropdownLabel: string;
  allowMultipleSelect?: boolean;
  tileOnClick: (value: string) => void;
  dropDownGetOptionsLabel?: (optionId: string) => string;
  dropdownOnChange: (value: string | string[]) => void;
  onCancel: () => void;
  onCreate: () => void;
};

const MainTiles: React.FC<MainTilesProps> = ({
  title,
  allowMultipleSelect = false,
  tilesPerRow = 2,
  tiles,
  dropdownOptions,
  dropdownLabel,
  tileOnClick,
  dropDownGetOptionsLabel,
  dropdownOnChange,
  onCancel,
  onCreate
}) => {
  const [selectedDropdown, setSelectedDropdown] = useState<string | string[] | undefined>(undefined);
  const [selectedTile, setSelectedTile] = useState<string | null>(null);
  const { t } = useTranslation("common");
  const handleTileClick = useCallback(
    (tile: string) => {
      setSelectedTile(tile);
      tileOnClick(tile);
    },
    [tileOnClick]
  );
  const handleMethodChange = useCallback(
    value => {
      setSelectedDropdown(value);
      dropdownOnChange(value);
    },
    [dropdownOnChange]
  );

  useEffect(() => {
    const _selectedTileTitle = tiles.find(({ selected }) => selected === true)?.["title"];
    if (_selectedTileTitle) {
      setSelectedTile(_selectedTileTitle);
    }
  }, [tiles]);

  return (
    <>
      {/* eslint-disable react/jsx-no-bind */}
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography
          variant="h5"
          gutterBottom
          style={{ fontFamily: "OpenSans", fontSize: "14px", marginBottom: "24px", alignSelf: "flex-start" }}
        >
          {title}
        </Typography>
        <Grid container spacing={4} justifyContent="center" style={{ paddingBottom: "24px" }}>
          {tiles.map((tile, index) => (
            <Grid item xs={12} sm={6} md={(12 / tilesPerRow) as any} key={index}>
              <Tile
                icon={tile.icon}
                title={tile.title}
                description={tile.description}
                selected={tile.title === selectedTile}
                disabled={tile.disabled}
                onClick={() => {
                  handleTileClick(tile.title);
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Box display="flex" style={{ width: "100%" }}>
          <MultiAutocomplete
            selected={selectedDropdown}
            options={dropdownOptions}
            getOptionLabel={dropDownGetOptionsLabel}
            disableClearable={true}
            freeSolo={false}
            updateSelected={handleMethodChange}
            hasMultiSelect={allowMultipleSelect}
            label={t(dropdownLabel)}
            fullWidth={true}
          />
        </Box>
      </Box>
      <Box style={{ paddingTop: "24px" }} display="flex" justifyContent="flex-end">
        <Button variant="outlined" size="medium" color="primary" style={{ fontFamily: "OpenSans" }} onClick={onCancel}>
          {t("common:cancel")}
        </Button>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          style={{ marginLeft: 14, fontFamily: "OpenSans" }}
          disabled={!selectedTile || !selectedDropdown || selectedDropdown.length <= 0}
          onClick={onCreate}
        >
          {t("common:create")}
        </Button>
      </Box>
    </>
  );
};

export default MainTiles;
