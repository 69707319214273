import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogContent, Grid, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextBodySecondary from "components/TextBodySecondary/TextBodySecondary";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import CustomAlert, { CustomAlertProps } from "../CustomAlert/CustomAlert";
import { ButtonProps } from "@material-ui/core/Button/Button";

const useStyles = makeStyles((theme: any) => ({
  circularLoading: {
    color: theme.palette.primary.light,
    position: "relative",
    top: "3px",
    right: "3px"
  },
  editModalIcon: {
    color: theme.palette.alert.info
  },
  editModalIconWarning: {
    color: theme.palette.alert.warning
  }
}));

ConfirmationModal.defaultProps = {
  modalBody: "",
  variant: "warning",
  buttons: []
};

export interface ConfirmationModalButtonProps
  extends Pick<ButtonProps, "variant" | "color" | "size" | "onClick" | "disabled" | "title"> {
  readonly confirmButton?: boolean;
}
export interface ConfirmationModalProps {
  readonly modalOpen: boolean;
  readonly onClose: () => void;
  readonly modalTitle?: string;
  readonly modalText: string;
  readonly modalNote?: string;
  readonly modalBody?: React.ReactNode;
  readonly variant?: CustomAlertProps["severity"];
  readonly buttons?: ConfirmationModalButtonProps[];
  readonly maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
}
export default function ConfirmationModal({
  modalOpen,
  onClose,
  modalTitle,
  modalText,
  modalNote,
  modalBody,
  buttons,
  variant,
  maxWidth
}: ConfirmationModalProps) {
  const classes = useStyles();
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);

  useEffect(() => {
    if (!modalOpen) {
      setLoadingConfirmation(false);
    }
  }, [modalOpen]);

  const confirmButtons = buttons?.filter(({ confirmButton }) => confirmButton);
  const nonConfirmButtons = buttons?.filter(({ confirmButton }) => !confirmButton);

  return (
    <Dialog open={modalOpen} onClose={onClose} maxWidth={maxWidth}>
      <DialogContent style={minWidth}>
        {modalTitle && (
          <CustomAlert
            severity={variant || "info"}
            icon={
              <ErrorOutline className={variant === "warning" ? classes.editModalIconWarning : classes.editModalIcon} />
            }
          >
            {modalTitle}
          </CustomAlert>
        )}
        <Box whiteSpace="pre-wrap" mt={2}>
          <TextBodySecondary text={modalText} />
        </Box>
        {modalNote ? (
          <Box padding="16px 24px" borderRadius={6} bgcolor="rgba(0, 0, 0, 0.04)" whiteSpace="pre-wrap" mt={2}>
            <TextBodySecondary text={modalNote} />
          </Box>
        ) : null}
        {modalBody && <Box mt={2}>{modalBody}</Box>}
        <Box display={"flex"} mt={2} mb={2}>
          <Box flex={1}>
            {nonConfirmButtons?.map((button, index) => (
              <ConfirmationModalButton
                key={index}
                button={button}
                loading={loadingConfirmation}
                onLoadingChange={setLoadingConfirmation}
              />
            ))}
          </Box>
          <Box display="flex">
            {confirmButtons?.map((button, index) => (
              <Box ml={3} key={index}>
                <ConfirmationModalButton
                  button={button}
                  loading={loadingConfirmation}
                  onLoadingChange={setLoadingConfirmation}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const minWidth: CSSProperties = {
  minWidth: 600
};

const ConfirmationModalButton = ({
  button,
  loading,
  onLoadingChange
}: {
  readonly button: ConfirmationModalButtonProps;
  readonly loading: boolean;
  readonly onLoadingChange: (value: boolean) => void;
}) => {
  const classes = useStyles();
  const onClick = button.onClick;
  const onClickCallback = useCallback(
    (event: any) => {
      onLoadingChange(true);
      onClick?.(event);
    },
    [onClick, onLoadingChange]
  );

  return (
    <Button
      variant={button.variant}
      color={button.color ?? "primary"}
      size={button.size ?? "medium"}
      disabled={loading || button.disabled}
      onClick={onClickCallback}
    >
      {!button.confirmButton && <span id={"confirmation_modal" + button.title}>{button.title}</span>}
      {!loading && button.confirmButton && <span>{button.title}</span>}
      {loading && button.confirmButton && (
        <span>
          <CircularProgress size={14} className={classes.circularLoading} /> {button.title}
        </span>
      )}
    </Button>
  );
};
