import { CSSProperties } from "react";

// all custom styles shiran (current designer) asked to apply
// this will probably become standard in the future, and then we should then play with theme.js instead of doing this

export const noUppercaseOpenSansButton: CSSProperties = {
  textTransform: "none",
  fontFamily: "OpenSans",
  fontWeight: 600
};

export const tabStyle: CSSProperties = {
  textTransform: "none",
  minWidth: 100,
  fontFamily: "OpenSans",
  fontWeight: 600
};

export const subText: CSSProperties = {
  textTransform: "none",
  fontSize: "12px",
  fontFamily: "OpenSans",
  fontWeight: 400,
  color: "#818181"
};

export const smallerHeading: CSSProperties = { lineHeight: "24px", fontSize: "16px", fontWeight: 600 };
