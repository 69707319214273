import {
  createTomApi,
  deleteTomApi,
  getTomApi,
  getTomsIndexApi,
  TomPayload,
  TomStatus,
  updateTomApi,
  UpdateTomModelDTO
} from "../api/tomApi";
import { xor } from "lodash-es";
import { getAllRisks } from "./risksHandler";
import { isAxiosErrorWithCode } from "../api/axios/axiosErrorHandler";

export const TOM_STATUSES: Record<string, TomStatus> = {
  PLANNED: "PLANNED",
  IMPLEMENTED: "IMPLEMENTED"
};

export const statusRequireDate = (status: string) => status === TOM_STATUSES.IMPLEMENTED;

export async function createTom(
  tomName: string,
  inputData: Partial<Omit<TomPayload, "name">>,
  processSpecific = false
) {
  const finalInputData = resetStatusDateOnStatusUpdate({
    riskIds: [],
    protectionObjectiveIds: [],
    description: "",
    labelIds: [],
    status: TOM_STATUSES.PLANNED,
    statusDate: null,
    ...inputData
  });
  return await createTomApi(tomName, {
    processSpecific: processSpecific || false,
    ...finalInputData
  });
}

async function internalUpdateTom(tomId: string, data: UpdateTomModelDTO) {
  await updateTomApi(tomId, data);
  return tomId;
}

const resetStatusDateOnStatusUpdate = (data: UpdateTomModelDTO) => {
  if (data.status && data.status !== TOM_STATUSES.IMPLEMENTED) {
    return {
      ...data,
      statusDate: null
    };
  }

  return data;
};

export async function updateTomWithData(
  tomId: string,
  data: Omit<UpdateTomModelDTO, "protectionObjectiveIds"> & { readonly protectionObjective?: string[] }
) {
  const { protectionObjective, ...restData } = data;
  if (protectionObjective && Array.isArray(protectionObjective)) {
    await updateTOMProtectionObjectiveIds(tomId, protectionObjective);
  }

  const updatePayload = resetStatusDateOnStatusUpdate(restData);
  return internalUpdateTom(tomId, updatePayload);
}

export const updateTOMProtectionObjectiveIds = async (tomId: string, updatedProtectionObjectiveIds: string[]) => {
  const currentTOM = await getTom(tomId);
  const currentProtectionObjectiveIds = currentTOM?.protectionObjectiveIds || [];
  const updateOperation = async () => {
    await internalUpdateTom(tomId, { protectionObjectiveIds: updatedProtectionObjectiveIds });
  };

  const isAddingNewProtectionObjective = currentProtectionObjectiveIds.length < updatedProtectionObjectiveIds.length;
  if (isAddingNewProtectionObjective) {
    await updateOperation();
    return;
  }

  const removedProtectionObjectiveIds = xor(currentProtectionObjectiveIds, updatedProtectionObjectiveIds);
  const risks = await getAllRisks();

  for (const removedProtectionObjectiveId of removedProtectionObjectiveIds) {
    const usedRisks = await getRisksUsingTomProtectionObjectives(tomId, removedProtectionObjectiveId, risks);
    if (usedRisks.length) {
      throw new ProtectionObjectiveUsedInRiskError(tomId, removedProtectionObjectiveId, usedRisks);
    }
  }
  await updateOperation();
};

export class ProtectionObjectiveUsedInRiskError extends Error {
  tomId: string;
  protectionObjectiveId: string;
  risks: RiskForTom[];
  constructor(tomId: string, protectionObjectiveId: string, risks: RiskForTom[]) {
    super(
      `TOM ${tomId}'s protection objective of ${protectionObjectiveId} is still used on: ${JSON.stringify(
        risks.map(risk => risk.id)
      )}`
    );
    this.tomId = tomId;
    this.protectionObjectiveId = protectionObjectiveId;
    this.risks = risks;
  }
}

export const getRisksUsingTomProtectionObjectives = (
  tomId: string,
  protectionObjectiveId: string,
  risks: RiskForTom[],
  explicitRiskId?: string
) => {
  return risks
    .filter(risk => isTomIdUsedInRisk(tomId, risk) || risk.id === explicitRiskId)
    .filter(risk => (risk.protectionObjectiveIds || []).includes(protectionObjectiveId));
};

export function deleteTom(id: string) {
  return deleteTomApi(id);
}

export async function getAllToms() {
  return await getTomsIndexApi();
}

export async function getTom(id: string) {
  try {
    return await getTomApi(id);
  } catch (error: unknown) {
    if (isAxiosErrorWithCode(error, 404)) {
      // 404 is not found, just return null to determine that the tom does not exist
      return null;
    }
    throw error;
  }
}

export interface RiskForTom {
  readonly id?: string;
  readonly implementedMeasureIds?: string[];
  readonly treatment?: {
    readonly measureIds: string[];
  };
  readonly protectionObjectiveIds?: string[];
}

export const isTomIdUsedInRisk = (tomId: string, risk?: RiskForTom) => {
  if (!Array.isArray(risk?.implementedMeasureIds)) {
    return false;
  }
  if (risk?.implementedMeasureIds.includes(tomId)) {
    return true;
  }

  if (!Array.isArray(risk?.treatment?.measureIds)) {
    return false;
  }

  return risk?.treatment?.measureIds.includes(tomId);
};
