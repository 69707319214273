import { useCallback } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useListItemViewStyles, LazySvgIcon } from "./ListViewItem";
import { usePromisedCallback } from "./usePromisedCallback";
import { useTranslation } from "react-i18next";

export const RowDeletePrompt = (props: { onDelete: () => Promise<void>; onCancel: () => void }) => {
  const listItemViewStyles = useListItemViewStyles();
  const { onDelete, onCancel } = props;
  const deleteTrigger = usePromisedCallback(useCallback(() => onDelete().then(onCancel), [onDelete, onCancel]));
  const [t] = useTranslation();
  const handleStopPropagation = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);
  return (
    <>
      <Box onClick={handleStopPropagation} className={listItemViewStyles.content}>
        <Typography variant="body1" sx={{ paddingLeft: "100px" }}>
          {t("overview:delete_confirmation")}
        </Typography>
      </Box>
      <Box onClick={handleStopPropagation} className={listItemViewStyles.icon}>
        <IconButton color="primary" disabled={deleteTrigger.pending} onClick={deleteTrigger.trigger}>
          <LazySvgIcon name="Check" />
        </IconButton>
        <IconButton color="error" disabled={deleteTrigger.pending} onClick={onCancel}>
          <LazySvgIcon name="Close" />
        </IconButton>
      </Box>
    </>
  );
};
