import React, { CSSProperties, useMemo, useRef } from "react";
import { Box, CircularProgress, Divider } from "@material-ui/core";
import TaskComments from "../controls/TaskComments";
import { TaskDetailsProps, useTaskDetailsLogic } from "./TaskDetailsController";
import {
  RecurringTaskInfoBanner,
  TaskAttachments,
  TaskCommentInput,
  TaskDescription,
  TaskInfoRows,
  TaskLabels,
  TaskLinkedTo,
  TaskParticipants,
  TaskTitle,
  TaskTopRowActions
} from "./components";
import { COLLECTIONS } from "app/collections";

const TaskDetails = ({
  detailType,
  onCloseTaskDetails,
  onTaskAssign,
  onTaskDelete,
  onRecurringTaskDelete,
  onTaskEdit,
  onTaskLoad,
  onTaskParticipantsChange,
  onPageChange,
  taskId,
  setIsSlidedIn,
  reload
}: TaskDetailsProps) => {
  const {
    documentNotFound,
    isGroupSubTask,
    newlyAddedCommentId,
    onAddingMentionsToComment,
    onChangeAssignType,
    onChangeDescription,
    onChangeFiles,
    onChangeLabels,
    onChangeParticipants,
    onChangeTitle,
    onDelete,
    saveOperationInProgress,
    setNewlyAddedCommentId,
    task,
    taskTitle,
    updateTask
  } = useTaskDetailsLogic({
    detailType,
    onCloseTaskDetails,
    onPageChange,
    onRecurringTaskDelete,
    onTaskAssign,
    onTaskDelete,
    onTaskEdit,
    onTaskLoad,
    onTaskParticipantsChange,
    reload,
    setIsSlidedIn,
    taskId
  });

  const taskDetailsContainerRef = useRef<HTMLDivElement | null>(null);

  const isGroupTask = useMemo(
    () => task && "groupId" in task && "taskId" in task && task?.groupId !== undefined,
    [task]
  );

  if (documentNotFound) {
    return <></>;
  }

  if (!task || saveOperationInProgress) {
    return (
      <Box pt={6} p={3} display={"flex"} justifyContent={"center"} alignItems="center">
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <Box display="flex" flexDirection="column" height="100%">
        {/** Box can't take in ref without compile error, have to be a div */}
        <div style={taskDetailsContainerStyle} ref={taskDetailsContainerRef}>
          <TaskTopRowActions
            assignedToType={task.assignedToType}
            item={task}
            onChangeAssignType={onChangeAssignType}
            onCloseDetails={onCloseTaskDetails}
            onDelete={onDelete}
            readOnly={isGroupSubTask}
            setIsSlidedIn={setIsSlidedIn}
          />
          <TaskTitle
            assignedToType={task.assignedToType}
            detailType={detailType}
            disabled={isGroupSubTask}
            onChangeTitle={onChangeTitle}
            title={task.title || taskTitle}
          />
          {task.recurringTaskId && (
            <RecurringTaskInfoBanner
              assigneeUID={task.assigneeUID || ""}
              onPageChange={onPageChange}
              participants={task.participants}
              recurringTaskId={task.recurringTaskId}
            />
          )}
          <TaskInfoRows detailType={detailType} item={task} updateTask={updateTask} readOnly={isGroupSubTask} />
          <TaskLinkedTo item={task} />
          <TaskDescription
            docId={task.id}
            onChangeDescription={onChangeDescription}
            readOnly={isGroupSubTask}
            text={task.description}
          />
          <Divider />
          <TaskAttachments
            docId={isGroupTask ? task.taskId : task.id}
            groupId={task.groupId}
            assigneeUID={task.assigneeUID || ""}
            onChangeFiles={onChangeFiles}
          />
          <Divider />
          {detailType !== "RECURRING" && (
            <>
              <TaskComments
                taskId={isGroupTask ? (task.taskId as string) : task.id}
                groupId={isGroupTask ? (task.groupId as string) : undefined}
                assigneeUID={task.assigneeUID || ""}
                creatorUID={task.creatorUID}
                onChangeParticipants={onChangeParticipants}
                participants={task.participants}
                handleAddMentions={onAddingMentionsToComment}
                newlyAddedCommentId={newlyAddedCommentId}
                setNewlyAddedCommentId={setNewlyAddedCommentId}
                taskDetailsContainerRef={taskDetailsContainerRef}
              />
            </>
          )}
          {detailType !== "GROUP" && (
            <>
              <Divider />
              <TaskLabels
                taskId={task.id}
                labels={task.labels}
                saveOperationInProgress={saveOperationInProgress}
                onChangeLabels={onChangeLabels}
              />
            </>
          )}
        </div>
        <Divider />
        <Box flex={1} p={3} pt={0}>
          {detailType !== "RECURRING" && (
            <TaskCommentInput
              docName={taskTitle}
              taskId={task.groupId ? task.taskId : task.id}
              collection={COLLECTIONS.TASK_DETAIL}
              pageId={taskId}
              groupTaskId={task.groupId ? task.groupId : undefined}
              assigneeUID={task.assigneeUID || ""}
              setNewlyAddedCommentId={setNewlyAddedCommentId}
              onChangeParticipants={onChangeParticipants}
              participants={task.participants}
            />
          )}
          {detailType !== "GROUP" && task.assignedToType !== "GROUP" && (
            <>
              <Divider />
              <TaskParticipants
                task={task}
                onChangeParticipants={onChangeParticipants}
                saveOperationInProgress={saveOperationInProgress}
              />
            </>
          )}
        </Box>
      </Box>
    );
  }
};

const taskDetailsContainerStyle: CSSProperties = {
  padding: "24px 24px 0px 24px",
  overflow: "auto",
  flex: "9"
};

export default TaskDetails;
